import * as infirmerieTypes from "../../types/infirmerieTypes"
import { AUTH_ERROR, LOGOUT_SUCCESS, TOKEN_REFRESH_FAIL } from "../../types/userTypes"


const initialState = {
    patient: {},
    patients:[],

    patientConsulte: {},
    patientConsultePut: {},
    patientConsultePost: {},
    patientsConsultes:[],
    professionSanteAConsulter:[],
    professionSanteByService:[],

    reorientationPost:{},
    
    isLoadingPatient: false,
    isLoadingPatients: false,

    isLoadingPatientConsulte: false,
    isLoadingPatientsConsultesRefresh: false,
    isLoadingPatientConsultePut: false,
    isLoadingPatientConsultePost: false,
    isLoadingPatientsConsultes: false,
    isLoadingProfessionSanteAConsulter: false,
    isLoadingProfessionSanteByService: false,

    isLoadingReorientationPost: false,
    
    isSuccesPatient: false,
    isSuccesPatients: false,

    isSuccesPatientConsulte: false,
    isSuccesPatientConsultePut: false,

    isSuccesPatientConsultePost: false,

    isSuccesPatientsConsultes: false,
    isSuccesProfessionSanteAConsulter: false,
    isSuccesProfessionSanteByService:false,

    isSuccesReorientationPost:false,

    isError: false,
    isErrorPatientConsultePost: false,
    isErrorPatientConsultePut:false,
    isErrorReorientationPost:false,
    error:'',

    errorReorientationPost: "",

    zscore: null,
    zscoreLoader: false,
}


// eslint-disable-next-line
export default function (state = initialState, action) {
    switch (action.type) {
        //get informations fiche patient à reorienter
        case infirmerieTypes.INFIRMERIE_PATIENT_GET_A_INFIRMERIE_LOADING:
            return {
                ...state,
                isLoadingPatient: true,
                isError:false,
                error:""
            }
        case infirmerieTypes.INFIRMERIE_PATIENT_GET_A_INFIRMERIE_SUCCESS:
            return {
                ...state,
                isLoadingPatient: false,
                isSuccesPatient:true,
                patient: action.payload,
                isError:false,
                error:""
            }
        case infirmerieTypes.INFIRMERIE_PATIENT_GET_A_INFIRMERIE_FAILED:
            return {
                ...state,
                isLoadingPatient: false,
                isSuccesPatient:false,
                patient: {},
                isError:true,
                error:action.payload
            }
        
        //gets informations fiches patients infirmerie
        case infirmerieTypes.INFIRMERIE_PATIENTS_GETS_A_INFIRMERIE_LOADING:
            return {
                ...state,
                isLoadingPatients: true,
                isError:false,
                error:""
            }
        case infirmerieTypes.INFIRMERIE_PATIENTS_GETS_A_INFIRMERIE_SUCCESS:
            return {
                ...state,
                isLoadingPatients: false,
                isSuccesPatients:true,
                patients: action.payload,
                isError:false,
                error:""
            }
        case infirmerieTypes.INFIRMERIE_PATIENTS_GETS_A_INFIRMERIE_FAILED:
            return {
                ...state,
                isLoadingPatients: false,
                isSuccesPatients:false,
                isError:true,
                error:action.payload
            }
        
        
        //get information patient consulte infirmerie
        case infirmerieTypes.INFIRMERIE_PATIENT_GET_CONSULTER_INFIRMERIE_LOADING:
            return {
                ...state,
                isLoadingPatientConsulte: true,
                isError:false,
                error:""
            }
        case infirmerieTypes.INFIRMERIE_PATIENT_GET_CONSULTER_INFIRMERIE_SUCCESS:
            return {
                ...state,
                isLoadingPatientConsulte: false,
                isSuccesPatientConsulte:true,
                patientConsulte: action.payload,
                isError:false,
                error:""
            }
        case infirmerieTypes.INFIRMERIE_PATIENT_GET_CONSULTER_INFIRMERIE_FAILED:
            return {
                ...state,
                isLoadingPatientConsulte: false,
                isSuccesPatientConsulte:false,
                patientConsulte: {},
                isError:true,
                error:action.payload
            }

        //put information patient consulte infirmerie
        case infirmerieTypes.INFIRMERIE_PATIENT_POST_CONSULTER_INFIRMERIE_LOADING:
            return {
                ...state,
                isLoadingPatientConsultePost: true,
                isErrorPatientConsultePost:false,
                error:""
            }
        case infirmerieTypes.INFIRMERIE_PATIENT_POST_CONSULTER_INFIRMERIE_SUCCESS:
            return {
                ...state,
                isLoadingPatientConsultePost: false,
                isSuccesPatientConsultePost:true,
                patientConsultePost: action.payload,
                isErrorPatientConsultePost:false,
                error:""
            }
        case infirmerieTypes.INFIRMERIE_PATIENT_POST_CONSULTER_INFIRMERIE_FAILED:
            return {
                ...state,
                isLoadingPatientConsultePost: false,
                isSuccesPatientConsultePost:false,
                patientConsultePost: {},
                isErrorPatientConsultePost:true,
                error:action.payload
            }
        
        
        //put information patient consulte infirmerie
        case infirmerieTypes.INFIRMERIE_PATIENT_PUT_CONSULTER_INFIRMERIE_LOADING:
            return {
                ...state,
                isLoadingPatientConsultePut: true,
                isSuccesPatientConsultePut:false,
                isErrorPatientConsultePut:false,
                error:""
            }
        case infirmerieTypes.INFIRMERIE_PATIENT_PUT_CONSULTER_INFIRMERIE_SUCCESS:
            return {
                ...state,
                isLoadingPatientConsultePut: false,
                isSuccesPatientConsultePut:true,
                patientConsultePut: action.payload,
                isErrorPatientConsultePut:false,
                error:""
            }
        case infirmerieTypes.INFIRMERIE_PATIENT_PUT_CONSULTER_INFIRMERIE_FAILED:
            return {
                ...state,
                isLoadingPatientConsultePut: false,
                isSuccesPatientConsultePut:false,
                patientConsultePut: {},
                isErrorPatientConsultePut:true,
                error:action.payload
            }

        
        //gets informations patients consultes infirmerie
        case infirmerieTypes.INFIRMERIE_PATIENTS_GETS_CONSULTER_INFIRMERIE_LOADING:
            return {
                ...state,
                isLoadingPatientsConsultes: true,
                isError:false,
                error:""
            }
        case infirmerieTypes.INFIRMERIE_PATIENTS_GETS_CONSULTER_INFIRMERIE_SUCCESS:
            return {
                ...state,
                isLoadingPatientsConsultes: false,
                isSuccesPatientsConsultes:true,
                patientsConsultes: action.payload,
                isError:false,
                error:""
            }
        case infirmerieTypes.INFIRMERIE_PATIENTS_GETS_CONSULTER_INFIRMERIE_FAILED:
            return {
                ...state,
                isLoadingPatientsConsultes: false,
                isSuccesPatientsConsultes:false,
                isError:true,
                error:action.payload
            }

        
        //gets informations patients consultes infirmerie refresh
        case infirmerieTypes.INFIRMERIE_PATIENTS_GETS_CONSULTER_INFIRMERIE_REFRESH_LOADING:
            return {
                ...state,
                isLoadingPatientsConsultesRefresh: true,
                isError:false,
                error:""
            }
        case infirmerieTypes.INFIRMERIE_PATIENTS_GETS_CONSULTER_INFIRMERIE_REFRESH_SUCCESS:
            return {
                ...state,
                isLoadingPatientsConsultesRefresh: false,
                isSuccesPatientsConsultes:true,
                patientsConsultes: action.payload,
                isError:false,
                error:""
            }
        case infirmerieTypes.INFIRMERIE_PATIENTS_GETS_CONSULTER_INFIRMERIE_REFRESH_FAILED:
            return {
                ...state,
                isLoadingPatientsConsultesRefresh: false,
                isSuccesPatientsConsultes:false,
                isError:true,
                error:action.payload
            }

        //post informations fiche patient à reorienter
        case infirmerieTypes.INFIRMERIE_REORIENTATION_POST_LOADING:
            return {
                ...state,
                isLoadingReorientationPost: true,
                isErrorReorientationPost:false,
                errorReorientationPost:""
            }
        case infirmerieTypes.INFIRMERIE_REORIENTATION_POST_SUCCESS:
            return {
                ...state,
                isLoadingReorientationPost: false,
                isSuccesReorientationPost:true,
                reorientationPost: action.payload,
                isErrorReorientationPost:false,
                errorReorientationPost:""
            }
        case infirmerieTypes.INFIRMERIE_REORIENTATION_POST_FAILED:
            return {
                ...state,
                isLoadingReorientationPost: false,
                isSuccesReorientationPost:false,
                reorientationPost: {},
                isErrorReorientationPost:true,
                errorReorientationPost:action.payload
            }

        
        //gets professionnel sante à consulter infirmerie
        case infirmerieTypes.INFIRMERIE_PROFESIONNELS_SANTE_A_CONSULTER_GETS_LOADING:
            return {
                ...state,
                isLoadingProfessionSanteAConsulter: true,
                isError:false,
                error:""
            }
        case infirmerieTypes.INFIRMERIE_PROFESIONNELS_SANTE_A_CONSULTER_GETS_SUCCESS:
            return {
                ...state,
                isLoadingProfessionSanteAConsulter: false,
                isSuccesProfessionSanteAConsulter:true,
                professionSanteAConsulter: action.payload,
                isError:false,
                error:""
            }
        case infirmerieTypes.INFIRMERIE_PROFESIONNELS_SANTE_A_CONSULTER_GETS_FAILED:
            return {
                ...state,
                isLoadingProfessionSanteAConsulter: false,
                isSuccesProfessionSanteAConsulter:false,
                professionSanteAConsulter: {},
                isError:true,
                error:action.payload
            }

        
        //gets professionnel sante by service infirmerie
        case infirmerieTypes.INFIRMERIE_PROFESIONNELS_SANTE_BY_SERVICE_GETS_LOADING:
            return {
                ...state,
                isLoadingProfessionSanteByService: true,
                isError:false,
                error:""
            }
        case infirmerieTypes.INFIRMERIE_PROFESIONNELS_SANTE_BY_SERVICE_GETS_SUCCESS:
            return {
                ...state,
                isLoadingProfessionSanteByService: false,
                isSuccesProfessionSanteByService:true,
                professionSanteByService: action.payload,
                isError:false,
                error:""
            }
        case infirmerieTypes.INFIRMERIE_PROFESIONNELS_SANTE_BY_SERVICE_GETS_FAILED:
            return {
                ...state,
                isLoadingProfessionSanteByService: false,
                isSuccesProfessionSanteByService:false,
                professionSanteByService: {},
                isError:true,
                error:action.payload
            }
        case infirmerieTypes.REQUEST_ZSCORE:
            return {
                ...state,
                zscoreLoader: true,
            }
        case infirmerieTypes.GET_ZSCORE:
            return {
                ...state,
                zscoreLoader:false,
                zscore: action.payload,
            }
        case infirmerieTypes.FAIL_ZSCORE:
            return {
                ...state,
                zscoreLoader: false,
            }

    
        //resets des constantes
        case infirmerieTypes.INFIRMERIE_STARTER_RESET:
            return {
                ...state,
                isLoadingPatient: false,
                isLoadingPatients: false,

                isLoadingPatientConsulte: false,
                isLoadingPatientConsultePut: false,
                isLoadingPatientsConsultes: false,
                isLoadingProfessionSanteByService: false,

                isLoadingReorientationPost: false,
                
                isSuccesPatient: false,
                isSuccesPatients: false,

                isSuccesPatientConsulte: false,
                isSuccesPatientConsultePut: false,
                isSuccesPatientsConsultes: false,
                isSuccesProfessionSanteByService:false,

                isSuccesReorientationPost:false,

                isError: false,
                error:'',


                patient: {},

                patientConsulte: {},
                patientConsultePut: {},
                patientConsultePost: {},

                reorientationPost:{},
                
                isLoadingPatientsConsultesRefresh: false,
                isLoadingPatientConsultePost: false,
                isLoadingProfessionSanteAConsulter: false,
                isSuccesPatientConsultePost: false,
                isErrorPatientConsultePost: false,
                isErrorPatientConsultePut: false,
                isErrorReorientationPost:false,

                errorReorientationPost: "",


                zscore: null,
                zscoreLoader: false,
            }
    
        case AUTH_ERROR:
        case LOGOUT_SUCCESS:
        case TOKEN_REFRESH_FAIL:
            localStorage.removeItem("token_access")
            localStorage.removeItem("token_refresh")
            return {
                ...state,
                patient: {},
                patients:[],

                patientConsulte: {},
                patientConsultePut: {},
                patientsConsultes:[],
                professionSanteAConsulter:[],
                professionSanteByService:[],

                reorientationPost:{},
                
                isLoadingPatient: false,
                isLoadingPatients: false,

                isLoadingPatientConsulte: false,
                isLoadingPatientsConsultesRefresh: false,
                isLoadingPatientConsultePut: false,
                isLoadingPatientsConsultes: false,
                isLoadingProfessionSanteAConsulter: false,
                isLoadingProfessionSanteByService: false,

                isLoadingReorientationPost: false,
                
                isSuccesPatient: false,
                isSuccesPatients: false,

                isSuccesPatientConsulte: false,
                isSuccesPatientConsultePut: false,
                isSuccesPatientsConsultes: false,
                isSuccesProfessionSanteAConsulter: false,
                isSuccesProfessionSanteByService:false,

                isLoadingPatientConsultePost: false,
                isSuccesPatientConsultePost: false,
                isErrorPatientConsultePost: false,

                isSuccesReorientationPost:false,
                isErrorPatientConsultePut: false,
                isErrorReorientationPost:false,

                errorReorientationPost: "",

                isError: false,
                error:''
            }
        

        default:
            return state
  }
}
