export const CENTRE_SANTE_GETS_DATA_SUCCESS = 'CENTRE_SANTE_GETS_DATA_SUCCESS'

export const CENTRE_SANTE_GETS_DATA_FAILED = 'CENTRE_SANTE_GETS_DATA_FAILED'

export const CENTRE_SANTE_GETS_DATA_LOADING = 'CENTRE_SANTE_GETS_DATA_LOADING'


export const CENTRE_SANTE_GETS_DATA_REFRESH_SUCCESS = 'CENTRE_SANTE_GETS_DATA_REFRESH_SUCCESS'

export const CENTRE_SANTE_GETS_DATA_REFRESH_FAILED = 'CENTRE_SANTE_GETS_DATA_REFRESH_FAILED'

export const CENTRE_SANTE_GETS_DATA_REFRESH_LOADING = 'CENTRE_SANTE_GETS_DATA_REFRESH_LOADING'


export const CENTRE_SANTE_GET_DATA_SUCCESS = 'CENTRE_SANTE_GET_DATA_SUCCESS'

export const CENTRE_SANTE_GET_DATA_FAILED = 'CENTRE_SANTE_GET_DATA_FAILED'

export const CENTRE_SANTE_GET_DATA_LOADING = 'CENTRE_SANTE_GET_DATA_LOADING'


export const CENTRE_SANTE_POST_DATA_LOADING = 'CENTRE_SANTE_POST_DATA_LOADING'

export const CENTRE_SANTE_POST_DATA_SUCCESS = 'CENTRE_SANTE_POST_DATA_SUCCESS'

export const CENTRE_SANTE_POST_DATA_FAILED = 'CENTRE_SANTE_POST_DATA_FAILED'


export const CENTRE_SANTE_PUT_DATA_LOADING = 'CENTRE_SANTE_PUT_DATA_LOADING'

export const CENTRE_SANTE_PUT_DATA_SUCCESS = 'CENTRE_SANTE_PUT_DATA_SUCCESS'

export const CENTRE_SANTE_PUT_DATA_FAILED = 'CENTRE_SANTE_PUT_DATA_FAILED'


export const CENTRE_SANTE_PATCH_DATA_LOADING = 'CENTRE_SANTE_PATCH_DATA_LOADING'

export const CENTRE_SANTE_PATCH_DATA_SUCCESS = 'CENTRE_SANTE_PATCH_DATA_SUCCESS'

export const CENTRE_SANTE_PATCH_DATA_FAILED = 'CENTRE_SANTE_PATCH_DATA_FAILED'

export const CENTRE_SANTE_SET_SUCCESS = 'CENTRE_SANTE_SET_SUCCESS'

export const CENTRE_SANTE_SELECT_CENTRE = 'CENTRE_SANTE_SELECT_CENTRE'
