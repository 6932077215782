import React, { lazy } from "react"
import * as MyPrivateRouters from "../routes/PrivateRouter";
import { Switch } from 'react-router-dom';

const LayoutDossiersPatientsDossiersListe = lazy(() =>
    import(
      "../../components/dossiersPatients/dossiers/dossiersListe/LayoutDossiersPatientsDossiersListe"
        )
);
const LayoutDossiersPatientsDossiersDetails = lazy(() =>
    import(
        "../../components/dossiersPatients/dossiers/dossiersVoirPlus/LayoutDossiersPatientsDossiersDetails"
    )
);
const LayoutDossiersPatientsPatientsListe = lazy(() =>
    import(
        "../../components/dossiersPatients/patients/patientsListe/LayoutDossiersPatientsPatientsListe"
    )
);
const LayoutDossiersPatientsPatientsDetals = lazy(() =>
    import(
        "../../components/dossiersPatients/patients/patientsVoirPlus/LayoutDossiersPatientsPatientsDetals"
    )
);
const LayoutDossiersPatientsCircuitsDetails = lazy(() =>
    import(
        "../../components/dossiersPatients/circuits/circuitsVoirPlus/LayoutDossiersPatientsCircuitsDetails"
    )
);
const LayoutDossiersPatientsCircuitsListe = lazy(() =>
    import(
        "../../components/dossiersPatients/circuits/circuitsListe/LayoutDossiersPatientsCircuitsListe"
    )
);
const CDLayout = lazy(() =>
    import(
        "../../components/dossiersPatients/dossiers/dossiersVoirPlus/Consultations/CabinetD/CDLayout"
    )
);
const DermaLayout = lazy(() =>
    import(
        "../../components/dossiersPatients/dossiers/dossiersVoirPlus/Consultations/Dermatologie/DermaLayout"
    )
);
const UrgenceLayout = lazy(() =>
    import(
        "../../components/dossiersPatients/dossiers/dossiersVoirPlus/Consultations/Urgence/UrgenceLayout"
    )
);
const PediatrieLayout = lazy(() =>
    import(
        "../../components/dossiersPatients/dossiers/dossiersVoirPlus/Consultations/Pediatrie/PediatrieLayout"
    )
);
const KineLayout = lazy(() =>
    import(
        "../../components/dossiersPatients/dossiers/dossiersVoirPlus/Consultations/Kinesitherapie/KineLayout"
    )
);
const CardioLayout = lazy(() =>
    import(
        "../../components/dossiersPatients/dossiers/dossiersVoirPlus/Consultations/Cardiologie/CardioLayout"
    )
);
const MGLayout = lazy(() =>
    import(
        "../../components/dossiersPatients/dossiers/dossiersVoirPlus/Consultations/MedecineG/MGLayout"
    )
);
const OrlLayout = lazy(() =>
    import(
        "../../components/dossiersPatients/dossiers/dossiersVoirPlus/Consultations/ORL/OrlLayout"
    )
);
const OphtaLayout = lazy(() =>
    import(
        "../../components/dossiersPatients/dossiers/dossiersVoirPlus/Consultations/Ophtalmologie/OphtaLayout"
    )
);
const ChirurgieLayout = lazy(() =>
    import(
        "../../components/dossiersPatients/dossiers/dossiersVoirPlus/Consultations/Chirurgie/ChirurgieLayout"
    )
);
const GynecoLayout = lazy(() =>
    import(
        "../../components/dossiersPatients/dossiers/dossiersVoirPlus/Consultations/Gynecologie/GynecoLayout"
    )
);
  

export default function DossiersPatientsRouter() {
  return (
    <Switch>
        <MyPrivateRouters.PrivateRouterDossiersPatients
        path="/dossiers-patients"
        component={LayoutDossiersPatientsPatientsListe}
        />

        <MyPrivateRouters.PrivateRouterDossiersPatients
        path="/dossiers-patients-voir-plus/:id"
        component={LayoutDossiersPatientsPatientsDetals}
        />

        <MyPrivateRouters.PrivateRouterDossiersPatients
        path="/dossiers-patients-dossiers"
        component={LayoutDossiersPatientsDossiersListe}
        />

        <MyPrivateRouters.PrivateRouterDossiersPatients
        path="/dossiers-patients-dossiers-voir-plus/:id"
        component={LayoutDossiersPatientsDossiersDetails}
        />

        <MyPrivateRouters.PrivateRouterDossiersPatients
        path="/dossiers-patients-circuits"
        component={LayoutDossiersPatientsCircuitsListe}
        />

        <MyPrivateRouters.PrivateRouterDossiersPatients
        path="/dossiers-patients-circuits-voir-plus/:id"
        component={LayoutDossiersPatientsCircuitsDetails}
        />

        <MyPrivateRouters.PrivateRouterDossiersPatients
        path="/dossiers-patients-consultation-cabinet-dentaire-detail/:id"
        component={CDLayout}
        />

        <MyPrivateRouters.PrivateRouterDossiersPatients
        path="/dossiers-patients-consultation-dermatologie-detail/:id"
        component={DermaLayout}
        />

        <MyPrivateRouters.PrivateRouterDossiersPatients
        path="/dossiers-patients-consultation-urgence-detail/:id"
        component={UrgenceLayout}
        />

        <MyPrivateRouters.PrivateRouterDossiersPatients
        path="/dossiers-patients-consultation-pediatrie-detail/:id"
        component={PediatrieLayout}
        />

        <MyPrivateRouters.PrivateRouterDossiersPatients
        path="/dossiers-patients-consultation-kinesitherapie-detail/:id"
        component={KineLayout}
        />

        <MyPrivateRouters.PrivateRouterDossiersPatients
        path="/dossiers-patients-consultation-cardiologie-detail/:id"
        component={CardioLayout}
        />

        <MyPrivateRouters.PrivateRouterDossiersPatients
        path="/dossiers-patients-consultation-medecine-generale-detail/:id"
        component={MGLayout}
        />

        <MyPrivateRouters.PrivateRouterDossiersPatients
        path="/dossiers-patients-consultation-orl-detail/:id"
        component={OrlLayout}
        />

        <MyPrivateRouters.PrivateRouterDossiersPatients
        path="/dossiers-patients-consultation-ophtalmologie-detail/:id"
        component={OphtaLayout}
        />

        <MyPrivateRouters.PrivateRouterDossiersPatients
        path="/dossiers-patients-consultation-chirurgie-detail/:id"
        component={ChirurgieLayout}
        />

        <MyPrivateRouters.PrivateRouterDossiersPatients
        path="/dossiers-patients-consultation-gynecologie-detail/:id"
        component={GynecoLayout}
        />

        <MyPrivateRouters.PrivateRouterDossiersPatients
        path="/dossiers-patients-consultation-curative-detail/:id"
        component={ChirurgieLayout}
        />
    </Switch>
  )
}
