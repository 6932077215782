import * as typesPatient from "../../types/patientTypes"

import * as typesUsers from "../../types/userTypes"

const initialState = {
    patient: {},
    patientPut: {},
    patientCarte:[],
    patientProfessions: [],
    patientParents: [],
    patients: [],
    fichesOrientations:[],
    fichesOrientationsByPatient:[],
    patientPaymentSlips : [],
    assuranceGet:[],
    assurancesData:[],
    assurancesAllData:[],
    assurancesPost:[],

    patientsGlobals: [],
    isSuccessPatientsGlobals: false,
    isErreurPatientsGlobals: false,
    
    isLoading: false,
    isDeleteLoading: false,
    isProfessionLoading: false,
    isParentLoading: false,
    iscarteLaoding:false,
    isLoadings: false,
    isLoadingsRefreshs: false,
    isLoadingAssurancesData:false,
    isLoadingAssuranceGet:false,
    isLoadingAssurancesRefreshData: false,
    isLoadingAssurancesAllData:false,
    isLoadingAssurancesPost: false,

    isError: false,
    isErrorsImpossibleCreate:false,
    isErrorOperation:false,

    isSuccesGetPatient: false,
    isLoadingFichesOrientations:false,
    isSuccesFichesOrientations:false,
    isLoadingFichesOrientationsRefresh: false,

    isLoadingFichesOrientationsByPatient: false,
    isSuccesFichesOrientationsByPatient:false,

    errors: {},
    error:'',
    errorOperation:"",
    isErrorSuppression:false,
    isLoadingRegiter:false,
    isSuccesAssuranceGet:false,
    isSuccesAssurancesData:false,
    isSuccesAssurancesRefreshData: false,
    isSuccesAssurancesAllData:false,
    isSuccesAssurancesPost:false,
    isSucces: false,
    isSuccesUpdate: false,
    putError: null,
    isPutError: false,
    isLoadingsAttentes: false,

    isLoadingAssurancesPut: false,
    isSuccesAssurancesPut: false,
    assurancesPut:"",

    isLoadingAssuranceDelete: false,
    isSuccesAssuranceDelete: false,
    errorAssuranceDelete:false,
    isErrorAssuranceDelete:"",
    isErrorAssuranceImpossibleDelete:false,

    errorAssurance: "",
    isErrorAssurance: false,
    errorPostAssurance: "",
    isErrorPostAssurance: false,

    lastDemandeExamen: {},
    isLoadingLastExamen: false,

    isLoadingUpload:false,
    patientUpload:{},
    isErrorUpload: false,
    isErrorsImpossibleUpload:false,
    errorsUpload: {},
    isSuccesUpload: false,

    badRequestError: false,
    badRequestMsg: "",
}

// eslint-disable-next-line
export default function (state = initialState, action) {
    switch (action.type) {
        ///////////////////////////////////////////////////////////
        ///////////////////////////////////////////////////////////
        ///////////////////////////////////////////////////////////
        case typesPatient.PATIENTS_PARENT_GETS_DATA_LOADING:
            return {
                ...state,
                isParentLoading: true,
                isSucces: false,
                lastDemandeExamen: {},
            }
        case typesPatient.PATIENTS_PARENT_GETS_DATA_SUCCESS:
            return {
                ...state,
                isParentLoading: false,
                patientParents: action.payload,
                lastDemandeExamen: {},
            };
        case typesPatient.PATIENTS_PARENT_GETS_DATA_FAILED:
            return {
                ...state,
                patientParents: [],
                isParentLoading: false,
                isSucces: false,
                isErrorOperation:false,
                errorOperation:"",
                lastDemandeExamen: {},
            }

        ///////////////////////////////////////////////////////////
        ///////////////////////////////////////////////////////////
        ///////////////////////////////////////////////////////////
        case typesPatient.PATIENTS_DELETE_DATA_LOADING:
            return {
                ...state,
                isError: false,
                error: '',
                isSucces:false,
                isLoadingsAttentes: true,
                isDeleteLoading: true,
                lastDemandeExamen: {},
            }
          case typesPatient.PATIENTS_DELETE_DATA_SUCCESS:
            return {
                ...state,
                isSucces:true,
                isLoadingsAttentes: false,
                isDeleteLoading: false,
                patients: state.patients.filter(data => data.id !== action.payload),
                lastDemandeExamen: {},
            }
        case typesPatient.PATIENTS_DELETE_DATA_IMPOSSIBLE_SUCCESS:
            return {
                ...state,
                isLoadingsAttentes: false,
                isDeleteLoading: false,
                isSucces:false,
                isErrorSuppression: true,
                error: action.payload,
                lastDemandeExamen: {},
            }
        case typesPatient.PATIENTS_DELETE_DATA_FAILED:
            return {
                ...state,
                isLoadingsAttentes: false,
                isDeleteLoading: false,
                isErrorSuppression: true,
                errors: action.payload,
                isSucces: false,
                lastDemandeExamen: {},
            };

        ///////////////////////////////////////////////////////////
        ///////////////////////////////////////////////////////////
        ///////////////////////////////////////////////////////////
        case typesPatient.PATIENTS_PROFESSION_GETS_DATA_LOADING:
            return {
                ...state,
                isProfessionLoading: true,
                isSucces: false,
                lastDemandeExamen: {},
            }
        case typesPatient.PATIENTS_PROFESSION_GETS_DATA_SUCCESS:
            return {
              ...state,
              isProfessionLoading: false,
              patientProfessions: action.payload,
            };
        case typesPatient.PATIENTS_PROFESSION_GETS_DATA_FAILED:
            return {
                ...state,
                patientProfessions: [],
                isProfessionLoading: false,
                isSucces: false,
                lastDemandeExamen: {},
            }
        
        
        ///////////////////////////////////////////////////////////
        ///////////////////////////////////////////////////////////
        ///////////////////////////////////////////////////////////
        case typesPatient.PATIENT_CARTE_GETS_DATA_LOADING:
            return {
                ...state,
                iscarteLaoding: true,
                isSucces: false,
                lastDemandeExamen: {},
            }
        case typesPatient.PATIENT_CARTE_GETS_DATA_SUCCESS:
            return {
                ...state,
                iscarteLaoding: false,
                patientCarte:action.payload,
                lastDemandeExamen: {},
            }
        case typesPatient.PATIENT_CARTE_GETS_DATA_FAILED:
              return {
                  ...state,
                  iscarteLaoding: false,
                  lastDemandeExamen: {},
              }

        ///////////////////////////////////////////////////////////
        ///////////////////////////////////////////////////////////
        ///////////////////////////////////////////////////////////
        case typesPatient.PATIENT_GET_DATA_LOADING:
            return {
                ...state,
                patient: {},
                isLoading: true,
                isSuccesGetPatient: false,
                lastDemandeExamen: {},
            }
        case typesPatient.PATIENT_GET_DATA_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccesGetPatient: true,
                patient: action.payload,
                lastDemandeExamen: {},
            };


        case typesPatient.PATIENTS_GETS_DATA_LOADING:
            return {
                ...state,
                isLoadings: true,
                isSucces: false,
                lastDemandeExamen: {},
                patientsGlobals: [],
                isSuccessPatientsGlobals: false,
                isErreurPatientsGlobals: false,
            }
        case typesPatient.PATIENTS_GETS_DATA_SUCCESS:
            return {
                ...state,
                isLoadingsAttentes: false,
                isLoadings: false,
                isDeleteLoading: false,
                patients: action.payload,
                lastDemandeExamen: {},
                patientsGlobals: [],
                isSuccessPatientsGlobals: false,
                isErreurPatientsGlobals: false,
            }

        //////////////////////////////////////////////
        //////////////////////////////////////////////
        //////////////////////////////////////////////
        case typesPatient.PATIENTS_GLOBALS_GETS_DATA_LOADING:
            return {
                ...state,
                isLoadings: true,
                isSucces: false,
                isSuccessPatientsGlobals: false,
                isErreurPatientsGlobals: false,
            }
        case typesPatient.PATIENTS_GLOBALS_GETS_DATA_SUCCESS:
            return {
                ...state,
                isLoadings: false,
                isDeleteLoading: false,
                patientsGlobals: action.payload,
                isSuccessPatientsGlobals: true,
                isErreurPatientsGlobals: false,
            }
        //////////////////////////////////////////////
        //////////////////////////////////////////////
        //////////////////////////////////////////////

      
        case typesPatient.PATIENTS_GETS_DATA_REFRESH_LOADING:
            return {
                ...state,
                isSucces: false,
                isLoadingsRefreshs: true,
                isLoadingsAttentes: false,
                lastDemandeExamen: {},
                patientsGlobals: [],
                isSuccessPatientsGlobals: false,
                isErreurPatientsGlobals: false,
            }
        case typesPatient.PATIENTS_GETS_DATA_REFRESH_SUCCESS:
            return {
                ...state,
                isLoadingsAttentes: false,
                isLoadings: false,
                isLoadingsRefreshs: false,
                isDeleteLoading: false,
                patients: action.payload,
                lastDemandeExamen: {},
                patientsGlobals: [],
                isSuccessPatientsGlobals: false,
                isErreurPatientsGlobals: false,
            }


        case typesPatient.PATIENT_GET_DATA_FAILED:
        case typesPatient.PATIENTS_GETS_DATA_FAILED:
        case typesPatient.PATIENTS_GLOBALS_GETS_DATA_FAILED:
        case typesPatient.PATIENTS_GETS_DATA_REFRESH_FAILED:
            return {
                ...state,
                patient: {},
                isSuccesGetPatient: false,
                isLoadingsRefreshs: false,
                isLoading: false,
                isLoadings: false,
                isLoadingsAttentes: false,
                isSucces: false,
                lastDemandeExamen: {},
                patientsGlobals: [],
                isSuccessPatientsGlobals: false,
                isErreurPatientsGlobals: true,
            }
        
    
        ///////////////////////////////////////////////////////////
        ///////////////////////////////////////////////////////////
        ///////////////////////////////////////////////////////////
        case typesPatient.FICHES_ORIENTATIONS_GETS_LOADING:
            return {
                ...state,
                isLoadingFichesOrientations: true,
                isSuccesFichesOrientations:false,
                lastDemandeExamen: {},
            }
        case typesPatient.FICHES_ORIENTATIONS_GETS_SUCCESS:
            return {
                ...state,
                isLoadingFichesOrientations: false,
                isSuccesFichesOrientations:true,
                fichesOrientations:action.payload,
                lastDemandeExamen: {},
            }
        case typesPatient.FICHES_ORIENTATIONS_GETS_FAILED:
            return {
                ...state,
                isLoadingFichesOrientations: false,
                isSuccesFichesOrientations:false,
                lastDemandeExamen: {},
            }

        
        ///////////////////////////////////////////////////////////
        ///////////////////////////////////////////////////////////
        ///////////////////////////////////////////////////////////
        case typesPatient.FICHES_ORIENTATIONS_GETS_REFRESH_LOADING:
            return {
                ...state,
                isLoadingFichesOrientationsRefresh: true,
                isSuccesFichesOrientations:false,
                lastDemandeExamen: {},
            }
        case typesPatient.FICHES_ORIENTATIONS_GETS_REFRESH_SUCCESS:
            return {
                ...state,
                isLoadingFichesOrientationsRefresh: false,
                isSuccesFichesOrientations:true,
                fichesOrientations:action.payload,
                lastDemandeExamen: {},
            }
        case typesPatient.FICHES_ORIENTATIONS_GETS_REFRESH_FAILED:
            return {
                ...state,
                isLoadingFichesOrientationsRefresh: false,
                isSuccesFichesOrientations:false,
                lastDemandeExamen: {},
            }
    
        ///////////////////////////////////////////////////////////
        ///////////////////////////////////////////////////////////
        ///////////////////////////////////////////////////////////
        case typesPatient.FICHES_ORIENTATIONS_BY_PATIENT_GETS_LOADING:
            return {
                ...state,
                isLoadingFichesOrientationsByPatient: false,
                isSuccesFichesOrientationsByPatient:false,
                lastDemandeExamen: {},
            };
        case typesPatient.FICHES_ORIENTATIONS_BY_PATIENT_GETS_SUCCESS:
            return {
                ...state,
                isLoadingFichesOrientationsByPatient: false,
                isSuccesFichesOrientationsByPatient:true,
                fichesOrientationsByPatient:action.payload,
                lastDemandeExamen: {},
            }
        case typesPatient.FICHES_ORIENTATIONS_BY_PATIENT_GETS_FAILED:
            return {
                ...state,
                isLoadingFichesOrientationsByPatient: false,
                isSuccesFichesOrientationsByPatient:false,
                lastDemandeExamen: {},
            }


        ///////////////////////////////////////////////////////////
        ///////////////////////////////////////////////////////////
        ///////////////////////////////////////////////////////////
        case typesPatient.ASSURANCE_GET_LOADING:
            return {
                ...state,
                isLoadingAssuranceGet: true,
                isSuccesAssuranceGet:false,
                lastDemandeExamen: {},
            };
        case typesPatient.ASSURANCE_GET_SUCCESS:
            return {
                ...state,
                isLoadingAssuranceGet: false,
                isSuccesAssuranceGet:true,
                assuranceGet:action.payload,
                lastDemandeExamen: {},
            }
        case typesPatient.ASSURANCE_GET_FAILED:
            return {
                ...state,
                isLoadingAssuranceGet: false,
                isSuccesAssuranceGet:false,
                lastDemandeExamen: {},
            }

        case typesPatient.ASSURANCES_GETS_LOADING:
            return {
                ...state,
                isLoadingAssurancesData: true,
                isSuccesAssurancesData:false,
                lastDemandeExamen: {},
            };
        case typesPatient.ASSURANCES_GETS_SUCCESS:
            return {
                ...state,
                isLoadingAssurancesData: false,
                isSuccesAssurancesData:true,
                assurancesData:action.payload,
                lastDemandeExamen: {},
            }
        case typesPatient.ASSURANCES_GETS_FAILED:
            return {
                ...state,
                isLoadingAssurancesData: false,
                isSuccesAssurancesData:false,
                lastDemandeExamen: {},
            }

        case typesPatient.ASSURANCES_REFRESH_GETS_LOADING:
            return {
                ...state,
                isLoadingAssurancesRefreshData: true,
                isSuccesAssurancesRefreshData:false,
                lastDemandeExamen: {},
            };
        case typesPatient.ASSURANCES_REFRESH_GETS_SUCCESS:
            return {
                ...state,
                isLoadingAssurancesRefreshData: false,
                isSuccesAssurancesRefreshData:true,
                assurancesData:action.payload,
                lastDemandeExamen: {},
            }
        case typesPatient.ASSURANCES_REFRESH_GETS_FAILED:
            return {
                ...state,
                isLoadingAssurancesRefreshData: false,
                isSuccesAssurancesRefreshData:false,
                lastDemandeExamen: {},
            }

        case typesPatient.ASSURANCES_CENTRE_POST_LOADING:
            return {
                ...state,
                isLoadingAssurancesPost: true,
                isSuccesAssurancesPost:false,
                errorPostAssurance: "",
                isErrorPostAssurance: false,
                lastDemandeExamen: {},
            };
        case typesPatient.ASSURANCES_CENTRE_POST_SUCCESS:
            return {
                ...state,
                isLoadingAssurancesPost: false,
                isSuccesAssurancesPost:true,
                assurancesPost:action.payload,
                errorPostAssurance: "",
                isErrorPostAssurance: false,
                lastDemandeExamen: {},
            }
        case typesPatient.ASSURANCES_CENTRE_POST_FAILED:
            return {
                ...state,
                isLoadingAssurancesPost: false,
                isSuccesAssurancesPost:false,
                errorPostAssurance: action.payload,
                isErrorPostAssurance: true,
                lastDemandeExamen: {},
            }

        case typesPatient.ASSURANCES_CENTRE_PUT_LOADING:
            return {
                ...state,
                isErrorAssurancePut: false,
                isLoadingAssurancesPut: true,
                isSuccesAssurancesPut:false,
                errorAssurance: "",
                lastDemandeExamen: {},
            };
        case typesPatient.ASSURANCES_CENTRE_PUT_SUCCESS:
            return {
                ...state,
                isErrorAssurancePut: false,
                isLoadingAssurancesPut: false,
                isSuccesAssurancesPut:true,
                assurancesPut:action.payload,
                errorAssurance: "",
                lastDemandeExamen: {},
            }
        case typesPatient.ASSURANCES_CENTRE_PUT_FAILED:
            return {
                ...state,
                isErrorAssurancePut: false,
                errorAssurance: action.payload,
                isLoadingAssurancesPut: false,
                isSuccesAssurancesPut:false,
                lastDemandeExamen: {},
            }
        
        case typesPatient.ASSURANCE_CENTRE_DELETE_LOADING:
            return {
                ...state,
                isLoadingAssuranceDelete: true,
                isSuccesAssuranceDelete: false,
                errorAssuranceDelete:false,
                isErrorAssuranceDelete:"",
                isErrorAssuranceImpossibleDelete:false,
                lastDemandeExamen: {},
            };
        case typesPatient.ASSURANCE_CENTRE_DELETE_SUCCESS:
            return {
                ...state,
                isLoadingAssuranceDelete: false,
                isSuccesAssuranceDelete: true,
                errorAssuranceDelete:false,
                assurancesData: state.assurancesData.filter(data => data?.id !== action.payload),
                isErrorAssuranceDelete:"",
                isErrorAssuranceImpossibleDelete:false,
                lastDemandeExamen: {},
            }
        case typesPatient.ASSURANCE_CENTRE_DELETE_IMPOSSIBLE:
            return {
                ...state,
                isLoadingAssuranceDelete: false,
                isSuccesAssuranceDelete: false,
                errorAssuranceDelete: action.payload,
                isErrorAssuranceImpossibleDelete:true,
                isErrorAssuranceDelete: false,
                lastDemandeExamen: {},
            }
        case typesPatient.ASSURANCE_CENTRE_DELETE_FAILED:
            return {
                ...state,
                isLoadingAssuranceDelete: false,
                isSuccesAssuranceDelete: false,
                errorAssuranceDelete: action.payload,
                isErrorAssuranceDelete: true,
                isErrorAssuranceImpossibleDelete:false,
                lastDemandeExamen: {},
            }

        case typesPatient.ASSURANCES_ALL_GETS_LOADING:
            return {
                ...state,
                isLoadingAssurancesAllData: true,
                isSuccesAssurancesAllData:false,
                lastDemandeExamen: {},
            };
        case typesPatient.ASSURANCES_ALL_GETS_SUCCESS:
            return {
                ...state,
                isLoadingAssurancesAllData: false,
                isSuccesAssurancesAllData:true,
                assurancesAllData:action.payload,
                lastDemandeExamen: {},
            }
        case typesPatient.ASSURANCES_ALL_GETS_FAILED:
            return {
                ...state,
                isLoadingAssurancesAllData: false,
                isSuccesAssurancesAllData:false,
                lastDemandeExamen: {},
            }
        
        ///////////////////////////////////////////////////////////
        ///////////////////////////////////////////////////////////
        ///////////////////////////////////////////////////////////
        case typesPatient.PATIENT_POST_DATA_LOADING:
            return {
                ...state,
                isLoadingRegiter:true,
                patient:{},
                isError: false,
                isErrorsImpossibleCreate:false,
                isErrorOperation:false,
                errorOperation:'',
                errors: {},
                isSucces: false,
                lastDemandeExamen: {},
            }
        case typesPatient.PATIENT_POST_DATA_FAILED:
            return {
                ...state,
                isLoadingRegiter:typeof action.payload === 'undefined' ? false : false,
                isError: typeof action.payload === 'undefined' ? false : true,
                isErrorsImpossibleCreate: typeof action.payload === 'undefined' ? false : false,
                errors: typeof action.payload === 'undefined' ? null : action.payload,
                isErrorOperation:true,
                errorOperation:typeof action.payload === 'undefined' ? null : action.payload,
                error:'',
                isSucces: false,
                lastDemandeExamen: {},
            }

        case typesPatient.PATIENT_POST_404:
            return {
                ...state,
                isLoadingRegiter: false,
                badRequestError: true,
                badRequestMsg: action?.payload,
            }
        case typesPatient.PATIENT_POST_DATA_SUCCESS:
            return {
                ...state,
                patient: action.payload,
                isLoadingsAttentes: true,
                isLoadingRegiter:false,
                isError: false,
                isErrorsImpossibleCreate:false,
                isErrorOperation:false,
                errorOperation:'',
                errors: {},
                error:'',
                isSucces: true,
                lastDemandeExamen: {},
            }
        case typesPatient.PATIENT_POST_DATA_IMPOSSIBLE_SUCCESS:
            return {
                ...state,
                isErrorsImpossibleCreate: true,
                isErrorOperation:false,
                isLoadingsAttentes: false,
                errorOperation:'',
                isLoadingRegiter:false,
                error: action.payload,
                isSucces: false,
                lastDemandeExamen: {},
            }
        
        
        
        case typesPatient.PATIENT_POST_UPLOAD_DATA_LOADING:
            return {
                ...state,
                isLoadingUpload:true,
                patientUpload:{},
                isErrorUpload: false,
                isErrorsImpossibleUpload:false,
                errorsUpload: {},
                isSuccesUpload: false,
            }
        case typesPatient.PATIENT_POST_UPLOAD_DATA_FAILED:
            return {
                ...state,
                isLoadingUpload: false,
                patientUpload:{},
                isSuccesUpload: false,
                isErrorUpload: typeof action.payload === 'undefined' ? false : true,
                isErrorsImpossibleUpload: false,
                errorsUpload: typeof action.payload === 'undefined' ? null : action.payload,
            }
        case typesPatient.PATIENT_POST_UPLOAD_DATA_SUCCESS:
            return {
                ...state,
                isLoadingUpload:false,
                patientUpload: action.payload,
                isSuccesUpload: true,
                isErrorUpload: false,
                isErrorsImpossibleUpload:false,
                errorsUpload: {},
            }
        case typesPatient.PATIENT_POST_UPLOAD_DATA_IMPOSSIBLE_SUCCESS:
            return {
                ...state,
                isLoadingUpload:false,
                patientUpload:{},
                isSuccesUpload: false,
                isErrorsImpossibleUpload: true,
                isErrorUpload:false,
                errorsUpload: action.payload,
            }
        
        
        ///////////////////////////////////////////////////////////
        ///////////////////////////////////////////////////////////
        ///////////////////////////////////////////////////////////
        case typesPatient.PATIENT_PUT_DATA_LOADING:
            return {
                ...state,
                isLoading: true,
                isLoadingsAttentes: true,
                isSuccesUpdate: false,
                putError: null,
                isPutError:false,
                patientPut:{},
                isSuccesGetPatient: false,
                lastDemandeExamen: {},
            };
        case typesPatient.PATIENT_PUT_DATA_SUCCESS:
            return {
                ...state,
                patientPut:action.payload,
                isLoading: false,
                isSuccesUpdate: true,
                putError: null,
                isPutError:false,
                lastDemandeExamen: {},
            };
        case typesPatient.PATIENT_PUT_DATA_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccesUpdate: false,
                putError: action.payload,
                isPutError:true,
                patientPut:{},
                lastDemandeExamen: {},
            };
        case typesPatient.PATIENT_PUT_ERROR_DATA_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccesUpdate: false,
                putError: action.payload,
                isPutError:true,
                patientPut:{},
                lastDemandeExamen: {},
            }
        
        case typesPatient.GET_LAST_DEMANDE_EXAMEN_REQUEST:
            return {
                ...state,
                lastDemandeExamen: {},
                isLoadingLastExamen: true,
            };
        case typesPatient.GET_LAST_DEMANDE_EXAMEN_SUCCESS:
            return {
                ...state,
                lastDemandeExamen: action.payload,
                isLoadingLastExamen: false,
            };
        case typesPatient.GET_LAST_DEMANDE_EXAMEN_FAILED:
            return {
                ...state,
                lastDemandeExamen: action.payload,
                isLoadingLastExamen: false,
            }

      
        ///////////////////////////////////////////////////////////
        ///////////////////////////////////////////////////////////
        ///////////////////////////////////////////////////////////
        case typesPatient.PATIENT_SET_SUCCESS:
            return {
                ...state,
                isSucces: false,
                isError: false,
                isErrorsImpossibleCreate:false,
                isSuccesGetPatient: false,
                error:'',
                errors:{},
                isErrorSuppression:false,
                isSuccesUpdate:false,
                isErrorOperation:false,
                errorOperation:'',
                putError:"",
                isPutError:false,
                isSuccesAssurancesPost:false,
                assurancesPost:null,
                isLoadingAssurancesPut: false,
                isSuccesAssurancesPut: false,
                assurancesPut:"",
                isLoadingAssuranceGet: false,
                isSuccesAssuranceGet: false,
                assuranceGet:{},
                errorAssurance: "",
                isErrorAssurance: false,
                isLoadingAssuranceDelete: false,
                isSuccesAssuranceDelete: false,
                errorAssuranceDelete:false,
                isErrorAssuranceDelete: false,
                isErrorAssuranceImpossibleDelete:false,
                errorPostAssurance: "",
                isErrorPostAssurance: false,

                lastDemandeExamen: {},
                isLoadingLastExamen: true,

                // patientsGlobals: [],
                isSuccessPatientsGlobals: false,
                isErreurPatientsGlobals: false,

                isLoadingUpload:false,
                patientUpload:{},
                isErrorUpload: false,
                isErrorsImpossibleUpload:false,
                errorsUpload: {},
                isSuccesUpload: false,

                badRequestError: false,
                badRequestMsg: "",
            }
        
        ///////////////////////////////////////////////////////////
        ///////////////////////////////////////////////////////////
        ///////////////////////////////////////////////////////////
        case typesUsers.AUTH_ERROR:
        case typesUsers.LOGOUT_SUCCESS:
        case typesUsers.TOKEN_REFRESH_FAIL:
            localStorage.removeItem("token_access");
            localStorage.removeItem("token_refresh");
            return {
                ...state,
                patient: {},
                patientPut: {},
                patientCarte:[],
                patientProfessions: [],
                patientParents: [],
                patients: [],
                fichesOrientations:[],
                fichesOrientationsByPatient:[],
                patientPaymentSlips : [],
                assurancesData:[],
                assurancesAllData:[],
                assurancesPost:null,
                assurancesPut:"",

                patientsGlobals: [],
                isSuccessPatientsGlobals: false,
                isErreurPatientsGlobals: false,
                
                isLoading: false,
                isDeleteLoading: false,
                isProfessionLoading: false,
                isParentLoading: false,
                iscarteLaoding:false,
                isLoadings: false,
                isLoadingsRefreshs: false,
                isLoadingAssurancesData:false,
                isLoadingAssurancesRefreshData:false,
                isLoadingAssurancesAllData:false,
                isLoadingAssurancesPost: false,
                isLoadingAssurancesPut: false,

                isError: false,
                isErrorsImpossibleCreate:false,
                isErrorOperation:false,

                isSuccesGetPatient: false,
                isLoadingFichesOrientations:false,
                isSuccesFichesOrientations:false,
                isLoadingFichesOrientationsRefresh: false,

                isLoadingFichesOrientationsByPatient: false,
                isSuccesFichesOrientationsByPatient:false,

                errors: {},
                error:'',
                errorOperation:"",
                isErrorSuppression:false,
                isLoadingRegiter:false,
                isSuccesAssurancesData:false,
                isSuccesAssurancesRefreshData:false,
                isSuccesAssurancesAllData:false,
                isSuccesAssurancesPost:false,
                isSuccesAssurancesPut: false,
                isSucces: false,
                isSuccesUpdate: false,
                putError: null,
                isPutError: false,
                isLoadingsAttentes: false,

                isLoadingAssuranceGet: false,
                isSuccesAssuranceGet: false,
                assuranceGet:{},
                errorAssurance: "",
                isErrorAssurance: false,

                isLoadingAssuranceDelete: false,
                isSuccesAssuranceDelete: false,
                errorAssuranceDelete:false,
                isErrorAssuranceDelete: "",
                isErrorAssuranceImpossibleDelete:false,
                errorPostAssurance: "",
                isErrorPostAssurance: false,
                lastDemandeExamen: {},

                isLoadingLastExamen: false,

                isLoadingUpload:false,
                patientUpload:{},
                isErrorUpload: false,
                isErrorsImpossibleUpload:false,
                errorsUpload: {},
                isSuccesUpload: false,
            }

    default:
        return state
  }
}
