import React, { lazy } from "react"
import * as MyPrivateRouters from "../routes/PrivateRouter";
import { Switch } from 'react-router-dom';


const LayoutKinesitherapiePatientAConsulter = lazy(() =>
    import(
      "../../components/kinesitherapie/patientAConsulter/LayoutKinesitherapiePatientAConsulter"
    )
);
const LayoutKinesitherapieConsultationListe = lazy(() =>
import(
    "../../components/kinesitherapie/consultation/LayoutKinesitherapieConsultationListe"
)
);
const LayoutKinesitherapieDemandeHospitalisationListe = lazy(() =>
import(
    "../../components/kinesitherapie/demandeHospitalisation/LayoutKinesitherapieDemandeHospitalisationListe"
)
);
const KinesitherapieObservation = lazy(() =>
import("../../components/kinesitherapie/observation/KinesitherapieObservation")
);

const KinesitherapieObservationTerminees = lazy(() =>
    import("../../components/kinesitherapie/observationTerminees/KinesitherapieObservationTerminees")
    );

const LayoutKinesitherapieCreateConsultation = lazy(() =>
import(
    "../../components/kinesitherapie/patientAConsulterCreate/LayoutKinesitherapieCreateConsultation"
)
);

const LayoutKinesitherapieViewConsultation = lazy(() =>
import(
    "../../components/kinesitherapie/patientAConsulterDetail/LayoutKinesitherapieViewConsultation"
)
);

const LayoutKinesitherapieFeuilleDeSoins = lazy(() =>
import(
    "../../components/kinesitherapie/feuilleDeSoins/LayoutKinesitherapieFeuilleDeSoins"
)
);

const LayoutCreateConsultationSortieKinesitherapie = lazy(() =>
import(
    "../../components/kinesitherapie/patientAConsulterIssuSortie/LayoutCreateConsultationSortieKinesitherapie"
)
);

const LayoutCreateConsultationSortieDemandeHospitalisationKinesitherapie = lazy(
() =>
    import(
    "../../components/kinesitherapie/patientAConsulterIssuSortie/DemandeHospitalisation/LayoutCreateConsultationSortieDemandeHospitalisationKinesitherapie"
    )
);
const LayoutCreateConsultationSortieMiseEnObservationKinesitherapie = lazy(() =>
import(
    "../../components/kinesitherapie/patientAConsulterIssuSortie/MiseEnObservation/LayoutCreateConsultationSortieMiseEnObservationKinesitherapie"
)
);

const LayoutCreateConsultationRefereInterneKinesitherapie = lazy(() =>
import(
    "../../components/kinesitherapie/patientAConsulterIssuSortie/RefereInterne/LayoutCreateConsultationRefereInterneKinesitherapie"
)
);
const LayoutCreateConsultationRefereExterneKinesitherapie = lazy(() =>
import(
    "../../components/kinesitherapie/patientAConsulterIssuSortie/RefereExterne/LayoutCreateConsultationRefereExterneKinesitherapie"
)
);

const LayoutKinesitherapieOrdonnanceDetails = lazy(() =>
import(
    "../../components/kinesitherapie/ordonnance/detail/LayoutKinesitherapieOrdonnanceDetails"
)
);
const LayoutKinesitherapieOrdonnanceListe = lazy(() =>
import(
    "../../components/kinesitherapie/ordonnance/liste/LayoutKinesitherapieOrdonnanceListe"
)
);

const LayoutKinesitherapieHospitalisationListe = lazy(() =>
import(
    "../../components/kinesitherapie/hospitalisation/LayoutKinesitherapieHospitalisationListe"
)
);

const LayoutKinesitherapieHospitalisationConsultationCreate = lazy(() =>
import(
    "../../components/kinesitherapie/hospitalisation/consultation/LayoutKinesitherapieHospitalisationConsultationCreate"
)
);
const LayoutKinesitherapieHospitalisationConsultationSortie = lazy(() =>
import(
    "../../components/kinesitherapie/hospitalisation/issueSortie/LayoutKinesitherapieHospitalisationConsultationSortie"
)
);

const KinesitherapieRefExterne = lazy(() =>
import("../../components/kinesitherapie/refExterne/KinesitherapieRefExterne")
);
const KinesitherapieRefInterne = lazy(() =>
import("../../components/kinesitherapie/refInterne/KinesitherapieRefInterne")
);
const LayoutKinesitherapieDecesConsultationSortie = lazy(() =>
import(
    "../../components/kinesitherapie/patientAConsulterIssuSortie/IssueDeDeces/LayoutKinesitherapieDecesConsultationSortie"
)
);

const DemandeHospiObservationKinesi = lazy(() =>
import(
    "../../components/kinesitherapie/patientAConsulterIssuSortie/MiseEnObservation/DemandeHospitalisation/LayoutCreateConsultationSortieDemandeHospitalisationKinesitherapie"
)
);
const RefInterneObservationKinesi = lazy(() =>
import(
    "../../components/kinesitherapie/patientAConsulterIssuSortie/MiseEnObservation/RefereInterne/LayoutCreateConsultationRefereInterneKinesitherapie"
)
);
const RefExterneObservationKinesi = lazy(() =>
import(
    "../../components/kinesitherapie/patientAConsulterIssuSortie/MiseEnObservation/RefereExterne/LayoutCreateConsultationRefereExterneKinesitherapie"
)
);

const KinesitherapieExamensDetail = lazy(() =>
import("../../components/kinesitherapie/Examens/detail")
);
const KinesitherapieExamensList = lazy(() =>
import("../../components/kinesitherapie/Examens/list")
);
const LayoutKinesitherapieDecesListe = lazy(() =>
import(
    "../../components/kinesitherapie/Deces/Liste/LayoutKinesitherapieDecesListe"
)
);
const LayoutKinesitherapieDecesCertificat = lazy(() =>
import(
    "../../components/kinesitherapie/Deces/Certificat/LayoutKinesitherapieDecesCertificat"
)
);

const AgendaKinesitherapie = lazy(() =>
import(
    "../../components/kinesitherapie/rendezVous/agendaDetail/AgendaKinesitherapie"
)
);
const LayoutkinesitherapieCreateConsultationRendezVous = lazy(() =>
import(
    "../../components/kinesitherapie/patientAConsulterIssuSortie/RendezVous/LayoutkinesitherapieCreateConsultationRendezVous"
)
);
const LayoutKinesitherapieRdvListe = lazy(() =>
import(
    "../../components/kinesitherapie/rendezVous/Liste/LayoutKinesitherapieRdvListe"
)
);

const LayoutKinesiRdvLastConsultation = lazy(() =>
import(
    "../../components/kinesitherapie/rendezVous/LastConsultation/LayoutKinesiRdvLastConsultation"
)
);

const LayoutKinesiRdvNewConsultation = lazy(() =>
import(
    "../../components/kinesitherapie/rendezVous/NewConsultation/LayoutKinesiRdvNewConsultation"
)
);

const LayoutVoirKinesitherapieResultatsExamens = lazy(() =>
import(
    "../../components/kinesitherapie/Examens/resultatsExamensVoir/LayoutVoirMedecineGeneralResultatsExamens"
)
);

const RapportKinesitherapie = lazy(() => import("../../components/kinesitherapie/rapports/RapportKinesitherapie"));

const LayoutKinesitherapieControleMajor = lazy(() => 
import("../../components/kinesitherapie/controleMajor/LayoutKinesitherapieControleMajor")
)
  

export default function KinesitherapieRouter() {
  return (
    <Switch>
        <MyPrivateRouters.PrivateRouterKinesitherapie
            path="/kinesitherapie"
            component={LayoutKinesitherapiePatientAConsulter}
        />

        <MyPrivateRouters.PrivateRouterKinesitherapie
        path="/kinesitherapie-demande-hospitalisation"
        component={LayoutKinesitherapieDemandeHospitalisationListe}
        />

        <MyPrivateRouters.PrivateRouterKinesitherapie
        path="/kinesitherapie-hospitalisation"
        component={LayoutKinesitherapieHospitalisationListe}
        />

        <MyPrivateRouters.PrivateRouterKinesitherapie
        path="/kinesitherapie-hospitalisation-consultation/:id"
        component={
            LayoutKinesitherapieHospitalisationConsultationCreate
        }
        />

        <MyPrivateRouters.PrivateRouterKinesitherapie
        path="/kinesitherapie-hospitalisation-sortie/:id"
        component={
            LayoutKinesitherapieHospitalisationConsultationSortie
        }
        />

        <MyPrivateRouters.PrivateRouterKinesitherapie
        path="/kinesitherapie-observation-encours"
        component={KinesitherapieObservation}
        />

        <MyPrivateRouters.PrivateRouterKinesitherapie
        path="/kinesitherapie-observation-terminee"
        component={KinesitherapieObservationTerminees}
        />

        <MyPrivateRouters.PrivateRouterKinesitherapie
        path="/kinesitherapie-consultation"
        component={LayoutKinesitherapieConsultationListe}
        />

        <MyPrivateRouters.PrivateRouterKinesitherapie
        path="/kinesitherapie-ordonnance"
        component={LayoutKinesitherapieOrdonnanceListe}
        />

        <MyPrivateRouters.PrivateRouterKinesitherapie
        path="/kinesitherapie-ordonnance-voir/:id"
        component={LayoutKinesitherapieOrdonnanceDetails}
        />

        <MyPrivateRouters.PrivateRouterKinesitherapie
        path="/kinesitherapie-feuille-de-soins/:id"
        component={LayoutKinesitherapieFeuilleDeSoins}
        />

        <MyPrivateRouters.PrivateRouterKinesitherapie
        path="/kinesitherapie-rdv"
        component={LayoutKinesitherapieRdvListe}
        />

        <MyPrivateRouters.PrivateRouterKinesitherapie
        path="/kinesitherapie-rdv-last-consultation/:id"
        component={LayoutKinesiRdvLastConsultation}
        />

        <MyPrivateRouters.PrivateRouterKinesitherapie
        path="/kinesitherapie-rdv-new-consultation/:id"
        component={LayoutKinesiRdvNewConsultation}
        />

        <MyPrivateRouters.PrivateRouterKinesitherapie
        path="/kinesitherapie-consultation-sortie-rdv/:id"
        component={LayoutkinesitherapieCreateConsultationRendezVous}
        />

        <MyPrivateRouters.PrivateRouterKinesitherapie
        path="/kinesitherapie-rdv-agenda/"
        component={AgendaKinesitherapie}
        />

        <MyPrivateRouters.PrivateRouterKinesitherapie
        path="/kinesitherapie-consultation-create/:id"
        component={LayoutKinesitherapieCreateConsultation}
        />

        <MyPrivateRouters.PrivateRouterKinesitherapie
        path="/kinesitherapie-consultation-voir-detail/:id"
        component={LayoutKinesitherapieViewConsultation}
        />

        <MyPrivateRouters.PrivateRouterKinesitherapie
        path="/kinesitherapie-consultation-sortie/:id"
        component={LayoutCreateConsultationSortieKinesitherapie}
        />

        <MyPrivateRouters.PrivateRouterKinesitherapie
        path="/kinesitherapie-consultation-sortie-demande-hospitalisation/:id"
        component={
            LayoutCreateConsultationSortieDemandeHospitalisationKinesitherapie
        }
        />

        <MyPrivateRouters.PrivateRouterKinesitherapie
        path="/kinesitherapie-consultation-sortie-mise-en-observation/:id"
        component={
            LayoutCreateConsultationSortieMiseEnObservationKinesitherapie
        }
        />

        <MyPrivateRouters.PrivateRouterKinesitherapie
        path="/kinesitherapie-consultation-sortie-refere-interne/:id"
        component={LayoutCreateConsultationRefereInterneKinesitherapie}
        />

        <MyPrivateRouters.PrivateRouterKinesitherapie
        path="/kinesitherapie-consultation-sortie-refere-externe/:id"
        component={LayoutCreateConsultationRefereExterneKinesitherapie}
        />

        <MyPrivateRouters.PrivateRouterKinesitherapie
        path="/kinesitherapie-referencements-externe"
        component={KinesitherapieRefExterne}
        />

        <MyPrivateRouters.PrivateRouterKinesitherapie
        path="/kinesitherapie-referencements-interne"
        component={KinesitherapieRefInterne}
        />

        <MyPrivateRouters.PrivateRouterKinesitherapie
        path="/kinesitherapie-consultation-sortie-issue-deces/:id"
        component={LayoutKinesitherapieDecesConsultationSortie}
        />

        <MyPrivateRouters.PrivateRouterKinesitherapie
        path="/kinesitherapie-observation-consultation/:id"
        component={LayoutKinesitherapieCreateConsultation}
        />

        <MyPrivateRouters.PrivateRouterKinesitherapie
        path="/kinesitherapie-exit-observation/:id"
        component={LayoutCreateConsultationSortieKinesitherapie}
        />

        <MyPrivateRouters.PrivateRouterKinesitherapie
        path="/kinesitherapie-observation-exit-demande-hospitalisation/:id"
        component={DemandeHospiObservationKinesi}
        />

        <MyPrivateRouters.PrivateRouterKinesitherapie
        path="/kinesitherapie-observation-exit-reference-interne/:id"
        component={RefInterneObservationKinesi}
        />

        <MyPrivateRouters.PrivateRouterKinesitherapie
        path="/kinesitherapie-observation-exit-reference-externe/:id"
        component={RefExterneObservationKinesi}
        />

        <MyPrivateRouters.PrivateRouterKinesitherapie
        path="/kinesitherapie-observation-exit-issue-deces/:id"
        component={LayoutKinesitherapieDecesConsultationSortie}
        />

        <MyPrivateRouters.PrivateRouterKinesitherapie
        path="/kinesitherapie-observation-exit-rdv/:id"
        component={LayoutkinesitherapieCreateConsultationRendezVous}
        />

        <MyPrivateRouters.PrivateRouterKinesitherapie
        path="/kinesitherapie-examens-detail/:id/"
        component={KinesitherapieExamensDetail}
        />

        <MyPrivateRouters.PrivateRouterKinesitherapie
        path="/kinesitherapie-examens"
        component={KinesitherapieExamensList}
        />

        <MyPrivateRouters.PrivateRouterKinesitherapie
        path="/kinesitherapie-patients-decedes"
        component={LayoutKinesitherapieDecesListe}
        />

        <MyPrivateRouters.PrivateRouterKinesitherapie
        path="/kinesitherapie-patients-decedes-certificat/:id"
        component={LayoutKinesitherapieDecesCertificat}
        />

        <MyPrivateRouters.PrivateRouterKinesitherapie
        path="/kinesitherapie-examens-resultat-voir/:id"
        component={LayoutVoirKinesitherapieResultatsExamens}
        />

        <MyPrivateRouters.PrivateRouterKinesitherapie
        path="/kinesitherapie-rapports"
        component={RapportKinesitherapie}
        />

        <MyPrivateRouters.PrivateRouterKinesitherapie
        path="/kinesitherapie-controle-major"
        component={LayoutKinesitherapieControleMajor}
        />
    </Switch>
  )
}
