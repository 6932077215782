import * as maladiesTypes from "../types/maladiesTypes";
import { AUTH_ERROR, LOGOUT_SUCCESS, TOKEN_REFRESH_FAIL } from "../types/userTypes";

const initialState = {
    isLoading: false,
    isLoadingCategorie: false,
    maladiesData: [],

    maladiesdata: [],
    categorieMaladiesdata: [],
    isLoadingRefresh: false,

    isLoadingGet: false,
    isLoadingPost: false,
    isLoadingUpdate: false,
    isLoadingDelete: false,

    maladieGet: {},

    isSuccessGet: false,
    isSuccessPost: false,
    isSuccessUpdate: false,
    isSuccessDelete: false,

    error: "",
    isError: false,
    isError403: false,
    isError500: false,
    isErrorDelete403: false,
    isErrorDelete500: false,
};

export const maladiesReducer = (state = initialState, action) => {
  switch (action.type) {
    //actes medicaux fiches de paiement
    case maladiesTypes.MALADIES_GETS_LOADING:
      return {
        ...state,
        isLoading: true,
        error: "",
      };
    case maladiesTypes.MALADIES_GETS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        maladiesData: action.payload,
        error: "",
      };
    case maladiesTypes.MALADIES_GETS_FAILED:
      return {
        ...state,
        isLoading: false,
        maladiesData: [],
        error: action.payload,
      };


    case maladiesTypes.CATEGORIES_MALADIES_GETS_DATA_LOADING:
      return {
        ...state,
        isLoadingCategorie: true,
        error: "",
      };
    case maladiesTypes.CATEGORIES_MALADIES_GETS_DATA_SUCCESS:
      return {
        ...state,
        isLoadingCategorie: false,
        categorieMaladiesdata: action.payload,
        error: "",
      };
    case maladiesTypes.CATEGORIES_MALADIES_GETS_DATA_FAILED:
      return {
        ...state,
        isLoadingCategorie: false,
        categorieMaladiesdata: [],
        error: action.payload,
      };
    
    
    
    case maladiesTypes.MALADIE_GETS_DATA_LOADING:
        return {
            ...state,
            isLoading: true
        }
    case maladiesTypes.MALADIE_GETS_DATA_SUCCESS:
        return {
            ...state,
            isLoading: false,
            maladiesdata: action.payload
        }
    case maladiesTypes.MALADIE_GETS_DATA_FAILED:
        return {
            ...state,
            isLoading: false,        
        }

    case maladiesTypes.MALADIE_GETS_REFRESH_DATA_LOADING:
        return {
            ...state,
            isLoadingRefresh: true
        }
    case maladiesTypes.MALADIE_GETS_REFRESH_DATA_SUCCESS:
        return {
            ...state,
            isLoadingRefresh: false,
            maladiesdata: action.payload
        }
    case maladiesTypes.MALADIE_GETS_REFRESH_DATA_FAILED:
        return {
            ...state,
            isLoadingRefresh: false,
            maladiesdata: []
        }

    
    //load get commune in dpi
    case maladiesTypes.MALADIE_GET_DATA_LOADING:
        return {
            ...state,
            isLoadingGet: true,
            isSuccessGet: false,
            maladieGet: {},
            error: "",
            isError: false,
            isError403: false,
            isError500: false,
        }
    case maladiesTypes.MALADIE_GET_DATA_SUCCESS:
        return {
            ...state,
            isLoadingGet: false,
            isSuccessGet: true,
            maladieGet: action.payload,
            error: "",
            isError: false,
            isError403: false,
            isError500: false,
        }
    case maladiesTypes.MALADIE_GET_DATA_FAILED:
        return {
            ...state,
            isLoadingGet: false,
            isSuccessGet: false,
            error: action.payload,
            maladieGet: {},
            isError: true,
            isError403: false,
            isError500: false,
        }
    case maladiesTypes.MALADIE_GET_DATA_FAILED_403:
        return {
            ...state,
            isLoadingGet: false,
            isSuccessGet: false,
            maladieGet:{},
            error: action.payload,
            isError: false,
            isError403: true,
            isError500: false,
        };
    case maladiesTypes.MALADIE_GET_DATA_FAILED_500:
        return {
            ...state,
            isLoadingGet: false,
            isSuccessGet: false,
            maladieGet:{},
            error: action.payload,
            isError: false,
            isError403: false,
            isError500: true,
        };

    
    //load post commune in dpi
    case maladiesTypes.MALADIE_POST_DATA_LOADING:
        return {
            ...state,
            isLoadingPost: true,
            isSuccessPost: false,
            maladieGet: {},
            error: "",
            isError: false,
            isError403: false,
            isError500: false,
        }
    case maladiesTypes.MALADIE_POST_DATA_SUCCESS:
        return {
            ...state,
            isLoadingPost: false,
            isSuccessPost: true,
            maladieGet: action.payload,
            error: "",
            isError: false,
            isError403: false,
            isError500: false,
        }
    case maladiesTypes.MALADIE_POST_DATA_FAILED:
        return {
            ...state,
            isLoadingPost: false,
            isSuccessPost: false,
            error: action.payload,
            isError: true,
            isError403: false,
            isError500: false,
        }
    case maladiesTypes.MALADIE_POST_DATA_FAILED_403:
        return {
            ...state,
            isLoadingPost: false,
            isSuccessPost: false,
            error: action.payload,
            isError: false,
            isError403: true,
            isError500: false,
        };
    case maladiesTypes.MALADIE_POST_DATA_FAILED_500:
        return {
            ...state,
            isLoadingPost: false,
            isSuccessPost: false,
            error: action.payload,
            isError: false,
            isError403: false,
            isError500: true,
        };

    
    //load put commune in dpi
    case maladiesTypes.MALADIE_PUT_DATA_LOADING:
        return {
            ...state,
            isLoadingUpdate: true,
            isSuccessUpdate: false,
            maladieGet: {},
            error: "",
            isError: false,
            isError403: false,
            isError500: false,
        }
    case maladiesTypes.MALADIE_PUT_DATA_SUCCESS:
        return {
            ...state,
            isLoadingUpdate: false,
            isSuccessUpdate: true,
            maladieGet: action.payload,
            error: "",
            isError: false,
            isError403: false,
            isError500: false,
        }
    case maladiesTypes.MALADIE_PUT_DATA_FAILED:
        return {
            ...state,
            isLoadingUpdate: false,
            isSuccessUpdate: false,
            error: action.payload,
            isError: true,
            isError403: false,
            isError500: false,
        }
    case maladiesTypes.MALADIE_PUT_DATA_FAILED_403:
        return {
            ...state,
            isLoadingUpdate: false,
            isSuccessUpdate: false,
            error: action.payload,
            isError: false,
            isError403: true,
            isError500: false,
        };
    case maladiesTypes.MALADIE_PUT_DATA_FAILED_500:
        return {
            ...state,
            isLoadingUpdate: false,
            isSuccessUpdate: false,
            error: action.payload,
            isError: false,
            isError403: false,
            isError500: true,
        };


    case maladiesTypes.MALADIE_CLEAR_STATE:
        return {
            ...state,
            isLoadingGet: false,
            isLoadingPost: false,
            isLoadingUpdate: false,
            isLoadingDelete: false,

            maladieGet: {},

            isSuccessGet: false,
            isSuccessPost: false,
            isSuccessUpdate: false,
            isSuccessDelete: false,

            error: "",
            isError: false,
            isError403: false,
            isError500: false,
            isErrorDelete403: false,
            isErrorDelete500: false,
        }


    
    case AUTH_ERROR:
    case LOGOUT_SUCCESS:
    case TOKEN_REFRESH_FAIL:
        localStorage.removeItem('token_access')
        localStorage.removeItem('token_refresh')
        return {
            ...state,
            maladiesData: [],

            isLoadingCategorie: false,
            categorieMaladiesdata: [],
            
            maladiesdata: [],
            isLoading: false,
            isLoadingRefresh: false,

            isLoadingGet: false,
            isLoadingPost: false,
            isLoadingUpdate: false,
            isLoadingDelete: false,

            maladieGet: {},

            isSuccessGet: false,
            isSuccessPost: false,
            isSuccessUpdate: false,
            isSuccessDelete: false,

            error: "",
            isError: false,
            isError403: false,
            isError500: false,
            isErrorDelete403: false,
            isErrorDelete500: false,
        }

    default:
      return state;
  }
};
