// import { parametreTypes } from "../../types/parametreTypes";
import { statistiqueTypes } from "../../types/statistiqueTypes";
import { AUTH_ERROR, LOGOUT_SUCCESS, TOKEN_REFRESH_FAIL } from "../../types/userTypes";

const initialState = {
    isLoading: false,
    isLoadingSelected: false,
    isLoadingHistogramme: false,
    dataPersonel: {},
    dataAccueil: {},
    dataCaisse: {},
    services: [],
    dataServices: [],
    dataCaisseHistogramme: {},
    dataHistogramme: [],
    DataUtilisateurStat : [],
    DataUtilisateurStatFilter: [],
    error: "",
    
};

export const statistiqueReducers = (state = initialState, action) => {

    switch (action.type) {

        case statistiqueTypes.STAT_PERSONNEL_LIST_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case statistiqueTypes.STAT_PERSONNEL_LIST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                dataPersonel: action.payload?.data,
            };
        case statistiqueTypes.STAT_PERSONNEL_LIST_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };



            

            case statistiqueTypes.STAT_GET_UTILISATEURS_STAT_LOADING:
                return {
                    ...state,
                    isLoading: true,
                };
            case statistiqueTypes.STAT_GET_UTILISATEURS_STAT_SUCCESS:
                
                return {
                    ...state,
                    isLoading: false,
                    DataUtilisateurStat: action.payload,
                    DataUtilisateurStatFilter: action.payload,
                };
            case statistiqueTypes.STAT_GET_UTILISATEURS_STAT_FAIL:
                return {
                    ...state,
                    isLoading: false,
                    error: action.payload,
                };
            
            case statistiqueTypes.STAT_GET_UTILISATEURS_STAT_FILTER:
                return {
                    ...state,
                    isLoading: false,
                    DataUtilisateurStat: !!action.payload ? state.DataUtilisateurStatFilter?.filter(data => data?.service === action.payload) : state.DataUtilisateurStatFilter,
                }








        case statistiqueTypes.STAT_ACCUEIL_LIST_REQUEST:
            return {
                ...state,
                isLoading: true,
                dataAccueil: {},
                error: "",
            };
        case statistiqueTypes.STAT_ACCUEIL_LIST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                dataAccueil: action.payload,
                error: "",
            };
        case statistiqueTypes.STAT_ACCUEIL_LIST_FAIL:
            return {
                ...state,
                isLoading: false,
                dataAccueil: {},
                error: action.payload,
            };


        case statistiqueTypes.STAT_ACCUEIL_HISTOGRAMME_REQUEST:
            return {
                ...state,
                isLoadingHistogramme: true,
            };
        case statistiqueTypes.STAT_ACCUEIL_HISTOGRAMME_SUCCESS:
            return {
                ...state,
                isLoadingHistogramme: false,
                dataHistogramme: action.payload,
            };
        case statistiqueTypes.STAT_ACCUEIL_HISTOGRAMME_FAIL:
            return {
                ...state,
                isLoadingHistogramme: false,
                error: action.payload,
            };


        case statistiqueTypes.STAT_CAISSE_LIST_REQUEST:
            return {
                ...state,
                isLoading: true,
                dataCaisse: {},
                error: "",
            };
        case statistiqueTypes.STAT_CAISSE_LIST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                dataCaisse: action.payload,
                error: "",
            };
        case statistiqueTypes.STAT_CAISSE_LIST_FAIL:
            return {
                ...state,
                isLoading: false,
                dataCaisse: {},
                error: action.payload,
            };

        
        case AUTH_ERROR:
        case LOGOUT_SUCCESS:
        case TOKEN_REFRESH_FAIL:
            localStorage.removeItem('token_access')
            localStorage.removeItem('token_refresh')
            return {
                ...state,
                isLoading: false,
                isLoadingSelected: false,
                isLoadingHistogramme: false,
                dataPersonel: {},
                dataAccueil: {},
                dataCaisse: {},
                services: [],
                dataServices: [],
                dataHistogramme: [],
                dataCaisseHistogramme: {},
                DataUtilisateurStat: [],
                DataUtilisateurStatFilter: [],
                error: ""
            }

        case statistiqueTypes.STAT_GET_SERVICES_LOADING:
            return {
                ...state,
                isLoading: true,
            };

        case statistiqueTypes.STAT_GET_SERVICES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                services: action.payload
            };
            
        case statistiqueTypes.STAT_GET_SERVICES_FAIL:
            return {
                ...state,
                isLoading: false,
            };

        case statistiqueTypes.STAT_GET_SERVICES_SELECTED_LOADING:
            return {
                ...state,
                isLoadingSelected: true,
            };

        case statistiqueTypes.STAT_GET_SERVICES_SELECTED_SUCCESS:
            return {
                ...state,
                isLoadingSelected: false,
                dataServices: action.payload
            };
            
        case statistiqueTypes.STAT_GET_SERVICES_SELECTED_FAIL:
            return {
                ...state,
                isLoadingSelected: false,
            };

        
        case statistiqueTypes.STAT_GET_SERVICES_SELECTED_HISTOGRAMME_LOADING:
            return {
                ...state,
                isLoadingSelectedCaisse: true,
            };

        case statistiqueTypes.STAT_GET_SERVICES_SELECTED_HISTOGRAMME_SUCCESS:
            return {
                ...state,
                isLoadingSelectedCaisse: false,
                dataCaisseHistogramme: action.payload,
            };
            
        case statistiqueTypes.STAT_GET_SERVICES_SELECTED_HISTOGRAMME_FAIL:
            return {
                ...state,
                isLoadingSelectedCaisse: false,
            };
            
        default:
            return state;
    }
};
