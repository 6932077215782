import * as patient from "../types/patientInfirmerieTypes";

const initialState = {
    isLoading: false,
    patientaconsulter: [],
    patientdejaconsulter: [],
    error: "",
    isLoadingRefresh: false,
};


export const patientInfListReducers = (state = initialState, action) => {
    switch (action.type) {

        case patient.GET_ALL_PATIENT_INFIRMERIE_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: "",
            };

        case patient.GET_ALL_PATIENT_INFIRMERIE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                patientaconsulter: action.payload,
                error: "",
            };

        case patient.GET_ALL_PATIENT_INFIRMERIE_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            }
        
    
        case patient.GET_ALL_PATIENT_INFIRMERIE_REFRESH_REQUEST:
            return {
                ...state,
                isLoadingRefresh: true,
                error: "",
            };

        case patient.GET_ALL_PATIENT_INFIRMERIE_REFRESH_SUCCESS:
            return {
                ...state,
                isLoadingRefresh: false,
                patientaconsulter: action.payload,
                error: "",
            };

        case patient.GET_ALL_PATIENT_INFIRMERIE_REFRESH_FAIL:
            return {
                ...state,
                isLoadingRefresh: false,
                error: action.payload,
            }


        case patient.GET_ALL_PATIENT_DEJA_CONSULTER_INFIRMERIE_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: "",
            };

        case patient.GET_ALL_PATIENT_DEJA_CONSULTER_INFIRMERIE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                patientdejaconsulter: action.payload,
                error: "",
            };

        case patient.GET_ALL_PATIENT_DEJA_CONSULTER_INFIRMERIE_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };
        
        default:
            return state;
    }
};
