export const GET_ALL_PATIENT_INFIRMERIE_REQUEST = 'GET_ALL_PATIENT_REQUEST'
export const GET_ALL_PATIENT_INFIRMERIE_SUCCESS = 'GET_ALL_PATIENT_INFIRMERIE_SUCCESS'
export const GET_ALL_PATIENT_INFIRMERIE_FAIL = 'GET_ALL_PATIENT_INFIRMERIE_FAIL'


export const GET_ALL_PATIENT_INFIRMERIE_REFRESH_REQUEST = 'GET_ALL_PATIENT_INFIRMERIE_REFRESH_REQUEST'
export const GET_ALL_PATIENT_INFIRMERIE_REFRESH_SUCCESS = 'GET_ALL_PATIENT_INFIRMERIE_REFRESH_SUCCESS'
export const GET_ALL_PATIENT_INFIRMERIE_REFRESH_FAIL = 'GET_ALL_PATIENT_INFIRMERIE_REFRESH_FAIL'


export const UPDATE_PATIENT_INFIRMERIE_REQUEST = 'UPDATE_PATIENT_INFIRMERIE_REQUEST'
export const UPDATE_PATIENT_INFIRMERIE_SUCCESS = 'UPDATE_PATIENT_INFIRMERIE_SUCCESS'
export const UPDATE_PATIENT_INFIRMERIE_FAIL = 'UPDATE_PATIENT_INFIRMERIE_FAIL'

export const GET_ALL_PATIENT_DEJA_CONSULTER_INFIRMERIE_REQUEST = 'GET_ALL_PATIENT_DEJA_CONSULTER_REQUEST'
export const GET_ALL_PATIENT_DEJA_CONSULTER_INFIRMERIE_SUCCESS = 'GET_ALL_PATIENT_DEJA_CONSULTER_INFIRMERIE_SUCCESS'
export const GET_ALL_PATIENT_DEJA_CONSULTER_INFIRMERIE_FAIL = 'GET_ALL_PATIENT_DEJA_CONSULTER_INFIRMERIE_FAIL'

export const UPDATE_PATIENT_DEJA_CONSULTER_INFIRMERIE_REQUEST = 'UPDATE_PATIENT_DEJA_CONSULTER_INFIRMERIE_REQUEST'
export const UPDATE_PATIENT_DEJA_CONSULTER_INFIRMERIE_SUCCESS = 'UPDATE_PATIENT_DEJA_CONSULTER_INFIRMERIE_SUCCESS'
export const UPDATE_PATIENT_DEJA_CONSULTER_INFIRMERIE_FAIL = 'UPDATE_PATIENT_DEJA_CONSULTER_INFIRMERIE_FAIL'