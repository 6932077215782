export const patientAConsulterTypes = {
  PATIENT_A_CONSULTER_LIST_REQUEST: "PATIENT_A_CONSULTER_LIST_REQUEST",
  PATIENT_A_CONSULTER_LIST_SUCCESS: "PATIENT_A_CONSULTER_LIST_SUCCESS",
  PATIENT_A_CONSULTER_LIST_FAIL: "PATIENT_A_CONSULTER_LIST_FAIL",

  PATIENT_A_CONSULTER_LIST_REFRESH_REQUEST: "PATIENT_A_CONSULTER_LIST_REFRESH_REQUEST",
  PATIENT_A_CONSULTER_LIST_REFRESH_SUCCESS: "PATIENT_A_CONSULTER_LIST_REFRESH_SUCCESS",
  PATIENT_A_CONSULTER_LIST_REFRESH_FAIL: "PATIENT_A_CONSULTER_LIST_REFRESH_FAIL",

  PATIENT_A_CONSULTER_TRANSFERER_REQUEST: "PATIENT_A_CONSULTER_TRANSFERER_REQUEST",
  PATIENT_A_CONSULTER_TRANSFERER_SUCCESS: "PATIENT_A_CONSULTER_TRANSFERER_SUCCESS",
  PATIENT_A_CONSULTER_TRANSFERER_FAIL: "PATIENT_A_CONSULTER_TRANSFERER_FAIL",

  PATIENT_A_CONSULTER_CLEAR: "PATIENT_A_CONSULTER_CLEAR"
};
