export const actesTypes = {
  ACTES_LIST_REQUEST: "ACTES_LIST_REQUEST",
  ACTES_LIST_SUCCESS: "ACTES_LIST_SUCCESS",
  ACTES_LIST_FAIL: "ACTES_LIST_FAIL",

  ACTES_MEDICAUX_LIST_REQUEST: "ACTES_MEDICAUX_LIST_REQUEST",
  ACTES_MEDICAUX_LIST_SUCCESS: "ACTES_MEDICAUX_LIST_SUCCESS",
  ACTES_MEDICAUX_LIST_FAIL: "ACTES_MEDICAUX_LIST_FAIL",

  ACTES_EXAMEN_EXTERNE_REQUEST: "ACTES_EXAMEN_EXTERNE_REQUEST",
  ACTES_EXAMEN_EXTERNE_SUCCESS: "ACTES_EXAMEN_EXTERNE_SUCCESS",
  ACTES_EXAMEN_EXTERNE_FAILED: "ACTES_EXAMEN_EXTERNE_FAILED",
};
