import {
  ACTES_CHIRUGICAUX_MG_GET_FAILED,
  ACTES_CHIRUGICAUX_MG_GET_LOADING,
  ACTES_CHIRUGICAUX_MG_GET_SUCCESS,
  CONSULTATION_MG_GET_FAILED,
  CONSULTATION_MG_GET_FAILED_403,
  CONSULTATION_MG_GET_FAILED_500,
  CONSULTATION_MG_GET_LOADING,
  CONSULTATION_MG_GET_PAS_TROUVER,
  CONSULTATION_MG_GET_SUCCESS,
  CONSULTATION_MG_POST_FAILED,
  CONSULTATION_MG_POST_LOADING,
  CONSULTATION_MG_POST_SUCCESS,
  CONSULTATION_MG_RESET,
  CONSULTATION_MG_RESET_AFTER,
  ISSU_ACTES_INFIRMIERS_MG_POST_FAILED,
  ISSU_ACTES_INFIRMIERS_MG_POST_LOADING,
  ISSU_ACTES_INFIRMIERS_MG_POST_SUCCESS,
  ISSU_DEMANDE_HOSPITALISATION_MG_POST_FAILED,
  ISSU_DEMANDE_HOSPITALISATION_MG_POST_LOADING,
  ISSU_DEMANDE_HOSPITALISATION_MG_POST_SUCCESS,
  ISSU_MISE_EN_OBSERVATION_MG_POST_FAILED,
  ISSU_MISE_EN_OBSERVATION_MG_POST_LOADING,
  ISSU_MISE_EN_OBSERVATION_MG_POST_SUCCESS,
  ISSU_PRESCRIPTIONS_MG_POST_FAILED,
  ISSU_PRESCRIPTIONS_MG_POST_LOADING,
  ISSU_PRESCRIPTIONS_MG_POST_SUCCESS,
  ISSU_SORTIE_CONSULTATION_MG_POST_FAILED,
  ISSU_SORTIE_CONSULTATION_MG_POST_LOADING,
  ISSU_SORTIE_CONSULTATION_MG_POST_SUCCESS,
  MALADIES_MG_GET_FAILED,
  MALADIES_MG_GET_LOADING,
  MALADIES_MG_GET_SUCCESS,
  PATIENT_A_CONSULTER_MG_GET_FAILED,
  PATIENT_A_CONSULTER_MG_GET_LOADING,
  PATIENT_A_CONSULTER_MG_GET_SUCCESS,
  TYPES_EXAMENS_MG_GET_FAILED,
  TYPES_EXAMENS_MG_GET_LOADING,
  TYPES_EXAMENS_MG_GET_SUCCESS,
  REFERE_INTERNE_MG_POST_FAILED,
  REFERE_INTERNE_MG_POST_LOADING,
  REFERE_INTERNE_MG_POST_SUCCESS,
  REFERE_EXTERNE_MG_POST_FAILED,
  REFERE_EXTERNE_MG_POST_LOADING,
  REFERE_EXTERNE_MG_POST_SUCCESS,
  ORDONNANCES_MG_GET_FAILED, 
  ORDONNANCES_MG_GET_LOADING, 
  ORDONNANCES_MG_GET_SUCCESS,
  ORDONNANCES_MG_GET_REFRESH_FAILED, 
  ORDONNANCES_MG_GET_REFRESH_LOADING, 
  ORDONNANCES_MG_GET_REFRESH_SUCCESS, 
  ORDONNANCE_MG_GET_FAILED, 
  ORDONNANCE_MG_GET_LOADING, 
  ORDONNANCE_MG_GET_SUCCESS,

  CONSULTATION_MG_POST_TERMINER_LOADING, 
  CONSULTATION_MG_POST_TERMINER_SUCCESS, 
  CONSULTATION_MG_POST_TERMINER_FAILED,

  HOSPITALISATION_MG_GET_DATA_LOADING,
  HOSPITALISATION_MG_GET_DATA_SUCCESS,
  HOSPITALISATION_MG_GET_DATA_FAILED,

  HOSPITALISATION_MG_GET_DATA__REFRESH_LOADING,
  HOSPITALISATION_MG_GET_DATA__REFRESH_SUCCESS,
  HOSPITALISATION_MG_GET_DATA__REFRESH_FAILED,

  RESULTAT_DEMANDE_EXAMEN_MG_GET_LOADING,
  RESULTAT_DEMANDE_EXAMEN_MG_GET_SUCCESS,
  RESULTAT_DEMANDE_EXAMEN_MG_GET_FAILED,

  PRESCRIPTION_EXAM_MG_POST_LOADING, 
  PRESCRIPTION_EXAM_MG_POST_SUCCESS, 
  PRESCRIPTION_EXAM_MG_POST_FAILED,

  PRESCRIPTION_EXAM_MG_GET_LOADING, 
  PRESCRIPTION_EXAM_MG_GET_SUCCESS, 
  PRESCRIPTION_EXAM_MG_GET_FAILED,
  PRESCRIPTION_EXAM_MG_GET_PAS_TROUVER,
  COMPTE_RENDU_GETS_LOADING,
  COMPTE_RENDU_GETS_SUCCESS,
  COMPTE_RENDU_GETS_FAILED,
  COMPTE_RENDU_GET_LOADING,
  COMPTE_RENDU_GET_SUCCESS,
  COMPTE_RENDU_GET_FAILED,
  COMPTE_RENDU_POST_LOADING,
  COMPTE_RENDU_POST_SUCCESS,
  COMPTE_RENDU_POST_FAILED,
  CONSULTATION_BAD_REQUEST,
  CONSULTATION_BAD_REQUEST_CLOSE,

} from "../../types/consultationMGTypes";
import { AUTH_ERROR, LOGOUT_SUCCESS, TOKEN_REFRESH_FAIL } from "../../types/userTypes";


const initialState = {
  consulterPatientMgConstante: {},
  maladiesMg: [],
  actesChirugicauxMg: [],
  consultationPatientMg: {},
  examPrescription: {},
  typesExamens: [],
  hospitalisations:[],
  
  isLoadingGet: false,
  isLoadingGetMaladies: false,
  isLoadingGetActesChirugies: false,
  isLoadingPostConsultationMg: false,
  isLoadingPostTerminerConsultationMg: false,
  isLoadingGetConsultationMg: false,
  isLoadingPostMiseEnObservationMg: false,
  isLoadingPostDemandeHospitalisationMg: false,
  isLoadingPostActesInfirmiersMg: false,
  isLoadingPostSortieConsultationMg: false,
  isLoadingGetTypeExamenMg: false,
  isLoadingPostRefereInterneMg: false,
  isLoadingGetRefereInterneMg: false,
  isLoadingPostRefereExterneMg: false,
  isLoadingGetRefereExterneMg: false,
  isLoadingGetHospitalisationsMg: false,
  isLoadingGetHospitalisationsRefreshMg: false,
  isLoadingPostPrescription: false,
  isLoadingGetPrescription: false,



  isSuccesGet: false,
  isSuccesGetMaladies: false,
  isSuccesGetActesChirugies: false,
  isSuccesPostConsultationMg: false,
  isSuccesPostTerminerConsultationMg: false,
  isSuccesGetConsultationMg: false,
  isSuccesPostMiseEnObservationMg: false,
  isSuccesPostDemandeHospitalisationMg: false,
  isSuccesPostActesInfirmiersMg: false,
  isSuccesPostSortieConsultationMg: false,
  isSuccesGetTypeExamenMg: false,
  isSuccesPostRefereInterneMg: false,
  isSuccesGetRefereInterneMg: false,
  isSuccesPostRefereExterneMg: false,
  isSuccesGetRefereExterneMg: false,
  isNonfoundConsultation: false,
  isNonfoundPrescription:false,
  isSuccesPostPrescription: false,
  isSuccesGetPrescription: false,

  ordonnacesMg: [],
  ordonnanceMg: [],
  isLoadingGetOrdonnancesMg:false,
  isLoadingGetOrdonnancesMgRefresh:false,
  isSuccesGetOrdonnancesMg:false,
  isSuccesGetOrdonnanceMg:false,

  isSuccesGetHospitalisationsMg: false,

  isLoadingPostCompteRendu:false,
  isSuccesPostCompteRendu:false,
  isErrorCompteRendu:false,
  compteRenduPost:{},
  isLoadingGetCompteRendu:false,
  isSuccesGetCompteRendu:false,
  compteRendu:{},
  isLoadingGetsCompteRendu:false,
  isSuccesGetsCompteRendu:false,
  comptesRendus:[],

  isError: false,
  isErrorPrescription: false,
  error: "",
  errorPrescription: "",
  isErrorPostConsultationMg: false,
  errorPostConsultationMg: "",

  isError403: false,
  isError500: false,
  badRequestError: {},
};

//eslint-disable-next-line
export default function (state = initialState, action) {
  switch(action.type) {
    case PATIENT_A_CONSULTER_MG_GET_LOADING:
      return {
        ...state,
        isLoadingGet: true,
        consulterPatientMgConstante: {},
        isLoadingPostTerminerConsultationMg: false,
        isSuccesPostTerminerConsultationMg: false,
        isError: false,
        error: "",
      };
      
    case PATIENT_A_CONSULTER_MG_GET_SUCCESS:
      return {
        ...state,
        isLoadingGet: false,
        consulterPatientMgConstante: action.payload,
        isSuccesGet: true,
        isLoadingPostTerminerConsultationMg: false,
        isSuccesPostTerminerConsultationMg: false,
        isError: false,
      };
    case PATIENT_A_CONSULTER_MG_GET_FAILED:
      return {
        ...state,
        isLoadingGet: false,
        consulterPatientMgConstante: {},
        error: action.payload,
        isSuccesGet: false,
        isLoadingPostTerminerConsultationMg: false,
        isSuccesPostTerminerConsultationMg: false,
        isError: true,
        isNonfoundConsultation: true,
      };

    case MALADIES_MG_GET_LOADING:
      return {
        ...state,
        isLoadingGetMaladies: true,
        error: "",
        isSuccesGet: false,
        isError: false,
      };
    case MALADIES_MG_GET_SUCCESS:
      return {
        ...state,
        isLoadingGetMaladies: false,
        maladiesMg: action.payload,
        error: "",
        isSuccesGet: true,
        isError: false,
      };
    case MALADIES_MG_GET_FAILED:
      return {
        ...state,
        isLoadingGetMaladies: false,
        error: action.payload,
        isSuccesGet: false,
        isError: true,
      };

    case ACTES_CHIRUGICAUX_MG_GET_LOADING:
      return {
        ...state,
        isLoadingGetActesChirugies: true,
        error: "",
        isSuccesGetActesChirugies: false,
        isError: false,
      };
    case ACTES_CHIRUGICAUX_MG_GET_SUCCESS:
      return {
        ...state,
        isLoadingGetActesChirugies: false,
        actesChirugicauxMg: action.payload,
        error: "",
        isSuccesGetActesChirugies: true,
        isError: false,
      };
    case ACTES_CHIRUGICAUX_MG_GET_FAILED:
      return {
        ...state,
        isLoadingGetActesChirugies: false,
        error: action.payload,
        isSuccesGetActesChirugies: false,
        isError: true,
      };
    
    //post without terminer
    case CONSULTATION_MG_POST_LOADING:
      return {
        ...state,
        isSuccesGetConsultationMg: false,
        isLoadingPostConsultationMg: true,
        isNonfoundConsultation:false,
        consultationPatientMg: {},
        errorPostConsultationMg: "",
        isSuccesPostConsultationMg: false,
        isLoadingPostTerminerConsultationMg: false,
        isSuccesPostTerminerConsultationMg: false,
        isErrorPostConsultationMg: false,
      };
    case CONSULTATION_MG_POST_SUCCESS:
      return {
        ...state,
        isSuccesGetConsultationMg: false,
        isLoadingPostConsultationMg: false,
        isNonfoundConsultation:false,
        consultationPatientMg: action.payload,
        errorPostConsultationMg: "",
        isSuccesPostConsultationMg: true,
        isLoadingPostTerminerConsultationMg: false,
        isSuccesPostTerminerConsultationMg: false,
        isErrorPostConsultationMg: false,
      };
    case CONSULTATION_MG_POST_FAILED:
      return {
        ...state,
        isSuccesGetConsultationMg: false,
        isLoadingPostConsultationMg: false,
        isLoadingGet: false,
        isLoadingGetMaladies: false,
        isLoadingGetActesChirugies: false,
        consultationPatientMg: {},
        errorPostConsultationMg: action.payload,
        isSuccesPostConsultationMg: false,
        isLoadingPostTerminerConsultationMg: false,
        isSuccesPostTerminerConsultationMg: false,
        isErrorPostConsultationMg: true,
      };

    case CONSULTATION_BAD_REQUEST:
      return {
        ...state,
        isSuccesGetConsultationMg: false,
        isLoadingPostConsultationMg: false,
        isLoadingGet: false,
        isLoadingGetMaladies: false,
        isLoadingGetActesChirugies: false,
        consultationPatientMg: {},
        errorPostConsultationMg: action.payload,
        isSuccesPostConsultationMg: false,
        isLoadingPostTerminerConsultationMg: false,
        isSuccesPostTerminerConsultationMg: false,
        isErrorPostConsultationMg: false,
        badRequestError: action.payload,
      };
    case CONSULTATION_BAD_REQUEST_CLOSE:
      return {
        ...state,
        badRequestError: {},
      };

      //post without and terminer
      case CONSULTATION_MG_POST_TERMINER_LOADING:
        return {
          ...state,
          isSuccesGetConsultationMg: false,
          isLoadingPostTerminerConsultationMg: true,
          isNonfoundConsultation:false,
          consultationPatientMg: {},
          error: "",
          isSuccesPostTerminerConsultationMg: false,
          isError: false,
        };
      case CONSULTATION_MG_POST_TERMINER_SUCCESS:
        return {
          ...state,
          isSuccesGetConsultationMg: false,
          isLoadingPostTerminerConsultationMg: false,
          isNonfoundConsultation:false,
          consultationPatientMg: action.payload,
          error: "",
          isSuccesPostTerminerConsultationMg: true,
          isError: false,
        };
      case CONSULTATION_MG_POST_TERMINER_FAILED:
        return {
          ...state,
          isSuccesGetConsultationMg: false,
          isLoadingPostTerminerConsultationMg: false,
          consultationPatientMg: {},
          error: action.payload,
          isSuccesPostTerminerConsultationMg: false,
          isError: true,
        };

    case CONSULTATION_MG_GET_LOADING:
      return {
        ...state,
        isLoadingGetConsultationMg: true,
        consultationPatientMg: {},
        isNonfoundConsultation:false,
        error: "",
        isSuccesGetConsultationMg: false,
        isError: false,
        isError403: false,
        isError500: false
      };
    case CONSULTATION_MG_GET_SUCCESS:
      return {
        ...state,
        isLoadingGetConsultationMg: false,
        consultationPatientMg: action.payload,
        isNonfoundConsultation:false,
        error: "",
        isSuccesGetConsultationMg: true,
        isError: false,
        isError403: false,
        isError500: false
      };
    case CONSULTATION_MG_GET_PAS_TROUVER:
      return {
        ...state,
        isLoadingGetConsultationMg: false,
        consultationPatientMg: action.payload,
        isNonfoundConsultation:true,
        error: "",
        isSuccesGetConsultationMg: false,
        isError: false,
        isError403: false,
        isError500: false
      };
    case CONSULTATION_MG_GET_FAILED:
      return {
        ...state,
        isLoadingGetConsultationMg: false,
        isNonfoundConsultation:true,
        consultationPatientMg: {},
        error: action.payload,
        isSuccesGetConsultationMg: false,
        isError: true,
        isError403: false,
        isError500: false
      };
    case CONSULTATION_MG_GET_FAILED_403:
      return {
        ...state,
        isLoadingGetConsultationMg: false,
        isNonfoundConsultation:false,
        consultationPatientMg: {},
        error: action.payload,
        isSuccesGetConsultationMg: false,
        isError403: true,
        isError500: false
      };
    case CONSULTATION_MG_GET_FAILED_500:
      return {
        ...state,
        isLoadingGetConsultationMg: false,
        isNonfoundConsultation:false,
        consultationPatientMg: {},
        error: action.payload,
        isSuccesGetConsultationMg: false,
        isError500: true,
        isError403: false
      };
        
        //actes infirmiers
        case ISSU_ACTES_INFIRMIERS_MG_POST_LOADING:
            return {
                ...state,
                isLoadingPostActesInfirmiersMg: true,
                consultationPatientMg:{},
                error: '',
                isSuccesPostActesInfirmiersMg: false,
                isError:false
            }
        case ISSU_ACTES_INFIRMIERS_MG_POST_SUCCESS:
            return {
                ...state,
                isLoadingPostActesInfirmiersMg: false,
                consultationPatientMg:action.payload,
                error: '',
                isSuccesPostActesInfirmiersMg: true,
                isError:false
            }
        case ISSU_ACTES_INFIRMIERS_MG_POST_FAILED:
            return {
                ...state,
                isLoadingPostActesInfirmiersMg: false,
                consultationPatientMg:{},
                error: action.payload,
                isSuccesPostActesInfirmiersMg: false,
                isError:true
            }
        
        
        //sortie simple de consultation
        case ISSU_SORTIE_CONSULTATION_MG_POST_LOADING:
            return {
                ...state,
                isLoadingPostSortieConsultationMg: true,
                consultationPatientMg:{},
                error: '',
                isSuccesPostSortieConsultationMg: false,
                isError:false
            }
        case ISSU_SORTIE_CONSULTATION_MG_POST_SUCCESS:
            return {
                ...state,
                isLoadingPostSortieConsultationMg: false,
                consultationPatientMg:action.payload,
                error: '',
                isSuccesPostSortieConsultationMg: true,
                isError:false
            }
        case ISSU_SORTIE_CONSULTATION_MG_POST_FAILED:
            return {
                ...state,
                isLoadingPostSortieConsultationMg: false,
                consultationPatientMg:{},
                error: action.payload,
                isSuccesPostSortieConsultationMg: false,
                isError:true
            }
        
        
        case TYPES_EXAMENS_MG_GET_LOADING:
            return {
                ...state,
                isLoadingGetTypeExamenMg:true,
                isSuccesGetTypeExamenMg:false,
            }
        case TYPES_EXAMENS_MG_GET_SUCCESS:
            return {
                ...state,
                isLoadingGetTypeExamenMg:false,
                isSuccesGetTypeExamenMg:true,
                typesExamens: action.payload
            }
        case TYPES_EXAMENS_MG_GET_FAILED:
            return {
                ...state,
                isLoadingGetTypeExamenMg:false,
                isSuccesGetTypeExamenMg:false,
            }

        case ORDONNANCES_MG_GET_LOADING:
            return {
                ...state,
                isLoadingGetOrdonnancesMg:true,
                isSuccesGetOrdonnancesMg:false,
            }
        case ORDONNANCES_MG_GET_SUCCESS:
            return {
                ...state,
                isLoadingGetOrdonnancesMg:false,
                isSuccesGetOrdonnancesMg:true,
                ordonnacesMg:action.payload
            }
        case ORDONNANCES_MG_GET_FAILED:
            return {
                ...state,
                isLoadingGetOrdonnancesMg:false,
                isSuccesGetOrdonnancesMg:false,
            }
        
        
        case ORDONNANCES_MG_GET_REFRESH_LOADING:
            return {
                ...state,
                isLoadingGetOrdonnancesMgRefresh:true,
                isSuccesGetOrdonnancesMg:false,
            }
        case ORDONNANCES_MG_GET_REFRESH_SUCCESS:
            return {
                ...state,
                isLoadingGetOrdonnancesMgRefresh:false,
                isSuccesGetOrdonnancesMg:true,
                ordonnacesMg:action.payload
            }
        case ORDONNANCES_MG_GET_REFRESH_FAILED:
            return {
                ...state,
                isLoadingGetOrdonnancesMgRefresh:false,
                isSuccesGetOrdonnancesMg:false,
                ordonnacesMg:[]
            }
        
        
        case ORDONNANCE_MG_GET_LOADING:
            return {
                ...state,
                isLoadingGetOrdonnanceMg:true,
                isSuccesGetOrdonnanceMg:false,
            }
        case ORDONNANCE_MG_GET_SUCCESS:
            return {
                ...state,
                isLoadingGetOrdonnanceMg:false,
                isSuccesGetOrdonnanceMg:true,
                ordonnanceMg:action.payload
            }
        case ORDONNANCE_MG_GET_FAILED:
            return {
                ...state,
                isLoadingGetOrdonnanceMg:false,
                isSuccesGetOrdonnanceMg:false,
            }



    //demandes d'examens
    case ISSU_DEMANDE_HOSPITALISATION_MG_POST_LOADING:
      return {
        ...state,
        isLoadingPostDemandeHospitalisationMg: true,
        consultationPatientMg: {},
        error: "",
        isSuccesPostDemandeHospitalisationMg: false,
        isError: false,
      };
    case ISSU_DEMANDE_HOSPITALISATION_MG_POST_SUCCESS:
      return {
        ...state,
        isLoadingPostDemandeHospitalisationMg: false,
        consultationPatientMg: action.payload,
        error: "",
        isSuccesPostDemandeHospitalisationMg: true,
        isError: false,
      };
    case ISSU_DEMANDE_HOSPITALISATION_MG_POST_FAILED:
      return {
        ...state,
        isLoadingPostDemandeHospitalisationMg: false,
        consultationPatientMg: {},
        error: action.payload,
        isSuccesPostDemandeHospitalisationMg: false,
        isError: true,
      };

    //prescriptions issue sortie
    case ISSU_PRESCRIPTIONS_MG_POST_LOADING:
      return {
        ...state,
        isLoadingPostConsultationMg: true,
        consultationPatientMg: {},
        error: "",
        isSuccesPostConsultationMg: false,
        isErrorPostConsultationMg: false,
        isError: false,
        errorPostConsultationMg: ""
      };
    case ISSU_PRESCRIPTIONS_MG_POST_SUCCESS:
      return {
        ...state,
        isLoadingPostConsultationMg: false,
        consultationPatientMg: action.payload,
        error: "",
        isSuccesPostConsultationMg: true,
        isErrorPostConsultationMg: false,
        errorPostConsultationMg: "",
        isError: false,
      };
    case ISSU_PRESCRIPTIONS_MG_POST_FAILED:
      return {
        ...state,
        isLoadingPostConsultationMg: false,
        consultationPatientMg: {},
        error: action.payload,
        isSuccesPostConsultationMg: false,
        isErrorPostConsultationMg: true,
        isError: true,
        errorPostConsultationMg: action.payload
      };

    //mise en observations
    case ISSU_MISE_EN_OBSERVATION_MG_POST_LOADING:
      return {
        ...state,
        isLoadingPostMiseEnObservationMg: true,
        consultationPatientMg: {},
        error: "",
        isSuccesPostMiseEnObservationMg: false,
        isError: false,
      };
    case ISSU_MISE_EN_OBSERVATION_MG_POST_SUCCESS:
      return {
        ...state,
        isLoadingPostMiseEnObservationMg: false,
        consultationPatientMg: action.payload,
        error: "",
        isSuccesPostMiseEnObservationMg: true,
        isError: false,
      };
    case ISSU_MISE_EN_OBSERVATION_MG_POST_FAILED:
      return {
        ...state,
        isLoadingPostMiseEnObservationMg: false,
        consultationPatientMg: {},
        error: action.payload,
        isSuccesPostMiseEnObservationMg: false,
        isError: true,
      };


    //Refere interne
    case REFERE_INTERNE_MG_POST_LOADING:
      return {
        ...state,
        isLoadingPostRefereInterneMg: true,
        consultationPatientMg: {},
        error: "",
        isSuccesPostRefereInterneMg: false,
        isError: false,
      };
    case REFERE_INTERNE_MG_POST_SUCCESS:
      return {
        ...state,
        isLoadingPostRefereInterneMg: false,
        consultationPatientMg: action.payload,
        error: "",
        isSuccesPostRefereInterneMg: true,
        isError: false,
      };
    case REFERE_INTERNE_MG_POST_FAILED:
      return {
        ...state,
        isLoadingPostRefereInterneMg: false,
        consultationPatientMg: {},
        error: action.payload,
        isSuccesPostRefereInterneMg: false, 
        isError: true,
      };

    //Refere externe
    case REFERE_EXTERNE_MG_POST_LOADING:
      return {
        ...state,
        isLoadingPostRefereExterneMg: true,
        consultationPatientMg: {},
        error: "",
        isSuccesPostRefereExterneMg: false,
        isError: false,
      };
    case REFERE_EXTERNE_MG_POST_SUCCESS:
      return {
        ...state,
        isLoadingPostRefereExterneMg: false,
        consultationPatientMg: action.payload,
        error: "",
        isSuccesPostRefereExterneMg: true,
        isError: false,
      };
    case REFERE_EXTERNE_MG_POST_FAILED:
      return {
        ...state,
        isLoadingPostRefereExterneMg: false,
        consultationPatientMg: {},
        error: action.payload,
        isSuccesPostRefereExterneMg: false,
        isError: true,
      };

    
    //hospitalisations
    case HOSPITALISATION_MG_GET_DATA_LOADING:
      return {
        ...state,
        isLoadingGetHospitalisationsMg: true,
        error: "",
        isSuccesGetHospitalisationsMg: false,
        isError: false,
      };
    case HOSPITALISATION_MG_GET_DATA_SUCCESS:
      return {
        ...state,
        isLoadingGetHospitalisationsMg: false,
        hospitalisations: action.payload,
        error: "",
        isSuccesGetHospitalisationsMg: true,
        isError: false,
      };
    case HOSPITALISATION_MG_GET_DATA_FAILED:
      return {
        ...state,
        isLoadingGetHospitalisationsMg: false,
        error: action.payload,
        isSuccesGetHospitalisationsMg: false,
        isError: true,
      };

    
    //hospitalisations
    case HOSPITALISATION_MG_GET_DATA__REFRESH_LOADING:
      return {
        ...state,
        isLoadingGetHospitalisationsRefreshMg: true,
        error: "",
        isSuccesGetHospitalisationsMg: false,
        isError: false,
      };
    case HOSPITALISATION_MG_GET_DATA__REFRESH_SUCCESS:
      return {
        ...state,
        isLoadingGetHospitalisationsRefreshMg: false,
        hospitalisations: action.payload,
        error: "",
        isSuccesGetHospitalisationsMg: true,
        isError: false,
      };
    case HOSPITALISATION_MG_GET_DATA__REFRESH_FAILED:
      return {
        ...state,
        isLoadingGetHospitalisationsRefreshMg: false,
        error: action.payload,
        isSuccesGetHospitalisationsMg: false,
        isError: true,
      };

      // RESULTAT_DEMANDE_EXAMEN_MG_GET_LOADING
      case RESULTAT_DEMANDE_EXAMEN_MG_GET_LOADING:
        return {
          ...state,
          isLoadingResultatDemandeExamen: true,
          isSuccesResultatDemandeExamen: false,
          isError: false,
          error: "",
        };
      case RESULTAT_DEMANDE_EXAMEN_MG_GET_SUCCESS:
        return {
          ...state,
          isLoadingResultatDemandeExamen: false,
          resultatDemandeExamen: action.payload,
          isSuccesResultatDemandeExamen: true,
          isError: false,
        };
      case RESULTAT_DEMANDE_EXAMEN_MG_GET_FAILED:
        return {
          ...state,
          isLoadingResultatDemandeExamen: false,
          resultatDemandeExamen: {},
          error: action.payload,
          isSuccesResultatDemandeExamen: false,
          isError: true,
        };


// Prescription 




      case PRESCRIPTION_EXAM_MG_GET_LOADING:
        return {
          ...state,
          isLoadingGetPrescription: true,
          examPrescription: {},
          isNonfoundPrescription:false,
          errorPrescription: "",
          isSuccesGetPrescription: false,
          isErrorPrescription: false,
        };
      case PRESCRIPTION_EXAM_MG_GET_SUCCESS:
        return {
          ...state,
          isLoadingGetPrescription: false,
          examPrescription: action.payload,
          isNonfoundPrescription:false,
          errorPrescription: "",
          isSuccesGetPrescription: true,
          isErrorPrescription: false,
        };
      case PRESCRIPTION_EXAM_MG_GET_PAS_TROUVER:
        return {
          ...state,
          isLoadingGetPrescription: false,
          examPrescription: action.payload,
          isNonfoundPrescription:true,
          errorPrescription: "",
          isSuccesGetPrescription: false,
          isErrorPrescription: false,
        };

      case PRESCRIPTION_EXAM_MG_GET_FAILED:
          return {
            ...state,
            isLoadingGetPrescription: false,
            isNonfoundPrescription:true,
            examPrescription: {},
            errorPrescription: action.payload,
            isSuccesGetPrescription: false,
            isErrorPrescription: true,
          };        

    //post without terminer
    case PRESCRIPTION_EXAM_MG_POST_LOADING:
      return {
        ...state,
        isSuccesGetPrescription: false,
        isLoadingPostPrescription: true,
        isNonfoundPrescription:false,
        examPrescription: {},
        errorPrescription: "",
        isSuccesPostPrescription: false,
        isErrorPrescription: false,
      };
    case PRESCRIPTION_EXAM_MG_POST_SUCCESS:
      return {
        ...state,
        isSuccesGetPrescription: false,
        isLoadingPostPrescription: false,
        isNonfoundPrescription:false,
        examPrescription: action.payload,
        errorPrescription: "",
        isSuccesPostPrescription: true,
        isErrorPrescription: false,
      };
    case PRESCRIPTION_EXAM_MG_POST_FAILED:
      return {
        ...state,
        isSuccesGetPrescription: false,
        isLoadingPostPrescription: false,
        examPrescription: {},
        errorPrescription: action.payload,
        isSuccesPostPrescription: false,
        isErrorPrescription: true,
      };




    case COMPTE_RENDU_GETS_LOADING:
        return {
            ...state,
            isLoadingGetsCompteRendu:true,
            isSuccesGetsCompteRendu:false,
        }
    case COMPTE_RENDU_GETS_SUCCESS:
        return {
            ...state,
            isLoadingGetsCompteRendu:false,
            isSuccesGetsCompteRendu:true,
            comptesRendus:action.payload
        }
    case COMPTE_RENDU_GETS_FAILED:
        return {
            ...state,
            isLoadingGetsCompteRendu:false,
            isSuccesGetsCompteRendu:false,
            comptesRendus:[]
        }

    
    case COMPTE_RENDU_GET_LOADING:
        return {
            ...state,
            isLoadingGetCompteRendu:true,
            isSuccesGetCompteRendu:false,
        }
    case COMPTE_RENDU_GET_SUCCESS:
        return {
            ...state,
            isLoadingGetCompteRendu:false,
            isSuccesGetCompteRendu:true,
            compteRendu:action.payload
        }
    case COMPTE_RENDU_GET_FAILED:
        return {
            ...state,
            isLoadingGetCompteRendu:false,
            isSuccesGetCompteRendu:false,
            compteRendu:{},
        }
        
      
    case COMPTE_RENDU_POST_LOADING:
        return {
            ...state,
            isLoadingPostCompteRendu:true,
            isSuccesPostCompteRendu:false,
            isErrorCompteRendu:false,
            error: null
        }
    case COMPTE_RENDU_POST_SUCCESS:
        return {
            ...state,
            isLoadingPostCompteRendu:false,
            isSuccesPostCompteRendu:true,
            compteRenduPost:action.payload,
            isErrorCompteRendu:false,
            error: null,
        }
    case COMPTE_RENDU_POST_FAILED:
        return {
            ...state,
            isLoadingPostCompteRendu:false,
            isSuccesPostCompteRendu:false,
            isErrorCompteRendu:true,
            error: action.payload,
            compteRenduPost:{},

        }

    case CONSULTATION_MG_RESET:
      return {
        ...state,
        consultationPatientMg: {},

        isLoadingGet: false,
        isLoadingGetMaladies: false,
        isLoadingGetActesChirugies: false,
        isLoadingPostConsultationMg: false,
        isLoadingGetConsultationMg: false,
        isLoadingPostMiseEnObservationMg: false,
        isLoadingPostDemandeHospitalisationMg: false,
        isLoadingPostActesInfirmiersMg: false,
        isLoadingPostSortieConsultationMg: false,
        isLoadingPostRefereInterneMg: false,
        isLoadingGetRefereInterneMg: false,
        isLoadingPostRefereExterneMg: false,
        isLoadingGetRefereExterneMg: false,

        isSuccesGet: false,
        isSuccesGetMaladies: false,
        isSuccesGetActesChirugies: false,
        isSuccesPostConsultationMg: false,
        isSuccesGetConsultationMg: false,
        isSuccesPostMiseEnObservationMg: false,
        isSuccesPostDemandeHospitalisationMg: false,
        isSuccesPostActesInfirmiersMg: false,
        isSuccesPostSortieConsultationMg: false,
        isSuccesPostRefereInterneMg: false,
        isSuccesGetRefereInterneMg: false,
        isSuccesPostRefereExterneMg: false,
        isSuccesPostPrescription: false,
        isSuccesGetRefereExterneMg: false,
        isNonfoundConsultation: false,

        isErrorPostConsultationMg: false,
        errorPostConsultationMg: "",
        isError: false,
        error: "",
        isError403: false,
        isError500: false,

        isLoadingPostCompteRendu:false,
        isSuccesPostCompteRendu:false,
        isErrorCompteRendu:false,
        compteRenduPost:{},
        isLoadingGetCompteRendu:false,
        isSuccesGetCompteRendu:false,
        compteRendu:{},
        isLoadingGetsCompteRendu:false,
        isSuccesGetsCompteRendu:false,
      };

    case CONSULTATION_MG_RESET_AFTER:
      return {
        ...state,
        isLoadingGetConsultationMg: false,

        isSuccesGetConsultationMg: false,
      };

    case AUTH_ERROR:
    case LOGOUT_SUCCESS:
    case TOKEN_REFRESH_FAIL:
      localStorage.removeItem("token_access");
      localStorage.removeItem("token_refresh");
      return {
        ...state,
        consulterPatientMgConstante: {},
        maladiesMg: [],
        actesChirugicauxMg: [],
        consultationPatientMg: {},
        typesExamens: [],
        hospitalisations:[],
        examPrescription: {},


        
        isLoadingGet: false,
        isLoadingGetMaladies: false,
        isLoadingGetActesChirugies: false,
        isLoadingPostConsultationMg: false,
        isLoadingPostTerminerConsultationMg: false,
        isLoadingGetConsultationMg: false,
        isLoadingPostMiseEnObservationMg: false,
        isLoadingPostDemandeHospitalisationMg: false,
        isLoadingPostActesInfirmiersMg: false,
        isLoadingPostSortieConsultationMg: false,
        isLoadingGetTypeExamenMg: false,
        isLoadingPostRefereInterneMg: false,
        isLoadingGetRefereInterneMg: false,
        isLoadingPostRefereExterneMg: false,
        isLoadingGetRefereExterneMg: false,
        isLoadingGetHospitalisationsMg: false,
        isLoadingGetHospitalisationsRefreshMg: false,
        isLoadingPostPrescription: false,
        isLoadingGetPrescription: false,
      

        isSuccesGet: false,
        isSuccesGetMaladies: false,
        isSuccesGetActesChirugies: false,
        isSuccesPostConsultationMg: false,
        isSuccesPostTerminerConsultationMg: false,
        isSuccesGetConsultationMg: false,
        isSuccesPostMiseEnObservationMg: false,
        isSuccesPostDemandeHospitalisationMg: false,
        isSuccesPostActesInfirmiersMg: false,
        isSuccesPostSortieConsultationMg: false,
        isSuccesGetTypeExamenMg: false,
        isSuccesPostRefereInterneMg: false,
        isSuccesGetRefereInterneMg: false,
        isSuccesPostRefereExterneMg: false,
        isSuccesGetRefereExterneMg: false,
        isNonfoundConsultation: false,
        isSuccesPostPrescription: false,
        isSuccesGetPrescription: false,
      


        ordonnacesMg: [],
        ordonnanceMg: [],
        isLoadingGetOrdonnancesMg:false,
        isLoadingGetOrdonnancesMgRefresh:false,
        isSuccesGetOrdonnancesMg:false,
        isSuccesGetOrdonnanceMg:false,

        isSuccesGetHospitalisationsMg: false,


        isLoadingPostCompteRendu:false,
        isSuccesPostCompteRendu:false,
        isErrorCompteRendu:false,
        compteRenduPost:{},
        isLoadingGetCompteRendu:false,
        isSuccesGetCompteRendu:false,
        compteRendu:{},
        isLoadingGetsCompteRendu:false,
        isSuccesGetsCompteRendu:false,
        comptesRendus:[],

        isErrorPrescription: false,
        isError: false,
        error: "",
        errorPrescription: "",
        isErrorPostConsultationMg: false,
        errorPostConsultationMg: ""
      };

    default:
      return state;
  }
}


