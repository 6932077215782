import * as regionSanitaireTypes from "../../types/regionSanitaireTypes"

import { AUTH_ERROR, LOGOUT_SUCCESS, TOKEN_REFRESH_FAIL } from "../../types/userTypes"

// import jwtDecode from "jwt-decode"

const initialState = {
    regionsanitaire: [],
    isLoading: false,
    isLoadingRefresh: false,

    isLoadingGet: false,
    isLoadingPost: false,
    isLoadingUpdate: false,
    isLoadingDelete: false,

    regionsanitaireGet: {},

    isSuccessGet: false,
    isSuccessPost: false,
    isSuccessUpdate: false,
    isSuccessDelete: false,

    error: "",
    isError: false,
    isError403: false,
    isError500: false,
    isErrorDelete403: false,
    isErrorDelete500: false,
}

// eslint-disable-next-line
export default function(state = initialState, action){
    switch (action.type) {
        case regionSanitaireTypes.REGION_SANITAIRE_GETS_DATA_LOADING:
            return {
                ...state,
                isLoading: true
            }
        case regionSanitaireTypes.REGION_SANITAIRE_GETS_DATA_SUCCESS:
            return {
                ...state,
                isLoading: false,
                regionsanitaire: action.payload
            }
        case regionSanitaireTypes.REGION_SANITAIRE_GETS_DATA_FAILED:
            return {
                ...state,
                isLoading: false,        
            }

        
        case regionSanitaireTypes.REGION_SANITAIRE_GETS_REFRESH_DATA_LOADING:
            return {
                ...state,
                isLoadingRefresh: true
            }
        case regionSanitaireTypes.REGION_SANITAIRE_GETS_REFRESH_DATA_SUCCESS:
            return {
                ...state,
                isLoadingRefresh: false,
                regionsanitaire: action.payload
            }
        case regionSanitaireTypes.REGION_SANITAIRE_GETS_REFRESH_DATA_FAILED:
            return {
                ...state,
                isLoadingRefresh: false,
                regionsanitaire: []    
            }
        
        
        //load get regionsanitaire in dpi
        case regionSanitaireTypes.REGION_SANITAIRE_GET_DATA_LOADING:
            return {
                ...state,
                isLoadingGet: true,
                isSuccessGet: false,
                regionsanitaireGet: {},
                error: "",
                isError: false,
                isError403: false,
                isError500: false,
            }
        case regionSanitaireTypes.REGION_SANITAIRE_GET_DATA_SUCCESS:
            return {
                ...state,
                isLoadingGet: false,
                isSuccessGet: true,
                regionsanitaireGet: action.payload,
                error: "",
                isError: false,
                isError403: false,
                isError500: false,
            }
        case regionSanitaireTypes.REGION_SANITAIRE_GET_DATA_FAILED:
            return {
                ...state,
                isLoadingGet: false,
                isSuccessGet: false,
                error: action.payload,
                regionsanitaireGet: {},
                isError: true,
                isError403: false,
                isError500: false,
            }
        case regionSanitaireTypes.REGION_SANITAIRE_GET_DATA_FAILED_403:
            return {
                ...state,
                isLoadingGet: false,
                isSuccessGet: false,
                regionsanitaireGet:{},
                error: action.payload,
                isError: false,
                isError403: true,
                isError500: false,
            };
        case regionSanitaireTypes.REGION_SANITAIRE_GET_DATA_FAILED_500:
            return {
                ...state,
                isLoadingGet: false,
                isSuccessGet: false,
                regionsanitaireGet:{},
                error: action.payload,
                isError: false,
                isError403: false,
                isError500: true,
            };

        
        //load post regionsanitaire in dpi
        case regionSanitaireTypes.REGION_SANITAIRE_POST_DATA_LOADING:
            return {
                ...state,
                isLoadingPost: true,
                isSuccessPost: false,
                regionsanitaireGet: {},
                error: "",
                isError: false,
                isError403: false,
                isError500: false,
            }
        case regionSanitaireTypes.REGION_SANITAIRE_POST_DATA_SUCCESS:
            return {
                ...state,
                isLoadingPost: false,
                isSuccessPost: true,
                regionsanitaireGet: action.payload,
                error: "",
                isError: false,
                isError403: false,
                isError500: false,
            }
        case regionSanitaireTypes.REGION_SANITAIRE_POST_DATA_FAILED:
            return {
                ...state,
                isLoadingPost: false,
                isSuccessPost: false,
                error: action.payload,
                isError: true,
                isError403: false,
                isError500: false,
            }
        case regionSanitaireTypes.REGION_SANITAIRE_POST_DATA_FAILED_403:
            return {
                ...state,
                isLoadingPost: false,
                isSuccessPost: false,
                error: action.payload,
                isError: false,
                isError403: true,
                isError500: false,
            };
        case regionSanitaireTypes.REGION_SANITAIRE_POST_DATA_FAILED_500:
            return {
                ...state,
                isLoadingPost: false,
                isSuccessPost: false,
                error: action.payload,
                isError: false,
                isError403: false,
                isError500: true,
            };

        
        //load put regionsanitaire in dpi
        case regionSanitaireTypes.REGION_SANITAIRE_PUT_DATA_LOADING:
            return {
                ...state,
                isLoadingUpdate: true,
                isSuccessUpdate: false,
                regionsanitaireGet: {},
                error: "",
                isError: false,
                isError403: false,
                isError500: false,
            }
        case regionSanitaireTypes.REGION_SANITAIRE_PUT_DATA_SUCCESS:
            return {
                ...state,
                isLoadingUpdate: false,
                isSuccessUpdate: true,
                regionsanitaireGet: action.payload,
                error: "",
                isError: false,
                isError403: false,
                isError500: false,
            }
        case regionSanitaireTypes.REGION_SANITAIRE_PUT_DATA_FAILED:
            return {
                ...state,
                isLoadingUpdate: false,
                isSuccessUpdate: false,
                error: action.payload,
                isError: true,
                isError403: false,
                isError500: false,
            }
        case regionSanitaireTypes.REGION_SANITAIRE_PUT_DATA_FAILED_403:
            return {
                ...state,
                isLoadingUpdate: false,
                isSuccessUpdate: false,
                error: action.payload,
                isError: false,
                isError403: true,
                isError500: false,
            };
        case regionSanitaireTypes.REGION_SANITAIRE_PUT_DATA_FAILED_500:
            return {
                ...state,
                isLoadingUpdate: false,
                isSuccessUpdate: false,
                error: action.payload,
                isError: false,
                isError403: false,
                isError500: true,
            };

        
        //load delete regionsanitaire in dpi
        case regionSanitaireTypes.REGION_SANITAIRE_DELETE_DATA_LOADING:
            return {
                ...state,
                isLoadingDelete: true,
                isSuccessDelete: false,
                error: "",
                isError: false,
                isErrorDelete403: false,
                isErrorDelete500: false,
            }
        case regionSanitaireTypes.REGION_SANITAIRE_DELETE_DATA_SUCCESS:
            return {
                ...state,
                isLoadingDelete: false,
                isSuccessDelete: true,
                regionsanitaire: state.regionsanitaire.filter(data => data?.id !== action.payload),
                error: "",
                isError: false,
                isErrorDelete403: false,
                isErrorDelete500: false,
            }
        case regionSanitaireTypes.REGION_SANITAIRE_DELETE_DATA_FAILED:
            return {
                ...state,
                isLoadingDelete: false,
                isSuccessDelete: false,
                error: action.payload,
                isError: true,
                isErrorDelete403: false,
                isErrorDelete500: false,
            }
        case regionSanitaireTypes.REGION_SANITAIRE_DELETE_DATA_FAILED_403:
            return {
                ...state,
                isLoadingDelete: false,
                isSuccessDelete: false,
                error: action.payload,
                isError: false,
                isErrorDelete403: true,
                isErrorDelete500: false,
            };
        case regionSanitaireTypes.REGION_SANITAIRE_DELETE_DATA_FAILED_500:
            return {
                ...state,
                isLoadingDelete: false,
                isSuccessDelete: false,
                error: action.payload,
                isError: false,
                isErrorDelete403: false,
                isErrorDelete500: true,
            };

        case regionSanitaireTypes.REGION_SANITAIRE_CLEAR_STATE:
            return {
                ...state,
                isLoadingGet: false,
                isLoadingPost: false,
                isLoadingUpdate: false,
                isLoadingDelete: false,

                regionsanitaireGet: {},

                isSuccessGet: false,
                isSuccessPost: false,
                isSuccessUpdate: false,
                isSuccessDelete: false,

                error: "",
                isError: false,
                isError403: false,
                isError500: false,
                isErrorDelete403: false,
                isErrorDelete500: false,
            }


        case AUTH_ERROR:
        case LOGOUT_SUCCESS:
        case TOKEN_REFRESH_FAIL:
            localStorage.removeItem('token_access')
            localStorage.removeItem('token_refresh')
            return {
                ...state,
                regionsanitaire: [],
                isLoading: false,
                isLoadingRefresh: false,

                isLoadingGet: false,
                isLoadingPost: false,
                isLoadingUpdate: false,
                isLoadingDelete: false,

                regionsanitaireGet: {},

                isSuccessGet: false,
                isSuccessPost: false,
                isSuccessUpdate: false,
                isSuccessDelete: false,

                error: "",
                isError: false,
                isError403: false,
                isError500: false,
                isErrorDelete403: false,
                isErrorDelete500: false,
            }

        default:
            return state
    }
}


