import React, { lazy } from "react"
import * as MyPrivateRouters from "../routes/PrivateRouter";
import { Switch } from 'react-router-dom';

const LayoutGynecologieOrdonnanceListe = lazy(() =>
import(
    "../../components/gynecologie/ordonnance/list/LayoutGynecologieOrdonnanceListe"
)
);
const LayoutGynecologieOrdonnanceDetail = lazy(() =>
import(
    "../../components/gynecologie/ordonnance/detail/LayoutGynecologieOrdonnanceDetail"
)
);
const LayoutGynecologiePatientAConsulter = lazy(() =>
import("../../components/gynecologie/patientAConsulter/list")
);
const LayoutGynecologieConsultationCreate = lazy(() =>
import(
    "../../components/gynecologie/patientAConsulter/create/LayoutGynecologieConsultationCreate"
)
);
const LayoutGynecologieConsultationView = lazy(() =>
import(
    "../../components/gynecologie/patientAConsulter/detail/LayoutGynecologieConsultationView"
)
);
const GynecologiePatientAConsulterIssue = lazy(() =>
import(
    "../../components/gynecologie/patientAConsulter/issue/LayoutCreateConsultationSortie"
)
);
///
const GynecologiePatientAConsulterIssueDemandeHospitalisation = lazy(() =>
import(
    "../../components/gynecologie/patientAConsulter/issue/DemandeHospitalisation/LayoutCreateConsultationSortieDemandeHospitalisation"
)
);
const GynecologiePatientAConsulterIssueMiseEnObservation = lazy(() =>
import(
    "../../components/gynecologie/patientAConsulter/issue/MiseEnObservation/LayoutCreateConsultationSortieMiseEnObservation"
)
);
const GynecologiePatientAConsulterIssueReferenceInterne = lazy(() =>
import(
    "../../components/gynecologie/patientAConsulter/issue/RefereInterne/LayoutCreateConsultationRefereInterne"
)
);
const GynecologiePatientAConsulterIssueReferenceExterne = lazy(() =>
import(
    "../../components/gynecologie/patientAConsulter/issue/RefereExterne/LayoutCreateConsultationRefereExterne"
)
);

const LayoutGynecologieConsultationListe = lazy(() =>
import(
    "../../components/gynecologie/patientDejaConsulte/LayoutGynecologieConsultationListe"
)
);
const LayoutGynecologieDemandeHospitalisationListe = lazy(() =>
import(
    "../../components/gynecologie/demandeHospitalisation/LayoutGynecologieDemandeHospitalisationListe"
)
);
const LayoutGynecologieHospitalisationListe = lazy(() =>
    import(
        "../../components/gynecologie/hospitalisation/LayoutGynecologieHospitalisationListe"
    )
);

const LayoutGynecologieHospitalisationTermineesListe = lazy(() =>
    import(
        "../../components/gynecologie/hospitalisationTerminees/LayoutGynecologieHospitalisationTermineesListe"
    )
);

const LayoutGynecologieHospitalisationConsultationCreate = lazy(() =>
import(
    "../../components/gynecologie/hospitalisation/consultation/LayoutGynecologieHospitalisationConsultationCreate"
)
);
const LayoutGynecologieHospitalisationConsultationSortie = lazy(() =>
import(
    "../../components/gynecologie/hospitalisation/issueSortie/LayoutGynecologieHospitalisationConsultationSortie"
)
);
const LayoutGynecologieFeuilleDeSoins = lazy(() =>
import(
    "../../components/gynecologie/feuilleDeSoins/LayoutGynecologieFeuilleDeSoins"
)
);

const GynecologieRefExterne = lazy(() =>
import("../../components/gynecologie/refExterne/GynecologieRefExterne")
);
const GynecologieRefInterne = lazy(() =>
import("../../components/gynecologie/refInterne/GynecologieRefInterne")
);

const LayoutGynecologieDecesConsultationSortie = lazy(() =>
import(
    "../../components/gynecologie/patientAConsulter/issue/IssueDeDeces/LayoutGynecologieDecesConsultationSortie"
)
);

const ConsultationObservationGynecologie = lazy(() =>
import(
    "../../components/gynecologie/patientAConsulter/issue/MiseEnObservation/consultation/LayoutCreateConsultation"
)
);

const observationDemandeHospitalisationGynecologie = lazy(() =>
import(
    "../../components/gynecologie/patientAConsulter/issue/MiseEnObservation/DemandeHospitalisation/LayoutCreateConsultationSortieDemandeHospitalisation"
)
);
const observationRefExterneGynecologie = lazy(() =>
import(
    "../../components/gynecologie/patientAConsulter/issue/MiseEnObservation/RefereExterne/LayoutCreateConsultationRefereExterne"
)
);
const observationRefInterneGynecologie = lazy(() =>
import(
    "../../components/gynecologie/patientAConsulter/issue/MiseEnObservation/RefereInterne/LayoutCreateConsultationRefereInterne"
)
);

const GynecologieExamensDetail = lazy(() =>
import("../../components/gynecologie/Examens/detail")
);
const GynecologieExamensList = lazy(() =>
import("../../components/gynecologie/Examens/list")
);

const GynecoObservation = lazy(() =>
import("../../components/gynecologie/observation/GynecoObservation")
);

const GynecologieObservationTerminees = lazy(() =>
    import("../../components/gynecologie/observationTerminees/GynecologieObservationTerminees")
    );

const LayoutGynecologieDecesListe = lazy(() =>
import("../../components/gynecologie/Deces/Liste/LayoutGynecologieDecesListe")
);
const LayoutGynecologieDecesCertificat = lazy(() =>
import(
    "../../components/gynecologie/Deces/Certificat/LayoutGynecologieDecesCertificat"
)
);

const GynecologieRdvAgenda = lazy(() =>
import("../../components/gynecologie/rendezVous/agendaDetail")
);
const LayoutGynecologieCreateConsultationRendezVous = lazy(() =>
import(
    "../../components/gynecologie/patientAConsulter/issue/RendezVous/LayoutGynecologieCreateConsultationRendezVous"
)
);
const LayoutGynecologieRdvListe = lazy(() =>
import("../../components/gynecologie/rendezVous/Liste/LayoutGynecologieRdvListe")
);

const LayoutGynecoRdvLastConsultation = lazy(() =>
import("../../components/gynecologie/rendezVous/LastConsultation/LayoutGynecoRdvLastConsultation")
);

const LayoutGynecoRdvNewConsultation = lazy(() =>
import("../../components/gynecologie/rendezVous/NewConsultation/LayoutGynecoRdvNewConsultation")
);

const LayoutVoirgynecologieResultatsExamens = lazy(() =>
import(
    "../../components/gynecologie/Examens/resultatsExamensVoir/LayoutVoirMedecineGeneralResultatsExamens"
)
);

const RapportGynecologie = lazy(() => import("../../components/gynecologie/rapports/RapportGynecologie"));


const LayoutGynecologieCompteRenduOperatoireListe = lazy(() =>
import("../../components/gynecologie/compteRenduOperatoire/Liste/LayoutGynecologieCompteRenduOperatoireListe")
);

const LayoutGynecologieCompteRenduOperatoireCreate = lazy(() =>
import("../../components/gynecologie/compteRenduOperatoire/Form/LayoutGynecologieCompteRenduOperatoireCreate")
);

const LayoutGynecologieCompteRenduOperatoireVoir = lazy(() =>
import("../../components/gynecologie/compteRenduOperatoire/Voir/LayoutGynecologieCompteRenduOperatoireVoir")
);

const LayoutGynecologieControleMajor = lazy(() => 
import("../../components/gynecologie/controleMajor/LayoutGynecologieControleMajor")
)
  

export default function GynecologieRouter() {
  return (
    <Switch>
        <MyPrivateRouters.PrivateRouterGynecologie
        path="/gynecologie"
        component={LayoutGynecologiePatientAConsulter}
        />

        <MyPrivateRouters.PrivateRouterGynecologie
        path="/gynecologie-consultation"
        component={LayoutGynecologieConsultationListe}
        />

        <MyPrivateRouters.PrivateRouterGynecologie
        path="/gynecologie-feuille-de-soins/:id"
        component={LayoutGynecologieFeuilleDeSoins}
        />

        <MyPrivateRouters.PrivateRouterGynecologie
        path="/gynecologie-demande-hospitalisation"
        component={LayoutGynecologieDemandeHospitalisationListe}
        />

        <MyPrivateRouters.PrivateRouterGynecologie
            path="/gynecologie-hospitalisation-encours"
            component={LayoutGynecologieHospitalisationListe}
        />

        <MyPrivateRouters.PrivateRouterGynecologie
            path="/gynecologie-hospitalisation-terminee"
            component={LayoutGynecologieHospitalisationTermineesListe}
        />

        <MyPrivateRouters.PrivateRouterGynecologie
        path="/gynecologie-hospitalisation-consultation/:id"
        component={LayoutGynecologieHospitalisationConsultationCreate}
        />

        <MyPrivateRouters.PrivateRouterGynecologie
        path="/gynecologie-hospitalisation-sortie/:id"
        component={LayoutGynecologieHospitalisationConsultationSortie}
        />

        <MyPrivateRouters.PrivateRouterGynecologie
        path="/gynecologie-consultation-sortie-rdv/:id"
        component={LayoutGynecologieCreateConsultationRendezVous}
        />

        <MyPrivateRouters.PrivateRouterGynecologie
        path="/gynecologie-rdv"
        component={LayoutGynecologieRdvListe}
        />

        <MyPrivateRouters.PrivateRouterGynecologie
        path="/gynecologie-rdv-last-consultation/:id"
        component={LayoutGynecoRdvLastConsultation}
        />

        <MyPrivateRouters.PrivateRouterGynecologie
        path="/gynecologie-rdv-new-consultation/:id"
        component={LayoutGynecoRdvNewConsultation}
        />

        <MyPrivateRouters.PrivateRouterGynecologie
        path="/gynecologie-rdv-agenda"
        component={GynecologieRdvAgenda}
        />

        <MyPrivateRouters.PrivateRouterGynecologie
        path="/gynecologie-consultation-create/:id"
        component={LayoutGynecologieConsultationCreate}
        />

        <MyPrivateRouters.PrivateRouterGynecologie
        path="/gynecologie-consultation-voir-detail/:id"
        component={LayoutGynecologieConsultationView}
        />

        <MyPrivateRouters.PrivateRouterGynecologie
        path="/gynecologie-consultation-sortie/:id"
        component={GynecologiePatientAConsulterIssue}
        />

        <MyPrivateRouters.PrivateRouterGynecologie
        path="/gynecologie-consultation-sortie-demande-hospitalisation/:id"
        component={
            GynecologiePatientAConsulterIssueDemandeHospitalisation
        }
        />

        <MyPrivateRouters.PrivateRouterGynecologie
        path="/gynecologie-consultation-sortie-mise-en-observation/:id"
        component={GynecologiePatientAConsulterIssueMiseEnObservation}
        />

        <MyPrivateRouters.PrivateRouterGynecologie
        path="/gynecologie-consultation-sortie-reference-interne/:id"
        component={GynecologiePatientAConsulterIssueReferenceInterne}
        />

        <MyPrivateRouters.PrivateRouterGynecologie
        path="/gynecologie-consultation-sortie-reference-externe/:id"
        component={GynecologiePatientAConsulterIssueReferenceExterne}
        />

        <MyPrivateRouters.PrivateRouterGynecologie
        path="/gynecologie-observation-encours"
        component={GynecoObservation}
        />

        <MyPrivateRouters.PrivateRouterGynecologie
        path="/gynecologie-observation-terminee"
        component={GynecologieObservationTerminees}
        />

        <MyPrivateRouters.PrivateRouterGynecologie
        path="/gynecologie-ordonnance"
        component={LayoutGynecologieOrdonnanceListe}
        />

        <MyPrivateRouters.PrivateRouterGynecologie
        path="/gynecologie-ordonnance-voir/:id"
        component={LayoutGynecologieOrdonnanceDetail}
        />

        <MyPrivateRouters.PrivateRouterGynecologie
        path="/gynecologie-referencements-externe"
        component={GynecologieRefExterne}
        />

        <MyPrivateRouters.PrivateRouterGynecologie
        path="/gynecologie-referencements-interne"
        component={GynecologieRefInterne}
        />

        <MyPrivateRouters.PrivateRouterGynecologie
        path="/gynecologie-consultation-sortie-observation/:id"
        component={GynecologiePatientAConsulterIssue}
        />

        <MyPrivateRouters.PrivateRouterGynecologie
        path="/gynecologie-observation-exit-deces/:id"
        component={LayoutGynecologieDecesConsultationSortie}
        />

        <MyPrivateRouters.PrivateRouterGynecologie
        path="/gynecologie-consultation-sortie-issue-deces/:id"
        component={LayoutGynecologieDecesConsultationSortie}
        />

        <MyPrivateRouters.PrivateRouterGynecologie
        path="/gynecologie-observation-consultation/:id"
        component={ConsultationObservationGynecologie}
        />

        <MyPrivateRouters.PrivateRouterGynecologie
        path="/gynecologie-observation-exit-demande-hospitalisation/:id"
        component={observationDemandeHospitalisationGynecologie}
        />

        <MyPrivateRouters.PrivateRouterGynecologie
        path="/gynecologie-observation-exit-reference-externe/:id"
        component={observationRefExterneGynecologie}
        />

        <MyPrivateRouters.PrivateRouterGynecologie
        path="/gynecologie-observation-exit-reference-interne/:id"
        component={observationRefInterneGynecologie}
        />

        <MyPrivateRouters.PrivateRouterGynecologie
        path="/gynecologie-observation-exit-rdv/:id"
        component={LayoutGynecologieCreateConsultationRendezVous}
        />

        <MyPrivateRouters.PrivateRouterGynecologie
        path="/gynecologie-examens-detail/:id/"
        component={GynecologieExamensDetail}
        />

        <MyPrivateRouters.PrivateRouterGynecologie
        path="/gynecologie-examens"
        component={GynecologieExamensList}
        />

        <MyPrivateRouters.PrivateRouterGynecologie
        path="/gynecologie-patients-decedes"
        component={LayoutGynecologieDecesListe}
        />

        <MyPrivateRouters.PrivateRouterGynecologie
        path="/gynecologie-patients-decedes-certificat/:id"
        component={LayoutGynecologieDecesCertificat}
        />

        <MyPrivateRouters.PrivateRouterGynecologie
        path="/gynecologie-examens-resultat-voir/:id"
        component={LayoutVoirgynecologieResultatsExamens}
        />

        <MyPrivateRouters.PrivateRouterGynecologie
        path="/gynecologie-compte-rendu-operatoire"
        component={LayoutGynecologieCompteRenduOperatoireListe}
        />

        <MyPrivateRouters.PrivateRouterGynecologie
        path="/gynecologie-compte-rendu-operatoire-create"
        component={LayoutGynecologieCompteRenduOperatoireCreate}
        />

        <MyPrivateRouters.PrivateRouterGynecologie
        path="/gynecologie-compte-rendu-operatoire-voir-detail/:id"
        component={LayoutGynecologieCompteRenduOperatoireVoir}
        />

        <MyPrivateRouters.PrivateRouterGynecologie
        path="/gynecologie-rapports"
        component={RapportGynecologie}
        />

        <MyPrivateRouters.PrivateRouterGynecologie
        path="/gynecologie-controle-major"
        component={LayoutGynecologieControleMajor}
        />
    </Switch>
  )
}
