import * as nationnaliteTypes from '../types/nationnaliteTypes';
import { AUTH_ERROR, LOGOUT_SUCCESS, TOKEN_REFRESH_FAIL } from '../types/userTypes';


const initialState = {
    data: [],
    showSuccessModal: false,
    isLoading: false,
    error:null
}

const executeGetDataSuccess = (state, action) => {
    return {
        ...state,
        data: action.data,
        isLoading:false,
        error: null
    }
}
const executeGetDataFailed = (state, action) => {
    return {
        ...state,
        data: action.data,
        isLoading:false,
        error: action
    }
}
const executeGetDataLoading = (state, action) => {
    return {
        ...state,
        isLoading:true,
        error: null
    }
}
const executePostDataSuccess = (state, action) => {
    return {
        ...state,
        showSuccessModal: true,
        isLoading:false
    }
}
const executePutDataSuccess = (state, action) => {
    return {
        ...state,
        showSuccessModal: true,
        isLoading:false
    }
}
const executeDeleteDataSuccess = (state, action) => {
    return {
        ...state,
        showSuccessModal: true,
        isLoading:false
    }
}

const reducer = (state = initialState, action) => {

    switch (action.type) {
        case nationnaliteTypes.NATIONNALITE_GET_DATA_SUCCESS:
            return executeGetDataSuccess(state, action);
        case nationnaliteTypes.NATIONNALITE_GET_DATA_LOADING:
            return executeGetDataLoading(state, action);
        case nationnaliteTypes.NATIONNALITE_GET_DATA_FAILED:
            return executeGetDataFailed(state, action);
        case nationnaliteTypes.NATIONNALITE_POST_DATA_SUCCESS:
            return executePostDataSuccess(state, action);
        case nationnaliteTypes.NATIONNALITE_PUT_DATA_SUCCESS:
            return executePutDataSuccess(state, action);
        case nationnaliteTypes.NATIONNALITE_DELETE_DATA_SUCCESS:
            return executeDeleteDataSuccess(state, action);
        case AUTH_ERROR:
        case LOGOUT_SUCCESS:
        case TOKEN_REFRESH_FAIL:
            localStorage.removeItem('token_access')
            localStorage.removeItem('token_refresh')
            return {
                ...state,
                data: [],
                showSuccessModal: false,
                isLoading: false,
                error:null
            }
        default:
            return state;
    }
}

export default reducer;