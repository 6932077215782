import React, { lazy } from "react"
import * as MyPrivateRouters from "../routes/PrivateRouter";
import { Switch } from 'react-router-dom';

const ControlePBFPatientsConsultes = lazy(() =>
    import("../../components/controle-pbf/patientsConsultes/ControlePBFPatientsConsultes")
);

const ControlePBFPatientsConsultesDetails = lazy(() =>
    import("../../components/controle-pbf/patientsConsultes/ControlePBFPatientsConsultesDetails")
);


const ControlePBFValides = lazy(() =>
    import("../../components/controle-pbf/controleValides/ControlePBFValides")
);

const ControlePBFValidesDetails = lazy(() =>
    import("../../components/controle-pbf/controleValides/ControlePBFValidesDetails")
);


const ControlePBFNonValides = lazy(() =>
    import("../../components/controle-pbf/controleNonValides/ControlePBFNonValides")
);

const ControlePBFNonValidesDetails = lazy(() =>
    import("../../components/controle-pbf/controleNonValides/ControlePBFNonValidesDetails")
);

const ControlePBFRapports = lazy(() =>
    import("../../components/controle-pbf/rapports/ControlePBFRapports")
);
  

export default function ControlePBFRouter() {
  return (
    <Switch>
        <MyPrivateRouters.PrivateRouterControlePBF
        path="/controle-pbf"
        component={ControlePBFPatientsConsultes}
        />

        <MyPrivateRouters.PrivateRouterControlePBF
        path="/controle-pbf-patients-consultes"
        component={ControlePBFPatientsConsultes}
        />

        <MyPrivateRouters.PrivateRouterControlePBF
        path="/controle-pbf-patients-consultes-details/:id"
        component={ControlePBFPatientsConsultesDetails}
        />

        <MyPrivateRouters.PrivateRouterControlePBF
        path="/controle-pbf-valides"
        component={ControlePBFValides}
        />

        <MyPrivateRouters.PrivateRouterControlePBF
        path="/controle-pbf-valides-details/:id"
        component={ControlePBFValidesDetails}
        />

        <MyPrivateRouters.PrivateRouterControlePBF
        path="/controle-pbf-non-valides"
        component={ControlePBFNonValides}
        />

        <MyPrivateRouters.PrivateRouterControlePBF
        path="/controle-pbf-non-valides-details/:id"
        component={ControlePBFNonValidesDetails}
        />

        <MyPrivateRouters.PrivateRouterControlePBF
        path="/controle-pbf-rapports"
        component={ControlePBFRapports}
        />
    </Switch>
  )
}
