export const CIRCUITS_PATIENTS_GET_DATA_SUCCESS = 'CIRCUITS_PATIENTS_GET_DATA_SUCCESS'

export const CIRCUITS_PATIENTS_GET_DATA_FAILED = 'CIRCUITS_PATIENTS_GET_DATA_FAILED'

export const CIRCUITS_PATIENTS_GET_DATA_LOADING = 'CIRCUITS_PATIENTS_GET_DATA_LOADING'


export const CIRCUIT_PATIENT_GET_DATA_SUCCESS = 'CIRCUIT_PATIENT_GET_DATA_SUCCESS'

export const CIRCUIT_PATIENT_GET_DATA_FAILED = 'CIRCUIT_PATIENT_GET_DATA_FAILED'

export const CIRCUIT_PATIENT_GET_DATA_LOADING = 'CIRCUIT_PATIENT_GET_DATA_LOADING'


export const CIRCUIT_PATIENT_DETAILS_GET_DATA_SUCCESS = 'CIRCUIT_PATIENT_DETAILS_GET_DATA_SUCCESS'

export const CIRCUIT_PATIENT_DETAILS_GET_DATA_FAILED = 'CIRCUIT_PATIENT_DETAILS_GET_DATA_FAILED'

export const CIRCUIT_PATIENT_DETAILS_GET_DATA_LOADING = 'CIRCUIT_PATIENT_DETAILS_GET_DATA_LOADING'


export const CIRCUITS_PATIENTS_REFRESH_GET_DATA_SUCCESS = 'CIRCUITS_PATIENTS_REFRESH_GET_DATA_SUCCESS'

export const CIRCUITS_PATIENTS_REFRESH_GET_DATA_FAILED = 'CIRCUITS_PATIENTS_REFRESH_GET_DATA_FAILED'

export const CIRCUITS_PATIENTS_REFRESH_GET_DATA_LOADING = 'CIRCUITS_PATIENTS_REFRESH_GET_DATA_LOADING'


export const DOSSIERS_PATIENT_FICHES_PAIEMENT_GET_DATA_SUCCESS = 'DOSSIERS_PATIENT_FICHES_PAIEMENT_GET_DATA_SUCCESS'

export const DOSSIERS_PATIENT_FICHES_PAIEMENT_GET_DATA_FAILED = 'DOSSIERS_PATIENT_FICHES_PAIEMENT_GET_DATA_FAILED'

export const DOSSIERS_PATIENT_FICHES_PAIEMENT_GET_DATA_LOADING = 'DOSSIERS_PATIENT_FICHES_PAIEMENT_GET_DATA_LOADING'


export const DOSSIERS_PATIENT_RECUS_PAIEMENT_GET_DATA_SUCCESS = 'DOSSIERS_PATIENT_RECUS_PAIEMENT_GET_DATA_SUCCESS'

export const DOSSIERS_PATIENT_RECUS_PAIEMENT_GET_DATA_FAILED = 'DOSSIERS_PATIENT_RECUS_PAIEMENT_GET_DATA_FAILED'

export const DOSSIERS_PATIENT_RECUS_PAIEMENT_GET_DATA_LOADING = 'DOSSIERS_PATIENT_RECUS_PAIEMENT_GET_DATA_LOADING'