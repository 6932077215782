import { suiteCoucheTypes } from "../types/SuiteCoucheTypes";

const initialState = {
    isLoading: false,
    suiteLoader: false,
    infoSuiteCoucheComplication: {},
    error: false,
    postSuccess: false,
    postLoader: false,
    consultationSuiteCoucheComplication: {},
    serverError: false,
    badRequestError: false,
    serverUnavailable: false,
}

const suiteCoucheReducer = (state = initialState, action) => {
    switch (action.type) {
        case suiteCoucheTypes.GET_INFO_SUITE_COUCHE_COMPLICATION_REQUEST:
            return {
                ...state,
                isLoading: true,
                infoSuiteCoucheComplication: {},
                postSuccess: false,
                postLoader: false,
                error: false,
                serverError: false,
                badRequestError: false,
                serverUnavailable: false,
            }
        case suiteCoucheTypes.GET_INFO_SUITE_COUCHE_COMPLICATION_SUCCESS:
            return {
                ...state,
                isLoading: false,
                infoSuiteCoucheComplication: action.payload,
                postSuccess: false,
                postLoader: false,
                error: false,
                serverError: false,
                badRequestError: false,
                serverUnavailable: false,
            }
        case suiteCoucheTypes.GET_INFO_SUITE_COUCHE_COMPLICATION_FAIL:
            return {
                ...state,
                isLoading: false,
                infoSuiteCoucheComplication: {},
                postSuccess: false,
                postLoader: false,
                error: true,
                serverError: false,
                badRequestError: false,
                serverUnavailable: false,
            }
        case suiteCoucheTypes.GET_CONSULTATION_SUITE_COUCHE_COMPLICATION_REQUEST:
            return {
                ...state,
                suiteLoader: true,
                consultationSuiteCoucheComplication: {},
                postSuccess: false,
                postLoader: false,
                error: false,
                serverError: false,
                badRequestError: false,
                serverUnavailable: false,
            }
        case suiteCoucheTypes.GET_CONSULTATION_SUITE_COUCHE_COMPLICATION_SUCCESS:
            return {
                ...state,
                suiteLoader: false,
                consultationSuiteCoucheComplication: action.payload,
                postSuccess: false,
                postLoader: false,
                error: false,
                serverError: false,
                badRequestError: false,
                serverUnavailable: false,
            }
        case suiteCoucheTypes.GET_CONSULTATION_SUITE_COUCHE_COMPLICATION_FAIL:
            return {
                ...state,
                suiteLoader: false,
                consultationSuiteCoucheComplication: {},
                postSuccess: false,
                postLoader: false,
                error: true,
                serverError: false,
                badRequestError: false,
                serverUnavailable: false,
            }
        case suiteCoucheTypes.POST_SUITE_COUCHE_COMPLICATION_REQUEST:
            return {
                ...state,
                postLoader: true,
                postSuccess: false,
                error: false,
                serverError: false,
                badRequestError: false,
                serverUnavailable: false,
            }
        case suiteCoucheTypes.POST_SUITE_COUCHE_COMPLICATION_SUCCESS:
            return {
                ...state,
                postLoader: false,
                postSuccess: true,
                error: false,
                serverError: false,
                badRequestError: false,
                serverUnavailable: false,
            }
        case suiteCoucheTypes.POST_SUITE_COUCHE_COMPLICATION_SERVER_ERROR:
            return {
                ...state,
                postLoader: false,
                serverError: true,
                badRequestError: false,
                serverUnavailable: false,
            }
        case suiteCoucheTypes.POST_SUITE_COUCHE_COMPLICATION_BAD_REQUEST:
            return {
                ...state,
                postLoader: false,
                serverError: false,
                badRequestError: true,
                serverUnavailable: false,
            }
        case suiteCoucheTypes.POST_SUITE_COUCHE_COMPLICATION_SERVER_UNAVAILABLE:
            return {
                ...state,
                postLoader: false,
                serverError: false,
                badRequestError: false,
                serverUnavailable: true,
            }
        case suiteCoucheTypes.CLEAR_POST_SUCCESS_POPUP:
            return {
                ...state,
                postSuccess: false,
            }
        case suiteCoucheTypes.CLEAR_POST_ERROR_POPUP:
            return {
                ...state,
                serverError: false,
                badRequestError: false,
                serverUnavailable: false,
            }
            
        default:
            return state;
    }
}

export default suiteCoucheReducer;