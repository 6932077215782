import { patientAConsulterPartogrammesTypes } from "../../types/partogrammeTypesListe";

const initialState = {
    isLoading: false,
    DataPatienteListes: [],
    DataProfessionnelsListes: [],
    error: "",
};

const initialStateInfoReducer = {
    isLoading: false,
    DataInfoReducers: [],
    error: "",
};

const initialStatePartoTermiInfoReducer = {
    isLoading: false,
    DataListePartoTermi: [],
    error: "",
};



const initialStateInfoPartogrammeReducers = {
    isLoading: false,
    DataInfoParto: [],
    error: "",
};
//eslint-disable-next-line
const initialStateCreatePartoReducer = {
    isLoading: false,
    DataPartoCreate: [],
    error: "",
};



const initialStatePosteListeEquipe = {
    isLoading: false,
    DataListeEquipe: [],
    error: "",
};

const initialPartogrammeCreateStar = {
    isLoading: false,
    DataCreatepartogrammeStart: [],
    error: "",
};

const terminerPartogramme = {
    isLoading: false,
    DataTerminerPartogramme: [],
    error: "",
};


export const partogrammeReducers = (state = initialState, action) => {

    switch (action.type) {

        case patientAConsulterPartogrammesTypes.GET_PATIENTE_PARTOGRAMME_LIST_A_CONSULTER_REQUEST:
            return {
                ...state,
                isLoading: true,
                DataProfessionnelsListes : []
            };



        case patientAConsulterPartogrammesTypes.GET_PATIENTE_PARTOGRAMME_LIST_A_CONSULTER_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                DataProfessionnelsListes : []
            };





        case patientAConsulterPartogrammesTypes.GET_PATIENTE_PARTOGRAMME_LIST_A_CONSULTER_SUCCEDED:
            return {
                ...state,
                isLoading: false,
                DataPatienteListes: action.payload,
            };






        case patientAConsulterPartogrammesTypes.GET_PATIENTE_PARTOGRAMME_LIST_PROFESSIONELS_CENTRE_REQUEST:
            return {
                ...state,
                isLoading: true,
                DataProfessionnelsListes : []
            };



        case patientAConsulterPartogrammesTypes.GET_PATIENTE_PARTOGRAMME_LIST_PROFESSIONELS_CENTRE_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                DataProfessionnelsListes : []
            };





        case patientAConsulterPartogrammesTypes.GET_PATIENTE_PARTOGRAMME_LIST_PROFESSIONELS_CENTRE_SUCCEDED:
            return {
                ...state,
                isLoading: false,
                DataProfessionnelsListes: action.payload,
            };



            //Action get Info patient
            



        default:
            return state;
    }
}


export const InforPatientPartoReducer = (state=initialStateInfoReducer, action) => {

    switch (action.type) {
        case patientAConsulterPartogrammesTypes.GET_PATIENTE_PARTOGRAMME_INFO_PATIENT_REQUEST:
            return {
                ...state,
                isLoading: true,
                DataInfoReducers : []
                
            };
        case patientAConsulterPartogrammesTypes.GET_PATIENTE_PARTOGRAMME_INFO_PATIENT_SUCCEDED:
            return {
                ...state,
                isLoading: false,
                error : '',
                DataInfoReducers: action.payload,
            };


        case patientAConsulterPartogrammesTypes.GET_PATIENTE_PARTOGRAMME_INFO_PATIENT_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                DataInfoReducers : null,
            };
        default:
            return state;
    }
}


export const PosteListeEquipeMedicale = (state=initialStatePosteListeEquipe, action) => {

    switch (action.type) {
        case patientAConsulterPartogrammesTypes.POST_PATIENTE_PARTOGRAMME_LIST_PROFESSIONELS_CENTRE_REQUEST:
            return {
                ...state,
                isLoading: true,
                DataInfoReducers : []
            };
        case patientAConsulterPartogrammesTypes.POST_PATIENTE_PARTOGRAMME_LIST_PROFESSIONELS_CENTRE_SUCCEDED:
            return {
                ...state,
                isLoading: false,
                DataInfoReducers: action.payload,
            };


        case patientAConsulterPartogrammesTypes.POST_PATIENTE_PARTOGRAMME_LIST_PROFESSIONELS_CENTRE_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                DataInfoReducers : []
            };
        default:
            return state;
    }
}

export const PutCreatePaartogrammePatientReducers = (state=initialPartogrammeCreateStar, action) => {

    switch (action.type) {
        case patientAConsulterPartogrammesTypes.PUT_CREATE_PARTOGRAMME_PATIENTE_REQUEST:
            return {
                ...state,
                isLoading: true,
                DataCreatepartogrammeStart : []
            };
        case patientAConsulterPartogrammesTypes.PUT_CREATE_PARTOGRAMME_PATIENTE_SUCCEDED:
            return {
                ...state,
                isLoading: false,
                DataCreatepartogrammeStart: action.payload,
            };


        case patientAConsulterPartogrammesTypes.PUT_CREATE_PARTOGRAMME_PATIENTE_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                DataCreatepartogrammeStart : []
            };
        default:
            return state;
    }
}


export const PosteCreatePartogrammePatienteReducers = (state=initialStatePosteListeEquipe, action) => {

    switch (action.type) {
        case patientAConsulterPartogrammesTypes.POST_CREATE_PARTOGRAMME_PATIENTE_REQUEST:
            return {
                ...state,
                isLoading: true,
                DataPartoCreate : []
            };
        case patientAConsulterPartogrammesTypes.POST_CREATE_PARTOGRAMME_PATIENTE_SUCCEDED:
            return {
                ...state,
                isLoading: false,
                DataPartoCreate: action.payload,
            };


        case patientAConsulterPartogrammesTypes.POST_CREATE_PARTOGRAMME_PATIENTE_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                DataPartoCreate : []
            };
        default:
            return state;
    }
}


export const InfoPartogrammeReducers = (state=initialStateInfoPartogrammeReducers, action) => {

    switch (action.type) {
        case patientAConsulterPartogrammesTypes.GET_INFO_PARTOGRAMME_PATIENTE_REQUEST:
            return {
                ...state,
                isLoading: true,
                DataInfoParto : [],
            };
        case patientAConsulterPartogrammesTypes.GET_INFO_PARTOGRAMME_PATIENTE_SUCCEDED:
            return {
                ...state,
                isLoading: false,
                DataInfoParto: action.payload,
                error : null
            };


        case patientAConsulterPartogrammesTypes.GET_INFO_PARTOGRAMME_PATIENTE_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                DataInfoParto : null,
            };
        default:
            return 0;
    }
}



export const GetListePartoTerminer = (state=initialStatePartoTermiInfoReducer, action) => {

    switch (action.type) {
        case patientAConsulterPartogrammesTypes.GET_PARTOGRAMME_PATIENTE_liste_Terminer_REQUEST:
            return {
                ...state,
                isLoading: true,
                DataListePartoTermi : [],
            };
        case patientAConsulterPartogrammesTypes.GET_PARTOGRAMME_PATIENTE_liste_Terminer_SUCCEDED:
            return {
                ...state,
                isLoading: false,
                DataListePartoTermi: action.payload,
                error : null
            };


        case patientAConsulterPartogrammesTypes.GET_PARTOGRAMME_PATIENTE_liste_Terminer_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                DataListePartoTermi : null,
            };
        default:
            return state;
    }
}

export const Put_Terminer_Partogramme = (state=terminerPartogramme, action) => {

    switch (action.type) {
        case patientAConsulterPartogrammesTypes.PUT_PARTOGRAMME_TERMINER_REQUEST:
            return {
                ...state,
                isLoading: true,
                DataTerminerPartogramme : []
            };
        case patientAConsulterPartogrammesTypes.PUT_CREATE_PARTOGRAMME_PATIENTE_SUCCEDED:
            return {
                ...state,
                isLoading: false,
                DataTerminerPartogramme: action.payload,
            };


        case patientAConsulterPartogrammesTypes.PUT_CREATE_PARTOGRAMME_PATIENTE_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                DataTerminerPartogramme : []
            };
        default:
            return state;
    }
}

