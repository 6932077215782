import React, { createContext, lazy, Suspense, useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Provider } from "react-redux";

import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'


import "./App.css";

import { loadUser } from "./reduxFiles/actions/userActions";

/////////////////////////////// Redux Persist Store Config ////////////////////////////////////////////////////
/////////////////////////////// Redux Persist Store Config ////////////////////////////////////////////////////
/////////////////////////////// Redux Persist Store Config ////////////////////////////////////////////////////

import configureStore from "./reduxFiles/store";
import { PersistGate } from "redux-persist/integration/react";
import { GlobalDebug } from "./utils/utilsFunctions/remove-consoles";

import { config } from "./Constants";

import LoadingPageComponent from "./utils/routes/LoadingPageComponent";
import TestLoadingPage from "./utils/routes/TestLoadingPage";

import useNetworkInformations from './hooks/useNetworkInformations';
import NoInternet from "./components/generics/onInternet/NoInternet";

import AccueilRouter from "./utils/LinkRouters/AccueilRouter"
import CaisseRouter from "./utils/LinkRouters/CaisseRouter"
import InfirmerieRouter from "./utils/LinkRouters/InfirmerieRouter"
import GynecologieRouter from "./utils/LinkRouters/GynecologieRouter"
import StatistiquesRouter from "./utils/LinkRouters/StatistiquesRouter"
import CorbeilleRouter from "./utils/LinkRouters/CorbeilleRouter"
import CabinetDentaireRouter from "./utils/LinkRouters/CabinetDentaireRouter"
import DermatologieRouter from "./utils/LinkRouters/DermatologieRouter"
import MedecineGeneraleRouter from "./utils/LinkRouters/MedecineGeneraleRouter"
import ReanimationRouter from "./utils/LinkRouters/ReanimationRouter"
import UrologieRouter from "./utils/LinkRouters/UrologieRouter"
import DossiersPatientsRouter from "./utils/LinkRouters/DossiersPatientsRouter"
import OrlRouter from "./utils/LinkRouters/OrlRouter"
import CardiologieRouter from "./utils/LinkRouters/CardiologieRouter"
import PediatrieRouter from "./utils/LinkRouters/PediatrieRouter"
import FacturationRouter from "./utils/LinkRouters/FacturationRouter"
import LaboratoireRouter from "./utils/LinkRouters/LaboratoireRouter"
import DiabetologieRouter from "./utils/LinkRouters/DiabetologieRouter"
import KinesitherapieRouter from "./utils/LinkRouters/KinesitherapieRouter"
import MaterniteRouter from "./utils/LinkRouters/MaterniteRouter"
import AccouchementRouter from "./utils/LinkRouters/AccouchementRouter"
import CpnRouter from "./utils/LinkRouters/CpnRouter"
import CponRouter from "./utils/LinkRouters/CponRouter"
import ConfigurationRouter from "./utils/LinkRouters/ConfigurationRouter"
import RadiologieRouter from "./utils/LinkRouters/RadiologieRouter"
import EchographieRouter from "./utils/LinkRouters/EchographieRouter"
import RhumatologieRouter from "./utils/LinkRouters/RhumatologieRouter"
import PsychiatrieRouter from "./utils/LinkRouters/PsychiatrieRouter"
import PneumologieRouter from "./utils/LinkRouters/PneumologieRouter"
import HospitalisationRouter from "./utils/LinkRouters/HospitalisationRouter"
import OphtalmologieRouter from "./utils/LinkRouters/OphtalmologieRouter"
import SuiteDeCoucheRouter from "./utils/LinkRouters/SuiteDeCoucheRouter"
import UrgenceRouter from "./utils/LinkRouters/UrgenceRouter"
import TelemedecineRouter from "./utils/LinkRouters/TelemedecineRouter"
import ControlePBFRouter from "./utils/LinkRouters/ControlePBFRouter"
import VaccinationRouter from "./utils/LinkRouters/VaccinationRouter"
import CasSociauxRouter from "./utils/LinkRouters/CasSociauxRouter"
import ParametrageGlobaleRouter from "./utils/LinkRouters/ParametrageGlobaleRouter"
import EtablissementsRouter from "./utils/LinkRouters/EtablissementsRouter"
import TarifsReduitsRouter from "./utils/LinkRouters/TarifsReduitsRouter"
import RapportsRouter from "./utils/LinkRouters/RapportsRouter"
import ChirurgieRouter from "./utils/LinkRouters/ChirurgieRouter"


const { store, persistor } = configureStore();

/////////////////////////////// Redux Persist Store Config ////////////////////////////////////////////////////
/////////////////////////////// Redux Persist Store Config ////////////////////////////////////////////////////
/////////////////////////////// Redux Persist Store Config ////////////////////////////////////////////////////

///////////////////// Connexion et Module ////////////////////////////// Connexion et Module */}
///////////////////// Connexion et Module ////////////////////////////// Connexion et Module */}
///////////////////// Connexion et Module ////////////////////////////// Connexion et Module */}
const Connexion = lazy(() => import("./components/connexion/Connexion"));
const Modules = lazy(() => import("./components/modules/Modules"));
const ConfirmPassword = lazy(() =>
  import("./components/connexion/password/forget/ConfirmPassword")
);
const Password = lazy(() =>
  import("./components/connexion/password/changed/Password")
);

//eslint-disable-next-line
const MonProfil = lazy(() => import("./components/modules/MonProfil"));

const TestComponent = lazy(() => import("./components/modules/TestComponent"));

///////////////////// Connexion et Module ////////////////////////////// Connexion et Module */}
///////////////////// Connexion et Module ////////////////////////////// Connexion et Module */}
///////////////////// Connexion et Module ////////////////////////////// Connexion et Module */}


//////////////////////////////PHARMACIE //////////////////////////////////////////////////////////////////
//////////////////////////////PHARMACIE //////////////////////////////////////////////////////////////////
//////////////////////////////PHARMACIE //////////////////////////////////////////////////////////////////

// eslint-disable-next-line
const ModulesPharmacie = lazy(() => import("./components/pharmacie/modules/ModulesPharmacie"));

// eslint-disable-next-line
const PharmacieCaissePaiementEffectue = lazy(() =>
  import(
    "./components/pharmacie/caisse/PharmacieCaissePaiementEffectue"
  )
);

// eslint-disable-next-line
const PharmacieCaisse = lazy(() =>
  import(
    "./components/pharmacie/caisse/PharmacieCaisse"
  )
);

// eslint-disable-next-line
const PharmacieCommandePharmacie = lazy(() =>
  import(
    "./components/pharmacie/commandes/PharmacieCommandePharmacie"
  )
);

// eslint-disable-next-line
const PharmacieCommandeValide = lazy(() =>
  import(
    "./components/pharmacie/commandes/PharmacieCommandeValide"
  )
);

// eslint-disable-next-line
const PharmacieCommandeAnnuler = lazy(() =>
  import(
    "./components/pharmacie/commandes/PharmacieCommandeAnnuler"
  )
);

//////////////////////////////PHARMACIE //////////////////////////////////////////////////////////////////
//////////////////////////////PHARMACIE //////////////////////////////////////////////////////////////////
//////////////////////////////PHARMACIE //////////////////////////////////////////////////////////////////




//////////////////////////////Page Not Found //////////////////////////////////////////////////////////////////
//////////////////////////////Page Not Found //////////////////////////////////////////////////////////////////
//////////////////////////////Page Not Found //////////////////////////////////////////////////////////////////

const PageNotFound = lazy(() => import("./components/pages/PageNotFound"));
const PageNotAuthorized = lazy(() =>
  import("./components/pages/PageNotAuthorized")
);

//////////////////////////////Page Not Found //////////////////////////////////////////////////////////////////
//////////////////////////////Page Not Found //////////////////////////////////////////////////////////////////
//////////////////////////////Page Not Found //////////////////////////////////////////////////////////////////




// Creation du client react query
const queryClient = new QueryClient()
// creation du contexte
export const ReportCenterContext = createContext(null);





const App = () => {

  const networkInformations = useNetworkInformations()
  const initialStateReportCurrentLogo = {
    libelle: "",
    adresse_lieu: "",
    adresse: "",
    logo: "",
    contact: "",
  }

  const [reportHeaderInfo, setReportHeaderInfo] = useState(initialStateReportCurrentLogo)



  // Similaire à componentDidMount et componentDidUpdate :
  useEffect(() => {
    store.dispatch(loadUser());
  }, []);

  /**
   * @REMOVE_CONSOLES
   * // supprimer le fonctionnement des journaux de la console
   * // supprimer toute utilisation accidentelle des journaux de la console
   * // || config.prod.REACT_APP_ENV === "STAGING"
   */
  useEffect(() => {
    if (config.virtual.ENV === "prod") {
      GlobalDebug(false, true);
    } else if (config.virtual.ENV === "dev") {
      GlobalDebug(true, false);
    }
    // eslint-disable-next-line
  }, []);



  if (!networkInformations?.isOnline && config.virtual.ENV === "prod" && config.virtual.SITE === "online") return (<NoInternet />)

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <QueryClientProvider client={queryClient}>
          <ReportCenterContext.Provider value={{ reportHeaderInfo, setReportHeaderInfo }}>
            <Router>
              <Suspense fallback={<TestLoadingPage />}>
                <Switch>
                  {/* ///////////////////// Connexion et Module ////////////////////////////// Connexion et Module */}
                  {/* ///////////////////// Connexion et Module ////////////////////////////// Connexion et Module */}
                  {/* ///////////////////// Connexion et Module ////////////////////////////// Connexion et Module */}
                  <Route path="/" exact component={Connexion} />

                  {/* <Route path='/connexion2' exact component={Connexion2} />
                  <Route path='/connexion3' exact component={Connexion} /> */}
                  <Route path="/modules" component={Modules} />
                  <Route path="/forget-password" component={Password} />
                  <Route path="/password-changed/:id" component={ConfirmPassword} />
                  <Route path="/loading" component={LoadingPageComponent} />
                  <Route path="/test-loading" component={TestLoadingPage} />
                  {/* <Route path="/change-password" component={PasswordChanger} /> */}
                  {/* ///////////////////// Connexion et Module ////////////////////////////// Connexion et Module */}
                  {/* ///////////////////// Connexion et Module ////////////////////////////// Connexion et Module */}
                  {/* ///////////////////// Connexion et Module ////////////////////////////// Connexion et Module */}

                  {/* ///////////////////// Bureau Entrées ////////////////////////////// Bureau Entrées */}
                  {/* ///////////////////// Bureau Entrées ////////////////////////////// Bureau Entrées */}
                  {/* ///////////////////// Bureau Entrées ////////////////////////////// Bureau Entrées */}
                  
                  <Route path="/accueil*">
                    <AccueilRouter />
                  </Route>
                  
                  {/* ///////////////////// Bureau Entrées ////////////////////////////// Bureau Entrées */}
                  {/* ///////////////////// Bureau Entrées ////////////////////////////// Bureau Entrées */}
                  {/* ///////////////////// Bureau Entrées ////////////////////////////// Bureau Entrées */}

                  {/* ///////////////////// Caisse ////////////////////////////// Caisse */}
                  {/* ///////////////////// Caisse ////////////////////////////// Caisse */}
                  {/* ///////////////////// Caisse ////////////////////////////// Caisse */}
                  
                  <Route path="/caisse*">
                    <CaisseRouter />
                  </Route>

                  {/* ///////////////////// Caisse ////////////////////////////// Caisse */}
                  {/* ///////////////////// Caisse ////////////////////////////// Caisse */}
                  {/* ///////////////////// Caisse ////////////////////////////// Caisse */}

                  {/* ///////////////////// Infirmerie ////////////////////////////// Infirmerie */}
                  {/* ///////////////////// Infirmerie ////////////////////////////// Infirmerie */}
                  {/* ///////////////////// Infirmerie ////////////////////////////// Infirmerie */}
                  
                  <Route path="/infirmerie*">
                    <InfirmerieRouter />
                  </Route>
                  

                  {/* ///////////////////// Infirmerie ////////////////////////////// Infirmerie */}
                  {/* ///////////////////// Infirmerie ////////////////////////////// Infirmerie */}
                  {/* ///////////////////// Infirmerie ////////////////////////////// Infirmerie */}

                  {/* ///////////////////// Gynecologie ////////////////////////////// Gynecologie */}
                  {/* ///////////////////// Gynecologie ////////////////////////////// Gynecologie */}
                  {/* ///////////////////// Gynecologie ////////////////////////////// Gynecologie */}

                  <Route path="/gynecologie*">
                    <GynecologieRouter />
                  </Route>

                  {/* ///////////////////// Gynecologie ////////////////////////////// Gynecologie */}
                  {/* ///////////////////// Gynecologie ////////////////////////////// Gynecologie */}
                  {/* ///////////////////// Gynecologie ////////////////////////////// Gynecologie */}

                  {/* ///////////////////// Statistiques ////////////////////////////// Statistiques */}
                  {/* ///////////////////// Statistiques ////////////////////////////// Statistiques */}
                  {/* ///////////////////// Statistiques ////////////////////////////// Statistiques */}

                  <Route path="/statistiques*">
                    <StatistiquesRouter />
                  </Route>


                  {/* ///////////////////// Statistiques ////////////////////////////// Statistiques */}
                  {/* ///////////////////// Statistiques ////////////////////////////// Statistiques */}
                  {/* ///////////////////// Statistiques ////////////////////////////// Statistiques */}


                  {/* ///////////////////// Corbeille ////////////////////////////// Corbeille */}
                  {/* ///////////////////// Corbeille ////////////////////////////// Corbeille */}
                  {/* ///////////////////// Corbeille ////////////////////////////// Corbeille */}
                  
                  <Route path="/corbeille*">
                    <CorbeilleRouter />
                  </Route>

                  {/* ///////////////////// Corbeille ////////////////////////////// Corbeille */}
                  {/* ///////////////////// Corbeille ////////////////////////////// Corbeille */}
                  {/* ///////////////////// Corbeille ////////////////////////////// Corbeille */}
                

                  {/* ///////////////////// Medecine Generale ////////////////////////////// Medecine Generale */}
                  {/* ///////////////////// Medecine Generale ////////////////////////////// Medecine Generale */}
                  {/* ///////////////////// Medecine Generale ////////////////////////////// Medecine Generale */}

                  <Route path="/medecine-generale*">
                    <MedecineGeneraleRouter />
                  </Route>

                  {/* ///////////////////// Medecine Generale ////////////////////////////// Medecine Generale */}
                  {/* ///////////////////// Medecine Generale ////////////////////////////// Medecine Generale */}
                  {/* ///////////////////// Medecine Generale ////////////////////////////// Medecine Generale */}



                  {/* ///////////////////// Orl ////////////////////////////// Orl */}
                  {/* ///////////////////// Orl ////////////////////////////// Orl */}
                  {/* ///////////////////// Orl ////////////////////////////// Orl */}

                  <Route path="/orl*">
                    <OrlRouter />
                  </Route>

                  {/* ///////////////////// Orl ////////////////////////////// Orl */}
                  {/* ///////////////////// Orl ////////////////////////////// Orl */}
                  {/* ///////////////////// Orl ////////////////////////////// Orl */}

                  {/* ///////////////////// Cardiologie ////////////////////////////// Cardiologie */}
                  {/* ///////////////////// Cardiologie ////////////////////////////// Cardiologie */}
                  {/* ///////////////////// Cardiologie ////////////////////////////// Cardiologie */}

                  <Route path="/cardiologie*">
                    <CardiologieRouter />
                  </Route>

                  {/* ///////////////////// Cadiologie ////////////////////////////// Cadiologie */}
                  {/* ///////////////////// Cadiologie ////////////////////////////// Cadiologie */}
                  {/* ///////////////////// Cadiologie ////////////////////////////// Cadiologie */}

                  {/* ///////////////////// Reanimation ////////////////////////////// Reanimation */}
                  {/* ///////////////////// Reanimation ////////////////////////////// Reanimation */}
                  {/* ///////////////////// Reanimation ////////////////////////////// Reanimation */}

                  <Route path="/reanimation*">
                    <ReanimationRouter />
                  </Route>

                  {/* ///////////////////// Reanimation ////////////////////////////// Reanimation */}
                  {/* ///////////////////// Reanimation ////////////////////////////// Reanimation */}
                  {/* ///////////////////// Reanimation ////////////////////////////// Reanimation */}

                  {/* ///////////////////// Diabetologie ////////////////////////////// Diabetologie */}
                  {/* ///////////////////// Diabetologie ////////////////////////////// Diabetologie */}
                  {/* ///////////////////// Diabetologie ////////////////////////////// Diabetologie */}
                  
                  <Route path="/diabetologie*">
                    <DiabetologieRouter />
                  </Route>

                  {/* ///////////////////// Diabetologie ////////////////////////////// Cadiologie */}
                  {/* ///////////////////// Diabetologie ////////////////////////////// Cadiologie */}
                  {/* ///////////////////// Diabetologie ////////////////////////////// Cadiologie */}

                  {/* ///////////////////// Kinesitherapie ////////////////////////////// Kinesitherapie */}
                  {/* ///////////////////// Kinesitherapie ////////////////////////////// Kinesitherapie */}
                  {/* ///////////////////// Kinesitherapie ////////////////////////////// Kinesitherapie */}
                  
                  <Route path="/kinesitherapie*">
                    <KinesitherapieRouter />
                  </Route>

                  {/* ///////////////////// Kinesitherapie ////////////////////////////// Kinesitherapie */}
                  {/* ///////////////////// Kinesitherapie ////////////////////////////// Kinesitherapie */}
                  {/* ///////////////////// Kinesitherapie ////////////////////////////// Kinesitherapie */}

                  {/* ///////////////////// Dossiers Patients ////////////////////////////// Dossiers Patients */}
                  {/* ///////////////////// Dossiers Patients ////////////////////////////// Dossiers Patients */}
                  {/* ///////////////////// Dossiers Patients ////////////////////////////// Dossiers Patients */}

                  <Route path="/dossiers-patients*">
                    <DossiersPatientsRouter />
                  </Route>

                  {/* ///////////////////// Dossiers Patients ////////////////////////////// Dossiers Patients */}
                  {/* ///////////////////// Dossiers Patients ////////////////////////////// Dossiers Patients */}
                  {/* ///////////////////// Dossiers Patients ////////////////////////////// Dossiers Patients */}

                  {/* ///////////////////// Pediatrie ////////////////////////////// Pediatrie */}
                  {/* ///////////////////// Pediatrie ////////////////////////////// Pediatrie */}
                  {/* ///////////////////// Pediatrie ////////////////////////////// Pediatrie */}

                  <Route path="/pediatrie*">
                    <PediatrieRouter />
                  </Route>

                  {/* ///////////////////// Pediatrie ////////////////////////////// Pediatrie */}
                  {/* ///////////////////// Pediatrie ////////////////////////////// Pediatrie */}
                  {/* ///////////////////// Pediatrie ////////////////////////////// Pediatrie */}

                  {/* ///////////////////// Maternité ////////////////////////////// maternite */}
                  {/* ///////////////////// Maternité ////////////////////////////// maternite */}
                  {/* ///////////////////// Maternité ////////////////////////////// maternite */}
                  
                  <Route path="/maternite*">
                    <MaterniteRouter />
                  </Route>

                  {/* ///////////////////// Maternité ////////////////////////////// maternite */}
                  {/* ///////////////////// Maternité ////////////////////////////// maternite */}
                  {/* ///////////////////// Maternité ////////////////////////////// maternite */}

                  {/* ///////////////////// CPN ////////////////////////////// CPN */}
                  {/* ///////////////////// CPN ////////////////////////////// CPN */}
                  {/* ///////////////////// CPN ////////////////////////////// CPN */}

                  <Route path="/cpn*">
                    <CpnRouter />
                  </Route>

                  {/* ///////////////////// CPN ////////////////////////////// CPN */}
                  {/* ///////////////////// CPN ////////////////////////////// CPN */}
                  {/* ///////////////////// CPN ////////////////////////////// CPN */}


                  {/* ///////////////////// Accouchement ////////////////////////////// Accouchement */}
                  {/* ///////////////////// Accouchement ////////////////////////////// Accouchement */}
                  {/* ///////////////////// Accouchement ////////////////////////////// Accouchement */}

                  <Route path="/accouchement*">
                    <AccouchementRouter />
                  </Route>

                  {/* ///////////////////// Accouchement ////////////////////////////// Accouchement */}
                  {/* ///////////////////// Accouchement ////////////////////////////// Accouchement */}
                  {/* ///////////////////// Accouchement ////////////////////////////// Accouchement */}


                  {/* ///////////////////// CPON ////////////////////////////// CPON */}
                  {/* ///////////////////// CPON ////////////////////////////// CPON */}
                  {/* ///////////////////// CPON ////////////////////////////// CPON */}

                  <Route path="/cpon*">
                    <CponRouter />
                  </Route>

                  {/* ///////////////////// CPON ////////////////////////////// CPON */}
                  {/* ///////////////////// CPON ////////////////////////////// CPON */}
                  {/* ///////////////////// CPON ////////////////////////////// CPON */}



                  {/* ///////////////////// Ophtalmologie ////////////////////////////// Ophtalmologie */}
                  {/* ///////////////////// Ophtalmologie ////////////////////////////// Ophtalmologie */}
                  {/* ///////////////////// Ophtalmologie ////////////////////////////// Ophtalmologie */}

                  <Route path="/ophtalmologie*">
                    <OphtalmologieRouter />
                  </Route>

                  {/* ///////////////////// Ophtalmologie ////////////////////////////// Ophtalmologie */}
                  {/* ///////////////////// Ophtalmologie ////////////////////////////// Ophtalmologie */}
                  {/* ///////////////////// Ophtalmologie ////////////////////////////// Ophtalmologie */}

                  {/* ///////////////////// Hospitalisation ////////////////////////////// Hospitalisation */}
                  {/* ///////////////////// Hospitalisation ////////////////////////////// Hospitalisation */}
                  {/* ///////////////////// Hospitalisation ////////////////////////////// Hospitalisation */}

                  <Route path="/hospitalisation*">
                    <HospitalisationRouter />
                  </Route>
                  
                  {/* ///////////////////// Hospitalisation ////////////////////////////// Hospitalisation */}
                  {/* ///////////////////// Hospitalisation ////////////////////////////// Hospitalisation */}
                  {/* ///////////////////// Hospitalisation ////////////////////////////// Hospitalisation */}

                  {/* ///////////////////// Cabinet Dentaire ////////////////////////////// Cabinet Dentaire */}
                  {/* ///////////////////// Cabinet Dentaire ////////////////////////////// Cabinet Dentaire */}
                  {/* ///////////////////// Cabinet Dentaire ////////////////////////////// Cabinet Dentaire */}

                  <Route path="/cabinet-dentaire*">
                    <CabinetDentaireRouter />
                  </Route>

                  {/* ///////////////////// Cabinet Dentare ////////////////////////////// Cabinet Dentare */}
                  {/* ///////////////////// Cabinet Dentare ////////////////////////////// Cabinet Dentare */}
                  {/* ///////////////////// Cabinet Dentare ////////////////////////////// Cabinet Dentare */}


                  {/* ///////////////////// Laboratoire ////////////////////////////// Laboratoire */}
                  {/* ///////////////////// Laboratoire ////////////////////////////// Laboratoire */}
                  {/* ///////////////////// Laboratoire ////////////////////////////// Laboratoire */}

                  <Route path="/laboratoire*">
                    <LaboratoireRouter />
                  </Route>

                  {/* ///////////////////// Laboratoire ////////////////////////////// Laboratoire */}
                  {/* ///////////////////// Laboratoire ////////////////////////////// Laboratoire */}
                  {/* ///////////////////// Laboratoire ////////////////////////////// Laboratoire */}

                  {/* ///////////////////// Dermatologie ////////////////////////////// Dermatologie */}
                  {/* ///////////////////// Dermatologie ////////////////////////////// Dermatologie */}
                  {/* ///////////////////// Dermatologie ////////////////////////////// Dermatologie */}

                  <Route path="/dermatologie*">
                    <DermatologieRouter />
                  </Route>

                  {/* ///////////////////// Dermatologie ////////////////////////////// Dermatologie */}
                  {/* ///////////////////// Dermatologie ////////////////////////////// Dermatologie */}
                  {/* ///////////////////// Dermatologie ////////////////////////////// Dermatologie */}

                  {/* ///////////////////// Facturation ////////////////////////////// Facturation */}
                  {/* ///////////////////// Facturation ////////////////////////////// Facturation */}
                  {/* ///////////////////// Facturation ////////////////////////////// Facturation */}

                  <Route path="/facturation*">
                    <FacturationRouter />
                  </Route>

                  {/* ///////////////////// Facturation ////////////////////////////// Facturation */}
                  {/* ///////////////////// Facturation ////////////////////////////// Facturation */}
                  {/* ///////////////////// Facturation ////////////////////////////// Facturation */}

                  {/* ///////////////////// Urgence ////////////////////////////// Urgence */}
                  {/* ///////////////////// Urgence ////////////////////////////// Urgence */}
                  {/* ///////////////////// Urgence ////////////////////////////// Urgence */}

                  <Route path="/urgence*">
                    <UrgenceRouter />
                  </Route>

                  {/* ///////////////////// Urgence ////////////////////////////// Urgence */}
                  {/* ///////////////////// Urgence ////////////////////////////// Urgence */}
                  {/* ///////////////////// Urgence ////////////////////////////// Urgence */}

                  {/* ///////////////////// Rapport ////////////////////////////// Urgence */}
                  {/* ///////////////////// Rapport ////////////////////////////// Urgence */}
                  {/* ///////////////////// Rapport ////////////////////////////// Urgence */}
                  
                  <Route path="/rapports*">
                    <RapportsRouter />
                  </Route>

                  {/* ///////////////////// Rapport ////////////////////////////// Urgence */}
                  {/* ///////////////////// Rapport ////////////////////////////// Urgence */}
                  {/* ///////////////////// Rapport ////////////////////////////// Urgence */}

                  {/* ///////////////////// Chirurgie ////////////////////////////// Chirurgie */}
                  {/* ///////////////////// Chirurgie ////////////////////////////// Chirurgie */}
                  {/* ///////////////////// Chirurgie ////////////////////////////// Chirurgie */}

                  <Route path="/chirurgie*">
                    <ChirurgieRouter />
                  </Route>

                  {/* ///////////////////// Chirurgie ////////////////////////////// Chirurgie */}
                  {/* ///////////////////// Chirurgie ////////////////////////////// Chirurgie */}
                  {/* ///////////////////// Chirurgie ////////////////////////////// Chirurgie */}

                  

                  {/* ///////////////////// Tarifs Réduits ////////////////////////////// Tarifs Réduits */}
                  {/* ///////////////////// Tarifs Réduits ////////////////////////////// Tarifs Réduits */}
                  {/* ///////////////////// Tarifs Réduits ////////////////////////////// Tarifs Réduits */}

                  <Route path="/tarifs-reduits*">
                    <TarifsReduitsRouter />
                  </Route>

                  {/* ///////////////////// Tarifs Réduits ////////////////////////////// Tarifs Réduits */}
                  {/* ///////////////////// Tarifs Réduits ////////////////////////////// Tarifs Réduits */}
                  {/* ///////////////////// Tarifs Réduits ////////////////////////////// Tarifs Réduits */}


                  {/* ///////////////////// Etablissement ////////////////////////////// Etablissement */}
                  {/* ///////////////////// Etablissement ////////////////////////////// Etablissement */}
                  {/* ///////////////////// Etablissement ////////////////////////////// Etablissement */}

                  <Route path="/etablissements*">
                    <EtablissementsRouter />
                  </Route>

                  {/* ///////////////////// Etablissement ////////////////////////////// Etablissement */}
                  {/* ///////////////////// Etablissement ////////////////////////////// Etablissement */}
                  {/* ///////////////////// Etablissement ////////////////////////////// Etablissement */}


                  {/* ///////////////////// Parametrage Globale ////////////////////////////// Parametrage Globale */}
                  {/* ///////////////////// Parametrage Globale ////////////////////////////// Parametrage Globale */}
                  {/* ///////////////////// Parametrage Globale ////////////////////////////// Parametrage Globale */}

                  <Route path="/parametrage-globale*">
                    <ParametrageGlobaleRouter />
                  </Route>

                  {/* ///////////////////// Parametrage Globale ////////////////////////////// Parametrage Globale */}
                  {/* ///////////////////// Parametrage Globale ////////////////////////////// Parametrage Globale */}
                  {/* ///////////////////// Parametrage Globale ////////////////////////////// Parametrage Globale */}


                  {/* ///////////////////// Psychiatrie ////////////////////////////// Psychiatrie */}
                  {/* ///////////////////// Psychiatrie ////////////////////////////// Psychiatrie */}
                  {/* ///////////////////// Psychiatrie ////////////////////////////// Psychiatrie */}

                  <Route path="/psychiatrie*">
                    <PsychiatrieRouter />
                  </Route>

                  {/* ///////////////////// Psychiatrie ////////////////////////////// Psychiatrie */}
                  {/* ///////////////////// Psychiatrie ////////////////////////////// Psychiatrie */}
                  {/* ///////////////////// Psychiatrie ////////////////////////////// Psychiatrie */}

                  
                  
                  {/* ///////////////////// Radiologie ////////////////////////////// Radiologie */}
                  {/* ///////////////////// Radiologie ////////////////////////////// Radiologie */}
                  {/* ///////////////////// Radiologie ////////////////////////////// Radiologie */}

                  <Route path="/radiologie*">
                    <RadiologieRouter />
                  </Route>

                  {/* ///////////////////// Radiologie ////////////////////////////// Radiologie */}
                  {/* ///////////////////// Radiologie ////////////////////////////// Radiologie */}
                  {/* ///////////////////// Radiologie ////////////////////////////// Radiologie */}



                  {/* ///////////////////// Suite De Couche ////////////////////////////// Suite De Couche */}
                  {/* ///////////////////// Suite De Couche ////////////////////////////// Suite De Couche */}
                  {/* ///////////////////// Suite De Couche ////////////////////////////// Suite De Couche */}
                  
                  <Route path="/suite-de-couche*">
                    <SuiteDeCoucheRouter />
                  </Route>

                  {/* ///////////////////// Suite De Couche ////////////////////////////// Suite De Couche */}
                  {/* ///////////////////// Suite De Couche ////////////////////////////// Suite De Couche */}
                  {/* ///////////////////// Suite De Couche ////////////////////////////// Suite De Couche */}


                  {/* ///////////////////// Cas Social ////////////////////////////// Cas Social */}
                  {/* ///////////////////// Cas Social ////////////////////////////// Cas Social */}
                  {/* ///////////////////// Cas Social ////////////////////////////// Cas Social */}
                  
                  <Route path="/cas-social*">
                    <CasSociauxRouter />
                  </Route>

                  {/* ///////////////////// Cas Social ////////////////////////////// Cas Social */}
                  {/* ///////////////////// Cas Social ////////////////////////////// Cas Social */}
                  {/* ///////////////////// Cas Social ////////////////////////////// Cas Social */}



                  {/* ///////////////////// Pneumologie ////////////////////////////// Pneumologie */}
                  {/* ///////////////////// Pneumologie ////////////////////////////// Pneumologie */}
                  {/* ///////////////////// Pneumologie ////////////////////////////// Pneumologie */}

                  <Route path="/pneumologie*">
                    <PneumologieRouter />
                  </Route>

                  {/* ///////////////////// Pneumologie ////////////////////////////// Pneumologie */}
                  {/* ///////////////////// Pneumologie ////////////////////////////// Pneumologie */}
                  {/* ///////////////////// Pneumologie ////////////////////////////// Pneumologie */}


                  {/* ///////////////////// Rhumatologie ////////////////////////////// Rhumatologie */}
                  {/* ///////////////////// Rhumatologie ////////////////////////////// Rhumatologie */}
                  {/* ///////////////////// Rhumatologie ////////////////////////////// Rhumatologie */}

                  <Route path="/rhumatologie*">
                    <RhumatologieRouter />
                  </Route>

                  {/* ///////////////////// Rhumatologie ////////////////////////////// Rhumatologie */}
                  {/* ///////////////////// Rhumatologie ////////////////////////////// Rhumatologie */}
                  {/* ///////////////////// Rhumatologie ////////////////////////////// Rhumatologie */}


                  {/* ///////////////////// Urologie ////////////////////////////// Urologie */}
                  {/* ///////////////////// Urologie ////////////////////////////// Urologie */}
                  {/* ///////////////////// Urologie ////////////////////////////// Urologie */}
                  
                  <Route path="/urologie*">
                    <UrologieRouter />
                  </Route>

                  {/* ///////////////////// Urologie ////////////////////////////// Urologie */}
                  {/* ///////////////////// Urologie ////////////////////////////// Urologie */}
                  {/* ///////////////////// Urologie ////////////////////////////// Urologie */}



                  {/* ///////////////////// Echographie ////////////////////////////// Echographie */}
                  {/* ///////////////////// Echographie ////////////////////////////// Echographie */}
                  {/* ///////////////////// Echographie ////////////////////////////// Echographie */}

                  <Route path="/echographie*">
                    <EchographieRouter />
                  </Route>

                  {/* ///////////////////// Echographie ////////////////////////////// Echographie */}
                  {/* ///////////////////// Echographie ////////////////////////////// Echographie */}
                  {/* ///////////////////// Echographie ////////////////////////////// Echographie */}



                  {/* ///////////////////// Vaccination ////////////////////////////// Vaccination */}
                  {/* ///////////////////// Vaccination ////////////////////////////// Vaccination */}
                  {/* ///////////////////// Vaccination ////////////////////////////// Vaccination */}

                  <Route path="/vaccination*">
                    <VaccinationRouter />
                  </Route>

                  {/* ///////////////////// Vaccination ////////////////////////////// Vaccination */}
                  {/* ///////////////////// Vaccination ////////////////////////////// Vaccination */}
                  {/* ///////////////////// Vaccination ////////////////////////////// Vaccination */}


                  {/* ///////////////////// Contrôle PBF ////////////////////////////// Contrôle PBF */}
                  {/* ///////////////////// Contrôle PBF ////////////////////////////// Contrôle PBF */}
                  {/* ///////////////////// Contrôle PBF ////////////////////////////// Contrôle PBF */}

                  <Route path="/controle-pbf*">
                    <ControlePBFRouter />
                  </Route>

                  {/* ///////////////////// Contrôle PBF ////////////////////////////// Contrôle PBF */}
                  {/* ///////////////////// Contrôle PBF ////////////////////////////// Contrôle PBF */}
                  {/* ///////////////////// Contrôle PBF ////////////////////////////// Contrôle PBF */}

                  

                  {/* //////////////////// Telemedecine ///////////////////////////// Telemedecine */}
                  {/* //////////////////// Telemedecine ///////////////////////////// Telemedecine */}
                  {/* //////////////////// Telemedecine ///////////////////////////// Telemedecine */}

                  <Route path="/telemedecine*">
                    <TelemedecineRouter />
                  </Route>

                  {/* //////////////////// Telemedecine ///////////////////////////// Telemedecine */}
                  {/* //////////////////// Telemedecine ///////////////////////////// Telemedecine */}
                  {/* //////////////////// Telemedecine ///////////////////////////// Telemedecine */}


                  {/* ///////////////////// Configuration ////////////////////////////// Configuration */}
                  {/* ///////////////////// Configuration ////////////////////////////// Configuration */}
                  {/* ///////////////////// Configuration ////////////////////////////// Configuration */}

                  <Route path="/configuration*">
                    <ConfigurationRouter />
                  </Route>

                  {/* ///////////////////// Configuration ////////////////////////////// Configuration */}
                  {/* ///////////////////// Configuration ////////////////////////////// Configuration */}
                  {/* ///////////////////// Configuration ////////////////////////////// Configuration */}


                  {/* ///////////////////// Page test ////////////////////////////// Page test */}
                  {/* ///////////////////// Page test ////////////////////////////// Page test */}
                  {/* ///////////////////// Page test ////////////////////////////// Page test */}

                  <Route path="/test-app" component={TestComponent} />

                  {/* ///////////////////// Page test ////////////////////////////// Page test */}
                  {/* ///////////////////// Page test ////////////////////////////// Page test */}
                  {/* ///////////////////// Page test ////////////////////////////// Page test */}

                  {/* ///////////////////// Page Not Found ////////////////////////////// Page Not Found */}
                  {/* ///////////////////// Page Not Found ////////////////////////////// Page Not Found */}
                  {/* ///////////////////// Page Not Found ////////////////////////////// Page Not Found */}

                  <Route
                    path="/page-not-authorized"
                    component={PageNotAuthorized}
                  />

                  <Route path="*">
                    <PageNotFound />
                  </Route>

                  {/* ///////////////////// Page Not Found ////////////////////////////// Page Not Found */}
                  {/* ///////////////////// Page Not Found ////////////////////////////// Page Not Found */}
                  {/* ///////////////////// Page Not Found ////////////////////////////// Page Not Found */}
                </Switch>
              </Suspense>
            </Router>
          </ReportCenterContext.Provider>
        </QueryClientProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
