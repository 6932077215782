import React, { lazy } from "react"
import * as MyPrivateRouters from "../routes/PrivateRouter";
import { Switch } from 'react-router-dom';

const LayoutUrologiePatientAConsulter = lazy(() =>
    import(
        "../../components/urologie/patientAConsulter/LayoutUrologiePatientAConsulter"
    )
);

const UrologieObservation = lazy(() =>
    import("../../components/urologie/observation/UrologieObservation")
);
const UrologieObservationTerminees = lazy(() =>
    import("../../components/urologie/observationTerminees/UrologieObservationTerminees")
);

const LayoutUrologieConsultationListe = lazy(() =>
    import("../../components/urologie/consultation/LayoutUrologieConsultationListe")
);
const LayoutUrologieOrdonnanceListe = lazy(() =>
    import("../../components/urologie/ordonnance/liste/LayoutUrologieOrdonnanceListe")
);
const LayoutUrologieOrdonnanceDetails = lazy(() =>
    import(
        "../../components/urologie/ordonnance/detail/LayoutUrologieOrdonnanceDetails"
    )
);

const LayoutUrologieConsultationCreate = lazy(() =>
    import(
        "../../components/urologie/patientAConsulterCreate/LayoutUrologieConsultationCreate"
    )
);
const LayoutUrologieConsultationView = lazy(() =>
    import(
        "../../components/urologie/patientAConsulterDetail/LayoutUrologieConsultationView"
    )
);
const LayoutUrologieCreateConsultationSortie = lazy(() =>
    import(
        "../../components/urologie/patientAConsulterIssuSortie/LayoutUrologieCreateConsultationSortie"
    )
);
const LayoutUrologieCreateConsultationSortieDemandeHospitalisation = lazy(() =>
    import(
        "../../components/urologie/patientAConsulterIssuSortie/DemandeHospitalisation/LayoutUrologieCreateConsultationSortieDemandeHospitalisation"
    )
);
const LayoutUrologieCreateConsultationSortieMiseEnObservation = lazy(() =>
    import(
        "../../components/urologie/patientAConsulterIssuSortie/MiseEnObservation/LayoutUrologieCreateConsultationSortieMiseEnObservation"
    )
);
const LayoutUrologieCreateConsultationRefereExterne = lazy(() =>
    import(
        "../../components/urologie/patientAConsulterIssuSortie/RefereExterne/LayoutUrologieCreateConsultationRefereExterne"
    )
);
const LayoutUrologieCreateConsultationRefereInterne = lazy(() =>
    import(
        "../../components/urologie/patientAConsulterIssuSortie/RefereInterne/LayoutUrologieCreateConsultationRefereInterne"
    )
);

const UrologieRefExterne = lazy(() =>
    import("../../components/urologie/refExterne/UrologieRefExterne")
);
const UrologieRefInterne = lazy(() =>
    import("../../components/urologie/refInterne/UrologieRefInterne")
);

const LayoutUrologieDemandeHospitalisationListe = lazy(() =>
    import(
        "../../components/urologie/demandeHospitalisation/LayoutUrologieDemandeHospitalisationListe"
    )
);

const LayoutUrologieHospitalisationListe = lazy(() =>
    import(
        "../../components/urologie/hospitalisation/LayoutUrologieHospitalisationListe"
    )
);
const LayoutUrologieHospitalisationConsultationCreate = lazy(() =>
    import(
        "../../components/urologie/hospitalisation/consultation/LayoutUrologieHospitalisationConsultationCreate"
    )
);
const LayoutUrologieHospitalisationConsultationSortie = lazy(() =>
    import(
        "../../components/urologie/hospitalisation/issueSortie/LayoutUrologieHospitalisationConsultationSortie"
    )
);

const UrologieExamensDetail = lazy(() =>
    import("../../components/urologie/Examens/detail")
);
const UrologieExamensList = lazy(() =>
    import("../../components/urologie/Examens/list")
);

const LayoutUrologieDecesConsultationSortie = lazy(() =>
    import(
        "../../components/urologie/patientAConsulterIssuSortie/IssueDeDeces/LayoutUrologieDecesConsultationSortie"
    )
);

const LayoutUrologieFeuilleDeSoins = lazy(() =>
    import("../../components/urologie/feuilleDeSoins/LayoutUrologieFeuilleDeSoins")
);

const LayoutUrologieDecesListe = lazy(() =>
    import("../../components/urologie/Deces/Liste/LayoutUrologieDecesListe")
);
const LayoutUrologieDecesCertificat = lazy(() =>
    import("../../components/urologie/Deces/Certificat/LayoutUrologieDecesCertificat")
);

const AgendaUrologie = lazy(() =>
    import("../../components/urologie/rendezVous/agendaDetail/AgendaUrologie")
);
const LayoutUrologieCreateConsultationRendezVous = lazy(() =>
    import(
        "../../components/urologie/patientAConsulterIssuSortie/RendezVous/LayoutUrologieCreateConsultationRendezVous"
    )
);
const LayoutUrologieRdvListe = lazy(() =>
    import("../../components/urologie/rendezVous/Liste/LayoutUrologieRdvListe")
);
const LayoutUrologieRdvLastConsultation = lazy(() =>
    import("../../components/urologie/rendezVous/LastConsultation/LayoutUrologieRdvLastConsultation")
);
const LayoutUrologieRdvNewConsultation = lazy(() =>
    import("../../components/urologie/rendezVous/NewConsultation/LayoutUrologieRdvNewConsultation")
);
const LayoutUrologieVoirResultatsExamens = lazy(() =>
    import(
        "../../components/urologie/Examens/resultatsExamensVoir/LayoutUrologieVoirResultatsExamens"
    )
);

const RapportUrologie = lazy(() =>  import("../../components/urologie/rapports/RapportUrologie"));

const LayoutUrologieControleMajor = lazy(() => 
    import("../../components/urologie/controleMajor/LayoutUrologieControleMajor")
)
  

export default function UrologieRouter() {
  return (
    <Switch>
        <MyPrivateRouters.PrivateRouterUrologie
        path="/urologie"
        component={LayoutUrologiePatientAConsulter}
        />

        <MyPrivateRouters.PrivateRouterUrologie
        path="/urologie-observation-"
        component={UrologieObservation}
        />

        <MyPrivateRouters.PrivateRouterUrologie
        path="/urologie-observation-encours"
        component={UrologieObservation}
        />

        <MyPrivateRouters.PrivateRouterUrologie
        path="/urologie-observation-terminee"
        component={UrologieObservationTerminees}
        />

        <MyPrivateRouters.PrivateRouterUrologie
        path="/urologie-consultation"
        component={LayoutUrologieConsultationListe}
        />

        <MyPrivateRouters.PrivateRouterUrologie
        path="/urologie-ordonnance"
        component={LayoutUrologieOrdonnanceListe}
        />

        <MyPrivateRouters.PrivateRouterUrologie
        path="/urologie-ordonnance-voir/:id"
        component={LayoutUrologieOrdonnanceDetails}
        />

        <MyPrivateRouters.PrivateRouterUrologie
        path="/urologie-consultation-create/:id"
        component={LayoutUrologieConsultationCreate}
        />

        <MyPrivateRouters.PrivateRouterUrologie
        path="/urologie-consultation-voir-detail/:id"
        component={LayoutUrologieConsultationView}
        />

        <MyPrivateRouters.PrivateRouterUrologie
        path="/urologie-consultation-sortie/:id"
        component={LayoutUrologieCreateConsultationSortie}
        />

        <MyPrivateRouters.PrivateRouterUrologie
        path="/urologie-consultation-sortie-demande-hospitalisation/:id"
        component={
            LayoutUrologieCreateConsultationSortieDemandeHospitalisation
        }
        />

        <MyPrivateRouters.PrivateRouterUrologie
        path="/urologie-consultation-sortie-mise-en-observation/:id"
        component={
            LayoutUrologieCreateConsultationSortieMiseEnObservation
        }
        />

        <MyPrivateRouters.PrivateRouterUrologie
        path="/urologie-consultation-sortie-refere-interne/:id"
        component={LayoutUrologieCreateConsultationRefereInterne}
        />

        <MyPrivateRouters.PrivateRouterUrologie
        path="/urologie-consultation-sortie-refere-externe/:id"
        component={LayoutUrologieCreateConsultationRefereExterne}
        />

        <MyPrivateRouters.PrivateRouterUrologie
        path="/urologie-referencements-externe"
        component={UrologieRefExterne}
        />

        <MyPrivateRouters.PrivateRouterUrologie
        path="/urologie-referencements-interne"
        component={UrologieRefInterne}
        />

        <MyPrivateRouters.PrivateRouterUrologie
        path="/urologie-demande-hospitalisation"
        component={LayoutUrologieDemandeHospitalisationListe}
        />

        <MyPrivateRouters.PrivateRouterUrologie
        path="/urologie-hospitalisation"
        component={LayoutUrologieHospitalisationListe}
        />

        <MyPrivateRouters.PrivateRouterUrologie
        path="/urologie-hospitalisation-consultation/:id"
        component={LayoutUrologieHospitalisationConsultationCreate}
        />

        <MyPrivateRouters.PrivateRouterUrologie
        path="/urologie-hospitalisation-sortie/:id"
        component={LayoutUrologieHospitalisationConsultationSortie}
        />

        <MyPrivateRouters.PrivateRouterUrologie
        path="/urologie-examens-detail/:id/"
        component={UrologieExamensDetail}
        />

        <MyPrivateRouters.PrivateRouterUrologie
        path="/urologie-examens"
        component={UrologieExamensList}
        />

        <MyPrivateRouters.PrivateRouterUrologie
        path="/urologie-consultation-sortie-rdv/:id"
        component={LayoutUrologieCreateConsultationRendezVous}
        />

        <MyPrivateRouters.PrivateRouterUrologie
        path="/urologie-rdv"
        component={LayoutUrologieRdvListe}
        />

        <MyPrivateRouters.PrivateRouterUrologie
        path="/urologie-rdv-last-consultation"
        component={LayoutUrologieRdvLastConsultation}
        />

        <MyPrivateRouters.PrivateRouterUrologie
        path="/urologie-rdv-new-consultation"
        component={LayoutUrologieRdvNewConsultation}
        />

        <MyPrivateRouters.PrivateRouterUrologie
        path="/urologie-rdv-agenda"
        component={AgendaUrologie}
        />

        <MyPrivateRouters.PrivateRouterUrologie
        path="/urologie-consultation-sortie-deces/:id"
        component={LayoutUrologieDecesConsultationSortie}
        />

        <MyPrivateRouters.PrivateRouterUrologie
        path="/urologie-observation-consultation/:id"
        component={LayoutUrologieConsultationCreate}
        />

        <MyPrivateRouters.PrivateRouterUrologie
        path="/urologie-exit-observation/:id"
        component={LayoutUrologieCreateConsultationSortie}
        />

        <MyPrivateRouters.PrivateRouterUrologie
        path="/urologie-observation-exit-referencement-interne/:id"
        component={LayoutUrologieCreateConsultationRefereInterne}
        />

        <MyPrivateRouters.PrivateRouterUrologie
        path="/urologie-observation-exit-referencement-externe/:id"
        component={LayoutUrologieCreateConsultationRefereExterne}
        />

        <MyPrivateRouters.PrivateRouterUrologie
        path="/urologie-observation-exit-rdv/:id"
        component={LayoutUrologieCreateConsultationRendezVous}
        />

        <MyPrivateRouters.PrivateRouterUrologie
        path="/urologie-observation-exit-deces/:id"
        component={LayoutUrologieDecesConsultationSortie}
        />

        <MyPrivateRouters.PrivateRouterUrologie
        path="/urologie-observation-exit-demande-hospitalisation/:id"
        component={
            LayoutUrologieCreateConsultationSortieDemandeHospitalisation
        }
        />

        <MyPrivateRouters.PrivateRouterUrologie
        path="/urologie-feuille-de-soins/:id"
        component={LayoutUrologieFeuilleDeSoins}
        />

        <MyPrivateRouters.PrivateRouterUrologie
        path="/urologie-patients-decedes"
        component={LayoutUrologieDecesListe}
        />

        <MyPrivateRouters.PrivateRouterUrologie
        path="/urologie-patients-decedes-certificat/:id"
        component={LayoutUrologieDecesCertificat}
        />

        <MyPrivateRouters.PrivateRouterUrologie
        path="/urologie-examens-resultat-voir/:id"
        component={LayoutUrologieVoirResultatsExamens}
        />

        <MyPrivateRouters.PrivateRouterUrologie
        path="/urologie-rapports"
        component={RapportUrologie}
        />
        
        <MyPrivateRouters.PrivateRouterUrologie
        path="/urologie-controle-major"
        component={LayoutUrologieControleMajor}
        />
    </Switch>
  )
}
