export const professionnelSanteTypes = {
  PROFESSIONNEL_LIST_REQUEST: "PROFESSIONNEL_LIST_REQUEST",
  PROFESSIONNEL_LIST_SUCCESS: "PROFESSIONNEL_LIST_SUCCESS",
  PROFESSIONNEL_LIST_FAIL: "PROFESSIONNEL_LIST_FAIL",
  PROFESSIONNEL_LIST_FAIL_403: "PROFESSIONNEL_LIST_FAIL_403",
  PROFESSIONNEL_LIST_FAIL_500: "PROFESSIONNEL_LIST_FAIL_500",

  PROFESSIONNEL_LIST_REFRESH_SUCCESS: "PROFESSIONNEL_LIST_REFRESH_SUCCESS",
  PROFESSIONNEL_LIST_REFRESH_FAIL: "PROFESSIONNEL_LIST_REFRESH_FAIL",
  PROFESSIONNEL_LIST_REFRESH_REQUEST: "PROFESSIONNEL_LIST_REFRESH_REQUEST",
  PROFESSIONNEL_LIST_REFRESH_FAIL_403: "PROFESSIONNEL_LIST_REFRESH_FAIL_403",
  PROFESSIONNEL_LIST_REFRESH_FAIL_500: "PROFESSIONNEL_LIST_REFRESH_FAIL_500",

  PROFESSIONNEL_LIST_BY_CENTRE_SANTE_REQUEST: "PROFESSIONNEL_LIST_BY_CENTRE_SANTE_REQUEST",
  PROFESSIONNEL_LIST_BY_CENTRE_SANTE_SUCCESS: "PROFESSIONNEL_LIST_BY_CENTRE_SANTE_SUCCESS",
  PROFESSIONNEL_LIST_BY_CENTRE_SANTE_FAIL: "PROFESSIONNEL_LIST_BY_CENTRE_SANTE_FAIL",
  PROFESSIONNEL_LIST_BY_CENTRE_SANTE_FAIL_403: "PROFESSIONNEL_LIST_BY_CENTRE_SANTE_FAIL_403",
  PROFESSIONNEL_LIST_BY_CENTRE_SANTE_FAIL_500: "PROFESSIONNEL_LIST_BY_CENTRE_SANTE_FAIL_500",

  PROFESSIONNEL_GET_LOADING: "PROFESSIONNEL_GET_LOADING",
  PROFESSIONNEL_GET_SUCCESS: "PROFESSIONNEL_GET_SUCCESS",
  PROFESSIONNEL_GET_FAILED: "PROFESSIONNEL_GET_FAILED",
  PROFESSIONNEL_GET_FAILED_403: "PROFESSIONNEL_GET_FAILED_403",
  PROFESSIONNEL_GET_FAILED_500: "PROFESSIONNEL_GET_FAILED_500",

  PROFESSIONNEL_UPDATE_LOADING: "PROFESSIONNEL_UPDATE_LOADING",
  PROFESSIONNEL_UPDATE_SUCCESS: "PROFESSIONNEL_UPDATE_SUCCESS",
  PROFESSIONNEL_UPDATE_FAILED: "PROFESSIONNEL_UPDATE_FAILED",

  PROFESSIONNEL_DELETE_LOADING: "PROFESSIONNEL_DELETE_LOADING",
  PROFESSIONNEL_DELETE_SUCCESS: "PROFESSIONNEL_DELETE_SUCCESS",
  PROFESSIONNEL_DELETE_FAILED: "PROFESSIONNEL_DELETE_FAILED",
  PROFESSIONNEL_DELETE_FAILED_500: "PROFESSIONNEL_DELETE_FAILED_500",
  PROFESSIONNEL_DELETE_FAILED_403: "PROFESSIONNEL_DELETE_FAILED_403",

  PROFESSIONNEL_CLEAR_STATE: "PROFESSIONNEL_CLEAR_STATE",
};
