import * as demandeHospitalisationTypes from "../../types/demandeHospitalisationTypes";
import { AUTH_ERROR, LOGOUT_SUCCESS, TOKEN_REFRESH_FAIL } from "../../types/userTypes";


const initialState = {
    demandeHospitalisation:[],
    motifsHospitalisation: [],
    isLoading: false,
    isError:false,
    error: ""
};

//eslint-disable-next-line
export const demandeHospitalisationReducers = (state = initialState, action) => {
    switch (action.type) {
        case demandeHospitalisationTypes.DEMANDE_HOSPITALISATION_GET_DATA_LOADING:
            return {
                ...state,
                isLoading: true,
                error: "",
            };
        case demandeHospitalisationTypes.DEMANDE_HOSPITALISATION_GET_DATA_SUCCESS:
            return {
                ...state,
                isLoading: false,
                demandeHospitalisation: action.payload,
                error: "",
            };
        case demandeHospitalisationTypes.DEMANDE_HOSPITALISATION_GET_DATA_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };
        case "FETCH_MOTIF_HOSPITALISATION_REQUEST":
            return {
                ...state,
                isLoading: true,
                motifsHospitalisation: [],
            }
        case "FETCH_MOTIF_HOSPITALISATION_SUCCESS":
            return {
                ...state,
                isLoading: false,
                motifsHospitalisation: action.payload,
            }
        case "FETCH_MOTIF_HOSPITALISATION_FAILED":
            return {
                ...state,
                isLoading: false,
                motifsHospitalisation: [],
            }
      
        
        case AUTH_ERROR:
        case LOGOUT_SUCCESS:
        case TOKEN_REFRESH_FAIL:
            localStorage.removeItem('token_access')
            localStorage.removeItem('token_refresh')
            return {
                ...state,
                isLoading: false,
                demandeHospitalisation: [],
                motifsHospitalisation: [],
                isError:false,
                error: "",
            }

        default:
            return state;
    }
};

