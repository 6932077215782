export const NIVEAU_ETUDE_GET_DATA_LOADING = 'NIVEAU_ETUDE_GET_DATA_LOADING'

export const NIVEAU_ETUDE_GET_DATA_SUCCESS = 'NIVEAU_ETUDE_GET_DATA_SUCCESS'

export const NIVEAU_ETUDE_GET_DATA_FAILED = 'NIVEAU_ETUDE_GET_DATA_FAILED'

export const NIVEAU_ETUDE_GET_DATA_FAILED_403 = "NIVEAU_ETUDE_GET_DATA_FAILED_403"

export const NIVEAU_ETUDE_GET_DATA_FAILED_500 = "NIVEAU_ETUDE_GET_DATA_FAILED_500"


export const NIVEAU_ETUDE_POST_DATA_LOADING = 'NIVEAU_ETUDE_POST_DATA_LOADING'

export const NIVEAU_ETUDE_POST_DATA_SUCCESS = 'NIVEAU_ETUDE_POST_DATA_SUCCESS'

export const NIVEAU_ETUDE_POST_DATA_FAILED = 'NIVEAU_ETUDE_POST_DATA_FAILED'

export const NIVEAU_ETUDE_POST_DATA_FAILED_403 = "NIVEAU_ETUDE_POST_DATA_FAILED_403"

export const NIVEAU_ETUDE_POST_DATA_FAILED_500 = "NIVEAU_ETUDE_POST_DATA_FAILED_500"


export const NIVEAU_ETUDE_PUT_DATA_LOADING = 'NIVEAU_ETUDE_PUT_DATA_LOADING'

export const NIVEAU_ETUDE_PUT_DATA_SUCCESS = 'NIVEAU_ETUDE_PUT_DATA_SUCCESS'

export const NIVEAU_ETUDE_PUT_DATA_FAILED = 'NIVEAU_ETUDE_PUT_DATA_FAILED'

export const NIVEAU_ETUDE_PUT_DATA_FAILED_403 = "NIVEAU_ETUDE_PUT_DATA_FAILED_403"

export const NIVEAU_ETUDE_PUT_DATA_FAILED_500 = "NIVEAU_ETUDE_PUT_DATA_FAILED_500"


export const NIVEAU_ETUDE_DELETE_DATA_LOADING = 'NIVEAU_ETUDE_DELETE_DATA_LOADING'

export const NIVEAU_ETUDE_DELETE_DATA_SUCCESS = 'NIVEAU_ETUDE_DELETE_DATA_SUCCESS'

export const NIVEAU_ETUDE_DELETE_DATA_FAILED = 'NIVEAU_ETUDE_DELETE_DATA_FAILED'

export const NIVEAU_ETUDE_DELETE_DATA_FAILED_403 = "NIVEAU_ETUDE_DELETE_DATA_FAILED_403"

export const NIVEAU_ETUDE_DELETE_DATA_FAILED_500 = "NIVEAU_ETUDE_DELETE_DATA_FAILED_500"


export const NIVEAU_ETUDE_GETS_DATA_LOADING = 'NIVEAU_ETUDE_GETS_DATA_LOADING'

export const NIVEAU_ETUDE_GETS_DATA_SUCCESS = 'NIVEAU_ETUDE_GETS_DATA_SUCCESS'

export const NIVEAU_ETUDE_GETS_DATA_FAILED = 'NIVEAU_ETUDE_GETS_DATA_FAILED'

export const NIVEAU_ETUDE_GETS_DATA_FAILED_403 = "NIVEAU_ETUDE_GETS_DATA_FAILED_403"

export const NIVEAU_ETUDE_GETS_DATA_FAILED_500 = "NIVEAU_ETUDE_GETS_DATA_FAILED_500"


export const NIVEAU_ETUDE_GETS_REFRESH_DATA_LOADING = 'NIVEAU_ETUDE_GETS_REFRESH_DATA_LOADING'

export const NIVEAU_ETUDE_GETS_REFRESH_DATA_SUCCESS = 'NIVEAU_ETUDE_GETS_REFRESH_DATA_SUCCESS'

export const NIVEAU_ETUDE_GETS_REFRESH_DATA_FAILED = 'NIVEAU_ETUDE_GETS_REFRESH_DATA_FAILED'

export const NIVEAU_ETUDE_GETS_REFRESH_DATA_FAILED_403 = "NIVEAU_ETUDE_GETS_REFRESH_DATA_FAILED_403"

export const NIVEAU_ETUDE_GETS_REFRESH_DATA_FAILED_500 = "NIVEAU_ETUDE_GETS_REFRESH_DATA_FAILED_500"


export const NIVEAU_ETUDE_CLEAR_STATE = "NIVEAU_ETUDE_CLEAR_STATE"