export const DEMANDE_EXAMEN_RADIOLOGIE_GET_LOADING =  "DEMANDE_EXAMEN_RADIOLOGIE_GET_LOADING"

export const DEMANDE_EXAMEN_RADIOLOGIE_GET_SUCCESS =  "DEMANDE_EXAMEN_RADIOLOGIE_GET_SUCCESS"

export const DEMANDE_EXAMEN_RADIOLOGIE_GET_FAILED =  "DEMANDE_EXAMEN_RADIOLOGIE_GET_FAILED"


export const DEMANDES_EXAMENS_RADIOLOGIE_GETS_LOADING =  "DEMANDES_EXAMENS_RADIOLOGIE_GETS_LOADING"

export const DEMANDES_EXAMENS_RADIOLOGIE_GETS_SUCCESS =  "DEMANDES_EXAMENS_RADIOLOGIE_GETS_SUCCESS"

export const DEMANDES_EXAMENS_RADIOLOGIE_GETS_FAILED =  "DEMANDES_EXAMENS_RADIOLOGIE_GETS_FAILED"


export const RESULTAT_DEMANDE_EXAMEN_RADIOLOGIE_GET_LOADING =  "RESULTAT_DEMANDE_EXAMEN_RADIOLOGIE_GET_LOADING"

export const RESULTAT_DEMANDE_EXAMEN_RADIOLOGIE_GET_SUCCESS =  "RESULTAT_DEMANDE_EXAMEN_RADIOLOGIE_GET_SUCCESS"

export const RESULTAT_DEMANDE_EXAMEN_RADIOLOGIE_GET_FAILED =  "RESULTAT_DEMANDE_EXAMEN_RADIOLOGIE_GET_FAILED"


export const RESULTAT_DEMANDE_EXAMEN_RADIOLOGIE_POST_LOADING =  "RESULTAT_DEMANDE_EXAMEN_RADIOLOGIE_POST_LOADING"

export const RESULTAT_DEMANDE_EXAMEN_RADIOLOGIE_POST_SUCCESS =  "RESULTAT_DEMANDE_EXAMEN_RADIOLOGIE_POST_SUCCESS"

export const RESULTAT_DEMANDE_EXAMEN_RADIOLOGIE_POST_FAILED =  "RESULTAT_DEMANDE_EXAMEN_RADIOLOGIE_POST_FAILED"


export const RESULTATS_DEMANDES_EXAMENS_RADIOLOGIES_GETS_LOADING =  "RESULTATS_DEMANDES_EXAMENS_RADIOLOGIES_GETS_LOADING"

export const RESULTATS_DEMANDES_EXAMENS_RADIOLOGIES_GETS_SUCCESS =  "RESULTATS_DEMANDES_EXAMENS_RADIOLOGIES_GETS_SUCCESS"

export const RESULTATS_DEMANDES_EXAMENS_RADIOLOGIES_GETS_FAILED =  "RESULTATS_DEMANDES_EXAMENS_RADIOLOGIES_GETS_FAILED"


export const RESULTATS_DEMANDES_EXAMENS_RADIOLOGIES_RESET =  "RESULTATS_DEMANDES_EXAMENS_RADIOLOGIES_RESET"


export const RESULTATS_DEMANDES_EXAMENS_RADIOLOGIES_RESET_AFTER =  "RESULTATS_DEMANDES_EXAMENS_RADIOLOGIES_RESET_AFTER"