import { patientAConsulterTypes } from "../types/patientAConsulterTypes";
import { AUTH_ERROR, LOGOUT_SUCCESS, TOKEN_REFRESH_FAIL } from "../types/userTypes";

const initialState = {
  isLoading: false,
  isLoadingRefresh: false,
  patients: [],
  error: null,

  isLoadingTransferer: false,
  isSuccessTransferer: false,
  isErreurTransferer: false,
  patientsTransferer: {},
};

const patientAConsulterReducer = (state = initialState, action) => {
  switch (action.type) {
    case patientAConsulterTypes.PATIENT_A_CONSULTER_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
        patients: [],
        error: null,
      };
    case patientAConsulterTypes.PATIENT_A_CONSULTER_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        patients: action.payload,
        error: null,
      };
    case patientAConsulterTypes.PATIENT_A_CONSULTER_LIST_FAIL:
      return {
        ...state,
        isLoading: false,
        patients: [],
        error: action.payload,
      };
    
    
    case patientAConsulterTypes.PATIENT_A_CONSULTER_LIST_REFRESH_REQUEST:
      return {
        ...state,
        isLoadingRefresh: true,
        error: null,
      };
    case patientAConsulterTypes.PATIENT_A_CONSULTER_LIST_REFRESH_SUCCESS:
      return {
        ...state,
        isLoadingRefresh: false,
        patients: action.payload,
        error: null,
      };
    case patientAConsulterTypes.PATIENT_A_CONSULTER_LIST_REFRESH_FAIL:
      return {
        ...state,
        isLoadingRefresh: false,
        patients: [],
        error: action.payload,
      };

    case patientAConsulterTypes.PATIENT_A_CONSULTER_TRANSFERER_REQUEST:
      return {
        ...state,
        isLoadingTransferer: true,
        isSuccessTransferer: false,
        isErreurTransferer: false,
        patientsTransferer: {},
        error: null,
      };
    case patientAConsulterTypes.PATIENT_A_CONSULTER_TRANSFERER_SUCCESS:
      return {
        ...state,
        isLoadingTransferer: false,
        isSuccessTransferer: true,
        isErreurTransferer: false,
        patientsTransferer: action.payload,
        error: null,
      };
    case patientAConsulterTypes.PATIENT_A_CONSULTER_TRANSFERER_FAIL:
      return {
        ...state,
        isLoadingTransferer: false,
        isSuccessTransferer: false,
        isErreurTransferer: true,
        patientsTransferer: {},
        error: action.payload,
      };

    case patientAConsulterTypes.PATIENT_A_CONSULTER_CLEAR:
      return {
        ...state,
        isLoadingTransferer: false,
        isSuccessTransferer: false,
        isErreurTransferer: false,
        patientsTransferer: {},
        error: null,
        isLoading: false,
        isLoadingRefresh: false,
      };

    case AUTH_ERROR:
    case LOGOUT_SUCCESS:
    case TOKEN_REFRESH_FAIL:
        localStorage.removeItem('token_access')
        localStorage.removeItem('token_refresh')
        return {
            ...state,
            isLoading: false,
            isLoadingRefresh: false,
            patients: [],
            error: null,
        }
    
    default:
      return state;
  }
};

export default patientAConsulterReducer;
