import React, { lazy } from "react"
import * as MyPrivateRouters from "../routes/PrivateRouter";
import { Switch } from 'react-router-dom';


const LayoutEchographieDemandesExamensListe = lazy(() =>
    import("../../components/echographie/demandesListe/LayoutEchographieDemandesExamensListe")
);
const LayoutEchographieResultatsListe = lazy(() =>
    import(
        "../../components/echographie/resultatsListe/LayoutEchographieResultatsListe"
    )
);
const LayoutEchographieResultatsCreate = lazy(() =>
    import(
        "../../components/echographie/resultatsCreate/LayoutEchographieResultatsCreate"
    )
);
const LayoutEchographieResultatsDetails = lazy(() =>
    import(
        "../../components/echographie/resultatsVoir/LayoutEchographieResultatsDetails"
    )
);

const LayoutEchographieRapports = lazy(() =>
    import(
        "../../components/echographie/rapports/LayoutEchographieRapports"
    )
);
  

export default function EchographieRouter() {
  return (
    <Switch>
        <MyPrivateRouters.PrivateRouterEchographie
        path="/echographie"
        component={LayoutEchographieDemandesExamensListe}
        />

        <MyPrivateRouters.PrivateRouterEchographie
        path="/echographie-resultats"
        component={LayoutEchographieResultatsListe}
        />

        <MyPrivateRouters.PrivateRouterEchographie
        path="/echographie-enregistrement-resultat/:id"
        component={LayoutEchographieResultatsCreate}
        />

        <MyPrivateRouters.PrivateRouterEchographie
        path="/echographie-resultat-voir/:id"
        component={LayoutEchographieResultatsDetails}
        />

        <MyPrivateRouters.PrivateRouterEchographie
        path="/echographie-rapports"
        component={LayoutEchographieRapports}
        />
    </Switch>
  )
}
