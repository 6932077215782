export const VACCINATION_GETS_LOADING = 'VACCINATION_GETS_LOADING'

export const VACCINATION_GETS_SUCCESS = 'VACCINATION_GETS_SUCCESS'

export const VACCINATION_GETS_FAILED = 'VACCINATION_GETS_FAILED'


export const VACCINATION_GET_LOADING = 'VACCINATION_GET_LOADING'

export const VACCINATION_GET_SUCCESS = 'VACCINATION_GET_SUCCESS'

export const VACCINATION_GET_FAILED = 'VACCINATION_GET_FAILED'


export const VACCINATION_POST_LOADING = 'VACCINATION_POST_LOADING'

export const VACCINATION_POST_SUCCESS = 'VACCINATION_POST_SUCCESS'

export const VACCINATION_POST_FAILED = 'VACCINATION_POST_FAILED'


export const VACCINATION_PUT_LOADING = 'VACCINATION_PUT_LOADING'

export const VACCINATION_PUT_SUCCESS = 'VACCINATION_PUT_SUCCESS'

export const VACCINATION_PUT_FAILED = 'VACCINATION_PUT_FAILED'

export const VACCINATION_CLEAR_STATE = "VACCINATION_CLEAR_STATE"