export const refInterneTypes = {
    REF_INTERNE_GET_REQUEST: "REF_INTERNE_GET_REQUEST",
    REF_INTERNE_GET_SUCCESS: "REF_INTERNE_GET_SUCCESS",
    REF_INTERNE_GET_FAIL: "REF_INTERNE_GET_FAIL",

    REF_INTERNE_GET_REFRESH_REQUEST: "REF_INTERNE_GET_REFRESH_REQUEST",
    REF_INTERNE_GET_REFRESH_SUCCESS: "REF_INTERNE_GET_REFRESH_SUCCESS",
    REF_INTERNE_GET_REFRESH_FAIL: "REF_INTERNE_GET_REFRESH_FAIL",

    PATIENT_ALL_REF_INTERNE_REQUEST: "PATIENT_ALL_REF_INTERNE_REQUEST",
    PATIENT_ALL_REF_INTERNE_SUCCESS: "PATIENT_ALL_REF_INTERNE_SUCCESS",
    PATIENT_ALL_REF_INTERNE_FAIL: "PATIENT_ALL_REF_INTERNE_FAIL",
}