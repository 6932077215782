export const REGION_SANITAIRE_GET_DATA_LOADING = 'REGION_SANITAIRE_GET_DATA_LOADING'

export const REGION_SANITAIRE_GET_DATA_SUCCESS = 'REGION_SANITAIRE_GET_DATA_SUCCESS'

export const REGION_SANITAIRE_GET_DATA_FAILED = 'REGION_SANITAIRE_GET_DATA_FAILED'

export const REGION_SANITAIRE_GET_DATA_FAILED_403 = "REGION_SANITAIRE_GET_DATA_FAILED_403"

export const REGION_SANITAIRE_GET_DATA_FAILED_500 = "REGION_SANITAIRE_GET_DATA_FAILED_500"


export const REGION_SANITAIRE_POST_DATA_LOADING = 'REGION_SANITAIRE_POST_DATA_LOADING'

export const REGION_SANITAIRE_POST_DATA_SUCCESS = 'REGION_SANITAIRE_POST_DATA_SUCCESS'

export const REGION_SANITAIRE_POST_DATA_FAILED = 'REGION_SANITAIRE_POST_DATA_FAILED'

export const REGION_SANITAIRE_POST_DATA_FAILED_403 = "REGION_SANITAIRE_POST_DATA_FAILED_403"

export const REGION_SANITAIRE_POST_DATA_FAILED_500 = "REGION_SANITAIRE_POST_DATA_FAILED_500"


export const REGION_SANITAIRE_PUT_DATA_LOADING = 'REGION_SANITAIRE_PUT_DATA_LOADING'

export const REGION_SANITAIRE_PUT_DATA_SUCCESS = 'REGION_SANITAIRE_PUT_DATA_SUCCESS'

export const REGION_SANITAIRE_PUT_DATA_FAILED = 'REGION_SANITAIRE_PUT_DATA_FAILED'

export const REGION_SANITAIRE_PUT_DATA_FAILED_403 = "REGION_SANITAIRE_PUT_DATA_FAILED_403"

export const REGION_SANITAIRE_PUT_DATA_FAILED_500 = "REGION_SANITAIRE_PUT_DATA_FAILED_500"


export const REGION_SANITAIRE_DELETE_DATA_LOADING = 'REGION_SANITAIRE_DELETE_DATA_LOADING'

export const REGION_SANITAIRE_DELETE_DATA_SUCCESS = 'REGION_SANITAIRE_DELETE_DATA_SUCCESS'

export const REGION_SANITAIRE_DELETE_DATA_FAILED = 'REGION_SANITAIRE_DELETE_DATA_FAILED'

export const REGION_SANITAIRE_DELETE_DATA_FAILED_403 = "REGION_SANITAIRE_DELETE_DATA_FAILED_403"

export const REGION_SANITAIRE_DELETE_DATA_FAILED_500 = "REGION_SANITAIRE_DELETE_DATA_FAILED_500"


export const REGION_SANITAIRE_GETS_DATA_LOADING = 'REGION_SANITAIRE_GETS_DATA_LOADING'

export const REGION_SANITAIRE_GETS_DATA_SUCCESS = 'REGION_SANITAIRE_GETS_DATA_SUCCESS'

export const REGION_SANITAIRE_GETS_DATA_FAILED = 'REGION_SANITAIRE_GETS_DATA_FAILED'

export const REGION_SANITAIRE_GETS_DATA_FAILED_403 = "REGION_SANITAIRE_GETS_DATA_FAILED_403"

export const REGION_SANITAIRE_GETS_DATA_FAILED_500 = "REGION_SANITAIRE_GETS_DATA_FAILED_500"


export const REGION_SANITAIRE_GETS_REFRESH_DATA_LOADING = 'REGION_SANITAIRE_GETS_REFRESH_DATA_LOADING'

export const REGION_SANITAIRE_GETS_REFRESH_DATA_SUCCESS = 'REGION_SANITAIRE_GETS_REFRESH_DATA_SUCCESS'

export const REGION_SANITAIRE_GETS_REFRESH_DATA_FAILED = 'REGION_SANITAIRE_GETS_REFRESH_DATA_FAILED'

export const REGION_SANITAIRE_GETS_REFRESH_DATA_FAILED_403 = "REGION_SANITAIRE_GETS_REFRESH_DATA_FAILED_403"

export const REGION_SANITAIRE_GETS_REFRESH_DATA_FAILED_500 = "REGION_SANITAIRE_GETS_REFRESH_DATA_FAILED_500"


export const REGION_SANITAIRE_CLEAR_STATE = "REGION_SANITAIRE_CLEAR_STATE"