import React, { lazy } from "react"
import * as MyPrivateRouters from "../routes/PrivateRouter";
import { Switch } from 'react-router-dom';

const LayoutCardiologiePatientAConsulter = lazy(() =>
    import(
      "../../components/cardiologie/patientAConsulter/LayoutCardiologiePatientAConsulter"
    )
);
const LayoutCardiologieConsultationListe = lazy(() =>
import(
    "../../components/cardiologie/consultation/LayoutCardiologieConsultationListe"
)
);
const LayoutCardiologieDemandeHospitalisationListe = lazy(() =>
import(
    "../../components/cardiologie/demandeHospitalisation/LayoutCardiologieDemandeHospitalisationListe"
)
);
const CardiologieObservation = lazy(() =>
import("../../components/cardiologie/observation/CardiologieObservation")
);

const CardiologieObservationTerminees = lazy(() =>
    import("../../components/cardiologie/observationTerminees/CardiologieObservationTerminees")
    );

const LayoutCardiologieCreateConsultation = lazy(() =>
import(
    "../../components/cardiologie/patientAConsulterCreate/LayoutCardiologieCreateConsultation"
)
);

const LayoutCardiologieViewConsultation = lazy(() =>
import(
    "../../components/cardiologie/patientAConsulterDetail/LayoutCardiologieViewConsultation"
)
);

const LayoutCreateConsultationSortieCardiologie = lazy(() =>
import(
    "../../components/cardiologie/patientAConsulterIssuSortie/LayoutCreateConsultationSortieCardiologie"
)
);

const LayoutCreateConsultationSortieDemandeHospitalisationCardiologie = lazy(
() =>
    import(
    "../../components/cardiologie/patientAConsulterIssuSortie/DemandeHospitalisation/LayoutCreateConsultationSortieDemandeHospitalisationCardiologie"
    )
);
const LayoutCreateConsultationSortieMiseEnObservationCardiologie = lazy(() =>
import(
    "../../components/cardiologie/patientAConsulterIssuSortie/MiseEnObservation/LayoutCreateConsultationSortieMiseEnObservationCardiologie"
)
);

const LayoutCreateConsultationRefereInterneCardiologie = lazy(() =>
import(
    "../../components/cardiologie/patientAConsulterIssuSortie/RefereInterne/LayoutCreateConsultationRefereInterneCardiologie"
)
);
const LayoutCreateConsultationRefereExterneCardiologie = lazy(() =>
import(
    "../../components/cardiologie/patientAConsulterIssuSortie/RefereExterne/LayoutCreateConsultationRefereExterneCardiologie"
)
);

const LayoutCardiologieOrdonnanceDetails = lazy(() =>
import(
    "../../components/cardiologie/ordonnance/detail/LayoutCardiologieOrdonnanceDetails"
)
);
const LayoutCardiologieOrdonnanceListe = lazy(() =>
import(
    "../../components/cardiologie/ordonnance/liste/LayoutCardiologieOrdonnanceListe"
)
);

const LayoutCardiologieFeuilleDeSoins = lazy(() =>
import(
    "../../components/cardiologie/feuilleDeSoins/LayoutCardiologieFeuilleDeSoins"
)
);

const LayoutCardiologieHospitalisationListe = lazy(() =>
import(
    "../../components/cardiologie/hospitalisation/LayoutCardiologieHospitalisationListe"
)
);

const LayoutCardiologieHospitalisationConsultationCreate = lazy(() =>
import(
    "../../components/cardiologie/hospitalisation/consultation/LayoutCardiologieHospitalisationConsultationCreate"
)
);
const LayoutCardiologieHospitalisationConsultationSortie = lazy(() =>
import(
    "../../components/cardiologie/hospitalisation/issueSortie/LayoutCardiologieHospitalisationConsultationSortie"
)
);

const CardiologieRefExterne = lazy(() =>
import("../../components/cardiologie/refExterne/CardiologieRefExterne")
);
const CardiologieRefInterne = lazy(() =>
import("../../components/cardiologie/refInterne/CardiologieRefInterne")
);
const LayoutCardiologieDecesConsultationSortie = lazy(() =>
import(
    "../../components/cardiologie/patientAConsulterIssuSortie/IssueDeDeces/LayoutCardiologieDecesConsultationSortie"
)
);

const DemandeHospiObservationCardio = lazy(() =>
import(
    "../../components/cardiologie/patientAConsulterIssuSortie/MiseEnObservation/DemandeHospitalisation/LayoutCreateConsultationSortieDemandeHospitalisation"
)
);
const RefExterneObservationCardio = lazy(() =>
import(
    "../../components/cardiologie/patientAConsulterIssuSortie/MiseEnObservation/RefereExterne/LayoutCreateConsultationRefereExterne"
)
);
const RefInterneObservationCardio = lazy(() =>
import(
    "../../components/cardiologie/patientAConsulterIssuSortie/MiseEnObservation/RefereInterne/LayoutCreateConsultationRefereInterne"
)
);

const CardiologieExamensDetail = lazy(() =>
import("../../components/cardiologie/Examens/detail")
);
const CardiologieExamensList = lazy(() =>
import("../../components/cardiologie/Examens/list")
);

const LayoutCardiologieDecesListe = lazy(() =>
import("../../components/cardiologie/Deces/Liste/LayoutCardiologieDecesListe")
);
const LayoutCardiologieDecesCertificat = lazy(() =>
import(
    "../../components/cardiologie/Deces/Certificat/LayoutCardiologieDecesCertificat"
)
);

const AgendaCardiologie = lazy(() =>
import("../../components/cardiologie/rendezVous/agendaDetail/AgendaCardiologie")
);
const LayoutCardiologieCreateConsultationRendezVous = lazy(() =>
import(
    "../../components/cardiologie/patientAConsulterIssuSortie/RendezVous/LayoutCardiologieCreateConsultationRendezVous"
)
);
const LayoutCardiologieRdvListe = lazy(() =>
import("../../components/cardiologie/rendezVous/Liste/LayoutCardiologieRdvListe")
);
const LayoutCardioRdvLastConsultation = lazy(() =>
import("../../components/cardiologie/rendezVous/LastConsultation/LayoutCardioRdvLastConsultation")
);
const LayoutCardioRdvNewConsultation = lazy(() =>
import("../../components/cardiologie/rendezVous/NewConsultation/LayoutCardioRdvNewConsultation")
);
const LayoutVoirCardiologieResultatsExamens = lazy(() =>
import(
    "../../components/cardiologie/Examens/resultatsExamensVoir/LayoutVoirMedecineGeneralResultatsExamens"
)
);

const RapportCardiologie = lazy(() => import("../../components/cardiologie/rapports/RapportCardiologie"));

const LayoutCardiologieControleMajor = lazy(() => 
import("../../components/cardiologie/controleMajor/LayoutCardiologieControleMajor")
)
  

export default function CardiologieRouter() {
  return (
    <Switch>
        <MyPrivateRouters.PrivateRouterCardiologie
        path="/cardiologie"
        component={LayoutCardiologiePatientAConsulter}
        />

        <MyPrivateRouters.PrivateRouterCardiologie
        path="/cardiologie-observation-encours"
        component={CardiologieObservation}
        />

        <MyPrivateRouters.PrivateRouterCardiologie
        path="/cardiologie-observation-terminee"
        component={CardiologieObservationTerminees}
        />

        <MyPrivateRouters.PrivateRouterCardiologie
        path="/cardiologie-consultation"
        component={LayoutCardiologieConsultationListe}
        />

        <MyPrivateRouters.PrivateRouterCardiologie
        path="/cardiologie-ordonnance"
        component={LayoutCardiologieOrdonnanceListe}
        />

        <MyPrivateRouters.PrivateRouterCardiologie
        path="/cardiologie-ordonnance-voir/:id"
        component={LayoutCardiologieOrdonnanceDetails}
        />

        <MyPrivateRouters.PrivateRouterCardiologie
        path="/cardiologie-rdv"
        component={LayoutCardiologieRdvListe}
        />

        <MyPrivateRouters.PrivateRouterCardiologie
        path="/cardiologie-rdv-last-consultation/:id"
        component={LayoutCardioRdvLastConsultation}
        />

        <MyPrivateRouters.PrivateRouterCardiologie
        path="/cardiologie-rdv-new-consultation/:id"
        component={LayoutCardioRdvNewConsultation}
        />

        <MyPrivateRouters.PrivateRouterCardiologie
        path="/cardiologie-rdv-agenda/"
        component={AgendaCardiologie}
        />

        <MyPrivateRouters.PrivateRouterCardiologie
        path="/cardiologie-consultation-create/:id"
        component={LayoutCardiologieCreateConsultation}
        />

        <MyPrivateRouters.PrivateRouterCardiologie
        path="/cardiologie-consultation-voir-detail/:id"
        component={LayoutCardiologieViewConsultation}
        />

        <MyPrivateRouters.PrivateRouterCardiologie
        path="/cardiologie-demande-hospitalisation"
        component={LayoutCardiologieDemandeHospitalisationListe}
        />

        <MyPrivateRouters.PrivateRouterCardiologie
        path="/cardiologie-hospitalisation"
        component={LayoutCardiologieHospitalisationListe}
        />

        <MyPrivateRouters.PrivateRouterCardiologie
        path="/cardiologie-hospitalisation-consultation/:id"
        component={LayoutCardiologieHospitalisationConsultationCreate}
        />

        <MyPrivateRouters.PrivateRouterCardiologie
        path="/cardiologie-hospitalisation-sortie/:id"
        component={LayoutCardiologieHospitalisationConsultationSortie}
        />

        <MyPrivateRouters.PrivateRouterCardiologie
        path="/cardiologie-feuille-de-soins/:id"
        component={LayoutCardiologieFeuilleDeSoins}
        />

        <MyPrivateRouters.PrivateRouterCardiologie
        path="/cardiologie-consultation-sortie/:id"
        component={LayoutCreateConsultationSortieCardiologie}
        />

        <MyPrivateRouters.PrivateRouterCardiologie
        path="/cardiologie-consultation-sortie-demande-hospitalisation/:id"
        component={
            LayoutCreateConsultationSortieDemandeHospitalisationCardiologie
        }
        />

        <MyPrivateRouters.PrivateRouterCardiologie
        path="/cardiologie-consultation-sortie-mise-en-observation/:id"
        component={
            LayoutCreateConsultationSortieMiseEnObservationCardiologie
        }
        />

        <MyPrivateRouters.PrivateRouterCardiologie
        path="/cardiologie-consultation-sortie-refere-interne/:id"
        component={LayoutCreateConsultationRefereInterneCardiologie}
        />

        <MyPrivateRouters.PrivateRouterCardiologie
        path="/cardiologie-consultation-sortie-refere-externe/:id"
        component={LayoutCreateConsultationRefereExterneCardiologie}
        />

        <MyPrivateRouters.PrivateRouterCardiologie
        path="/cardiologie-consultation-sortie-issue-deces/:id"
        component={LayoutCardiologieDecesConsultationSortie}
        />

        <MyPrivateRouters.PrivateRouterCardiologie
        path="/cardiologie-consultation-sortie-rdv/:id"
        component={LayoutCardiologieCreateConsultationRendezVous}
        />

        <MyPrivateRouters.PrivateRouterCardiologie
        path="/cardiologie-exit-observation/:id"
        component={LayoutCreateConsultationSortieCardiologie}
        />

        <MyPrivateRouters.PrivateRouterCardiologie
        path="/cardiologie-observation-consultation/:id"
        component={LayoutCardiologieCreateConsultation}
        />

        <MyPrivateRouters.PrivateRouterCardiologie
        path="/cardiologie-observation-exit-demande-hospitalisation/:id"
        component={DemandeHospiObservationCardio}
        />

        <MyPrivateRouters.PrivateRouterCardiologie
        path="/cardiologie-observation-exit-reference-externe/:id"
        component={RefExterneObservationCardio}
        />

        <MyPrivateRouters.PrivateRouterCardiologie
        path="/cardiologie-observation-exit-reference-interne/:id"
        component={RefInterneObservationCardio}
        />

        <MyPrivateRouters.PrivateRouterCardiologie
        path="/cardiologie-observation-exit-deces/:id"
        component={LayoutCardiologieDecesConsultationSortie}
        />

        <MyPrivateRouters.PrivateRouterCardiologie
        path="/cardiologie-observation-exit-rdv/:id"
        component={LayoutCardiologieCreateConsultationRendezVous}
        />

        <MyPrivateRouters.PrivateRouterCardiologie
        path="/cardiologie-examens-detail/:id/"
        component={CardiologieExamensDetail}
        />

        <MyPrivateRouters.PrivateRouterCardiologie
        path="/cardiologie-examens"
        component={CardiologieExamensList}
        />

        <MyPrivateRouters.PrivateRouterCardiologie
        path="/cardiologie-patients-decedes"
        component={LayoutCardiologieDecesListe}
        />

        <MyPrivateRouters.PrivateRouterCardiologie
        path="/cardiologie-referencements-externe"
        component={CardiologieRefExterne}
        />

        <MyPrivateRouters.PrivateRouterCardiologie
        path="/cardiologie-referencements-interne"
        component={CardiologieRefInterne}
        />

        <MyPrivateRouters.PrivateRouterCardiologie
        path="/cardiologie-patients-decedes-certificat/:id"
        component={LayoutCardiologieDecesCertificat}
        />

        <MyPrivateRouters.PrivateRouterCardiologie
        path="/cardiologie-examens-resultat-voir/:id"
        component={LayoutVoirCardiologieResultatsExamens}
        />

        <MyPrivateRouters.PrivateRouterCardiologie
        path="/cardiologie-rapports"
        component={RapportCardiologie}
        />

        <MyPrivateRouters.PrivateRouterCardiologie
        path="/cardiologie-controle-major"
        component={LayoutCardiologieControleMajor}
        />
    </Switch>
  )
}
