import { observationTypes } from "../../types/observationTypes";
import { AUTH_ERROR, LOGOUT_SUCCESS, TOKEN_REFRESH_FAIL } from "../../types/userTypes";

const initialState = {
  isLoading: false,
  isLoadingRefresh: false,
  observations: [],
  patientAllObservations: [],
  error: "",
};

const observationReducer = (state = initialState, action) => {
  switch (action.type) {
    case observationTypes.OBSERVATION_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: "",
      };
    case observationTypes.OBSERVATION_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        observations: action.payload,
        error: "",
      };
    case observationTypes.OBSERVATION_LIST_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    
    
    case observationTypes.OBSERVATION_LIST_REFRESH_REQUEST:
      return {
        ...state,
        isLoadingRefresh: true,
        error: "",
      };
    case observationTypes.OBSERVATION_LIST_REFRESH_SUCCESS:
      return {
        ...state,
        isLoadingRefresh: false,
        observations: action.payload,
        error: "",
      };
    case observationTypes.OBSERVATION_LIST_REFRESH_FAIL:
      return {
        ...state,
        isLoadingRefresh: false,
        error: action.payload,
      };
    case observationTypes.PATIENT_ALL_OBSERVATIONS_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: "",
      };
    case observationTypes.PATIENT_ALL_OBSERVATIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        patientAllObservations: action.payload,
        error: "",
      };
    case observationTypes.PATIENT_ALL_OBSERVATIONS_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case AUTH_ERROR:
    case LOGOUT_SUCCESS:
    case TOKEN_REFRESH_FAIL:
        localStorage.removeItem('token_access')
        localStorage.removeItem('token_refresh')
        return {
            ...state,
            isLoading: false,
            isLoadingRefresh: false,
            observations: [],
            error: "",
        }

    default:
      return state;
  }
};

export default observationReducer;
