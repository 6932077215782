
export const blocOperatoireParametreTypes = {
    BLOC_OPERATOIRE_LIST_REQUEST: "BLOC_OPERATOIRE_LIST_REQUEST",
    BLOC_OPERATOIRE_LIST_SUCCESS: "BLOC_OPERATOIRE_LIST_SUCCESS",
    BLOC_OPERATOIRE_LIST_FAIL: "BLOC_OPERATOIRE_LIST_FAIL",

    BLOC_OPERATOIRE_LIST_REFRESH_REQUEST: "BLOC_OPERATOIRE_LIST_REFRESH_REQUEST",
    BLOC_OPERATOIRE_LIST_REFRESH_SUCCESS: "BLOC_OPERATOIRE_LIST_REFRESH_SUCCESS",
    BLOC_OPERATOIRE_LIST_REFRESH_FAIL: "BLOC_OPERATOIRE_LIST_REFRESH_FAIL",

    BLOC_OPERATOIRE_LIST_DISPONIBLE_REQUEST: "BLOC_OPERATOIRE_LIST_DISPONIBLE_REQUEST",
    BLOC_OPERATOIRE_LIST_DISPONIBLE_SUCCESS: "BLOC_OPERATOIRE_LIST_DISPONIBLE_SUCCESS",
    BLOC_OPERATOIRE_LIST_DISPONIBLE_FAIL: "BLOC_OPERATOIRE_LIST_DISPONIBLE_FAIL",

    BLOC_OPERATOIRE_POST_SUCCESS: 'BLOC_OPERATOIRE_POST_SUCCESS',
    BLOC_OPERATOIRE_POST_FAILED: 'BLOC_OPERATOIRE_POST_FAILED',
    BLOC_OPERATOIRE_POST_LOADING: 'BLOC_OPERATOIRE_POST_LOADING',
    BLOC_OPERATOIRE_DETAIL_REQUEST: 'BLOC_OPERATOIRE_DETAIL_REQUEST',
    BLOC_OPERATOIRE_DETAIL_SUCCESS: 'BLOC_OPERATOIRE_DETAIL_SUCCESS',
    BLOC_OPERATOIRE_DETAIL_FAIL: 'BLOC_OPERATOIRE_DETAIL_FAIL',
    BLOC_OPERATOIRE_UPDATE_REQUEST: 'BLOC_OPERATOIRE_UPDATE_REQUEST',
    BLOC_OPERATOIRE_UPDATE_SUCCESS: 'BLOC_OPERATOIRE_UPDATE_SUCCESS',
    BLOC_OPERATOIRE_UPDATE_FAIL: 'BLOC_OPERATOIRE_UPDATE_FAIL',
    BLOC_OPERATOIRE_DELETE_REQUEST: 'BLOC_OPERATOIRE_DELETE_REQUEST',
    BLOC_OPERATOIRE_DELETE_SUCCESS: 'BLOC_OPERATOIRE_DELETE_SUCCESS',
    BLOC_OPERATOIRE_DELETE_FAIL: 'BLOC_OPERATOIRE_DELETE_FAIL',
};
