import React, { lazy } from "react"
import * as MyPrivateRouters from "../routes/PrivateRouter";
import { Switch } from 'react-router-dom';

const LayoutOrlPatientAConsulter = lazy(() =>
    import("../../components/orl/patientAConsulter/LayoutOrlPatientAConsulter")
);
const LayoutOrlConsultationListe = lazy(() =>
import("../../components/orl/consultation/LayoutOrlConsultationListe")
);
const LayoutOrlDemandeHospitalisationListe = lazy(() =>
import(
    "../../components/orl/demandeHospitalisation/LayoutOrlDemandeHospitalisationListe"
)
);
const OrlObservation = lazy(() =>
import("../../components/orl/observation/OrlObservation")
);

const OrlObservationTerminees = lazy(() =>
    import("../../components/orl/observationTerminees/OrlObservationTerminees")
    );

const LayoutOrlConsultationCreate = lazy(() =>
import("../../components/orl/patientAConsulterCreate/LayoutOrlConsultationCreate")
);

const LayoutOrlConsultationView = lazy(() =>
import("../../components/orl/patientAConsulterDetail/LayoutOrlConsultationView")
);

const LayoutCreateConsultationSortieOrl = lazy(() =>
import(
    "../../components/orl/patientAConsulterIssuSortie/LayoutCreateConsultationSortie"
)
);

const LayoutCreateConsultationSortieDemandeHospitalisationOrl = lazy(() =>
import(
    "../../components/orl/patientAConsulterIssuSortie/DemandeHospitalisation/LayoutCreateConsultationSortieDemandeHospitalisation"
)
);
const LayoutCreateConsultationSortieMiseEnObservationOrl = lazy(() =>
import(
    "../../components/orl/patientAConsulterIssuSortie/MiseEnObservation/LayoutCreateConsultationSortieMiseEnObservation"
)
);

const LayoutCreateConsultationRefereInterneOrl = lazy(() =>
import(
    "../../components/orl/patientAConsulterIssuSortie/RefereInterne/LayoutCreateConsultationRefereInterne"
)
);
const LayoutCreateConsultationRefereExterneOrl = lazy(() =>
import(
    "../../components/orl/patientAConsulterIssuSortie/RefereExterne/LayoutCreateConsultationRefereExterne"
)
);

const LayoutOrlOrdonnanceDetails = lazy(() =>
import("../../components/orl/ordonnance/detail/LayoutOrlOrdonnanceDetails")
);
const LayoutOrlOrdonnanceListe = lazy(() =>
import("../../components/orl/ordonnance/liste/LayoutOrlOrdonnanceListe")
);

const LayoutOrlHospitalisationListe = lazy(() =>
import("../../components/orl/hospitalisation/LayoutOrlHospitalisationListe")
);

const LayoutOrlHospitalisationConsultationCreate = lazy(() =>
import(
    "../../components/orl/hospitalisation/consultation/LayoutOrlHospitalisationConsultationCreate"
)
);

const LayoutOrlHospitalisationConsultationSortie = lazy(() =>
import(
    "../../components/orl/hospitalisation/issueSortie/LayoutOrlHospitalisationConsultationSortie"
)
);

const LayoutOrlFeuilleDeSoins = lazy(() =>
import("../../components/orl/feuilleDeSoins/LayoutOrlFeuilleDeSoins")
);

const OrlRefExterne = lazy(() =>
import("../../components/orl/refExterne/OrlRefExterne")
);
const OrlRefInterne = lazy(() =>
import("../../components/orl/refInterne/OrlRefInterne")
);
const LayoutOrlDecesConsultationSortie = lazy(() =>
import(
    "../../components/orl/patientAConsulterIssuSortie/IssueDeDeces/LayoutOrlDecesConsultationSortie"
)
);

const DemandeHospiObservationOrl = lazy(() =>
import(
    "../../components/orl/patientAConsulterIssuSortie/MiseEnObservation/DemandeHospitalisation/LayoutCreateConsultationSortieDemandeHospitalisation"
)
);
const RefExterneObservationOrl = lazy(() =>
import(
    "../../components/orl/patientAConsulterIssuSortie/MiseEnObservation/RefereExterne/LayoutCreateConsultationRefereExterne"
)
);
const RefInterneObservationOrl = lazy(() =>
import(
    "../../components/orl/patientAConsulterIssuSortie/MiseEnObservation/RefereInterne/LayoutCreateConsultationRefereInterne"
)
);

const OrlExamensDetail = lazy(() => import("../../components/orl/Examens/detail"));
const OrlExamensList = lazy(() => import("../../components/orl/Examens/list"));
const LayoutOrlDecesListe = lazy(() =>
import("../../components/orl/Deces/Liste/LayoutOrlDecesListe")
);
const LayoutOrlDecesCertificat = lazy(() =>
import("../../components/orl/Deces/Certificat/LayoutOrlDecesCertificat")
);

const AgendaOrl = lazy(() =>
import("../../components/orl/rendezVous/agendaDetail/AgendaOrl")
);
const LayoutOrlCreateConsultationRendezVous = lazy(() =>
import(
    "../../components/orl/patientAConsulterIssuSortie/RendezVous/LayoutOrlCreateConsultationRendezVous"
)
);
const LayoutOrlRdvListe = lazy(() =>
import("../../components/orl/rendezVous/Liste/LayoutOrlRdvListe")
);

const LayoutOrlRdvLastConsultation = lazy(() =>
import("../../components/orl/rendezVous/LastConsultation/LayoutOrlRdvLastConsultation")
);

const LayoutOrlRdvNewConsultation = lazy(() =>
import("../../components/orl/rendezVous/NewConsultation/LayoutOrlRdvNewConsultation")
);

const LayoutVoirOrlResultatsExamens = lazy(() =>
import(
    "../../components/orl/Examens/resultatsExamensVoir/LayoutVoirMedecineGeneralResultatsExamens"
)
);

const RapportOrl = lazy(() => import("../../components/orl/rapports/RapportOrl"));

const LayoutOrlControleMajor = lazy(() => 
import("../../components/orl/controleMajor/LayoutOrlControleMajor")
)
  

export default function OrlRouter() {
  return (
    <Switch>
        <MyPrivateRouters.PrivateRouterOrl
        path="/orl"
        component={LayoutOrlPatientAConsulter}
        />

        <MyPrivateRouters.PrivateRouterOrl
        path="/orl-demande-hospitalisation"
        component={LayoutOrlDemandeHospitalisationListe}
        />

        <MyPrivateRouters.PrivateRouterOrl
        path="/orl-observation-encours"
        component={OrlObservation}
        />

        <MyPrivateRouters.PrivateRouterOrl
        path="/orl-observation-terminee"
        component={OrlObservationTerminees}
        />

        <MyPrivateRouters.PrivateRouterOrl
        path="/orl-consultation"
        component={LayoutOrlConsultationListe}
        />

        <MyPrivateRouters.PrivateRouterOrl
        path="/orl-feuille-de-soins/:id"
        component={LayoutOrlFeuilleDeSoins}
        />

        <MyPrivateRouters.PrivateRouterOrl
        path="/orl-ordonnance"
        component={LayoutOrlOrdonnanceListe}
        />

        <MyPrivateRouters.PrivateRouterOrl
        path="/orl-ordonnance-voir/:id"
        component={LayoutOrlOrdonnanceDetails}
        />

        <MyPrivateRouters.PrivateRouterOrl
        path="/orl-rdv"
        component={LayoutOrlRdvListe}
        />

        <MyPrivateRouters.PrivateRouterOrl
        path="/orl-rdv-last-consultation/:id"
        component={LayoutOrlRdvLastConsultation}
        />

        <MyPrivateRouters.PrivateRouterOrl
        path="/orl-rdv-new-consultation/:id"
        component={LayoutOrlRdvNewConsultation}
        />

        <MyPrivateRouters.PrivateRouterOrl
        path="/orl-rdv-agenda/"
        component={AgendaOrl}
        />

        <MyPrivateRouters.PrivateRouterOrl
        path="/orl-consultation-create/:id"
        component={LayoutOrlConsultationCreate}
        />

        <MyPrivateRouters.PrivateRouterOrl
        path="/orl-consultation-voir-detail/:id"
        component={LayoutOrlConsultationView}
        />

        <MyPrivateRouters.PrivateRouterOrl
        path="/orl-hospitalisation"
        component={LayoutOrlHospitalisationListe}
        />

        <MyPrivateRouters.PrivateRouterOrl
        path="/orl-hospitalisation-consultation/:id"
        component={LayoutOrlHospitalisationConsultationCreate}
        />

        <MyPrivateRouters.PrivateRouterOrl
        path="/orl-hospitalisation-sortie/:id"
        component={LayoutOrlHospitalisationConsultationSortie}
        />

        <MyPrivateRouters.PrivateRouterOrl
        path="/orl-consultation-sortie/:id"
        component={LayoutCreateConsultationSortieOrl}
        />

        <MyPrivateRouters.PrivateRouterOrl
        path="/orl-consultation-sortie-demande-hospitalisation/:id"
        component={
            LayoutCreateConsultationSortieDemandeHospitalisationOrl
        }
        />

        <MyPrivateRouters.PrivateRouterOrl
        path="/orl-consultation-sortie-mise-en-observation/:id"
        component={LayoutCreateConsultationSortieMiseEnObservationOrl}
        />

        <MyPrivateRouters.PrivateRouterOrl
        path="/orl-consultation-sortie-refere-interne/:id"
        component={LayoutCreateConsultationRefereInterneOrl}
        />

        <MyPrivateRouters.PrivateRouterOrl
        path="/orl-consultation-sortie-refere-externe/:id"
        component={LayoutCreateConsultationRefereExterneOrl}
        />

        <MyPrivateRouters.PrivateRouterOrl
        path="/orl-consultation-sortie-rdv/:id"
        component={LayoutOrlCreateConsultationRendezVous}
        />

        <MyPrivateRouters.PrivateRouterOrl
        path="/orl-referencements-externe"
        component={OrlRefExterne}
        />

        <MyPrivateRouters.PrivateRouterOrl
        path="/orl-referencements-interne"
        component={OrlRefInterne}
        />

        <MyPrivateRouters.PrivateRouterOrl
        path="/orl-consultation-sortie-deces/:id"
        component={LayoutOrlDecesConsultationSortie}
        />

        <MyPrivateRouters.PrivateRouterOrl
        path="/orl-observation-consultation/:id"
        component={LayoutOrlConsultationCreate}
        />

        <MyPrivateRouters.PrivateRouterOrl
        path="/orl-exit-observation/:id"
        component={LayoutCreateConsultationSortieOrl}
        />

        <MyPrivateRouters.PrivateRouterOrl
        path="/orl-observation-exit-demande-hospitalisation/:id"
        component={DemandeHospiObservationOrl}
        />

        <MyPrivateRouters.PrivateRouterOrl
        path="/orl-observation-exit-reference-externe/:id"
        component={RefExterneObservationOrl}
        />

        <MyPrivateRouters.PrivateRouterOrl
        path="/orl-observation-exit-reference-interne/:id"
        component={RefInterneObservationOrl}
        />

        <MyPrivateRouters.PrivateRouterOrl
        path="/orl-observation-exit-rdv/:id"
        component={LayoutOrlCreateConsultationRendezVous}
        />

        <MyPrivateRouters.PrivateRouterOrl
        path="/orl-observation-exit-deces/:id"
        component={LayoutOrlDecesConsultationSortie}
        />

        <MyPrivateRouters.PrivateRouterOrl
        path="/orl-examens-detail/:id/"
        component={OrlExamensDetail}
        />

        <MyPrivateRouters.PrivateRouterOrl
        path="/orl-examens"
        component={OrlExamensList}
        />

        <MyPrivateRouters.PrivateRouterOrl
        path="/orl-patients-decedes"
        component={LayoutOrlDecesListe}
        />

        <MyPrivateRouters.PrivateRouterOrl
        path="/orl-patients-decedes-certificat/:id"
        component={LayoutOrlDecesCertificat}
        />

        <MyPrivateRouters.PrivateRouterOrl
        path="/orl-examens-resultat-voir/:id"
        component={LayoutVoirOrlResultatsExamens}
        />

        <MyPrivateRouters.PrivateRouterOrl
        path="/orl-rapports"
        component={RapportOrl}
        />

        <MyPrivateRouters.PrivateRouterOrl
        path="/orl-controle-major"
        component={LayoutOrlControleMajor}
        />
    </Switch>
  )
}
