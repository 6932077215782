
import { fichePaiementTypes } from "../types/fichePaiementTypes";
import * as userTypes from "../types/userTypes";

const initialState = {
    isLoading: false,
    data: [],
    error: "",
}


const FichePaiementsDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
      
        case fichePaiementTypes.GET_FICHEPAIEMENT_DETAILS_LOADING:
            return {
                ...state,
                isLoading: true,
                error: "",
            }
        case fichePaiementTypes.GET_FICHEPAIEMENT_DETAILS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload,
                error:""
            }
        
        case fichePaiementTypes.GET_FICHEPAIEMENT_DETAILS_FAILED:
            return {
                    ...state,
                    isLoading: false,
                    error: action.payload,
                }
        case userTypes.AUTH_ERROR:
        case userTypes.LOGOUT_SUCCESS:
        case userTypes.TOKEN_REFRESH_FAIL:
            localStorage.removeItem('token_access')
            localStorage.removeItem('token_refresh')
            return {
                ...state,
                isLoading: false,
                isLoadingRefresh: false,
                data: [],
                error: "",
            }
    
        default:
            return state;
    }
}

export default FichePaiementsDetailsReducer