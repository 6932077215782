import { 
        TYPE_CENTRE_GET_DATA_SUCCESS, 
        TYPE_CENTRE_GET_DATA_FAILED, 
        TYPE_CENTRE_GET_DATA_LOADING
    } from "../../types/typeCentreTypes"
import { AUTH_ERROR, LOGOUT_SUCCESS, TOKEN_REFRESH_FAIL } from "../../types/userTypes"

// import jwtDecode from "jwt-decode"

const initialState = {
    typecentres: [],
    isLoading: false
}

// eslint-disable-next-line
export default function(state = initialState, action){
    switch (action.type) {
        case TYPE_CENTRE_GET_DATA_LOADING:
            return {
                ...state,
                isLoading: true
        }
        
        case TYPE_CENTRE_GET_DATA_SUCCESS:
            return {
                ...state,
                isLoading: false,
                typecentres: action.payload
        }
        case TYPE_CENTRE_GET_DATA_FAILED:
            return {
                ...state,
                isLoading: false,        
            }
        case AUTH_ERROR:
        case LOGOUT_SUCCESS:
        case TOKEN_REFRESH_FAIL:
            localStorage.removeItem('token_access')
            localStorage.removeItem('token_refresh')
            return {
                ...state,
                typecentres: [],
                isLoading: false
            }

        default:
            return state
    }
}


