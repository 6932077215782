import React, { lazy } from "react"
import * as MyPrivateRouters from "../routes/PrivateRouter";
import { Switch } from 'react-router-dom';


const LayoutListeReferenceInterne = lazy(() =>
    import(
      "../../components/facturation/referencementInterne/listeReferenceInterne/LayoutListeReferenceInterne"
    )
);
const LayoutFormeReferenceInterne = lazy(() =>
import(
    "../../components/facturation/referencementInterne/formeReferenceInterne/LayoutFormeReferenceInterne"
)
);
const LayoutVoirReferenceInterne = lazy(() =>
import(
    "../../components/facturation/referencementInterne/voirReferenceInterne/LayoutVoirReferenceInterne"
)
);

const LayoutFormeHospitalisation = lazy(() =>
import(
    "../../components/facturation/hospitalisation/formeHospitalisation/LayoutFormeHospitalisation"
)
);
const LayoutListeHospitalisation = lazy(() =>
import(
    "../../components/facturation/hospitalisation/listeHospitalisation/LayoutListeHospitalisation"
)
);
const LayoutVoirHospitalisation = lazy(() =>
import(
    "../../components/facturation/hospitalisation/voirHospitalisation/LayoutVoirHospitalisation"
)
);

const LayoutFormeDemandeExamen = lazy(() =>
import(
    "../../components/facturation/demandeExamen/formeDemandeExamen/LayoutFormeDemandeExamen"
)
);
const LayoutListeDemandeExamen = lazy(() =>
import(
    "../../components/facturation/demandeExamen/listeDemandeExamen/LayoutListeDemandeExamen"
)
);
const LayoutVoirDemandeExamen = lazy(() =>
import(
    "../../components/facturation/demandeExamen/voirDemandeExamen/LayoutVoirDemandeExamen"
)
);

const DataTableListeAcetsPoser = lazy(() =>
import(
    "../../components/facturation/actesPoser/listeActesPoser/DataTableListeAcetsPoser"
)
);


const DataTableListeAcetsPoserDetails = lazy(() =>
import(
    "../../components/facturation/actesPoser/voirActesPoser/FormPaiementActesPoser"
)
);
  

export default function FacturationRouter() {
  return (
    <Switch>
        <MyPrivateRouters.PrivateRouterFacturation
        path="/facturation"
        component={LayoutListeReferenceInterne}
        />

        <MyPrivateRouters.PrivateRouterFacturation
        path="/facturation-referencement-interne"
        component={LayoutListeReferenceInterne}
        />

        <MyPrivateRouters.PrivateRouterFacturation
        path="/facturation-referencement-interne-valider/:id"
        component={LayoutFormeReferenceInterne}
        />

        <MyPrivateRouters.PrivateRouterFacturation
        path="/facturation-referencement-interne-details/:id"
        component={LayoutVoirReferenceInterne}
        />

        <MyPrivateRouters.PrivateRouterFacturation
        path="/facturation-hospitalisations"
        component={LayoutListeHospitalisation}
        />

        <MyPrivateRouters.PrivateRouterFacturation
        path="/facturation-hospitalisations-valider/:id"
        component={LayoutFormeHospitalisation}
        />

        <MyPrivateRouters.PrivateRouterFacturation
        path="/facturation-hospitalisations-details/:id"
        component={LayoutVoirHospitalisation}
        />

        <MyPrivateRouters.PrivateRouterFacturation
        path="/facturation-demandes-examens"
        component={LayoutListeDemandeExamen}
        />

        <MyPrivateRouters.PrivateRouterFacturation
        path="/facturation-demandes-examens-valider/:id"
        component={LayoutFormeDemandeExamen}
        />

        <MyPrivateRouters.PrivateRouterFacturation
        path="/facturation-demandes-examens-details/:id"
        component={LayoutVoirDemandeExamen}
        />

        <MyPrivateRouters.PrivateRouterFacturation
        path="/facturation-actes-poses"
        component={DataTableListeAcetsPoser}
        />

        <MyPrivateRouters.PrivateRouterFacturation
        path="/facturation-actes-poses-valider/:id"
        component={DataTableListeAcetsPoserDetails}
        />
    </Switch>
  )
}
