// import { parametreTypes } from "../../types/parametreTypes";
// import { patientparametreTypes } from "../../types/patientTypes";
import { batimentparametreTypes } from "../../types/batimentTypes";
import {
  AUTH_ERROR,
  LOGOUT_SUCCESS,
  TOKEN_REFRESH_FAIL,
} from "../../types/userTypes";
const initialState = {
  isLoading: false,
  error: "",
  success: false,
  batiments: [],
  batiment: {},
  deleteLoader: false,
};

export const batimentListReducers = (state = initialState, action) => {
  switch (action.type) {
    case batimentparametreTypes.BATIMENT_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: "",
        batiment: {},
        success: false,
      };
    case batimentparametreTypes.BATIMENT_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        batiments: action.payload,
        error: "",
        batiment: {},
        success: false,
      };
    case batimentparametreTypes.BATIMENT_LIST_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        batiment: {},
        success: false,
      };
    case batimentparametreTypes.BATIMENT_POST_FAILED:
      return {
        ...state,
        isLoading: false,
        success: false,
        error: action.payload,
        batiment: {},
      };
    case batimentparametreTypes.BATIMENT_POST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        batiments: [action.payload, ...state.batiments],
        success: true,
        error: "",
        batiment: {},
      };
    case batimentparametreTypes.BATIMENT_POST_LOADING:
      return {
        ...state,
        isLoading: true,
        success: false,
        error: "",
        batiment: {},
      };
    case batimentparametreTypes.BATIMENT_DETAIL_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: "",
        batiment: {},
        success: false,
      };
    case batimentparametreTypes.BATIMENT_DETAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        batiment: { ...action.payload },
        error: "",
        success: false,
      };
    case batimentparametreTypes.BATIMENT_DETAIL_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        batiment: {},
        success: false,
      };
    case batimentparametreTypes.BATIMENT_UPDATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        success: false,
        error: "",
        batiment: {},
      };
    case batimentparametreTypes.BATIMENT_UPDATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        batiments: state.batiments.map((batiment) =>
          batiment.id === action.payload.id ? action.payload : batiment
        ),
        success: true,
        error: "",
        batiment: {},
      };
    case batimentparametreTypes.BATIMENT_UPDATE_FAIL:
      return {
        ...state,
        isLoading: false,
        success: false,
        error: action.payload,
        batiment: {},
      };
    case batimentparametreTypes.BATIMENT_DELETE_REQUEST:
      return {
        ...state,
        deleteLoader: true,
        error: "",
      };
    case batimentparametreTypes.BATIMENT_DELETE_SUCCESS:
      return {
        ...state,
        deleteLoader: false,
        batiments: state.batiments.filter(
          (batiment) => batiment.id !== action.payload
        ),
        error: "",
        success: true,
      };
    case batimentparametreTypes.BATIMENT_DELETE_FAIL:
      return {
        ...state,
        deleteLoader: false,
        error: action.payload,
      }
    case "SUCCESS_CLEAN":
        return {
            ...state,
            success: false,
            error: "",
        }
    case AUTH_ERROR:
    case LOGOUT_SUCCESS:
    case TOKEN_REFRESH_FAIL:
      localStorage.removeItem("token_access");
      localStorage.removeItem("token_refresh");
      return {
        ...state,
        isLoading: false,
        error: "",
        success: false,
        batiments: [],
        batiment: {},
        deleteLoader: false,
      };

    default:
      return state;
  }
};
