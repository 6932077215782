import {
  ACTES_CHIRUGICAUX_CHIRURGIE_GET_FAILED,
  ACTES_CHIRUGICAUX_CHIRURGIE_GET_LOADING,
  ACTES_CHIRUGICAUX_CHIRURGIE_GET_SUCCESS,
  CONSULTATION_CHIRURGIE_GET_FAILED,
  CONSULTATION_CHIRURGIE_GET_LOADING,
  CONSULTATION_CHIRURGIE_GET_PAS_TROUVER,
  CONSULTATION_CHIRURGIE_GET_SUCCESS,
  CONSULTATION_CHIRURGIE_POST_FAILED,
  CONSULTATION_CHIRURGIE_POST_LOADING,
  CONSULTATION_CHIRURGIE_POST_SUCCESS,
  CONSULTATION_CHIRURGIE_RESET,
  CONSULTATION_CHIRURGIE_RESET_AFTER,

  ISSU_SORTIE_CONSULTATION_CHIRURGIE_POST_FAILED,
  ISSU_SORTIE_CONSULTATION_CHIRURGIE_POST_LOADING,
  ISSU_SORTIE_CONSULTATION_CHIRURGIE_POST_SUCCESS,

  PATIENT_A_CONSULTER_CHIRURGIE_GET_FAILED,
  PATIENT_A_CONSULTER_CHIRURGIE_GET_LOADING,
  PATIENT_A_CONSULTER_CHIRURGIE_GET_SUCCESS,

  ORDONNANCES_CHIRURGIE_GET_FAILED, 
  ORDONNANCES_CHIRURGIE_GET_LOADING, 
  ORDONNANCES_CHIRURGIE_GET_SUCCESS, 
  ORDONNANCE_CHIRURGIE_GET_FAILED, 
  ORDONNANCE_CHIRURGIE_GET_LOADING, 
  ORDONNANCE_CHIRURGIE_GET_SUCCESS,

  CONSULTATION_CHIRURGIE_POST_TERMINER_LOADING, 
  CONSULTATION_CHIRURGIE_POST_TERMINER_SUCCESS, 
  CONSULTATION_CHIRURGIE_POST_TERMINER_FAILED,
  ISSU_SORTIE_CONSULTATION_DEMANDE_CHIRURGIE_CHIRURGIE_POST_LOADING,
  ISSU_SORTIE_CONSULTATION_DEMANDE_CHIRURGIE_CHIRURGIE_POST_SUCCESS,
  ISSU_SORTIE_CONSULTATION_DEMANDE_CHIRURGIE_CHIRURGIE_POST_FAILED,
  DEMANDE_DE_CHIRURGIE_REFRESH_GETS_DATA_LOADING,
  DEMANDE_DE_CHIRURGIE_REFRESH_GETS_DATA_SUCCESS,
  DEMANDE_DE_CHIRURGIE_REFRESH_GETS_DATA_FAILED,
  DEMANDE_DE_CHIRURGIE_POST_DATA_SUCCESS,
  DEMANDE_DE_CHIRURGIE_POST_DATA_FAILED,
  DEMANDE_DE_CHIRURGIE_POST_DATA_LOADING,
  REQUETE_OPERATOIRE_CHIRURGIE_GETS_DATA_LOADING,
  REQUETE_OPERATOIRE_CHIRURGIE_GETS_DATA_SUCCESS,
  REQUETE_OPERATOIRE_CHIRURGIE_GETS_DATA_FAILED,
  REQUETE_OPERATOIRE_CHIRURGIE_REFRESH_GETS_DATA_LOADING,
  REQUETE_OPERATOIRE_CHIRURGIE_REFRESH_GETS_DATA_SUCCESS,
  REQUETE_OPERATOIRE_CHIRURGIE_REFRESH_GETS_DATA_FAILED,
  REQUETE_OPERATOIRE_CHIRURGIE_POST_DATA_LOADING,
  REQUETE_OPERATOIRE_CHIRURGIE_POST_DATA_SUCCESS,
  REQUETE_OPERATOIRE_CHIRURGIE_POST_DATA_FAILED,
  REQUETE_OPERATOIRE_CHIRURGIE_GET_DATA_LOADING,
  REQUETE_OPERATOIRE_CHIRURGIE_GET_DATA_SUCCESS,
  REQUETE_OPERATOIRE_CHIRURGIE_GET_DATA_FAILED,
  DEMANDE_DE_CHIRURGIE_GETS_DATA_LOADING,
  DEMANDE_DE_CHIRURGIE_GETS_DATA_SUCCESS,
  DEMANDE_DE_CHIRURGIE_GETS_DATA_FAILED

} from "../../types/consultationChirurgieTypes";

import * as typesChirurgieTypes from "../../types/consultationChirurgieTypes";

import { AUTH_ERROR, LOGOUT_SUCCESS, TOKEN_REFRESH_FAIL } from "../../types/userTypes";


const initialState = {
  consulterPatientMgConstante: {},
  actesChirugicauxMg: [],
  consultationPatientMg: {},

  demandeHospitalisation:[],

  observations: [],

  hospitalisation: [],
  hospitalisationValidee: [],
  demandes: [],
  
  isOriente: false,

  isLoadingGet: false,
  isLoadingGetActesChirugies: false,
  isLoadingPostConsultationMg: false,
  isLoadingPostTerminerConsultationMg: false,
  isLoadingGetConsultationMg: false,
  isLoadingPostSortieConsultationMg: false,

  isLoadingDemandeHospitalisation:false,

  isLoadingHospitalisationValidee:false,

  isLoadingObservations:false,

  isLoadingHospitalisation: false,

  isSuccesGet: false,
  isSuccesGetMaladies: false,
  isSuccesGetActesChirugies: false,
  isSuccesPostConsultationMg: false,
  isSuccesPostTerminerConsultationMg: false,
  isSuccesGetConsultationMg: false,
  isSuccesPostSortieConsultationMg: false,
  isNonfoundConsultation: false,
  ordonnacesMg: [],
  ordonnanceMg: [],
  isSuccesGetOrdonnancesMg:false,
  isSuccesGetOrdonnanceMg:false,

  isLoadingPostSortieConsultationDemandeChirurgie: false,
  consultationPatientDemandeChirurgie:{},
  isSuccesPostSortieConsultationDemandeChirurgie: false,

  isLoadingDemandeDeChirurgie: false,
  isLoadingDemandeDeChirurgieRefresh: false,
  demandeDeChirurgie: [],

  isLoadingDemandeDeChirurgiePost: false,
  isSuccesDemandeDeChirurgiePost: false,
  demandeDeChirurgiePost: {},

  isLoadingRequeteOperatoireChirurgiePost: false,
  isSuccesRequeteOperatoireChirurgiePost: false,
  requeteOperatoirePost: {},

  isLoadingRequeteOperatoireChirurgie: false,
  isSuccesRequeteOperatoireChirurgie: false,
  requeteOperatoire: {},

  isLoadingRequeteOperatoiresChirurgie: false,
  isLoadingRequeteOperatoiresChirurgieRefresh: false,
  requeteOperatoires: [],

  isLoadingInterventionProgrammeeChirurgie: false,
  isSuccesInterventionProgrammeeChirurgie: false,
  interventionProgrammee: {},

  isLoadingInterventionProgrammeeChirurgiePost: false,
  isSuccesInterventionProgrammeeChirurgiePost: false,
  interventionProgrammeePost: {},

  isLoadingInterventionProgrammeesChirurgie: false,
  isLoadingInterventionProgrammeesChirurgieRefresh: false,
  interventionProgrammees: [],

  isLoadingInterventionProgrammeesListeChirurgie: false,
  isLoadingInterventionProgrammeesListeChirurgieRefresh: false,
  interventionProgrammeesListe: [],

  isError: false,
  error: "",
};

//eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case PATIENT_A_CONSULTER_CHIRURGIE_GET_LOADING:
      return {
        ...state,
        isLoadingGet: true,
        isLoadingPostTerminerConsultationMg: false,
        isSuccesPostTerminerConsultationMg: false,
        isError: false,
        error: "",
      };
    case PATIENT_A_CONSULTER_CHIRURGIE_GET_SUCCESS:
      return {
        ...state,
        isLoadingGet: false,
        consulterPatientMgConstante: action.payload,
        isSuccesGet: true,
        isLoadingPostTerminerConsultationMg: false,
        isSuccesPostTerminerConsultationMg: false,
        isError: false,
      };
    case PATIENT_A_CONSULTER_CHIRURGIE_GET_FAILED:
      return {
        ...state,
        isLoadingGet: false,
        consulterPatientMgConstante: {},
        error: action.payload,
        isSuccesGet: false,
        isLoadingPostTerminerConsultationMg: false,
        isSuccesPostTerminerConsultationMg: false,
        isError: true,
      };

    case ACTES_CHIRUGICAUX_CHIRURGIE_GET_LOADING:
      return {
        ...state,
        isLoadingGetActesChirugies: true,
        error: "",
        isSuccesGetActesChirugies: false,
        isError: false,
      };
    case ACTES_CHIRUGICAUX_CHIRURGIE_GET_SUCCESS:
      return {
        ...state,
        isLoadingGetActesChirugies: false,
        actesChirugicauxMg: action.payload,
        error: "",
        isSuccesGetActesChirugies: true,
        isError: false,
      };
    case ACTES_CHIRUGICAUX_CHIRURGIE_GET_FAILED:
      return {
        ...state,
        isLoadingGetActesChirugies: false,
        error: action.payload,
        isSuccesGetActesChirugies: false,
        isError: true,
      };
    
    //post without terminer
    case CONSULTATION_CHIRURGIE_POST_LOADING:
      return {
        ...state,
        isLoadingPostConsultationMg: true,
        isNonfoundConsultation:false,
        consultationPatientMg: {},
        error: "",
        isSuccesPostConsultationMg: false,
        isLoadingPostTerminerConsultationMg: false,
        isSuccesPostTerminerConsultationMg: false,
        isError: false,
      };
    case CONSULTATION_CHIRURGIE_POST_SUCCESS:
      return {
        ...state,
        isLoadingPostConsultationMg: false,
        isNonfoundConsultation:false,
        consultationPatientMg: action.payload,
        error: "",
        isSuccesPostConsultationMg: true,
        isLoadingPostTerminerConsultationMg: false,
        isSuccesPostTerminerConsultationMg: false,
        isError: false,
      };
    case CONSULTATION_CHIRURGIE_POST_FAILED:
      return {
        ...state,
        isLoadingPostConsultationMg: false,
        consultationPatientMg: {},
        error: action.payload,
        isSuccesPostConsultationMg: false,
        isLoadingPostTerminerConsultationMg: false,
        isSuccesPostTerminerConsultationMg: false,
        isError: true,
      };

      //post without and terminer
      case CONSULTATION_CHIRURGIE_POST_TERMINER_LOADING:
        return {
          ...state,
          isLoadingPostTerminerConsultationMg: true,
          isNonfoundConsultation:false,
          consultationPatientMg: {},
          error: "",
          isSuccesPostTerminerConsultationMg: false,
          isError: false,
        };
      case CONSULTATION_CHIRURGIE_POST_TERMINER_SUCCESS:
        return {
          ...state,
          isLoadingPostTerminerConsultationMg: false,
          isNonfoundConsultation:false,
          consultationPatientMg: action.payload,
          error: "",
          isSuccesPostTerminerConsultationMg: true,
          isError: false,
        };
      case CONSULTATION_CHIRURGIE_POST_TERMINER_FAILED:
        return {
          ...state,
          isLoadingPostTerminerConsultationMg: false,
          consultationPatientMg: {},
          error: action.payload,
          isSuccesPostTerminerConsultationMg: false,
          isError: true,
        };

    case CONSULTATION_CHIRURGIE_GET_LOADING:
      return {
        ...state,
        isLoadingGetConsultationMg: true,
        consultationPatientMg: {},
        isNonfoundConsultation:false,
        error: "",
        isSuccesGetConsultationMg: false,
        isError: false,
      };
    case CONSULTATION_CHIRURGIE_GET_SUCCESS:
      return {
        ...state,
        isLoadingGetConsultationMg: false,
        consultationPatientMg: action.payload,
        isNonfoundConsultation:false,
        error: "",
        isSuccesGetConsultationMg: true,
        isError: false,
      };
    case CONSULTATION_CHIRURGIE_GET_PAS_TROUVER:
      return {
        ...state,
        isLoadingGetConsultationMg: false,
        consultationPatientMg: action.payload,
        isNonfoundConsultation:true,
        error: "",
        isSuccesGetConsultationMg: true,
        isError: false,
      };
    case CONSULTATION_CHIRURGIE_GET_FAILED:
      return {
        ...state,
        isLoadingGetConsultationMg: false,
        isNonfoundConsultation:false,
        consultationPatientMg: {},
        error: action.payload,
        isSuccesGetConsultationMg: false,
        isError: true,
      };
      
      
      //sortie simple de consultation
      case ISSU_SORTIE_CONSULTATION_CHIRURGIE_POST_LOADING:
          return {
              ...state,
              isLoadingPostSortieConsultationMg: true,
              consultationPatientMg:{},
              error: '',
              isSuccesPostSortieConsultationMg: false,
              isError:false
          }
      case ISSU_SORTIE_CONSULTATION_CHIRURGIE_POST_SUCCESS:
          return {
              ...state,
              isLoadingPostSortieConsultationMg: false,
              consultationPatientMg:action.payload,
              error: '',
              isSuccesPostSortieConsultationMg: true,
              isError:false
          }
      case ISSU_SORTIE_CONSULTATION_CHIRURGIE_POST_FAILED:
          return {
              ...state,
              isLoadingPostSortieConsultationMg: false,
              consultationPatientMg:{},
              error: action.payload,
              isSuccesPostSortieConsultationMg: false,
              isError:true
          }

      
      //sortie de consultation demande de chirurgie
      case ISSU_SORTIE_CONSULTATION_DEMANDE_CHIRURGIE_CHIRURGIE_POST_LOADING:
          return {
              ...state,
              isLoadingPostSortieConsultationDemandeChirurgie: true,
              consultationPatientDemandeChirurgie:{},
              error: '',
              isSuccesPostSortieConsultationDemandeChirurgie: false,
              isError:false
          }
      case ISSU_SORTIE_CONSULTATION_DEMANDE_CHIRURGIE_CHIRURGIE_POST_SUCCESS:
          return {
              ...state,
              isLoadingPostSortieConsultationDemandeChirurgie: false,
              consultationPatientDemandeChirurgie:action.payload,
              error: '',
              isSuccesPostSortieConsultationDemandeChirurgie: true,
              isError:false
          }
      case ISSU_SORTIE_CONSULTATION_DEMANDE_CHIRURGIE_CHIRURGIE_POST_FAILED:
          return {
              ...state,
              isLoadingPostSortieConsultationDemandeChirurgie: false,
              consultationPatientDemandeChirurgie:{},
              error: action.payload,
              isSuccesPostSortieConsultationDemandeChirurgie: false,
              isError:true
          }

      case ORDONNANCES_CHIRURGIE_GET_LOADING:
          return {
              ...state,
              isLoadingGetOrdonnancesMg:true,
              isSuccesGetOrdonnancesMg:false,
          }
      case ORDONNANCES_CHIRURGIE_GET_SUCCESS:
          return {
              ...state,
              isLoadingGetOrdonnancesMg:false,
              isSuccesGetOrdonnancesMg:true,
              ordonnacesMg:action.payload
          }
      case ORDONNANCES_CHIRURGIE_GET_FAILED:
          return {
              ...state,
              isLoadingGetOrdonnancesMg:false,
              isSuccesGetOrdonnancesMg:false,
          }
      
      
      case ORDONNANCE_CHIRURGIE_GET_LOADING:
          return {
              ...state,
              isLoadingGetOrdonnanceMg:true,
              isSuccesGetOrdonnanceMg:false,
          }
      case ORDONNANCE_CHIRURGIE_GET_SUCCESS:
          return {
              ...state,
              isLoadingGetOrdonnanceMg:false,
              isSuccesGetOrdonnanceMg:true,
              ordonnanceMg:action.payload
          }
      case ORDONNANCE_CHIRURGIE_GET_FAILED:
          return {
              ...state,
              isLoadingGetOrdonnanceMg:false,
              isSuccesGetOrdonnanceMg:false,
          }
    
    //////////////////////demande de chirurgie /////////////////////////////////////
    case DEMANDE_DE_CHIRURGIE_GETS_DATA_LOADING:
        return {
            ...state,
            isLoadingDemandeDeChirurgies: true,
            error: "",
        };
    case DEMANDE_DE_CHIRURGIE_GETS_DATA_SUCCESS:
        return {
            ...state,
            isLoadingDemandeDeChirurgies: false,
            demandeDeChirurgies: action.payload,
            error: "",
        };
    case DEMANDE_DE_CHIRURGIE_GETS_DATA_FAILED:
        return {
            ...state,
            isLoadingDemandeDeChirurgies: false,
            demandeDeChirurgies: [],
            error: action.payload,
        }

      
    case DEMANDE_DE_CHIRURGIE_REFRESH_GETS_DATA_LOADING:
        return {
            ...state,
            isLoadingDemandeDeChirurgiesRefresh: true,
            error: "",
        };
    case DEMANDE_DE_CHIRURGIE_REFRESH_GETS_DATA_SUCCESS:
        return {
            ...state,
            isLoadingDemandeDeChirurgiesRefresh: false,
            demandeDeChirurgies: action.payload,
            error: "",
        };
    case DEMANDE_DE_CHIRURGIE_REFRESH_GETS_DATA_FAILED:
        return {
            ...state,
            isLoadingDemandeDeChirurgiesRefresh: false,
            demandeDeChirurgies: [],
            error: action.payload,
        }

      
    case DEMANDE_DE_CHIRURGIE_POST_DATA_LOADING:
        return {
            ...state,
            isLoadingDemandeDeChirurgiePost: true,
            isSuccesDemandeDeChirurgiePost: false,
            demandeDeChirurgiePost: {},
            error: "",
        };
    case DEMANDE_DE_CHIRURGIE_POST_DATA_SUCCESS:
        return {
            ...state,
            isLoadingDemandeDeChirurgiePost: false,
            isSuccesDemandeDeChirurgiePost: true,
            demandeDeChirurgiePost: action.payload,
            error: "",
        };
    case DEMANDE_DE_CHIRURGIE_POST_DATA_FAILED:
        return {
            ...state,
            isLoadingDemandeDeChirurgiePost: false,
            isSuccesDemandeDeChirurgiePost: false,
            demandeDeChirurgiePost: {},
            error: action.payload,
        }
    //////////////////////demande  de chirurgie /////////////////////////////////////


    //////////////////////requete operatoire de chirurgie /////////////////////////////////////
    case REQUETE_OPERATOIRE_CHIRURGIE_GETS_DATA_LOADING:
        return {
            ...state,
            isLoadingRequeteOperatoiresChirurgie: true,
            error: "",
        };
    case REQUETE_OPERATOIRE_CHIRURGIE_GETS_DATA_SUCCESS:
        return {
            ...state,
            isLoadingRequeteOperatoiresChirurgie: false,
            requeteOperatoires: action.payload,
            error: "",
        };
    case REQUETE_OPERATOIRE_CHIRURGIE_GETS_DATA_FAILED:
        return {
            ...state,
            isLoadingRequeteOperatoiresChirurgie: false,
            requeteOperatoires: [],
            error: action.payload,
        }

      
    case REQUETE_OPERATOIRE_CHIRURGIE_REFRESH_GETS_DATA_LOADING:
        return {
            ...state,
            isLoadingRequeteOperatoiresChirurgieRefresh: true,
            error: "",
        };
    case REQUETE_OPERATOIRE_CHIRURGIE_REFRESH_GETS_DATA_SUCCESS:
        return {
            ...state,
            isLoadingRequeteOperatoiresChirurgieRefresh: false,
            requeteOperatoires: action.payload,
            error: "",
        };
    case REQUETE_OPERATOIRE_CHIRURGIE_REFRESH_GETS_DATA_FAILED:
        return {
            ...state,
            isLoadingRequeteOperatoiresChirurgieRefresh: false,
            requeteOperatoires: [],
            error: action.payload,
        }

      
    case REQUETE_OPERATOIRE_CHIRURGIE_POST_DATA_LOADING:
        return {
            ...state,
            isLoadingRequeteOperatoireChirurgiePost: true,
            isSuccesRequeteOperatoireChirurgiePost: false,
            requeteOperatoirePost: {},
            error: "",
        };
    case REQUETE_OPERATOIRE_CHIRURGIE_POST_DATA_SUCCESS:
        return {
            ...state,
            isLoadingRequeteOperatoireChirurgiePost: false,
            isSuccesRequeteOperatoireChirurgiePost: true,
            requeteOperatoirePost: action.payload,
            error: "",
        };
    case REQUETE_OPERATOIRE_CHIRURGIE_POST_DATA_FAILED:
        return {
            ...state,
            isLoadingRequeteOperatoireChirurgiePost: false,
            isSuccesRequeteOperatoireChirurgiePost: false,
            requeteOperatoirePost: {},
            error: action.payload,
        }
    
    case REQUETE_OPERATOIRE_CHIRURGIE_GET_DATA_LOADING:
        return {
            ...state,
            isLoadingRequeteOperatoireChirurgie: true,
            isSuccesRequeteOperatoireChirurgie: false,
            requeteOperatoire: {},
            error: "",
        };
    case REQUETE_OPERATOIRE_CHIRURGIE_GET_DATA_SUCCESS:
        return {
            ...state,
            isLoadingRequeteOperatoireChirurgie: false,
            isSuccesRequeteOperatoireChirurgie: true,
            requeteOperatoire: action.payload,
            error: "",
        };
    case REQUETE_OPERATOIRE_CHIRURGIE_GET_DATA_FAILED:
        return {
            ...state,
            isLoadingRequeteOperatoireChirurgie: false,
            isSuccesRequeteOperatoireChirurgie: false,
            requeteOperatoire: {},
            error: action.payload,
        }
    //////////////////////requete operatoire de chirurgie /////////////////////////////////////
    

    //////////////////////interventions programmes de chirurgie /////////////////////////////////////
    case typesChirurgieTypes.INTERVENTION_PROGRAMMEE_CHIRURGIE_GETS_DATA_LOADING:
        return {
            ...state,
            isLoadingInterventionProgrammeesChirurgie: true,
            error: "",
        };
    case typesChirurgieTypes.INTERVENTION_PROGRAMMEE_CHIRURGIE_GETS_DATA_SUCCESS:
        return {
            ...state,
            isLoadingInterventionProgrammeesChirurgie: false,
            interventionProgrammees: action.payload,
            error: "",
        };
    case typesChirurgieTypes.INTERVENTION_PROGRAMMEE_CHIRURGIE_GETS_DATA_FAILED:
        return {
            ...state,
            isLoadingInterventionProgrammeesChirurgie: false,
            interventionProgrammees: [],
            error: action.payload,
        }

      
    case typesChirurgieTypes.INTERVENTION_PROGRAMMEE_CHIRURGIE_REFRESH_GETS_DATA_LOADING:
        return {
            ...state,
            isLoadingInterventionProgrammeesChirurgieRefresh: true,
            error: "",
        };
    case typesChirurgieTypes.INTERVENTION_PROGRAMMEE_CHIRURGIE_REFRESH_GETS_DATA_SUCCESS:
        return {
            ...state,
            isLoadingInterventionProgrammeesChirurgieRefresh: false,
            interventionProgrammees: action.payload,
            error: "",
        };
    case typesChirurgieTypes.INTERVENTION_PROGRAMMEE_CHIRURGIE_REFRESH_GETS_DATA_FAILED:
        return {
            ...state,
            isLoadingInterventionProgrammeesChirurgieRefresh: false,
            interventionProgrammees: [],
            error: action.payload,
        }

      
    case typesChirurgieTypes.INTERVENTION_PROGRAMMEE_CHIRURGIE_POST_DATA_LOADING:
        return {
            ...state,
            isLoadingInterventionProgrammeeChirurgiePost: true,
            isSuccesInterventionProgrammeeChirurgiePost: false,
            interventionProgrammeePost: {},
            error: "",
        };
    case typesChirurgieTypes.INTERVENTION_PROGRAMMEE_CHIRURGIE_POST_DATA_SUCCESS:
        return {
            ...state,
            isLoadingInterventionProgrammeeChirurgiePost: false,
            isSuccesInterventionProgrammeeChirurgiePost: true,
            interventionProgrammeePost: action.payload,
            error: "",
        };
    case typesChirurgieTypes.INTERVENTION_PROGRAMMEE_CHIRURGIE_POST_DATA_FAILED:
        return {
            ...state,
            isLoadingInterventionProgrammeeChirurgiePost: false,
            isSuccesInterventionProgrammeeChirurgiePost: false,
            interventionProgrammeePost: {},
            error: action.payload,
        }
    
    case typesChirurgieTypes.INTERVENTION_PROGRAMMEE_CHIRURGIE_GET_DATA_LOADING:
        return {
            ...state,
            isLoadingInterventionProgrammeeChirurgie: true,
            isSuccesInterventionProgrammeeChirurgie: false,
            interventionProgrammee: {},
            error: "",
        };
    case typesChirurgieTypes.INTERVENTION_PROGRAMMEE_CHIRURGIE_GET_DATA_SUCCESS:
        return {
            ...state,
            isLoadingInterventionProgrammeeChirurgie: false,
            isSuccesInterventionProgrammeeChirurgie: true,
            interventionProgrammee: action.payload,
            error: "",
        };
    case typesChirurgieTypes.INTERVENTION_PROGRAMMEE_CHIRURGIE_GET_DATA_FAILED:
        return {
            ...state,
            isLoadingInterventionProgrammeeChirurgie: false,
            isSuccesInterventionProgrammeeChirurgie: false,
            interventionProgrammee: {},
            error: action.payload,
        }
    //////////////////////interventions programmes de chirurgie /////////////////////////////////////

    //////////////////////liste des interventions de chirurgie /////////////////////////////////////
    case typesChirurgieTypes.INTERVENTION_PROGRAMMEE_LISTE_CHIRURGIE_GETS_DATA_LOADING:
        return {
            ...state,
            isLoadingInterventionProgrammeesListeChirurgie: true,
            error: "",
        };
    case typesChirurgieTypes.INTERVENTION_PROGRAMMEE_LISTE_CHIRURGIE_GETS_DATA_SUCCESS:
        return {
            ...state,
            isLoadingInterventionProgrammeesListeChirurgie: false,
            interventionProgrammeesListe: action.payload,
            error: "",
        };
    case typesChirurgieTypes.INTERVENTION_PROGRAMMEE_LISTE_CHIRURGIE_GETS_DATA_FAILED:
        return {
            ...state,
            isLoadingInterventionProgrammeesListeChirurgie: false,
            interventionProgrammeesListe: [],
            error: action.payload,
        }

      
    case typesChirurgieTypes.INTERVENTION_PROGRAMMEE_LISTE_CHIRURGIE_REFRESH_GETS_DATA_LOADING:
        return {
            ...state,
            isLoadingInterventionProgrammeesListeChirurgieRefresh: true,
            error: "",
        };
    case typesChirurgieTypes.INTERVENTION_PROGRAMMEE_LISTE_CHIRURGIE_REFRESH_GETS_DATA_SUCCESS:
        return {
            ...state,
            isLoadingInterventionProgrammeesListeChirurgieRefresh: false,
            interventionProgrammeesListe: action.payload,
            error: "",
        };
    case typesChirurgieTypes.INTERVENTION_PROGRAMMEE_LISTE_CHIRURGIE_REFRESH_GETS_DATA_FAILED:
        return {
            ...state,
            isLoadingInterventionProgrammeesListeChirurgieRefresh: false,
            interventionProgrammeesListe: [],
            error: action.payload,
        }

    //////////////////////liste des interventions de chirurgie /////////////////////////////////////

    case CONSULTATION_CHIRURGIE_RESET:
      return {
        ...state,
        isLoadingGet: false,
        isLoadingGetActesChirugies: false,
        isLoadingPostConsultationMg: false,
        isLoadingPostTerminerConsultationMg: false,
        isLoadingGetConsultationMg: false,
        isLoadingPostSortieConsultationMg: false,
        isLoadingGetRefereInterneMg: false,
        isLoadingGetRefereExterneMg: false,

        isLoadingDemandeHospitalisation:false,

        isLoadingObservations:false,

        isLoadingHospitalisation: false,

        isSuccesGet: false,
        isSuccesGetMaladies: false,
        isSuccesGetActesChirugies: false,
        isSuccesPostConsultationMg: false,
        isSuccesPostTerminerConsultationMg: false,
        isSuccesGetConsultationMg: false,
        isSuccesPostDemandeHospitalisationMg: false,
        isSuccesPostActesInfirmiersMg: false,
        isSuccesPostSortieConsultationMg: false,
        isSuccesGetTypeExamenMg: false,
        isSuccesPostRefereInterneMg: false,
        isSuccesGetRefereInterneMg: false,
        isSuccesPostRefereExterneMg: false,
        isSuccesGetRefereExterneMg: false,
        isNonfoundConsultation: false,

        isLoadingPostSortieConsultationDemandeChirurgie: false,
        isSuccesPostSortieConsultationDemandeChirurgie: false,

        isLoadingDemandeDeChirurgiePost: false,
        isSuccesDemandeDeChirurgiePost: false,

        isLoadingRequeteOperatoireChirurgie: false,
        isSuccesRequeteOperatoireChirurgie: false,

        isLoadingRequeteOperatoireChirurgiePost: false,
        isSuccesRequeteOperatoireChirurgiePost: false,

        isLoadingInterventionProgrammeeChirurgiePost: false,
        isSuccesInterventionProgrammeeChirurgiePost: false,


        isError: false,
        error: "",
      };

    case CONSULTATION_CHIRURGIE_RESET_AFTER:
      return {
        ...state,
        isLoadingGetConsultationMg: false,

        isSuccesGetConsultationMg: false,
      }

    case AUTH_ERROR:
    case LOGOUT_SUCCESS:
    case TOKEN_REFRESH_FAIL:
      localStorage.removeItem("token_access");
      localStorage.removeItem("token_refresh");
      return {
        ...state,
        consulterPatientMgConstante: {},
        maladiesMg: [],
        actesChirugicauxMg: [],
        consultationPatientMg: {},
        typesExamens: [],

        demandeHospitalisation:[],

        observations: [],

        hospitalisation: [],

        isLoadingGet: false,
        isLoadingGetActesChirugies: false,
        isLoadingPostConsultationMg: false,
        isLoadingPostTerminerConsultationMg: false,
        isLoadingGetConsultationMg: false,
        isLoadingPostSortieConsultationMg: false,
        isLoadingGetRefereInterneMg: false,
        isLoadingGetRefereExterneMg: false,

        isLoadingDemandeHospitalisation:false,

        isLoadingObservations:false,

        isLoadingHospitalisation: false,

        isSuccesGet: false,
        isSuccesGetMaladies: false,
        isSuccesGetActesChirugies: false,
        isSuccesPostConsultationMg: false,
        isSuccesPostTerminerConsultationMg: false,
        isSuccesGetConsultationMg: false,
        isSuccesPostDemandeHospitalisationMg: false,
        isSuccesPostActesInfirmiersMg: false,
        isSuccesPostSortieConsultationMg: false,
        isSuccesGetTypeExamenMg: false,
        isSuccesPostRefereInterneMg: false,
        isSuccesGetRefereInterneMg: false,
        isSuccesPostRefereExterneMg: false,
        isSuccesGetRefereExterneMg: false,
        isNonfoundConsultation: false,

        isLoadingPostSortieConsultationDemandeChirurgie: false,
        consultationPatientDemandeChirurgie:{},
        isSuccesPostSortieConsultationDemandeChirurgie: false,

        isLoadingDemandeDeChirurgie: false,
        isLoadingDemandeDeChirurgieRefresh: false,
        demandeDeChirurgie: [],

        isLoadingDemandeDeChirurgiePost: false,
        isSuccesDemandeDeChirurgiePost: false,
        demandeDeChirurgiePost: {},

        isLoadingRequeteOperatoireChirurgiePost: false,
        isSuccesRequeteOperatoireChirurgiePost: false,
        requeteOperatoirePost: {},

        isLoadingRequeteOperatoireChirurgie: false,
        isSuccesRequeteOperatoireChirurgie: false,
        requeteOperatoire: {},

        isLoadingRequeteOperatoiresChirurgie: false,
        isLoadingRequeteOperatoiresChirurgieRefresh: false,
        requeteOperatoires: [],

        isLoadingInterventionProgrammeeChirurgie: false,
        isSuccesInterventionProgrammeeChirurgie: false,
        interventionProgrammee: {},

        isLoadingInterventionProgrammeeChirurgiePost: false,
        isSuccesInterventionProgrammeeChirurgiePost: false,
        interventionProgrammeePost: {},

        isLoadingInterventionProgrammeesChirurgie: false,
        isLoadingInterventionProgrammeesChirurgieRefresh: false,
        interventionProgrammees: [],

        isLoadingInterventionProgrammeesListeChirurgie: false,
        isLoadingInterventionProgrammeesListeChirurgieRefresh: false,
        interventionProgrammeesListe: [],

        isError: false,
        error: "",
      };

    default:
      return state;
  }
}
