import React, { lazy } from "react"
import * as MyPrivateRouters from "../routes/PrivateRouter";
import { Switch } from 'react-router-dom';


const LayoutCabinetDentairePatientAConsulter = lazy(() =>
    import(
      "../../components/cabinet-dentaire/patientAConsulter/LayoutCabinetDentairePatientAConsulter"
    )
);
const LayoutCabinetDentaireCreateConsultation = lazy(() =>
import(
    "../../components/cabinet-dentaire/patientAConsulterCreate/LayoutCabinetDentaireCreateConsultation"
)
);
const LayoutCabinetDentaireViewConsultation = lazy(() =>
import(
    "../../components/cabinet-dentaire/patientAConsulterDetail/LayoutCabinetDentaireViewConsultation"
)
);
const LayoutCabinetDentaireCreateConsultationSortie = lazy(() =>
import(
    "../../components/cabinet-dentaire/patientAConsulterIssuSortie/LayoutCabinetDentaireCreateConsultationSortie"
)
);
const LayoutCabinetDentaireCreateConsultationSortieDemandeHospitalisation =
lazy(() =>
    import(
    "../../components/cabinet-dentaire/patientAConsulterIssuSortie/DemandeHospitalisation/LayoutCabinetDentaireCreateConsultationSortieDemandeHospitalisation"
    )
);
const LayoutCabinetDentaireCreateConsultationSortieMiseEnObservation = lazy(
() =>
    import(
    "../../components/cabinet-dentaire/patientAConsulterIssuSortie/MiseEnObservation/LayoutCabinetDentaireCreateConsultationSortieMiseEnObservation"
    )
);
const LayoutCabinetDentaireCreateConsultationRefereInterne = lazy(() =>
import(
    "../../components/cabinet-dentaire/patientAConsulterIssuSortie/RefereInterne/LayoutCabinetDentaireCreateConsultationRefereInterne"
)
);
const LayoutCabinetDentaireCreateConsultationRefereExterne = lazy(() =>
import(
    "../../components/cabinet-dentaire/patientAConsulterIssuSortie/RefereExterne/LayoutCabinetDentaireCreateConsultationRefereExterne"
)
);

const LayoutCabinetDentaireConsultationListe = lazy(() =>
import(
    "../../components/cabinet-dentaire/consultation/LayoutCabinetDentaireConsultationListe"
)
);
const CabinetDentaireObservation = lazy(() =>
import("../../components/cabinet-dentaire/observation/CabinetDentaireObservation")
);
const CabinetDentaireObservationTerminees = lazy(() =>
    import("../../components/cabinet-dentaire/observationTerminees/CabinetDentaireObservationTerminees")
    );

const LayoutCabinetDentaireOrdonnanceDetails = lazy(() =>
import(
    "../../components/cabinet-dentaire/ordonnance/detail/LayoutCabinetDentaireOrdonnanceDetails"
)
);

const LayoutCabinetDentaireOrdonnanceListe = lazy(() =>
import(
    "../../components/cabinet-dentaire/ordonnance/liste/LayoutCabinetDentaireOrdonnanceListe"
)
);

const LayoutCabinetDentaireFeuilleDeSoins = lazy(() =>
import(
    "../../components/cabinet-dentaire/feuilleDeSoins/LayoutCabinetDentaireFeuilleDeSoins"
)
);
const CabinetDentaireRefExterne = lazy(() =>
import("../../components/cabinet-dentaire/refExterne/CabinetDentaireRefExterne")
);
const CabinetDentaireRefInterne = lazy(() =>
import("../../components/cabinet-dentaire/refInterne/CabinetDentaireRefInterne")
);
const LayoutCabinetDentaireDecesConsultationSortie = lazy(() =>
import(
    "../../components/cabinet-dentaire/patientAConsulterIssuSortie/IssueDeDeces/LayoutCabinetDentaireDecesConsultationSortie"
)
);

const RefInterneObservationCD = lazy(() =>
import(
    "../../components/cabinet-dentaire/patientAConsulterIssuSortie/MiseEnObservation/RefereInterne/LayoutCabinetDentaireCreateConsultationRefereInterne"
)
);
const RefExterneObservationCD = lazy(() =>
import(
    "../../components/cabinet-dentaire/patientAConsulterIssuSortie/MiseEnObservation/RefereExterne/LayoutCabinetDentaireCreateConsultationRefereExterne"
)
);

const CabinetDentaireExamensDetail = lazy(() =>
import("../../components/cabinet-dentaire/Examens/detail")
);
const CabinetDentaireExamensList = lazy(() =>
import("../../components/cabinet-dentaire/Examens/list")
);
const LayoutCabinetDentaireDecesListe = lazy(() =>
import(
    "../../components/cabinet-dentaire/Deces/Liste/LayoutCabinetDentaireDecesListe"
)
);
const LayoutCabinetDentaireDecesCertificat = lazy(() =>
import(
    "../../components/cabinet-dentaire/Deces/Certificat/LayoutCabinetDentaireDecesCertificat"
)
);

const AgendaCabinetDentaire = lazy(() =>
import(
    "../../components/cabinet-dentaire/rendezVous/agendaDetail/AgendaCabinetDentaire"
)
);
const LayoutCabinetDentaireCreateConsultationRendezVous = lazy(() =>
import(
    "../../components/cabinet-dentaire/patientAConsulterIssuSortie/RendezVous/LayoutCabinetDentaireCreateConsultationRendezVous"
)
);
const LayoutCabinetDentaireRdvListe = lazy(() =>
import(
    "../../components/cabinet-dentaire/rendezVous/Liste/LayoutCabinetDentaireRdvListe"
)
);
const LayoutCDRdvLastConsultation = lazy(() =>
import(
    "../../components/cabinet-dentaire/rendezVous/LastConsultation/LayoutCDRdvLastConsultation"
)
);
const LayoutCDRdvNewConsultation = lazy(() =>
import(
    "../../components/cabinet-dentaire/rendezVous/NewConsultation/LayoutCDRdvNewConsultation"
)
);
const LayoutVoirCabinetDentaireResultatsExamens = lazy(() =>
import(
    "../../components/cabinet-dentaire/Examens/resultatsExamensVoir/LayoutVoirMedecineGeneralResultatsExamens"
)
);

const RapportCabinetDentaire = lazy(() => import("../../components/cabinet-dentaire/rapports/RapportCabinetDentaire"));

const LayoutCabinetDentaireControleMajor = lazy(() => 
import("../../components/cabinet-dentaire/controleMajor/LayoutCabinetDentaireControleMajor")
)
  

export default function CabinetDentaireRouter() {
  return (
    <Switch>
        <MyPrivateRouters.PrivateRouterCabinetDentaires
        path="/cabinet-dentaire"
        component={LayoutCabinetDentairePatientAConsulter}
        />

        <MyPrivateRouters.PrivateRouterCabinetDentaires
        path="/cabinet-dentaire-consultation-create/:id"
        component={LayoutCabinetDentaireCreateConsultation}
        />

        <MyPrivateRouters.PrivateRouterCabinetDentaires
        path="/cabinet-dentaire-consultation-voir-detail/:id"
        component={LayoutCabinetDentaireViewConsultation}
        />

        <MyPrivateRouters.PrivateRouterCabinetDentaires
        path="/cabinet-dentaire-consultation-sortie/:id"
        component={LayoutCabinetDentaireCreateConsultationSortie}
        />

        <MyPrivateRouters.PrivateRouterCabinetDentaires
        path="/cabinet-dentaire-consultation-sortie-demande-hospitalisation/:id"
        component={
            LayoutCabinetDentaireCreateConsultationSortieDemandeHospitalisation
        }
        />

        <MyPrivateRouters.PrivateRouterCabinetDentaires
        path="/cabinet-dentaire-consultation-sortie-mise-en-observation/:id"
        component={
            LayoutCabinetDentaireCreateConsultationSortieMiseEnObservation
        }
        />

        <MyPrivateRouters.PrivateRouterCabinetDentaires
        path="/cabinet-dentaire-consultation-sortie-refere-interne/:id"
        component={LayoutCabinetDentaireCreateConsultationRefereInterne}
        />

        <MyPrivateRouters.PrivateRouterCabinetDentaires
        path="/cabinet-dentaire-consultation-sortie-refere-externe/:id"
        component={LayoutCabinetDentaireCreateConsultationRefereExterne}
        />

        <MyPrivateRouters.PrivateRouterCabinetDentaires
        path="/cabinet-dentaire-consultation-sortie-rdv/:id"
        component={LayoutCabinetDentaireCreateConsultationRendezVous}
        />

        <MyPrivateRouters.PrivateRouterCabinetDentaires
        path="/cabinet-dentaire-rdv-agenda/"
        component={AgendaCabinetDentaire}
        />

        <MyPrivateRouters.PrivateRouterCabinetDentaires
        path="/cabinet-dentaire-consultation"
        component={LayoutCabinetDentaireConsultationListe}
        />

        <MyPrivateRouters.PrivateRouterCabinetDentaires
        path="/cabinet-dentaire-observation-encours"
        component={CabinetDentaireObservation}
        />

        <MyPrivateRouters.PrivateRouterCabinetDentaires
        path="/cabinet-dentaire-observation-terminee"
        component={CabinetDentaireObservationTerminees}
        />

        <MyPrivateRouters.PrivateRouterCabinetDentaires
        path="/cabinet-dentaire-rdv"
        component={LayoutCabinetDentaireRdvListe}
        />
        
        <MyPrivateRouters.PrivateRouterCabinetDentaires
        path="/cabinet-dentaire-rdv-last-consultation/:id"
        component={LayoutCDRdvLastConsultation}
        />

        <MyPrivateRouters.PrivateRouterCabinetDentaires
        path="/cabinet-dentaire-rdv-new-consultation/:id"
        component={LayoutCDRdvNewConsultation}
        />

        <MyPrivateRouters.PrivateRouterCabinetDentaires
        path="/cabinet-dentaire-ordonnance"
        component={LayoutCabinetDentaireOrdonnanceListe}
        />

        <MyPrivateRouters.PrivateRouterCabinetDentaires
        path="/cabinet-dentaire-ordonnance-voir/:id"
        component={LayoutCabinetDentaireOrdonnanceDetails}
        />

        <MyPrivateRouters.PrivateRouterCabinetDentaires
        path="/cabinet-dentaire-feuille-de-soins/:id"
        component={LayoutCabinetDentaireFeuilleDeSoins}
        />

        <MyPrivateRouters.PrivateRouterCabinetDentaires
        path="/cabinet-dentaire-referencements-externe"
        component={CabinetDentaireRefExterne}
        />

        <MyPrivateRouters.PrivateRouterCabinetDentaires
        path="/cabinet-dentaire-referencements-interne"
        component={CabinetDentaireRefInterne}
        />

        <MyPrivateRouters.PrivateRouterCabinetDentaires
        path="/cabinet-dentaire-consultation-sortie-deces/:id"
        component={LayoutCabinetDentaireDecesConsultationSortie}
        />

        <MyPrivateRouters.PrivateRouterCabinetDentaires
        path="/cabinet-dentaire-observation-consultation/:id"
        component={LayoutCabinetDentaireCreateConsultation}
        />

        <MyPrivateRouters.PrivateRouterCabinetDentaires
        path="/cabinet-dentaire-exit-observation/:id"
        component={LayoutCabinetDentaireCreateConsultationSortie}
        />

        <MyPrivateRouters.PrivateRouterCabinetDentaires
        path="/cabinet-dentaire-observation-exit-reference-interne/:id"
        component={RefInterneObservationCD}
        />

        <MyPrivateRouters.PrivateRouterCabinetDentaires
        path="/cabinet-dentaire-observation-exit-reference-externe/:id"
        component={RefExterneObservationCD}
        />

        <MyPrivateRouters.PrivateRouterCabinetDentaires
        path="/cabinet-dentaire-observation-exit-issue-deces/:id"
        component={LayoutCabinetDentaireDecesConsultationSortie}
        />

        <MyPrivateRouters.PrivateRouterCabinetDentaires
        path="/cabinet-dentaire-observation-exit-rdv/:id"
        component={LayoutCabinetDentaireCreateConsultationRendezVous}
        />

        <MyPrivateRouters.PrivateRouterCabinetDentaires
        path="/cabinet-dentaire-examens-detail/:id/"
        component={CabinetDentaireExamensDetail}
        />

        <MyPrivateRouters.PrivateRouterCabinetDentaires
        path="/cabinet-dentaire-examens"
        component={CabinetDentaireExamensList}
        />

        <MyPrivateRouters.PrivateRouterCabinetDentaires
        path="/cabinet-dentaire-patients-decedes"
        component={LayoutCabinetDentaireDecesListe}
        />

        <MyPrivateRouters.PrivateRouterCabinetDentaires
        path="/cabinet-dentaire-decedes-certificat/:id"
        component={LayoutCabinetDentaireDecesCertificat}
        />

        <MyPrivateRouters.PrivateRouterCabinetDentaires
        path="/cabinet-examens-resultat-voir/:id"
        component={LayoutVoirCabinetDentaireResultatsExamens}
        />

        <MyPrivateRouters.PrivateRouterCabinetDentaires
        path="/cabinet-dentaire-rapports"
        component={RapportCabinetDentaire}
        />

        <MyPrivateRouters.PrivateRouterCabinetDentaires
        path="/cabinet-dentaire-controle-major"
        component={LayoutCabinetDentaireControleMajor}
        />
    </Switch>
  )
}
