export const ASSURANCE_GLOBALE_GET_DATA_LOADING = 'ASSURANCE_GLOBALE_GET_DATA_LOADING'

export const ASSURANCE_GLOBALE_GET_DATA_SUCCESS = 'ASSURANCE_GLOBALE_GET_DATA_SUCCESS'

export const ASSURANCE_GLOBALE_GET_DATA_FAILED = 'ASSURANCE_GLOBALE_GET_DATA_FAILED'

export const ASSURANCE_GLOBALE_GET_DATA_FAILED_403 = "ASSURANCE_GLOBALE_GET_DATA_FAILED_403"

export const ASSURANCE_GLOBALE_GET_DATA_FAILED_500 = "ASSURANCE_GLOBALE_GET_DATA_FAILED_500"


export const ASSURANCE_GLOBALE_POST_DATA_LOADING = 'ASSURANCE_GLOBALE_POST_DATA_LOADING'

export const ASSURANCE_GLOBALE_POST_DATA_SUCCESS = 'ASSURANCE_GLOBALE_POST_DATA_SUCCESS'

export const ASSURANCE_GLOBALE_POST_DATA_FAILED = 'ASSURANCE_GLOBALE_POST_DATA_FAILED'

export const ASSURANCE_GLOBALE_POST_DATA_FAILED_403 = "ASSURANCE_GLOBALE_POST_DATA_FAILED_403"

export const ASSURANCE_GLOBALE_POST_DATA_FAILED_500 = "ASSURANCE_GLOBALE_POST_DATA_FAILED_500"


export const ASSURANCE_GLOBALE_PUT_DATA_LOADING = 'ASSURANCE_GLOBALE_PUT_DATA_LOADING'

export const ASSURANCE_GLOBALE_PUT_DATA_SUCCESS = 'ASSURANCE_GLOBALE_PUT_DATA_SUCCESS'

export const ASSURANCE_GLOBALE_PUT_DATA_FAILED = 'ASSURANCE_GLOBALE_PUT_DATA_FAILED'

export const ASSURANCE_GLOBALE_PUT_DATA_FAILED_403 = "ASSURANCE_GLOBALE_PUT_DATA_FAILED_403"

export const ASSURANCE_GLOBALE_PUT_DATA_FAILED_500 = "ASSURANCE_GLOBALE_PUT_DATA_FAILED_500"


export const ASSURANCE_GLOBALE_DELETE_DATA_LOADING = 'ASSURANCE_GLOBALE_DELETE_DATA_LOADING'

export const ASSURANCE_GLOBALE_DELETE_DATA_SUCCESS = 'ASSURANCE_GLOBALE_DELETE_DATA_SUCCESS'

export const ASSURANCE_GLOBALE_DELETE_DATA_FAILED = 'ASSURANCE_GLOBALE_DELETE_DATA_FAILED'

export const ASSURANCE_GLOBALE_DELETE_DATA_FAILED_403 = "ASSURANCE_GLOBALE_DELETE_DATA_FAILED_403"

export const ASSURANCE_GLOBALE_DELETE_DATA_FAILED_500 = "ASSURANCE_GLOBALE_DELETE_DATA_FAILED_500"


export const ASSURANCE_GLOBALE_GETS_DATA_LOADING = 'ASSURANCE_GLOBALE_GETS_DATA_LOADING'

export const ASSURANCE_GLOBALE_GETS_DATA_SUCCESS = 'ASSURANCE_GLOBALE_GETS_DATA_SUCCESS'

export const ASSURANCE_GLOBALE_GETS_DATA_FAILED = 'ASSURANCE_GLOBALE_GETS_DATA_FAILED'

export const ASSURANCE_GLOBALE_GETS_DATA_FAILED_403 = "ASSURANCE_GLOBALE_GETS_DATA_FAILED_403"

export const ASSURANCE_GLOBALE_GETS_DATA_FAILED_500 = "ASSURANCE_GLOBALE_GETS_DATA_FAILED_500"


export const ASSURANCE_GLOBALE_GETS_REFRESH_DATA_LOADING = 'ASSURANCE_GLOBALE_GETS_REFRESH_DATA_LOADING'

export const ASSURANCE_GLOBALE_GETS_REFRESH_DATA_SUCCESS = 'ASSURANCE_GLOBALE_GETS_REFRESH_DATA_SUCCESS'

export const ASSURANCE_GLOBALE_GETS_REFRESH_DATA_FAILED = 'ASSURANCE_GLOBALE_GETS_REFRESH_DATA_FAILED'

export const ASSURANCE_GLOBALE_GETS_REFRESH_DATA_FAILED_403 = "ASSURANCE_GLOBALE_GETS_REFRESH_DATA_FAILED_403"

export const ASSURANCE_GLOBALE_GETS_REFRESH_DATA_FAILED_500 = "ASSURANCE_GLOBALE_GETS_REFRESH_DATA_FAILED_500"


export const ASSURANCE_GLOBALE_CLEAR_STATE = "ASSURANCE_GLOBALE_CLEAR_STATE"