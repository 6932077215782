export const VILLE_GET_DATA_LOADING = 'VILLE_GET_DATA_LOADING'

export const VILLE_GET_DATA_SUCCESS = 'VILLE_GET_DATA_SUCCESS'

export const VILLE_GET_DATA_FAILED = 'VILLE_GET_DATA_FAILED'

export const VILLE_GET_DATA_FAILED_403 = "VILLE_GET_DATA_FAILED_403"

export const VILLE_GET_DATA_FAILED_500 = "VILLE_GET_DATA_FAILED_500"


export const VILLE_POST_DATA_LOADING = 'VILLE_POST_DATA_LOADING'

export const VILLE_POST_DATA_SUCCESS = 'VILLE_POST_DATA_SUCCESS'

export const VILLE_POST_DATA_FAILED = 'VILLE_POST_DATA_FAILED'

export const VILLE_POST_DATA_FAILED_403 = "VILLE_POST_DATA_FAILED_403"

export const VILLE_POST_DATA_FAILED_500 = "VILLE_POST_DATA_FAILED_500"


export const VILLE_PUT_DATA_LOADING = 'VILLE_PUT_DATA_LOADING'

export const VILLE_PUT_DATA_SUCCESS = 'VILLE_PUT_DATA_SUCCESS'

export const VILLE_PUT_DATA_FAILED = 'VILLE_PUT_DATA_FAILED'

export const VILLE_PUT_DATA_FAILED_403 = "VILLE_PUT_DATA_FAILED_403"

export const VILLE_PUT_DATA_FAILED_500 = "VILLE_PUT_DATA_FAILED_500"


export const VILLE_DELETE_DATA_LOADING = 'VILLE_DELETE_DATA_LOADING'

export const VILLE_DELETE_DATA_SUCCESS = 'VILLE_DELETE_DATA_SUCCESS'

export const VILLE_DELETE_DATA_FAILED = 'VILLE_DELETE_DATA_FAILED'

export const VILLE_DELETE_DATA_FAILED_403 = "VILLE_DELETE_DATA_FAILED_403"

export const VILLE_DELETE_DATA_FAILED_500 = "VILLE_DELETE_DATA_FAILED_500"


export const VILLE_GETS_DATA_LOADING = 'VILLE_GETS_DATA_LOADING'

export const VILLE_GETS_DATA_SUCCESS = 'VILLE_GETS_DATA_SUCCESS'

export const VILLE_GETS_DATA_FAILED = 'VILLE_GETS_DATA_FAILED'

export const VILLE_GETS_DATA_FAILED_403 = "VILLE_GETS_DATA_FAILED_403"

export const VILLE_GETS_DATA_FAILED_500 = "VILLE_GETS_DATA_FAILED_500"


export const VILLE_GETS_REFRESH_DATA_LOADING = 'VILLE_GETS_REFRESH_DATA_LOADING'

export const VILLE_GETS_REFRESH_DATA_SUCCESS = 'VILLE_GETS_REFRESH_DATA_SUCCESS'

export const VILLE_GETS_REFRESH_DATA_FAILED = 'VILLE_GETS_REFRESH_DATA_FAILED'

export const VILLE_GETS_REFRESH_DATA_FAILED_403 = "VILLE_GETS_REFRESH_DATA_FAILED_403"

export const VILLE_GETS_REFRESH_DATA_FAILED_500 = "VILLE_GETS_REFRESH_DATA_FAILED_500"


export const VILLE_CLEAR_STATE = "VILLE_CLEAR_STATE"