import React, { lazy } from "react"
import * as MyPrivateRouters from "../routes/PrivateRouter";
import { Switch } from 'react-router-dom';


const LayoutCasSociauxRequeteListe = lazy(() =>
    import("../../components/cas-social/Requete/Liste/LayoutCasSociauxRequeteListe")
);
const LayoutCasSociauxRequeteCreate = lazy(() =>
    import("../../components/cas-social/Requete/Form/LayoutCasSociauxRequeteCreate")
);

const LayoutCasSociauxRequeteDetails = lazy(() =>
    import("../../components/cas-social/Requete/Voir/LayoutCasSociauxRequeteDetails")
);
const LayoutCasSociauxRequeteTraiteListe = lazy(() =>
    import(
        "../../components/cas-social/RequeteTraite/LayoutCasSociauxRequeteTraiteListe"
    )
);
const LayoutCasSociauxRapport = lazy(() =>
    import(
        "../../components/cas-social/rapport/LayoutCasSociauxRapport"
    )
);
  

export default function CasSociauxRouter() {
  return (
    <Switch>
        <MyPrivateRouters.PrivateRouterCasSociaux
        path="/cas-social"
        component={LayoutCasSociauxRequeteListe}
        />

        <MyPrivateRouters.PrivateRouterCasSociaux
        path="/cas-social-requete-details/:id"
        component={LayoutCasSociauxRequeteDetails}
        />

        <MyPrivateRouters.PrivateRouterCasSociaux
        path="/cas-social-requete-create/:id"
        component={LayoutCasSociauxRequeteCreate}
        />

        <MyPrivateRouters.PrivateRouterCasSociaux
        path="/cas-social-requete-traite-liste"
        component={LayoutCasSociauxRequeteTraiteListe}
        />

        <MyPrivateRouters.PrivateRouterCasSociaux
        path="/cas-social-rapport"
        component={LayoutCasSociauxRapport}
        />
    </Switch>
  )
}
