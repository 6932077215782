import React, { lazy } from "react"
import * as MyPrivateRouters from "../routes/PrivateRouter";
import { Switch } from 'react-router-dom';


const LayoutOphtalmologiePatientAConsulter = lazy(() =>
    import(
      "../../components/ophtalmologie/patientAConsulter/LayoutOphtalmologiePatientAConsulter"
    )
);
const LayoutOphtalmologieConsultationCreate = lazy(() =>
    import(
        "../../components/ophtalmologie/patientAConsulterCreate/LayoutOphtalmologieConsultationCreate"
    )
);
const LayoutOphtalmologieConsultationView = lazy(() =>
    import(
        "../../components/ophtalmologie/patientAConsulterDetail/LayoutOphtalmologieConsultationView"
    )
);
const LayoutOphtalmologieCreateConsultationSortie = lazy(() =>
    import(
        "../../components/ophtalmologie/patientAConsulterIssuSortie/LayoutOphtalmologieCreateConsultationSortie"
    )
);
const LayoutOphtalmologieCreateConsultationSortieDemandeHospitalisation = lazy(() =>
    import(
        "../../components/ophtalmologie/patientAConsulterIssuSortie/DemandeHospitalisation/LayoutOphtalmologieCreateConsultationSortieDemandeHospitalisation"
    )
);
const LayoutOphtalmologieCreateConsultationSortieMiseEnObservation = lazy(() =>
    import(
        "../../components/ophtalmologie/patientAConsulterIssuSortie/MiseEnObservation/LayoutOphtalmologieCreateConsultationSortieMiseEnObservation"
    )
);
const LayoutOphtalmologieCreateConsultationRefereExterne = lazy(() =>
    import(
        "../../components/ophtalmologie/patientAConsulterIssuSortie/RefereExterne/LayoutOphtalmologieCreateConsultationRefereExterne"
    )
);
const LayoutOphtalmologieCreateConsultationRefereInterne = lazy(() =>
    import(
        "../../components/ophtalmologie/patientAConsulterIssuSortie/RefereInterne/LayoutOphtalmologieCreateConsultationRefereInterne"
    )
);

const LayoutOphtalmologieDecesConsultationSortie = lazy(() =>
    import(
        "../../components/ophtalmologie/patientAConsulterIssuSortie/IssueDeDeces/LayoutOphtalmologieDecesConsultationSortie"
    )
);

const LayoutOphtalmologieFeuilleDeSoins = lazy(() =>
    import(
        "../../components/ophtalmologie/feuilleDeSoins/LayoutOphtalmologieFeuilleDeSoins"
    )
);

const LayoutOphtalmologieDemandeHospitalisationListe = lazy(() =>
    import(
        "../../components/ophtalmologie/demandeHospitalisation/LayoutOphtalmologieDemandeHospitalisationListe"
    )
);
const LayoutOphtalmologieHospitalisationConsultationCreate = lazy(() =>
    import(
        "../../components/ophtalmologie/hospitalisation/consultation/LayoutOphtalmologieHospitalisationConsultationCreate"
    )
);
const LayoutOphtalmologieHospitalisationConsultationSortie = lazy(() =>
    import(
        "../../components/ophtalmologie/hospitalisation/issueSortie/LayoutOphtalmologieHospitalisationConsultationSortie"
    )
);
const LayoutOphtalmologieHospitalisationListe = lazy(() =>
    import(
        "../../components/ophtalmologie/hospitalisation/LayoutOphtalmologieHospitalisationListe"
    )
);
const LayoutOphtalmologieConsultationListe = lazy(() =>
    import(
        "../../components/ophtalmologie/consultation/LayoutOphtalmologieConsultationListe"
    )
);
const OphtalmologieObservation = lazy(() =>
    import("../../components/ophtalmologie/observation/OphtalmologieObservation")
);

const LayoutOphtalmologieOrdonnanceListe = lazy(() =>
    import(
        "../../components/ophtalmologie/ordonnance/liste/LayoutOphtalmologieOrdonnanceListe"
    )
);
const LayoutOphtalmologieOrdonnanceDetails = lazy(() =>
    import(
        "../../components/ophtalmologie/ordonnance/detail/LayoutOphtalmologieOrdonnanceDetails"
    )
);

const OphtalmologieExamensDetail = lazy(() =>
    import("../../components/ophtalmologie/Examens/detail")
);
const OphtalmologieExamensList = lazy(() =>
    import("../../components/ophtalmologie/Examens/list")
);

const LayoutOphtalmologieDecesListe = lazy(() =>
    import("../../components/ophtalmologie/Deces/Liste/LayoutOphtalmologieDecesListe")
);
const LayoutOphtalmologieDecesCertificat = lazy(() =>
    import(
        "../../components/ophtalmologie/Deces/Certificat/LayoutOphtalmologieDecesCertificat"
    )
);
const OphtalmologieRefExterne = lazy(() =>
    import("../../components/ophtalmologie/refExterne/OphtalmologieRefExterne")
);
const OphtalmologieRefInterne = lazy(() =>
    import("../../components/ophtalmologie/refInterne/OphtalmologieRefInterne")
);
const OrdonnanceLunette = lazy(() =>
    import("../../components/ophtalmologie/ordonnanceLunette/liste/OrdonnanceLunette")
);
const LayoutOrdonnanceLunette = lazy(() =>
    import(
        "../../components/ophtalmologie/ordonnanceLunette/detail/LayoutOrdonnanceLunette"
    )
);

const AgendaOphtalmologie = lazy(() =>
    import(
        "../../components/ophtalmologie/rendezVous/agendaDetail/AgendaOphtalmologie"
    )
);
const LayoutOphtalmologieCreateConsultationRendezVous = lazy(() =>
    import(
        "../../components/ophtalmologie/patientAConsulterIssuSortie/RendezVous/LayoutOphtalmologieCreateConsultationRendezVous"
    )
);
const LayoutOphtalmologieRdvListe = lazy(() =>
    import(
        "../../components/ophtalmologie/rendezVous/Liste/LayoutOphtalmologieRdvListe"
    )
);

const LayoutOphtaRdvLastConsultation = lazy(() =>
    import(
        "../../components/ophtalmologie/rendezVous/LastConsultation/LayoutOphtaRdvLastConsultation"
    )
);

const LayoutOphtaRdvNewConsultation = lazy(() =>
    import(
        "../../components/ophtalmologie/rendezVous/NewConsultation/LayoutCardioRdvNewConsultation"
    )
);

const LayoutVoirOphtalmologieResultatsExamens = lazy(() =>
    import(
        "../../components/ophtalmologie/Examens/resultatsExamensVoir/LayoutVoirMedecineGeneralResultatsExamens"
    )
);


const RapportOphtalmologie = lazy(() =>
    import(
        "../../components/ophtalmologie/rapports/RapportOphtalmologie"
    )
);

const LayoutOphtalmologieControleMajor = lazy(() => 
    import("../../components/ophtalmologie/controleMajor/LayoutOphtalmologieControleMajor")
)
  

export default function OphtalmologieRouter() {
  return (
    <Switch>
        <MyPrivateRouters.PrivateRouterisOphtalmologie
        path="/ophtalmologie"
        component={LayoutOphtalmologiePatientAConsulter}
        />

        <MyPrivateRouters.PrivateRouterisOphtalmologie
        path="/ophtalmologie-consultation-create/:id"
        component={LayoutOphtalmologieConsultationCreate}
        />

        <MyPrivateRouters.PrivateRouterisOphtalmologie
        path="/ophtalmologie-consultation-voir-detail/:id"
        component={LayoutOphtalmologieConsultationView}
        />

        <MyPrivateRouters.PrivateRouterisOphtalmologie
        path="/ophtalmologie-feuille-de-soins/:id"
        component={LayoutOphtalmologieFeuilleDeSoins}
        />

        <MyPrivateRouters.PrivateRouterisOphtalmologie
        path="/ophtalmologie-consultation-sortie/:id"
        component={LayoutOphtalmologieCreateConsultationSortie}
        />

        <MyPrivateRouters.PrivateRouterisOphtalmologie
        path="/ophtalmologie-consultation-sortie-demande-hospitalisation/:id"
        component={
            LayoutOphtalmologieCreateConsultationSortieDemandeHospitalisation
        }
        />

        <MyPrivateRouters.PrivateRouterisOphtalmologie
        path="/ophtalmologie-consultation-sortie-mise-en-observation/:id"
        component={
            LayoutOphtalmologieCreateConsultationSortieMiseEnObservation
        }
        />

        <MyPrivateRouters.PrivateRouterisOphtalmologie
        path="/ophtalmologie-consultation-sortie-refere-interne/:id"
        component={LayoutOphtalmologieCreateConsultationRefereInterne}
        />

        <MyPrivateRouters.PrivateRouterisOphtalmologie
        path="/ophtalmologie-consultation-sortie-refere-externe/:id"
        component={LayoutOphtalmologieCreateConsultationRefereExterne}
        />

        <MyPrivateRouters.PrivateRouterisOphtalmologie
        path="/ophtalmologie-consultation-sortie-rdv/:id"
        component={LayoutOphtalmologieCreateConsultationRendezVous}
        />

        <MyPrivateRouters.PrivateRouterisOphtalmologie
        path="/ophtalmologie-consultation-sortie-issue-deces/:id"
        component={LayoutOphtalmologieDecesConsultationSortie}
        />

        <MyPrivateRouters.PrivateRouterisOphtalmologie
        path="/ophtalmologie-rdv-agenda/"
        component={AgendaOphtalmologie}
        />

        <MyPrivateRouters.PrivateRouterisOphtalmologie
        path="/ophtalmologie-demande-hospitalisation"
        component={LayoutOphtalmologieDemandeHospitalisationListe}
        />

        <MyPrivateRouters.PrivateRouterisOphtalmologie
        path="/ophtalmologie-hospitalisation-consultation/:id"
        component={LayoutOphtalmologieHospitalisationConsultationCreate}
        />

        <MyPrivateRouters.PrivateRouterisOphtalmologie
        path="/ophtalmologie-hospitalisation-sortie/:id"
        component={LayoutOphtalmologieHospitalisationConsultationSortie}
        />

        <MyPrivateRouters.PrivateRouterisOphtalmologie
        path="/ophtalmologie-hospitalisation"
        component={LayoutOphtalmologieHospitalisationListe}
        />

        <MyPrivateRouters.PrivateRouterisOphtalmologie
        path="/ophtalmologie-consultation"
        component={LayoutOphtalmologieConsultationListe}
        />

        <MyPrivateRouters.PrivateRouterisOphtalmologie
        path="/ophtalmologie-observation-encours"
        component={OphtalmologieObservation}
        />

        <MyPrivateRouters.PrivateRouterisOphtalmologie
        path="/ophtalmologie-rdv"
        component={LayoutOphtalmologieRdvListe}
        />

        <MyPrivateRouters.PrivateRouterisOphtalmologie
        path="/ophtalmologie-rdv-last-consultation/:id"
        component={LayoutOphtaRdvLastConsultation}
        />

        <MyPrivateRouters.PrivateRouterisOphtalmologie
        path="/ophtalmologie-rdv-new-consultation/:id"
        component={LayoutOphtaRdvNewConsultation}
        />

        <MyPrivateRouters.PrivateRouterisOphtalmologie
        path="/ophtalmologie-ordonnance"
        component={LayoutOphtalmologieOrdonnanceListe}
        />

        <MyPrivateRouters.PrivateRouterisOphtalmologie
        path="/ophtalmologie-ordonnance-voir/:id"
        component={LayoutOphtalmologieOrdonnanceDetails}
        />

        <MyPrivateRouters.PrivateRouterisOphtalmologie
        path="/ophtalmologie-examens-detail/:id/"
        component={OphtalmologieExamensDetail}
        />

        <MyPrivateRouters.PrivateRouterisOphtalmologie
        path="/ophtalmologie-examens"
        component={OphtalmologieExamensList}
        />

        <MyPrivateRouters.PrivateRouterisOphtalmologie
        path="/ophtalmologie-patients-decedes"
        component={LayoutOphtalmologieDecesListe}
        />

        <MyPrivateRouters.PrivateRouterisOphtalmologie
        path="/ophtalmologie-patients-decedes-certificat/:id"
        component={LayoutOphtalmologieDecesCertificat}
        />

        <MyPrivateRouters.PrivateRouterisOphtalmologie
        path="/ophtalmologie-referencements-externe"
        component={OphtalmologieRefExterne}
        />

        <MyPrivateRouters.PrivateRouterisOphtalmologie
        path="/ophtalmologie-referencements-interne"
        component={OphtalmologieRefInterne}
        />

        <MyPrivateRouters.PrivateRouterisOphtalmologie
        path="/ophtalmologie-ordonnance-lunette"
        component={OrdonnanceLunette}
        />

        <MyPrivateRouters.PrivateRouterisOphtalmologie
        path="/ophtalmologie-ordonnance-lunette-voir/:id"
        component={LayoutOrdonnanceLunette}
        />

        <MyPrivateRouters.PrivateRouterisOphtalmologie
        path="/ophtalmologie-examens-resultat-voir/:id"
        component={LayoutVoirOphtalmologieResultatsExamens}
        />

        <MyPrivateRouters.PrivateRouterisOphtalmologie
        path="/ophtalmologie-rapports"
        component={RapportOphtalmologie}
        />

        <MyPrivateRouters.PrivateRouterisOphtalmologie
        path="/ophtalmologie-controle-major"
        component={LayoutOphtalmologieControleMajor}
        />
    </Switch>
  )
}
