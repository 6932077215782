import { hospitalisationTypes } from "../../types/hospitalisationTypes";

import { AUTH_ERROR, LOGOUT_SUCCESS, TOKEN_REFRESH_FAIL } from "../../types/userTypes";

const initialState = {
  isLoading: false,
  isLoadingRefresh: false,

  isSucces: false,
  isSuccesRefresh: false,

  data: [],
  error: "",
};


export const hospitalisationsAccueilsReducers = (state = initialState, action) => {
  switch (action.type) {
    case hospitalisationTypes.HOSPITALISATIONS_LIST_ACCUEIL_LOADING:
      return {
        ...state,
        isLoading: true,
        isSucces: false,
        error: "",
      };
    case hospitalisationTypes.HOSPITALISATIONS_LIST_ACCUEIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSucces: true,
        data: action.payload,
        error: "",
      };
    case hospitalisationTypes.HOSPITALISATIONS_LIST_ACCUEIL_FAILED:
      return {
        ...state,
        isLoading: false,
        isSucces: false,
        error: action.payload,
      };
    

    case hospitalisationTypes.HOSPITALISATIONS_LIST_ACCUEIL_REFRESH_LOADING:
      return {
        ...state,
        isLoadingRefresh: true,
        isSuccesRefresh: false,
        error: "",
    };
    case hospitalisationTypes.HOSPITALISATIONS_LIST_ACCUEIL_REFRESH_SUCCESS:
      return {
        ...state,
        isLoadingRefresh: false,
        isSuccesRefresh: true,
        data: action.payload,
        error: "",
    };
    case hospitalisationTypes.HOSPITALISATIONS_LIST_ACCUEIL_REFRESH_FAILED:
      return {
        ...state,
        isLoadingRefresh: false,
        isSuccesRefresh: false,
        error: action.payload,
    };

    case AUTH_ERROR:
    case LOGOUT_SUCCESS:
    case TOKEN_REFRESH_FAIL:
        localStorage.removeItem('token_access')
        localStorage.removeItem('token_refresh')
        return {
            ...state,
            isLoading: false,
            isLoadingRefresh: false,

            isSucces: false,
            isSuccesRefresh: false,

            data: [],
            error: ""
        }

    default:
      return state;
  }
};
