export const INFIRMERIE_PATIENT_GET_A_INFIRMERIE_SUCCESS = 'INFIRMERIE_PATIENT_GET_A_INFIRMERIE_SUCCESS'

export const INFIRMERIE_PATIENT_GET_A_INFIRMERIE_FAILED = 'INFIRMERIE_PATIENT_GET_A_INFIRMERIE_FAILED'

export const INFIRMERIE_PATIENT_GET_A_INFIRMERIE_LOADING = 'INFIRMERIE_PATIENT_GET_A_INFIRMERIE_LOADING'


export const INFIRMERIE_PATIENTS_GETS_A_INFIRMERIE_SUCCESS = 'INFIRMERIE_PATIENTS_GETS_A_INFIRMERIE_SUCCESS'

export const INFIRMERIE_PATIENTS_GETS_A_INFIRMERIE_FAILED = 'INFIRMERIE_PATIENTS_GETS_A_INFIRMERIE_FAILED'

export const INFIRMERIE_PATIENTS_GETS_A_INFIRMERIE_LOADING = 'INFIRMERIE_PATIENTS_GETS_A_INFIRMERIE_LOADING'

export const INFIRMERIE_PATIENT_POST_CONSULTER_INFIRMERIE_SUCCESS = 'INFIRMERIE_PATIENT_POST_CONSULTER_INFIRMERIE_SUCCESS'

export const INFIRMERIE_PATIENT_POST_CONSULTER_INFIRMERIE_FAILED = 'INFIRMERIE_PATIENT_POST_CONSULTER_INFIRMERIE_FAILED'

export const INFIRMERIE_PATIENT_POST_CONSULTER_INFIRMERIE_LOADING = 'INFIRMERIE_PATIENT_POST_CONSULTER_INFIRMERIE_LOADING'


export const INFIRMERIE_PATIENT_PUT_CONSULTER_INFIRMERIE_SUCCESS = 'INFIRMERIE_PATIENT_PUT_CONSULTER_INFIRMERIE_SUCCESS'

export const INFIRMERIE_PATIENT_PUT_CONSULTER_INFIRMERIE_FAILED = 'INFIRMERIE_PATIENT_PUT_CONSULTER_INFIRMERIE_FAILED'

export const INFIRMERIE_PATIENT_PUT_CONSULTER_INFIRMERIE_LOADING = 'INFIRMERIE_PATIENT_PUT_CONSULTER_INFIRMERIE_LOADING'


export const INFIRMERIE_PATIENT_GET_CONSULTER_INFIRMERIE_SUCCESS = 'INFIRMERIE_PATIENT_GET_CONSULTER_INFIRMERIE_SUCCESS'

export const INFIRMERIE_PATIENT_GET_CONSULTER_INFIRMERIE_FAILED = 'INFIRMERIE_PATIENT_GET_CONSULTER_INFIRMERIE_FAILED'

export const INFIRMERIE_PATIENT_GET_CONSULTER_INFIRMERIE_LOADING = 'INFIRMERIE_PATIENT_GET_CONSULTER_INFIRMERIE_LOADING'


export const INFIRMERIE_PATIENTS_GETS_CONSULTER_INFIRMERIE_SUCCESS = 'INFIRMERIE_PATIENTS_GETS_CONSULTER_INFIRMERIE_SUCCESS'

export const INFIRMERIE_PATIENTS_GETS_CONSULTER_INFIRMERIE_FAILED = 'INFIRMERIE_PATIENTS_GETS_CONSULTER_INFIRMERIE_FAILED'

export const INFIRMERIE_PATIENTS_GETS_CONSULTER_INFIRMERIE_LOADING = 'INFIRMERIE_PATIENTS_GETS_CONSULTER_INFIRMERIE_LOADING'


export const INFIRMERIE_PATIENTS_GETS_CONSULTER_INFIRMERIE_REFRESH_SUCCESS = 'INFIRMERIE_PATIENTS_GETS_CONSULTER_INFIRMERIE_REFRESH_SUCCESS'

export const INFIRMERIE_PATIENTS_GETS_CONSULTER_INFIRMERIE_REFRESH_FAILED = 'INFIRMERIE_PATIENTS_GETS_CONSULTER_INFIRMERIE_REFRESH_FAILED'

export const INFIRMERIE_PATIENTS_GETS_CONSULTER_INFIRMERIE_REFRESH_LOADING = 'INFIRMERIE_PATIENTS_GETS_CONSULTER_INFIRMERIE_REFRESH_LOADING'


export const INFIRMERIE_REORIENTATION_POST_SUCCESS = 'INFIRMERIE_REORIENTATION_POST_SUCCESS'

export const INFIRMERIE_REORIENTATION_POST_FAILED = 'INFIRMERIE_REORIENTATION_POST_FAILED'

export const INFIRMERIE_REORIENTATION_POST_LOADING = 'INFIRMERIE_REORIENTATION_POST_LOADING'



export const INFIRMERIE_PROFESIONNELS_SANTE_A_CONSULTER_GETS_SUCCESS = 'INFIRMERIE_PROFESIONNELS_SANTE_A_CONSULTER_GETS_SUCCESS'

export const INFIRMERIE_PROFESIONNELS_SANTE_A_CONSULTER_GETS_FAILED = 'INFIRMERIE_PROFESIONNELS_SANTE_A_CONSULTER_GETS_FAILED'

export const INFIRMERIE_PROFESIONNELS_SANTE_A_CONSULTER_GETS_LOADING = 'INFIRMERIE_PROFESIONNELS_SANTE_A_CONSULTER_GETS_LOADING'


export const INFIRMERIE_PROFESIONNELS_SANTE_BY_SERVICE_GETS_SUCCESS = 'INFIRMERIE_PROFESIONNELS_SANTE_BY_SERVICE_GETS_SUCCESS'

export const INFIRMERIE_PROFESIONNELS_SANTE_BY_SERVICE_GETS_FAILED = 'INFIRMERIE_PROFESIONNELS_SANTE_BY_SERVICE_GETS_FAILED'

export const INFIRMERIE_PROFESIONNELS_SANTE_BY_SERVICE_GETS_LOADING = 'INFIRMERIE_PROFESIONNELS_SANTE_BY_SERVICE_GETS_LOADING'


export const INFIRMERIE_STARTER_RESET = 'INFIRMERIE_STARTER_RESET'

export const GET_ZSCORE = "GET_ZSCORE"
export const REQUEST_ZSCORE = "REQUEST_ZSCORE"
export const FAIL_ZSCORE = "FAIL_ZSCORE"