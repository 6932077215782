import { AUTH_ERROR, LOGOUT_SUCCESS, TOKEN_REFRESH_FAIL } from "../../types/userTypes"

import * as parametreActeLaboTypes from "../../types/parametreActeLaboTypes"

// import jwtDecode from "jwt-decode"

const initialState = {
    isLoading: false,

    isLoadingGet: false,
    isLoadingPost: false,
    isLoadingUpdate: false,
    isLoadingDelete: false,

    parametreactes: [],
    parametreacteGet: {},
    parametreactePost: {},
    parametreacteUpdate: {},

    isSuccessGet: false,
    isSuccessPost: false,
    isSuccessUpdate: false,

    error: "",
    isError: false
}

// eslint-disable-next-line
export default function(state = initialState, action){
    switch (action.type) {
        case parametreActeLaboTypes.PARAMETRE_ACTE_LABO_GETS_LOADING:
            return {
                ...state,
                isLoading: true
            }
        case parametreActeLaboTypes.PARAMETRE_ACTE_LABO_GETS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                parametreactes: action.payload
            }
        case parametreActeLaboTypes.PARAMETRE_ACTE_LABO_GETS_FAILED:
            return {
                ...state,
                isLoading: false,        
            }
        
        
        //load get parametreacte in dpi
        case parametreActeLaboTypes.PARAMETRE_ACTE_LABO_GET_LOADING:
            return {
                ...state,
                isLoadingGet: true,
                isSuccessGet: false,
                parametreacteGet: {},
                error: "",
                isError: false,
            }
        case parametreActeLaboTypes.PARAMETRE_ACTE_LABO_GET_SUCCESS:
            return {
                ...state,
                isLoadingGet: false,
                isSuccessGet: true,
                parametreacteGet: action.payload,
                error: "",
                isError: false,
            }
        case parametreActeLaboTypes.PARAMETRE_ACTE_LABO_GET_FAILED:
            return {
                ...state,
                isLoadingGet: false,
                isSuccessGet: false,
                error: action.payload,
                parametreacteGet: {},
                isError: true,
            }

        
        //load post parametreacte in dpi
        case parametreActeLaboTypes.PARAMETRE_ACTE_LABO_POST_LOADING:
            return {
                ...state,
                isLoadingPost: true,
                isSuccessPost: false,
                parametreacteGet: {},
                error: "",
                isError: false,
            }
        case parametreActeLaboTypes.PARAMETRE_ACTE_LABO_POST_SUCCESS:
            return {
                ...state,
                isLoadingPost: false,
                isSuccessPost: true,
                parametreacteGet: action.payload,
                error: "",
                isError: false,
            }
        case parametreActeLaboTypes.PARAMETRE_ACTE_LABO_POST_FAILED:
            return {
                ...state,
                isLoadingPost: false,
                isSuccessPost: false,
                error: action.payload,
                isError: true,
            }

        
        //load put parametreacte in dpi
        case parametreActeLaboTypes.PARAMETRE_ACTE_LABO_PUT_LOADING:
            return {
                ...state,
                isLoadingUpdate: true,
                isSuccessUpdate: false,
                parametreacteUpdate: {},
                error: "",
                isError: false
            }
        case parametreActeLaboTypes.PARAMETRE_ACTE_LABO_PUT_SUCCESS:
            return {
                ...state,
                isLoadingUpdate: false,
                isSuccessUpdate: true,
                parametreacteUpdate: action.payload,
                error: "",
                isError: false
            }
        case parametreActeLaboTypes.PARAMETRE_ACTE_LABO_PUT_FAILED:
            return {
                ...state,
                isLoadingUpdate: false,
                isSuccessUpdate: false,
                error: action.payload,
                isError: true
            }

        
        
        case parametreActeLaboTypes.PARAMETRE_ACTE_LABO_CLEAR_STATE:
            return {
                ...state,
                isLoadingGet: false,
                isLoadingPost: false,
                isLoadingUpdate: false,

                parametreacteGet: {},

                isSuccessGet: false,
                isSuccessPost: false,
                isSuccessUpdate: false,

                error: "",
                isError: false,
            }


        case AUTH_ERROR:
        case LOGOUT_SUCCESS:
        case TOKEN_REFRESH_FAIL:
            localStorage.removeItem('token_access')
            localStorage.removeItem('token_refresh')
            return {
                ...state,
                isLoading: false,

                isLoadingGet: false,
                isLoadingPost: false,
                isLoadingUpdate: false,
                isLoadingDelete: false,

                parametreactes: [],
                parametreacteGet: {},
                parametreactePost: {},
                parametreacteUpdate: {},

                isSuccessGet: false,
                isSuccessPost: false,
                isSuccessUpdate: false,

                error: "",
                isError: false
            }

        default:
            return state
    }
}


