
export const litparametreTypes = {
    LIT_LIST_REQUEST: "LIT_LIST_REQUEST",
    LIT_LIST_SUCCESS: "LIT_LIST_SUCCESS",
    LIT_LIST_FAIL: "LIT_LIST_FAIL",

    LIT_LIST_DISPONIBLE_REQUEST: "LIT_LIST_DISPONIBLE_REQUEST",
    LIT_LIST_DISPONIBLE_SUCCESS: "LIT_LIST_DISPONIBLE_SUCCESS",
    LIT_LIST_DISPONIBLE_FAIL: "LIT_LIST_DISPONIBLE_FAIL",

    LIT_POST_SUCCESS: 'LIT_POST_SUCCESS',
    LIT_POST_FAILED: 'LIT_POST_FAILED',
    LIT_POST_LOADING: 'LIT_POST_LOADING',
    LIT_DETAIL_REQUEST: 'LIT_DETAIL_REQUEST',
    LIT_DETAIL_SUCCESS: 'LIT_DETAIL_SUCCESS',
    LIT_DETAIL_FAIL: 'LIT_DETAIL_FAIL',
    LIT_UPDATE_REQUEST: 'LIT_UPDATE_REQUEST',
    LIT_UPDATE_SUCCESS: 'LIT_UPDATE_SUCCESS',
    LIT_UPDATE_FAIL: 'LIT_UPDATE_FAIL',
    LIT_DELETE_REQUEST: 'LIT_DELETE_REQUEST',
    LIT_DELETE_SUCCESS: 'LIT_DELETE_SUCCESS',
    LIT_DELETE_FAIL: 'LIT_DELETE_FAIL',
};
