import { examenExterneTypes } from "../types/ExamenExterneTypes";

const initialState = {
    addNatureLoader: false,
    addNatureSuccess: false,
    addNatureError: false,
    lastNatureExamen: {},

    addActeLoader: false,
    addActeSuccess: false,
    addActeError: false,
    lastActeExamen: [],
}

const ExamenExterneReducer = (state=initialState, action) => {
    switch (action.type) {
        case examenExterneTypes.POST_REQUEST:
            return {
                ...state,
                addNatureLoader: true,
                addNatureError: false,
                error: false,
                lastNatureExamen: {},
            }
        case examenExterneTypes.POST_SUCCESS:
            return {
                ...state,
                addNatureLoader: false,
                addNatureSuccess: true,
                addNatureError: false,
                lastNatureExamen: action.payload,
            }
        case examenExterneTypes.POST_FAILED:
            return {
                ...state,
                addNatureLoader: false,
                addNatureSuccess: false,
                addNatureError: action.payload,
                lastNatureExamen: {},
            }
        case examenExterneTypes.RESET_NATURE_EXAMEN:
            return {
                ...state,
                addNatureLoader: false,
                addNatureSuccess: false,
                addNatureError: false,
            }
        case examenExterneTypes.POST_ACTE_REQUEST:
            return {
                ...state,
                addActeLoader: true,
                addActeSuccess: false,
                addActeError: false,
                lastActeExamen: [],
            }
        case examenExterneTypes.POST_ACTE_SUCCESS:
            return {
                ...state,
                addActeLoader: false,
                addActeSuccess: true,
                addActeError: false,
                lastActeExamen: action.payload,
            }
        case examenExterneTypes.POST_ACTE_FAILED:
            return {
                ...state,
                addActeLoader: false,
                addActeSuccess: false,
                addActeError: action.payload,
                lastActeExamen: [],
            }
        case examenExterneTypes.RESET_ACTE_EXAMEN:
            return {
                ...state,
                addActeLoader: false,
                addActeSuccess: false,
                addActeError: false,
            }
    
        default:
            return state;
    }
}

export default ExamenExterneReducer;