import * as professionTypes from "../../types/professionTypes";

const initialState = {
  data: [],
  isLoading: false,
  error: null,
};

const executeGetDataSuccess = (state, action) => {
  return {
    ...state,
    data: action.data,
    isLoading: false,
    error: null,
  };
};
const executeGetDataFailed = (state, action) => {
  return {
    ...state,
    data: action.data,
    isLoading: false,
    error: action,
  };
};
const executeGetDataLoading = (state, action) => {
  return {
    ...state,
    isLoading: true,
    error: null,
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case professionTypes.PROFESSION_GET_DATA_SUCCESS:
      return executeGetDataSuccess(state, action);

    case professionTypes.PROFESSION_GET_DATA_LOADING:
      return executeGetDataLoading(state, action);

    case professionTypes.PROFESSION_GET_DATA_FAILED:
      return executeGetDataFailed(state, action);
    default:
      return state;
  }
};

export default reducer;
