import React, { lazy } from "react"
import * as MyPrivateRouters from "../routes/PrivateRouter";
import { Switch } from 'react-router-dom';

const LayoutCaisseFichesPaiementListe = lazy(() =>
    import(
      "../../components/caisse/FichesPaiement/Liste/LayoutCaisseFichesPaiementListe"
    )
);
const LayoutCaisseFichePaiementDetails = lazy(() =>
    import(
        "../../components/caisse/FichesPaiement/Voir/LayoutCaisseFichePaiementDetails"
    )
);
  
const LayoutCaisseClotureValideesListe = lazy(() => import("../../components/caisse/clotures/ListeValidee/LayoutCaisseClotureValideesListe"));

const LayoutCaisseClotureListe = lazy(() => import("../../components/caisse/clotures/ListeAttente/LayoutCaisseClotureListe"))

const LayoutCaisseClotureForm = lazy(() => import("../../components/caisse/clotures/Form/LayoutCaisseClotureForm"));
  
  
const LayoutCaisseRecuPaiementCreate = lazy(() =>
    import(
        "../../components/caisse/RecusPaiements/Form/LayoutCaisseRecuPaiementCreate"
    )
);
const LayoutFichesPaiementRecuListe = lazy(() =>
    import(
        "../../components/caisse/RecusPaiements/Liste/LayoutFichesPaiementRecuListe"
    )
);
const LayoutFichePaiementRecuDetails = lazy(() =>
    import(
        "../../components/caisse/RecusPaiements/Voir/LayoutFichePaiementRecuDetails"
    )
);

const LayoutCaisseCaisseOpenListe = lazy(() => import("../../components/caisse/caisses/LayoutCaisseCaisseOpenListe"));

const LayoutCaisseBilanPaiement = lazy(() =>
    import("../../components/caisse/bilanPaiementCaisse/LayoutCaisseBilanPaiement")
);

const LayoutCaisseBilanPaiementAll = lazy(() => import("../../components/caisse/bilanPaiementCaisseAll/LayoutCaisseBilanPaiementAll"))

const LayoutClotureBilan = lazy(() =>
    import("../../components/caisse/clotures/ClotureBilan/LayoutClotureBilan")
);


const RedevancePercuesRapportCaisse = lazy(() =>
    import("../../components/caisse/rapports/RedevancePercuesRapportCaisse")
);


const RapportCaissesPeriodesExerciceCaisse = lazy(() =>
    import("../../components/caisse/rapports/RapportCaissesPeriodesExerciceCaisse")
);

const LayoutCaisseVersement = lazy(() => import("../../components/caisse/versement/LayoutCaisseVersement"))

export default function CaisseRouter() {
  return (
    <Switch>
        <MyPrivateRouters.PrivateRouterCaisse
        path="/caisse"
        component={LayoutCaisseFichesPaiementListe}
        />

        <MyPrivateRouters.PrivateRouterCaisse
        path="/caisse-liste"
        component={LayoutCaisseFichesPaiementListe}
        />

        <MyPrivateRouters.PrivateRouterCaisse
        path="/caisse-bilan"
        component={LayoutCaisseBilanPaiement}
        />

        <MyPrivateRouters.PrivateRouterCaisse
        path="/caisse-mes-bilan"
        component={LayoutCaisseBilanPaiementAll}
        />

        <MyPrivateRouters.PrivateRouterCaisse
        path="/caisse-cloture-bilan"
        component={LayoutClotureBilan}
        />

        <MyPrivateRouters.PrivateRouterCaisse
        path="/caisse-caisse-active"
        component={LayoutCaisseCaisseOpenListe}
        />

        <MyPrivateRouters.PrivateRouterCaisse
        path="/caisse-cloturer-la-journee"
        component={LayoutCaisseClotureForm}
        />

        <MyPrivateRouters.PrivateRouterCaisse
        path="/caisse-historique-cloture"
        component={LayoutCaisseClotureListe}
        />

        <MyPrivateRouters.PrivateRouterCaisse
        path="/caisse-historique-clotures-validees"
        component={LayoutCaisseClotureValideesListe}
        />


        <MyPrivateRouters.PrivateRouterCaisse
        path="/caisse-paiement/:id"
        component={LayoutCaisseRecuPaiementCreate}
        />

        <MyPrivateRouters.PrivateRouterCaisse
        path="/caisse-paiement-consulter/:id"
        component={LayoutCaisseFichePaiementDetails}
        />

        <MyPrivateRouters.PrivateRouterCaisse
        path="/caisse-patient-recu"
        component={LayoutFichesPaiementRecuListe}
        />

        <MyPrivateRouters.PrivateRouterCaisse
        path="/caisse-patient-recu-consulter/:id"
        component={LayoutFichePaiementRecuDetails}
        />

        <MyPrivateRouters.PrivateRouterCaisse
        path="/caisse-rapports-redevances-percues"
        component={RedevancePercuesRapportCaisse}
        />

        <MyPrivateRouters.PrivateRouterCaisse
        path="/caisse-rapports-periode-exercice"
        component={RapportCaissesPeriodesExerciceCaisse}
        />

        <MyPrivateRouters.PrivateRouterCaisse
        path="/caisse-versement"
        component={LayoutCaisseVersement}
        />
        
    </Switch>
  )
}
