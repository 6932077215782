import React, { lazy } from "react"
import * as MyPrivateRouters from "../routes/PrivateRouter";
import { Switch } from 'react-router-dom';


const LayoutRhumatologiePatientAConsulter = lazy(() =>
        import("../../components/rhumatologie/patientAConsulter/LayoutRhumatologiePatientAConsulter")
);
const LayoutRhumatologieConsultationListe = lazy(() =>
    import("../../components/rhumatologie/consultation/LayoutRhumatologieConsultationListe")
);
const LayoutRhumatologieDemandeHospitalisationListe = lazy(() =>
import(
    "../../components/rhumatologie/demandeHospitalisation/LayoutRhumatologieDemandeHospitalisationListe"
)
);
const RhumatologieObservation = lazy(() =>
    import("../../components/rhumatologie/observation/RhumatologieObservation")
);
const RhumatologieObservationTerminees = lazy(() =>
    import("../../components/rhumatologie/observationTerminees/RhumatologieObservationTerminees")
);

const LayoutRhumatologieCreateConsultation = lazy(() =>
    import("../../components/rhumatologie/patientAConsulterCreate/LayoutRhumatologieCreateConsultation")
);

const LayoutRhumatologieConsultationView = lazy(() =>
    import("../../components/rhumatologie/patientAConsulterDetail/LayoutRhumatologieViewConsultation")
);

const LayoutCreateConsultationSortieRhumatologie = lazy(() =>
import(
    "../../components/rhumatologie/patientAConsulterIssuSortie/LayoutCreateConsultationSortieRhumatologie"
)
);

const LayoutCreateConsultationSortieDemandeHospitalisationRhumatologie = lazy(() =>
import(
    "../../components/rhumatologie/patientAConsulterIssuSortie/DemandeHospitalisation/LayoutCreateConsultationSortieDemandeHospitalisationRhumatologie"
)
);
const LayoutCreateConsultationSortieMiseEnObservationRhumatologie = lazy(() =>
import(
    "../../components/rhumatologie/patientAConsulterIssuSortie/MiseEnObservation/LayoutCreateConsultationSortieMiseEnObservationRhumatologie"
)
);

const LayoutCreateConsultationRefereInterneRhumatologie = lazy(() =>
import(
    "../../components/rhumatologie/patientAConsulterIssuSortie/RefereInterne/LayoutCreateConsultationRefereInterneRhumatologie"
)
);
const LayoutCreateConsultationRefereExterneRhumatologie = lazy(() =>
import(
    "../../components/rhumatologie/patientAConsulterIssuSortie/RefereExterne/LayoutCreateConsultationRefereExterneRhumatologie"
)
);

const LayoutRhumatologieOrdonnanceDetails = lazy(() =>
    import("../../components/rhumatologie/ordonnance/detail/LayoutRhumatologieOrdonnanceDetails")
);
const LayoutRhumatologieOrdonnanceListe = lazy(() =>
    import("../../components/rhumatologie/ordonnance/liste/LayoutRhumatologieOrdonnanceListe")
);

const LayoutRhumatologieHospitalisationListe = lazy(() =>
    import("../../components/rhumatologie/hospitalisation/LayoutRhumatologieHospitalisationListe")
);

const LayoutRhumatologieHospitalisationConsultationCreate = lazy(() =>
import(
    "../../components/rhumatologie/hospitalisation/consultation/LayoutRhumatologieHospitalisationConsultationCreate"
)
);

const LayoutRhumatologieHospitalisationConsultationSortie = lazy(() =>
import(
    "../../components/rhumatologie/hospitalisation/issueSortie/LayoutRhumatologieHospitalisationConsultationSortie"
)
);

const LayoutRhumatologieFeuilleDeSoins = lazy(() =>
    import("../../components/rhumatologie/feuilleDeSoins/LayoutRhumatologieFeuilleDeSoins")
);

const RhumatologieRefExterne = lazy(() =>
    import("../../components/rhumatologie/refExterne/RhumatologieRefExterne")
);
const RhumatologieRefInterne = lazy(() =>
    import("../../components/rhumatologie/refInterne/RhumatologieRefInterne")
);
const LayoutRhumatologieDecesConsultationSortie = lazy(() =>
import(
    "../../components/rhumatologie/patientAConsulterIssuSortie/IssueDeDeces/LayoutRhumatologieDecesConsultationSortie"
)
);

const DemandeHospiObservationRhumatologie = lazy(() =>
import(
    "../../components/rhumatologie/patientAConsulterIssuSortie/MiseEnObservation/DemandeHospitalisation/LayoutCreateConsultationSortieDemandeHospitalisationRhumatologie"
)
);
const RefExterneObservationRhumatologie = lazy(() =>
import(
    "../../components/rhumatologie/patientAConsulterIssuSortie/MiseEnObservation/RefereExterne/LayoutCreateConsultationRefereExterneRhumatologie"
)
);
const RefInterneObservationRhumatologie = lazy(() =>
import(
    "../../components/rhumatologie/patientAConsulterIssuSortie/MiseEnObservation/RefereInterne/LayoutCreateConsultationRefereInterneRhumatologie"
)
);

const RhumatologieExamensDetail = lazy(() =>  import("../../components/rhumatologie/Examens/detail"));
const RhumatologieExamensList = lazy(() =>  import("../../components/rhumatologie/Examens/list"));
const LayoutRhumatologieDecesListe = lazy(() =>
    import("../../components/rhumatologie/Deces/Liste/LayoutRhumatologieDecesListe")
);
const LayoutRhumatologieDecesCertificat = lazy(() =>
    import("../../components/rhumatologie/Deces/Certificat/LayoutRhumatologieDecesCertificat")
);

const AgendaRhumatologie = lazy(() =>
    import("../../components/rhumatologie/rendezVous/agendaDetail/AgendaRhumatologie")
);
const LayoutRhumatologieCreateConsultationRendezVous = lazy(() =>
import(
    "../../components/rhumatologie/patientAConsulterIssuSortie/RendezVous/LayoutRhumatologieCreateConsultationRendezVous"
)
);
const LayoutRhumatologieRdvListe = lazy(() =>
    import("../../components/rhumatologie/rendezVous/Liste/LayoutRhumatologieRdvListe")
);

const LayoutRhumatologieRdvLastConsultation = lazy(() =>
    import("../../components/rhumatologie/rendezVous/LastConsultation/LayoutRhumatologieRdvLastConsultation")
);

const LayoutRhumatologieRdvNewConsultation = lazy(() =>
    import("../../components/rhumatologie/rendezVous/NewConsultation/LayoutRhumatologieRdvNewConsultation")
);

const LayoutVoirRhumatologieResultatsExamens = lazy(() =>
import(
    "../../components/rhumatologie/Examens/resultatsExamensVoir/LayoutRhumatologieRadiologieVoirResultatsExamens"
)
);

const RapportRhumatologie = lazy(() =>  import("../../components/rhumatologie/rapports/RapportRhumatologie"));

const LayoutRhumatologieControleMajor = lazy(() => 
    import("../../components/rhumatologie/controleMajor/LayoutRhumatologieControleMajor")
)
  

export default function RhumatologieRouter() {
  return (
    <Switch>
        <MyPrivateRouters.PrivateRouterRhumatologie
        path="/rhumatologie"
        component={LayoutRhumatologiePatientAConsulter}
        />

        <MyPrivateRouters.PrivateRouterRhumatologie
        path="/rhumatologie-demande-hospitalisation"
        component={LayoutRhumatologieDemandeHospitalisationListe}
        />

        <MyPrivateRouters.PrivateRouterRhumatologie
        path="/rhumatologie-observation-encours"
        component={RhumatologieObservation}
        />

        <MyPrivateRouters.PrivateRouterRhumatologie
        path="/rhumatologie-observation-terminee"
        component={RhumatologieObservationTerminees}
        />

        <MyPrivateRouters.PrivateRouterRhumatologie
        path="/rhumatologie-consultation"
        component={LayoutRhumatologieConsultationListe}
        />

        <MyPrivateRouters.PrivateRouterRhumatologie
        path="/rhumatologie-feuille-de-soins/:id"
        component={LayoutRhumatologieFeuilleDeSoins}
        />

        <MyPrivateRouters.PrivateRouterRhumatologie
        path="/rhumatologie-ordonnance"
        component={LayoutRhumatologieOrdonnanceListe}
        />

        <MyPrivateRouters.PrivateRouterRhumatologie
        path="/rhumatologie-ordonnance-voir/:id"
        component={LayoutRhumatologieOrdonnanceDetails}
        />

        <MyPrivateRouters.PrivateRouterRhumatologie
        path="/rhumatologie-rdv"
        component={LayoutRhumatologieRdvListe}
        />

        <MyPrivateRouters.PrivateRouterRhumatologie
        path="/rhumatologie-rdv-last-consultation/:id"
        component={LayoutRhumatologieRdvLastConsultation}
        />

        <MyPrivateRouters.PrivateRouterRhumatologie
        path="/rhumatologie-rdv-new-consultation/:id"
        component={LayoutRhumatologieRdvNewConsultation}
        />

        <MyPrivateRouters.PrivateRouterRhumatologie
        path="/rhumatologie-rdv-agenda/"
        component={AgendaRhumatologie}
        />

        <MyPrivateRouters.PrivateRouterRhumatologie
        path="/rhumatologie-consultation-create/:id"
        component={LayoutRhumatologieCreateConsultation}
        />

        <MyPrivateRouters.PrivateRouterRhumatologie
        path="/rhumatologie-consultation-voir-detail/:id"
        component={LayoutRhumatologieConsultationView}
        />

        <MyPrivateRouters.PrivateRouterRhumatologie
        path="/rhumatologie-hospitalisation"
        component={LayoutRhumatologieHospitalisationListe}
        />

        <MyPrivateRouters.PrivateRouterRhumatologie
        path="/rhumatologie-hospitalisation-consultation/:id"
        component={LayoutRhumatologieHospitalisationConsultationCreate}
        />

        <MyPrivateRouters.PrivateRouterRhumatologie
        path="/rhumatologie-hospitalisation-sortie/:id"
        component={LayoutRhumatologieHospitalisationConsultationSortie}
        />

        <MyPrivateRouters.PrivateRouterRhumatologie
        path="/rhumatologie-consultation-sortie/:id"
        component={LayoutCreateConsultationSortieRhumatologie}
        />

        <MyPrivateRouters.PrivateRouterRhumatologie
        path="/rhumatologie-consultation-sortie-demande-hospitalisation/:id"
        component={
            LayoutCreateConsultationSortieDemandeHospitalisationRhumatologie
        }
        />

        <MyPrivateRouters.PrivateRouterRhumatologie
        path="/rhumatologie-consultation-sortie-mise-en-observation/:id"
        component={LayoutCreateConsultationSortieMiseEnObservationRhumatologie}
        />

        <MyPrivateRouters.PrivateRouterRhumatologie
        path="/rhumatologie-consultation-sortie-refere-interne/:id"
        component={LayoutCreateConsultationRefereInterneRhumatologie}
        />

        <MyPrivateRouters.PrivateRouterRhumatologie
        path="/rhumatologie-consultation-sortie-refere-externe/:id"
        component={LayoutCreateConsultationRefereExterneRhumatologie}
        />

        <MyPrivateRouters.PrivateRouterRhumatologie
        path="/rhumatologie-consultation-sortie-rdv/:id"
        component={LayoutRhumatologieCreateConsultationRendezVous}
        />

        <MyPrivateRouters.PrivateRouterRhumatologie
        path="/rhumatologie-referencements-externe"
        component={RhumatologieRefExterne}
        />

        <MyPrivateRouters.PrivateRouterRhumatologie
        path="/rhumatologie-referencements-interne"
        component={RhumatologieRefInterne}
        />

        <MyPrivateRouters.PrivateRouterRhumatologie
        path="/rhumatologie-consultation-sortie-deces/:id"
        component={LayoutRhumatologieDecesConsultationSortie}
        />

        <MyPrivateRouters.PrivateRouterRhumatologie
        path="/rhumatologie-observation-consultation/:id"
        component={LayoutRhumatologieCreateConsultation}
        />

        <MyPrivateRouters.PrivateRouterRhumatologie
        path="/rhumatologie-exit-observation/:id"
        component={LayoutCreateConsultationSortieRhumatologie}
        />

        <MyPrivateRouters.PrivateRouterRhumatologie
        path="/rhumatologie-observation-exit-demande-hospitalisation/:id"
        component={DemandeHospiObservationRhumatologie}
        />

        <MyPrivateRouters.PrivateRouterRhumatologie
        path="/rhumatologie-observation-exit-reference-externe/:id"
        component={RefExterneObservationRhumatologie}
        />

        <MyPrivateRouters.PrivateRouterRhumatologie
        path="/rhumatologie-observation-exit-reference-interne/:id"
        component={RefInterneObservationRhumatologie}
        />

        <MyPrivateRouters.PrivateRouterRhumatologie
        path="/rhumatologie-observation-exit-rdv/:id"
        component={LayoutRhumatologieCreateConsultationRendezVous}
        />

        <MyPrivateRouters.PrivateRouterRhumatologie
        path="/rhumatologie-observation-exit-deces/:id"
        component={LayoutRhumatologieDecesConsultationSortie}
        />

        <MyPrivateRouters.PrivateRouterRhumatologie
        path="/rhumatologie-examens-detail/:id/"
        component={RhumatologieExamensDetail}
        />

        <MyPrivateRouters.PrivateRouterRhumatologie
        path="/rhumatologie-examens"
        component={RhumatologieExamensList}
        />

        <MyPrivateRouters.PrivateRouterRhumatologie
        path="/rhumatologie-patients-decedes"
        component={LayoutRhumatologieDecesListe}
        />

        <MyPrivateRouters.PrivateRouterRhumatologie
        path="/rhumatologie-patients-decedes-certificat/:id"
        component={LayoutRhumatologieDecesCertificat}
        />

        <MyPrivateRouters.PrivateRouterRhumatologie
        path="/rhumatologie-examens-resultat-voir/:id"
        component={LayoutVoirRhumatologieResultatsExamens}
        />

        <MyPrivateRouters.PrivateRouterRhumatologie
        path="/rhumatologie-rapports"
        component={RapportRhumatologie}
        />

        <MyPrivateRouters.PrivateRouterRhumatologie
        path="/rhumatologie-controle-major"
        component={LayoutRhumatologieControleMajor}
        />
    </Switch>
  )
}
