import { refExterneTypes } from "../types/refExterneTypes";

const initialState = {
    isLoading: false,
    isLoadingRefresh: false,
    refExternes: [],
    patientAllRefExternes: [],
    error: "",
}

const refExterneReducer = (state = initialState, action) => {
    switch (action.type) {
        case refExterneTypes.REF_EXTERNE_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: "",
            }
        case refExterneTypes.REF_EXTERNE_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                refExternes: action.payload,
                error: "",
            }
        case refExterneTypes.REF_EXTERNE_GET_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            }
        
        case refExterneTypes.REF_EXTERNE_GET_REFRESH_REQUEST:
            return {
                ...state,
                isLoadingRefresh: true,
                error: "",
            }
        case refExterneTypes.REF_EXTERNE_GET_REFRESH_SUCCESS:
            return {
                ...state,
                isLoadingRefresh: false,
                refExternes: action.payload,
                error: "",
            }
        case refExterneTypes.REF_EXTERNE_GET_REFRESH_FAIL:
            return {
                ...state,
                isLoadingRefresh: false,
                error: action.payload,
            }
        case refExterneTypes.PATIENT_ALL_REF_EXTERNE_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: "",
            }
        case refExterneTypes.PATIENT_ALL_REF_EXTERNE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                patientAllRefExternes: action.payload,
                error: "",
            }
        case refExterneTypes.PATIENT_ALL_REF_EXTERNE_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            }
    
        default:
            return state;
    }
}

export default refExterneReducer