export const DISTRICT_SANITAIRE_GET_DATA_LOADING = 'DISTRICT_SANITAIRE_GET_DATA_LOADING'

export const DISTRICT_SANITAIRE_GET_DATA_SUCCESS = 'DISTRICT_SANITAIRE_GET_DATA_SUCCESS'

export const DISTRICT_SANITAIRE_GET_DATA_FAILED = 'DISTRICT_SANITAIRE_GET_DATA_FAILED'

export const DISTRICT_SANITAIRE_GET_DATA_FAILED_403 = "DISTRICT_SANITAIRE_GET_DATA_FAILED_403"

export const DISTRICT_SANITAIRE_GET_DATA_FAILED_500 = "DISTRICT_SANITAIRE_GET_DATA_FAILED_500"


export const DISTRICT_SANITAIRE_POST_DATA_LOADING = 'DISTRICT_SANITAIRE_POST_DATA_LOADING'

export const DISTRICT_SANITAIRE_POST_DATA_SUCCESS = 'DISTRICT_SANITAIRE_POST_DATA_SUCCESS'

export const DISTRICT_SANITAIRE_POST_DATA_FAILED = 'DISTRICT_SANITAIRE_POST_DATA_FAILED'

export const DISTRICT_SANITAIRE_POST_DATA_FAILED_403 = "DISTRICT_SANITAIRE_POST_DATA_FAILED_403"

export const DISTRICT_SANITAIRE_POST_DATA_FAILED_500 = "DISTRICT_SANITAIRE_POST_DATA_FAILED_500"


export const DISTRICT_SANITAIRE_PUT_DATA_LOADING = 'DISTRICT_SANITAIRE_PUT_DATA_LOADING'

export const DISTRICT_SANITAIRE_PUT_DATA_SUCCESS = 'DISTRICT_SANITAIRE_PUT_DATA_SUCCESS'

export const DISTRICT_SANITAIRE_PUT_DATA_FAILED = 'DISTRICT_SANITAIRE_PUT_DATA_FAILED'

export const DISTRICT_SANITAIRE_PUT_DATA_FAILED_403 = "DISTRICT_SANITAIRE_PUT_DATA_FAILED_403"

export const DISTRICT_SANITAIRE_PUT_DATA_FAILED_500 = "DISTRICT_SANITAIRE_PUT_DATA_FAILED_500"


export const DISTRICT_SANITAIRE_DELETE_DATA_LOADING = 'DISTRICT_SANITAIRE_DELETE_DATA_LOADING'

export const DISTRICT_SANITAIRE_DELETE_DATA_SUCCESS = 'DISTRICT_SANITAIRE_DELETE_DATA_SUCCESS'

export const DISTRICT_SANITAIRE_DELETE_DATA_FAILED = 'DISTRICT_SANITAIRE_DELETE_DATA_FAILED'

export const DISTRICT_SANITAIRE_DELETE_DATA_FAILED_403 = "DISTRICT_SANITAIRE_DELETE_DATA_FAILED_403"

export const DISTRICT_SANITAIRE_DELETE_DATA_FAILED_500 = "DISTRICT_SANITAIRE_DELETE_DATA_FAILED_500"


export const DISTRICT_SANITAIRE_GETS_DATA_LOADING = 'DISTRICT_SANITAIRE_GETS_DATA_LOADING'

export const DISTRICT_SANITAIRE_GETS_DATA_SUCCESS = 'DISTRICT_SANITAIRE_GETS_DATA_SUCCESS'

export const DISTRICT_SANITAIRE_GETS_DATA_FAILED = 'DISTRICT_SANITAIRE_GETS_DATA_FAILED'


export const DISTRICT_SANITAIRE_GETS_REFRESH_DATA_LOADING = 'DISTRICT_SANITAIRE_GETS_REFRESH_DATA_LOADING'

export const DISTRICT_SANITAIRE_GETS_REFRESH_DATA_SUCCESS = 'DISTRICT_SANITAIRE_GETS_REFRESH_DATA_SUCCESS'

export const DISTRICT_SANITAIRE_GETS_REFRESH_DATA_FAILED = 'DISTRICT_SANITAIRE_GETS_REFRESH_DATA_FAILED'


export const DISTRICT_SANITAIRE_CLEAR_STATE = "DISTRICT_SANITAIRE_CLEAR_STATE"