export const PATIENT_A_CONSULTER_MG_GET_LOADING =  "PATIENT_A_CONSULTER_MG_GET_LOADING"

export const PATIENT_A_CONSULTER_MG_GET_SUCCESS =  "PATIENT_A_CONSULTER_MG_GET_SUCCESS"

export const PATIENT_A_CONSULTER_MG_GET_FAILED =  "PATIENT_A_CONSULTER_MG_GET_FAILED"


export const MALADIES_MG_GET_LOADING = 'MALADIES_MG_GET_LOADING'

export const MALADIES_MG_GET_SUCCESS = 'MALADIES_MG_GET_SUCCESS'

export const MALADIES_MG_GET_FAILED = 'MALADIES_MG_GET_FAILED'


export const ACTES_CHIRUGICAUX_MG_GET_LOADING = 'ACTES_CHIRUGICAUX_MG_GET_LOADING'

export const ACTES_CHIRUGICAUX_MG_GET_SUCCESS = 'ACTES_CHIRUGICAUX_MG_GET_SUCCESS'

export const ACTES_CHIRUGICAUX_MG_GET_FAILED = 'ACTES_CHIRUGICAUX_MG_GET_FAILED'


export const CONSULTATION_MG_GET_LOADING = 'CONSULTATION_MG_GET_LOADING'

export const CONSULTATION_MG_GET_SUCCESS = 'CONSULTATION_MG_GET_SUCCESS'

export const CONSULTATION_MG_GET_PAS_TROUVER = "CONSULTATION_MG_GET_PAS_TROUVER"

export const CONSULTATION_MG_GET_FAILED = 'CONSULTATION_MG_GET_FAILED'

export const CONSULTATION_MG_GET_FAILED_403 = 'CONSULTATION_MG_GET_FAILED_403'

export const CONSULTATION_MG_GET_FAILED_500 = 'CONSULTATION_MG_GET_FAILED_500'


export const CONSULTATION_MG_POST_LOADING = 'CONSULTATION_MG_POST_LOADING'

export const CONSULTATION_MG_POST_SUCCESS = 'CONSULTATION_MG_POST_SUCCESS'

export const CONSULTATION_MG_POST_FAILED = 'CONSULTATION_MG_POST_FAILED'


export const CONSULTATION_MG_POST_TERMINER_LOADING = 'CONSULTATION_MG_POST_TERMINER_LOADING'

export const CONSULTATION_MG_POST_TERMINER_SUCCESS = 'CONSULTATION_MG_POST_TERMINER_SUCCESS'

export const CONSULTATION_MG_POST_TERMINER_FAILED = 'CONSULTATION_MG_POST_TERMINER_FAILED'


export const ISSU_DEMANDE_HOSPITALISATION_MG_POST_LOADING = 'ISSU_DEMANDE_HOSPITALISATION_MG_POST_LOADING'

export const ISSU_DEMANDE_HOSPITALISATION_MG_POST_SUCCESS = 'ISSU_DEMANDE_HOSPITALISATION_MG_POST_SUCCESS'

export const ISSU_DEMANDE_HOSPITALISATION_MG_POST_FAILED = 'ISSU_DEMANDE_HOSPITALISATION_MG_POST_FAILED'


export const FETCH_MOTIF_HOSPITALISATION_REQUEST = "FETCH_MOTIF_HOSPITALISATION_REQUEST"

export const FETCH_MOTIF_HOSPITALISATION_SUCCESS = "FETCH_MOTIF_HOSPITALISATION_SUCCESS"

export const FETCH_MOTIF_HOSPITALISATION_FAILED = "FETCH_MOTIF_HOSPITALISATION_FAILED"


export const ISSU_ACTES_INFIRMIERS_MG_POST_LOADING = 'ISSU_ACTES_INFIRMIERS_MG_POST_LOADING'

export const ISSU_ACTES_INFIRMIERS_MG_POST_SUCCESS = 'ISSU_ACTES_INFIRMIERS_MG_POST_SUCCESS'

export const ISSU_ACTES_INFIRMIERS_MG_POST_FAILED = 'ISSU_ACTES_INFIRMIERS_MG_POST_FAILED'


export const REFERE_INTERNE_MG_POST_LOADING = 'REFERE_INTERNE_MG_POST_LOADING'

export const REFERE_INTERNE_MG_POST_SUCCESS = 'REFERE_INTERNE_MG_POST_SUCCESS'

export const REFERE_INTERNE_MG_POST_FAILED = 'REFERE_INTERNE_MG_POST_FAILED'


export const REFERE_EXTERNE_MG_POST_LOADING = 'REFERE_EXTERNE_MG_POST_LOADING'

export const REFERE_EXTERNE_MG_POST_SUCCESS = 'REFERE_EXTERNE_MG_POST_SUCCESS'

export const REFERE_EXTERNE_MG_POST_FAILED = 'REFERE_EXTERNE_MG_POST_FAILED'


export const ISSU_MISE_EN_OBSERVATION_MG_POST_LOADING = 'ISSU_MISE_EN_OBSERVATION_MG_POST_LOADING'

export const ISSU_MISE_EN_OBSERVATION_MG_POST_SUCCESS = 'ISSU_MISE_EN_OBSERVATION_MG_POST_SUCCESS'

export const ISSU_MISE_EN_OBSERVATION_MG_POST_FAILED = 'ISSU_MISE_EN_OBSERVATION_MG_POST_FAILED'


export const ISSU_PRESCRIPTIONS_MG_POST_LOADING = 'ISSU_PRESCRIPTIONS_MG_POST_LOADING'

export const ISSU_PRESCRIPTIONS_MG_POST_SUCCESS = 'ISSU_PRESCRIPTIONS_MG_POST_SUCCESS'

export const ISSU_PRESCRIPTIONS_MG_POST_FAILED = 'ISSU_PRESCRIPTIONS_MG_POST_FAILED'


export const ISSU_SORTIE_CONSULTATION_MG_POST_LOADING = 'ISSU_SORTIE_CONSULTATION_MG_POST_LOADING'

export const ISSU_SORTIE_CONSULTATION_MG_POST_SUCCESS = 'ISSU_SORTIE_CONSULTATION_MG_POST_SUCCESS'

export const ISSU_SORTIE_CONSULTATION_MG_POST_FAILED = 'ISSU_SORTIE_CONSULTATION_MG_POST_FAILED'


export const TYPES_EXAMENS_MG_GET_LOADING = 'TYPES_EXAMENS_MG_GET_LOADING'

export const TYPES_EXAMENS_MG_GET_SUCCESS = 'TYPES_EXAMENS_MG_GET_SUCCESS'

export const TYPES_EXAMENS_MG_GET_FAILED = 'TYPES_EXAMENS_MG_GET_FAILED'


export const ORDONNANCES_MG_GET_LOADING = 'ORDONNANCES_MG_GET_LOADING'

export const ORDONNANCES_MG_GET_SUCCESS = 'ORDONNANCES_MG_GET_SUCCESS'

export const ORDONNANCES_MG_GET_FAILED = 'ORDONNANCES_MG_GET_FAILED'


export const ORDONNANCES_MG_GET_REFRESH_LOADING = 'ORDONNANCES_MG_GET_REFRESH_LOADING'

export const ORDONNANCES_MG_GET_REFRESH_SUCCESS = 'ORDONNANCES_MG_GET_REFRESH_SUCCESS'

export const ORDONNANCES_MG_GET_REFRESH_FAILED = 'ORDONNANCES_MG_GET_REFRESH_FAILED'


export const ORDONNANCE_MG_GET_LOADING = 'ORDONNANCE_MG_GET_LOADING'

export const ORDONNANCE_MG_GET_SUCCESS = 'ORDONNANCE_MG_GET_SUCCESS'

export const ORDONNANCE_MG_GET_FAILED = 'ORDONNANCE_MG_GET_FAILED'


export const HOSPITALISATION_MG_GET_DATA_LOADING = "HOSPITALISATION_MG_GET_DATA_LOADING"

export const HOSPITALISATION_MG_GET_DATA_SUCCESS = "HOSPITALISATION_MG_GET_DATA_SUCCESS"

export const HOSPITALISATION_MG_GET_DATA_FAILED = "HOSPITALISATION_MG_GET_DATA_FAILED"


export const HOSPITALISATION_MG_GET_DATA__REFRESH_LOADING = "HOSPITALISATION_MG_GET_DATA__REFRESH_LOADING"

export const HOSPITALISATION_MG_GET_DATA__REFRESH_SUCCESS = "HOSPITALISATION_MG_GET_DATA__REFRESH_SUCCESS"

export const HOSPITALISATION_MG_GET_DATA__REFRESH_FAILED = "HOSPITALISATION_MG_GET_DATA__REFRESH_FAILED"


export const CONSULTATION_MG_RESET = 'CONSULTATION_MG_RESET'

export const CONSULTATION_MG_RESET_AFTER = "CONSULTATION_MG_RESET_AFTER"



export  const RESULTAT_DEMANDE_EXAMEN_MG_GET_LOADING  = 'RESULTAT_DEMANDE_EXAMEN_MG_GET_LOADING'

export const RESULTAT_DEMANDE_EXAMEN_MG_GET_SUCCESS  = 'RESULTAT_DEMANDE_EXAMEN_MG_GET_SUCCESS'

export const RESULTAT_DEMANDE_EXAMEN_MG_GET_FAILED = 'RESULTAT_DEMANDE_EXAMEN_MG_GET_FAILED'


export const PRESCRIPTION_EXAM_MG_POST_LOADING = 'PRESCRIPTION_EXAM_MG_POST_LOADING'

export const  PRESCRIPTION_EXAM_MG_POST_SUCCESS = 'PRESCRIPTION_EXAM_MG_POST_SUCCESS'

export const PRESCRIPTION_EXAM_MG_POST_FAILED = 'PRESCRIPTION_EXAM_MG_POST_FAILED'


export const PRESCRIPTION_EXAM_MG_GET_LOADING  = 'PRESCRIPTION_EXAM_MG_GET_LOADING'

export const PRESCRIPTION_EXAM_MG_GET_SUCCESS  = 'PRESCRIPTION_EXAM_MG_GET_SUCCESS'

export const PRESCRIPTION_EXAM_MG_GET_FAILED  = 'PRESCRIPTION_EXAM_MG_GET_FAILED'

export const PRESCRIPTION_EXAM_MG_GET_PAS_TROUVER  = 'PRESCRIPTION_EXAM_MG_GET_PAS_TROUVER'


export const COMPTE_RENDU_GETS_LOADING = 'COMPTE_RENDU_GETS_LOADING'

export const COMPTE_RENDU_GETS_SUCCESS = 'COMPTE_RENDU_GETS_SUCCESS'

export const COMPTE_RENDU_GETS_FAILED = 'COMPTE_RENDU_GETS_FAILED'


export const COMPTE_RENDU_GET_LOADING = 'COMPTE_RENDU_GET_LOADING'

export const COMPTE_RENDU_GET_SUCCESS = 'COMPTE_RENDU_GET_SUCCESS'

export const COMPTE_RENDU_GET_FAILED = 'COMPTE_RENDU_GET_FAILED'


export const COMPTE_RENDU_POST_LOADING = 'COMPTE_RENDU_POST_LOADING'

export const COMPTE_RENDU_POST_SUCCESS = 'COMPTE_RENDU_POST_SUCCESS'

export const COMPTE_RENDU_POST_FAILED = 'COMPTE_RENDU_POST_FAILED'

export const CONSULTATION_BAD_REQUEST = "CONSULTATION_BAD_REQUEST"
export const CONSULTATION_BAD_REQUEST_CLOSE = "CONSULTATION_BAD_REQUEST_CLOSE"
