import { rdvTypes } from "../types/rdvTypes";
import {
  AUTH_ERROR,
  LOGOUT_SUCCESS,
  TOKEN_REFRESH_FAIL,
} from "../types/userTypes";

const initialState = {
  isLoading: false,
  rdv: [],
  error: "",
  isSuccess: false,
  isError: false,
  warning: "",
  message: "",
};

export const rdvListReducer = (state = initialState, action) => {
  switch (action.type) {
    //rafraichir Get
    case rdvTypes.RDV_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: "",
        isSuccess: false,
        isError: false,
        warning: "",
      };
    case rdvTypes.RDV_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        rdv: action.payload,
        error: "",
        isSuccess: false,
        isError: false,
        warning: "",
        message: "",
      };
    case rdvTypes.RDV_LIST_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        isSuccess: false,
        isError: false,
        rdv: [],
        warning: "",
        message: "",
      };
    
    //rafraichir Data
    case rdvTypes.RDV_LIST_RAFRAICHIR_REQUEST:
      return {
        ...state,
        isLoadingRafraichir: true,
        error: "",
        isSuccess: false,
        isError: false
      };
    case rdvTypes.RDV_LIST_RAFRAICHIR_SUCCESS:
      return {
        ...state,
        isLoadingRafraichir: false,
        rdv: action.payload,
        error: "",
        isSuccess: false,
        isError: false
      };
    case rdvTypes.RDV_LIST_RAFRAICHIR_FAIL:
      return {
        ...state,
        isLoadingRafraichir: false,
        rdv: [],
        error: action.payload,
        isSuccess: false,
        isError: false
      };
    
    //refresh Data
    case rdvTypes.RDV_LIST_REFRESH_REQUEST:
      return {
        ...state,
        isLoadingRefresh: true,
        error: "",
        isSuccess: false,
        isError: false,
        warning: "",
      };
    case rdvTypes.RDV_LIST_REFRESH_SUCCESS:
      return {
        ...state,
        isLoadingRefresh: false,
        rdv: action.payload,
        error: "",
        isSuccess: false,
        isError: false,
        warning: "",
        message: "",
      };
    case rdvTypes.RDV_LIST_REFRESH_FAIL:
      return {
        ...state,
        isLoadingRefresh: false,
        error: action.payload,
        isSuccess: false,
        isError: false,
        warning: "",
        message: "",
      };


    case rdvTypes.RDV_PATIENT_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: "",
        isSuccess: false,
        isError: false,
        warning: "",
        message: "",
      };
    case rdvTypes.RDV_PATIENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        rdv: action.payload,
        error: "",
        isSuccess: false,
        isError: false,
        warning: "",
        message: "",
      };
    case rdvTypes.RDV_PATIENT_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        isSuccess: false,
        isError: false,
        warning: "",
        message: "",
      };
    case rdvTypes.RDV_POST_REQUEST:
      return {
        isLoading: true,
        isSuccess: false,
        isError: false,
        error: "",
        warning: "",
        message: "",
      };
    case rdvTypes.RDV_POST_SUCCESS:
      return {
        isLoading: false,
        isSuccess: true,
        isError: false,
        error: "",
        warning: "",
        message: "",
      };
    case rdvTypes.RDV_POST_FAIL:
      return {
        isLoading: false,
        isSuccess: false,
        isError: true,
        error: action.payload,
        warning: "",
        message: "",
      };
    case rdvTypes.RDV_END_REQUEST:
      return {
        isLoading: true,
        isSuccess: false,
        isError: false,
        error: "",
        warning: "",
        message: "",
      };
    case rdvTypes.RDV_END_SUCCESS:
      return {
        isLoading: false,
        isSuccess: true,
        isError: false,
        error: "",
        warning: "",
        message: action.payload,
      };
    case rdvTypes.RDV_END_FAIL:
      return {
        isLoading: false,
        isSuccess: false,
        isError: false,
        error: action.payload,
        warning: "",
        message: "",
      };
    case rdvTypes.RDV_END_WARNING:
      return {
        isLoading: false,
        isSuccess: false,
        isError: false,
        error: "",
        warning: action.payload,
        message: "",
      };
    
    case rdvTypes.CLEAR_RDV_STATE:
      return {
        error: "",
        isSuccess: false,
        isError: false,
        message: "",
      }

    case AUTH_ERROR:
    case LOGOUT_SUCCESS:
    case TOKEN_REFRESH_FAIL:
      localStorage.removeItem("token_access");
      localStorage.removeItem("token_refresh");
      return {
        ...state,
        isLoading: false,
        rdv: [],
        error: "",
        isSuccess: false,
        isError: false,
        message: "",
      };

    default:
      return state;
  }
};

export const rdvDeleteReducer = (state = initialState, action) => {
  switch (action.type) {
    case rdvTypes.RDV_DELETE_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: "",
      };
    case rdvTypes.RDV_DELETE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        rdv: action.payload,
        error: "",
      };
    case rdvTypes.RDV_DELETE_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    
    case rdvTypes.CLEAR_RDV_STATE:
      return {
        error: "",
        isLoading: false
      }

    case AUTH_ERROR:
    case LOGOUT_SUCCESS:
    case TOKEN_REFRESH_FAIL:
      localStorage.removeItem("token_access");
      localStorage.removeItem("token_refresh");
      return {
        ...state,
        isLoading: false,
        rdv: [],
        error: "",
      };

    default:
      return state;
  }
};
