import {
  DEMANDE_EXAMEN_LABORATOIRE_GET_FAILED,
  DEMANDE_EXAMEN_LABORATOIRE_GET_LOADING,
  DEMANDE_EXAMEN_LABORATOIRE_GET_SUCCESS,

  DEMANDES_EXAMENS_LABORATOIRE_GETS_FAILED,
  DEMANDES_EXAMENS_LABORATOIRE_GETS_LOADING,
  DEMANDES_EXAMENS_LABORATOIRE_GETS_SUCCESS,

  RESULTAT_DEMANDE_EXAMEN_LABORATOIRE_POST_FAILED,
  RESULTAT_DEMANDE_EXAMEN_LABORATOIRE_POST_LOADING,
  RESULTAT_DEMANDE_EXAMEN_LABORATOIRE_POST_SUCCESS,

  RESULTAT_DEMANDE_EXAMEN_LABORATOIRE_GET_FAILED,
  RESULTAT_DEMANDE_EXAMEN_LABORATOIRE_GET_LOADING,
  RESULTAT_DEMANDE_EXAMEN_LABORATOIRE_GET_SUCCESS,

  RESULTATS_DEMANDES_EXAMENS_LABORATOIRES_GETS_FAILED,
  RESULTATS_DEMANDES_EXAMENS_LABORATOIRES_GETS_LOADING,
  RESULTATS_DEMANDES_EXAMENS_LABORATOIRES_GETS_SUCCESS,

  RESULTATS_DEMANDES_EXAMENS_LABORATOIRES_RESET,
  RESULTATS_DEMANDES_EXAMENS_LABORATOIRES_RESET_AFTER,

  GET_EXAMENS_PARAMETRES_REQUEST,
  GET_EXAMENS_PARAMETRES_SUCCESS,
  GET_EXAMENS_PARAMETRES_FAILED,
  
  GET_LABORATOIRE_REFERENCE_RESULTATS_LOADING,
  GET_LABORATOIRE_REFERENCE_RESULTATS_SUCCESS,
  GET_LABORATOIRE_REFERENCE_RESULTATS_FAILED,

  GET_LABORATOIRE_RESULTATS_MULTIPLE_LOADING,
  GET_LABORATOIRE_RESULTATS_MULTIPLE_SUCCESS,
  GET_LABORATOIRE_RESULTATS_MULTIPLE_FAILED,

} from "../../types/consultationLaboratoireTypes";


import { AUTH_ERROR, LOGOUT_SUCCESS, TOKEN_REFRESH_FAIL } from "../../types/userTypes";


const initialState = {
  demandesExamens: [],
  isLoadingDemandesExamens: false,
  isSuccesDemandesExamens: false,

  demandeExamen: [],
  isLoadingDemandeExamen: false,
  isSuccesDemandeExamen: false,

  resultatPostDemandeExamen: {},
  isLoadingPostResultatDemandeExamen: false,
  isSuccesPostResultatDemandeExamen: false,

  resultatsDemandesExamens: [],
  isLoadingResultatsDemandesExamens: false,
  isSuccesResultatsDemandesExamens: false,

  resultatDemandeExamen: {},
  isLoadingResultatDemandeExamen: false,
  isSuccesResultatDemandeExamen: false,

  isErrorPostResultatDemandeExamen: false,
  errorPostResultatDemandeExamen: "",

  isError: false,
  error: "",

  parametres: [], // Variable récupération des paramètres des examens
  parametresLoader: false, // Paramètres Loader params
  parametresError: false, // Paramètres Error params

  laboResultatMultipleData: [],
  isLaodingLaboResultatMultipleData: false,
  ErrorLaboResultatMultipleData: null,

  laboResultatReferenceResultatData: [],
  isLaodingLaboReferenceResultat: false,
  ErrorLaboReferenceResultat: null,
};

//eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case DEMANDES_EXAMENS_LABORATOIRE_GETS_LOADING:
      return {
        ...state,
        isLoadingDemandesExamens: true,
        isSuccesDemandesExamens: false,
        isError: false,
        error: "",
      };
    case DEMANDES_EXAMENS_LABORATOIRE_GETS_SUCCESS:
      return {
        ...state,
        isLoadingDemandesExamens: false,
        demandesExamens: action.payload,
        isSuccesDemandesExamens: true,
        isError: false,
      };
    case DEMANDES_EXAMENS_LABORATOIRE_GETS_FAILED:
      return {
        ...state,
        isLoadingDemandesExamens: false,
        demandesExamens: [],
        error: action.payload,
        isSuccesDemandesExamens: false,
        isError: true,
      };

    case DEMANDE_EXAMEN_LABORATOIRE_GET_LOADING:
      return {
        ...state,
        isLoadingDemandeExamen: true,
        isSuccesDemandeExamen: false,
        isError: false,
        error: "",
      };
    case DEMANDE_EXAMEN_LABORATOIRE_GET_SUCCESS:
      return {
        ...state,
        isLoadingDemandeExamen: false,
        demandeExamen: action.payload,
        isSuccesDemandeExamen: true,
        isError: false,
      };
    case DEMANDE_EXAMEN_LABORATOIRE_GET_FAILED:
      return {
        ...state,
        isLoadingDemandeExamen: false,
        demandeExamen: {},
        error: action.payload,
        isSuccesDemandeExamen: false,
        isError: true,
      };

    case RESULTATS_DEMANDES_EXAMENS_LABORATOIRES_GETS_LOADING:
      return {
        ...state,
        isLoadingResultatsDemandesExamens: true,
        isSuccesResultatsDemandesExamens: false,
        isError: false,
        error: "",
      };
    case RESULTATS_DEMANDES_EXAMENS_LABORATOIRES_GETS_SUCCESS:
      return {
        ...state,
        isLoadingResultatsDemandesExamens: false,
        resultatsDemandesExamens: action.payload,
        isSuccesResultatsDemandesExamens: true,
        isError: false,
      };
    case RESULTATS_DEMANDES_EXAMENS_LABORATOIRES_GETS_FAILED:
      return {
        ...state,
        isLoadingResultatsDemandesExamens: false,
        error: action.payload,
        isSuccesResultatsDemandesExamens: false,
        isError: true,
      };

    case RESULTAT_DEMANDE_EXAMEN_LABORATOIRE_GET_LOADING:
      return {
        ...state,
        isLoadingResultatDemandeExamen: true,
        isSuccesResultatDemandeExamen: false,
        isError: false,
        error: "",
      };
    case RESULTAT_DEMANDE_EXAMEN_LABORATOIRE_GET_SUCCESS:
      return {
        ...state,
        isLoadingResultatDemandeExamen: false,
        resultatDemandeExamen: action.payload,
        isSuccesResultatDemandeExamen: true,
        isError: false,
      };
    case RESULTAT_DEMANDE_EXAMEN_LABORATOIRE_GET_FAILED:
      return {
        ...state,
        isLoadingResultatDemandeExamen: false,
        resultatDemandeExamen: {},
        error: action.payload,
        isSuccesResultatDemandeExamen: false,
        isError: true,
      };

    case RESULTAT_DEMANDE_EXAMEN_LABORATOIRE_POST_LOADING:
      return {
        ...state,
        isLoadingPostResultatDemandeExamen: true,
        isSuccesPostResultatDemandeExamen: false,
        isErrorPostResultatDemandeExamen: false,
        errorPostResultatDemandeExamen: "",
      };
    case RESULTAT_DEMANDE_EXAMEN_LABORATOIRE_POST_SUCCESS:
      return {
        ...state,
        isLoadingPostResultatDemandeExamen: false,
        resultatPostDemandeExamen: action.payload,
        isSuccesPostResultatDemandeExamen: true,
        isErrorPostResultatDemandeExamen: false,
        errorPostResultatDemandeExamen: "",
      };
    case RESULTAT_DEMANDE_EXAMEN_LABORATOIRE_POST_FAILED:
      return {
        ...state,
        isLoadingPostResultatDemandeExamen: false,
        resultatDemandeExamen: {},
        isSuccesPostResultatDemandeExamen: false,
        isErrorPostResultatDemandeExamen: true,
        errorPostResultatDemandeExamen: action.payload,
      };

    case RESULTATS_DEMANDES_EXAMENS_LABORATOIRES_RESET_AFTER:
      return {
        isSuccesDemandeExamen: false,
        isSuccesResultatDemandeExamen: false,
      };

    case GET_EXAMENS_PARAMETRES_REQUEST:
      return {
        ...state,
        parametres: [],
        parametresLoader: true,
        parametresError: false,
      };
    case GET_EXAMENS_PARAMETRES_SUCCESS:
      return {
        ...state,
        parametres: action.payload,
        parametresLoader: false,
        parametresError: false,
      };

    case GET_EXAMENS_PARAMETRES_FAILED:
      return {
        ...state,
        parametres: [],
        parametresLoader: false,
        parametresError: true,
      };

    case GET_LABORATOIRE_RESULTATS_MULTIPLE_LOADING:
      return {
        ...state,
        laboResultatMultipleData: [],
        isLaodingLaboResultatMultipleData: true,
        ErrorLaboResultatMultipleData: null,
      };
    case GET_LABORATOIRE_RESULTATS_MULTIPLE_SUCCESS:
      return {
        ...state,
        laboResultatMultipleData: action.payload,
        isLaodingLaboResultatMultipleData: false,
        ErrorLaboResultatMultipleData: null,
      };

    case GET_LABORATOIRE_RESULTATS_MULTIPLE_FAILED:
      return {
        ...state,
        laboResultatMultipleData: [],
        isLaodingLaboResultatMultipleData: false,
        ErrorLaboResultatMultipleData: action.payload,
      };

    case GET_LABORATOIRE_REFERENCE_RESULTATS_LOADING:
      return {
        ...state,
        laboResultatReferenceResultatData: [],
        isLaodingLaboReferenceResultat: true,
        ErrorLaboReferenceResultat: null,
      };
    case GET_LABORATOIRE_REFERENCE_RESULTATS_SUCCESS:
      return {
        ...state,
        laboResultatReferenceResultatData: action.payload,
        isLaodingLaboReferenceResultat: false,
        ErrorLaboReferenceResultat: null,
      };

    case GET_LABORATOIRE_REFERENCE_RESULTATS_FAILED:
      return {
        ...state,
        laboResultatReferenceResultatData: [],
        isLaodingLaboReferenceResultat: false,
        ErrorLaboReferenceResultat: action.payload,
      };

    case RESULTATS_DEMANDES_EXAMENS_LABORATOIRES_RESET:
      return {
        ...state,
        isLoadingDemandesExamens: false,
        isSuccesDemandesExamens: false,

        isLoadingDemandeExamen: false,
        isSuccesDemandeExamen: false,

        isLoadingPostResultatDemandeExamen: false,
        isSuccesPostResultatDemandeExamen: false,

        isLoadingResultatsDemandesExamens: false,
        isSuccesResultatsDemandesExamens: false,

        isLoadingResultatDemandeExamen: false,
        isSuccesResultatDemandeExamen: false,

        isErrorPostResultatDemandeExamen: false,
        errorPostResultatDemandeExamen: "",

        isError: false,
        error: "",

        laboResultatMultipleData: [],
        isLaodingLaboResultatMultipleData: false,
        ErrorLaboResultatMultipleData: null,

        laboResultatReferenceResultatData: [],
        isLaodingLaboReferenceResultat: false,
        ErrorLaboReferenceResultat: null,
      };

    case AUTH_ERROR:
    case LOGOUT_SUCCESS:
    case TOKEN_REFRESH_FAIL:
      localStorage.removeItem("token_access");
      localStorage.removeItem("token_refresh");
      return {
        ...state,
        demandesExamens: [],
        isLoadingDemandesExamens: false,
        isSuccesDemandesExamens: false,

        demandeExamen: [],
        isLoadingDemandeExamen: false,
        isSuccesDemandeExamen: false,

        resultatPostDemandeExamen: {},
        isLoadingPostResultatDemandeExamen: false,
        isSuccesPostResultatDemandeExamen: false,

        resultatsDemandesExamens: [],
        isLoadingResultatsDemandesExamens: false,
        isSuccesResultatsDemandesExamens: false,

        resultatDemandeExamen: {},
        isLoadingResultatDemandeExamen: false,
        isSuccesResultatDemandeExamen: false,

        isErrorPostResultatDemandeExamen: false,
        errorPostResultatDemandeExamen: "",

        isError: false,
        error: "",
      };

    default:
      return state;
  }
}
