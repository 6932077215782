export const batimentparametreTypes = {
    BATIMENT_LIST_REQUEST: "BATIMENT_LIST_REQUEST",
    BATIMENT_LIST_SUCCESS: "BATIMENT_LIST_SUCCESS",
    BATIMENT_LIST_FAIL: "BATIMENT_LIST_FAIL",
    BATIMENT_POST_SUCCESS: 'BATIMENT_POST_SUCCESS',
    BATIMENT_POST_FAILED: 'BATIMENT_POST_FAILED',
    BATIMENT_POST_LOADING: 'BATIMENT_POST_LOADING',
    BATIMENT_DETAIL_REQUEST: 'BATIMENT_DETAIL_REQUEST',
    BATIMENT_DETAIL_SUCCESS: 'BATIMENT_DETAIL_SUCCESS',
    BATIMENT_DETAIL_FAIL: 'BATIMENT_DETAIL_FAIL',
    BATIMENT_UPDATE_REQUEST: 'BATIMENT_UPDATE_REQUEST',
    BATIMENT_UPDATE_SUCCESS: 'BATIMENT_UPDATE_SUCCESS',
    BATIMENT_UPDATE_FAIL: 'BATIMENT_UPDATE_FAIL',
    BATIMENT_DELETE_REQUEST: 'BATIMENT_DELETE_REQUEST',
    BATIMENT_DELETE_SUCCESS: 'BATIMENT_DELETE_SUCCESS',
    BATIMENT_DELETE_FAIL: 'BATIMENT_DELETE_FAIL',
};
