import { fichePaiementTypes } from "../types/fichePaiementTypes"
import { recuPaiementTypes } from "../types/recuPaiementTypes";
import { AUTH_ERROR, LOGOUT_SUCCESS, TOKEN_REFRESH_FAIL } from "../types/userTypes"

const initialState = {
  isLoading: false,
  fichesPaiements: [],
  fichePaiementPrestations:[],
  fichesPaiementsCaisse:[],
  fichesPaiementsCaisseRecu:[],
  error: "",
  errors:"",
  isError:false,
  isErrors:false,
  fichePaiement:{},
  isLoadingRegiter:false,
  isRegister:false,
  isLoadingGet:false,
  isLoadingGetFiche:false,
  isLoadingGetPrestation:false,
  isSucces:false,
  isSuccesUpdateFiche:false,
  isSuccesMount:false,
  isLoadingRefresh: false,
  postAlert: "",
  isSuccesDeleteFiche: false,

  facture_exist: false,
  facture_body: {},

  controle_alert: false,
  controle_body: null,
  
}

//eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case fichePaiementTypes.FICHEPAIEMENT_POST_LOADING:
      return {
        ...state,
        isRegister: false,
        fichePaiement: {},
        isLoadingRegiter:true,
        isError:false,
        error: ""
      }
    case fichePaiementTypes.FICHEPAIEMENT_PUT_LOADING:
      return {
        ...state,
        isRegister: false,
        isSuccesUpdateFiche:false,
        fichePaiement: {},
        isLoadingRegiter:true,
        isError:false,
        error: ""
      }
    case fichePaiementTypes.FICHEPAIEMENT_POST_SUCCESS:
      return {
        ...state,
        isRegister: true,
        isLoadingRegiter:false,
        fichePaiement: action.payload,
        error: "",
        isError:false
      }
    case fichePaiementTypes.FICHEPAIEMENT_POST_ALERT:
      return {
        ...state,
        isRegister: false,
        isLoadingRegiter:false,
        isSuccesUpdateFiche:false,
        fichePaiement: {},
        controle_alert:true,
        controle_body: action.payload,
      }
    case fichePaiementTypes.FICHEPAIEMENT_PUT_SUCCESS:
      return {
        ...state,
        isRegister: false,
        isSuccesUpdateFiche:true,
        isLoadingRegiter:false,
        fichePaiement: action.payload,
        error: "",
        isError:false,
        isSuccesMount:false
      }
    case fichePaiementTypes.FICHEPAIEMENT_POST_FAILED:
      return {
        ...state,
        isRegister: false,
        isLoadingRegiter:false,
        isSuccesUpdateFiche:false,
        isError:true,
        fichePaiement: {},
        error: action.payload,
      }
    case fichePaiementTypes.FICHEPAIEMENT_PUT_FAILED:
      return {
        ...state,
        isRegister: false,
        isLoadingRegiter:false,
        isError:true,
        fichePaiement: {},
        error: action.payload,
      }

    //Get une fiche paiement
    case fichePaiementTypes.FICHEPAIEMENT_GET_LOADING:
      return {
        ...state,
        isLoadingGet: false,
        isLoadingGetFiche:true,
        fichePaiement: {},
        isSuccesMount:false,
        isError:false,
        error: "",
        isSucces:false,
        controle_alert: false,
        controle_body: null,
      }
    case fichePaiementTypes.FICHEPAIEMENT_GET_SUCCESS:
      return {
        ...state,
        isLoadingGet: false,
        isLoadingGetFiche:false,
        fichePaiement: action.payload,
        isSuccesMount:true,
        error: "",
        isError:false,
        controle_alert: false,
        controle_body: null,
      }
    case fichePaiementTypes.FICHEPAIEMENT_GET_FAILED:
      return {
        ...state,
        isLoadingGet: false,
        isError:true,
        isSuccesMount:false,
        isLoadingGetFiche:false,
        fichePaiement: {},
        error: action.payload,
        isSucces:false,
        controle_alert: false,
        controle_body: null,
      }
    
    //Get prestation pour une fiche paiement
    case fichePaiementTypes.FICHEPAIEMENT_GET_PRESTATION_LOADING:
      return {
        ...state,
        isLoadingGetPrestation: true,
        fichePaiementPrestations: [],
        isError:false,
        error: "",
        controle_alert: false,
        controle_body: null,
      }
    case fichePaiementTypes.FICHEPAIEMENT_GET_PRESTATION_SUCCESS:
      return {
        ...state,
        isLoadingGetPrestation: false,
        fichePaiementPrestations: action.payload,
        error: "",
        isError:false,
        controle_alert: false,
        controle_body: null,
      }
    case fichePaiementTypes.FICHEPAIEMENT_GET_PRESTATION_FAILED:
      return {
        ...state,
        isLoadingGetPrestation: false,
        isError:true,
        fichePaiementPrestations: [],
        error: action.payload,
        controle_alert: false,
        controle_body: null,
      }

    //gets fiches paiements
    case fichePaiementTypes.FICHEPAIEMENT_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: "",
        controle_alert: false,
        controle_body: null,
      }
    case fichePaiementTypes.FICHEPAIEMENT_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        fichesPaiements: action.payload,
        error: "",
        controle_alert: false,
        controle_body: null,
      }
    case fichePaiementTypes.FICHEPAIEMENT_LIST_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        controle_alert: false,
        controle_body: null,
      }

    //gets fiches paiements refresh
    case fichePaiementTypes.FICHEPAIEMENT_LIST_REFRESH_REQUEST:
      return {
        ...state,
        isLoadingRefresh: true,
        error: "",
        controle_alert: false,
        controle_body: null,
      }
    case fichePaiementTypes.FICHEPAIEMENT_LIST_REFRESH_SUCCESS:
      return {
        ...state,
        isLoadingRefresh: false,
        fichesPaiements: action.payload,
        error: "",
        controle_alert: false,
        controle_body: null,
      }
    case fichePaiementTypes.FICHEPAIEMENT_LIST_REFRESH_FAIL:
      return {
        ...state,
        isLoadingRefresh: false,
        error: action.payload,
        controle_alert: false,
        controle_body: null,
      }
      

    case fichePaiementTypes.FICHEPAIEMENT_LIST_PRE_CAISSE_LOADING:
      return {
        ...state,
        isLoading: true,
        error: "",
        controle_alert: false,
        controle_body: null,
      }
    case fichePaiementTypes.FICHEPAIEMENT_LIST_PRE_CAISSE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        fichesPaiementsCaisse: action.payload,
        error: "",
        controle_alert: false,
        controle_body: null,
      }
    case fichePaiementTypes.FICHEPAIEMENT_LIST_PRE_CAISSE_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        controle_alert: false,
        controle_body: null,
      }
    case recuPaiementTypes.CAISSE_RECU_GET_SUCCESS:
      return {
        ...state,
        isLoadingForce: true,
        controle_alert: false,
        controle_body: null,
      }

    
    case fichePaiementTypes.FICHEPAIEMENT_LIST_PRE_CAISSE_REFRESH_LOADING:
      return {
        ...state,
        isLoadingRefresh: true,
        error: "",
        controle_alert: false,
        controle_body: null,
      }
    case fichePaiementTypes.FICHEPAIEMENT_LIST_PRE_CAISSE_REFRESH_SUCCESS:
      return {
        ...state,
        isLoadingRefresh: false,
        fichesPaiementsCaisse: action.payload,
        error: "",
        controle_alert: false,
        controle_body: null,
      }
    case fichePaiementTypes.FICHEPAIEMENT_LIST_PRE_CAISSE_REFRESH_FAILED:
      return {
        ...state,
        isLoadingRefresh: false,
        error: action.payload,
      }


    case fichePaiementTypes.FICHEPAIEMENT_LIST_POST_CAISSE_LOADING:
      return {
        ...state,
        isLoading: true,
        error: "",
        controle_alert: false,
        controle_body: null,
      }
    case fichePaiementTypes.FICHEPAIEMENT_LIST_POST_CAISSE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        fichesPaiementsCaisseRecu: action.payload,
        error: "",
        controle_alert: false,
        controle_body: null,
      }
    case fichePaiementTypes.FICHEPAIEMENT_LIST_POST_CAISSE_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        controle_alert: false,
        controle_body: null,
      }

    case fichePaiementTypes.FICHEPAIEMENT_DELETE_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: "",
        isSucces:false,
        isError:false,
        controle_alert: false,
        controle_body: null,
      }

    case fichePaiementTypes.FICHEPAIEMENT_DELETE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        fichesPaiements: state.fichesPaiements.filter(data => data.id !== action.payload), //action.payload
        error: "",
        isSucces:true,
        isError:false,
        controle_alert: false,
        controle_body: null,
      }

    case fichePaiementTypes.FICHEPAIEMENT_DELETE_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        isSucces:false,
        isError:true,
        controle_alert: false,
        controle_body: null,
      }
    
    case fichePaiementTypes.FICHEPAIEMENT_DELETE_PRE_CAISSE_REQUEST:
      return {
        ...state,
        isLoading: true,
        errors: "",
        isSuccesDeleteFiche: false,
        isErrors:false,
        controle_alert: false,
        controle_body: null,
      }

    case fichePaiementTypes.FICHEPAIEMENT_DELETE_PRE_CAISSE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        fichesPaiementsCaisse: state.fichesPaiementsCaisse.filter(data => data.id !== action.payload), //action.payload
        fichesPaiements: state.fichesPaiements.filter(data => data.id !== action.payload),
        errors: "",
        isSuccesDeleteFiche: true,
        isErrors:false,
        controle_alert: false,
        controle_body: null,
      }
    
    case fichePaiementTypes.FICHEPAIEMENT_DELETE_IMPOSSIBLE_PRE_CAISSE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errors: action.payload,
        isSuccesDeleteFiche: false,
        isErrors:true,
        controle_alert: false,
        controle_body: null,
      }

    case fichePaiementTypes.FICHEPAIEMENT_DELETE_PRE_CAISSE_FAIL:
      return {
        ...state,
        isLoading: false,
        errors: action.payload,
        isSuccesDeleteFiche:false,
        isErrors:true,
        controle_alert: false,
        controle_body: null,
      }
    
    case fichePaiementTypes.FICHEPAIEMENT_DELETE_POST_CAISSE_LOADING:
      return {
        ...state,
        isLoading: true,
        error: "",
        controle_alert: false,
        controle_body: null,
      }

    case fichePaiementTypes.FICHEPAIEMENT_DELETE_POST_CAISSE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        fichesPaiementsCaisseRecu: state.fichesPaiementsCaisseRecu.filter(data => data.id !== action.payload), //action.payload
        error: "",
        isSucces:true,
        isError:false,
        controle_alert: false,
        controle_body: null,
      }

    case fichePaiementTypes.FICHEPAIEMENT_DELETE_POST_CAISSE_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        isSucces:false,
        isError:true,
        controle_alert: false,
        controle_body: null,
      }

    case fichePaiementTypes.FICHEPAIEMENT_SET_SUCCESS:
            return {
                ...state,
                isRegister: false,
                isSucces:false,
                isError:false,
                isErrors:false,
                error: "",
                errors: "",
                isSuccesUpdateFiche:false,
                isLoadingRefresh: false,
                isSuccesDeleteFiche: false,
                controle_alert: false,
                controle_body: null,
            }
    case fichePaiementTypes.FACTURE_FICHE_EXIST:
      return {
          ...state,
          facture_exist: true,
          facture_body: action.payload,
          isLoadingRegiter:false,
          controle_alert: false,
          controle_body: null,
      }
    case fichePaiementTypes.FACTURE_FICHE_EXIST_RESET:
      return {
          ...state,
          facture_exist: false,
          isLoadingRegiter:false,
          facture_body: {},
          controle_alert: false,
          controle_body: null,
      }

    case AUTH_ERROR:
    case LOGOUT_SUCCESS:
    case TOKEN_REFRESH_FAIL:
        localStorage.removeItem('token_access')
        localStorage.removeItem('token_refresh')
        return {
            ...state,
            isLoading: false,
            fichesPaiements: [],
            fichePaiementPrestations:[],
            fichesPaiementsCaisse:[],
            fichesPaiementsCaisseRecu:[],
            error: "",
            errors:"",
            isError:false,
            isErrors:false,
            fichePaiement:{},
            isLoadingRegiter:false,
            isRegister:false,
            isLoadingGet:false,
            isLoadingGetFiche:false,
            isLoadingGetPrestation:false,
            isSucces:false,
            isSuccesUpdateFiche:false,
            isSuccesMount:false,
            isLoadingRefresh: false,
            postAlert: "",
            isSuccesDeleteFiche: false,
            facture_exist: false,
            acture_body: {},
            controle_alert:false,
            controle_body: null,

        }

    default:
      return state
  }
}