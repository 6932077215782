export const SERVICE_POST_DATA_LOADING = 'SERVICE_POST_DATA_LOADING'

export const SERVICE_POST_DATA_SUCCESS = 'SERVICE_POST_DATA_SUCCESS'

export const SERVICE_POST_DATA_FAILED_403 = 'SERVICE_POST_DATA_FAILED_403'

export const SERVICE_POST_DATA_FAILED_500 = 'SERVICE_POST_DATA_FAILED_500'

export const SERVICE_POST_DATA_FAILED = 'SERVICE_POST_DATA_FAILED'



export const SERVICE_PUT_DATA_LOADING = 'SERVICE_PUT_DATA_LOADING'

export const SERVICE_PUT_DATA_SUCCESS = 'SERVICE_PUT_DATA_SUCCESS'

export const SERVICE_PUT_DATA_FAILED = 'SERVICE_PUT_DATA_FAILED'

export const SERVICE_PUT_DATA_FAILED_403 = 'SERVICE_PUT_DATA_FAILED_403'

export const SERVICE_PUT_DATA_FAILED_500 = 'SERVICE_PUT_DATA_FAILED_500'



export const SERVICE_DELETE_DATA_LOADING = 'SERVICE_DELETE_DATA_LOADING'

export const SERVICE_DELETE_DATA_SUCCESS = 'SERVICE_DELETE_DATA_SUCCESS'

export const SERVICE_DELETE_DATA_FAILED = 'SERVICE_DELETE_DATA_FAILED'

export const SERVICE_DELETE_DATA_FAILED_403 = 'SERVICE_DELETE_DATA_FAILED_403'

export const SERVICE_DELETE_DATA_FAILED_500 = 'SERVICE_DELETE_DATA_FAILED_500'



export const SERVICE_GET_DATA_SUCCESS = 'SERVICE_GET_DATA_SUCCESS'

export const SERVICE_GET_DATA_FAILED = 'SERVICE_GET_DATA_FAILED'

export const SERVICE_GET_DATA_FAILED_403 = 'SERVICE_GET_DATA_FAILED_403'

export const SERVICE_GET_DATA_FAILED_500 = 'SERVICE_GET_DATA_FAILED_500'

export const SERVICE_GET_DATA_LOADING = 'SERVICE_GET_DATA_LOADING'



export const SERVICE_GETS_DATA_SUCCESS = 'SERVICE_GETS_DATA_SUCCESS'

export const SERVICE_GETS_DATA_FAILED = 'SERVICE_GETS_DATA_FAILED'

export const SERVICE_GETS_DATA_LOADING = 'SERVICE_GETS_DATA_LOADING'


export const SERVICE_GET_DATA_ALL_SUCCESS = 'SERVICE_GET_DATA_ALL_SUCCESS'

export const SERVICE_GET_DATA_ALL_FAILED = 'SERVICE_GET_DATA_ALL_FAILED'

export const SERVICE_GET_DATA_ALL_LOADING = 'SERVICE_GET_DATA_ALL_LOADING'


export const SERVICE_GET_REFRESH_DATA_ALL_SUCCESS = 'SERVICE_GET_REFRESH_DATA_ALL_SUCCESS'

export const SERVICE_GET_REFRESH_DATA_ALL_FAILED = 'SERVICE_GET_REFRESH_DATA_ALL_FAILED'

export const SERVICE_GET_REFRESH_DATA_ALL_LOADING = 'SERVICE_GET_REFRESH_DATA_ALL_LOADING'


export const SERVICE_CLEAR_STATE = "SERVICE_CLEAR_STATE"
