import React, { lazy } from "react"
import * as MyPrivateRouters from "../routes/PrivateRouter";
import { Switch } from 'react-router-dom';

const LayoutTelemedecinePatientAConsulter = lazy(() =>
    import("../../components/telemedecine/patientAConsulter/LayoutTelemedecinePatientAConsulter")
);


const LayoutTelemedecineConsultationListe = lazy(() => 
    import("../../components/telemedecine/consultation/LayoutTelemedecineConsultationListe")
)
  

export default function TelemedecineRouter() {
  return (
    <Switch>
        <MyPrivateRouters.PrivateRouterTelemedecine
        path="/telemedecine"
        component={LayoutTelemedecinePatientAConsulter}
        />

        <MyPrivateRouters.PrivateRouterTelemedecine
        path="/telemedecine-consultation"
        component={LayoutTelemedecineConsultationListe}
        />
    </Switch>
  )
}
