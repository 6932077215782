export const chambreparametreTypes = {
    CHAMBRE_LIST_REQUEST: "CHAMBRE_LIST_REQUEST",
    CHAMBRE_LIST_SUCCESS: "CHAMBRE_LIST_SUCCESS",
    CHAMBRE_LIST_FAIL: "CHAMBRE_LIST_FAIL",

    CHAMBRE_LIST_DISPONIBLE_REQUEST: "CHAMBRE_LIST_DISPONIBLE_REQUEST",
    CHAMBRE_LIST_DISPONIBLE_SUCCESS: "CHAMBRE_LIST_DISPONIBLE_SUCCESS",
    CHAMBRE_LIST_DISPONIBLE_FAIL: "CHAMBRE_LIST_DISPONIBLE_FAIL",

    CHAMBRE_POST_SUCCESS: 'CHAMBRE_POST_SUCCESS',
    CHAMBRE_POST_FAILED: 'CHAMBRE_POST_FAILED',
    CHAMBRE_POST_LOADING: 'CHAMBRE_POST_LOADING',
    CHAMBRE_DETAIL_REQUEST: 'CHAMBRE_DETAIL_REQUEST',
    CHAMBRE_DETAIL_SUCCESS: 'CHAMBRE_DETAIL_SUCCESS',
    CHAMBRE_DETAIL_FAIL: 'CHAMBRE_DETAIL_FAIL',
    CHAMBRE_UPDATE_REQUEST: 'CHAMBRE_UPDATE_REQUEST',
    CHAMBRE_UPDATE_SUCCESS: 'CHAMBRE_UPDATE_SUCCESS',
    CHAMBRE_UPDATE_FAIL: 'CHAMBRE_UPDATE_FAIL',
    CHAMBRE_DELETE_REQUEST: 'CHAMBRE_DELETE_REQUEST',
    CHAMBRE_DELETE_SUCCESS: 'CHAMBRE_DELETE_SUCCESS',
    CHAMBRE_DELETE_FAIL: 'CHAMBRE_DELETE_FAIL',

    CAT_CHAMBRE_REQUEST: "CAT_CHAMBRE_REQUEST",
    CAT_CHAMBRE_SUCCESS: "CAT_CHAMBRE_SUCCESS",
    CAT_CHAMBRE_FAIL: "CAT_CHAMBRE_FAIL",

    CAT_CHAMBRE_ADD_REQUEST: "CAT_CHAMBRE_ADD_REQUEST",
    CAT_CHAMBRE_ADD_SUCCESS: "CAT_CHAMBRE_ADD_SUCCESS",
    CAT_CHAMBRE_ADD_FAIL: "CAT_CHAMBRE_ADD_FAIL",

    CAT_CHAMBRE_UPDATE_REQUEST: "CAT_CHAMBRE_UPDATE_REQUEST",
    CAT_CHAMBRE_UPDATE_SUCCESS: "CAT_CHAMBRE_UPDATE_SUCCESS",
    CAT_CHAMBRE_UPDATE_FAIL: "CAT_CHAMBRE_UPDATE_FAIL",

    ONE_CAT_CHAMBRE_REQUEST: "ONE_CAT_CHAMBRE_REQUEST",
    ONE_CAT_CHAMBRE_SUCCESS: "ONE_CAT_CHAMBRE_SUCCESS",
    ONE_CAT_CHAMBRE_FAIL: "ONE_CAT_CHAMBRE_FAIL",

    DELETE_CAT_CHAMBRE_REQUEST: "DELETE_CAT_CHAMBRE_REQUEST",
    DELETE_CAT_CHAMBRE_SUCCESS: "DELETE_CAT_CHAMBRE_SUCCESS",
    DELETE_CAT_CHAMBRE_FAIL: "DELETE_CAT_CHAMBRE_FAIL",
};
