export const observationTypes = {
    OBSERVATION_LIST_REQUEST: "OBSERVATION_LIST_REQUEST",
    OBSERVATION_LIST_SUCCESS: "OBSERVATION_LIST_SUCCESS",
    OBSERVATION_LIST_FAIL: "OBSERVATION_LIST_FAIL",

    OBSERVATION_LIST_REFRESH_REQUEST: "OBSERVATION_LIST_REFRESH_REQUEST",
    OBSERVATION_LIST_REFRESH_SUCCESS: "OBSERVATION_LIST_REFRESH_SUCCESS",
    OBSERVATION_LIST_REFRESH_FAIL: "OBSERVATION_LIST_REFRESH_FAIL",

    PATIENT_ALL_OBSERVATIONS_REQUEST: "PATIENT_ALL_OBSERVATIONS_REQUEST",
    PATIENT_ALL_OBSERVATIONS_SUCCESS: "PATIENT_ALL_OBSERVATIONS_SUCCESS",
    PATIENT_ALL_OBSERVATIONS_FAIL: "PATIENT_ALL_OBSERVATIONS_FAIL",
}