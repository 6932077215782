export const STAT_PERSONNEL_GETS_DATA_LOADING = "STAT_PERSONNEL_GETS_DATA_LOADING"

export const STAT_PERSONNEL_GETS_DATA_SUCCESS = "STAT_PERSONNEL_GETS_DATA_SUCCESS"

export const STAT_PERSONNEL_GETS_DATA_FAILED = 'STAT_PERSONNEL_GETS_DATA_FAILED'

export const STAT_ACCUEIL_GETS_DATA_LOADING = "STAT_ACCUEIL_GETS_DATA_LOADING"

export const STAT_ACCUEIL_GETS_DATA_SUCCESS = "STAT_ACCUEIL_GETS_DATA_SUCCESS"

export const STAT_ACCUEIL_GETS_DATA_FAILED = 'STAT_ACCUEIL_GETS_DATA_FAILED'

export const STAT_CAISSE_GETS_DATA_LOADING = "STAT_CAISSE_GETS_DATA_LOADING"

export const STAT_CAISSE_GETS_DATA_SUCCESS = "STAT_CAISSE_GETS_DATA_SUCCESS"

export const STAT_CAISSE_GETS_DATA_FAILED = 'STAT_CAISSE_GETS_DATA_FAILED'


export const statistiqueTypes = {
    STAT_PERSONNEL_LIST_REQUEST: "STAT_PERSONNEL_LIST_REQUEST",
    STAT_PERSONNEL_LIST_SUCCESS: "STAT_PERSONNEL_LIST_SUCCESS",
    STAT_PERSONNEL_LIST_FAIL: "STAT_PERSONNEL_LIST_FAIL",
    STAT_ACCUEIL_LIST_REQUEST: "STAT_ACCUEIL_LIST_REQUEST",
    STAT_ACCUEIL_LIST_SUCCESS: "STAT_ACCUEIL_LIST_SUCCESS",
    STAT_ACCUEIL_LIST_FAIL: "STAT_ACCUEIL_LIST_FAIL",

    STAT_ACCUEIL_HISTOGRAMME_REQUEST: "STAT_ACCUEIL_HISTOGRAMME_REQUEST",
    STAT_ACCUEIL_HISTOGRAMME_SUCCESS: "STAT_ACCUEIL_HISTOGRAMME_SUCCESS",
    STAT_ACCUEIL_HISTOGRAMME_FAIL: "STAT_ACCUEIL_HISTOGRAMME_FAIL",

    STAT_CAISSE_LIST_REQUEST: "STAT_CAISSE_LIST_REQUEST",
    STAT_CAISSE_LIST_SUCCESS: "STAT_CAISSE_LIST_SUCCESS",
    STAT_CAISSE_LIST_FAIL: "STAT_CAISSE_LIST_FAIL",
    
    STAT_GET_SERVICES_LOADING: "STAT_GET_SERVICES_LOADING",
    STAT_GET_SERVICES_FAIL: "STAT_GET_SERVICES_FAIL",
    STAT_GET_SERVICES_SUCCESS: "STAT_GET_SERVICES_SUCCESS",

    STAT_GET_UTILISATEURS_STAT_LOADING: "STAT_GET_UTILISATEURS_STAT_LOADING",
    STAT_GET_UTILISATEURS_STAT_FAIL: "STAT_GET_UTILISATEURS_STAT_FAIL",
    STAT_GET_UTILISATEURS_STAT_SUCCESS: "STAT_GET_UTILISATEURS_STAT_SUCCESS",

    STAT_GET_SERVICES_SELECTED_LOADING: "STAT_GET_SERVICES_SELECTED_LOADING",
    STAT_GET_SERVICES_SELECTED_FAIL: "STAT_GET_SERVICES_SELECTED_FAIL",
    STAT_GET_SERVICES_SELECTED_SUCCESS: "STAT_GET_SERVICES_SELECTED_SUCCESS",

    STAT_GET_SERVICES_SELECTED_HISTOGRAMME_LOADING: "STAT_GET_SERVICES_SELECTED_HISTOGRAMME_LOADING",
    STAT_GET_SERVICES_SELECTED_HISTOGRAMME_FAIL: "STAT_GET_SERVICES_SELECTED_HISTOGRAMME_FAIL",
    STAT_GET_SERVICES_SELECTED_HISTOGRAMME_SUCCESS: "STAT_GET_SERVICES_SELECTED_HISTOGRAMME_SUCCESS",


    STAT_GET_FREQUENTATION_LOADING: "STAT_GET_FREQUENTATION_LOADING",
    STAT_GET_FREQUENTATION_FAILED: "STAT_GET_FREQUENTATION_FAILED",
    STAT_GET_FREQUENTATION_SUCCESS: "STAT_GET_FREQUENTATION_SUCCESS",

    STAT_GET_FREQUENTATION_MOIS_LOADING: "STAT_GET_FREQUENTATION_MOIS_LOADING",
    STAT_GET_FREQUENTATION_MOIS_FAILED: "STAT_GET_FREQUENTATION_MOIS_FAILED",
    STAT_GET_FREQUENTATION_MOIS_SUCCESS: "STAT_GET_FREQUENTATION_MOIS_SUCCESS",

    STAT_GET_UTILISATEURS_STAT_FILTER: "STAT_GET_UTILISATEURS_STAT_FILTER"
    
};
