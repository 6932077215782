import React, { lazy } from "react"
import * as MyPrivateRouters from "../routes/PrivateRouter";
import { Switch } from 'react-router-dom';

const LayoutReanimationPatientAConsulter = lazy(() =>
    import(
      "../../components/Reanimation/patientAConsulter/LayoutReanimationPatientAConsulter"
    )
);
const LayoutReanimationConsultationListe = lazy(() =>
import(
    "../../components/Reanimation/consultation/LayoutReanimationConsultationListe"
)
);
const LayoutReanimationDemandeHospitalisationListe = lazy(() =>
import(
    "../../components/Reanimation/demandeHospitalisation/LayoutReanimationDemandeHospitalisationListe"
)
);
const ReanimationObservation = lazy(() =>
import("../../components/Reanimation/observation/ReanimationObservation")
);
const ReanimationObservationTerminees = lazy(() =>
    import("../../components/Reanimation/observationTerminees/ReanimationObservationTerminees")
    );

const LayoutReanimationCreateConsultation = lazy(() =>
import(
    "../../components/Reanimation/patientAConsulterCreate/LayoutReanimationCreateConsultation"
)
);

const LayoutReanimationViewConsultation = lazy(() =>
import(
    "../../components/Reanimation/patientAConsulterDetail/LayoutReanimationViewConsultation"
)
);

const LayoutCreateConsultationSortieReanimation = lazy(() =>
import(
    "../../components/Reanimation/patientAConsulterIssuSortie/LayoutCreateConsultationSortieReanimation"
)
);

const LayoutCreateConsultationSortieDemandeHospitalisationReanimation = lazy(() =>
    import(
    "../../components/Reanimation/patientAConsulterIssuSortie/DemandeHospitalisation/LayoutCreateConsultationSortieDemandeHospitalisationReanimation"
    )
);
const LayoutCreateConsultationSortieMiseEnObservationReanimation = lazy(() =>
import(
    "../../components/Reanimation/patientAConsulterIssuSortie/MiseEnObservation/LayoutCreateConsultationSortieMiseEnObservationReanimation"
)
);

const LayoutCreateConsultationRefereInterneReanimation = lazy(() =>
import(
    "../../components/Reanimation/patientAConsulterIssuSortie/RefereInterne/LayoutCreateConsultationRefereInterneReanimation"
)
);
const LayoutCreateConsultationRefereExterneReanimation = lazy(() =>
import(
    "../../components/Reanimation/patientAConsulterIssuSortie/RefereExterne/LayoutCreateConsultationRefereExterneReanimation"
)
);

const LayoutReanimationOrdonnanceDetails = lazy(() =>
import(
    "../../components/Reanimation/ordonnance/detail/LayoutReanimationOrdonnanceDetails"
)
);
const LayoutReanimationOrdonnanceListe = lazy(() =>
import(
    "../../components/Reanimation/ordonnance/liste/LayoutReanimationOrdonnanceListe"
)
);

const LayoutReanimationFeuilleDeSoins = lazy(() =>
import(
    "../../components/Reanimation/feuilleDeSoins/LayoutReanimationFeuilleDeSoins"
)
);

const LayoutReanimationHospitalisationListe = lazy(() =>
import(
    "../../components/Reanimation/hospitalisation/LayoutReanimationHospitalisationListe"
)
);

const LayoutReanimationHospitalisationConsultationCreate = lazy(() =>
import(
    "../../components/Reanimation/hospitalisation/consultation/LayoutReanimationHospitalisationConsultationCreate"
)
);
const LayoutReanimationHospitalisationConsultationSortie = lazy(() =>
import(
    "../../components/Reanimation/hospitalisation/issueSortie/LayoutReanimationHospitalisationConsultationSortie"
)
);

const ReanimationRefExterne = lazy(() =>
import("../../components/Reanimation/refExterne/ReanimationRefExterne")
);
const ReanimationRefInterne = lazy(() =>
import("../../components/Reanimation/refInterne/ReanimationRefInterne")
);
const LayoutReanimationDecesConsultationSortie = lazy(() =>
import(
    "../../components/Reanimation/patientAConsulterIssuSortie/IssueDeDeces/LayoutReanimationDecesConsultationSortie"
)
);

const DemandeHospiObservationReanimation = lazy(() =>
import(
    "../../components/Reanimation/patientAConsulterIssuSortie/MiseEnObservation/DemandeHospitalisation/LayoutCreateConsultationSortieDemandeHospitalisation"
)
);
const RefExterneObservationReanimation = lazy(() =>
import(
    "../../components/Reanimation/patientAConsulterIssuSortie/MiseEnObservation/RefereExterne/LayoutCreateConsultationRefereExterne"
)
);
const RefInterneObservationReanimation = lazy(() =>
import(
    "../../components/Reanimation/patientAConsulterIssuSortie/MiseEnObservation/RefereInterne/LayoutCreateConsultationRefereInterne"
)
);

const ReanimationExamensDetail = lazy(() =>
import("../../components/Reanimation/Examens/detail")
);
const ReanimationExamensList = lazy(() =>
import("../../components/Reanimation/Examens/list")
);

const LayoutReanimationDecesListe = lazy(() =>
import("../../components/Reanimation/Deces/Liste/LayoutReanimationDecesListe")
);
const LayoutReanimationDecesCertificat = lazy(() =>
import(
    "../../components/Reanimation/Deces/Certificat/LayoutReanimationDecesCertificat"
)
);

const AgendaReanimation = lazy(() =>
import("../../components/Reanimation/rendezVous/agendaDetail/AgendaReanimation")
);
const LayoutReanimationCreateConsultationRendezVous = lazy(() =>
import(
    "../../components/Reanimation/patientAConsulterIssuSortie/RendezVous/LayoutReanimationCreateConsultationRendezVous"
)
);
const LayoutReanimationRdvListe = lazy(() =>
import("../../components/Reanimation/rendezVous/Liste/LayoutReanimationRdvListe")
);
const LayoutReanimationRdvLastConsultation = lazy(() =>
import("../../components/Reanimation/rendezVous/LastConsultation/LayoutReanimationRdvLastConsultation")
);
const LayoutReanimationRdvNewConsultation = lazy(() =>
import("../../components/Reanimation/rendezVous/NewConsultation/LayoutReanimationRdvNewConsultation")
);
const LayoutVoirReanimationResultatsExamens = lazy(() =>
import(
    "../../components/Reanimation/Examens/resultatsExamensVoir/LayoutVoirMedecineGeneralResultatsExamens"
)
);

const RapportReanimation = lazy(() => import("../../components/Reanimation/rapports/RapportReanimation"));

const LayoutReanimationControleMajor = lazy(() => 
import("../../components/Reanimation/controleMajor/LayoutReanimationControleMajor")
)
  

export default function ReanimationRouter() {
  return (
    <Switch>
        <MyPrivateRouters.PrivateRouterReanimation
        path="/reanimation"
        component={LayoutReanimationPatientAConsulter}
        />

        <MyPrivateRouters.PrivateRouterReanimation
        path="/reanimation-observation-encours"
        component={ReanimationObservation}
        />

        <MyPrivateRouters.PrivateRouterReanimation
        path="/reanimation-observation-terminee"
        component={ReanimationObservationTerminees}
        />

        <MyPrivateRouters.PrivateRouterReanimation
        path="/reanimation-consultation"
        component={LayoutReanimationConsultationListe}
        />

        <MyPrivateRouters.PrivateRouterReanimation
        path="/reanimation-ordonnance"
        component={LayoutReanimationOrdonnanceListe}
        />

        <MyPrivateRouters.PrivateRouterReanimation
        path="/reanimation-ordonnance-voir/:id"
        component={LayoutReanimationOrdonnanceDetails}
        />

        <MyPrivateRouters.PrivateRouterReanimation
        path="/reanimation-rdv"
        component={LayoutReanimationRdvListe}
        />

        <MyPrivateRouters.PrivateRouterReanimation
        path="/reanimation-rdv-last-consultation/:id"
        component={LayoutReanimationRdvLastConsultation}
        />

        <MyPrivateRouters.PrivateRouterReanimation
        path="/reanimation-rdv-new-consultation/:id"
        component={LayoutReanimationRdvNewConsultation}
        />

        <MyPrivateRouters.PrivateRouterReanimation
        path="/reanimation-rdv-agenda/"
        component={AgendaReanimation}
        />

        <MyPrivateRouters.PrivateRouterReanimation
        path="/reanimation-consultation-create/:id"
        component={LayoutReanimationCreateConsultation}
        />

        <MyPrivateRouters.PrivateRouterReanimation
        path="/reanimation-consultation-voir-detail/:id"
        component={LayoutReanimationViewConsultation}
        />

        <MyPrivateRouters.PrivateRouterReanimation
        path="/reanimation-demande-hospitalisation"
        component={LayoutReanimationDemandeHospitalisationListe}
        />

        <MyPrivateRouters.PrivateRouterReanimation
        path="/reanimation-hospitalisation"
        component={LayoutReanimationHospitalisationListe}
        />

        <MyPrivateRouters.PrivateRouterReanimation
        path="/reanimation-hospitalisation-consultation/:id"
        component={LayoutReanimationHospitalisationConsultationCreate}
        />

        <MyPrivateRouters.PrivateRouterReanimation
        path="/reanimation-hospitalisation-sortie/:id"
        component={LayoutReanimationHospitalisationConsultationSortie}
        />

        <MyPrivateRouters.PrivateRouterReanimation
        path="/reanimation-feuille-de-soins/:id"
        component={LayoutReanimationFeuilleDeSoins}
        />

        <MyPrivateRouters.PrivateRouterReanimation
        path="/reanimation-consultation-sortie/:id"
        component={LayoutCreateConsultationSortieReanimation}
        />

        <MyPrivateRouters.PrivateRouterReanimation
        path="/reanimation-consultation-sortie-demande-hospitalisation/:id"
        component={
            LayoutCreateConsultationSortieDemandeHospitalisationReanimation
        }
        />

        <MyPrivateRouters.PrivateRouterReanimation
        path="/reanimation-consultation-sortie-mise-en-observation/:id"
        component={
            LayoutCreateConsultationSortieMiseEnObservationReanimation
        }
        />

        <MyPrivateRouters.PrivateRouterReanimation
        path="/reanimation-consultation-sortie-refere-interne/:id"
        component={LayoutCreateConsultationRefereInterneReanimation}
        />

        <MyPrivateRouters.PrivateRouterReanimation
        path="/reanimation-consultation-sortie-refere-externe/:id"
        component={LayoutCreateConsultationRefereExterneReanimation}
        />

        <MyPrivateRouters.PrivateRouterReanimation
        path="/reanimation-consultation-sortie-issue-deces/:id"
        component={LayoutReanimationDecesConsultationSortie}
        />

        <MyPrivateRouters.PrivateRouterReanimation
        path="/reanimation-consultation-sortie-rdv/:id"
        component={LayoutReanimationCreateConsultationRendezVous}
        />

        <MyPrivateRouters.PrivateRouterReanimation
        path="/reanimation-exit-observation/:id"
        component={LayoutCreateConsultationSortieReanimation}
        />

        <MyPrivateRouters.PrivateRouterReanimation
        path="/reanimation-observation-consultation/:id"
        component={LayoutReanimationCreateConsultation}
        />

        <MyPrivateRouters.PrivateRouterReanimation
        path="/reanimation-observation-exit-demande-hospitalisation/:id"
        component={DemandeHospiObservationReanimation}
        />

        <MyPrivateRouters.PrivateRouterReanimation
        path="/reanimation-observation-exit-reference-externe/:id"
        component={RefExterneObservationReanimation}
        />

        <MyPrivateRouters.PrivateRouterReanimation
        path="/reanimation-observation-exit-reference-interne/:id"
        component={RefInterneObservationReanimation}
        />

        <MyPrivateRouters.PrivateRouterReanimation
        path="/reanimation-observation-exit-deces/:id"
        component={LayoutReanimationDecesConsultationSortie}
        />

        <MyPrivateRouters.PrivateRouterReanimation
        path="/reanimation-observation-exit-rdv/:id"
        component={LayoutReanimationCreateConsultationRendezVous}
        />

        <MyPrivateRouters.PrivateRouterReanimation
        path="/reanimation-examens-detail/:id/"
        component={ReanimationExamensDetail}
        />

        <MyPrivateRouters.PrivateRouterReanimation
        path="/reanimation-examens"
        component={ReanimationExamensList}
        />

        <MyPrivateRouters.PrivateRouterReanimation
        path="/reanimation-patients-decedes"
        component={LayoutReanimationDecesListe}
        />

        <MyPrivateRouters.PrivateRouterReanimation
        path="/reanimation-referencements-externe"
        component={ReanimationRefExterne}
        />

        <MyPrivateRouters.PrivateRouterReanimation
        path="/reanimation-referencements-interne"
        component={ReanimationRefInterne}
        />

        <MyPrivateRouters.PrivateRouterReanimation
        path="/reanimation-patients-decedes-certificat/:id"
        component={LayoutReanimationDecesCertificat}
        />

        <MyPrivateRouters.PrivateRouterReanimation
        path="/reanimation-examens-resultat-voir/:id"
        component={LayoutVoirReanimationResultatsExamens}
        />

        <MyPrivateRouters.PrivateRouterReanimation
        path="/reanimation-rapports"
        component={RapportReanimation}
        />

        <MyPrivateRouters.PrivateRouterReanimation
        path="/reanimation-controle-major"
        component={LayoutReanimationControleMajor}
        />

    </Switch>
  )
}
