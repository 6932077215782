import { cartesTypes } from "../../types/cartesTypes";

const initialState = {
  isLoading: false,
  data: [],
  error: "",
};

export const cartesReducer = (state = initialState, action) => {
  switch (action.type) {
    case cartesTypes.CARTE_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: "",
      };
    case cartesTypes.CARTE_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        error: "",
      };
    case cartesTypes.CARTE_LIST_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
