export const motifsCentreTypes = {
    MOTIFS_LIST_LOADING: "MOTIFS_LIST_LOADING",
    MOTIFS_LIST_SUCCESS: "MOTIFS_LIST_SUCCESS",
    MOTIFS_LIST_FAILED: "MOTIFS_LIST_FAILED",

    MOTIFS_CENTRE_LIST_LOADING: "MOTIFS_CENTRE_LIST_LOADING",
    MOTIFS_CENTRE_LIST_SUCCESS: "MOTIFS_CENTRE_LIST_SUCCESS",
    MOTIFS_CENTRE_LIST_FAILED: "MOTIFS_CENTRE_LIST_FAILED",

    MOTIFS_CENTRE_LIST_PAR_ASSURANCE_LOADING: "MOTIFS_CENTRE_LIST_PAR_ASSURANCE_LOADING",
    MOTIFS_CENTRE_LIST_PAR_ASSURANCE_SUCCESS: "MOTIFS_CENTRE_LIST_PAR_ASSURANCE_SUCCESS",
    MOTIFS_CENTRE_LIST_PAR_ASSURANCE_FAILED: "MOTIFS_CENTRE_LIST_PAR_ASSURANCE_FAILED",

    MOTIFS_CENTRE_LIST_BY_SERVICE_LOADING: "MOTIFS_CENTRE_LIST_BY_SERVICE_LOADING",
    MOTIFS_CENTRE_LIST_BY_SERVICE_SUCCESS: "MOTIFS_CENTRE_LIST_BY_SERVICE_SUCCESS",
    MOTIFS_CENTRE_LIST_BY_SERVICE_FAILED: "MOTIFS_CENTRE_LIST_BY_SERVICE_FAILED",

    MOTIFS_CENTRE_LIST_REFRESH_LOADING: "MOTIFS_CENTRE_LIST_REFRESH_LOADING",
    MOTIFS_CENTRE_LIST_REFRESH_SUCCESS: "MOTIFS_CENTRE_LIST_REFRESH_SUCCESS",
    MOTIFS_CENTRE_LIST_REFRESH_FAILED: "MOTIFS_CENTRE_LIST_REFRESH_FAILED",

    MOTIF_CENTRE_GET_LOADING: "MOTIF_CENTRE_GET_LOADING",
    MOTIF_CENTRE_GET_SUCCESS: "MOTIF_CENTRE_GET_SUCCESS",
    MOTIF_CENTRE_GET_FAILED: "MOTIF_CENTRE_GET_FAILED",

    MOTIF_CENTRE_POST_LOADING: "MOTIF_CENTRE_POST_LOADING",
    MOTIF_CENTRE_POST_SUCCESS: "MOTIF_CENTRE_POST_SUCCESS",
    MOTIF_CENTRE_POST_FAILED: "MOTIF_CENTRE_POST_FAILED",

    MOTIF_CENTRE_UPDATE_LOADING: "MOTIF_CENTRE_UPDATE_LOADING",
    MOTIF_CENTRE_UPDATE_SUCCESS: "MOTIF_CENTRE_UPDATE_SUCCESS",
    MOTIF_CENTRE_UPDATE_FAILED: "MOTIF_CENTRE_UPDATE_FAILED",

    MOTIF_CENTRE_DELETE_LOADING: "MOTIF_CENTRE_DELETE_LOADING",
    MOTIF_CENTRE_DELETE_SUCCESS: "MOTIF_CENTRE_DELETE_SUCCESS",
    MOTIF_CENTRE_DELETE_FAILED: "MOTIF_CENTRE_DELETE_FAILED",
    MOTIF_CENTRE_DELETE_IMPOSSIBLE: "MOTIF_CENTRE_DELETE_IMPOSSIBLE",

    MOTIF_CENTRE_CLEAR_STATE: "MOTIF_CENTRE_CLEAR_STATE"
}