import { fichePaiementTypes } from "../types/fichePaiementTypes"
import { AUTH_ERROR, LOGOUT_SUCCESS, TOKEN_REFRESH_FAIL } from "../types/userTypes"

const initialState = {
  isLoadingCaisseOuvert: false,
  isCaisseOuvert: false,
  isErrorCaisseOuvert: false,
  message: "",

  isLoadingCaisseOuvertPost: false,
  isCaisseOuvertPost: false,
  isErrorCaisseOuvertPost: false,
  
  isLoadingCaisseOuvertPut: false,
  isCaisseOuvertPut: false,
  isErrorCaisseOuvertPut: false,
}

//eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    
    ////////////////////check caisse ouvert//////////////////////////////
    ////////////////////check caisse ouvert//////////////////////////////
    case fichePaiementTypes.GET_CHECK_CAISSE_OUVERT_LOADING:
      return {
        ...state,
        isLoadingCaisseOuvert: true,
        isErrorCaisseOuvert: false,
        message: "",
        error: "",
      }
    case fichePaiementTypes.GET_CHECK_CAISSE_OUVERT_SUCCESS:
      return {
        ...state,
        isLoadingCaisseOuvert: false,
        isCaisseOuvert: true,
        isErrorCaisseOuvert: false,
        message: action.payload,
        error: "",
      }
    case fichePaiementTypes.GET_CHECK_CAISSE_OUVERT_FAILED:
      return {
        ...state,
        isLoadingCaisseOuvert: false,
        isCaisseOuvert: false,
        isErrorCaisseOuvert: true,
        message: "",
        error: action.payload,
      }

    case fichePaiementTypes.POST_CHECK_CAISSE_OUVERT_LOADING:
      return {
        ...state,
        isLoadingCaisseOuvertPost: true,
        isCaisseOuvertPost: false,
        isErrorCaisseOuvertPost: false,
        message: "",
        error: "",
      }
    case fichePaiementTypes.POST_CHECK_CAISSE_OUVERT_SUCCESS:
      return {
        ...state,
        isLoadingCaisseOuvertPost: false,
        isCaisseOuvertPost: true,
        isErrorCaisseOuvertPost: false,
        message: action.payload,
        error: "",
      }
    case fichePaiementTypes.POST_CHECK_CAISSE_OUVERT_FAILED:
      return {
        ...state,
        isLoadingCaisseOuvertPost: false,
        isCaisseOuvertPost: false,
        isErrorCaisseOuvertPost: true,
        message: "",
        error: action.payload,
      }

      case fichePaiementTypes.UPDATE_CHECK_CAISSE_PROLONGER_HEURE_FERMETURE_LOADING:
        return {
          ...state,
          isLoadingCaisseOuvertPut: true,
          isCaisseOuvertPut: false,
          isErrorCaisseOuvertPut: false,
          error: "",
        }
      case fichePaiementTypes.UPDATE_CHECK_CAISSE_PROLONGER_HEURE_FERMETURE_SUCCESS:
        return {
          ...state,
          isLoadingCaisseOuvertPut: false,
          isCaisseOuvertPut: true,
          isErrorCaisseOuvertPut: false,
          message: action.payload,
          error: "",
        }
      case fichePaiementTypes.UPDATE_CHECK_CAISSE_PROLONGER_HEURE_FERMETURE_FAILED:
        return {
          ...state,
          isLoadingCaisseOuvertPut: false,
          isCaisseOuvertPut: false,
          isErrorCaisseOuvertPut: true,
          error: action.payload,
        }
    ////////////////////check caisse ouvert//////////////////////////////
    ////////////////////check caisse ouvert//////////////////////////////


    case fichePaiementTypes.FICHEPAIEMENT_SET_SUCCESS:
            return {
                ...state,
                isLoadingCaisseOuvert: false,
                isErrorCaisseOuvert: false,

                isLoadingCaisseOuvertPost: false,
                isCaisseOuvertPost: false,
                isErrorCaisseOuvertPost: false,

                isLoadingCaisseOuvertPut: false,
                isCaisseOuvertPut: false,
                isErrorCaisseOuvertPut: false,
            }

    case AUTH_ERROR:
    case LOGOUT_SUCCESS:
    case TOKEN_REFRESH_FAIL:
        localStorage.removeItem('token_access')
        localStorage.removeItem('token_refresh')
        return {
            ...state,
            isLoadingCaisseOuvert: false,
            isCaisseOuvert: false,
            isErrorCaisseOuvert: false,
            message: "",

            isLoadingCaisseOuvertPost: false,
            isCaisseOuvertPost: false,
            isErrorCaisseOuvertPost: false,
        }

    default:
      return state
  }
}