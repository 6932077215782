import React, { lazy } from "react"
import * as MyPrivateRouters from "../routes/PrivateRouter";
import { Switch } from 'react-router-dom';


const LayoutParametrageGlobaleMotifsGlobalesListe = lazy(() =>
    import(
        "../../components/parametrageGlobale/motifsPaiementsGlobales/liste/LayoutParametrageGlobaleMotifsGlobalesListe"
    )
);
const LayoutParametrageGlobaleMotifsGlobalesCreate = lazy(() =>
    import(
        "../../components/parametrageGlobale/motifsPaiementsGlobales/create/LayoutParametrageGlobaleMotifsGlobalesCreate"
    )
);
const LayoutParametrageGlobaleMotifsGlobalesUpdate = lazy(() =>
    import(
        "../../components/parametrageGlobale/motifsPaiementsGlobales/update/LayoutParametrageGlobaleMotifsGlobalesUpdate"
    )
);


const LayoutParametrageGlobaleAssurancesGlobalesListe = lazy(() =>
    import(
        "../../components/parametrageGlobale/assurancesGlobales/liste/LayoutParametrageGlobaleAssurancesGlobalesListe"
    )
);
const LayoutParametrageGlobaleAssurancesGlobalesCreate = lazy(() =>
    import(
        "../../components/parametrageGlobale/assurancesGlobales/create/LayoutParametrageGlobaleAssurancesGlobalesCreate"
    )
);
const LayoutParametrageGlobaleAssurancesGlobalesUpdate = lazy(() =>
    import(
        "../../components/parametrageGlobale/assurancesGlobales/update/LayoutParametrageGlobaleAssurancesGlobalesUpdate"
    )
);


const LayoutParametrageGlobaleCommunesListe = lazy(() =>
    import(
        "../../components/parametrageGlobale/communes/liste/LayoutParametrageGlobaleCommunesListe"
    )
);
const LayoutParametrageGlobaleCommunesCreate = lazy(() =>
    import(
        "../../components/parametrageGlobale/communes/create/LayoutParametrageGlobaleCommunesCreate"
    )
);
const LayoutParametrageGlobaleCommunesUpdate = lazy(() =>
    import(
        "../../components/parametrageGlobale/communes/update/LayoutParametrageGlobaleCommunesUpdate"
    )
);


const LayoutParametrageGlobaleDistrictsSanitairesListe = lazy(() =>
    import(
        "../../components/parametrageGlobale/districtsSanitaires/liste/LayoutParametrageGlobaleDistrictsSanitairesListe"
    )
);
const LayoutParametrageGlobaleDistrictsSanitairesCreate = lazy(() =>
    import(
        "../../components/parametrageGlobale/districtsSanitaires/create/LayoutParametrageGlobaleDistrictsSanitairesCreate"
    )
);
const LayoutParametrageGlobaleDistrictsSanitairesUpdate = lazy(() =>
    import(
        "../../components/parametrageGlobale/districtsSanitaires/update/LayoutParametrageGlobaleDistrictsSanitairesUpdate"
    )
);


const LayoutParametrageGlobaleRegionsSanitairesListe = lazy(() =>
    import(
        "../../components/parametrageGlobale/regionsSanitaires/liste/LayoutParametrageGlobaleRegionsSanitairesListe"
    )
);
const LayoutParametrageGlobaleRegionsSanitairesCreate = lazy(() =>
    import(
        "../../components/parametrageGlobale/regionsSanitaires/create/LayoutParametrageGlobaleRegionsSanitairesCreate"
    )
);
const LayoutParametrageGlobaleRegionsSanitairesUpdate = lazy(() =>
    import(
        "../../components/parametrageGlobale/regionsSanitaires/update/LayoutParametrageGlobaleRegionsSanitairesUpdate"
    )
);


const LayoutParametrageGlobaleServicesListe = lazy(() =>
    import(
        "../../components/parametrageGlobale/servicesGlobales/liste/LayoutParametrageGlobaleServicesListe"
    )
);
const LayoutParametrageGlobaleServicesCreate = lazy(() =>
    import(
        "../../components/parametrageGlobale/servicesGlobales/create/LayoutParametrageGlobaleServicesCreate"
    )
);
const LayoutParametrageGlobaleServicesUpdate = lazy(() =>
    import(
        "../../components/parametrageGlobale/servicesGlobales/update/LayoutParametrageGlobaleServicesUpdate"
    )
);



const LayoutParametrageGlobaleSpecialitesListe = lazy(() =>
    import(
        "../../components/parametrageGlobale/specialitesGlobales/liste/LayoutParametrageGlobaleSpecialitesListe"
    )
);
const LayoutParametrageGlobaleSpecialitesCreate = lazy(() =>
    import(
        "../../components/parametrageGlobale/specialitesGlobales/create/LayoutParametrageGlobaleSpecialitesCreate"
    )
);
const LayoutParametrageGlobaleSpecialitesUpdate = lazy(() =>
    import(
        "../../components/parametrageGlobale/specialitesGlobales/update/LayoutParametrageGlobaleSpecialitesUpdate"
    )
);


const LayoutParametrageGlobaleVillesListe = lazy(() =>
    import(
        "../../components/parametrageGlobale/villes/liste/LayoutParametrageGlobaleVillesListe"
    )
);
const LayoutParametrageGlobaleVillesCreate = lazy(() =>
    import(
        "../../components/parametrageGlobale/villes/create/LayoutParametrageGlobaleVillesCreate"
    )
);
const LayoutParametrageGlobaleVillesUpdate = lazy(() =>
    import(
        "../../components/parametrageGlobale/villes/update/LayoutParametrageGlobaleVillesUpdate"
    )
);


const LayoutParametrageGlobaleSituationMatrimonialeListe = lazy(() =>
    import(
        "../../components/parametrageGlobale/situationMatrimoniale/liste/LayoutParametrageGlobaleSituationMatrimonialeListe"
    )
);
const LayoutParametrageGlobaleSituationMatrimonialeCreate = lazy(() =>
    import(
        "../../components/parametrageGlobale/situationMatrimoniale/create/LayoutParametrageGlobaleSituationMatrimonialeCreate"
    )
);
const LayoutParametrageGlobaleSituationMatrimonialeUpdate = lazy(() =>
    import(
        "../../components/parametrageGlobale/situationMatrimoniale/update/LayoutParametrageGlobaleSituationMatrimonialeUpdate"
    )
);


const LayoutParametrageGlobaleNiveauEtudeListe = lazy(() =>
    import(
        "../../components/parametrageGlobale/niveauEtude/liste/LayoutParametrageGlobaleNiveauEtudeListe"
    )
);
const LayoutParametrageGlobaleNiveauEtudeCreate = lazy(() =>
    import(
        "../../components/parametrageGlobale/niveauEtude/create/LayoutParametrageGlobaleNiveauEtudeCreate"
    )
);
const LayoutParametrageGlobaleNiveauEtudeUpdate = lazy(() =>
    import(
        "../../components/parametrageGlobale/niveauEtude/update/LayoutParametrageGlobaleNiveauEtudeUpdate"
    )
);

const LayoutParametrageGlobaleRolesListe = lazy(() =>
    import(
        "../../components/parametrageGlobale/roles/LayoutParametrageGlobaleRolesListe"
    )
);

const LayoutParametrageGlobaleCodeServicesListe = lazy(() =>
    import(
        "../../components/parametrageGlobale/codeServices/LayoutParametrageGlobaleCodeServicesListe"
    )
);

const LayoutParametrageGlobaleMaladieListe = lazy(() =>
    import(
        "../../components/parametrageGlobale/maladies/liste/LayoutParametrageGlobaleMaladieListe"
    )
);
const LayoutParametrageGlobaleMaladieCreate = lazy(() =>
    import(
        "../../components/parametrageGlobale/maladies/create/LayoutParametrageGlobaleMaladieCreate"
    )
);
const LayoutParametrageGlobaleMaladieUpdate = lazy(() =>
    import(
        "../../components/parametrageGlobale/maladies/update/LayoutParametrageGlobaleMaladieUpdate"
    )
);
  

export default function ParametrageGlobaleRouter() {
  return (
    <Switch>
        <MyPrivateRouters.PrivateRouterParametrageGlobale
        path="/parametrage-globale"
        component={LayoutParametrageGlobaleMotifsGlobalesListe}
        />

        <MyPrivateRouters.PrivateRouterParametrageGlobale
        path="/parametrage-globale-prestations-globales"
        component={LayoutParametrageGlobaleMotifsGlobalesListe}
        />

        <MyPrivateRouters.PrivateRouterParametrageGlobale
        path="/parametrage-globale-prestations-globales-create"
        component={LayoutParametrageGlobaleMotifsGlobalesCreate}
        />

        <MyPrivateRouters.PrivateRouterParametrageGlobale
        path="/parametrage-globale-prestations-globales-update/:id"
        component={LayoutParametrageGlobaleMotifsGlobalesUpdate}
        />

        <MyPrivateRouters.PrivateRouterParametrageGlobale
        path="/parametrage-globale-assurances-globales"
        component={LayoutParametrageGlobaleAssurancesGlobalesListe}
        />

        <MyPrivateRouters.PrivateRouterParametrageGlobale
        path="/parametrage-globale-assurances-globales-create"
        component={LayoutParametrageGlobaleAssurancesGlobalesCreate}
        />

        <MyPrivateRouters.PrivateRouterParametrageGlobale
        path="/parametrage-globale-assurances-globales-update/:id"
        component={LayoutParametrageGlobaleAssurancesGlobalesUpdate}
        />

        <MyPrivateRouters.PrivateRouterParametrageGlobale
        path="/parametrage-globale-codes-services"
        component={LayoutParametrageGlobaleCodeServicesListe}
        />

        <MyPrivateRouters.PrivateRouterParametrageGlobale
        path="/parametrage-globale-services-globales"
        component={LayoutParametrageGlobaleServicesListe}
        />

        <MyPrivateRouters.PrivateRouterParametrageGlobale
        path="/parametrage-globale-services-globales-create"
        component={LayoutParametrageGlobaleServicesCreate}
        />

        <MyPrivateRouters.PrivateRouterParametrageGlobale
        path="/parametrage-globale-services-globales-update/:id"
        component={LayoutParametrageGlobaleServicesUpdate}
        />

        <MyPrivateRouters.PrivateRouterParametrageGlobale
        path="/parametrage-globale-roles"
        component={LayoutParametrageGlobaleRolesListe}
        />

        <MyPrivateRouters.PrivateRouterParametrageGlobale
        path="/parametrage-globale-specialites-globales"
        component={LayoutParametrageGlobaleSpecialitesListe}
        />

        <MyPrivateRouters.PrivateRouterParametrageGlobale
        path="/parametrage-globale-specialites-globales-create"
        component={LayoutParametrageGlobaleSpecialitesCreate}
        />

        <MyPrivateRouters.PrivateRouterParametrageGlobale
        path="/parametrage-globale-specialites-globales-update/:id"
        component={LayoutParametrageGlobaleSpecialitesUpdate}
        />

        <MyPrivateRouters.PrivateRouterParametrageGlobale
        path="/parametrage-globale-region-sanitaire"
        component={LayoutParametrageGlobaleRegionsSanitairesListe}
        />

        <MyPrivateRouters.PrivateRouterParametrageGlobale
        path="/parametrage-globale-region-sanitaire-create"
        component={LayoutParametrageGlobaleRegionsSanitairesCreate}
        />

        <MyPrivateRouters.PrivateRouterParametrageGlobale
        path="/parametrage-globale-region-sanitaire-update/:id"
        component={LayoutParametrageGlobaleRegionsSanitairesUpdate}
        />

        <MyPrivateRouters.PrivateRouterParametrageGlobale
        path="/parametrage-globale-district-sanitaire"
        component={LayoutParametrageGlobaleDistrictsSanitairesListe}
        />

        <MyPrivateRouters.PrivateRouterParametrageGlobale
        path="/parametrage-globale-district-sanitaire-create"
        component={LayoutParametrageGlobaleDistrictsSanitairesCreate}
        />

        <MyPrivateRouters.PrivateRouterParametrageGlobale
        path="/parametrage-globale-district-sanitaire-update/:id"
        component={LayoutParametrageGlobaleDistrictsSanitairesUpdate}
        />

        <MyPrivateRouters.PrivateRouterParametrageGlobale
        path="/parametrage-globale-villes"
        component={LayoutParametrageGlobaleVillesListe}
        />

        <MyPrivateRouters.PrivateRouterParametrageGlobale
        path="/parametrage-globale-villes-create"
        component={LayoutParametrageGlobaleVillesCreate}
        />

        <MyPrivateRouters.PrivateRouterParametrageGlobale
        path="/parametrage-globale-villes-update/:id"
        component={LayoutParametrageGlobaleVillesUpdate}
        />

        <MyPrivateRouters.PrivateRouterParametrageGlobale
        path="/parametrage-globale-commune"
        component={LayoutParametrageGlobaleCommunesListe}
        />

        <MyPrivateRouters.PrivateRouterParametrageGlobale
        path="/parametrage-globale-commune-create"
        component={LayoutParametrageGlobaleCommunesCreate}
        />

        <MyPrivateRouters.PrivateRouterParametrageGlobale
        path="/parametrage-globale-commune-update/:id"
        component={LayoutParametrageGlobaleCommunesUpdate}
        />

        <MyPrivateRouters.PrivateRouterParametrageGlobale
        path="/parametrage-globale-situation-matrimoniale"
        component={LayoutParametrageGlobaleSituationMatrimonialeListe}
        />

        <MyPrivateRouters.PrivateRouterParametrageGlobale
        path="/parametrage-globale-situation-matrimoniale-create"
        component={LayoutParametrageGlobaleSituationMatrimonialeCreate}
        />

        <MyPrivateRouters.PrivateRouterParametrageGlobale
        path="/parametrage-globale-situation-matrimoniale-update/:id"
        component={LayoutParametrageGlobaleSituationMatrimonialeUpdate}
        />

        <MyPrivateRouters.PrivateRouterParametrageGlobale
        path="/parametrage-globale-niveau-etude"
        component={LayoutParametrageGlobaleNiveauEtudeListe}
        />

        <MyPrivateRouters.PrivateRouterParametrageGlobale
        path="/parametrage-globale-niveau-etude-create"
        component={LayoutParametrageGlobaleNiveauEtudeCreate}
        />

        <MyPrivateRouters.PrivateRouterParametrageGlobale
        path="/parametrage-globale-niveau-etude-update/:id"
        component={LayoutParametrageGlobaleNiveauEtudeUpdate}
        />

        <MyPrivateRouters.PrivateRouterParametrageGlobale
        path="/parametrage-globale-maladie"
        component={LayoutParametrageGlobaleMaladieListe}
        />

        <MyPrivateRouters.PrivateRouterParametrageGlobale
        path="/parametrage-globale-maladie-create"
        component={LayoutParametrageGlobaleMaladieCreate}
        />

        <MyPrivateRouters.PrivateRouterParametrageGlobale
        path="/parametrage-globale-maladie-update/:id"
        component={LayoutParametrageGlobaleMaladieUpdate}
        />
    </Switch>
  )
}
