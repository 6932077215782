import React, { lazy } from "react"
import * as MyPrivateRouters from "../routes/PrivateRouter";
import { Switch } from 'react-router-dom';


const LayoutMaterniteSuiteCoucheSansSortieInterne = lazy(() =>
    import(
      "../../components/maternite/SuiteCouche/SansComplication/issue/ReferenceInterne/LayoutMaterniteSuiteCoucheSansReferenceInter"
    )
);

const LayoutMaterniteSuiteCoucheSansSortieExterne = lazy(() =>
    import(
        "../../components/maternite/SuiteCouche/SansComplication/issue/ReferenceExterne/LayoutMaterniteSuiteCoucheSansReferenceExterne"
    )
);

const LayoutMaterniteSuiteCoucheSansSortieDemandeHospitalisation = lazy(() =>
    import(
        "../../components/maternite/SuiteCouche/SansComplication/issue/DemandeHospitalisation/LayoutMaterniteSuiteCoucheSansDemandeHospitalisation"
    )
);

const LayoutVoirConsultationMaterniteResultatsExamens = lazy(() =>
    import(
        "../../components/maternite/examens/ExamensConsultation/resultatsExamensVoir/LayoutVoirMedecineGeneralResultatsExamens"
    )
);
const LayoutVoirCPNMaterniteResultatsExamens = lazy(() =>
    import(
        "../../components/maternite/examens/ExamensCPN/resultatsExamensVoir/LayoutVoirMedecineGeneralResultatsExamens"
    )
);
const LayoutVoirCPONMaterniteResultatsExamens = lazy(() =>
    import(
        "../../components/maternite/examens/ExamensCPON/resultatsExamensVoir/LayoutVoirMedecineGeneralResultatsExamens"
    )
);

const LayoutMaterniteAccouchement2Issue = lazy(() =>
    import(
        "../../components/maternite/Accouchement/Issue/LayoutMaterniteAccouchementSortie"
    )
);

const LayoutMaterniteAccouchement2IssueEvacuation = lazy(() =>
    import(
        "../../components/maternite/Accouchement/Issue/Evacuation/LayoutMaterniteAccouchementSortieEvacuationCreate"
    )
);

const LayoutMaterniteAccouchement2IssueDeces = lazy(() =>
    import(
        "../../components/maternite/Accouchement/Issue/IssueDeDeces/LayoutMaterniteAccouchementSortieDecesCreate"
    )
);

const LayoutMaterniteAccouchement2IssueSuiteCouche = lazy(() =>
    import(
        "../../components/maternite/Accouchement/Issue/SuiteDeCouche/LayoutMaterniteSuiteCouche"
    )
);

const RefExterneSCCLayout = lazy(() =>
    import(
      "../../components/maternite/SuiteCouche/AvecComplication/Issues/RefExterneSCCLayout"
    )
);

const LayoutMaterniteCpnSortieRdvCreate = lazy(() =>
    import(
        "../../components/maternite/gestante/issuSorties/issuCPN/RendezVous/LayoutMaterniteCpnSortieRdvCreate"
    )
);

const RendezVousSCCLayout = lazy(() =>
    import(
        "../../components/maternite/SuiteCouche/AvecComplication/Issues/RendezVousSCCLayout"
    )
);

const LayoutMaterniteAccouchementSortie = lazy(() =>
    import(
        "../../components/maternite/gestante/issuSorties/LayoutMaterniteAccouchementSortie"
    )
);
const LayoutMaterniteAccouchementSortieDecesCreate = lazy(() =>
    import(
        "../../components/maternite/gestante/issuSorties/issuAccouchement/IssueDeDeces/LayoutMaterniteAccouchementSortieDecesCreate"
    )
);
const LayoutMaterniteAccouchementSortieEvacuationCreate = lazy(() =>
    import(
        "../../components/maternite/gestante/issuSorties/issuAccouchement/Evacuation/LayoutMaterniteAccouchementSortieEvacuationCreate"
    )
);

const LayoutMaternteSuiteCouche = lazy(() =>
    import(
        "../../components/maternite/gestante/issuSorties/issuAccouchement/SuiteDeCouche/LayoutMaterniteSuiteCouche"
    )
);

const LayoutMaterniteAccouchementDetails = lazy(() =>
    import(
      "../../components/maternite/Accouchement/VoirPlus/LayoutMaterniteAccouchementDetails"
    )
);
const LayoutMaterniteConsultationPostnataleDetails = lazy(() =>
    import(
        "../../components/maternite/consultationPostnatale/VoirPlus/LayoutMaterniteConsultationPostnataleDetails"
    )
);

const LayoutOrdonnancePostnataleListe = lazy(() =>
    import(
        "../../components/maternite/ordonnances/consultationsPostnatales/Liste/LayoutOrdonnancePostnataleListe"
    )
);
const LayoutOrdonnancePrenataleListe = lazy(() =>
    import(
        "../../components/maternite/ordonnances/consultationsPrenatales/Liste/LayoutOrdonnancePrenataleListe"
    )
);
const LayoutOrdonnanceMereListe = lazy(() =>
    import(
        "../../components/maternite/ordonnances/ordonnancesMeres/Liste/LayoutOrdonnanceMereListe"
    )
);
const LayoutOrdonnanceEnfantListe = lazy(() =>
    import(
        "../../components/maternite/ordonnances/ordonnancesEnfants/Liste/LayoutOrdonnanceEnfantListe"
    )
);
const LayoutOrdonnanceListe = lazy(() =>
    import(
        "../../components/maternite/ordonnances/consultations/Liste/LayoutOrdonnanceListe"
    )
);

const LayoutOrdonnancePostnataleFormView = lazy(() =>
    import(
        "../../components/maternite/ordonnances/consultationsPostnatales/Form/LayoutOrdonnancePostnataleFormView"
    )
);
const LayoutOrdonnancePrenataleFormView = lazy(() =>
    import(
        "../../components/maternite/ordonnances/consultationsPrenatales/Form/LayoutOrdonnancePrenataleFormView"
    )
);
const LayoutOrdonnanceMereFormView = lazy(() =>
    import(
        "../../components/maternite/ordonnances/ordonnancesMeres/Form/LayoutOrdonnanceMereFormView"
    )
);
const LayoutOrdonnanceEnfantFormView = lazy(() =>
    import(
        "../../components/maternite/ordonnances/ordonnancesEnfants/Form/LayoutOrdonnanceEnfantFormView"
    )
);
const LayoutOrdonnanceFormView = lazy(() =>
    import(
        "../../components/maternite/ordonnances/consultations/Form/LayoutOrdonnanceFormView"
    )
);

const LayoutExamensCPONDetail = lazy(() =>
    import("../../components/maternite/examens/ExamensCPON/detail")
);
const LayoutExamensCPONList = lazy(() =>
    import("../../components/maternite/examens/ExamensCPON/list")
);

const LayoutExamensCPNDetail = lazy(() =>
    import("../../components/maternite/examens/ExamensCPN/detail")
);
const LayoutExamensCPNList = lazy(() =>
    import("../../components/maternite/examens/ExamensCPN/list")
);

const LayoutExamensConsultationDetail = lazy(() =>
    import("../../components/maternite/examens/ExamensConsultation/detail")
);
const LayoutExamensConsultationList = lazy(() =>
    import("../../components/maternite/examens/ExamensConsultation/list")
);
const LayoutMaterniteDecesListe = lazy(() =>
    import("../../components/maternite/Deces/Liste/LayoutMaterniteDecesListe")
);
const LayoutMaterniteDecesCertificat = lazy(() =>
    import(
        "../../components/maternite/Deces/Certificat/LayoutMaterniteDecesCertificat"
    )
);

const LayoutMaterniteCreateConsultation = lazy(() =>
    import(
        "../../components/maternite/patientAConsulterCreate/LayoutCreateConsultation"
    )
);
const LayoutMaterniteCreateConsultationSortie = lazy(() =>
    import(
        "../../components/maternite/patientAConsulterIssuSortie/LayoutCreateConsultationSortie"
    )
);

const LayoutMaterniteListeRefInterne = lazy(() =>
    import(
        "../../components/maternite/refInterne/LayoutMaterniteListeRefInterne"
    )
);

const LayoutMaterniteListeRefExterne = lazy(() =>
    import(
        "../../components/maternite/refExterne/LayoutMaterniteListeRefExterne"
    )
);

const LayoutCreateConsultationSortieDemandeHospitalisationMaternite = lazy(() =>
    import(
        "../../components/maternite/patientAConsulterIssuSortie/DemandeHospitalisation/LayoutCreateConsultationSortieDemandeHospitalisationMaternite"
    )
);

const LayoutCreateMaterniteConsultationSortieMiseEnObservation = lazy(() =>
    import(
        "../../components/maternite/patientAConsulterIssuSortie/MiseEnObservation/LayoutCreateConsultationSortieMiseEnObservation"
    )
);

const LayoutCreateMaterniteConsultationRefereInterne = lazy(() =>
    import(
        "../../components/maternite/patientAConsulterIssuSortie/RefereInterne/LayoutCreateConsultationRefereInterneCardiologie"
    )
);

const LayoutCreateMaterniteConsultationRefereExterne = lazy(() =>
    import(
        "../../components/maternite/patientAConsulterIssuSortie/RefereExterne/LayoutCreateConsultationRefereExterneCardiologie"
    )
);

const LayoutMaterniteDecesConsultationSortie = lazy(() =>
    import(
        "../../components/maternite/patientAConsulterIssuSortie/IssueDeDeces/LayoutMaterniteDecesConsultationSortie"
    )
);

const DecesSCCLayout = lazy(() =>
    import(
        "../../components/maternite/SuiteCouche/AvecComplication/Issues/DecesSCCLayout"
    )
);

const LayoutMaterniteCreateConsultationRendezVous = lazy(() =>
    import(
        "../../components/maternite/patientAConsulterIssuSortie/RendezVous/LayoutMaterniteCreateConsultationRendezVous"
    )
);

const LayoutMaterniteRdvListe = lazy(() =>
    import("../../components/maternite/rendezVous/Liste/LayoutMaterniteRdvListe")
);

const AgendaMaternite = lazy(() =>
    import("../../components/maternite/rendezVous/agendaDetail/AgendaCardiologie")
);

const LayoutMaterniteCPNSortie = lazy(() =>
    import("../../components/maternite/gestante/issuSorties/LayoutMaterniteCPNSortie")
);
const LayoutMaterniteCpnSortieDemandeHospitalisationCreate = lazy(() =>
    import(
        "../../components/maternite/gestante/issuSorties/issuCPN/DemandeHospitalisation/LayoutMaterniteCpnSortieDemandeHospitalisationCreate"
    )
);
const LayoutMaterniteCpnSortieRefereInterneCreate = lazy(() =>
    import(
        "../../components/maternite/gestante/issuSorties/issuCPN/RefereInterne/LayoutMaterniteCpnSortieRefereInterneCreate"
    )
);
const LayoutMaterniteCpnSortieRefereExterneCreate = lazy(() =>
    import(
        "../../components/maternite/gestante/issuSorties/issuCPN/RefereExterne/LayoutMaterniteCpnSortieRefereExterneCreate"
    )
);

const LayoutMaterniteAccouchementCreate = lazy(() =>
    import(
      "../../components/maternite/gestante/Accouchement/Create/LayoutMaterniteAccouchementCreate"
    )
);
const LayoutMaterniteConsultationPrenataleCreate = lazy(() =>
    import(
        "../../components/maternite/gestante/Prenatale/Create/LayoutMaterniteConsultationPrenataleCreate"
    )
);
const LayoutMaterniteConsultationPostnataleCreate = lazy(() =>
    import(
        "../../components/maternite/gestante/Postnatale/Create/LayoutMaterniteConsultationPostnataleCreate"
    )
);

const LayoutMaternitePtmeMere = lazy(() =>
    import("../../components/maternite/ptmeMere/Form/LayoutMaternitePtmeMere")
);
const LayoutMaternitePtmeEnfant = lazy(() =>
    import("../../components/maternite/ptmeEnfant/Form/LayoutMaternitePtmeEnfant")
);

const LayoutMaterniteAccouchementList = lazy(() =>
    import(
        "../../components/maternite/Accouchement/Liste/LayoutMaterniteAccouchementList"
    )
);

const MaterniteVisitePtmeEnfant = lazy(() =>
        import("../../components/maternite/visitePtmeEnfant/MaterniteVisitePtmeEnfant")
);
const MaterniteVisitePtmeMere = lazy(() =>
    import("../../components/maternite/visitePtmeMere/MaterniteVisitePtmeMere")
);
const MaternitePtmeEnfant = lazy(() =>
    import("../../components/maternite/ptmeEnfant/MaternitePtmeEnfant")
);
const MaternitePtmeMere = lazy(() =>
    import("../../components/maternite/ptmeMere/MaternitePtmeMere")
);
const MaterniteConsultationPostnatale = lazy(() =>
    import(
        "../../components/maternite/consultationPostnatale/MaterniteConsultationPostnatale"
    )
);
const MaterniteConsultationPostnataleCreate = lazy(() =>
    import(
        "../../components/maternite/consultationPostnatale/Create/LayoutMaterniteConsultationPostnataleCreate"
    )
);
const MaterniteConsultationPrenatale = lazy(() =>
    import(
        "../../components/maternite/consultationPrenatale/MaterniteConsultationPrenatale"
    )
);

const LayoutMaterniteEnregistrementGrossesse = lazy(() =>
    import(
        "../../components/maternite/patientAConsulter/Form/LayoutMaterniteEnregistrementGrossesse"
    )
);

const LayoutMaternitePtmeMereAdd = lazy(() =>
    import("../../components/maternite/ptmeMere/FormAdd/LayoutMaternitePtmeMereAdd")
);
const LayoutMaternitePtmeEnfantAdd = lazy(() =>
    import(
        "../../components/maternite/ptmeEnfant/FormAdd/LayoutMaternitePtmeEnfantAdd"
    )
);

const SCDataTableLayout = lazy(() =>
    import("../../components/maternite/SuiteCouche/SansComplication/list/SCDataTableLayout")
);

const ACDataTableLayout = lazy(() =>
    import("../../components/maternite/SuiteCouche/AvecComplication/list/ACDataTableLayout")
);

const IssueSortieSCCLayout = lazy(() =>
    import("../../components/maternite/SuiteCouche/AvecComplication/Issues/IssueSortieSCCLayout")
);

const SCACLayout = lazy(() =>
    import("../../components/maternite/SuiteCouche/AvecComplication/Create/SCACLayout")
);

const LayoutMaterniteSuiteCoucheSansSortie = lazy(() =>
    import(
      "../../components/maternite/SuiteCouche/SansComplication/LayoutMaterniteSuiteCoucheSansSortie"
    )
);
  

export default function MaterniteRouter() {
  return (
    <Switch>
        <MyPrivateRouters.PrivateRouterMaternite
        path="/maternite-accouchement"
        component={LayoutMaterniteAccouchementList}
        />

        <MyPrivateRouters.PrivateRouterMaternite
        path="/maternite-accouchement-details/:id"
        component={LayoutMaterniteAccouchementDetails}
        />

        <MyPrivateRouters.PrivateRouterMaternite
        path="/maternite-suite-de-couche-sans-complication"
        component={SCDataTableLayout}
        />

        <MyPrivateRouters.PrivateRouterMaternite
        path="/maternite-suite-de-couche-sans-sortie/:id"
        component={LayoutMaterniteSuiteCoucheSansSortie}
        />

        <MyPrivateRouters.PrivateRouterMaternite
        path="/maternite-suite-de-couche-sans-sortie-reference-interne/:id"
        component={LayoutMaterniteSuiteCoucheSansSortieInterne}
        />

        <MyPrivateRouters.PrivateRouterMaternite
        path="/maternite-suite-de-couche-sans-sortie-reference-externe/:id"
        component={LayoutMaterniteSuiteCoucheSansSortieExterne}
        />

        <MyPrivateRouters.PrivateRouterMaternite
        path="/maternite-suite-de-couche-sans-sortie-demande-hospitalisation/:id"
        component={LayoutMaterniteSuiteCoucheSansSortieDemandeHospitalisation}
        />

        <MyPrivateRouters.PrivateRouterMaternite
        path="/maternite-suite-de-couche-complication"
        component={ACDataTableLayout}
        />

        <MyPrivateRouters.PrivateRouterMaternite
        path="/maternite-suite-de-couche-complication-issue-sortie/:id"
        component={IssueSortieSCCLayout}
        />

        <MyPrivateRouters.PrivateRouterMaternite
        path="/maternite-suite-de-couche-complication-consultation/:id"
        component={SCACLayout}
        />

        <MyPrivateRouters.PrivateRouterMaternite
        path="/maternite-gestante-prenatale"
        component={MaterniteConsultationPrenatale}
        />

        <MyPrivateRouters.PrivateRouterMaternite
        path="/maternite-gestante-prenatale-create/:id"
        component={LayoutMaterniteConsultationPrenataleCreate}
        />

        <MyPrivateRouters.PrivateRouterMaternite
        path="/maternite-consultation-postnatale"
        component={MaterniteConsultationPostnatale}
        />

        <MyPrivateRouters.PrivateRouterMaternite
        path="/maternite-consultation-postnatale-create/:id"
        component={MaterniteConsultationPostnataleCreate}
        />

        <MyPrivateRouters.PrivateRouterMaternite
        path="/maternite-consultation-postnatale-details/:id"
        component={LayoutMaterniteConsultationPostnataleDetails}
        />

        <MyPrivateRouters.PrivateRouterMaternite
        path="/maternite-ptme-mere"
        component={MaternitePtmeMere}
        />

        <MyPrivateRouters.PrivateRouterMaternite
        path="/maternite-ptme-mere-create"
        component={LayoutMaternitePtmeMereAdd}
        />

        <MyPrivateRouters.PrivateRouterMaternite
        path="/maternite-ptme-mere-create-visite/:id"
        component={LayoutMaternitePtmeMere}
        />

        <MyPrivateRouters.PrivateRouterMaternite
        path="/maternite-ptme-enfants"
        component={MaternitePtmeEnfant}
        />

        <MyPrivateRouters.PrivateRouterMaternite
        path="/maternite-ptme-enfants-create"
        component={LayoutMaternitePtmeEnfantAdd}
        />

        <MyPrivateRouters.PrivateRouterMaternite
        path="/maternite-ptme-enfants-create-visite/:id"
        component={LayoutMaternitePtmeEnfant}
        />

        <MyPrivateRouters.PrivateRouterMaternite
        path="/maternite-ptme-enfants/:id"
        component={LayoutMaternitePtmeEnfant}
        />

        <MyPrivateRouters.PrivateRouterMaternite
        path="/maternite-visite-ptme-mere"
        component={MaterniteVisitePtmeMere}
        />

        <MyPrivateRouters.PrivateRouterMaternite
        path="/maternite-visite-ptme-enfant"
        component={MaterniteVisitePtmeEnfant}
        />

        <MyPrivateRouters.PrivateRouterMaternite
        path="/maternite-ordonnance-postnatale"
        component={LayoutOrdonnancePostnataleListe}
        />

        <MyPrivateRouters.PrivateRouterMaternite
        path="/maternite-ordonnance-postnatale-voir/:id"
        component={LayoutOrdonnancePostnataleFormView}
        />

        <MyPrivateRouters.PrivateRouterMaternite
        path="/maternite-ordonnance-prenatale"
        component={LayoutOrdonnancePrenataleListe}
        />

        <MyPrivateRouters.PrivateRouterMaternite
        path="/maternite-ordonnance-prenatale-voir/:id"
        component={LayoutOrdonnancePrenataleFormView}
        />

        <MyPrivateRouters.PrivateRouterMaternite
        path="/maternite-ordonnance-mere"
        component={LayoutOrdonnanceMereListe}
        />

        <MyPrivateRouters.PrivateRouterMaternite
        path="/maternite-ordonnance-mere-voir/:id"
        component={LayoutOrdonnanceMereFormView}
        />

        <MyPrivateRouters.PrivateRouterMaternite
        path="/maternite-ordonnance-enfant"
        component={LayoutOrdonnanceEnfantListe}
        />

        <MyPrivateRouters.PrivateRouterMaternite
        path="/maternite-ordonnance-enfant-voir/:id"
        component={LayoutOrdonnanceEnfantFormView}
        />

        <MyPrivateRouters.PrivateRouterMaternite
        path="/maternite-demande-examen-cpns-detail/:id/"
        component={LayoutExamensCPNDetail}
        />

        <MyPrivateRouters.PrivateRouterMaternite
        path="/maternite-demande-examen-cpns"
        component={LayoutExamensCPNList}
        />

        <MyPrivateRouters.PrivateRouterMaternite
        path="/maternite-demande-examen-cpons-detail/:id/"
        component={LayoutExamensCPONDetail}
        />

        <MyPrivateRouters.PrivateRouterMaternite
        path="/maternite-demande-examen-cpons"
        component={LayoutExamensCPONList}
        />

        <MyPrivateRouters.PrivateRouterMaternite
        path="/maternite-demande-examen-consultations-detail/:id/"
        component={LayoutExamensConsultationDetail}
        />

        <MyPrivateRouters.PrivateRouterMaternite
        path="/maternite-demande-examen-consultations"
        component={LayoutExamensConsultationList}
        />

        <MyPrivateRouters.PrivateRouterMaternite
        path="/maternite-patients-decedes"
        component={LayoutMaterniteDecesListe}
        />

        <MyPrivateRouters.PrivateRouterMaternite
        path="/maternite-patients-decedes-certificat/:id"
        component={LayoutMaterniteDecesCertificat}
        />

        <MyPrivateRouters.PrivateRouterMaternite
        path="/maternite-consultation-create/:id"
        component={LayoutMaterniteCreateConsultation}
        />

        <MyPrivateRouters.PrivateRouterMaternite
        path="/maternite-consultation-sortie/:id"
        component={LayoutMaterniteCreateConsultationSortie}
        />

        <MyPrivateRouters.PrivateRouterMaternite
        path="/maternite-referencements-interne"
        component={LayoutMaterniteListeRefInterne}
        />

        <MyPrivateRouters.PrivateRouterMaternite
        path="/maternite-referencements-externe"
        component={LayoutMaterniteListeRefExterne}
        />

        <MyPrivateRouters.PrivateRouterMaternite
        path="/maternite-ordonnance"
        component={LayoutOrdonnanceListe}
        />

        <MyPrivateRouters.PrivateRouterMaternite
        path="/maternite-ordonnance-voir/:id"
        component={LayoutOrdonnanceFormView}
        />

        <MyPrivateRouters.PrivateRouterMaternite
        path="/maternite-consultation-create/:id"
        component={LayoutMaterniteEnregistrementGrossesse}
        />

        <MyPrivateRouters.PrivateRouterMaternite
        path="/maternite-consultation-sortie-demande-hospitalisation/:id"
        component={
            LayoutCreateConsultationSortieDemandeHospitalisationMaternite
        }
        />

        <MyPrivateRouters.PrivateRouterMaternite
        path="/maternite-consultation-sortie-deces/:id"
        component={LayoutMaterniteDecesConsultationSortie}
        />

        <MyPrivateRouters.PrivateRouterMaternite
        path="/maternite-suite-de-couche-complication-deces/:id"
        component={DecesSCCLayout}
        />

        <MyPrivateRouters.PrivateRouterMaternite
        path="/maternite-consultation-sortie-mise-en-observation/:id"
        component={
            LayoutCreateMaterniteConsultationSortieMiseEnObservation
        }
        />

        <MyPrivateRouters.PrivateRouterMaternite
        path="/maternite-consultation-refere-interne/:id"
        component={LayoutCreateMaterniteConsultationRefereInterne}
        />

        <MyPrivateRouters.PrivateRouterMaternite
        path="/maternite-consultation-refere-externe/:id"
        component={LayoutCreateMaterniteConsultationRefereExterne}
        />

        <MyPrivateRouters.PrivateRouterMaternite
        path="/maternite-consultation-sortie-rdv/:id"
        component={LayoutMaterniteCreateConsultationRendezVous}
        />

        <MyPrivateRouters.PrivateRouterMaternite
        path="/maternite-gestante-prenatale/:id"
        component={LayoutMaterniteConsultationPrenataleCreate}
        />

        <MyPrivateRouters.PrivateRouterMaternite
        path="/maternite-gestante-prenatale-sortie/:id"
        component={LayoutMaterniteCPNSortie}
        />

        <MyPrivateRouters.PrivateRouterMaternite
        path="/maternite-gestante-prenatale-sortie-demande-hospitalisation/:id"
        component={LayoutMaterniteCpnSortieDemandeHospitalisationCreate}
        />

        <MyPrivateRouters.PrivateRouterMaternite
        path="/maternite-gestante-prenatale-refere-interne/:id"
        component={LayoutMaterniteCpnSortieRefereInterneCreate}
        />

        <MyPrivateRouters.PrivateRouterMaternite
        path="/maternite-gestante-prenatale-refere-externe/:id"
        component={LayoutMaterniteCpnSortieRefereExterneCreate}
        />

        <MyPrivateRouters.PrivateRouterMaternite
        path="/maternite-suite-de-couche-complication-reference-externe/:id"
        component={RefExterneSCCLayout}
        />

        <MyPrivateRouters.PrivateRouterMaternite
        path="/maternite-gestante-prenatale-sortie-rdv/:id"
        component={LayoutMaterniteCpnSortieRdvCreate}
        />

        <MyPrivateRouters.PrivateRouterMaternite
        path="/maternite-suite-de-couche-complication-rdv/:id"
        component={RendezVousSCCLayout}
        />

        <MyPrivateRouters.PrivateRouterMaternite
        path="/maternite-gestante-accouchement-create/:id"
        component={LayoutMaterniteAccouchementCreate}
        />

        <MyPrivateRouters.PrivateRouterMaternite
        path="/maternite-gestante-accouchement-sortie/:id"
        component={LayoutMaterniteAccouchementSortie}
        />

        <MyPrivateRouters.PrivateRouterMaternite
        path="/maternite-gestante-accouchement-sortie-deces/:id"
        component={LayoutMaterniteAccouchementSortieDecesCreate}
        />

        <MyPrivateRouters.PrivateRouterMaternite
        path="/maternite-gestante-accouchement-sortie-evacuation/:id"
        component={LayoutMaterniteAccouchementSortieEvacuationCreate}
        />

        <MyPrivateRouters.PrivateRouterMaternite
        path="/maternite-accouchement-create/:id"
        component={LayoutMaterniteAccouchementCreate}
        />

        <MyPrivateRouters.PrivateRouterMaternite
        path="/maternite-accouchement-sortie/:id"
        component={LayoutMaterniteAccouchement2Issue}
        />

        <MyPrivateRouters.PrivateRouterMaternite
        path="/maternite-accouchement-sortie-deces/:id"
        component={LayoutMaterniteAccouchement2IssueDeces}
        />

        <MyPrivateRouters.PrivateRouterMaternite
        path="/maternite-accouchement-sortie-evacuation/:id"
        component={LayoutMaterniteAccouchement2IssueEvacuation}
        />

        <MyPrivateRouters.PrivateRouterMaternite
        path="/maternite-accouchement-sortie-suite-couche/:id"
        component={LayoutMaterniteAccouchement2IssueSuiteCouche}
        />

        <MyPrivateRouters.PrivateRouterMaternite
        path="/maternite-suite-de-couche/:id"
        component={LayoutMaternteSuiteCouche}
        />

        <MyPrivateRouters.PrivateRouterMaternite
        path="/maternite-suite-de-couche-consultation/:id"
        component={LayoutMaterniteConsultationPostnataleCreate}
        />

        <MyPrivateRouters.PrivateRouterMaternite
        path="/maternite-rdv"
        component={LayoutMaterniteRdvListe}
        />

        <MyPrivateRouters.PrivateRouterMaternite
        path="/maternite-rdv-agenda/"
        component={AgendaMaternite}
        />

        <MyPrivateRouters.PrivateRouterMaternite
        path="/maternite-demande-examen-cpns-resultat-voir/:id"
        component={LayoutVoirCPNMaterniteResultatsExamens}
        />

        <MyPrivateRouters.PrivateRouterMaternite
        path="/maternite-demande-examen-cpons-resultat-voir/:id"
        component={LayoutVoirCPONMaterniteResultatsExamens}
        />

        <MyPrivateRouters.PrivateRouterMaternite
        path="/maternite-demande-examen-consultations-resultat-voir/:id"
        component={LayoutVoirConsultationMaterniteResultatsExamens}
        />
    </Switch>
  )
}
