export const PATIENT_A_CONSULTER_MATERNITE_GET_LOADING =  "PATIENT_A_CONSULTER_MATERNITE_GET_LOADING"

export const PATIENT_A_CONSULTER_MATERNITE_GET_SUCCESS =  "PATIENT_A_CONSULTER_MATERNITE_GET_SUCCESS"

export const PATIENT_A_CONSULTER_MATERNITE_GET_FAILED =  "PATIENT_A_CONSULTER_MATERNITE_GET_FAILED"


export const PATIENT_A_CONSULTER_MATERNITE_GETS_LOADING =  "PATIENT_A_CONSULTER_MATERNITE_GETS_LOADING"

export const PATIENT_A_CONSULTER_MATERNITE_GETS_SUCCESS =  "PATIENT_A_CONSULTER_MATERNITE_GETS_SUCCESS"

export const PATIENT_A_CONSULTER_MATERNITE_GETS_FAILED =  "PATIENT_A_CONSULTER_MATERNITE_GETS_FAILED"


export const PATIENT_A_CONSULTER_MATERNITE_REFRESH_GETS_LOADING =  "PATIENT_A_CONSULTER_MATERNITE_REFRESH_GETS_LOADING"

export const PATIENT_A_CONSULTER_MATERNITE_REFRESH_GETS_SUCCESS =  "PATIENT_A_CONSULTER_MATERNITE_REFRESH_GETS_SUCCESS"

export const PATIENT_A_CONSULTER_MATERNITE_REFRESH_GETS_FAILED =  "PATIENT_A_CONSULTER_MATERNITE_REFRESH_GETS_FAILED"


export const MATERNITE_ACTES_MEDICAUX_GET_LOADING = 'MATERNITE_ACTES_MEDICAUX_GET_LOADING'

export const MATERNITE_ACTES_MEDICAUX_GET_SUCCESS = 'MATERNITE_ACTES_MEDICAUX_GET_SUCCESS'

export const MATERNITE_ACTES_MEDICAUX_GET_FAILED = 'MATERNITE_ACTES_MEDICAUX_GET_FAILED'


/***  Modify */
export const CONSULTATION_MATERNITE_POST_LOADING  =  'CONSULTATION_MATERNITE_POST_LOADING'

export const CONSULTATION_MATERNITE_POST_SUCCESS  =  'CONSULTATION_MATERNITE_POST_SUCCESS'

export const CONSULTATION_MATERNITE_POST_FAILED =  'CONSULTATION_MATERNITE_POST_FAILED'


export const ACTES_CHIRUGICAUX_MATERNITE_GET_LOADING =  'ACTES_CHIRUGICAUX_MATERNITE_GET_FAILED'

export const ACTES_CHIRUGICAUX_MATERNITE_GET_SUCCESS =  'ACTES_CHIRUGICAUX_MATERNITE_GET_SUCCESS'

export const ACTES_CHIRUGICAUX_MATERNITE_GET_FAILED =  'ACTES_CHIRUGICAUX_MATERNITE_GET_FAILED'


export const CONSULTATION_MATERNITE_GET_LOADING  =  'CONSULTATION_MATERNITE_GET_LOADING'

export const CONSULTATION_MATERNITE_GET_SUCCESS  =  'CONSULTATION_MATERNITE_GET_SUCCESS'

export const CONSULTATION_MATERNITE_GET_PAS_TROUVER =  'CONSULTATION_MATERNITE_GET_PAS_TROUVER'

export const CONSULTATION_MATERNITE_GET_FAILED =  'CONSULTATION_MATERNITE_GET_FAILED'


export const TYPES_EXAMENS_MATERNITE_GET_LOADING =  'TYPES_EXAMENS_MATERNITE_GET_LOADING'

export const TYPES_EXAMENS_MATERNITE_GET_SUCCESS =  'TYPES_EXAMENS_MATERNITE_GET_SUCCESS'

export const TYPES_EXAMENS_MATERNITE_GET_FAILED =  'TYPES_EXAMENS_MATERNITE_GET_FAILED'


export const CONSULTATION_MATERNITE_POST_TERMINER_LOADING =  'CONSULTATION_MATERNITE_POST_TERMINER_LOADING'

export const CONSULTATION_MATERNITE_POST_TERMINER_SUCCESS =  'CONSULTATION_MATERNITE_POST_TERMINER_SUCCESS'

export const CONSULTATION_MATERNITE_POST_TERMINER_FAILED =  'CONSULTATION_MATERNITE_POST_TERMINER_FAILED'



      


export const CONSULTATION_MATERNITE_GROSSESSE_GET_LOADING = 'CONSULTATION_MATERNITE_GROSSESSE_GET_LOADING'

export const CONSULTATION_MATERNITE_GROSSESSE_GET_SUCCESS = 'CONSULTATION_MATERNITE_GROSSESSE_GET_SUCCESS'

export const CONSULTATION_MATERNITE_GROSSESSE_GET_PAS_TROUVER = "CONSULTATION_MATERNITE_GROSSESSE_GET_PAS_TROUVER"

export const CONSULTATION_MATERNITE_GROSSESSE_GET_FAILED = 'CONSULTATION_MATERNITE_GROSSESSE_GET_FAILED'


export const MATERNITE_CONSULTATION_GROSSESSE_POST_LOADING = 'MATERNITE_CONSULTATION_GROSSESSE_POST_LOADING'

export const MATERNITE_CONSULTATION_GROSSESSE_POST_SUCCESS = 'MATERNITE_CONSULTATION_GROSSESSE_POST_SUCCESS'

export const MATERNITE_CONSULTATION_GROSSESSE_POST_FAILED = 'MATERNITE_CONSULTATION_GROSSESSE_POST_FAILED'


export const CONSULTATION_MATERNITE_GROSSESSES_GETS_LOADING = 'CONSULTATION_MATERNITE_GROSSESSES_GETS_LOADING'

export const CONSULTATION_MATERNITE_GROSSESSES_GETS_SUCCESS = 'CONSULTATION_MATERNITE_GROSSESSES_GETS_SUCCESS'

export const CONSULTATION_MATERNITE_GROSSESSES_GETS_PAS_TROUVER = "CONSULTATION_MATERNITE_GROSSESSES_GETS_PAS_TROUVER"

export const CONSULTATION_MATERNITE_GROSSESSES_GETS_FAILED = 'CONSULTATION_MATERNITE_GROSSESSES_GETS_FAILED'


export const CONSULTATION_MATERNITE_GROSSESSES_REFRESH_GETS_LOADING = 'CONSULTATION_MATERNITE_GROSSESSES_REFRESH_GETS_LOADING'

export const CONSULTATION_MATERNITE_GROSSESSES_REFRESH_GETS_SUCCESS = 'CONSULTATION_MATERNITE_GROSSESSES_REFRESH_GETS_SUCCESS'

export const CONSULTATION_MATERNITE_GROSSESSES_REFRESH_GETS_FAILED = 'CONSULTATION_MATERNITE_GROSSESSES_REFRESH_GETS_FAILED'



export const MATERNITE_ACCOUCHEMENT_POST_LOADING = 'MATERNITE_ACCOUCHEMENT_POST_LOADING'

export const MATERNITE_ACCOUCHEMENT_POST_SUCCESS = 'MATERNITE_ACCOUCHEMENT_POST_SUCCESS'

export const MATERNITE_ACCOUCHEMENT_POST_FAILED = 'MATERNITE_ACCOUCHEMENT_POST_FAILED'



export const MATERNITE_ACCOUCHEMENT_TERMINER_POST_LOADING = 'MATERNITE_ACCOUCHEMENT_TERMINER_POST_LOADING'

export const MATERNITE_ACCOUCHEMENT_TERMINER_POST_SUCCESS = 'MATERNITE_ACCOUCHEMENT_TERMINER_POST_SUCCESS'

export const MATERNITE_ACCOUCHEMENT_TERMINER_POST_FAILED = 'MATERNITE_ACCOUCHEMENT_TERMINER_POST_FAILED'



export const MATERNITE_ACCOUCHEMENT_GET_LOADING = 'MATERNITE_ACCOUCHEMENT_GET_LOADING'

export const MATERNITE_ACCOUCHEMENT_GET_SUCCESS = 'MATERNITE_ACCOUCHEMENT_GET_SUCCESS'

export const MATERNITE_ACCOUCHEMENT_GET_FAILED = 'MATERNITE_ACCOUCHEMENT_GET_FAILED'



export const MATERNITE_CURRENT_ACCOUCHEMENT_GET_LOADING = 'MATERNITE_CURRENT_ACCOUCHEMENT_GET_LOADING'

export const MATERNITE_CURRENT_ACCOUCHEMENT_GET_SUCCESS = 'MATERNITE_CURRENT_ACCOUCHEMENT_GET_SUCCESS'

export const MATERNITE_CURRENT_ACCOUCHEMENT_NON_TROUVER_GET_SUCCESS = 'MATERNITE_CURRENT_ACCOUCHEMENT_NON_TROUVER_GET_SUCCESS'

export const MATERNITE_CURRENT_ACCOUCHEMENT_GET_FAILED = 'MATERNITE_CURRENT_ACCOUCHEMENT_GET_FAILED'



export const MATERNITE_ACCOUCHEMENT_GETS_LOADING = 'MATERNITE_ACCOUCHEMENT_GETS_LOADING'

export const MATERNITE_ACCOUCHEMENT_GETS_SUCCESS = 'MATERNITE_ACCOUCHEMENT_GETS_SUCCESS'

export const MATERNITE_ACCOUCHEMENT_GETS_FAILED = 'MATERNITE_ACCOUCHEMENT_GETS_FAILED'

/////////

export const ORDONNANCE_CONSULTATION_MATERNITE_GET_LOADING  =  'ORDONNANCE_CONSULTATION_MATERNITE_GET_LOADING'

export const ORDONNANCE_CONSULTATION_MATERNITE_GET_SUCCESS =  'ORDONNANCE_CONSULTATION_MATERNITE_GET_SUCCESS'

export const ORDONNANCE_CONSULTATION_MATERNITE_GET_FAILED =  'ORDONNANCE_CONSULTATION_MATERNITE_GET_FAILED'


export const MATERNITE_ACCOUCHEMENT_REFRESH_GETS_LOADING = 'MATERNITE_ACCOUCHEMENT_REFRESH_GETS_LOADING'

export const MATERNITE_ACCOUCHEMENT_REFRESH_GETS_SUCCESS = 'MATERNITE_ACCOUCHEMENT_REFRESH_GETS_SUCCESS'

export const MATERNITE_ACCOUCHEMENT_REFRESH_GETS_FAILED = 'MATERNITE_ACCOUCHEMENT_REFRESH_GETS_FAILED'



export const MATERNITE_ACCOUCHEMENT_DELETE_LOADING = 'MATERNITE_ACCOUCHEMENT_DELETE_LOADING'

export const MATERNITE_ACCOUCHEMENT_DELETE_SUCCESS = 'MATERNITE_ACCOUCHEMENT_DELETE_SUCCESS'

export const MATERNITE_ACCOUCHEMENT_DELETE_ERROR_SUCCESS = 'MATERNITE_ACCOUCHEMENT_DELETE_ERROR_SUCCESS'

export const MATERNITE_ACCOUCHEMENT_DELETE_FAILED = 'MATERNITE_ACCOUCHEMENT_DELETE_FAILED'



export const MATERNITE_CONSULTATION_PRENATALE_GET_LOADING = 'MATERNITE_CONSULTATION_PRENATALE_GET_LOADING'

export const MATERNITE_CONSULTATION_PRENATALE_GET_SUCCESS = 'MATERNITE_CONSULTATION_PRENATALE_GET_SUCCESS'

export const MATERNITE_CONSULTATION_PRENATALE_GET_FAILED = 'MATERNITE_CONSULTATION_PRENATALE_GET_FAILED'


export const MATERNITE_CURRENT_CONSULTATION_PRENATALE_GET_LOADING = 'MATERNITE_CURRENT_CONSULTATION_PRENATALE_GET_LOADING'

export const MATERNITE_CURRENT_CONSULTATION_PRENATALE_GET_SUCCESS = 'MATERNITE_CURRENT_CONSULTATION_PRENATALE_GET_SUCCESS'

export const MATERNITE_CURRENT_CONSULTATION_PRENATALE_NON_TROUVER_GET_SUCCESS = 'MATERNITE_CURRENT_CONSULTATION_PRENATALE_NON_TROUVER_GET_SUCCESS'

export const MATERNITE_CURRENT_CONSULTATION_PRENATALE_GET_FAILED = 'MATERNITE_CURRENT_CONSULTATION_PRENATALE_GET_FAILED'



export const MATERNITE_CONSULTATION_PRENATALE_GETS_LOADING = "MATERNITE_CONSULTATION_PRENATALE_GETS_LOADING"

export const MATERNITE_CONSULTATION_PRENATALE_GETS_SUCCESS = "MATERNITE_CONSULTATION_PRENATALE_GETS_SUCCESS"

export const MATERNITE_CONSULTATION_PRENATALE_GETS_FAILED = "MATERNITE_CONSULTATION_PRENATALE_GETS_FAILED"


export const MATERNITE_CONSULTATION_PRENATALE_REFRESH_GETS_LOADING = "MATERNITE_CONSULTATION_PRENATALE_REFRESH_GETS_LOADING"

export const MATERNITE_CONSULTATION_PRENATALE_REFRESH_GETS_SUCCESS = "MATERNITE_CONSULTATION_PRENATALE_REFRESH_GETS_SUCCESS"

export const MATERNITE_CONSULTATION_PRENATALE_REFRESH_GETS_FAILED = "MATERNITE_CONSULTATION_PRENATALE_REFRESH_GETS_FAILED"



export const MATERNITE_CONSULTATION_PRENATALE_POST_LOADING = 'MATERNITE_CONSULTATION_PRENATALE_POST_LOADING'

export const MATERNITE_CONSULTATION_PRENATALE_POST_SUCCESS = 'MATERNITE_CONSULTATION_PRENATALE_POST_SUCCESS'

export const MATERNITE_CONSULTATION_PRENATALE_POST_FAILED = 'MATERNITE_CONSULTATION_PRENATALE_POST_FAILED'


export const MATERNITE_CONSULTATION_PRENATALE_POST_TERMINER_LOADING = 'MATERNITE_CONSULTATION_PRENATALE_POST_TERMINER_LOADING'

export const MATERNITE_CONSULTATION_PRENATALE_POST_TERMINER_SUCCESS = 'MATERNITE_CONSULTATION_PRENATALE_POST_TERMINER_SUCCESS'

export const MATERNITE_CONSULTATION_PRENATALE_POST_TERMINER_FAILED = 'MATERNITE_CONSULTATION_PRENATALE_POST_TERMINER_FAILED'



export const MATERNITE_CONSULTATION_PRENATALE_DELETE_LOADING = "MATERNITE_CONSULTATION_PRENATALE_DELETE_LOADING"

export const MATERNITE_CONSULTATION_PRENATALE_DELETE_SUCCESS = "MATERNITE_CONSULTATION_PRENATALE_DELETE_SUCCESS"

export const MATERNITE_CONSULTATION_POSTNATALE_DELETE_IMPOSSIBLE_SUCCESS = "MATERNITE_CONSULTATION_POSTNATALE_DELETE_IMPOSSIBLE_SUCCESS"

export const MATERNITE_CONSULTATION_PRENATALE_DELETE_FAILED = "MATERNITE_CONSULTATION_PRENATALE_DELETE_FAILED"




export const MATERNITE_CONSULTATION_POSTNATALE_GET_LOADING = 'MATERNITE_CONSULTATION_POSTNATALE_GET_LOADING'

export const MATERNITE_CONSULTATION_POSTNATALE_GET_SUCCESS = 'MATERNITE_CONSULTATION_POSTNATALE_GET_SUCCESS'

export const MATERNITE_CONSULTATION_POSTNATALE_GET_FAILED = 'MATERNITE_CONSULTATION_POSTNATALE_GET_FAILED'



export const MATERNITE_CURRENT_CONSULTATION_POSTNATALE_GET_LOADING = 'MATERNITE_CURRENT_CONSULTATION_POSTNATALE_GET_LOADING'

export const MATERNITE_CURRENT_CONSULTATION_POSTNATALE_GET_SUCCESS = 'MATERNITE_CURRENT_CONSULTATION_POSTNATALE_GET_SUCCESS'

export const MATERNITE_CURRENT_CONSULTATION_POSTNATALE_NON_TROUVER_GET_SUCCESS = 'MATERNITE_CURRENT_CONSULTATION_POSTNATALE_NON_TROUVER_GET_SUCCESS'

export const MATERNITE_CURRENT_CONSULTATION_POSTNATALE_GET_FAILED = 'MATERNITE_CURRENT_CONSULTATION_POSTNATALE_GET_FAILED'



export const MATERNITE_CONSULTATION_POSTNATALE_GETS_LOADING = "MATERNITE_CONSULTATION_POSTNATALE_GETS_LOADING"

export const MATERNITE_CONSULTATION_POSTNATALE_GETS_SUCCESS = "MATERNITE_CONSULTATION_POSTNATALE_GETS_SUCCESS"

export const MATERNITE_CONSULTATION_POSTNATALE_GETS_FAILED = "MATERNITE_CONSULTATION_POSTNATALE_GETS_FAILED"


export const MATERNITE_CONSULTATION_POSTNATALE_REFRESH_GETS_LOADING = "MATERNITE_CONSULTATION_POSTNATALE_REFRESH_GETS_LOADING"

export const MATERNITE_CONSULTATION_POSTNATALE_REFRESH_GETS_SUCCESS = "MATERNITE_CONSULTATION_POSTNATALE_REFRESH_GETS_SUCCESS"

export const MATERNITE_CONSULTATION_POSTNATALE_REFRESH_GETS_FAILED = "MATERNITE_CONSULTATION_POSTNATALE_REFRESH_GETS_FAILED"



export const MATERNITE_CONSULTATION_POSTNATALE_POST_LOADING = 'MATERNITE_CONSULTATION_POSTNATALE_POST_LOADING'

export const MATERNITE_CONSULTATION_POSTNATALE_POST_SUCCESS = 'MATERNITE_CONSULTATION_POSTNATALE_POST_SUCCESS'

export const MATERNITE_CONSULTATION_POSTNATALE_POST_FAILED = 'MATERNITE_CONSULTATION_POSTNATALE_POST_FAILED'



export const MATERNITE_CONSULTATION_POSTNATALE_DELETE_LOADING = "MATERNITE_CONSULTATION_POSTNATALE_DELETE_LOADING"

export const MATERNITE_CONSULTATION_POSTNATALE_DELETE_SUCCESS = "MATERNITE_CONSULTATION_POSTNATALE_DELETE_SUCCESS"

export const MATERNITE_CONSULTATION_POSTNATALE_DELETE_FAILED = "MATERNITE_CONSULTATION_POSTNATALE_DELETE_FAILED"



export const MATERNITE_PTME_MERE_POST_LOADING = 'MATERNITE_PTME_MERE_POST_LOADING'

export const MATERNITE_PTME_MERE_POST_SUCCESS = 'MATERNITE_PTME_MERE_POST_SUCCESS'

export const MATERNITE_PTME_MERE_POST_FAILED = 'MATERNITE_PTME_MERE_POST_FAILED'



export const MATERNITE_PTME_MERE_GETS_LOADING = 'MATERNITE_PTME_MERE_GETS_LOADING'

export const MATERNITE_PTME_MERE_GETS_SUCCESS = 'MATERNITE_PTME_MERE_GETS_SUCCESS'

export const MATERNITE_PTME_MERE_GETS_FAILED = 'MATERNITE_PTME_MERE_GETS_FAILED'


export const MATERNITE_PTME_MERE_REFRESH_GETS_LOADING = 'MATERNITE_PTME_MERE_REFRESH_GETS_LOADING'

export const MATERNITE_PTME_MERE_REFRESH_GETS_SUCCESS = 'MATERNITE_PTME_MERE_REFRESH_GETS_SUCCESS'

export const MATERNITE_PTME_MERE_REFRESH_GETS_FAILED = 'MATERNITE_PTME_MERE_REFRESH_GETS_FAILED'


export const MATERNITE_PTME_MERE_GET_LOADING = 'MATERNITE_PTME_MERE_GET_LOADING'

export const MATERNITE_PTME_MERE_GET_SUCCESS = 'MATERNITE_PTME_MERE_GET_SUCCESS'

export const MATERNITE_PTME_MERE_GET_FAILED = 'MATERNITE_PTME_MERE_GET_FAILED'



export const MATERNITE_PTME_MERE_DELETE_LOADING = 'MATERNITE_PTME_MERE_DELETE_LOADING'

export const MATERNITE_PTME_MERE_DELETE_SUCCESS = 'MATERNITE_PTME_MERE_DELETE_SUCCESS'

export const MATERNITE_PTME_MERE_DELETE_IMPOSSIBLE_SUCCESS = "MATERNITE_PTME_MERE_DELETE_IMPOSSIBLE_SUCCESS"

export const MATERNITE_PTME_MERE_DELETE_FAILED = 'MATERNITE_PTME_MERE_DELETE_FAILED'


export const MATERNITE_VISITE_PTME_MERE_DELETE_LOADING = 'MATERNITE_VISITE_PTME_MERE_DELETE_LOADING'

export const MATERNITE_VISITE_PTME_MERE_DELETE_SUCCESS = 'MATERNITE_VISITE_PTME_MERE_DELETE_SUCCESS'

export const MATERNITE_VISITE_PTME_MERE_DELETE_FAILED = 'MATERNITE_VISITE_PTME_MERE_DELETE_FAILED'


export const MATERNITE_VISITE_PTME_MERE_POST_LOADING = 'MATERNITE_VISITE_PTME_MERE_POST_LOADING'

export const MATERNITE_VISITE_PTME_MERE_POST_SUCCESS = 'MATERNITE_VISITE_PTME_MERE_POST_SUCCESS'

export const MATERNITE_VISITE_PTME_MERE_POST_FAILED = 'MATERNITE_VISITE_PTME_MERE_POST_FAILED'


export const MATERNITE_VISITE_PTME_MERE_GET_LOADING = 'MATERNITE_VISITE_PTME_MERE_GET_LOADING'

export const MATERNITE_VISITE_PTME_MERE_GET_SUCCESS = 'MATERNITE_VISITE_PTME_MERE_GET_SUCCESS'

export const MATERNITE_VISITE_PTME_MERE_GET_FAILED = 'MATERNITE_VISITE_PTME_MERE_GET_FAILED'


export const MATERNITE_VISITE_PTME_MERE_GETS_LOADING = 'MATERNITE_VISITE_PTME_MERE_GETS_LOADING'

export const MATERNITE_VISITE_PTME_MERE_GETS_SUCCESS = 'MATERNITE_VISITE_PTME_MERE_GETS_SUCCESS'

export const MATERNITE_VISITE_PTME_MERE_GETS_FAILED = 'MATERNITE_VISITE_PTME_MERE_GETS_FAILED'


export const MATERNITE_VISITE_PTME_MERE_REFRESH_GETS_LOADING = 'MATERNITE_VISITE_PTME_MERE_REFRESH_GETS_LOADING'

export const MATERNITE_VISITE_PTME_MERE_REFRESH_GETS_SUCCESS = 'MATERNITE_VISITE_PTME_MERE_REFRESH_GETS_SUCCESS'

export const MATERNITE_VISITE_PTME_MERE_REFRESH_GETS_FAILED = 'MATERNITE_VISITE_PTME_MERE_REFRESH_GETS_FAILED'



export const MATERNITE_PTME_ENFANT_POST_LOADING = 'MATERNITE_PTME_ENFANT_POST_LOADING'

export const MATERNITE_PTME_ENFANT_POST_SUCCESS = 'MATERNITE_PTME_ENFANT_POST_SUCCESS'

export const MATERNITE_PTME_ENFANT_POST_FAILED = 'MATERNITE_PTME_ENFANT_POST_FAILED'


export const MATERNITE_PTME_ENFANT_GETS_LOADING = 'MATERNITE_PTME_ENFANT_GETS_LOADING'

export const MATERNITE_PTME_ENFANT_GETS_SUCCESS = 'MATERNITE_PTME_ENFANT_GETS_SUCCESS'

export const MATERNITE_PTME_ENFANT_GETS_FAILED = 'MATERNITE_PTME_ENFANT_GETS_FAILED'


export const MATERNITE_PTME_ENFANT_REFRESH_GETS_LOADING = 'MATERNITE_PTME_ENFANT_REFRESH_GETS_LOADING'

export const MATERNITE_PTME_ENFANT_REFRESH_GETS_SUCCESS = 'MATERNITE_PTME_ENFANT_REFRESH_GETS_SUCCESS'

export const MATERNITE_PTME_ENFANT_REFRESH_GETS_FAILED = 'MATERNITE_PTME_ENFANT_REFRESH_GETS_FAILED'


export const MATERNITE_PTME_ENFANT_GET_LOADING = 'MATERNITE_PTME_ENFANT_GET_LOADING'

export const MATERNITE_PTME_ENFANT_GET_SUCCESS = 'MATERNITE_PTME_ENFANT_GET_SUCCESS'

export const MATERNITE_PTME_ENFANT_GET_FAILED = 'MATERNITE_PTME_ENFANT_GET_FAILED'


export const MATERNITE_PTME_ENFANT_DELETE_LOADING = 'MATERNITE_PTME_ENFANT_DELETE_LOADING'

export const MATERNITE_PTME_ENFANT_DELETE_SUCCESS = 'MATERNITE_PTME_ENFANT_DELETE_SUCCESS'

export const MATERNITE_PTME_ENFANT_DELETE_IMPOSSIBLE_SUCCESS = "MATERNITE_PTME_ENFANT_DELETE_IMPOSSIBLE_SUCCESS"

export const MATERNITE_PTME_ENFANT_DELETE_FAILED = 'MATERNITE_PTME_ENFANT_DELETE_FAILED'


export const MATERNITE_VISITE_PTME_ENFANT_DELETE_LOADING = 'MATERNITE_VISITE_PTME_ENFANT_DELETE_LOADING'

export const MATERNITE_VISITE_PTME_ENFANT_DELETE_SUCCESS = 'MATERNITE_VISITE_PTME_ENFANT_DELETE_SUCCESS'

export const MATERNITE_VISITE_PTME_ENFANT_DELETE_FAILED = 'MATERNITE_VISITE_PTME_ENFANT_DELETE_FAILED'


export const MATERNITE_VISITE_PTME_ENFANT_POST_LOADING = 'MATERNITE_VISITE_PTME_ENFANT_POST_LOADING'

export const MATERNITE_VISITE_PTME_ENFANT_POST_SUCCESS = 'MATERNITE_VISITE_PTME_ENFANT_POST_SUCCESS'

export const MATERNITE_VISITE_PTME_ENFANT_POST_FAILED = 'MATERNITE_VISITE_PTME_ENFANT_POST_FAILED'


export const MATERNITE_VISITE_PTME_ENFANT_GET_LOADING = 'MATERNITE_VISITE_PTME_ENFANT_GET_LOADING'

export const MATERNITE_VISITE_PTME_ENFANT_GET_SUCCESS = 'MATERNITE_VISITE_PTME_ENFANT_GET_SUCCESS'

export const MATERNITE_VISITE_PTME_ENFANT_GET_FAILED = 'MATERNITE_VISITE_PTME_ENFANT_GET_FAILED'


export const MATERNITE_VISITE_PTME_ENFANT_GETS_LOADING = 'MATERNITE_VISITE_PTME_ENFANT_GETS_LOADING'

export const MATERNITE_VISITE_PTME_ENFANT_GETS_SUCCESS = 'MATERNITE_VISITE_PTME_ENFANT_GETS_SUCCESS'

export const MATERNITE_VISITE_PTME_ENFANT_GETS_FAILED = 'MATERNITE_VISITE_PTME_ENFANT_GETS_FAILED'


export const MATERNITE_VISITE_PTME_ENFANT_REFRESH_GETS_LOADING = 'MATERNITE_VISITE_PTME_ENFANT_REFRESH_GETS_LOADING'

export const MATERNITE_VISITE_PTME_ENFANT_REFRESH_GETS_SUCCESS = 'MATERNITE_VISITE_PTME_ENFANT_REFRESH_GETS_SUCCESS'

export const MATERNITE_VISITE_PTME_ENFANT_REFRESH_GETS_FAILED = 'MATERNITE_VISITE_PTME_ENFANT_REFRESH_GETS_FAILED'


export const ORDONNANCE_CONSULTATION_PRENATALE_MATERNITE_GET_LOADING = 'ORDONNANCE_CONSULTATION_PRENATALE_MATERNITE_GET_LOADING'

export const ORDONNANCE_CONSULTATION_PRENATALE_MATERNITE_GET_SUCCESS = 'ORDONNANCE_CONSULTATION_PRENATALE_MATERNITE_GET_SUCCESS'

export const ORDONNANCE_CONSULTATION_PRENATALE_MATERNITE_GET_FAILED = 'ORDONNANCE_CONSULTATION_PRENATALE_MATERNITE_GET_FAILED'


export const ORDONNANCES_CONSULTATIONS_PRENATALES_MATERNITE_GETS_LOADING = 'ORDONNANCES_CONSULTATIONS_PRENATALES_MATERNITE_GETS_LOADING'

export const ORDONNANCES_CONSULTATIONS_PRENATALES_MATERNITE_GETS_SUCCESS = 'ORDONNANCES_CONSULTATIONS_PRENATALES_MATERNITE_GETS_SUCCESS'

export const ORDONNANCES_CONSULTATIONS_PRENATALES_MATERNITE_GETS_FAILED = 'ORDONNANCES_CONSULTATIONS_PRENATALES_MATERNITE_GETS_FAILED'


export const ORDONNANCES_CONSULTATIONS_PRENATALES_MATERNITE_REFRESH_GETS_LOADING = 'ORDONNANCES_CONSULTATIONS_PRENATALES_MATERNITE_REFRESH_GETS_LOADING'

export const ORDONNANCES_CONSULTATIONS_PRENATALES_MATERNITE_REFRESH_GETS_SUCCESS = 'ORDONNANCES_CONSULTATIONS_PRENATALES_MATERNITE_REFRESH_GETS_SUCCESS'

export const ORDONNANCES_CONSULTATIONS_PRENATALES_MATERNITE_REFRESH_GETS_FAILED = 'ORDONNANCES_CONSULTATIONS_PRENATALES_MATERNITE_REFRESH_GETS_FAILED'




export const ORDONNANCE_CONSULTATION_POSTNATALE_MATERNITE_GET_LOADING = 'ORDONNANCE_CONSULTATION_POSTNATALE_MATERNITE_GET_LOADING'

export const ORDONNANCE_CONSULTATION_POSTNATALE_MATERNITE_GET_SUCCESS = 'ORDONNANCE_CONSULTATION_POSTNATALE_MATERNITE_GET_SUCCESS'

export const ORDONNANCE_CONSULTATION_POSTNATALE_MATERNITE_GET_FAILED = 'ORDONNANCE_CONSULTATION_POSTNATALE_MATERNITE_GET_FAILED'


export const ORDONNANCES_CONSULTATIONS_POSTNATALES_MATERNITE_GETS_LOADING = 'ORDONNANCES_CONSULTATIONS_POSTNATALES_MATERNITE_GETS_LOADING'

export const ORDONNANCES_CONSULTATIONS_POSTNATALES_MATERNITE_GETS_SUCCESS = 'ORDONNANCES_CONSULTATIONS_POSTNATALES_MATERNITE_GETS_SUCCESS'

export const ORDONNANCES_CONSULTATIONS_POSTNATALES_MATERNITE_GETS_FAILED = 'ORDONNANCES_CONSULTATIONS_POSTNATALES_MATERNITE_GETS_FAILED'


export const ORDONNANCES_CONSULTATIONS_POSTNATALES_MATERNITE_REFRESH_GETS_LOADING = 'ORDONNANCES_CONSULTATIONS_POSTNATALES_MATERNITE_REFRESH_GETS_LOADING'

export const ORDONNANCES_CONSULTATIONS_POSTNATALES_MATERNITE_REFRESH_GETS_SUCCESS = 'ORDONNANCES_CONSULTATIONS_POSTNATALES_MATERNITE_REFRESH_GETS_SUCCESS'

export const ORDONNANCES_CONSULTATIONS_POSTNATALES_MATERNITE_REFRESH_GETS_FAILED = 'ORDONNANCES_CONSULTATIONS_POSTNATALES_MATERNITE_REFRESH_GETS_FAILED'



export const ORDONNANCE_SORTIE_MERE_MATERNITE_GET_LOADING = 'ORDONNANCE_SORTIE_MERE_MATERNITE_GET_LOADING'

export const ORDONNANCE_SORTIE_MERE_MATERNITE_GET_SUCCESS = 'ORDONNANCE_SORTIE_MERE_MATERNITE_GET_SUCCESS'

export const ORDONNANCE_SORTIE_MERE_MATERNITE_GET_FAILED = 'ORDONNANCE_SORTIE_MERE_MATERNITE_GET_FAILED'


export const ORDONNANCES_SORTIES_MERES_MATERNITE_GETS_LOADING = 'ORDONNANCES_SORTIES_MERES_MATERNITE_GETS_LOADING'

export const ORDONNANCES_SORTIES_MERES_MATERNITE_GETS_SUCCESS = 'ORDONNANCES_SORTIES_MERES_MATERNITE_GETS_SUCCESS'

export const ORDONNANCES_SORTIES_MERES_MATERNITE_GETS_FAILED = 'ORDONNANCES_SORTIES_MERES_MATERNITE_GETS_FAILED'


export const ORDONNANCES_SORTIES_MERES_MATERNITE_REFRESH_GETS_LOADING = 'ORDONNANCES_SORTIES_MERES_MATERNITE_REFRESH_GETS_LOADING'

export const ORDONNANCES_SORTIES_MERES_MATERNITE_REFRESH_GETS_SUCCESS = 'ORDONNANCES_SORTIES_MERES_MATERNITE_REFRESH_GETS_SUCCESS'

export const ORDONNANCES_SORTIES_MERES_MATERNITE_REFRESH_GETS_FAILED = 'ORDONNANCES_SORTIES_MERES_MATERNITE_REFRESH_GETS_FAILED'



export const ORDONNANCE_SORTIE_ENFANT_MATERNITE_GET_LOADING = 'ORDONNANCE_SORTIE_ENFANT_MATERNITE_GET_LOADING'

export const ORDONNANCE_SORTIE_ENFANT_MATERNITE_GET_SUCCESS = 'ORDONNANCE_SORTIE_ENFANT_MATERNITE_GET_SUCCESS'

export const ORDONNANCE_SORTIE_ENFANT_MATERNITE_GET_FAILED = 'ORDONNANCE_SORTIE_ENFANT_MATERNITE_GET_FAILED'


export const ORDONNANCES_SORTIES_ENFANTS_MATERNITE_GETS_LOADING = 'ORDONNANCES_SORTIES_ENFANTS_MATERNITE_GETS_LOADING'

export const ORDONNANCES_SORTIES_ENFANTS_MATERNITE_GETS_SUCCESS = 'ORDONNANCES_SORTIES_ENFANTS_MATERNITE_GETS_SUCCESS'

export const ORDONNANCES_SORTIES_ENFANTS_MATERNITE_GETS_FAILED = 'ORDONNANCES_SORTIES_ENFANTS_MATERNITE_GETS_FAILED'


export const ORDONNANCES_SORTIES_ENFANTS_MATERNITE_REFRESH_GETS_LOADING = 'ORDONNANCES_SORTIES_ENFANTS_MATERNITE_REFRESH_GETS_LOADING'

export const ORDONNANCES_SORTIES_ENFANTS_MATERNITE_REFRESH_GETS_SUCCESS = 'ORDONNANCES_SORTIES_ENFANTS_MATERNITE_REFRESH_GETS_SUCCESS'

export const ORDONNANCES_SORTIES_ENFANTS_MATERNITE_REFRESH_GETS_FAILED = 'ORDONNANCES_SORTIES_ENFANTS_MATERNITE_REFRESH_GETS_FAILED'



export const CONSULTATION_MATERNITE_RESET = 'CONSULTATION_MATERNITE_RESET'

export const CONSULTATION_MATERNITE_RESET_AFTER = "CONSULTATION_MATERNITE_RESET_AFTER"



export const ISSU_SORTIE_CONSULTATION_MATERNITE_POST_LOADING = "ISSU_SORTIE_CONSULTATION_MATERNITE_POST_LOADING"

export const ISSU_SORTIE_CONSULTATION_MATERNITE_POST_SUCCESS = "ISSU_SORTIE_CONSULTATION_MATERNITE_POST_SUCCESS"

export const ISSU_SORTIE_CONSULTATION_MATERNITE_POST_FAILED = "ISSU_SORTIE_CONSULTATION_MATERNITE_POST_FAILED"



export const ISSU_DEMANDE_HOSPITALISATION_MATERNITE_POST_LOADING = "ISSU_DEMANDE_HOSPITALISATION_MATERNITE_POST_LOADING"

export const ISSU_DEMANDE_HOSPITALISATION_MATERNITE_POST_SUCCESS = "ISSU_DEMANDE_HOSPITALISATION_MATERNITE_POST_SUCCESS"

export const ISSU_DEMANDE_HOSPITALISATION_MATERNITE_POST_FAILED = "ISSU_DEMANDE_HOSPITALISATION_MATERNITE_POST_FAILED"

//4
export const ISSU_MISE_EN_OBSERVATION_MATERNITE_POST_LOADING = "ISSU_MISE_EN_OBSERVATION_MATERNITE_POST_LOADING"

export const ISSU_MISE_EN_OBSERVATION_MATERNITE_POST_SUCCESS  = "ISSU_MISE_EN_OBSERVATION_MATERNITE_POST_SUCCESS"

export const ISSU_MISE_EN_OBSERVATION_MATERNITE_POST_FAILED = "ISSU_MISE_EN_OBSERVATION_MATERNITE_POST_FAILED"


export const REFERE_EXTERNE_MATERNITE_POST_LOADING = "REFERE_EXTERNE_MATERNITE_POST_LOADING"

export const REFERE_EXTERNE_MATERNITE_POST_SUCCESS = "REFERE_EXTERNE_MATERNITE_POST_SUCCESS"

export const REFERE_EXTERNE_MATERNITE_POST_FAILED = "REFERE_EXTERNE_MATERNITE_POST_FAILED"


export const REFERE_INTERNE_MATERNITE_POST_LOADING = "REFERE_INTERNE_MATERNITE_POST_LOADING"

export const REFERE_INTERNE_MATERNITE_POST_SUCCESS = "REFERE_INTERNE_MATERNITE_POST_SUCCESS"

export const REFERE_INTERNE_MATERNITE_POST_FAILED  = "REFERE_INTERNE_MATERNITE_POST_FAILED"

export const ORDONNANCES_MATERNITE_GET_REFRESH_LOADING  = "ORDONNANCES_MATERNITE_GET_REFRESH_LOADING"

export const  ORDONNANCES_MATERNITE_GET_REFRESH_SUCCESS = "ORDONNANCES_MATERNITE_GET_REFRESH_SUCCESS"

export const  ORDONNANCES_MATERNITE_GET_REFRESH_FAILED = "ORDONNANCES_MATERNITE_GET_REFRESH_FAILED"
