export const GlobalDebug = (function () {
    var savedConsole = console;
    // /**
    // * @param {boolean} debugOn
    // * @param {boolean} suppressAll
    // */
    return function (debugOn, suppressAll) {
        var suppress = suppressAll || false;
        if (debugOn === false) {
            // supress the default console functionality
            //eslint-disable-next-line
            console = {};
            

            console.log = function(){};
            // supress all type of consoles
            if (suppress === true) {
                console.info = function(){};
                console.warn = function(){};
                console.error = function(){};
                console.debug = function(){};
                console.time = function(){};
                console.trace = function(){};
            }else {
                console.log("pas suppress")
                console.info = savedConsole.info;
                console.warn = savedConsole.warn;
                console.error = savedConsole.error;
            }
        } else {
            //eslint-disable-next-line
            console = savedConsole;
        }
    };
})();