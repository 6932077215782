import * as roleTypes from '../types/roleTypes';
import { AUTH_ERROR, LOGOUT_SUCCESS, TOKEN_REFRESH_FAIL } from '../types/userTypes';


const initialState = {
    data: [],
    showSuccessModal: false
}

const executeGetDataSuccess = (state, action) => {
    return {
        ...state,
        data: action.data
    }
}
const executePostDataSuccess = (state, action) => {
    return {
        ...state,
        showSuccessModal: true
    }
}
const executePutDataSuccess = (state, action) => {
    return {
        ...state,
        showSuccessModal: true
    }
}
const executeDeleteDataSuccess = (state, action) => {
    return {
        ...state,
        showSuccessModal: true
    }
}

const reducer = (state = initialState, action) => {

    switch (action.type) {
        case roleTypes.ROLE_GET_DATA_SUCCESS:
            return executeGetDataSuccess(state, action);
        case roleTypes.ROLE_POST_DATA_SUCCESS:
            return executePostDataSuccess(state, action);
        case roleTypes.ROLE_PUT_DATA_SUCCESS:
            return executePutDataSuccess(state, action);
        case roleTypes.ROLE_DELETE_DATA_SUCCESS:
            return executeDeleteDataSuccess(state, action);
        case AUTH_ERROR:
        case LOGOUT_SUCCESS:
        case TOKEN_REFRESH_FAIL:
            localStorage.removeItem('token_access')
            localStorage.removeItem('token_refresh')
            return {
                ...state,
                data: [],
                showSuccessModal: false
            }
        default:
            return state;
    }
}

export default reducer;