import React, { lazy } from "react"
import * as MyPrivateRouters from "../routes/PrivateRouter";
import { Switch } from 'react-router-dom';

;
const LayoutConfigurationProfessionnelListe = lazy(() =>
    import(
      "../../components/configuration/professionnelMedical/liste/LayoutConfigurationProfessionnelListe"
    )
);
const LayoutConfigurationImportProfessionnel = lazy(() =>
  import(
    "../../components/configuration/professionnelMedical/importProfessionnel/LayoutConfigurationImportProfessionnel"
  )
);
const LayoutConfigurationProfessionnelCreate = lazy(() =>
    import(
        "../../components/configuration/professionnelMedical/create/LayoutConfigurationProfessionnelCreate"
    )
);
const LayoutConfigurationProfessionnelUpdate = lazy(() =>
    import(
        "../../components/configuration/professionnelMedical/update/LayoutConfigurationProfessionnelUpdate"
    )
);
const LayoutConfigurationProfessionnelDetails = lazy(() =>
    import(
        "../../components/configuration/professionnelMedical/voir/LayoutConfigurationProfessionnelDetails"
    )
);

const LayoutConfigurationUtilisateurListe = lazy(() =>
    import(
        "../../components/configuration/Utilisateurs/liste/LayoutConfigurationUtilisateurListe"
    )
);
const LayoutConfigurationUtilisateurCreate = lazy(() =>
    import(
        "../../components/configuration/Utilisateurs/create/LayoutConfigurationUtilisateurCreate"
    )
);
const LayoutConfigurationUtilisateurUpdate = lazy(() =>
    import(
        "../../components/configuration/Utilisateurs/update/LayoutConfigurationUtilisateurUpdate"
    )
);
const LayoutConfigurationUtilisateurUpdateSuper = lazy(() =>
    import(
        "../../components/configuration/Utilisateurs/updateSuper/LayoutConfigurationUtilisateurUpdateSuper"
    )
);

const LayoutConfigurationRolesListe = lazy(() =>
import("../../components/configuration/groupes/LayoutConfigurationRolesListe")
);

const LayoutConfigurationBatimentsListe = lazy(() =>
    import(
        "../../components/configuration/batiments/liste/LayoutConfigurationBatimentsListe"
    )
);
const LayoutConfigurationBatimentsCreate = lazy(() =>
    import(
        "../../components/configuration/batiments/create/LayoutConfigurationBatimentsCreate"
    )
);
const LayoutConfigurationBatimentsUpdate = lazy(() =>
    import(
        "../../components/configuration/batiments/update/LayoutConfigurationBatimentsUpdate"
    )
);

const LayoutConfigurationChambreListe = lazy(() =>
    import(
        "../../components/configuration/chambres/liste/LayoutConfigurationChambreListe"
    )
);
const LayoutConfigurationChambreCreate = lazy(() =>
    import(
        "../../components/configuration/chambres/create/LayoutConfigurationChambreCreate"
    )
);
const LayoutConfigurationChambreUpdate = lazy(() =>
    import(
        "../../components/configuration/chambres/update/LayoutConfigurationChambreUpdate"
    )
);

const LayoutConfigurationLitsListe = lazy(() =>
import("../../components/configuration/lits/liste/LayoutConfigurationLitsListe")
);
const LayoutConfigurationLitsCreate = lazy(() =>
import("../../components/configuration/lits/create/LayoutConfigurationLitsCreate")
);
const LayoutConfigurationLitsUpdate = lazy(() =>
import("../../components/configuration/lits/update/LayoutConfigurationLitsUpdate")
);

const LayoutConfigurationBlocOperatoireListe = lazy(() =>
    import(
        "../../components/configuration/blocOperatoires/liste/LayoutConfigurationBlocOperatoireListe"
    )
);
const LayoutConfigurationBlocOperatoireUpdate = lazy(() =>
    import(
        "../../components/configuration/blocOperatoires/update/LayoutConfigurationBlocOperatoireUpdate"
    )
);
const LayoutConfigurationBlocOperatoireCreate = lazy(() =>
    import(
        "../../components/configuration/blocOperatoires/create/LayoutConfigurationBlocOperatoireCreate"
    )
);

const LayoutConfigurationMotifsCentreListe = lazy(() =>
    import(
        "../../components/configuration/prestations/motifsCentres/liste/LayoutConfigurationMotifsCentreListe"
    )
);
const LayoutConfigurationMotifsCentreCreate = lazy(() =>
    import(
        "../../components/configuration/prestations/motifsCentres/create/LayoutConfigurationMotifsCentreCreate"
    )
);
const LayoutConfigurationMotifsCentreUpdate = lazy(() =>
    import(
        "../../components/configuration/prestations/motifsCentres/update/LayoutConfigurationMotifsCentreUpdate"
    )
);


const LayoutConfigurationAssurancesCentreListe = lazy(() =>
    import(
        "../../components/configuration/prestations/assurances/liste/LayoutConfigurationAssurancesCentreListe"
    )
);
const LayoutConfigurationAssurancesCentreCreate = lazy(() =>
    import(
        "../../components/configuration/prestations/assurances/create/LayoutConfigurationAssurancesCentreCreate"
    )
);
const LayoutConfigurationAssurancesCentreUpdate = lazy(() =>
    import(
        "../../components/configuration/prestations/assurances/update/LayoutConfigurationAssurancesCentreUpdate"
    )
);


const LayoutConfigurationConfigParAssurancesListe = lazy(() =>
    import(
        "../../components/configuration/prestations/configurationParAssurances/liste/LayoutConfigurationConfigParAssurancesListe"
    )
);
const LayoutConfigurationConfigParAssurancesCreate = lazy(() =>
    import(
        "../../components/configuration/prestations/configurationParAssurances/create/LayoutConfigurationConfigParAssurancesCreate"
    )
);
const LayoutConfigurationConfigParAssurancesUpdate = lazy(() =>
    import(
        "../../components/configuration/prestations/configurationParAssurances/update/LayoutConfigurationConfigParAssurancesUpdate"
    )
);



const LayoutConfigurationParametresDetails = lazy(() =>
    import(
        "../../components/configuration/parametres/voir/LayoutConfigurationParametresDetails"
    )
);
const LayoutConfigurationParametresUpdate = lazy(() =>
    import(
        "../../components/configuration/parametres/update/LayoutConfigurationParametresUpdate"
    )
);

const LayoutConfigurationLogsListe = lazy(() =>
    import(
        "../../components/configuration/logFiles/LayoutConfigurationLogsListe"
    )
);

const LayoutConfigurationParametreActesLaboListe = lazy(() => 
import("../../components/configuration/parametreActesLabo/configurationActes/Liste/LayoutConfigurationParametreActesLaboListe")
)

const LayoutConfigurationParametreActesLaboCreate = lazy(() => 
import("../../components/configuration/parametreActesLabo/configurationActes/Forme/LayoutConfigurationParametreActesLaboCreate")
)

const LayoutConfigurationConfigurationActesListe = lazy(() => 
import("../../components/configuration/parametreActesLabo/gestionParametres/Liste/LayoutConfigurationConfigurationActesListe")
)

const LayoutConfigurationConfigurationActesCreate = lazy(() => 
import("../../components/configuration/parametreActesLabo/gestionParametres/Forme/LayoutConfigurationConfigurationActesCreate")
)

const LayoutListeCatChambre = lazy(() => 
    import("../../components/configuration/CategoryChambre/Liste/LayoutListeCatChambre")
)
const LayoutCreateCatChambre = lazy(() => 
    import("../../components/configuration/CategoryChambre/Create/LayoutCreateCatChambre")
);
const LayoutUpdateCatChambre = lazy(() => 
    import("../../components/configuration/CategoryChambre/Update/LayoutUpdateCatChambre")
);

  

export default function ConfigurationRouter() {
  return (
    <Switch>
      <MyPrivateRouters.PrivateRouterConfiguration
        path="/configuration"
        component={LayoutConfigurationProfessionnelListe}
      />
      <MyPrivateRouters.PrivateRouterConfiguration
        path="/configuration-import-personnels"
        component={LayoutConfigurationImportProfessionnel}
      />

      <MyPrivateRouters.PrivateRouterConfiguration
        path="/configuration-create"
        component={LayoutConfigurationProfessionnelCreate}
      />
      <MyPrivateRouters.PrivateRouterConfiguration
        path="/configuration-update/:id"
        component={LayoutConfigurationProfessionnelUpdate}
      />
      <MyPrivateRouters.PrivateRouterConfiguration
        path="/configuration-professionnel-voir/:id/"
        component={LayoutConfigurationProfessionnelDetails}
      />
      <MyPrivateRouters.PrivateRouterConfiguration
        path="/configuration-utilisateurs-list/"
        component={LayoutConfigurationUtilisateurListe}
      />
      <MyPrivateRouters.PrivateRouterConfiguration
        path="/configuration-utilisateurs-create/"
        component={LayoutConfigurationUtilisateurCreate}
      />
      <MyPrivateRouters.PrivateRouterConfiguration
        path="/configuration-utilisateurs-update/:id/"
        component={LayoutConfigurationUtilisateurUpdate}
      />
      <MyPrivateRouters.PrivateRouterConfiguration
        path="/configuration-utilisateurs-update-super/:id/"
        component={LayoutConfigurationUtilisateurUpdateSuper}
      />
      <MyPrivateRouters.PrivateRouterConfiguration
        path="/configuration-roles"
        component={LayoutConfigurationRolesListe}
      />
      <MyPrivateRouters.PrivateRouterConfiguration
        path="/configuration-chambres"
        component={LayoutConfigurationChambreListe}
      />
      <MyPrivateRouters.PrivateRouterConfiguration
        path="/configuration-chambres-create"
        component={LayoutConfigurationChambreCreate}
      />
      <MyPrivateRouters.PrivateRouterConfiguration
        path="/configuration-chambres-update/:id"
        component={LayoutConfigurationChambreUpdate}
      />
      <MyPrivateRouters.PrivateRouterConfiguration
        path="/configuration-lits"
        component={LayoutConfigurationLitsListe}
      />
      <MyPrivateRouters.PrivateRouterConfiguration
        path="/configuration-lits-create"
        component={LayoutConfigurationLitsCreate}
      />
      <MyPrivateRouters.PrivateRouterConfiguration
        path="/configuration-lits-update/:id"
        component={LayoutConfigurationLitsUpdate}
      />
      <MyPrivateRouters.PrivateRouterConfiguration
        path="/configuration-bloc-operatoires"
        component={LayoutConfigurationBlocOperatoireListe}
      />
      <MyPrivateRouters.PrivateRouterConfiguration
        path="/configuration-bloc-operatoires-create"
        component={LayoutConfigurationBlocOperatoireCreate}
      />
      <MyPrivateRouters.PrivateRouterConfiguration
        path="/configuration-bloc-operatoires-update/:id"
        component={LayoutConfigurationBlocOperatoireUpdate}
      />
      <MyPrivateRouters.PrivateRouterConfiguration
        path="/configuration-batiments"
        component={LayoutConfigurationBatimentsListe}
      />
      <MyPrivateRouters.PrivateRouterConfiguration
        path="/configuration-categorie-chambre"
        component={LayoutListeCatChambre}
      />
      <MyPrivateRouters.PrivateRouterConfiguration
        path="/configuration-categorie-chambre-create"
        component={LayoutCreateCatChambre}
      />
      <MyPrivateRouters.PrivateRouterConfiguration
        path="/configuration-categorie-chambre-update/:id"
        component={LayoutUpdateCatChambre}
      />
      <MyPrivateRouters.PrivateRouterConfiguration
        path="/configuration-batiments-create"
        component={LayoutConfigurationBatimentsCreate}
      />
      <MyPrivateRouters.PrivateRouterConfiguration
        path="/configuration-batiments-update/:id"
        component={LayoutConfigurationBatimentsUpdate}
      />
      <MyPrivateRouters.PrivateRouterConfiguration
        path="/configuration-motifs-paiements"
        component={LayoutConfigurationMotifsCentreListe}
      />
      <MyPrivateRouters.PrivateRouterConfiguration
        path="/configuration-motifs-paiements-create"
        component={LayoutConfigurationMotifsCentreCreate}
      />
      <MyPrivateRouters.PrivateRouterConfiguration
        path="/configuration-motifs-paiements-update/:id"
        component={LayoutConfigurationMotifsCentreUpdate}
      />
      <MyPrivateRouters.PrivateRouterConfiguration
        path="/configuration-config-prestations-assurance"
        component={LayoutConfigurationConfigParAssurancesListe}
      />
      <MyPrivateRouters.PrivateRouterConfiguration
        path="/configuration-config-prestations-assurance-create"
        component={LayoutConfigurationConfigParAssurancesCreate}
      />
      <MyPrivateRouters.PrivateRouterConfiguration
        path="/configuration-config-prestations-assurance-update/:id"
        component={LayoutConfigurationConfigParAssurancesUpdate}
      />
      <MyPrivateRouters.PrivateRouterConfiguration
        path="/configuration-assurances"
        component={LayoutConfigurationAssurancesCentreListe}
      />
      <MyPrivateRouters.PrivateRouterConfiguration
        path="/configuration-assurances-create"
        component={LayoutConfigurationAssurancesCentreCreate}
      />
      <MyPrivateRouters.PrivateRouterConfiguration
        path="/configuration-assurances-update/:id"
        component={LayoutConfigurationAssurancesCentreUpdate}
      />
      <MyPrivateRouters.PrivateRouterConfiguration
        path="/configuration-parametres"
        component={LayoutConfigurationParametresDetails}
      />
      <MyPrivateRouters.PrivateRouterConfiguration
        path="/configuration-parametres-update/:id"
        component={LayoutConfigurationParametresUpdate}
      />
      <MyPrivateRouters.PrivateRouterConfiguration
        path="/configuration-logs"
        component={LayoutConfigurationLogsListe}
      />
      <MyPrivateRouters.PrivateRouterConfiguration
        path="/configuration-parametre-acte-labo"
        component={LayoutConfigurationParametreActesLaboListe}
      />
      <MyPrivateRouters.PrivateRouterConfiguration
        path="/configuration-parametre-acte-labo-create"
        component={LayoutConfigurationParametreActesLaboCreate}
      />
      <MyPrivateRouters.PrivateRouterConfiguration
        path="/configuration-acte-labo"
        component={LayoutConfigurationConfigurationActesListe}
      />
      <MyPrivateRouters.PrivateRouterConfiguration
        path="/configuration-acte-labo-create"
        component={LayoutConfigurationConfigurationActesCreate}
      />
    </Switch>
  );
}
