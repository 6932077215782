import { actesTypes } from "../types/actesTypes";
import { AUTH_ERROR, LOGOUT_SUCCESS, TOKEN_REFRESH_FAIL } from "../types/userTypes";

const initialState = {
  isLoading: false,
  isLoadingMedicaux:false,
  actes: [],
  actes_medicaux: [],
  error: "",

  actesExamenExterne: [],
  actesExamenExterneLoader: false,
  actesExamenExterneError: false,
  actesExamenExterneSuccess: false,
};

export const actesListReducer = (state = initialState, action) => {
  switch (action.type) {
    //actes medicaux fiches de paiement
    case actesTypes.ACTES_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: "",
      };
    case actesTypes.ACTES_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        actes: action.payload,
        error: "",
      };
    case actesTypes.ACTES_LIST_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    
      //actes medicaux
    case actesTypes.ACTES_MEDICAUX_LIST_REQUEST:
      return {
        ...state,
        isLoadingMedicaux: true,
        error: "",
      };
    case actesTypes.ACTES_MEDICAUX_LIST_SUCCESS:
      return {
        ...state,
        isLoadingMedicaux: false,
        actes_medicaux: action.payload,
        error: "",
      };
    case actesTypes.ACTES_MEDICAUX_LIST_FAIL:
      return {
        ...state,
        isLoadingMedicaux: false,
        error: action.payload,
      };

    //actes à poser pour les examens externes
    case actesTypes.ACTES_EXAMEN_EXTERNE_REQUEST:
      return {
        ...state,
        actesExamenExterne: [],
        actesExamenExterneLoader: true,
        actesExamenExterneError: false,
        actesExamenExterneSuccess: false,
      };
    case actesTypes.ACTES_EXAMEN_EXTERNE_SUCCESS:
      return {
        ...state,
        actesExamenExterne: action.payload,
        actesExamenExterneLoader: false,
        actesExamenExterneError: false,
        actesExamenExterneSuccess: true,
      };
    case actesTypes.ACTES_EXAMEN_EXTERNE_FAILED:
      return {
        ...state,
        actesExamenExterne: [],
        actesExamenExterneLoader: false,
        actesExamenExterneError: true,
        actesExamenExterneSuccess: false,
      };
    
    case AUTH_ERROR:
    case LOGOUT_SUCCESS:
    case TOKEN_REFRESH_FAIL:
        localStorage.removeItem('token_access')
        localStorage.removeItem('token_refresh')
        return {
            ...state,
            isLoading: false,
            isLoadingMedicaux:false,
            actes: [],
            actes_medicaux: [],
            error: "",
            actesExamenExterne: [],
            actesExamenExterneLoader: false,
            actesExamenExterneError: false,
            actesExamenExterneSuccess: false,
        }

    default:
      return state;
  }
};
