import { fichePaiementTypes } from "../../types/fichePaiementTypes";
import { AUTH_ERROR, LOGOUT_SUCCESS, TOKEN_REFRESH_FAIL } from "../../types/userTypes";

const initialState = {
  isLoading: false,
  fichesPaiements: [],
  error: "",
};

export const fichePaiementListReducer = (state = initialState, action) => {
  switch (action.type) {
    case fichePaiementTypes.FICHEPAIEMENT_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: "",
      };
    case fichePaiementTypes.FICHEPAIEMENT_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        fichesPaiements: action.payload,
        error: "",
      };
    case fichePaiementTypes.FICHEPAIEMENT_LIST_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    
    case AUTH_ERROR:
    case LOGOUT_SUCCESS:
    case TOKEN_REFRESH_FAIL:
        localStorage.removeItem('token_access')
        localStorage.removeItem('token_refresh')
        return {
            ...state,
            isLoading: false,
            fichesPaiements: [],
            error: "",
        }

    default:
      return state;
  }
};
