export const GYNECOLOGIE_GET_PATIENTS_A_CONSULTER_DATA_REQUEST = 'GYNECOLOGIE_GET_PATIENTS_A_CONSULTER_DATA_REQUEST';
export const GYNECOLOGIE_GET_PATIENTS_A_CONSULTER_DATA_SUCCESS = 'GYNECOLOGIE_GET_PATIENTS_A_CONSULTER_DATA_SUCCESS';
export const GYNECOLOGIE_GET_PATIENTS_A_CONSULTER_DATA_FAILED = "GYNECOLOGIE_GET_PATIENTS_A_CONSULTER_DATA_FAILED";
export const GYNECOLOGIE_GET_ORDONNANCE_DATA_SUCCESS = 'GYNECOLOGIE_GET_ORDONNANCE_DATA_SUCCESS';
export const GYNECOLOGIE_GET_ORDONNANCE_DATA_REQUEST = 'GYNECOLOGIE_GET_ORDONNANCE_DATA_REQUEST';
export const GYNECOLOGIE_GET_PATIENTS_DEJA_CONSULTER_DATA_SUCCESS = 'GYNECOLOGIE_GET_PATIENTS_DEJA_CONSULTER_DATA_SUCCESS';
export const GYNECOLOGIE_GET_PATIENTS_DEJA_CONSULTER_DATA_REQUEST = 'GYNECOLOGIE_GET_PATIENTS_DEJA_CONSULTER_DATA_REQUEST';
export const GYNECOLOGIE_GET_DEMANDES_D_HOSPITALISATION_DATA_SUCCESS = 'GYNECOLOGIE_GET_DEMANDES_D_HOSPITALISATION_DATA_SUCCESS';
export const GYNECOLOGIE_GET_HOSPITALISATIONS_DATA_SUCCESS = 'GYNECOLOGIE_GET_HOSPITALISATIONS_DATA_SUCCESS';
export const GYNECOLOGIE_GET_RDV_DATA_SUCCESS = 'GYNECOLOGIE_GET_RDV_DATA_SUCCESS';


export const GYNECOLOGIE_POST_PATIENTS_A_CONSULTER_DATA_SUCCESS = 'GYNECOLOGIE_POST_PATIENTS_A_CONSULTER_DATA_SUCCESS';
export const GYNECOLOGIE_POST_ORDONNANCE_DATA_SUCCESS = 'GYNECOLOGIE_POST_ORDONNANCE_DATA_SUCCESS';
export const GYNECOLOGIE_POST_PATIENTS_DEJA_CONSULTER_DATA_SUCCESS = 'GYNECOLOGIE_POST_PATIENTS_DEJA_CONSULTER_DATA_SUCCESS';
export const GYNECOLOGIE_POST_DEMANDES_D_HOSPITALISATION_DATA_SUCCESS = 'GYNECOLOGIE_POST_DEMANDES_D_HOSPITALISATION_DATA_SUCCESS';
export const GYNECOLOGIE_POST_HOSPITALISATIONS_DATA_SUCCESS = 'GYNECOLOGIE_POST_HOSPITALISATIONS_DATA_SUCCESS';
export const GYNECOLOGIE_POST_RDV_DATA_SUCCESS = 'GYNECOLOGIE_POST_RDV_DATA_SUCCESS';


export const GYNECOLOGIE_PUT_PATIENTS_A_CONSULTER_DATA_SUCCESS = 'GYNECOLOGIE_PUT_PATIENTS_A_CONSULTER_DATA_SUCCESS';
export const GYNECOLOGIE_PUT_ORDONNANCE_DATA_SUCCESS = 'GYNECOLOGIE_PUT_ORDONNANCE_DATA_SUCCESS';
export const GYNECOLOGIE_PUT_PATIENTS_DEJA_CONSULTER_DATA_SUCCESS = 'GYNECOLOGIE_PUT_PATIENTS_DEJA_CONSULTER_DATA_SUCCESS';
export const GYNECOLOGIE_PUT_DEMANDES_D_HOSPITALISATION_DATA_SUCCESS = 'GYNECOLOGIE_PUT_DEMANDES_D_HOSPITALISATION_DATA_SUCCESS';
export const GYNECOLOGIE_PUT_HOSPITALISATIONS_DATA_SUCCESS = 'GYNECOLOGIE_PUT_HOSPITALISATIONS_DATA_SUCCESS';
export const GYNECOLOGIE_PUT_RDV_DATA_SUCCESS = 'GYNECOLOGIE_PUT_RDV_DATA_SUCCESS';


export const GYNECOLOGIE_DELETE_PATIENTS_A_CONSULTER_DATA_SUCCESS = 'GYNECOLOGIE_DELETE_PATIENTS_A_CONSULTER_DATA_SUCCESS';
export const GYNECOLOGIE_ORDONNANCE_DATA_SUCCESS = 'GYNECOLOGIE_ORDONNANCE_DATA_SUCCESS';
export const GYNECOLOGIE_DELETE_PATIENTS_DEJA_CONSULTER_DATA_SUCCESS = 'GYNECOLOGIE_DELETE_PATIENTS_DEJA_CONSULTER_DATA_SUCCESS';
export const GYNECOLOGIE_DELETE_DEMANDES_D_HOSPITALISATION_DATA_SUCCESS = 'GYNECOLOGIE_DELETE_DEMANDES_D_HOSPITALISATION_DATA_SUCCESS';
export const GYNECOLOGIE_DELETE_HOSPITALISATIONS_DATA_SUCCESS = 'GYNECOLOGIE_DELETE_HOSPITALISATIONS_DATA_SUCCESS';
export const GYNECOLOGIE_DELETE_RDV_DATA_SUCCESS = 'GYNECOLOGIE_DELETE_RDV_DATA_SUCCESS';



//======================================================================================//
//                                 TYPE DE GYNECO DEBUT                                 //
//======================================================================================//

export const PATIENT_A_CONSULTER_GYNECO_GET_LOADING =  "PATIENT_A_CONSULTER_GYNECO_GET_LOADING"

export const PATIENT_A_CONSULTER_GYNECO_GET_SUCCESS =  "PATIENT_A_CONSULTER_GYNECO_GET_SUCCESS"

export const PATIENT_A_CONSULTER_GYNECO_GET_FAILED =  "PATIENT_A_CONSULTER_GYNECO_GET_FAILED"


export const MALADIES_GYNECO_GET_LOADING = 'MALADIES_GYNECO_GET_LOADING'

export const MALADIES_GYNECO_GET_SUCCESS = 'MALADIES_GYNECO_GET_SUCCESS'

export const MALADIES_GYNECO_GET_FAILED = 'MALADIES_GYNECO_GET_FAILED'


export const ACTES_CHIRUGICAUX_GYNECO_GET_LOADING = 'ACTES_CHIRUGICAUX_GYNECO_GET_LOADING'

export const ACTES_CHIRUGICAUX_GYNECO_GET_SUCCESS = 'ACTES_CHIRUGICAUX_GYNECO_GET_SUCCESS'

export const ACTES_CHIRUGICAUX_GYNECO_GET_FAILED = 'ACTES_CHIRUGICAUX_GYNECO_GET_FAILED'


export const CONSULTATION_GYNECO_GET_LOADING = 'CONSULTATION_GYNECO_GET_LOADING'

export const CONSULTATION_GYNECO_GET_SUCCESS = 'CONSULTATION_GYNECO_GET_SUCCESS'

export const CONSULTATION_GYNECO_GET_PAS_TROUVER = "CONSULTATION_GYNECO_GET_PAS_TROUVER"

export const CONSULTATION_GYNECO_GET_FAILED = 'CONSULTATION_GYNECO_GET_FAILED'


export const CONSULTATION_GYNECO_POST_LOADING = 'CONSULTATION_GYNECO_POST_LOADING'

export const CONSULTATION_GYNECO_POST_SUCCESS = 'CONSULTATION_GYNECO_POST_SUCCESS'

export const CONSULTATION_GYNECO_POST_FAILED = 'CONSULTATION_GYNECO_POST_FAILED'


export const CONSULTATION_GYNECO_POST_TERMINER_LOADING = 'CONSULTATION_GYNECO_POST_TERMINER_LOADING'

export const CONSULTATION_GYNECO_POST_TERMINER_SUCCESS = 'CONSULTATION_GYNECO_POST_TERMINER_SUCCESS'

export const CONSULTATION_GYNECO_POST_TERMINER_FAILED = 'CONSULTATION_GYNECO_POST_TERMINER_FAILED'


export const ISSU_DEMANDE_HOSPITALISATION_GYNECO_POST_LOADING = 'ISSU_DEMANDE_HOSPITALISATION_GYNECO_POST_LOADING'

export const ISSU_DEMANDE_HOSPITALISATION_GYNECO_POST_SUCCESS = 'ISSU_DEMANDE_HOSPITALISATION_GYNECO_POST_SUCCESS'

export const ISSU_DEMANDE_HOSPITALISATION_GYNECO_POST_FAILED = 'ISSU_DEMANDE_HOSPITALISATION_GYNECO_POST_FAILED'


export const ISSU_ACTES_INFIRMIERS_GYNECO_POST_LOADING = 'ISSU_ACTES_INFIRMIERS_GYNECO_POST_LOADING'

export const ISSU_ACTES_INFIRMIERS_GYNECO_POST_SUCCESS = 'ISSU_ACTES_INFIRMIERS_GYNECO_POST_SUCCESS'

export const ISSU_ACTES_INFIRMIERS_GYNECO_POST_FAILED = 'ISSU_ACTES_INFIRMIERS_GYNECO_POST_FAILED'


export const REFERE_INTERNE_GYNECO_POST_LOADING = 'REFERE_INTERNE_GYNECO_POST_LOADING'

export const REFERE_INTERNE_GYNECO_POST_SUCCESS = 'REFERE_INTERNE_GYNECO_POST_SUCCESS'

export const REFERE_INTERNE_GYNECO_POST_FAILED = 'REFERE_INTERNE_GYNECO_POST_FAILED'


export const REFERE_EXTERNE_GYNECO_POST_LOADING = 'REFERE_EXTERNE_GYNECO_POST_LOADING'

export const REFERE_EXTERNE_GYNECO_POST_SUCCESS = 'REFERE_EXTERNE_GYNECO_POST_SUCCESS'

export const REFERE_EXTERNE_GYNECO_POST_FAILED = 'REFERE_EXTERNE_GYNECO_POST_FAILED'


export const ISSU_MISE_EN_OBSERVATION_GYNECO_POST_LOADING = 'ISSU_MISE_EN_OBSERVATION_GYNECO_POST_LOADING'

export const ISSU_MISE_EN_OBSERVATION_GYNECO_POST_SUCCESS = 'ISSU_MISE_EN_OBSERVATION_GYNECO_POST_SUCCESS'

export const ISSU_MISE_EN_OBSERVATION_GYNECO_POST_FAILED = 'ISSU_MISE_EN_OBSERVATION_GYNECO_POST_FAILED'


export const ISSU_PRESCRIPTIONS_GYNECO_POST_LOADING = 'ISSU_PRESCRIPTIONS_GYNECO_POST_LOADING'

export const ISSU_PRESCRIPTIONS_GYNECO_POST_SUCCESS = 'ISSU_PRESCRIPTIONS_GYNECO_POST_SUCCESS'

export const ISSU_PRESCRIPTIONS_GYNECO_POST_FAILED = 'ISSU_PRESCRIPTIONS_GYNECO_POST_FAILED'


export const ISSU_SORTIE_CONSULTATION_GYNECO_POST_LOADING = 'ISSU_SORTIE_CONSULTATION_GYNECO_POST_LOADING'

export const ISSU_SORTIE_CONSULTATION_GYNECO_POST_SUCCESS = 'ISSU_SORTIE_CONSULTATION_GYNECO_POST_SUCCESS'

export const ISSU_SORTIE_CONSULTATION_GYNECO_POST_FAILED = 'ISSU_SORTIE_CONSULTATION_GYNECO_POST_FAILED'


export const TYPES_EXAMENS_GYNECO_GET_LOADING = 'TYPES_EXAMENS_GYNECO_GET_LOADING'

export const TYPES_EXAMENS_GYNECO_GET_SUCCESS = 'TYPES_EXAMENS_GYNECO_GET_SUCCESS'

export const TYPES_EXAMENS_GYNECO_GET_FAILED = 'TYPES_EXAMENS_GYNECO_GET_FAILED'


export const ORDONNANCES_GYNECO_GET_LOADING = 'ORDONNANCES_GYNECO_GET_LOADING'

export const ORDONNANCES_GYNECO_GET_SUCCESS = 'ORDONNANCES_GYNECO_GET_SUCCESS'

export const ORDONNANCES_GYNECO_GET_FAILED = 'ORDONNANCES_GYNECO_GET_FAILED'


export const ORDONNANCE_GYNECO_GET_LOADING = 'ORDONNANCE_GYNECO_GET_LOADING'

export const ORDONNANCE_GYNECO_GET_SUCCESS = 'ORDONNANCE_GYNECO_GET_SUCCESS'

export const ORDONNANCE_GYNECO_GET_FAILED = 'ORDONNANCE_GYNECO_GET_FAILED'


export const HOSPITALISATION_GYNECO_GET_DATA_LOADING = "HOSPITALISATION_GYNECO_GET_DATA_LOADING"

export const HOSPITALISATION_GYNECO_GET_DATA_SUCCESS = "HOSPITALISATION_GYNECO_GET_DATA_SUCCESS"

export const HOSPITALISATION_GYNECO_GET_DATA_FAILED = "HOSPITALISATION_GYNECO_GET_DATA_FAILED"



export const HOSPITALISATION_VALIDEE_GYNECO_GET_DATA_LOADING = "HOSPITALISATION_VALIDEE_GYNECO_GET_DATA_LOADING"

export const HOSPITALISATION_VALIDEE_GYNECO_GET_DATA_SUCCESS = "HOSPITALISATION_VALIDEE_GYNECO_GET_DATA_SUCCESS"

export const HOSPITALISATION_VALIDEE_GYNECO_GET_DATA_FAILED = "HOSPITALISATION_VALIDEE_GYNECO_GET_DATA_FAILED"


export const CONSULTATION_GYNECO_RESET = 'CONSULTATION_GYNECO_RESET'

export const CONSULTATION_GYNECO_RESET_AFTER = "CONSULTATION_GYNECO_RESET_AFTER"

export const VACCINS_GYNECO_GET_REQUEST = "VACCINS_GYNECO_GET_REQUEST"
export const VACCINS_GYNECO_GET_SUCCESS = "VACCINS_GYNECO_GET_SUCCESS"
export const VACCINS_GYNECO_GET_FAIL = "VACCINS_GYNECO_GET_FAIL"

export const PATHOLOGIE_MAMMAIRE_GET = "PATHOLOGIE_MAMMAIRE_GET"
export const PATHOLOGIE_OVARIENNE_GET = "PATHOLOGIE_OVARIENNE_GET"
export const PATHOLOGIE_UTERINE_GET = "PATHOLOGIE_UTERINE_GET"
export const PATHOLOGIE_URINAIRE_GET = "PATHOLOGIE_URINAIRE_GET"


//======================================================================================//
//                                 TYPE DE GYNECO FIN                                   //
//======================================================================================//
