// import { parametreTypes } from "../../types/parametreTypes";
// import { patientparametreTypes } from "../../types/patientTypes";
import { logTypes } from "../../types/logTypes";
import { AUTH_ERROR, LOGOUT_SUCCESS, TOKEN_REFRESH_FAIL } from "../../types/userTypes";
const initialState = {
    isLoading: false,
    logs: [],
    error: "",
};

export const logListReducers = (state = initialState, action) => {
    switch (action.type) {
        case logTypes.LOG_LIST_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: "",
            };
        case logTypes.LOG_LIST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                logs: action.payload,
                error: "",
            };
        case logTypes.LOG_LIST_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };
        case AUTH_ERROR:
        case LOGOUT_SUCCESS:
        case TOKEN_REFRESH_FAIL:
            localStorage.removeItem('token_access')
            localStorage.removeItem('token_refresh')
            return {
                ...state,
                isLoading: false,
                logs: [],
                error: "",
            }
        default:
            return state;
    }
};
