// import { parametreTypes } from "../../types/parametreTypes";
// import { patientparametreTypes } from "../../types/patientTypes";
import { chambreparametreTypes } from "../../types/chambreTypes";
import {
  AUTH_ERROR,
  LOGOUT_SUCCESS,
  TOKEN_REFRESH_FAIL,
} from "../../types/userTypes";

const initialState = {
  isLoading: false,
  error: "",
  chambres: [],
  chambresDisponibles: [],
  chambre: {},
  deleteLoader: false,
  categorieChambre: {},
  categoriesChambres: [],
  success: false,
};

export const chambreListReducers = (state = initialState, action) => {
  switch (action.type) {
    case chambreparametreTypes.CHAMBRE_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
        success: false,
        error: "",
        chambre: {},
        deleteLoader: false,
      };
    case chambreparametreTypes.CHAMBRE_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        chambres: action.payload,
        success: false,
        error: "",
        chambre: {},
        deleteLoader: false,
      };
    case chambreparametreTypes.CHAMBRE_LIST_FAIL:
      return {
        ...state,
        isLoading: false,
        success: false,
        error: action.payload,
        chambre: {},
        deleteLoader: false,
      };

      case chambreparametreTypes.CHAMBRE_LIST_DISPONIBLE_REQUEST:
        return {
          ...state,
          isLoading: true,
          success: false,
          chambresDisponibles: [],
          error: "",
          chambre: {},
          deleteLoader: false,
        };
      case chambreparametreTypes.CHAMBRE_LIST_DISPONIBLE_SUCCESS:
        return {
          ...state,
          isLoading: false,
          chambresDisponibles: action.payload,
          success: false,
          error: "",
          chambre: {},
          deleteLoader: false,
        };
      case chambreparametreTypes.CHAMBRE_LIST_DISPONIBLE_FAIL:
        return {
          ...state,
          isLoading: false,
          success: false,
          chambresDisponibles: [],
          error: action.payload,
          chambre: {},
          deleteLoader: false,
        };

    case chambreparametreTypes.CHAMBRE_POST_LOADING:
      return {
        ...state,
        isLoading: true,
        success: false,
        error: "",
        chambre: {},
        deleteLoader: false,
      };
    case chambreparametreTypes.CHAMBRE_POST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        chambres: [action.payload, ...state.chambres],
        success: true,
        error: "",
        chambre: {},
        deleteLoader: false,
      };
    case chambreparametreTypes.CHAMBRE_POST_FAILED:
      return {
        ...state,
        isLoading: false,
        success: false,
        error: action.payload,
        chambre: {},
        deleteLoader: false,
      };
    case chambreparametreTypes.CHAMBRE_DETAIL_REQUEST:
      return {
        ...state,
        isLoading: true,
        success: false,
        error: "",
        chambre: {},
        deleteLoader: false,
      };
    case chambreparametreTypes.CHAMBRE_DETAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: false,
        error: "",
        chambre: { ...action.payload },
        deleteLoader: false,
      };
    case chambreparametreTypes.CHAMBRE_DETAIL_FAIL:
      return {
        ...state,
        isLoading: false,
        success: false,
        error: action.payload,
        chambre: {},
        deleteLoader: false,
      };
    case chambreparametreTypes.CHAMBRE_UPDATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        success: false,
        error: "",
        chambre: {},
        deleteLoader: false,
      };
    case chambreparametreTypes.CHAMBRE_UPDATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        chambres: state.chambres.map((chambre) =>
          chambre.id === action.payload.id ? action.payload : chambre
        ),
        success: true,
        error: "",
        chambre: {},
        deleteLoader: false,
      };
    case chambreparametreTypes.CHAMBRE_UPDATE_FAIL:
      return {
        ...state,
        isLoading: false,
        success: false,
        error: action.payload,
        chambre: {},
        deleteLoader: false,
      };
    case chambreparametreTypes.CHAMBRE_DELETE_REQUEST:
      return {
        ...state,
        deleteLoader: true,
        error: "",
      };
    case chambreparametreTypes.CHAMBRE_DELETE_SUCCESS:
      return {
        ...state,
        deleteLoader: false,
        chambres: state.chambres.filter(
          (chambre) => chambre.id !== action.payload
        ),
        error: "",
        success: true,
      };
    case chambreparametreTypes.CHAMBRE_DELETE_FAIL:
      return {
        ...state,
        deleteLoader: false,
        error: action.payload,
      }
    case chambreparametreTypes.CAT_CHAMBRE_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: "",
      };
    case chambreparametreTypes.CAT_CHAMBRE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        categoriesChambres: action.payload,
        error: "",
      };
    case chambreparametreTypes.CAT_CHAMBRE_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      }
    case chambreparametreTypes.CAT_CHAMBRE_ADD_REQUEST:
      return {
        ...state,
        isLoading: true,
        success: false,
        error: "",
      };
    case chambreparametreTypes.CAT_CHAMBRE_ADD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
        error: "",
      };
    case chambreparametreTypes.CAT_CHAMBRE_ADD_FAIL:
      return {
        ...state,
        isLoading: false,
        success: false,
        error: action.payload,
      }
    case chambreparametreTypes.CAT_CHAMBRE_UPDATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        success: false,
        error: "",
      };
    case chambreparametreTypes.CAT_CHAMBRE_UPDATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
        error: "",
      };
    case chambreparametreTypes.CAT_CHAMBRE_UPDATE_FAIL:
      return {
        ...state,
        isLoading: false,
        success: false,
        error: action.payload,
      }
      case chambreparametreTypes.ONE_CAT_CHAMBRE_REQUEST:
        return {
          ...state,
          isLoading: true,
          categorieChambre: {},
          error: "",
        };
      case chambreparametreTypes.ONE_CAT_CHAMBRE_SUCCESS:
        return {
          ...state,
          isLoading: false,
          categorieChambre: action.payload,
          error: "",
        };
      case chambreparametreTypes.ONE_CAT_CHAMBRE_FAIL:
        return {
          ...state,
          isLoading: false,
          categorieChambre: {},
          error: action.payload,
        }
      case chambreparametreTypes.DELETE_CAT_CHAMBRE_REQUEST:
        return {
          ...state,
          isLoading: true,
          success: false,
          error: "",
        };
      case chambreparametreTypes.DELETE_CAT_CHAMBRE_SUCCESS:
        return {
          ...state,
          isLoading: false,
          success: true,
          categoriesChambres: state.categoriesChambres.filter(item => item.id !== action.payload),
          error: "",
        }
      case chambreparametreTypes.DELETE_CAT_CHAMBRE_FAIL:
        return {
          ...state,
          isLoading: false,
          success: false,
          error: action.payload,
        };
    case "SUCCESS_CLEAN":
        return {
            ...state,
            success: false,
            error: "",
        }

    case AUTH_ERROR:
    case LOGOUT_SUCCESS:
    case TOKEN_REFRESH_FAIL:
      localStorage.removeItem("token_access");
      localStorage.removeItem("token_refresh");
      return {
        ...state,
        isLoading: false,
        success: false,
        error: "",
        chambres: [],
        chambresDisponibles: [],
        chambre: {},
        deleteLoader: false,
      };

    default:
      return state;
  }
};
