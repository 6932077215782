export const HOSPITALISATION_GET_DATA_SUCCESS = 'HOSPITALISATION_GET_DATA_SUCCESS'

export const HOSPITALISATION_GET_DATA_FAILED = 'HOSPITALISATION_GET_DATA_FAILED'

export const HOSPITALISATION_GET_DATA_LOADING = 'HOSPITALISATION_GET_DATA_LOADING'

export const hospitalisationTypes = {
    DEMANDE_HOSPITALISATION_LIST_REQUEST: "DEMANDE_HOSPITALISATION_LIST_REQUEST",
    DEMANDE_HOSPITALISATION_LIST_SUCCESS: "DEMANDE_HOSPITALISATION_LIST_SUCCESS",
    DEMANDE_HOSPITALISATION_LIST_FAIL: "DEMANDE_HOSPITALISATION_LIST_FAIL",

    DEMANDE_HOSPITALISATION_LIST_REFRESH_REQUEST: "DEMANDE_HOSPITALISATION_LIST_REFRESH_REQUEST",
    DEMANDE_HOSPITALISATION_LIST_REFRESH_SUCCESS: "DEMANDE_HOSPITALISATION_LIST_REFRESH_SUCCESS",
    DEMANDE_HOSPITALISATION_LIST_REFRESH_FAIL: "DEMANDE_HOSPITALISATION_LIST_REFRESH_FAIL",

    PATIENT_HOSPITALISATION_REQUEST: "PATIENT_HOSPITALISATION_REQUEST",
    PATIENT_HOSPITALISATION_SUCCESS: "PATIENT_HOSPITALISATION_SUCCESS",
    PATIENT_HOSPITALISATION_FAIL: "PATIENT_HOSPITALISATION_FAIL",

    HOSPITALISATIONS_LIST_REQUEST: "HOSPITALISATIONS_LIST_REQUEST",
    HOSPITALISATIONS_LIST_SUCCESS: "HOSPITALISATIONS_LIST_SUCCESS",
    HOSPITALISATIONS_LIST_FAIL: "HOSPITALISATIONS_LIST_FAIL",

    HOSPITALISATION_ORIENTATION_REQUEST: "HOSPITALISATION_ORIENTATION_REQUEST",
    HOSPITALISATION_ORIENTATION_SUCCESS: "HOSPITALISATION_ORIENTATION_SUCCESS",
    HOSPITALISATION_ORIENTATION_FAIL: "HOSPITALISATION_ORIENTATION_FAIL",
    HOSPITALISATION_CANCEL_REQUEST: "HOSPITALISATION_CANCEL_REQUEST",
    HOSPITALISATION_CANCEL_SUCCESS: "HOSPITALISATION_CANCEL_SUCCESS",

    HOSPITALISATIONS_LIST_ACCUEIL_LOADING: "HOSPITALISATIONS_LIST_ACCUEIL_LOADING",
    HOSPITALISATIONS_LIST_ACCUEIL_SUCCESS: "HOSPITALISATIONS_LIST_ACCUEIL_SUCCESS",
    HOSPITALISATIONS_LIST_ACCUEIL_FAILED: "HOSPITALISATIONS_LIST_ACCUEIL_FAILED",

    HOSPITALISATIONS_LIST_ACCUEIL_REFRESH_LOADING: "HOSPITALISATIONS_LIST_ACCUEIL_REFRESH_LOADING",
    HOSPITALISATIONS_LIST_ACCUEIL_REFRESH_SUCCESS: "HOSPITALISATIONS_LIST_ACCUEIL_REFRESH_SUCCESS",
    HOSPITALISATIONS_LIST_ACCUEIL_REFRESH_FAILED: "HOSPITALISATIONS_LIST_ACCUEIL_REFRESH_FAILED",

    HOSPITALISATION_CANCEL_FAIL: "HOSPITALISATION_CANCEL_FAIL",
    HOSPITALISATION_RESET_STATE: "HOSPITALISATION_RESET_STATE",

    PATIENT_ALL_HOSPITALISATIONS_REQUEST: "PATIENT_ALL_HOSPITALISATIONS_REQUEST",
    PATIENT_ALL_HOSPITALISATIONS_SUCCESS: "PATIENT_ALL_HOSPITALISATIONS_SUCCESS",
    PATIENT_ALL_HOSPITALISATIONS_FAIL: "PATIENT_ALL_HOSPITALISATIONS_FAIL",
}
