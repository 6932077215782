import React, { lazy } from "react"
import * as MyPrivateRouters from "../routes/PrivateRouter";
import { Switch } from 'react-router-dom';


const LayoutHospitalisationDemande = lazy(() =>
    import("../../components/hospitalisation/demande/LayoutHospitalisationDemande")
);
const LayoutHospitalisationEnCours = lazy(() =>
import("../../components/hospitalisation/encours/LayoutHospitalisationEnCours")
);
const LayoutHospitalisationBatiments = lazy(() =>
import(
    "../../components/hospitalisation/batiments/LayoutHospitalisationBatiments"
)
);
const LayoutHospitalisationChambres = lazy(() =>
import("../../components/hospitalisation/chambres/LayoutHospitalisationChambres")
);
const LayoutHospitalisationLits = lazy(() =>
import("../../components/hospitalisation/lits/LayoutHospitalisationLits")
);
const LayoutHospitalisationTermine = lazy(() =>
import(
    "../../components/hospitalisation/dejaHospitalise/LayoutHospitalisationTermine"
)
);
const LayoutHospitalisationFeuilleDeSoins = lazy(() =>
import(
    "../../components/hospitalisation/feuilleDeSoins/LayoutHospitalisationFeuilleDeSoins"
)
);

const HospitalisationOrientation = lazy(() =>
import("../../components/hospitalisation/orientation/HospitalisationOrientation")
);


export default function HospitalisationRouter() {
  return (
    <Switch>
        <MyPrivateRouters.PrivateRouterHospitalisation
        path="/hospitalisation"
        component={LayoutHospitalisationDemande}
        />

        <MyPrivateRouters.PrivateRouterHospitalisation
        path="/hospitalisation-en-cours/"
        component={LayoutHospitalisationEnCours}
        />

        <MyPrivateRouters.PrivateRouterHospitalisation
        path="/hospitalisation-termines/"
        component={LayoutHospitalisationTermine}
        />

        <MyPrivateRouters.PrivateRouterHospitalisation
        path="/hospitalisation-termines-feuille-de-soins/:id/"
        component={LayoutHospitalisationFeuilleDeSoins}
        />

        <MyPrivateRouters.PrivateRouterHospitalisation
        path="/hospitalisation-batiments/"
        component={LayoutHospitalisationBatiments}
        />

        <MyPrivateRouters.PrivateRouterHospitalisation
        path="/hospitalisation-lits/"
        component={LayoutHospitalisationLits}
        />

        <MyPrivateRouters.PrivateRouterHospitalisation
        path="/hospitalisation-chambres/"
        component={LayoutHospitalisationChambres}
        />

        <MyPrivateRouters.PrivateRouterHospitalisation
        path="/hospitalisation-orientation/:id/"
        component={HospitalisationOrientation}
        />
    </Switch>
  )
}
