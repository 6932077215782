export const facturationTypes = {

    FACTURATION_REFERENCE_INTERNE_GETS_LOADING: "FACTURATION_REFERENCE_INTERNE_GETS_LOADING",
    FACTURATION_REFERENCE_INTERNE_GETS_SUCCESS: "FACTURATION_REFERENCE_INTERNE_GETS_SUCCESS",
    FACTURATION_REFERENCE_INTERNE_GETS_FAILED: "FACTURATION_REFERENCE_INTERNE_GETS_FAILED",

    FACTURATION_REFERENCE_INTERNE_GETS_REFRESH_LOADING: "FACTURATION_REFERENCE_INTERNE_GETS_REFRESH_LOADING",
    FACTURATION_REFERENCE_INTERNE_GETS_REFRESH_SUCCESS: "FACTURATION_REFERENCE_INTERNE_GETS_REFRESH_SUCCESS",
    FACTURATION_REFERENCE_INTERNE_GETS_REFRESH_FAILED: "FACTURATION_REFERENCE_INTERNE_GETS_REFRESH_FAILED",

    FACTURATION_REFERENCE_INTERNE_GET_LOADING: "FACTURATION_REFERENCE_INTERNE_GET_LOADING",
    FACTURATION_REFERENCE_INTERNE_GET_SUCCESS: "FACTURATION_REFERENCE_INTERNE_GET_SUCCESS",
    FACTURATION_REFERENCE_INTERNE_GET_FAILED: "FACTURATION_REFERENCE_INTERNE_GET_FAILED",

    FACTURATION_REFERENCE_INTERNE_POST_SUCCESS: 'FACTURATION_REFERENCE_INTERNE_POST_SUCCESS',
    FACTURATION_REFERENCE_INTERNE_POST_FAILED: 'FACTURATION_REFERENCE_INTERNE_POST_FAILED',
    FACTURATION_REFERENCE_INTERNE_POST_LOADING: 'FACTURATION_REFERENCE_INTERNE_POST_LOADING',


    FACTURATION_HOSPITALISATION_GETS_LOADING: "FACTURATION_HOSPITALISATION_GETS_LOADING",
    FACTURATION_HOSPITALISATION_GETS_SUCCESS: "FACTURATION_HOSPITALISATION_GETS_SUCCESS",
    FACTURATION_HOSPITALISATION_GETS_FAILED: "FACTURATION_HOSPITALISATION_GETS_FAILED",

    FACTURATION_HOSPITALISATION_GETS_REFRESH_LOADING: "FACTURATION_HOSPITALISATION_GETS_REFRESH_LOADING",
    FACTURATION_HOSPITALISATION_GETS_REFRESH_SUCCESS: "FACTURATION_HOSPITALISATION_GETS_REFRESH_SUCCESS",
    FACTURATION_HOSPITALISATION_GETS_REFRESH_FAILED: "FACTURATION_HOSPITALISATION_GETS_REFRESH_FAILED",

    FACTURATION_HOSPITALISATION_GET_LOADING: "FACTURATION_HOSPITALISATION_GET_LOADING",
    FACTURATION_HOSPITALISATION_GET_SUCCESS: "FACTURATION_HOSPITALISATION_GET_SUCCESS",
    FACTURATION_HOSPITALISATION_GET_FAILED: "FACTURATION_HOSPITALISATION_GET_FAILED",

    FACTURATION_HOSPITALISATION_POST_SUCCESS: 'FACTURATION_HOSPITALISATION_POST_SUCCESS',
    FACTURATION_HOSPITALISATION_POST_FAILED: 'FACTURATION_HOSPITALISATION_POST_FAILED',
    FACTURATION_HOSPITALISATION_POST_LOADING: 'FACTURATION_HOSPITALISATION_POST_LOADING',


    FACTURATION_DEMANDE_EXAMEN_GETS_LOADING: "FACTURATION_DEMANDE_EXAMEN_GETS_LOADING",
    FACTURATION_DEMANDE_EXAMEN_GETS_SUCCESS: "FACTURATION_DEMANDE_EXAMEN_GETS_SUCCESS",
    FACTURATION_DEMANDE_EXAMEN_GETS_FAILED: "FACTURATION_DEMANDE_EXAMEN_GETS_FAILED",

    FACTURATION_DEMANDE_EXAMEN_GETS_REFRESH_LOADING: "FACTURATION_DEMANDE_EXAMEN_GETS_REFRESH_LOADING",
    FACTURATION_DEMANDE_EXAMEN_GETS_REFRESH_SUCCESS: "FACTURATION_DEMANDE_EXAMEN_GETS_REFRESH_SUCCESS",
    FACTURATION_DEMANDE_EXAMEN_GETS_REFRESH_FAILED: "FACTURATION_DEMANDE_EXAMEN_GETS_REFRESH_FAILED",

    FACTURATION_DEMANDE_EXAMEN_GET_LOADING: "FACTURATION_DEMANDE_EXAMEN_GET_LOADING",
    FACTURATION_DEMANDE_EXAMEN_GET_SUCCESS: "FACTURATION_DEMANDE_EXAMEN_GET_SUCCESS",
    FACTURATION_DEMANDE_EXAMEN_GET_FAILED: "FACTURATION_DEMANDE_EXAMEN_GET_FAILED",

    FACTURATION_DEMANDE_EXAMEN_POST_SUCCESS: 'FACTURATION_DEMANDE_EXAMEN_POST_SUCCESS',
    FACTURATION_DEMANDE_EXAMEN_POST_FAILED: 'FACTURATION_DEMANDE_EXAMEN_POST_FAILED',
    FACTURATION_DEMANDE_EXAMEN_POST_LOADING: 'FACTURATION_DEMANDE_EXAMEN_POST_LOADING',

    FACTURATION_ACTES_POSES_SUCCESS: 'FACTURATION_ACTES_POSES_SUCCESS',
    FACTURATION_ACTES_POSES_FAILED: 'FACTURATION_ACTES_POSES_FAILED',
    FACTURATION_ACTES_POSES_LOADING: 'FACTURATION_ACTES_POSES_LOADING',

    FACTURATION_ACTES_POSES_DETAILS_SUCCESS: 'FACTURATION_ACTES_POSES_DETAILS_SUCCESS',
    FACTURATION_ACTES_POSES_DETAILS_FAILED: 'FACTURATION_ACTES_POSES_DETAILS_FAILED',
    FACTURATION_ACTES_POSES_DETAILS_LOADING: 'FACTURATION_ACTES_POSES_DETAILS_LOADING',

    FACTURATION_ACTES_POSES_POST_SUCCESS: 'FACTURATION_ACTES_POSES_POST_SUCCESS',
    FACTURATION_ACTES_POSES_POST_FAILED: 'FACTURATION_ACTES_POSES_POST_FAILED',
    FACTURATION_ACTES_POSES_POST_LOADING: 'FACTURATION_ACTES_POSES_POST_LOADING',

    
    FACTURATION_STARTER_RESET: "FACTURATION_STARTER_RESET"
};