
import * as vaccinationTypes from '../types/vaccinationTypes';
import { AUTH_ERROR, LOGOUT_SUCCESS, TOKEN_REFRESH_FAIL } from '../types/userTypes';


const initialState = {
    vaccinations: [],
    isLoading: false,

    isLoadingGet: false,
    isLoadingPost: false,
    isLoadingUpdate: false,

    vaccinationGet: {},
    vaccinationPost: {},
    vaccinationPut: {},

    isSuccessGet: false,
    isSuccessPost: false,
    isSuccessUpdate: false,

    error: "",
    isError: false
}

// eslint-disable-next-line
export default function (state = initialState, action) {
    switch (action.type) {
        case vaccinationTypes.VACCINATION_GETS_LOADING:
            return {
                ...state,
                isLoading: true
            }
        case vaccinationTypes.VACCINATION_GETS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                vaccinations: action.payload
            }
        case vaccinationTypes.VACCINATION_GETS_FAILED:
            return {
                ...state,
                isLoading: false,        
            }

        
        //load get commune in dpi
        case vaccinationTypes.VACCINATION_GET_LOADING:
            return {
                ...state,
                isLoadingGet: true,
                isSuccessGet: false,
                vaccinationGet: {},
                error: "",
                isError: false,
            }
        case vaccinationTypes.VACCINATION_GET_SUCCESS:
            return {
                ...state,
                isLoadingGet: false,
                isSuccessGet: true,
                vaccinationGet: action.payload,
                error: "",
                isError: false,
            }
        case vaccinationTypes.VACCINATION_GET_FAILED:
            return {
                ...state,
                isLoadingGet: false,
                isSuccessGet: false,
                error: action.payload,
                vaccinationGet: {},
                isError: true,
            }

        
        //load post commune in dpi
        case vaccinationTypes.VACCINATION_POST_LOADING:
            return {
                ...state,
                isLoadingPost: true,
                isSuccessPost: false,
                vaccinationPost: {},
                error: "",
                isError: false,
            }
        case vaccinationTypes.VACCINATION_POST_SUCCESS:
            return {
                ...state,
                isLoadingPost: false,
                isSuccessPost: true,
                vaccinationPost: action.payload,
                error: "",
                isError: false,
            }
        case vaccinationTypes.VACCINATION_POST_FAILED:
            return {
                ...state,
                isLoadingPost: false,
                isSuccessPost: false,
                error: action.payload,
                isError: true,
            }

        
        //load put commune in dpi
        case vaccinationTypes.VACCINATION_PUT_LOADING:
            return {
                ...state,
                isLoadingUpdate: true,
                isSuccessUpdate: false,
                vaccinationPut: {},
                error: "",
                isError: false,
            }
        case vaccinationTypes.VACCINATION_PUT_SUCCESS:
            return {
                ...state,
                isLoadingUpdate: false,
                isSuccessUpdate: true,
                vaccinationPut: action.payload,
                error: "",
                isError: false,
            }
        case vaccinationTypes.VACCINATION_PUT_FAILED:
            return {
                ...state,
                isLoadingUpdate: false,
                isSuccessUpdate: false,
                error: action.payload,
                isError: true,
            }

        
        case vaccinationTypes.VACCINATION_CLEAR_STATE:
            return {
                ...state,
                isLoadingGet: false,
                isLoadingPost: false,
                isLoadingUpdate: false,

                vaccinationGet: {},
                vaccinationPost: {},
                vaccinationPut: {},

                isSuccessGet: false,
                isSuccessPost: false,
                isSuccessUpdate: false,

                error: "",
                isError: false,
            }


        case AUTH_ERROR:
        case LOGOUT_SUCCESS:
        case TOKEN_REFRESH_FAIL:
            localStorage.removeItem('token_access')
            localStorage.removeItem('token_refresh')
            return {
                ...state,
                vaccinations: [],
                isLoading: false,

                isLoadingGet: false,
                isLoadingPost: false,
                isLoadingUpdate: false,

                vaccinationGet: {},
                vaccinationPost: {},
                vaccinationPut: {},

                isSuccessGet: false,
                isSuccessPost: false,
                isSuccessUpdate: false,

                error: "",
                isError: false,
        }

        default:
            return state;
    }
}
