import * as situationMatrimonialeTypes from '../../types/situationMatrimonialeTypes';
import * as typesUsers from "../../types/userTypes"

const initialState = {
    data: [],
    isLoading: false,
    isLoadingRefresh: false,

    isLoadingGet: false,
    isLoadingPost: false,
    isLoadingUpdate: false,
    isLoadingDelete: false,

    situationMatrimonialeGet: {},

    isSuccessGet: false,
    isSuccessPost: false,
    isSuccessUpdate: false,
    isSuccessDelete: false,

    error: "",
    isError: false,
    isError403: false,
    isError500: false,
    isErrorDelete403: false,
    isErrorDelete500: false,
}

// eslint-disable-next-line
export default function(state = initialState, action){
    switch (action.type) {
        case situationMatrimonialeTypes.SITUATION_MATRIMONIALE_GETS_DATA_LOADING:
            return {
                ...state,
                isLoading: true
            }
        case situationMatrimonialeTypes.SITUATION_MATRIMONIALE_GETS_DATA_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload
            }
        case situationMatrimonialeTypes.SITUATION_MATRIMONIALE_GETS_DATA_FAILED:
            return {
                ...state,
                isLoading: false,        
            }


        case situationMatrimonialeTypes.SITUATION_MATRIMONIALE_GETS_REFRESH_DATA_LOADING:
            return {
                ...state,
                isLoadingRefresh: true
            }
        case situationMatrimonialeTypes.SITUATION_MATRIMONIALE_GETS_REFRESH_DATA_SUCCESS:
            return {
                ...state,
                isLoadingRefresh: false,
                data: action.payload
            }
        case situationMatrimonialeTypes.SITUATION_MATRIMONIALE_GETS_REFRESH_DATA_FAILED:
            return {
                ...state,
                isLoadingRefresh: false,
                data: []    
            }
        
        
        //load get ville in dpi
        case situationMatrimonialeTypes.SITUATION_MATRIMONIALE_GET_DATA_LOADING:
            return {
                ...state,
                isLoadingGet: true,
                isSuccessGet: false,
                situationMatrimonialeGet: {},
                error: "",
                isError: false,
                isError403: false,
                isError500: false,
            }
        case situationMatrimonialeTypes.SITUATION_MATRIMONIALE_GET_DATA_SUCCESS:
            return {
                ...state,
                isLoadingGet: false,
                isSuccessGet: true,
                situationMatrimonialeGet: action.payload,
                error: "",
                isError: false,
                isError403: false,
                isError500: false,
            }
        case situationMatrimonialeTypes.SITUATION_MATRIMONIALE_GET_DATA_FAILED:
            return {
                ...state,
                isLoadingGet: false,
                isSuccessGet: false,
                error: action.payload,
                situationMatrimonialeGet: {},
                isError: true,
                isError403: false,
                isError500: false,
            }
        case situationMatrimonialeTypes.SITUATION_MATRIMONIALE_GET_DATA_FAILED_403:
            return {
                ...state,
                isLoadingGet: false,
                isSuccessGet: false,
                situationMatrimonialeGet:{},
                error: action.payload,
                isError: false,
                isError403: true,
                isError500: false,
            };
        case situationMatrimonialeTypes.SITUATION_MATRIMONIALE_GET_DATA_FAILED_500:
            return {
                ...state,
                isLoadingGet: false,
                isSuccessGet: false,
                situationMatrimonialeGet:{},
                error: action.payload,
                isError: false,
                isError403: false,
                isError500: true,
            };

        
        //load post ville in dpi
        case situationMatrimonialeTypes.SITUATION_MATRIMONIALE_POST_DATA_LOADING:
            return {
                ...state,
                isLoadingPost: true,
                isSuccessPost: false,
                situationMatrimonialeGet: {},
                error: "",
                isError: false,
                isError403: false,
                isError500: false,
            }
        case situationMatrimonialeTypes.SITUATION_MATRIMONIALE_POST_DATA_SUCCESS:
            return {
                ...state,
                isLoadingPost: false,
                isSuccessPost: true,
                situationMatrimonialeGet: action.payload,
                error: "",
                isError: false,
                isError403: false,
                isError500: false,
            }
        case situationMatrimonialeTypes.SITUATION_MATRIMONIALE_POST_DATA_FAILED:
            return {
                ...state,
                isLoadingPost: false,
                isSuccessPost: false,
                error: action.payload,
                isError: true,
                isError403: false,
                isError500: false,
            }
        case situationMatrimonialeTypes.SITUATION_MATRIMONIALE_POST_DATA_FAILED_403:
            return {
                ...state,
                isLoadingPost: false,
                isSuccessPost: false,
                error: action.payload,
                isError: false,
                isError403: true,
                isError500: false,
            };
        case situationMatrimonialeTypes.SITUATION_MATRIMONIALE_POST_DATA_FAILED_500:
            return {
                ...state,
                isLoadingPost: false,
                isSuccessPost: false,
                error: action.payload,
                isError: false,
                isError403: false,
                isError500: true,
            };

        
        //load put ville in dpi
        case situationMatrimonialeTypes.SITUATION_MATRIMONIALE_PUT_DATA_LOADING:
            return {
                ...state,
                isLoadingUpdate: true,
                isSuccessUpdate: false,
                situationMatrimonialeGet: {},
                error: "",
                isError: false,
                isError403: false,
                isError500: false,
            }
        case situationMatrimonialeTypes.SITUATION_MATRIMONIALE_PUT_DATA_SUCCESS:
            return {
                ...state,
                isLoadingUpdate: false,
                isSuccessUpdate: true,
                situationMatrimonialeGet: action.payload,
                error: "",
                isError: false,
                isError403: false,
                isError500: false,
            }
        case situationMatrimonialeTypes.SITUATION_MATRIMONIALE_PUT_DATA_FAILED:
            return {
                ...state,
                isLoadingUpdate: false,
                isSuccessUpdate: false,
                error: action.payload,
                isError: true,
                isError403: false,
                isError500: false,
            }
        case situationMatrimonialeTypes.SITUATION_MATRIMONIALE_PUT_DATA_FAILED_403:
            return {
                ...state,
                isLoadingUpdate: false,
                isSuccessUpdate: false,
                error: action.payload,
                isError: false,
                isError403: true,
                isError500: false,
            };
        case situationMatrimonialeTypes.SITUATION_MATRIMONIALE_PUT_DATA_FAILED_500:
            return {
                ...state,
                isLoadingUpdate: false,
                isSuccessUpdate: false,
                error: action.payload,
                isError: false,
                isError403: false,
                isError500: true,
            };

        
        //load delete ville in dpi
        case situationMatrimonialeTypes.SITUATION_MATRIMONIALE_DELETE_DATA_LOADING:
            return {
                ...state,
                isLoadingDelete: true,
                isSuccessDelete: false,
                error: "",
                isError: false,
                isErrorDelete403: false,
                isErrorDelete500: false,
            }
        case situationMatrimonialeTypes.SITUATION_MATRIMONIALE_DELETE_DATA_SUCCESS:
            return {
                ...state,
                isLoadingDelete: false,
                isSuccessDelete: true,
                data: state.data.filter(data => data?.id !== action.payload),
                error: "",
                isError: false,
                isErrorDelete403: false,
                isErrorDelete500: false,
            }
        case situationMatrimonialeTypes.SITUATION_MATRIMONIALE_DELETE_DATA_FAILED:
            return {
                ...state,
                isLoadingDelete: false,
                isSuccessDelete: false,
                error: action.payload,
                isError: true,
                isErrorDelete403: false,
                isErrorDelete500: false,
            }
        case situationMatrimonialeTypes.SITUATION_MATRIMONIALE_DELETE_DATA_FAILED_403:
            return {
                ...state,
                isLoadingDelete: false,
                isSuccessDelete: false,
                error: action.payload,
                isError: false,
                isErrorDelete403: true,
                isErrorDelete500: false,
            };
        case situationMatrimonialeTypes.SITUATION_MATRIMONIALE_DELETE_DATA_FAILED_500:
            return {
                ...state,
                isLoadingDelete: false,
                isSuccessDelete: false,
                error: action.payload,
                isError: false,
                isErrorDelete403: false,
                isErrorDelete500: true,
            };

        case situationMatrimonialeTypes.SITUATION_MATRIMONIALE_CLEAR_STATE:
            return {
                ...state,
                isLoadingGet: false,
                isLoadingPost: false,
                isLoadingUpdate: false,
                isLoadingDelete: false,

                situationMatrimonialeGet: {},

                isSuccessGet: false,
                isSuccessPost: false,
                isSuccessUpdate: false,
                isSuccessDelete: false,

                error: "",
                isError: false,
                isError403: false,
                isError500: false,
                isErrorDelete403: false,
                isErrorDelete500: false,
            }


        case typesUsers.AUTH_ERROR:
        case typesUsers.LOGOUT_SUCCESS:
        case typesUsers.TOKEN_REFRESH_FAIL:
            localStorage.removeItem('token_access')
            localStorage.removeItem('token_refresh')
            return {
                ...state,
                data: [],
                isLoading: false,
                isLoadingRefresh: false,

                isLoadingGet: false,
                isLoadingPost: false,
                isLoadingUpdate: false,
                isLoadingDelete: false,

                situationMatrimonialeGet: {},

                isSuccessGet: false,
                isSuccessPost: false,
                isSuccessUpdate: false,
                isSuccessDelete: false,

                error: "",
                isError: false,
                isError403: false,
                isError500: false,
                isErrorDelete403: false,
                isErrorDelete500: false,
            }

        default:
            return state
    }
}