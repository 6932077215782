import React, { lazy } from "react"
import * as MyPrivateRouters from "../routes/PrivateRouter";
import { Switch } from 'react-router-dom';

const LayoutRadiologieDemandesListe = lazy(() =>
    import("../../components/radiologie/demandesListe/LayoutRadiologieDemandesListe")
);
const LayoutRadiologieResultatsListe = lazy(() =>
import(
    "../../components/radiologie/resultatsListe/LayoutRadiologieResultatsListe"
)
);
const LayoutRadiologieResultatsCreate = lazy(() =>
import(
    "../../components/radiologie/resultatsCreate/LayoutRadiologieResultatsCreate"
)
);
const LayoutRadiologieResultatsDetails = lazy(() =>
import(
    "../../components/radiologie/resultatsVoir/LayoutRadiologieResultatsDetails"
)
);

const LayoutRadiologieRapports = lazy(() =>
import(
    "../../components/radiologie/rapports/LayoutRadiologieRapports"
)
);
  

export default function RadiologieRouter() {
  return (
    <Switch>
        <MyPrivateRouters.PrivateRouterRadiologie
        path="/radiologie"
        component={LayoutRadiologieDemandesListe}
        />

        <MyPrivateRouters.PrivateRouterRadiologie
        path="/radiologie-resultats"
        component={LayoutRadiologieResultatsListe}
        />

        <MyPrivateRouters.PrivateRouterRadiologie
        path="/radiologie-enregistrement-resultat/:id"
        component={LayoutRadiologieResultatsCreate}
        />

        <MyPrivateRouters.PrivateRouterRadiologie
        path="/radiologie-resultat-voir/:id"
        component={LayoutRadiologieResultatsDetails}
        />

        <MyPrivateRouters.PrivateRouterRadiologie
        path="/radiologie-rapports"
        component={LayoutRadiologieRapports}
        />
    </Switch>
  )
}
