export const SPECIALITE_GET_DATA_LOADING = 'SPECIALITE_GET_DATA_LOADING'

export const SPECIALITE_GET_DATA_SUCCESS = 'SPECIALITE_GET_DATA_SUCCESS'

export const SPECIALITE_GET_DATA_FAILED = 'SPECIALITE_GET_DATA_FAILED'

export const SPECIALITE_GET_DATA_FAILED_403 = "SPECIALITE_GET_DATA_FAILED_403"

export const SPECIALITE_GET_DATA_FAILED_500 = "SPECIALITE_GET_DATA_FAILED_500"


export const SPECIALITE_POST_DATA_LOADING = 'SPECIALITE_POST_DATA_LOADING'

export const SPECIALITE_POST_DATA_SUCCESS = 'SPECIALITE_POST_DATA_SUCCESS'

export const SPECIALITE_POST_DATA_FAILED = 'SPECIALITE_POST_DATA_FAILED'

export const SPECIALITE_POST_DATA_FAILED_403 = "SPECIALITE_POST_DATA_FAILED_403"

export const SPECIALITE_POST_DATA_FAILED_500 = "SPECIALITE_POST_DATA_FAILED_500"


export const SPECIALITE_PUT_DATA_LOADING = 'SPECIALITE_PUT_DATA_LOADING'

export const SPECIALITE_PUT_DATA_SUCCESS = 'SPECIALITE_PUT_DATA_SUCCESS'

export const SPECIALITE_PUT_DATA_FAILED = 'SPECIALITE_PUT_DATA_FAILED'

export const SPECIALITE_PUT_DATA_FAILED_403 = "SPECIALITE_PUT_DATA_FAILED_403"

export const SPECIALITE_PUT_DATA_FAILED_500 = "SPECIALITE_PUT_DATA_FAILED_500"


export const SPECIALITE_DELETE_DATA_LOADING = 'SPECIALITE_DELETE_DATA_LOADING'

export const SPECIALITE_DELETE_DATA_SUCCESS = 'SPECIALITE_DELETE_DATA_SUCCESS'

export const SPECIALITE_DELETE_DATA_FAILED = 'SPECIALITE_DELETE_DATA_FAILED'

export const SPECIALITE_DELETE_DATA_FAILED_403 = "SPECIALITE_DELETE_DATA_FAILED_403"

export const SPECIALITE_DELETE_DATA_FAILED_500 = "SPECIALITE_DELETE_DATA_FAILED_500"


export const SPECIALITE_GETS_DATA_LOADING = 'SPECIALITE_GETS_DATA_LOADING'

export const SPECIALITE_GETS_DATA_SUCCESS = 'SPECIALITE_GETS_DATA_SUCCESS'

export const SPECIALITE_GETS_DATA_FAILED = 'SPECIALITE_GETS_DATA_FAILED'


export const SPECIALITE_GETS_REFRESH_DATA_LOADING = 'SPECIALITE_GETS_REFRESH_DATA_LOADING'

export const SPECIALITE_GETS_REFRESH_DATA_SUCCESS = 'SPECIALITE_GETS_REFRESH_DATA_SUCCESS'

export const SPECIALITE_GETS_REFRESH_DATA_FAILED = 'SPECIALITE_GETS_REFRESH_DATA_FAILED'


export const SPECIALITE_CLEAR_STATE = "SPECIALITE_CLEAR_STATE"