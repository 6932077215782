export const refExterneTypes = {
    REF_EXTERNE_GET_REQUEST: "REF_EXTERNE_GET_REQUEST",
    REF_EXTERNE_GET_SUCCESS: "REF_EXTERNE_GET_SUCCESS",
    REF_EXTERNE_GET_FAIL: "REF_EXTERNE_GET_FAIL",

    REF_EXTERNE_GET_REFRESH_REQUEST: "REF_EXTERNE_GET_REFRESH_REQUEST",
    REF_EXTERNE_GET_REFRESH_SUCCESS: "REF_EXTERNE_GET_REFRESH_SUCCESS",
    REF_EXTERNE_GET_REFRESH_FAIL: "REF_EXTERNE_GET_REFRESH_FAIL",

    PATIENT_ALL_REF_EXTERNE_REQUEST: "PATIENT_ALL_REF_EXTERNE_REQUEST",
    PATIENT_ALL_REF_EXTERNE_SUCCESS: "PATIENT_ALL_REF_EXTERNE_SUCCESS",
    PATIENT_ALL_REF_EXTERNE_FAIL: "PATIENT_ALL_REF_EXTERNE_FAIL",
}