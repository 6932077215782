import React, { lazy } from "react"
import * as MyPrivateRouters from "../routes/PrivateRouter";
import { Switch } from 'react-router-dom';

const LayoutPsychiatriePatientAConsulter = lazy(() =>
    import(
      "../../components/psychiatrie/patientAConsulter/LayoutPsychiatriePatientAConsulter"
    )
);
const LayoutPsychiatrieConsultationListe = lazy(() =>
    import(
        "../../components/psychiatrie/consultation/LayoutPsychiatrieConsultationListe"
    )
);
const LayoutPsychiatrieDemandeHospitalisationListe = lazy(() =>
    import(
        "../../components/psychiatrie/demandeHospitalisation/LayoutPsychiatrieDemandeHospitalisationListe"
    )
);
const PsychiatrieObservation = lazy(() =>
    import("../../components/psychiatrie/observation/PsychiatrieObservation")
);
const PsychiatrieObservationTerminees = lazy(() =>
    import("../../components/psychiatrie/observationTerminees/PsychiatrieObservationTerminees")
);

const LayoutPsychiatrieCreateConsultation = lazy(() =>
    import(
        "../../components/psychiatrie/patientAConsulterCreate/LayoutPsychiatrieCreateConsultation"
    )
);

const LayoutPsychiatrieViewConsultation = lazy(() =>
    import(
        "../../components/psychiatrie/patientAConsulterDetail/LayoutPsychiatrieViewConsultation"
    )
);

const LayoutPsychiatrieFeuilleDeSoins = lazy(() =>
    import(
        "../../components/psychiatrie/feuilleDeSoins/LayoutPsychiatrieFeuilleDeSoins"
    )
);

const LayoutCreateConsultationSortiePsychiatrie = lazy(() =>
    import(
        "../../components/psychiatrie/patientAConsulterIssuSortie/LayoutCreateConsultationSortiePsychiatrie"
    )
);

const LayoutCreateConsultationSortieDemandeHospitalisationPsychiatrie = lazy(
() =>
        import(
        "../../components/psychiatrie/patientAConsulterIssuSortie/DemandeHospitalisation/LayoutCreateConsultationSortieDemandeHospitalisationKinesitherapie"
        )
);
const LayoutCreateConsultationSortieMiseEnObservationPsychiatrie = lazy(() =>
    import(
        "../../components/psychiatrie/patientAConsulterIssuSortie/MiseEnObservation/LayoutCreateConsultationSortieMiseEnObservationPsychiatrie"
    )
);

const LayoutCreateConsultationRefereInternePsychiatrie = lazy(() =>
    import(
        "../../components/psychiatrie/patientAConsulterIssuSortie/RefereInterne/LayoutCreateConsultationRefereInternePsychiatrie"
    )
);
const LayoutCreateConsultationRefereExternePsychiatrie = lazy(() =>
    import(
        "../../components/psychiatrie/patientAConsulterIssuSortie/RefereExterne/LayoutCreateConsultationRefereExternePsychiatrie"
    )
);

const LayoutPsychiatrieOrdonnanceDetails = lazy(() =>
    import(
        "../../components/psychiatrie/ordonnance/detail/LayoutPsychiatrieOrdonnanceDetails"
    )
);
const LayoutPsychiatrieOrdonnanceListe = lazy(() =>
    import(
        "../../components/psychiatrie/ordonnance/liste/LayoutPsychiatrieOrdonnanceListe"
    )
);

const LayoutPsychiatrieHospitalisationListe = lazy(() =>
    import(
        "../../components/psychiatrie/hospitalisation/LayoutPsychiatrieHospitalisationListe"
    )
);

const LayoutPsychiatrieHospitalisationConsultationCreate = lazy(() =>
    import(
        "../../components/psychiatrie/hospitalisation/consultation/LayoutPsychiatrieHospitalisationConsultationCreate"
    )
);
const LayoutPsychiatrieHospitalisationConsultationSortie = lazy(() =>
    import(
        "../../components/psychiatrie/hospitalisation/issueSortie/LayoutPsychiatrieHospitalisationConsultationSortie"
    )
);

const PsychiatrieRefExterne = lazy(() =>
    import("../../components/psychiatrie/refExterne/PsychiatrieRefExterne")
);
const PsychiatrieRefInterne = lazy(() =>
    import("../../components/psychiatrie/refInterne/PsychiatrieRefInterne")
);
const LayoutPsychiatrieDecesConsultationSortie = lazy(() =>
    import(
        "../../components/psychiatrie/patientAConsulterIssuSortie/IssueDeDeces/LayoutPsychiatrieDecesConsultationSortie"
    )
);

const DemandeHospiObservationPsychiatrie = lazy(() =>
    import(
        "../../components/psychiatrie/patientAConsulterIssuSortie/MiseEnObservation/DemandeHospitalisation/LayoutCreateConsultationSortieDemandeHospitalisationPsychiatrie"
    )
);
const RefInterneObservationPsychiatrie = lazy(() =>
    import(
        "../../components/psychiatrie/patientAConsulterIssuSortie/MiseEnObservation/RefereInterne/LayoutCreateConsultationRefereInternePsychiatrie"
    )
);
const RefExterneObservationPsychiatrie = lazy(() =>
    import(
        "../../components/psychiatrie/patientAConsulterIssuSortie/MiseEnObservation/RefereExterne/LayoutCreateConsultationRefereExternePsychiatrie"
    )
);

const PsychiatrieExamensDetail = lazy(() =>
    import("../../components/psychiatrie/Examens/detail")
);
const PsychiatrieExamensList = lazy(() =>
    import("../../components/psychiatrie/Examens/list")
);
const LayoutPsychiatrieDecesListe = lazy(() =>
    import(
        "../../components/psychiatrie/Deces/Liste/LayoutPsychiatrieDecesListe"
    )
);
const LayoutPsychiatrieDecesCertificat = lazy(() =>
    import(
        "../../components/psychiatrie/Deces/Certificat/LayoutPsychiatrieDecesCertificat"
    )
);

const AgendaPsychiatrie = lazy(() =>
    import(
        "../../components/psychiatrie/rendezVous/agendaDetail/AgendaPsychiatrie"
    )
);
const LayoutPsychiatrieCreateConsultationRendezVous = lazy(() =>
    import(
        "../../components/psychiatrie/patientAConsulterIssuSortie/RendezVous/LayoutPsychiatrieCreateConsultationRendezVous"
    )
);
const LayoutPsychiatrieRdvListe = lazy(() =>
    import(
        "../../components/psychiatrie/rendezVous/Liste/LayoutPsychiatrieRdvListe"
    )
);
const LayoutPsyRdvLastConsultation = lazy(() =>
    import(
        "../../components/psychiatrie/rendezVous/LastConsultation/LayoutPsyRdvLastConsultation"
    )
);
const LayoutPsyRdvNewConsultation = lazy(() =>
    import(
        "../../components/psychiatrie/rendezVous/NewConsultation/LayoutCardioRdvNewConsultation"
    )
);
const LayoutPsychiatrieRadiologieVoirResultatsExamens = lazy(() =>
    import(
        "../../components/psychiatrie/Examens/resultatsExamensVoir/LayoutPsychiatrieRadiologieVoirResultatsExamens"
    )
);

const RapportPsychiatrie = lazy(() =>   import("../../components/psychiatrie/rapports/RapportPsychiatrie"));

const LayoutPsychiatrieControleMajor = lazy(() => 
    import("../../components/psychiatrie/controleMajor/LayoutPsychiatrieControleMajor")
)
  

export default function PsychiatrieRouter() {
  return (
    <Switch>
        <MyPrivateRouters.PrivateRouterNeuroPsychiatrie
        path="/psychiatrie"
        component={LayoutPsychiatriePatientAConsulter}
        />

        <MyPrivateRouters.PrivateRouterNeuroPsychiatrie
        path="/psychiatrie-demande-hospitalisation"
        component={LayoutPsychiatrieDemandeHospitalisationListe}
        />

        <MyPrivateRouters.PrivateRouterNeuroPsychiatrie
        path="/psychiatrie-hospitalisation"
        component={LayoutPsychiatrieHospitalisationListe}
        />

        <MyPrivateRouters.PrivateRouterNeuroPsychiatrie
        path="/psychiatrie-hospitalisation-consultation/:id"
        component={
            LayoutPsychiatrieHospitalisationConsultationCreate
        }
        />

        <MyPrivateRouters.PrivateRouterNeuroPsychiatrie
        path="/psychiatrie-hospitalisation-sortie/:id"
        component={
            LayoutPsychiatrieHospitalisationConsultationSortie
        }
        />

        <MyPrivateRouters.PrivateRouterNeuroPsychiatrie
        path="/psychiatrie-observation-encours"
        component={PsychiatrieObservation}
        />

        <MyPrivateRouters.PrivateRouterNeuroPsychiatrie
        path="/psychiatrie-observation-terminee"
        component={PsychiatrieObservationTerminees}
        />

        <MyPrivateRouters.PrivateRouterNeuroPsychiatrie
        path="/psychiatrie-consultation"
        component={LayoutPsychiatrieConsultationListe}
        />

        <MyPrivateRouters.PrivateRouterNeuroPsychiatrie
        path="/psychiatrie-ordonnance"
        component={LayoutPsychiatrieOrdonnanceListe}
        />

        <MyPrivateRouters.PrivateRouterNeuroPsychiatrie
        path="/psychiatrie-ordonnance-voir/:id"
        component={LayoutPsychiatrieOrdonnanceDetails}
        />

        <MyPrivateRouters.PrivateRouterNeuroPsychiatrie
        path="/psychiatrie-feuille-de-soins/:id"
        component={LayoutPsychiatrieFeuilleDeSoins}
        />

        <MyPrivateRouters.PrivateRouterNeuroPsychiatrie
        path="/psychiatrie-rdv"
        component={LayoutPsychiatrieRdvListe}
        />

        <MyPrivateRouters.PrivateRouterNeuroPsychiatrie
        path="/psychiatrie-rdv-last-consultation/:id"
        component={LayoutPsyRdvLastConsultation}
        />

        <MyPrivateRouters.PrivateRouterNeuroPsychiatrie
        path="/psychiatrie-rdv-new-consultation/:id"
        component={LayoutPsyRdvNewConsultation}
        />

        <MyPrivateRouters.PrivateRouterNeuroPsychiatrie
        path="/psychiatrie-consultation-sortie-rdv/:id"
        component={LayoutPsychiatrieCreateConsultationRendezVous}
        />

        <MyPrivateRouters.PrivateRouterNeuroPsychiatrie
        path="/psychiatrie-rdv-agenda/"
        component={AgendaPsychiatrie}
        />

        <MyPrivateRouters.PrivateRouterNeuroPsychiatrie
        path="/psychiatrie-consultation-create/:id"
        component={LayoutPsychiatrieCreateConsultation}
        />

        <MyPrivateRouters.PrivateRouterNeuroPsychiatrie
        path="/psychiatrie-consultation-voir-detail/:id"
        component={LayoutPsychiatrieViewConsultation}
        />

        <MyPrivateRouters.PrivateRouterNeuroPsychiatrie
        path="/psychiatrie-consultation-sortie/:id"
        component={LayoutCreateConsultationSortiePsychiatrie}
        />

        <MyPrivateRouters.PrivateRouterNeuroPsychiatrie
        path="/psychiatrie-consultation-sortie-demande-hospitalisation/:id"
        component={
            LayoutCreateConsultationSortieDemandeHospitalisationPsychiatrie
        }
        />

        <MyPrivateRouters.PrivateRouterNeuroPsychiatrie
        path="/psychiatrie-consultation-sortie-mise-en-observation/:id"
        component={
            LayoutCreateConsultationSortieMiseEnObservationPsychiatrie
        }
        />

        <MyPrivateRouters.PrivateRouterNeuroPsychiatrie
        path="/psychiatrie-consultation-sortie-refere-interne/:id"
        component={LayoutCreateConsultationRefereInternePsychiatrie}
        />

        <MyPrivateRouters.PrivateRouterNeuroPsychiatrie
        path="/psychiatrie-consultation-sortie-refere-externe/:id"
        component={LayoutCreateConsultationRefereExternePsychiatrie}
        />

        <MyPrivateRouters.PrivateRouterNeuroPsychiatrie
        path="/psychiatrie-referencements-externe"
        component={PsychiatrieRefExterne}
        />

        <MyPrivateRouters.PrivateRouterNeuroPsychiatrie
        path="/psychiatrie-referencements-interne"
        component={PsychiatrieRefInterne}
        />

        <MyPrivateRouters.PrivateRouterNeuroPsychiatrie
        path="/psychiatrie-consultation-sortie-issue-deces/:id"
        component={LayoutPsychiatrieDecesConsultationSortie}
        />

        <MyPrivateRouters.PrivateRouterNeuroPsychiatrie
        path="/psychiatrie-observation-consultation/:id"
        component={LayoutPsychiatrieCreateConsultation}
        />

        <MyPrivateRouters.PrivateRouterNeuroPsychiatrie
        path="/psychiatrie-exit-observation/:id"
        component={LayoutCreateConsultationSortiePsychiatrie}
        />

        <MyPrivateRouters.PrivateRouterNeuroPsychiatrie
        path="/psychiatrie-observation-exit-demande-hospitalisation/:id"
        component={DemandeHospiObservationPsychiatrie}
        />

        <MyPrivateRouters.PrivateRouterNeuroPsychiatrie
        path="/psychiatrie-observation-exit-reference-interne/:id"
        component={RefInterneObservationPsychiatrie}
        />

        <MyPrivateRouters.PrivateRouterNeuroPsychiatrie
        path="/psychiatrie-observation-exit-reference-externe/:id"
        component={RefExterneObservationPsychiatrie}
        />

        <MyPrivateRouters.PrivateRouterNeuroPsychiatrie
        path="/psychiatrie-observation-exit-issue-deces/:id"
        component={LayoutPsychiatrieDecesConsultationSortie}
        />

        <MyPrivateRouters.PrivateRouterNeuroPsychiatrie
        path="/psychiatrie-observation-exit-rdv/:id"
        component={LayoutPsychiatrieCreateConsultationRendezVous}
        />

        <MyPrivateRouters.PrivateRouterNeuroPsychiatrie
        path="/psychiatrie-examens-detail/:id/"
        component={PsychiatrieExamensDetail}
        />

        <MyPrivateRouters.PrivateRouterNeuroPsychiatrie
        path="/psychiatrie-examens"
        component={PsychiatrieExamensList}
        />

        <MyPrivateRouters.PrivateRouterNeuroPsychiatrie
        path="/psychiatrie-patients-decedes"
        component={LayoutPsychiatrieDecesListe}
        />

        <MyPrivateRouters.PrivateRouterNeuroPsychiatrie
        path="/psychiatrie-patients-decedes-certificat/:id"
        component={LayoutPsychiatrieDecesCertificat}
        />

        <MyPrivateRouters.PrivateRouterNeuroPsychiatrie
        path="/psychiatrie-examens-resultat-voir/:id"
        component={LayoutPsychiatrieRadiologieVoirResultatsExamens}
        />

        <MyPrivateRouters.PrivateRouterNeuroPsychiatrie
        path="/psychiatrie-rapports"
        component={RapportPsychiatrie}
        />

        <MyPrivateRouters.PrivateRouterNeuroPsychiatrie
        path="/psychiatrie-controle-major"
        component={LayoutPsychiatrieControleMajor}
        />
    </Switch>
  )
}
