export const REGIONS_GET_DATA_LOADING = 'REGIONS_GET_DATA_LOADING'

export const REGIONS_GET_DATA_SUCCESS = 'REGIONS_GET_DATA_SUCCESS'

export const REGIONS_GET_DATA_FAILED = 'REGIONS_GET_DATA_FAILED'

export const REGIONS_GET_DATA_FAILED_403 = "REGIONS_GET_DATA_FAILED_403"

export const REGIONS_GET_DATA_FAILED_500 = "REGIONS_GET_DATA_FAILED_500"


export const REGIONS_POST_DATA_LOADING = 'REGIONS_POST_DATA_LOADING'

export const REGIONS_POST_DATA_SUCCESS = 'REGIONS_POST_DATA_SUCCESS'

export const REGIONS_POST_DATA_FAILED = 'REGIONS_POST_DATA_FAILED'

export const REGIONS_POST_DATA_FAILED_403 = "REGIONS_POST_DATA_FAILED_403"

export const REGIONS_POST_DATA_FAILED_500 = "REGIONS_POST_DATA_FAILED_500"


export const REGIONS_PUT_DATA_LOADING = 'REGIONS_PUT_DATA_LOADING'

export const REGIONS_PUT_DATA_SUCCESS = 'REGIONS_PUT_DATA_SUCCESS'

export const REGIONS_PUT_DATA_FAILED = 'REGIONS_PUT_DATA_FAILED'

export const REGIONS_PUT_DATA_FAILED_403 = "REGIONS_PUT_DATA_FAILED_403"

export const REGIONS_PUT_DATA_FAILED_500 = "REGIONS_PUT_DATA_FAILED_500"


export const REGIONS_DELETE_DATA_LOADING = 'REGIONS_DELETE_DATA_LOADING'

export const REGIONS_DELETE_DATA_SUCCESS = 'REGIONS_DELETE_DATA_SUCCESS'

export const REGIONS_DELETE_DATA_FAILED = 'REGIONS_DELETE_DATA_FAILED'

export const REGIONS_DELETE_DATA_FAILED_403 = "REGIONS_DELETE_DATA_FAILED_403"

export const REGIONS_DELETE_DATA_FAILED_500 = "REGIONS_DELETE_DATA_FAILED_500"


export const REGIONS_GETS_DATA_LOADING = 'REGIONS_GETS_DATA_LOADING'

export const REGIONS_GETS_DATA_SUCCESS = 'REGIONS_GETS_DATA_SUCCESS'

export const REGIONS_GETS_DATA_FAILED = 'REGIONS_GETS_DATA_FAILED'


export const REGIONS_GETS_REFRESH_DATA_LOADING = 'REGIONS_GETS_REFRESH_DATA_LOADING'

export const REGIONS_GETS_REFRESH_DATA_SUCCESS = 'REGIONS_GETS_REFRESH_DATA_SUCCESS'

export const REGIONS_GETS_REFRESH_DATA_FAILED = 'REGIONS_GETS_REFRESH_DATA_FAILED'


export const REGIONS_CLEAR_STATE = "REGIONS_CLEAR_STATE"