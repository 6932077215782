import React, { lazy } from "react"
import * as MyPrivateRouters from "../routes/PrivateRouter";
import { Switch } from 'react-router-dom';


const LayoutCPNConsultationPrenataleListe = lazy(() =>
    import(
      "../../components/cpn/PrenataleAConsulter/Liste/LayoutCPNConsultationPrenataleListe"
    )
);

const LayoutCPNConsultationPrenataleCreate = lazy(() =>
import(
    "../../components/cpn/PrenataleAConsulter/Create/LayoutCPNConsultationPrenataleCreate"
)
);

const LayoutCPNListPatientConsultes = lazy(() =>
import(
    "../../components/cpn/consultation/Liste/LayoutCPNListPatientConsultes"
)
);

const LayoutCPNConsultationPrenataleDetails = lazy(() =>
import(
    "../../components/cpn/consultation/VoirPlus/LayoutCPNConsultationPrenataleDetails"
)
);

const LayoutNewConsultation = lazy(() =>
import(
"../../components/cpn/consultation/NewConsultation/LayoutNewConsultation"
)
);

const LayoutCPNSortieCreate = lazy(() =>
import("../../components/cpn/PrenataleAConsulterIssue/LayoutCPNSortieCreate")
);

const LayoutRDVSortie = lazy(() =>
import("../../components/cpn/consultation/NewConsultation/LayoutRDVSortie")
);

const LayoutCpnSortieDemandeHospitalisationCreate = lazy(() =>
import(
    "../../components/cpn/PrenataleAConsulterIssue/DemandeHospitalisation/LayoutCpnSortieDemandeHospitalisationCreate"
)
);

const LayoutCpnSortieRefereInterneCreate = lazy(() =>
import(
    "../../components/cpn/PrenataleAConsulterIssue/RefereInterne/LayoutCpnSortieRefereInterneCreate"
)
);

const LayoutCpnRDVSortieRefereInterneCreate = lazy(() =>
import(
    "../../components/cpn/consultation/NewConsultation/RefereInterne/LayoutCpnSortieRefereInterneCreate"
)
);

const LayoutCpnSortieRefereExterneCreate = lazy(() =>
import(
    "../../components/cpn/PrenataleAConsulterIssue/RefereExterne/LayoutCpnSortieRefereExterneCreate"
)
);

const LayoutCpnRDVSortieRefereExterneCreate = lazy(() =>
import(
    "../../components/cpn/consultation/NewConsultation/RefereExterne/LayoutCpnSortieRefereExterneCreate"
)
);

const LayoutCpnSortieRdvCreate = lazy(() =>
import(
    "../../components/cpn/PrenataleAConsulterIssue/RendezVous/LayoutCpnSortieRdvCreate"
)
);

const LayoutCpnRDVSortieRdvCreate = lazy(() =>
import(
    "../../components/cpn/consultation/NewConsultation/RendezVous/LayoutCpnSortieRdvCreate"
)
);

const LayoutCPNOrdonnanceListe = lazy(() =>
import(
    "../../components/cpn/ordonnances/Liste/LayoutCPNOrdonnanceListe"
)
);

const LayoutCPNOrdonnanceFormView = lazy(() =>
import(
    "../../components/cpn/ordonnances/Form/LayoutCPNOrdonnanceFormView"
)
);

const LayoutCPNExamenList = lazy(() =>
import("../../components/cpn/examens/list")
);

const LayoutCPNExamenDetail = lazy(() =>
import("../../components/cpn/examens/detail")
);

const LayoutCPNVoirResultatsExamens = lazy(() =>
import(
    "../../components/cpn/examens/voir/LayoutCPNVoirResultatsExamens"
)
);

const LayoutCPNDecesListe = lazy(() =>
import("../../components/cpn/Deces/Liste/LayoutCPNDecesListe")
);

const LayoutCPNDecesCertificat = lazy(() =>
import(
    "../../components/cpn/Deces/Certificat/LayoutCPNDecesCertificat"
)
);

const LayoutCPNListeRefInterne = lazy(() =>
import(
    "../../components/cpn/refInterne/LayoutCPNListeRefInterne"
)
);

const LayoutCPNListeRefExterne = lazy(() =>
import(
    "../../components/cpn/refExterne/LayoutCPNListeRefExterne"
)
);

const LayoutCPNRdvListe = lazy(() =>
import("../../components/cpn/rendezVous/Liste/LayoutCPNRdvListe")
);

const LayoutCPNAgenda = lazy(() =>
import("../../components/cpn/rendezVous/agendaDetail/LayoutCPNAgenda")
);

const LayoutCPNRapports = lazy(() =>
import("../../components/cpn/rapports/LayoutCPNRapports")
);

const LayoutCPNControleMajor = lazy(() => 
import("../../components/cpn/controleMajor/LayoutCpnControleMajor")
)

const LayoutCpnSortieDecesCreate = lazy(() =>
    import(
      "../../components/cpn/PrenataleAConsulterIssue/IssueDeDeces/LayoutCpnDecesConsultationSortie"
    )
);
  
const LayoutRDVSortieDeces = lazy(() =>
    import(
        "../../components/cpn/consultation/NewConsultation/IssueDeDeces/LayoutCpnDecesConsultationSortie"
    )
);
  

export default function CpnRouter() {
  return (
    <Switch>
        <MyPrivateRouters.PrivateRouterCPN
        path="/cpn"
        component={LayoutCPNConsultationPrenataleListe}
        />

        <MyPrivateRouters.PrivateRouterCPN
        path="/cpn-consultation-create/:id"
        component={LayoutCPNConsultationPrenataleCreate}
        />

        <MyPrivateRouters.PrivateRouterCPN
        path="/cpn-consultation-voir-detail/:id"
        component={LayoutCPNConsultationPrenataleDetails}
        />

        <MyPrivateRouters.PrivateRouterCPN
        path="/cpn-consultation-sortie/:id"
        component={LayoutCPNSortieCreate}
        />

        <MyPrivateRouters.PrivateRouterCPN
        path="/cpn-rdv-consultation-sortie/:id"
        component={LayoutRDVSortie}
        />

        <MyPrivateRouters.PrivateRouterCPN
        path="/cpn-consultation-deces/:id"
        component={LayoutCpnSortieDecesCreate}
        />

        <MyPrivateRouters.PrivateRouterCPN
        path="/cpn-rdv-consultation-deces/:id"
        component={LayoutRDVSortieDeces}
        />

        <MyPrivateRouters.PrivateRouterCPN
        path="/cpn-consultation-sortie-demande-hospitalisation/:id"
        component={LayoutCpnSortieDemandeHospitalisationCreate}
        />

        <MyPrivateRouters.PrivateRouterCPN
        path="/cpn-consultation-sortie-refere-externe/:id"
        component={LayoutCpnSortieRefereExterneCreate}
        />

        <MyPrivateRouters.PrivateRouterCPN
        path="/cpn-rdv-consultation-sortie-refere-interne/:id"
        component={LayoutCpnRDVSortieRefereInterneCreate}
        />

        <MyPrivateRouters.PrivateRouterCPN
        path="/cpn-consultation-sortie-refere-externe/:id"
        component={LayoutCpnSortieRefereExterneCreate}
        />

        <MyPrivateRouters.PrivateRouterCPN
        path="/cpn-rdv-consultation-sortie-refere-externe/:id"
        component={LayoutCpnRDVSortieRefereExterneCreate}
        />

        <MyPrivateRouters.PrivateRouterCPN
        path="/cpn-consultation-sortie-rdv/:id"
        component={LayoutCpnSortieRdvCreate}
        />

        <MyPrivateRouters.PrivateRouterCPN
        path="/cpn-rdv-consultation-sortie-rdv/:id"
        component={LayoutCpnRDVSortieRdvCreate}
        />

        <MyPrivateRouters.PrivateRouterCPN
        path="/cpn-consultation-liste"
        component={LayoutCPNListPatientConsultes}
        />

        <MyPrivateRouters.PrivateRouterCPN
        path="/cpn-ordonnance-prenatale-voir/:id"
        component={LayoutCPNOrdonnanceFormView}
        />

        <MyPrivateRouters.PrivateRouterCPN
        path="/cpn-demande-examen-cpns-detail/:id/"
        component={LayoutCPNExamenDetail}
        />

        <MyPrivateRouters.PrivateRouterCPN
        path="/cpn-demande-examen-cpns-resultat-voir/:id"
        component={LayoutCPNVoirResultatsExamens}
        />

        <MyPrivateRouters.PrivateRouterCPN
        path="/cpn-demande-examen-cpns"
        component={LayoutCPNExamenList}
        />

        <MyPrivateRouters.PrivateRouterCPN
        path="/cpn-patients-decedes"
        component={LayoutCPNDecesListe}
        />

        <MyPrivateRouters.PrivateRouterCPN
        path="/cpn-patients-decedes-certificat/:id"
        component={LayoutCPNDecesCertificat}
        />

        <MyPrivateRouters.PrivateRouterCPN
        path="/cpn-referencements-interne"
        component={LayoutCPNListeRefInterne}
        />

        <MyPrivateRouters.PrivateRouterCPN
        path="/cpn-referencements-externe"
        component={LayoutCPNListeRefExterne}
        />

        <MyPrivateRouters.PrivateRouterCPN
        path="/cpn-rdv"
        component={LayoutCPNRdvListe}
        />

        <MyPrivateRouters.PrivateRouterCPN
        path="/cpn-rdv-agenda"
        component={LayoutCPNAgenda}
        />

        <MyPrivateRouters.PrivateRouterCPN
        path="/cpn-rdv-last-consultation/:id"
        component={LayoutCPNConsultationPrenataleDetails}
        />

        <MyPrivateRouters.PrivateRouterCPN
        path="/cpn-rdv-new-consultation/:id"
        component={LayoutNewConsultation}
        />

        <MyPrivateRouters.PrivateRouterCPN
        path="/cpn-consultation-sortie-refere-interne/:id"
        component={LayoutCpnSortieRefereInterneCreate}
        />

        <MyPrivateRouters.PrivateRouterCPN
        path="/cpn-ordonnance-prenatale"
        component={LayoutCPNOrdonnanceListe}
        />
        
        <MyPrivateRouters.PrivateRouterCPN
        path="/cpn-rapports"
        component={LayoutCPNRapports}
        />

        <MyPrivateRouters.PrivateRouterCPN
        path="/cpn-controle-major"
        component={LayoutCPNControleMajor}
        />
    </Switch>
  )
}
