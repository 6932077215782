import { reportType } from "../../../types/reportType.js";
import { AUTH_ERROR, LOGOUT_SUCCESS, TOKEN_REFRESH_FAIL } from "../../../types/userTypes.js";

const initialState = {
    isLoading: false,
    data: [],
    error: "",
};

export const getPrestationGratuiteReducer = (state = initialState, action) => {
    switch (action.type) {
        case reportType.GET_PRESTATION_GRATUITE_LOADING:
            return {
                ...state,
                isLoading: true,
                error:""
            };
        case reportType.GET_PRESTATION_GRATUITE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload,
                error:""
            };
        case reportType.GET_PRESTATION_GRATUITE_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };
        case AUTH_ERROR:
        case LOGOUT_SUCCESS:
        case TOKEN_REFRESH_FAIL:
            localStorage.removeItem('token_access')
            localStorage.removeItem('token_refresh')
            return {
                ...state,
                isLoading: false,
                data: false,
                error: "",
            }
        default:
            return state;
    }
};
