import React, { lazy } from "react"
import * as MyPrivateRouters from "../routes/PrivateRouter";
import { Switch } from 'react-router-dom';


const LayoutAccouchementCreate = lazy(() =>
    import(
      "../../components/accouchement/Accouchement/Create/LayoutAccouchementCreate"
    )
);

const LayoutAccouchementList = lazy(() =>
import(
    "../../components/accouchement/Accouchement/Liste/LayoutAccouchementList"
)
);

const LayoutAccouchementTerminesListe = lazy(() =>
import(
    "../../components/accouchement/Accouchement/ListeTermines/LayoutAccouchementTerminesListe"
)
);

const LayoutAccouchementDetails = lazy(() =>
import(
    "../../components/accouchement/Accouchement/VoirPlus/LayoutAccouchementDetails"
)
);

const LayoutAccouchementOrdonnanceMereListe = lazy(() =>
import(
    "../../components/accouchement/ordonnancesMeres/Liste/LayoutAccouchementOrdonnanceMereListe"
)
);

const LayoutAccouchementOrdonnanceEnfantListe = lazy(() =>
import(
    "../../components/accouchement/ordonnancesEnfants/Liste/LayoutAccouchementOrdonnanceEnfantListe"
)
);

const LayoutAccouchementOrdonnanceMereFormView = lazy(() =>
import(
    "../../components/accouchement/ordonnancesMeres/Form/LayoutAccouchementOrdonnanceMereFormView"
)
);

const LayoutAccouchementOrdonnanceEnfantFormView = lazy(() =>
import(
    "../../components/accouchement/ordonnancesEnfants/Form/LayoutAccouchementOrdonnanceEnfantFormView"
)
);

const LayoutAccouchementDecesListe = lazy(() =>
import("../../components/accouchement/Deces/Liste/LayoutAccouchementDecesListe")
);

const LayoutAccouchementDecesCertificat = lazy(() =>
import(
    "../../components/accouchement/Deces/Certificat/LayoutAccouchementDecesCertificat"
)
);

const LayoutAccouchementListeEvacues = lazy(() =>
import("../../components/accouchement/evacues/LayoutAccouchementListeEvacues.jsx")
);

const LayoutAccouchementListeRefExterne = lazy(() =>
import(
    "../../components/accouchement/refExterne/LayoutAccouchementListeRefExterne"
)
);


const LayoutAccouchementRdvCreate = lazy(() =>
import(
    "../../components/accouchement/rendezVous/Create/LayoutAccouchementRdvCreate.jsx"
)
);

const LayoutAccouchementRdvListe = lazy(() =>
import("../../components/accouchement/rendezVous/Liste/LayoutAccouchementRdvListe.jsx")
);

const AgendaAccouchement = lazy(() =>
import(
    "../../components/accouchement/rendezVous/agendaDetail/AgendaAccouchement.js"
)
);

const LayoutAccouchementRdvLastConsultation = lazy(() =>
import(
    "../../components/accouchement/rendezVous/LastConsultation/LayoutAccouchementRdvLastConsultation.js"
)
);

const LayoutAccouchementRdvNewConsultation = lazy(() =>
import(
    "../../components/accouchement/rendezVous/NewConsultation/LayoutAccouchementRdvNewConsultation.js"
)
);

;



const LayoutAccouchementSortie = lazy(() =>
import(
    "../../components/accouchement/Accouchement/Issue/LayoutAccouchementSortie"
)
);

const LayoutAccouchementSortieRdv = lazy(() =>
import(
    "../../components/accouchement/Accouchement/Issue/rdv/LayoutAccouchementSortieRdv"
)
);

const LayoutAccouchementSortieDecesCreate = lazy(() =>
import(
    "../../components/accouchement/Accouchement/Issue/IssueDeDeces/LayoutAccouchementSortieDecesCreate"
)
);
const LayoutAccouchementSortieEvacuationCreate = lazy(() =>
import(
    "../../components/accouchement/Accouchement/Issue/Evacuation/LayoutAccouchementSortieEvacuationCreate"
)
);

const LayoutAccouchementSortieSuiteCoucheCreate = lazy(() =>
import(
    "../../components/accouchement/Accouchement/Issue/SuiteDeCouche/LayoutAccouchementSortieSuiteCoucheCreate"
)
);


const LayoutListeSuiteCoucheSansComplications = lazy(() =>
import("../../components/accouchement/SuiteCouche/SansComplication/Liste/LayoutListeSuiteCoucheSansComplications")
);


const LayoutListeFIchesPartogramme = lazy(() =>
import("../../components/accouchement/partogramme/accueil/LayoutListeFIchesPartogramme"
)
);



const LayoutListeFIchesPartogrammeTerminer = lazy(() =>
import("../../components/accouchement/partogramme/partogrammestermines/LIste/LIstePartoTerminer"
)
); 

const LayoutDetailPartoTermine = lazy(() =>
import("../../components/accouchement/partogramme/partogrammestermines/DetailsParto/DetailPartoTermine"
)
); 




const LayoutListeCreateFIchesPartogramme = lazy(() =>
import("../../components/accouchement/partogramme/consultationPartograme/ConsultationPartogramme"
)

);



const LayoutSuiteCoucheSansComplications = lazy(() =>
import("../../components/accouchement/SuiteCouche/SansComplication/Create/LayoutSuiteCoucheSansComplications.jsx")
);

const LayoutAccouchementSortieSuiteCoucheSansComplications = lazy(() =>
import(
    "../../components/accouchement/SuiteCouche/SansComplication/LayoutAccouchementSortieSuiteCoucheSansComplications"
)
);

const LayoutAccouchementSuiteCoucheSansComplicationsRefereInterne = lazy(() =>
import(
    "../../components/accouchement/SuiteCouche/SansComplication/issue/ReferenceInterne/LayoutAccouchementSuiteCoucheSansComplicationsRefereInterne"
)
);

const LayoutAccouchementSuiteCoucheSansComplicationsRefereExterne = lazy(() =>
import(
    "../../components/accouchement/SuiteCouche/SansComplication/issue/ReferenceExterne/LayoutAccouchementSuiteCoucheSansComplicationsRefereExterne"
)
);

const LayoutAccouchementSuiteCoucheSansComplicationsDemandeHospitalisation = lazy(() =>
import(
    "../../components/accouchement/SuiteCouche/SansComplication/issue/DemandeHospitalisation/LayoutAccouchementSuiteCoucheSansComplicationsDemandeHospitalisation"
)
);


const LayoutListeSuiteCoucheAvecComplications = lazy(() =>
import("../../components/accouchement/SuiteCouche/AvecComplication/Liste/LayoutListeSuiteCoucheAvecComplications")
);

const LayoutAccouchementSuiteCoucheAvecComplicationsIssueSortie = lazy(() =>
import("../../components/accouchement/SuiteCouche/AvecComplication/Issues/LayoutAccouchementSuiteCoucheAvecComplicationsIssueSortie")
);

const LayoutAccouchementSuiteCoucheAvecComplications = lazy(() =>
import("../../components/accouchement/SuiteCouche/AvecComplication/Create/LayoutAccouchementSuiteCoucheAvecComplications")
);

const LayoutAccouchementSuiteCoucheAvecComplicationsDeces = lazy(() =>
import(
    "../../components/accouchement/SuiteCouche/AvecComplication/Issues/LayoutAccouchementSuiteCoucheAvecComplicationsDeces"
)
);

const LayoutAccouchementSuiteCoucheAvecComplicationsRefereExterne = lazy(() =>
import(
    "../../components/accouchement/SuiteCouche/AvecComplication/Issues/LayoutAccouchementSuiteCoucheAvecComplicationsRefereExterne"
)
);

const LayoutAccouchementSuiteCoucheAvecComplicationsRendezVous = lazy(() =>
import(
    "../../components/accouchement/SuiteCouche/AvecComplication/Issues/LayoutAccouchementSuiteCoucheAvecComplicationsRendezVous"
)
);


const LayoutAccouchementsRapports = lazy(() =>
import(
    "../../components/accouchement/rapports/LayoutAccouchementsRapports"
)
);

const LayoutAccouchementControleMajor = lazy(() => 
    import("../../components/accouchement/controleMajor/LayoutAccouchementControleMajor")
)

const LayoutMaterniteSuiteCoucheSansSortie = lazy(() =>
    import(
      "../../components/maternite/SuiteCouche/SansComplication/LayoutMaterniteSuiteCoucheSansSortie"
    )
);
  

export default function AccouchementRouter() {
  return (
    <Switch>
        <MyPrivateRouters.PrivateRouterAccouchement
        path="/accouchement"
        component={LayoutAccouchementList}
        />

        <MyPrivateRouters.PrivateRouterAccouchement
        path="/accouchement-effectues"
        component={LayoutAccouchementTerminesListe}
        />

        <MyPrivateRouters.PrivateRouterAccouchement
        path="/accouchement-effectues-voir-detail/:id"
        component={LayoutAccouchementDetails}
        />

        <MyPrivateRouters.PrivateRouterAccouchement
        path="/accouchement-create/:id"
        component={LayoutAccouchementCreate}
        />

        <MyPrivateRouters.PrivateRouterAccouchement
        path="/accouchement-sortie/:id"
        component={LayoutAccouchementSortie}
        />

        <MyPrivateRouters.PrivateRouterAccouchement
        path="/accouchement-sortie-deces/:id"
        component={LayoutAccouchementSortieDecesCreate}
        />

        <MyPrivateRouters.PrivateRouterAccouchement
        path="/accouchement-sortie-evacuation/:id"
        component={LayoutAccouchementSortieEvacuationCreate}
        />

        <MyPrivateRouters.PrivateRouterAccouchement
        path="/accouchement-sortie-suite-couche/:id"
        component={LayoutAccouchementSortieSuiteCoucheCreate}
        />


        <MyPrivateRouters.PrivateRouterAccouchement
        path="/accouchement-sortie-rdv-create/:id"
        component={LayoutAccouchementSortieSuiteCoucheCreate}
        />

        <MyPrivateRouters.PrivateRouterAccouchement
        path="/accouchement-sortie-reference-interne/:id"
        component={LayoutAccouchementSortieRdv}
        />

        <MyPrivateRouters.PrivateRouterAccouchement
        path="/accouchement-ordonnance-mere"
        component={LayoutAccouchementOrdonnanceMereListe}
        />

        <MyPrivateRouters.PrivateRouterAccouchement
        path="/accouchement-ordonnance-mere-voir/:id"
        component={LayoutAccouchementOrdonnanceMereFormView}
        />

        <MyPrivateRouters.PrivateRouterAccouchement
        path="/accouchement-ordonnance-enfant"
        component={LayoutAccouchementOrdonnanceEnfantListe}
        />

        <MyPrivateRouters.PrivateRouterAccouchement
        path="/accouchement-ordonnance-enfant-voir/:id"
        component={LayoutAccouchementOrdonnanceEnfantFormView}
        />

        <MyPrivateRouters.PrivateRouterAccouchement
        path="/accouchement-suite-de-couche/:id"
        component={LayoutAccouchementSortieSuiteCoucheSansComplications}
        />

        <MyPrivateRouters.PrivateRouterAccouchement
        path="/accouchement-suite-de-couche-sans-complication"
        component={LayoutListeSuiteCoucheSansComplications}
        />

        <MyPrivateRouters.PrivateRouterAccouchement
        path="/accouchement-suite-de-couche-consultation/:id"
        component={LayoutSuiteCoucheSansComplications}
        />

        <MyPrivateRouters.PrivateRouterAccouchement
        path="/accouchement-suite-de-couche-sans-sortie/:id"
        component={LayoutMaterniteSuiteCoucheSansSortie}
        />

        <MyPrivateRouters.PrivateRouterAccouchement
        path="/accouchement-suite-de-couche-sans-sortie-reference-interne/:id"
        component={LayoutAccouchementSuiteCoucheSansComplicationsRefereInterne}
        />

        <MyPrivateRouters.PrivateRouterAccouchement
        path="/accouchement-suite-de-couche-sans-sortie-reference-externe/:id"
        component={LayoutAccouchementSuiteCoucheSansComplicationsRefereExterne}
        />

        <MyPrivateRouters.PrivateRouterAccouchement
        path="/accouchement-suite-de-couche-sans-sortie-demande-hospitalisation/:id"
        component={LayoutAccouchementSuiteCoucheSansComplicationsDemandeHospitalisation}
        />

        <MyPrivateRouters.PrivateRouterAccouchement
        path="/accouchement-partogramme"
        component={LayoutListeFIchesPartogramme}
        />

        <MyPrivateRouters.PrivateRouterAccouchement
        path="/accouchement-liste-partogramme-end"
        component={LayoutListeFIchesPartogrammeTerminer}
        />
        <MyPrivateRouters.PrivateRouterAccouchement
        path="/accouchement-partogramme-end-detail/:id"
        component={LayoutDetailPartoTermine}
        />

        <MyPrivateRouters.PrivateRouterAccouchement
        path="/accouchement-partogramme-create/:id"
        component={LayoutListeCreateFIchesPartogramme}
        />

        <MyPrivateRouters.PrivateRouterAccouchement
        path="/accouchement-suite-de-couche-complication"
        component={LayoutListeSuiteCoucheAvecComplications}
        />

        <MyPrivateRouters.PrivateRouterAccouchement
        path="/accouchement-suite-de-couche-complication-issue-sortie/:id"
        component={LayoutAccouchementSuiteCoucheAvecComplicationsIssueSortie}
        />

        <MyPrivateRouters.PrivateRouterAccouchement
        path="/accouchement-suite-de-couche-complication-consultation/:id"
        component={LayoutAccouchementSuiteCoucheAvecComplications}
        />

        <MyPrivateRouters.PrivateRouterAccouchement
        path="/accouchement-suite-de-couche-complication-deces/:id"
        component={LayoutAccouchementSuiteCoucheAvecComplicationsDeces}
        />

        <MyPrivateRouters.PrivateRouterAccouchement
        path="/accouchement-suite-de-couche-complication-reference-externe/:id"
        component={LayoutAccouchementSuiteCoucheAvecComplicationsRefereExterne}
        />

        <MyPrivateRouters.PrivateRouterAccouchement
        path="/accouchement-suite-de-couche-complication-rdv/:id"
        component={LayoutAccouchementSuiteCoucheAvecComplicationsRendezVous}
        />

        <MyPrivateRouters.PrivateRouterAccouchement
        path="/accouchement-evacues"
        component={LayoutAccouchementListeEvacues}
        />

        <MyPrivateRouters.PrivateRouterAccouchement
        path="/accouchement-referencements-externe"
        component={LayoutAccouchementListeRefExterne}
        />

        <MyPrivateRouters.PrivateRouterAccouchement
        path="/accouchement-rdv"
        component={LayoutAccouchementRdvListe}
        />

        <MyPrivateRouters.PrivateRouterAccouchement
        path="/accouchement-rdv-create/:id"
        component={LayoutAccouchementRdvCreate}
        />

        <MyPrivateRouters.PrivateRouterAccouchement
        path="/accouchement-rdv-agenda"
        component={AgendaAccouchement}
        />

        <MyPrivateRouters.PrivateRouterAccouchement
        path="/accouchement-rdv-last-consultation/:id"
        component={LayoutAccouchementRdvLastConsultation}
        />

        <MyPrivateRouters.PrivateRouterAccouchement
        path="/accouchement-rdv-new-consultation/:id"
        component={LayoutAccouchementRdvNewConsultation}
        />

        <MyPrivateRouters.PrivateRouterAccouchement
        path="/accouchement-patients-decedes"
        component={LayoutAccouchementDecesListe}
        />

        <MyPrivateRouters.PrivateRouterAccouchement
        path="/accouchement-patients-decedes-certificat/:id"
        component={LayoutAccouchementDecesCertificat}
        />

        <MyPrivateRouters.PrivateRouterAccouchement
        path="/accouchement-rapports"
        component={LayoutAccouchementsRapports}
        />

        <MyPrivateRouters.PrivateRouterAccouchement
        path="/accouchement-controle-major"
        component={LayoutAccouchementControleMajor}
        />
    </Switch>
  )
}
