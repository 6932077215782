import React, { lazy } from "react"
import * as MyPrivateRouters from "../routes/PrivateRouter";
import { Switch } from 'react-router-dom';


const LayoutEtablissementAdministrateurCreate = lazy(() => import("../../components/etablissements/administrateurs/Create/LayoutEtablissementAdministrateurCreate"));
const LayoutEtablissementAdministrateurListe = lazy(() => import("../../components/etablissements/administrateurs/Liste/LayoutEtablissementAdministrateurListe"));
const LayoutEtablissementAdministrateurAvecCentreListe = lazy(() => import("../../components/etablissements/administrateurs/ListeAvecCentre/LayoutEtablissementAdministrateurAvecCentreListe"));
const LayoutEtablissementAdministrateurUpdate = lazy(() => import("../../components/etablissements/administrateurs/Update/LayoutEtablissementAdministrateurUpdate"))


const LayoutEtablissementEtablissementCreate = lazy(() => import("../../components/etablissements/etablissements/Create/LayoutEtablissementEtablissementCreate"));
const LayoutEtablissementEtablissementListe = lazy(() => import("../../components/etablissements/etablissements/Liste/LayoutEtablissementEtablissementListe"));
const LayoutEtablissementEtablissementVoir = lazy(() => import("../../components/etablissements/etablissements/Voir/LayoutEtablissementEtablissementVoir"))
const LayoutEtablissementEtablissementUpdate = lazy(() => import("../../components/etablissements/etablissements/Update/LayoutEtablissementEtablissementUpdate"))

  

export default function EtablissementsRouter() {
  return (
    <Switch>
        <MyPrivateRouters.PrivateRouterEtablissements
        path="/etablissements"
        component={LayoutEtablissementAdministrateurListe}
        />

        <MyPrivateRouters.PrivateRouterEtablissements
        path="/etablissements-affectes-administrateur"
        component={LayoutEtablissementAdministrateurAvecCentreListe}
        />

        <MyPrivateRouters.PrivateRouterEtablissements
        path="/etablissements-administrateur-create"
        component={LayoutEtablissementAdministrateurCreate}
        />

        <MyPrivateRouters.PrivateRouterEtablissements
        path="/etablissements-administrateur-update/:id"
        component={LayoutEtablissementAdministrateurUpdate}
        />

        <MyPrivateRouters.PrivateRouterEtablissements
        path="/etablissements-etablissement-liste"
        component={LayoutEtablissementEtablissementListe}
        />

        <MyPrivateRouters.PrivateRouterEtablissements
        path="/etablissements-etablissement-create"
        component={LayoutEtablissementEtablissementCreate}
        />

        <MyPrivateRouters.PrivateRouterEtablissements
        path="/etablissements-etablissement-update/:id"
        component={LayoutEtablissementEtablissementUpdate}
        />

        <MyPrivateRouters.PrivateRouterEtablissements
        path="/etablissements-etablissement-voir/:id"
        component={LayoutEtablissementEtablissementVoir}
        />
    </Switch>
  )
}
