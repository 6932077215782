export const PATIENTS_PROFESSION_GETS_DATA_LOADING = "PATIENTS_PROFESSION_GETS_DATA_LOADING"
export const PATIENTS_PROFESSION_GETS_DATA_SUCCESS = "PATIENTS_PROFESSION_GETS_DATA_SUCCESS"

export const PATIENTS_PROFESSION_GETS_DATA_FAILED = 'PATIENTS_PROFESSION_GETS_DATA_FAILED'

export const PATIENTS_PARENT_GETS_DATA_LOADING = "PATIENTS_PARENT_GETS_DATA_LOADING"

export const PATIENTS_PARENT_GETS_DATA_SUCCESS = "PATIENTS_PARENT_GETS_DATA_SUCCESS"

export const PATIENTS_PARENT_GETS_DATA_FAILED = 'PATIENTS_PARENT_GETS_DATA_FAILED'

export const PATIENT_SET_SUCCESS = 'PATIENT_SET_SUCCESS'

export const PATIENT_CARTE_GETS_DATA_SUCCESS = 'PATIENT_CARTE_GETS_DATA_SUCCESS'

export const PATIENT_CARTE_GETS_DATA_FAILED = 'PATIENT_CARTE_GETS_DATA_FAILED'

export const PATIENT_CARTE_GETS_DATA_LOADING = 'PATIENT_CARTE_GETS_DATA_LOADING'

//export const GET_ALL_PATIENT_REQUEST = 'GET_ALL_PATIENT_REQUEST'
//export const GET_ALL_PATIENT_SUCCESS = 'GET_ALL_PATIENT_SUCCESS'
//export const GET_ALL_PATIENT_FAIL = 'GET_ALL_PATIENT_FAIL'

export const patientparametreTypes = {
    PATIENT_LIST_REQUEST: "PATIENT_LIST_REQUEST",
    PATIENT_LIST_SUCCESS: "PATIENT_LIST_SUCCESS",
    PATIENT_LIST_FAIL: "PATIENT_LIST_FAIL",
};


// export const ADD_PATIENT_REQUEST = 'ADD_PATIENT_REQUEST'
// export const ADD_PATIENT_SUCCESS = 'ADD_PATIENT_SUCCESS'
// export const ADD_PATIENT_FAIL = 'ADD_PATIENT_FAIL'
// export const ADD_PATIENT_RESET = 'ADD_PATIENT_RESET'


// export const DETAIL_PATIENT_REQUEST = 'DETAIL_PATIENT_REQUEST'
// export const DETAIL_PATIENT_SUCCESS = 'DETAIL_PPATIENT_SUCCESS'
// export const DETAIL_PATIENT_FAIL = 'DETAIL_PATIENT_FAIL'



export const UPDATE_PATIENT_REQUEST = 'UPDATE_PATIENT_REQUEST'
export const UPDATE_PATIENT_SUCCESS = 'UPDATE_PATIENT_SUCCESS'
export const UPDATE_PATIENT_FAIL = 'UPDATE_PATIENT_FAIL'
export const UPDATE_PATIENT_RESET = 'UPDATE_PATIENT_RESET'


export const PATIENTS_DELETE_DATA_SUCCESS = 'PATIENTS_DELETE_DATA_SUCCESS'
export const PATIENTS_DELETE_DATA_IMPOSSIBLE_SUCCESS = "PATIENTS_DELETE_DATA_IMPOSSIBLE_SUCCESS"
export const PATIENTS_DELETE_DATA_LOADING = 'PATIENTS_DELETE_DATA_LOADING'
export const PATIENTS_DELETE_DATA_FAILED = 'PATIENTS_DELETE_DATA_FAILED'


export const PATIENTS_DESARCHIVAGE_DATA_SUCCESS = 'PATIENTS_DESARCHIVAGE_DATA_SUCCESS'
export const PATIENTS_DESARCHIVAGE_DATA_LOADING = 'PATIENTS_DESARCHIVAGE_DATA_LOADING'
export const PATIENTS_DESARCHIVAGE_DATA_FAILED = 'PATIENTS_DESARCHIVAGE_DATA_FAILED'


export const PATIENT_GET_DATA_SUCCESS = 'PATIENT_GET_DATA_SUCCESS'
export const PATIENT_GET_DATA_FAILED = 'PATIENT_GET_DATA_FAILED'
export const PATIENT_GET_DATA_LOADING = 'PATIENT_GET_DATA_LOADING'


export const PATIENTS_GETS_DATA_SUCCESS = 'PATIENTS_GETS_DATA_SUCCESS'

export const PATIENTS_GETS_DATA_FAILED = 'PATIENTS_GETS_DATA_FAILED'

export const PATIENTS_GETS_DATA_LOADING = 'PATIENTS_GETS_DATA_LOADING'




export const PATIENTS_GLOBALS_GETS_DATA_LOADING = 'PATIENTS_GLOBALS_GETS_DATA_LOADING'

export const PATIENTS_GLOBALS_GETS_DATA_SUCCESS = 'PATIENTS_GLOBALS_GETS_DATA_SUCCESS'

export const PATIENTS_GLOBALS_GETS_DATA_FAILED = 'PATIENTS_GLOBALS_GETS_DATA_FAILED'




export const PATIENTS_GETS_DATA_REFRESH_LOADING = "PATIENTS_GETS_DATA_REFRESH_LOADING"

export const PATIENTS_GETS_DATA_REFRESH_SUCCESS = "PATIENTS_GETS_DATA_REFRESH_SUCCESS" 

export const PATIENTS_GETS_DATA_REFRESH_FAILED = "PATIENTS_GETS_DATA_REFRESH_FAILED"


export const PATIENT_DELETE_DATA_LOADING = 'PATIENT_DELETE_DATA_LOADING'

export const PATIENT_DELETE_DATA_SUCCESS = 'PATIENT_DELETE_DATA_SUCCESS'

export const PATIENT_DELETE_DATA_FAILED = 'PATIENT_DELETE_DATA_FAILED'



export const PATIENT_POST_DATA_LOADING = 'PATIENT_POST_DATA_LOADING'

export const PATIENT_POST_DATA_SUCCESS = 'PATIENT_POST_DATA_SUCCESS'

export const PATIENT_POST_DATA_IMPOSSIBLE_SUCCESS = "PATIENT_POST_DATA_IMPOSSIBLE_SUCCESS"

export const PATIENT_POST_DATA_FAILED = 'PATIENT_POST_DATA_FAILED'



export const PATIENT_POST_UPLOAD_DATA_LOADING = 'PATIENT_POST_UPLOAD_DATA_LOADING'

export const PATIENT_POST_UPLOAD_DATA_SUCCESS = 'PATIENT_POST_UPLOAD_DATA_SUCCESS'

export const PATIENT_POST_UPLOAD_DATA_IMPOSSIBLE_SUCCESS = "PATIENT_POST_UPLOAD_DATA_IMPOSSIBLE_SUCCESS"

export const PATIENT_POST_UPLOAD_DATA_FAILED = 'PATIENT_POST_UPLOAD_DATA_FAILED'



export const PATIENT_PUT_DATA_LOADING = 'PATIENT_PUT_DATA_LOADING'

export const PATIENT_PUT_DATA_SUCCESS = 'PATIENT_PUT_DATA_SUCCESS'

export const PATIENT_PUT_DATA_FAILED = 'PATIENT_PUT_DATA_FAILED'

export const PATIENT_PUT_ERROR_DATA_SUCCESS = 'PATIENT_PUT_ERROR_DATA_SUCCESS'



export const PATIENT_PATCH_DATA_LOADING = 'PATIENT_PATCH_DATA_LOADING'

export const PATIENT_PATCH_DATA_SUCCESS = 'PATIENT_PATCH_DATA_SUCCESS'

export const PATIENT_PATCH_DATA_FAILED = 'PATIENT_PATCH_DATA_FAILED'



export const FICHES_ORIENTATIONS_GETS_LOADING = "FICHES_ORIENTATIONS_GETS_LOADING"

export const FICHES_ORIENTATIONS_GETS_SUCCESS = "FICHES_ORIENTATIONS_GETS_SUCCESS" 

export const FICHES_ORIENTATIONS_GETS_FAILED = "FICHES_ORIENTATIONS_GETS_FAILED"



export const FICHES_ORIENTATIONS_GETS_REFRESH_LOADING = "FICHES_ORIENTATIONS_GETS_REFRESH_LOADING"

export const FICHES_ORIENTATIONS_GETS_REFRESH_SUCCESS = "FICHES_ORIENTATIONS_GETS_REFRESH_SUCCESS" 

export const FICHES_ORIENTATIONS_GETS_REFRESH_FAILED = "FICHES_ORIENTATIONS_GETS_REFRESH_FAILED"


export const FICHES_ORIENTATIONS_BY_PATIENT_GETS_LOADING = "FICHES_ORIENTATIONS_BY_PATIENT_GETS_LOADING"

export const FICHES_ORIENTATIONS_BY_PATIENT_GETS_SUCCESS = "FICHES_ORIENTATIONS_BY_PATIENT_GETS_SUCCESS" 

export const FICHES_ORIENTATIONS_BY_PATIENT_GETS_FAILED = "FICHES_ORIENTATIONS_BY_PATIENT_GETS_FAILED"

export const ASSURANCE_GET_LOADING = "ASSURANCE_GET_LOADING"
export const ASSURANCE_GET_SUCCESS = "ASSURANCE_GET_SUCCESS" 
export const ASSURANCE_GET_FAILED = "ASSURANCE_GET_FAILED"

export const ASSURANCES_GETS_LOADING = "ASSURANCES_GETS_LOADING"
export const ASSURANCES_GETS_SUCCESS = "ASSURANCES_GETS_SUCCESS" 
export const ASSURANCES_GETS_FAILED = "ASSURANCES_GETS_FAILED"

export const ASSURANCES_REFRESH_GETS_LOADING = "ASSURANCES_REFRESH_GETS_LOADING"
export const ASSURANCES_REFRESH_GETS_SUCCESS = "ASSURANCES_REFRESH_GETS_SUCCESS" 
export const ASSURANCES_REFRESH_GETS_FAILED = "ASSURANCES_REFRESH_GETS_FAILED"

export const ASSURANCES_CENTRE_POST_LOADING = "ASSURANCES_CENTRE_POST_LOADING"
export const ASSURANCES_CENTRE_POST_SUCCESS = "ASSURANCES_CENTRE_POST_SUCCESS" 
export const ASSURANCES_CENTRE_POST_FAILED = "ASSURANCES_CENTRE_POST_FAILED"

export const ASSURANCES_CENTRE_PUT_LOADING = "ASSURANCES_CENTRE_PUT_LOADING"
export const ASSURANCES_CENTRE_PUT_SUCCESS = "ASSURANCES_CENTRE_PUT_SUCCESS" 
export const ASSURANCES_CENTRE_PUT_FAILED = "ASSURANCES_CENTRE_PUT_FAILED"

export const ASSURANCE_CENTRE_DELETE_LOADING = "ASSURANCE_CENTRE_DELETE_LOADING"
export const ASSURANCE_CENTRE_DELETE_SUCCESS = "ASSURANCE_CENTRE_DELETE_SUCCESS" 
export const ASSURANCE_CENTRE_DELETE_FAILED = "ASSURANCE_CENTRE_DELETE_FAILED"
export const ASSURANCE_CENTRE_DELETE_IMPOSSIBLE = "ASSURANCE_CENTRE_DELETE_IMPOSSIBLE"


export const ASSURANCES_ALL_GETS_LOADING = "ASSURANCES_ALL_GETS_LOADING"
export const ASSURANCES_ALL_GETS_SUCCESS = "ASSURANCES_ALL_GETS_SUCCESS" 
export const ASSURANCES_ALL_GETS_FAILED = "ASSURANCES_ALL_GETS_FAILED"

export const GET_PATIENTS_PAYMENT_SLIP_LOADING = "GET_PATIENTS_PAYMENT_SLIP_LOADING"
export const GET_PATIENTS_PAYMENT_SLIP_SUCCESS  = "GET_PATIENTS_PAYMENT_SLIP_SUCCESS"
export const GET_PATIENTS_PAYMENT_SLIP_FAILED = "GET_PATIENTS_PAYMENT_SLIP_FAILED"



export const GET_SEARCHING_PATIENT_LOADING = "GET_SEARCHING_PATIENT_LOADING"
export const GET_SEARCHING_PATIENT_SUCCESS = "GET_SEARCHING_PATIENT_SUCCESS"
export const GET_SEARCHING_PATIENT_FAILED = "GET_SEARCHING_PATIENT_FAILED"



export const GET_LAST_DEMANDE_EXAMEN_REQUEST = "GET_LAST_DEMANDE_EXAMEN_REQUEST"
export const GET_LAST_DEMANDE_EXAMEN_SUCCESS = "GET_LAST_DEMANDE_EXAMEN_SUCCESS"
export const GET_LAST_DEMANDE_EXAMEN_FAILED = "GET_LAST_DEMANDE_EXAMEN_FAILED"

export const PATIENT_POST_404 = "PATIENT_POST_404"