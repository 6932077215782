export const TYPE_EXAMEN_GET_DATA_SUCCESS = 'TYPE_EXAMEN_GET_DATA_SUCCESS'

export const TYPE_EXAMEN_GET_DATA_FAILED = 'TYPE_EXAMEN_GET_DATA_FAILED'

export const TYPE_EXAMEN_GET_DATA_LOADING = 'TYPE_EXAMEN_GET_DATA_LOADING'


export const TYPE_EXAMEN_EXTERNE_GET_DATA_SUCCESS = 'TYPE_EXAMEN_EXTERNE_GET_DATA_SUCCESS'
export const TYPE_EXAMEN_EXTERNE_GET_DATA_FAILED = 'TYPE_EXAMEN_EXTERNE_GET_DATA_FAILED'
export const TYPE_EXAMEN_EXTERNE_GET_DATA_LOADING = 'TYPE_EXAMEN_EXTERNE_GET_DATA_LOADING'
