// import { parametreTypes } from "../../types/parametreTypes";
// import { patientparametreTypes } from "../../types/patientTypes";
import { litparametreTypes } from "../../types/litTypes";
import { AUTH_ERROR, LOGOUT_SUCCESS, TOKEN_REFRESH_FAIL } from "../../types/userTypes";
const initialState = {
    isLoading: false,
    error: "",
    isSuccess:false,
    lits: [],
    litsDisponibles: [],
    lit: {},
    deleteLoader: false,
};

export const litListReducers = (state = initialState, action) => {
    switch (action.type) {
        case litparametreTypes.LIT_LIST_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: "",
                isSuccess:false,
                lit: {},
                deleteLoader: false,
            };
        case litparametreTypes.LIT_LIST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                lits: action.payload,
                error: "",
                isSuccess:false,
                lit: {},
                deleteLoader: false,
            };
        case litparametreTypes.LIT_LIST_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                isSuccess:false,
                lit: {},
                deleteLoader: false,
            };

        case litparametreTypes.LIT_LIST_DISPONIBLE_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: "",
                isSuccess:false,
                litsDisponibles: [],
                lit: {},
                deleteLoader: false,
            };
        case litparametreTypes.LIT_LIST_DISPONIBLE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                litsDisponibles: action.payload,
                error: "",
                isSuccess:false,
                lit: {},
                deleteLoader: false,
            };
        case litparametreTypes.LIT_LIST_DISPONIBLE_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                isSuccess:false,
                litsDisponibles: [],
                lit: {},
                deleteLoader: false,
            };

        case litparametreTypes.LIT_POST_LOADING:
            return {
                ...state,
                isLoading: true,
                isSuccess:false,
                error: '',
                lit: {},
                deleteLoader: false,
            };
        case litparametreTypes.LIT_POST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error: "",
                isSuccess:true,
                lit: {},
                deleteLoader: false,
            };
        case litparametreTypes.LIT_POST_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess:false,
                error: action.payload,
                lit: {},
                deleteLoader: false,
            };
        case litparametreTypes.LIT_DETAIL_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: "",
                isSuccess:false,
                lit: {},
                deleteLoader: false,
            };
        case litparametreTypes.LIT_DETAIL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error: "",
                isSuccess:false,
                lit: {...action.payload},
                deleteLoader: false,
            };
        case litparametreTypes.LIT_DETAIL_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                isSuccess:false,
                lit: {},
                deleteLoader: false,
            };
        case litparametreTypes.LIT_UPDATE_REQUEST:
            return {
                ...state,
                isLoading: true,
                isSuccess:false,
                error: '',
                lit: {},
                deleteLoader: false,
            };
        case litparametreTypes.LIT_UPDATE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error: "",
                isSuccess:true,
                lit: {},
                deleteLoader: false,
            };
        case litparametreTypes.LIT_UPDATE_FAIL:
            return {
                ...state,
                isLoading: false,
                isSuccess:false,
                error: action.payload,
                lit: {},
                deleteLoader: false,
            };
        case litparametreTypes.LIT_DELETE_REQUEST:
            return {
                ...state,
                deleteLoader: true,
                error: "",
            };
        case litparametreTypes.LIT_DELETE_SUCCESS:
            return {
                ...state,
                deleteLoader: false,
                lits: state.lits.filter(
                    (lit) => lit.id !== action.payload
                  ),
                error: "",
                success: true,
            };
        case litparametreTypes.LIT_DELETE_FAIL:
            return {
                ...state,
                deleteLoader: false,
                error: action.payload,
            }
            case "SUCCESS_CLEAN":
                return {
                    ...state,
                    success: false,
                    error: "",
                }

        case AUTH_ERROR:
        case LOGOUT_SUCCESS:
        case TOKEN_REFRESH_FAIL:
            localStorage.removeItem('token_access')
            localStorage.removeItem('token_refresh')
            return {
                ...state,
                isLoading: false,
                error: "",
                isSuccess:false,
                lits: [],
                litsDisponibles: [],
                lit: {},
                deleteLoader: false,
            }

        default:
            return state;
    }
};
