import * as patientTypes from "../../types/patientTypes";

const initialState = {
  data: [],
  isLoading: false,
  error: "",
};

const executeGetDataSuccess = (state, action) => {
  return {
    ...state,
    data: action.payload,
    isLoading: false,
    error: "",
  };
};
const executeGetDataFailed = (state, action) => {
  return {
    ...state,
    isLoading: false,
    error: action.payload,
  };
};
const executeGetDataLoading = (state) => {
  return {
    ...state,
    isLoading: true,
    error: "",
  };
};

const searchPatientsReducers = (state = initialState, action) => {
  switch (action.type) {
    case patientTypes.GET_SEARCHING_PATIENT_SUCCESS:
      return executeGetDataSuccess(state, action);

    case patientTypes.GET_SEARCHING_PATIENT_LOADING:
      return executeGetDataLoading(state, action);

    case patientTypes.GET_SEARCHING_PATIENT_FAILED:
      return executeGetDataFailed(state, action);
    default:
      return state;
  }
};

export default searchPatientsReducers;
