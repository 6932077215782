import React, { lazy } from "react"
import * as MyPrivateRouters from "../routes/PrivateRouter";
import { Switch } from 'react-router-dom';

const LayoutMedicineGPatientAConsulter = lazy(() =>
    import(
      "../../components/medecineG/patientAConsulter/LayoutMedicineGPatientAConsulter"
    )
);
const LayoutMedecineGConsultationListe = lazy(() =>
    import("../../components/medecineG/consultation/LayoutMedecineGConsultationListe")
);
const LayoutMedecineGDemandeHospitalisationListe = lazy(() =>
import(
    "../../components/medecineG/demandeHospitalisation/LayoutMedecineGDemandeHospitalisationListe"
)
);
const MedecineGObservation = lazy(() =>
    import("../../components/medecineG/observation/MedecineGObservation")
);

const MedecineGObservationTerminees = lazy(() =>
    import("../../components/medecineG/observationTerminees/MedecineGObservationTerminees")
);

const LayoutCreateConsultation = lazy(() =>
import(
    "../../components/medecineG/patientAConsulterCreate/LayoutCreateConsultation"
)
);

const LayoutViewConsultation = lazy(() =>
import(
    "../../components/medecineG/patientAConsulterDetail/LayoutViewConsultation"
)
);

const LayoutCreateConsultationSortie = lazy(() =>
import(
    "../../components/medecineG/patientAConsulterIssuSortie/LayoutCreateConsultationSortie"
)
);

const LayoutCreateConsultationSortieDemandeHospitalisation = lazy(() =>
import(
    "../../components/medecineG/patientAConsulterIssuSortie/DemandeHospitalisation/LayoutCreateConsultationSortieDemandeHospitalisation"
)
);
const LayoutCreateConsultationSortieMiseEnObservation = lazy(() =>
import(
    "../../components/medecineG/patientAConsulterIssuSortie/MiseEnObservation/LayoutCreateConsultationSortieMiseEnObservation"
)
);

const LayoutCreateConsultationRefereInterne = lazy(() =>
import(
    "../../components/medecineG/patientAConsulterIssuSortie/RefereInterne/LayoutCreateConsultationRefereInterne"
)
);
const LayoutCreateConsultationRefereExterne = lazy(() =>
import(
    "../../components/medecineG/patientAConsulterIssuSortie/RefereExterne/LayoutCreateConsultationRefereExterne"
)
);

const LayoutMedecineGOrdonnanceDetails = lazy(() =>
import(
    "../../components/medecineG/ordonnance/detail/LayoutMedecineGOrdonnanceDetails"
)
);
const LayoutMedecineGOrdonnanceListe = lazy(() =>
import(
    "../../components/medecineG/ordonnance/liste/LayoutMedecineGOrdonnanceListe"
)
);

const LayoutMedecineGHospitalisationListe = lazy(() =>
    import(
        "../../components/medecineG/hospitalisation/LayoutMedecineGHospitalisationListe"
    )
);

const LayoutMedecineGHospitalisationTermineesListe = lazy(() =>
    import(
        "../../components/medecineG/hospitalisationTerminees/LayoutMedecineGHospitalisationTermineesListe"
    )
);

const LayoutMedecineGHospitalisationConsultationCreate = lazy(() =>
import(
    "../../components/medecineG/hospitalisation/consultation/LayoutMedecineGHospitalisationConsultationCreate"
)
);

const LayoutMedecineGHospitalisationConsultationSortie = lazy(() =>
import(
    "../../components/medecineG/hospitalisation/issueSortie/LayoutMedecineGHospitalisationConsultationSortie"
)
);

const LayoutMedicineGFeuilleDeSoins = lazy(() =>
    import("../../components/medecineG/feuilleDeSoins/LayoutMedicineGFeuilleDeSoins")
);

const MedecineGRefExterne = lazy(() =>
    import("../../components/medecineG/refExterne/MedecineGRefExterne")
);

const MedecineGRefExterneFiche = lazy(() =>
  import("../../components/medecineG/refExterne/MedecineGRefExterneFiche")
);

const MedecineGRefInterne = lazy(() =>
    import("../../components/medecineG/refInterne/MedecineGRefInterne")
);

const LayoutMedecineGDecesConsultationSortie = lazy(() =>
import(
    "../../components/medecineG/patientAConsulterIssuSortie/IssueDeDeces/LayoutMedecineGDecesConsultationSortie"
)
);

const MedecineGExamensDetail = lazy(() =>
    import("../../components/medecineG/Examens/detail")
);
const MedecineGExamensList = lazy(() =>
    import("../../components/medecineG/Examens/list")
);

const DemandeHospiObservationMedecineGeneral = lazy(() =>
import(
    "../../components/medecineG/patientAConsulterIssuSortie/MiseEnObservation/DemandeHospitalisation/LayoutCreateConsultationSortieDemandeHospitalisation"
)
);
const RefExterneObservationMedecineGeneral = lazy(() =>
import(
    "../../components/medecineG/patientAConsulterIssuSortie/MiseEnObservation/RefereExterne/LayoutCreateConsultationRefereExterne"
)
);
const RefInterneObservationMedecineGeneral = lazy(() =>
import(
    "../../components/medecineG/patientAConsulterIssuSortie/MiseEnObservation/RefereInterne/LayoutCreateConsultationRefereInterne"
)
);

const LayoutMedecineGDecesListe = lazy(() =>
    import("../../components/medecineG/Deces/Liste/LayoutMedecineGDecesListe")
);
const LayoutMedecineGDecesCertificat = lazy(() =>
import(
    "../../components/medecineG/Deces/Certificat/LayoutMedecineGDecesCertificat"
)
);

const LayoutVoirMedecineGeneralResultatsExamens = lazy(() =>
import(
    "../../components/medecineG/Examens/resultatsExamensVoir/LayoutVoirMedecineGeneralResultatsExamens"
)
);

const AgendaMG = lazy(() =>
    import("../../components/medecineG/rendezVous/agendaDetail/AgendaMG")
);
const LayoutMedecineGCreateConsultationRendezVous = lazy(() =>
import(
    "../../components/medecineG/patientAConsulterIssuSortie/RendezVous/LayoutMedecineGCreateConsultationRendezVous"
)
);
const LayoutMedecineGRdvListe = lazy(() =>
    import("../../components/medecineG/rendezVous/Liste/LayoutMedecineGRdvListe")
);
const LayoutMGRdvLastConsultation = lazy(() =>
    import("../../components/medecineG/rendezVous/LastConsultation/LayoutMGRdvLastConsultation")
);
const LayoutMGRdvNewConsultation = lazy(() =>
    import("../../components/medecineG/rendezVous/NewConsultation/LayoutMGRdvNewConsultation")
);

const RapportSpecialiteMain = lazy(() =>
    import("../../components/medecineG/rapports/RapportMedecinG")
);

const LayoutMedecinGControleMajor = lazy(() => 
    import("../../components/medecineG/controleMajor/LayoutMedecinGControleMajor")
)

const LayoutMedecineGRdvCreate = lazy(() => 
    import(
        "../../components/medecineG/rendezVous/Create/LayoutMedecineGRdvCreate"
    )
);
  

export default function MedecineGeneraleRouter() {
  return (
    <Switch>
      <MyPrivateRouters.PrivateRouterMedecineGenerale
        path="/medecine-generale"
        component={LayoutMedicineGPatientAConsulter}
      />

      <MyPrivateRouters.PrivateRouterMedecineGenerale
        path="/medecine-generale-consultation-create/:id"
        component={LayoutCreateConsultation}
      />

      <MyPrivateRouters.PrivateRouterMedecineGenerale
        path="/medecine-generale-consultation-voir-detail/:id"
        component={LayoutViewConsultation}
      />

      <MyPrivateRouters.PrivateRouterMedecineGenerale
        path="/medecine-generale-consultation-sortie/:id"
        component={LayoutCreateConsultationSortie}
      />

      <MyPrivateRouters.PrivateRouterMedecineGenerale
        path="/medecine-generale-consultation-sortie-deces/:id"
        component={LayoutMedecineGDecesConsultationSortie}
      />

      <MyPrivateRouters.PrivateRouterMedecineGenerale
        path="/medecine-generale-consultation-sortie/:id"
        component={LayoutCreateConsultationSortie}
      />

      <MyPrivateRouters.PrivateRouterMedecineGenerale
        path="/medecine-generale-consultation-sortie-demande-hospitalisation/:id"
        component={LayoutCreateConsultationSortieDemandeHospitalisation}
      />

      <MyPrivateRouters.PrivateRouterMedecineGenerale
        path="/medecine-generale-consultation-sortie-mise-en-observation/:id"
        component={LayoutCreateConsultationSortieMiseEnObservation}
      />

      <MyPrivateRouters.PrivateRouterMedecineGenerale
        path="/medecine-generale-consultation-sortie-refere-interne/:id"
        component={LayoutCreateConsultationRefereInterne}
      />

      <MyPrivateRouters.PrivateRouterMedecineGenerale
        path="/medecine-generale-consultation-sortie-refere-externe/:id"
        component={LayoutCreateConsultationRefereExterne}
      />

      <MyPrivateRouters.PrivateRouterMedecineGenerale
        path="/medecine-generale-consultation-sortie-rdv/:id"
        component={LayoutMedecineGCreateConsultationRendezVous}
      />

      <MyPrivateRouters.PrivateRouterMedecineGenerale
        path="/medecine-generale-demande-hospitalisation"
        component={LayoutMedecineGDemandeHospitalisationListe}
      />

      <MyPrivateRouters.PrivateRouterMedecineGenerale
        path="/medecine-generale-observation-encours"
        component={MedecineGObservation}
      />

      <MyPrivateRouters.PrivateRouterMedecineGenerale
        path="/medecine-generale-observation-terminee"
        component={MedecineGObservationTerminees}
      />

      <MyPrivateRouters.PrivateRouterMedecineGenerale
        path="/medecine-generale-consultation"
        component={LayoutMedecineGConsultationListe}
      />

      <MyPrivateRouters.PrivateRouterMedecineGenerale
        path="/medecine-generale-feuille-de-soins/:id"
        component={LayoutMedicineGFeuilleDeSoins}
      />

      <MyPrivateRouters.PrivateRouterMedecineGenerale
        path="/medecine-generale-ordonnance"
        component={LayoutMedecineGOrdonnanceListe}
      />

      <MyPrivateRouters.PrivateRouterMedecineGenerale
        path="/medecine-generale-ordonnance-voir/:id"
        component={LayoutMedecineGOrdonnanceDetails}
      />

      <MyPrivateRouters.PrivateRouterMedecineGenerale
        path="/medecine-generale-rdv"
        component={LayoutMedecineGRdvListe}
      />

      <MyPrivateRouters.PrivateRouterMedecineGenerale
        path="/medecine-generale-rdv-last-consultation/:id"
        component={LayoutMGRdvLastConsultation}
      />

      <MyPrivateRouters.PrivateRouterMedecineGenerale
        path="/medecine-generale-rdv-new-consultation/:id"
        component={LayoutMGRdvNewConsultation}
      />

      <MyPrivateRouters.PrivateRouterMedecineGenerale
        path="/medecine-generale-rdv-create/:id"
        component={LayoutMedecineGRdvCreate}
      />

      <MyPrivateRouters.PrivateRouterMedecineGenerale
        path="/medecine-generale-rdv-agenda/"
        component={AgendaMG}
      />

      <MyPrivateRouters.PrivateRouterMedecineGenerale
        path="/medecine-generale-hospitalisation-encours"
        component={LayoutMedecineGHospitalisationListe}
      />

      <MyPrivateRouters.PrivateRouterMedecineGenerale
        path="/medecine-generale-hospitalisation-terminee"
        component={LayoutMedecineGHospitalisationTermineesListe}
      />

      <MyPrivateRouters.PrivateRouterMedecineGenerale
        path="/medecine-generale-hospitalisation-consultation/:id"
        component={LayoutMedecineGHospitalisationConsultationCreate}
      />

      <MyPrivateRouters.PrivateRouterMedecineGenerale
        path="/medecine-generale-hospitalisation-sortie/:id"
        component={LayoutMedecineGHospitalisationConsultationSortie}
      />

      <MyPrivateRouters.PrivateRouterMedecineGenerale
        path="/medecine-generale-referencements-externe"
        component={MedecineGRefExterne}
      />

      <MyPrivateRouters.PrivateRouterMedecineGenerale
        path="/medecine-generale-referencements-externe-fiche/:id"
        component={MedecineGRefExterneFiche}
      />

      <MyPrivateRouters.PrivateRouterMedecineGenerale
        path="/medecine-generale-referencements-interne"
        component={MedecineGRefInterne}
      />

      <MyPrivateRouters.PrivateRouterMedecineGenerale
        path="/medecine-generale-examens"
        component={MedecineGExamensList}
      />

      <MyPrivateRouters.PrivateRouterMedecineGenerale
        path="/medecine-generale-examens-detail/:id/"
        component={MedecineGExamensDetail}
      />

      <MyPrivateRouters.PrivateRouterMedecineGenerale
        path="/medecine-generale-exit-observation/:id"
        component={LayoutCreateConsultationSortie}
      />

      <MyPrivateRouters.PrivateRouterMedecineGenerale
        path="/medecine-generale-observation-consultation/:id"
        component={LayoutCreateConsultation}
      />

      <MyPrivateRouters.PrivateRouterMedecineGenerale
        path="/medecine-generale-observation-exit-demande-hospitalisation/:id"
        component={DemandeHospiObservationMedecineGeneral}
      />

      <MyPrivateRouters.PrivateRouterMedecineGenerale
        path="/medecine-generale-observation-exit-reference-externe/:id"
        component={RefExterneObservationMedecineGeneral}
      />

      <MyPrivateRouters.PrivateRouterMedecineGenerale
        path="/medecine-generale-observation-exit-reference-interne/:id"
        component={RefInterneObservationMedecineGeneral}
      />

      <MyPrivateRouters.PrivateRouterMedecineGenerale
        path="/medecine-generale-observation-exit-deces/:id"
        component={LayoutMedecineGDecesConsultationSortie}
      />

      <MyPrivateRouters.PrivateRouterMedecineGenerale
        path="/medecine-generale-observation-exit-rdv/:id"
        component={LayoutMedecineGCreateConsultationRendezVous}
      />

      <MyPrivateRouters.PrivateRouterMedecineGenerale
        path="/medecine-generale-patients-decedes"
        component={LayoutMedecineGDecesListe}
      />

      <MyPrivateRouters.PrivateRouterMedecineGenerale
        path="/medecine-generale-patients-decedes-certificat/:id"
        component={LayoutMedecineGDecesCertificat}
      />

      <MyPrivateRouters.PrivateRouterMedecineGenerale
        path="/medecine-generale-examens-resultat-voir/:id"
        component={LayoutVoirMedecineGeneralResultatsExamens}
      />

      <MyPrivateRouters.PrivateRouterMedecineGenerale
        path="/medecine-generale-rapports"
        component={RapportSpecialiteMain}
      />

      <MyPrivateRouters.PrivateRouterMedecineGenerale
        path="/medecine-generale-controle-major"
        component={LayoutMedecinGControleMajor}
      />
    </Switch>
  );
}
