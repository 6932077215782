export const USER_LOADED = 'USER_LOADED' 

export const USER_LOADING = 'USER_LOADING' 

export const AUTH_ERROR = 'AUTH_ERROR'

export const AUTH_ERROR_NON_TROUVE = 'AUTH_ERROR_NON_TROUVE'

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'

export const LOGIN_FAIL = 'LOGIN_FAIL'

export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'

export const LOGIN_LOADING = 'LOGIN_LOADING'

// export const REGISTER_FAIL = 'REGISTER_FAIL'

// export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'

export const TOKEN_REFRESH_SUCCESS = 'TOKEN_REFRESH_SUCCESS'

export const TOKEN_REFRESH_FAIL = 'TOKEN_REFRESH_FAIL'

export const TOKEN_REFRESH_LOADING = 'TOKEN_REFRESH_LOADING'

export const ERROR_VALUE_CLEAR = 'ERROR_VALUE_CLEAR'


export const USER_PASSWORD_CHANGE_SUCCESS = 'USER_PASSWORD_CHANGE_SUCCESS'

export const USER_PASSWORD_CHANGE_FAIL = 'USER_PASSWORD_CHANGE_FAIL'

export const USER_PASSWORD_CHANGE_LOADING = 'USER_PASSWORD_CHANGE_LOADING'


export const CIRCUIT_PATIENT_SUCCESS = 'CIRCUIT_PATIENT_SUCCESS'

export const CIRCUIT_PATIENT_FAILED = 'CIRCUIT_PATIENT_FAILED'

export const CIRCUIT_PATIENT_LOADING = 'CIRCUIT_PATIENT_LOADING'


export const MARQUE_IMPRIMER_FICHE_SUCCESS = 'MARQUE_IMPRIMER_FICHE_SUCCESS'

export const MARQUE_IMPRIMER_FICHE_FAILED = 'MARQUE_IMPRIMER_FICHE_FAILED'

export const MARQUE_IMPRIMER_FICHE_LOADING = 'MARQUE_IMPRIMER_FICHE_LOADING'


export const EMAIL_USER_GET_SUCCESS = 'EMAIL_USER_GET_SUCCESS'

export const EMAIL_USER_GET_FAILED = 'EMAIL_USER_GET_FAILED'

export const EMAIL_USER_GET_LOADING = 'EMAIL_USER_GET_LOADING'


export const ONCHANGE_SEARCH_INPUT = 'ONCHANGE_SEARCH_INPUT'


export const CLEAR_STAPPERS_VALUE_CONNEXION = 'CLEAR_STAPPERS_VALUE_CONNEXION'

export const SESSION_EXPIRED = 'SESSION_EXPIRED'


export const SESSION_NEW_LOADING = 'SESSION_NEW_LOADING'

export const SESSION_NEW_SUCCESS = 'SESSION_NEW_SUCCESS'

export const SESSION_NEW_FAILED = 'SESSION_NEW_FAILED'