import { CAISSE_GETS_CAISSES_OUVERTES_DATA_FAILED, CAISSE_GETS_CAISSES_OUVERTES_DATA_LOADING, CAISSE_GETS_CAISSES_OUVERTES_DATA_SUCCESS, CAISSE_POST_FERMER_CAISSE_DATA_FAILED, CAISSE_POST_FERMER_CAISSE_DATA_LOADING, CAISSE_POST_FERMER_CAISSE_DATA_SUCCESS, caisseClotureTypes } from "../../types/caisseType";
import { recuPaiementTypes } from "../../types/recuPaiementTypes";
import { AUTH_ERROR, LOGOUT_SUCCESS, TOKEN_REFRESH_FAIL } from "../../types/userTypes";
const initialState = {
    isLoading: false,
    isLoadingRefresh: false,
    recusPaiements: [],
    recuPaiement: {},
    recuPrestationAPayer:[],
    isSucces:false,

    isErrorAnnuler: false,
    isSuccesMount:false,
    isLoadingPrestation:false,

    error: "",
    isError: false,
    isError500: false,
    isError403: false,
    isError400: false,

    isError400Cloture: false,

    montantCaisse: 0,

    clotureSuccess: false,
    clotureIsLoading: false,

    clotureError: "",
    historiquesClotures: [],
    validerHistoriqueSuccess: false,
    validerHistoriqueLoader: false,
    validerHistoriqueError: null,
    isLoadingCloture:false,
    isSuccesCloture:false,
    historiquesCloturesValidees: [],

    validerHistoriqueLoaderPost:false,
    validerHistoriqueSuccessPost:false,
    cloturesBilan: [],

    isLoadingStatsData:false,
    isSuccessStatsData:false,
    caissesStatsDataGet: [],

    isLoadingFermerCaisse:false,
    isSuccessFermerCaisse:false,
    FermerCaissePost: {},
    isErreurStatsData:false,
    erreurStatsData: null,

    isLoadingStatut: false,
    statutRecuPaiement:{},
    isSuccesStatut: false,
    isSuccesStatut203: false,
    isErrorStatut:false,
};

//eslint-disable-next-line
export default function (state = initialState, action){
    switch (action.type) {
        case recuPaiementTypes.CAISSE_RECU_POST_LOADING:
            return {
                ...state,
                isLoading: true,
                isError:false,
                error: ""
            };
        case recuPaiementTypes.CAISSE_RECU_POST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                recuPaiement: action.payload,
                isSucces: true,
                isError:false
            };
        case recuPaiementTypes.CAISSE_RECU_POST_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                isSucces: false,
                isError:true
            };
        case recuPaiementTypes.CAISSE_RECU_POST_FAILED_400:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                isSucces: false,
                isError400:true
            };

        
        case recuPaiementTypes.CAISSE_RECU_GETS_LOADING:
            return {
                ...state,
                isLoading: true,
                isError:false,
                error: ""
            };
        case recuPaiementTypes.CAISSE_RECU_GETS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                recusPaiements: action.payload,
                isSuccesMount: true,
                isError:false
            };
        case recuPaiementTypes.CAISSE_RECU_GETS_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                isSuccesMount: false,
                isError:true
            };

        
        case recuPaiementTypes.CAISSE_RECU_GETS_REFRESH_LOADING:
            return {
                ...state,
                isLoadingRefresh: true,
                isError:false,
                error: ""
            };
        case recuPaiementTypes.CAISSE_RECU_GETS_REFRESH_SUCCESS:
            return {
                ...state,
                isLoadingRefresh: false,
                recusPaiements: action.payload,
                isSuccesMount: true,
                isError:false
            };
        case recuPaiementTypes.CAISSE_RECU_GETS_REFRESH_FAILED:
            return {
                ...state,
                isLoadingRefresh: false,
                error: action.payload,
                isSuccesMount: false,
                isError:true
            };
        
        case recuPaiementTypes.CAISSE_RECU_GET_LOADING:
            return {
                ...state,
                isLoading: true,
                recuPaiement:{},
                isError:false,
                error: ""
            };
        case recuPaiementTypes.CAISSE_RECU_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                recuPaiement: action.payload,
                isSuccesMount: true,
                isError:false
            };
        case recuPaiementTypes.CAISSE_RECU_GET_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                recuPaiement:{},
                isSuccesMount: false,
                isError:true
            };
        
        case recuPaiementTypes.CAISSE_RECU_GET_PRESTATION_LOADING:
            return {
                ...state,
                isLoadingPrestation: true,
                recuPrestationAPayer:[],
                isError:false,
                error: ""
            };
        case recuPaiementTypes.CAISSE_RECU_GET_PRESTATION_SUCCESS:
            return {
                ...state,
                isLoadingPrestation: false,
                recuPrestationAPayer: action.payload,
                isSuccesMount: true,
                isError:false
            };
        case recuPaiementTypes.CAISSE_RECU_GET_PRESTATION_FAILED:
            return {
                ...state,
                isLoadingPrestation: false,
                error: action.payload,
                recuPrestationAPayer:[],
                isSuccesMount: false,
                isError:true
            };
        
        case recuPaiementTypes.CAISSE_RECU_ANNULER_LOADING:
            return {
                ...state,
                isLoading:true,
                isSucces:false,
                isErrorAnnuler:false,
                error: ""
            };
        case recuPaiementTypes.CAISSE_RECU_ANNULER_SUCCESS:
            return {
                ...state,
                isLoading:false,
                isSucces:true,
                recusPaiements: state.recusPaiements.filter(data => data.id !== action.payload),
                isErrorAnnuler:false
            };
        case recuPaiementTypes.CAISSE_RECU_ANNULER_IMPOSSIBLE_SUCCESS:
            return {
                ...state,
                isLoading:false,
                isSucces:false,
                error: action.payload,
                isErrorAnnuler:true
            };
        case recuPaiementTypes.CAISSE_RECU_ANNULER_FAILED:
            return {
                ...state,
                isSucces:false,
                error: action.payload,
                isLoading:false,
                isErrorAnnuler:true
            };

        
        case recuPaiementTypes.CAISSE_RECU_SET_SUCCESS:
            return {
                ...state,
                isSucces: false,
                isLoadingRefresh: false,
                isError:false,
                isErrorAnnuler: false,
                error: "",
                montantCaisse: 0,
                clotureSuccess: false,
                clotureIsLoading: false,
                clotureError: "",

                isError500: false,
                isError403: false,
                isError400: false,

                isLoadingFermerCaisse:false,
                isSuccessFermerCaisse:false,
                FermerCaissePost: {},
            }
        
        case caisseClotureTypes.GET_CAISSE_MONTANT_REQUEST:
            return {
                ...state,
                isLoadingCloture:true,
                isSuccesCloture:false,
                isError400Cloture: false,
                error: ""
            }
        case caisseClotureTypes.GET_CAISSE_MONTANT_SUCCESS:
            return {
                ...state,
                isLoadingCloture:false,
                isSuccesCloture:false,
                isError400Cloture: false,
                error: "",
                montantCaisse: action.payload,
            }
        case caisseClotureTypes.GET_CAISSE_MONTANT_FAIL:
            return {
                ...state,
                isLoadingCloture:false,
                isSuccesCloture:false,
                isError400Cloture: false,
                error: action.payload
            }
        case caisseClotureTypes.GET_CAISSE_MONTANT_FAIL_400:
            return {
                ...state,
                isLoadingCloture:false,
                isSuccesCloture:false,
                isError400Cloture: true,
                error: "",
                montantCaisse: action.payload,
            }
        
        case caisseClotureTypes.POST_CAISSE_CLOTURE_REQUEST:
            return {
                ...state,
                clotureIsLoading:true,
                clotureSuccess:false,
                clotureError: "",
                isError: false,
                isError500: false,
                isError403: false,
            }
        case caisseClotureTypes.POST_CAISSE_CLOTURE_SUCCESS:
            return {
                ...state,
                clotureIsLoading:false,
                clotureSuccess:true,
                clotureError: "",
                isError: false,
                isError500: false,
                isError403: false,
            }
        case caisseClotureTypes.POST_CAISSE_CLOTURE_FAIL:
            return {
                ...state,
                clotureIsLoading:false,
                clotureSuccess:false,
                isError: true,
                isError500: false,
                isError403: false,
                clotureError: action.payload,
            }
        case caisseClotureTypes.POST_CAISSE_CLOTURE_FAIL_403:
            return {
                ...state,
                clotureIsLoading:false,
                clotureSuccess:false,
                isError: false,
                isError500: false,
                isError403: true,
                clotureError: action.payload,
            }
        case caisseClotureTypes.POST_CAISSE_CLOTURE_FAIL_500:
            return {
                ...state,
                clotureIsLoading:false,
                clotureSuccess:false,
                isError: false,
                isError500: true,
                isError403: false,
                clotureError: action.payload,
            }
        
        case caisseClotureTypes.GET_HISTORIQUE_CLOTURE_REQUEST:
            return {
                ...state,
                isLoading:true,
                isSucces:false,
                error: ""
            }
        case caisseClotureTypes.GET_HISTORIQUE_CLOTURE_SUCCESS:
            return {
                ...state,
                isLoading:false,
                isSucces:false,
                error: "",
                historiquesClotures: action.payload,
            }
        case caisseClotureTypes.GET_HISTORIQUE_CLOTURE_FAIL:
            return {
                ...state,
                isLoading:false,
                isSucces:false,
                isError: true,
                isError500: false,
                isError403: false,
                error: action.payload,
                historiquesClotures: []
            }
        case caisseClotureTypes.GET_HISTORIQUE_CLOTURE_FAIL_403:
            return {
                ...state,
                isLoading:false,
                isSucces:false,
                isError: false,
                isError500: false,
                isError403: true, 
                error: action.payload,
                historiquesClotures: []
            }
        case caisseClotureTypes.GET_HISTORIQUE_CLOTURE_FAIL_500:
            return {
                ...state,
                isLoading:false,
                isSucces:false,
                isError: false,
                isError500: true,
                isError403: false,
                error: action.payload,
                historiquesClotures: []
            }

        case caisseClotureTypes.VALIDER_HISTORIQUE_CLOTURE_REQUEST:
            return {
                ...state,
                validerHistoriqueLoaderPost:true,
                validerHistoriqueSuccessPost:false,
                validerHistoriqueError: null,
            }
        case caisseClotureTypes.VALIDER_HISTORIQUE_CLOTURE_SUCCESS:
            return {
                ...state,
                historiquesClotures: state.historiquesClotures.filter(data => data.id !== action.payload),
                validerHistoriqueLoaderPost:false,
                validerHistoriqueSuccessPost:true,
                validerHistoriqueError: null,
            }
        case caisseClotureTypes.VALIDER_HISTORIQUE_CLOTURE_FAIL:
            return {
                ...state,
                validerHistoriqueLoaderPost:false,
                validerHistoriqueSuccessPost:false,
                validerHistoriqueError: true,
            }
        case caisseClotureTypes.GET_HISTORIQUE_VALIDEE_CLOTURE_REQUEST:
            return {
                ...state,
                isLoading:true,
                isSucces:false,
                error: ""
            }
        case caisseClotureTypes.GET_HISTORIQUE_VALIDEE_CLOTURE_SUCCESS:
            return {
                ...state,
                isLoading:false,
                isSucces:false,
                error: "",
                historiquesCloturesValidees: action.payload,
            }
        case caisseClotureTypes.GET_HISTORIQUE_VALIDEE_CLOTURE_FAIL:
            return {
                ...state,
                isLoading:false,
                isSucces:false,
                isError: true,
                isError500: false,
                isError403: false,
                error: action.payload,
                historiquesCloturesValidees: []
            }
        case caisseClotureTypes.GET_HISTORIQUE_VALIDEE_CLOTURE_FAIL_403:
            return {
                ...state,
                isLoading:false,
                isSucces:false,
                isError: false,
                isError500: false,
                isError403: true, 
                error: action.payload,
                historiquesCloturesValidees: []
            }
        case caisseClotureTypes.GET_HISTORIQUE_VALIDEE_CLOTURE_FAIL_500:
            return {
                ...state,
                isLoading:false,
                isSucces:false,
                isError: false,
                isError500: true,
                isError403: false,
                error: action.payload,
                historiquesCloturesValidees: []
            }

        case caisseClotureTypes.CLOSE_HISTORIQUE_VALIDATION_POPUP:
            return {
                ...state,
                validerHistoriqueLoader:false,
                validerHistoriqueSuccess:false,
                validerHistoriqueError: false,

                validerHistoriqueLoaderPost:false,
                validerHistoriqueSuccessPost:false,
            }
        case caisseClotureTypes.CLOSE_CLOTURE_POPUP:
            return {
                ...state,
                clotureIsLoading:false,
                clotureSuccess:false,
                clotureError: "",

                isError: false,
                isError500: false,
                isError403: false,
                isError400: false,
                error: "",

                isError400Cloture: false,

                validerHistoriqueLoaderPost:false,
                validerHistoriqueSuccessPost:false,

                isLoadingFermerCaisse:false,
                isSuccessFermerCaisse:false,
                FermerCaissePost: {},
                isErreurStatsData:false,
                erreurStatsData: null,
                isSuccesStatut: false,
                isSuccesStatut203: false,
                statutRecuPaiement:{},
                isErrorStatut: false
            }
        case caisseClotureTypes.GET_CLOTURE_BILAN_REQUEST:
            return {
                ...state,
                isLoading:true,
                isSucces:false,
                error: ""
            }
        case caisseClotureTypes.GET_CLOTURE_BILAN_SUCCESS:
            return {
                ...state,
                isLoading:false,
                isSucces:false,
                error: "",
                cloturesBilan: action.payload,
            }
        case caisseClotureTypes.GET_CLOTURE_BILAN_FAIL:
            return {
                ...state,
                isLoading:false,
                isSucces:false,
                error: action.payload
            }

        case CAISSE_GETS_CAISSES_OUVERTES_DATA_LOADING:
            return {
                ...state,
                isLoadingStatsData:true,
                isSuccessStatsData:false,
            }
        case CAISSE_GETS_CAISSES_OUVERTES_DATA_SUCCESS:
            return {
                ...state,
                isLoadingStatsData:false,
                isSuccessStatsData:true,
                caissesStatsDataGet: action.payload,
            }
        case CAISSE_GETS_CAISSES_OUVERTES_DATA_FAILED:
            return {
                ...state,
                isLoadingStatsData:false,
                isSuccessStatsData:false,
                caissesStatsDataGet: [],
            }

        
        case CAISSE_POST_FERMER_CAISSE_DATA_LOADING:
            return {
                ...state,
                isLoadingFermerCaisse:true,
                isSuccessFermerCaisse:false,
                isErreurStatsData:false,
                erreurStatsData:null,
            }
        case CAISSE_POST_FERMER_CAISSE_DATA_SUCCESS:
            return {
                ...state,
                isLoadingFermerCaisse:false,
                isSuccessFermerCaisse:true,
                FermerCaissePost: action.payload,
                isErreurStatsData:false,
                erreurStatsData:null,
            }
        case CAISSE_POST_FERMER_CAISSE_DATA_FAILED:
            return {
                ...state,
                isLoadingFermerCaisse:false,
                isSuccessFermerCaisse:false,
                FermerCaissePost: {},
                isErreurStatsData:true,
                erreurStatsData: action.payload,
            }
        
        
        case recuPaiementTypes.CAISSE_RECU_GET_STATUT_RECU_LOADING:
            return {
                ...state,
                isLoadingStatut: true,
                statutRecuPaiement:{},
                isErrorStatut:false,
                isSuccesStatut: false,
                isSuccesStatut203: false,
                error: ""
            };
        case recuPaiementTypes.CAISSE_RECU_GET_STATUT_RECU_SUCCESS:
            return {
                ...state,
                isLoadingStatut: false,
                statutRecuPaiement: action.payload,
                isSuccesStatut: true,
                isErrorStatut:false
            };
        case recuPaiementTypes.CAISSE_RECU_GET_STATUT_RECU_203_SUCCESS:
            return {
                ...state,
                isLoadingStatut: false,
                statutRecuPaiement: action.payload,
                isSuccesStatut203: true,
                isErrorStatut:false
            };
        case recuPaiementTypes.CAISSE_RECU_GET_STATUT_RECU_FAILED:
            return {
                ...state,
                isLoadingStatut: false,
                error: action.payload,
                statutRecuPaiement:{},
                isSuccesStatut: false,
                isSuccesStatut203: false,
                isErrorStatut:true
            };
        
        case AUTH_ERROR:
        case LOGOUT_SUCCESS:
        case TOKEN_REFRESH_FAIL:
            localStorage.removeItem('token_access')
            localStorage.removeItem('token_refresh')
            return {
                ...state,
                isLoading: false,
                isLoadingRefresh: false,
                recusPaiements: [],
                recuPaiement: {},
                recuPrestationAPayer:[],
                isSucces:false,
                isError:false,
                isErrorAnnuler: false,
                isSuccesMount:false,
                isLoadingPrestation:false,
                error: "",
                montantCaisse: 0,
                clotureSuccess: false,
                clotureIsLoading: false,
                clotureError: "",
                historiquesClotures: [],
                validerHistoriqueSuccess: false,
                validerHistoriqueLoader: false,
                validerHistoriqueError: null,
                isLoadingCloture:false,
                isSuccesCloture:false,
                isError400Cloture: false,

                isLoadingStatut: false,
                statutRecuPaiement:{},
                isSuccesStatut: false,
                isSuccesStatut203: false,
                isErrorStatut:false,

                isError500: false,
                isError403: false,
                isError400: false,

                validerHistoriqueLoaderPost:false,
                validerHistoriqueSuccessPost:false,

                isLoadingStatsData:false,
                isSuccessStatsData:false,
                caissesStatsDataGet: [],
            }
        default:
            return state;
    }
}
