import { Image } from 'primereact/image';
import no_internet from './no-internet.png';

export default function NoInternet(){
    
    return(
        <div className="text-center" style={{marginTop:"5%"}}>
            <div className="">
                <h1 className="fw-border" style={{fontSize:"5rem"}} > Ooopss ! </h1>
                <p className="" style={{fontSize:"3rem"}}>Un soucis avec votre connexion internet. </p>
                <h6 className="mt-4 text-danger fw-bolder" style={{fontSize:"2rem"}}> Vérifier que vous êtes bien connecté à internet. </h6>
            </div>
            <Image src={no_internet} alt="Image" width="auto" className="img-fluid my-0" />
        </div>
    )
}