// import { parametreTypes } from "../../types/parametreTypes";
import { statistiqueTypes } from "../../types/statistiqueTypes";
import { AUTH_ERROR, LOGOUT_SUCCESS, TOKEN_REFRESH_FAIL } from "../../types/userTypes";
const initialState = {
    isLoading: false,
    data: [],
    error: "",
};


export const statFrequentationPatientsReducer = (state = initialState, action) => {
    switch (action.type) {
        case statistiqueTypes.STAT_GET_FREQUENTATION_LOADING:
            return {
                ...state,
                isLoading: true,
            };
        case statistiqueTypes.STAT_GET_FREQUENTATION_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload,
            };


        case statistiqueTypes.STAT_GET_FREQUENTATION_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };
        
        
        case AUTH_ERROR:
        case LOGOUT_SUCCESS:
        case TOKEN_REFRESH_FAIL:
            localStorage.removeItem('token_access')
            localStorage.removeItem('token_refresh')
            return {
                ...state,
                isLoading: false,
                data: [],
                error: ""
            }

        default:
            return state;
    }
};
