import React, { lazy } from "react"
import * as MyPrivateRouters from "../routes/PrivateRouter";
import { Switch } from 'react-router-dom';

const LayoutInfirmeriePatientAConsulterListe = lazy(() =>
    import(
      "../../components/infirmerie/patientAconsulter/Liste/LayoutInfirmeriePatientAConsulterListe"
    )
);
const LayoutInfirmeriePatientAConsulterCreate = lazy(() =>
import(
    "../../components/infirmerie/patientAconsulter/Create/LayoutInfirmeriePatientAConsulterCreate"
)
);

const LayoutInfirmeriePatientDejaConsulterListe = lazy(() =>
import(
    "../../components/infirmerie/patientDejaConsulter/Liste/LayoutInfirmeriePatientDejaConsulterListe"
)
);
const LayoutInfirmeriePatientDejaConsulteDetails = lazy(() =>
import(
    "../../components/infirmerie/patientDejaConsulter/Voir/LayoutInfirmeriePatientDejaConsulteDetails"
)
);
const LayoutInfirmeriePatientDejaConsulterUpdate = lazy(() =>
import(
    "../../components/infirmerie/patientDejaConsulter/Update/LayoutInfirmeriePatientDejaConsulterUpdate"
)
);

const LayoutInfirmerieRdvListe = lazy(() =>
import("../../components/infirmerie/rdv/Liste/LayoutInfirmerieRdvListe")
);
const LayoutInfirmerieRdvCreate = lazy(() =>
import("../../components/infirmerie/rdv/Create/LayoutInfirmerieRdvCreate")
);

const LayoutInfirmeriePatientReorientesCreate = lazy(() =>
import(
    "../../components/infirmerie/patientReorientes/Form/LayoutInfirmeriePatientReorientesCreate"
)
);

const LayoutInfirmeriePatientAConsulterControle = lazy(() =>
import(
    "../../components/infirmerie/patientDejaConsulter/Controle/LayoutInfirmeriePatientAConsulterControle"
)
);
  

export default function InfirmerieRouter() {
  return (
    <Switch>
        <MyPrivateRouters.PrivateRouterInfirmerie
        path="/infirmerie"
        component={LayoutInfirmeriePatientAConsulterListe}
        />

        <MyPrivateRouters.PrivateRouterInfirmerie
        path="/infirmerie-create/:id"
        component={LayoutInfirmeriePatientAConsulterCreate}
        />

        <MyPrivateRouters.PrivateRouterInfirmerie
        path="/infirmerie-detail/:id"
        component={LayoutInfirmeriePatientDejaConsulteDetails}
        />

        <MyPrivateRouters.PrivateRouterInfirmerie
        path="/infirmerie-update/:id"
        component={LayoutInfirmeriePatientDejaConsulterUpdate}
        />

        <MyPrivateRouters.PrivateRouterInfirmerie
        path="/infirmerie-patient-deja-consulte"
        component={LayoutInfirmeriePatientDejaConsulterListe}
        />

        <MyPrivateRouters.PrivateRouterInfirmerie
        path="/infirmerie-rdv"
        component={LayoutInfirmerieRdvListe}
        />

        <MyPrivateRouters.PrivateRouterInfirmerie
        path="/infirmerie-rdv-create"
        component={LayoutInfirmerieRdvCreate}
        />

        <MyPrivateRouters.PrivateRouterInfirmerie
        path="/infirmerie-reorientation/:id"
        component={LayoutInfirmeriePatientReorientesCreate}
        />

        <MyPrivateRouters.PrivateRouterInfirmerie
        path="/infirmerie-controle/:id"
        component={LayoutInfirmeriePatientAConsulterControle}
        />
    </Switch>
  )
}
