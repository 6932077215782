import React, { lazy } from "react"
import * as MyPrivateRouters from "../routes/PrivateRouter";
import { Switch } from 'react-router-dom';


const LayoutConsultationPostnataleListe = lazy(() =>
    import(
      "../../components/cpon/patientAConsulter/Liste/LayoutConsultationPostnataleListe"
    )
);
const LayoutConsultationPostnataleCreate = lazy(() =>
import(
    "../../components/cpon/patientAConsulter/Create/LayoutConsultationPostnataleCreate"
)
);

const LayoutCPONDejaConsulterListe = lazy(() =>
import(
    "../../components/cpon/consultation/Liste/LayoutCPONDejaConsulterListe"
)
);

const LayoutConsultationPostnataleDetails = lazy(() =>
import(
    "../../components/cpon/consultation/Create/LayoutMaterniteConsultationPostnataleCreate"
)
);

const LayoutCPONSortieDemandeHospitalisationCreate = lazy(() =>
import(
    "../../components/cpon/patientAConsulterIssuSortie/DemandeHospitalisation/LayoutCPONSortieDemandeHospitalisationCreate"
)
);

const LayoutCPONSortieRefereExterneCreate = lazy(() =>
import(
    "../../components/cpon/patientAConsulterIssuSortie/RefereExterne/LayoutCPONSortieRefereExterneCreate"
)
);

const LayoutCPONSortieRefereInterneCreate = lazy(() =>
import(
    "../../components/cpon/patientAConsulterIssuSortie/RefereInterne/LayoutCPONSortieRefereInterneCreate"
)
);

const LayoutCPONSortieRdvCreate = lazy(() =>
import(
    "../../components/cpon/patientAConsulterIssuSortie/RendezVous/LayoutCPONSortieRdvCreate"
)
);

const LayoutCPONSortieCreate = lazy(() =>
import(
    "../../components/cpon/patientAConsulterIssuSortie/LayoutCPONSortieCreate"
)
);




const LayoutCPONOrdonnanceListe = lazy(() =>
import(
    "../../components/cpon/ordonnances/Liste/LayoutCPONOrdonnanceListe"
)
);

const LayoutCPONOrdonnanceFormView = lazy(() =>
import(
    "../../components/cpon/ordonnances/Form/LayoutCPONOrdonnanceFormView"
)
);



const LayoutCPONExamensList = lazy(() =>
import("../../components/cpon/examens/list")
);

const LayoutCPONExamensDetail = lazy(() =>
import("../../components/cpon/examens/detail")
);

const LayoutCPONVoirResultatsExamens = lazy(() =>
import(
    "../../components/cpon/examens/resultatsExamensVoir/LayoutCPONVoirResultatsExamens"
)
);

const LayoutCPONDecesListe = lazy(() =>
import("../../components/cpon/Deces/Liste/LayoutCPONDecesListe")
);
const LayoutCPONDecesCertificat = lazy(() =>
import(
    "../../components/cpon/Deces/Certificat/LayoutCPONDecesCertificat"
)
);

const LayoutCPONListeRefInterne = lazy(() =>
import(
    "../../components/cpon/refInterne/LayoutCPONListeRefInterne"
)
);

const LayoutCPONListeRefExterne = lazy(() =>
import(
    "../../components/cpon/refExterne/LayoutCPONListeRefExterne"
)
);

const LayoutCPONRdvListe = lazy(() =>
import("../../components/cpon/rendezVous/Liste/LayoutCPONRdvListe")
);

const LayoutCPONAgenda = lazy(() =>
import("../../components/cpon/rendezVous/agendaDetail/LayoutCPONAgenda")
);

const LayoutCPONRdvLastConsultation = lazy(() =>
import(
    "../../components/cpon/rendezVous/LastConsultation/LayoutCPONRdvLastConsultation"
)
);

const LayoutCPONRapports = lazy(() =>
import(
    "../../components/cpon/rapports/LayoutCPONRapports"
)
);

const LayoutCPONControleMajor = lazy(() => 
import("../../components/cpon/controleMajor/LayoutCponControleMajor")
)

export default function CponRouter(){
  return (
    <Switch>
        <MyPrivateRouters.PrivateRouterCPON
        path="/cpon"
        component={LayoutConsultationPostnataleListe}
        />

        <MyPrivateRouters.PrivateRouterCPON
        path="/cpon-consultation-create/:id"
        component={LayoutConsultationPostnataleCreate}
        />

        <MyPrivateRouters.PrivateRouterCPON
        path="/cpon-consultation-sortie/:id"
        component={LayoutCPONSortieCreate}
        />

        <MyPrivateRouters.PrivateRouterCPON
        path="/cpon-consultation-sortie-reference-interne/:id"
        component={LayoutCPONSortieRefereInterneCreate}
        />

        <MyPrivateRouters.PrivateRouterCPON
        path="/cpon-consultation-sortie-reference-externe/:id"
        component={LayoutCPONSortieRefereExterneCreate}
        />

        <MyPrivateRouters.PrivateRouterCPON
        path="/cpon-consultation-sortie-demande-hospitalisation/:id"
        component={LayoutCPONSortieDemandeHospitalisationCreate}
        />

        <MyPrivateRouters.PrivateRouterCPON
        path="/cpon-consultation-sortie-rdv/:id"
        component={LayoutCPONSortieRdvCreate}
        />

        <MyPrivateRouters.PrivateRouterCPON
        path="/cpon-consultation"
        component={LayoutCPONDejaConsulterListe}
        />

        <MyPrivateRouters.PrivateRouterCPON
        path="/cpon-consultation-voir-detail/:id"
        component={LayoutConsultationPostnataleDetails}
        />

        <MyPrivateRouters.PrivateRouterCPON
        path="/cpon-ordonnance"
        component={LayoutCPONOrdonnanceListe}
        />

        <MyPrivateRouters.PrivateRouterCPON
        path="/cpon-ordonnance-voir/:id"
        component={LayoutCPONOrdonnanceFormView}
        />

        <MyPrivateRouters.PrivateRouterCPON
        path="/cpon-demande-examen"
        component={LayoutCPONExamensList}
        />

        <MyPrivateRouters.PrivateRouterCPON
        path="/cpon-demande-examen-detail/:id/"
        component={LayoutCPONExamensDetail}
        />

        <MyPrivateRouters.PrivateRouterCPON
        path="/cpon-demande-examen-resultats/:id/"
        component={LayoutCPONVoirResultatsExamens}
        />

        <MyPrivateRouters.PrivateRouterCPON
        path="/cpon-patients-decedes"
        component={LayoutCPONDecesListe}
        />

        <MyPrivateRouters.PrivateRouterCPON
        path="/cpon-patients-decedes-certificat/:id"
        component={LayoutCPONDecesCertificat}
        />

        <MyPrivateRouters.PrivateRouterCPON
        path="/cpon-referencements-interne"
        component={LayoutCPONListeRefInterne}
        />

        <MyPrivateRouters.PrivateRouterCPON
        path="/cpon-referencements-externe"
        component={LayoutCPONListeRefExterne}
        />

        <MyPrivateRouters.PrivateRouterCPON
        path="/cpon-rdv"
        component={LayoutCPONRdvListe}
        />

        <MyPrivateRouters.PrivateRouterCPON
        path="/cpon-rdv-agenda/"
        component={LayoutCPONAgenda}
        />

        <MyPrivateRouters.PrivateRouterCPON
        path="/cpon-rdv-last-consultation/:id"
        component={LayoutCPONRdvLastConsultation}
        />

        <MyPrivateRouters.PrivateRouterCPON
        path="/cpon-rapports"
        component={LayoutCPONRapports}
        />

        <MyPrivateRouters.PrivateRouterCPON
        path="/cpon-controle-major"
        component={LayoutCPONControleMajor}
        />
    </Switch>
  )
}
