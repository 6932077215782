export const CAISSE_GETS_DATA_LOADING = "CAISSE_GETS_DATA_LOADING"

export const CAISSE_GETS_DATA_SUCCESS = "CAISSE_GETS_DATA_SUCCESS"

export const CAISSE_GETS_DATA_FAILED = 'CAISSE_GETS_DATA_FAILED'


export const CAISSE_GETS_CAISSES_OUVERTES_DATA_LOADING = "CAISSE_GETS_CAISSES_OUVERTES_DATA_LOADING"

export const CAISSE_GETS_CAISSES_OUVERTES_DATA_SUCCESS = "CAISSE_GETS_CAISSES_OUVERTES_DATA_SUCCESS"

export const CAISSE_GETS_CAISSES_OUVERTES_DATA_FAILED = 'CAISSE_GETS_CAISSES_OUVERTES_DATA_FAILED'


export const CAISSE_POST_FERMER_CAISSE_DATA_LOADING = "CAISSE_POST_FERMER_CAISSE_DATA_LOADING"

export const CAISSE_POST_FERMER_CAISSE_DATA_SUCCESS = "CAISSE_POST_FERMER_CAISSE_DATA_SUCCESS"

export const CAISSE_POST_FERMER_CAISSE_DATA_FAILED = 'CAISSE_POST_FERMER_CAISSE_DATA_FAILED'

// export const PATIENTS_PARENT_GETS_DATA_LOADING = "PATIENTS_PARENT_GETS_DATA_LOADING"

// export const PATIENTS_PARENT_GETS_DATA_SUCCESS = "PATIENTS_PARENT_GETS_DATA_SUCCESS"

// export const PATIENTS_PARENT_GETS_DATA_FAILED = 'PATIENTS_PARENT_GETS_DATA_FAILED'

// export const PATIENT_SET_SUCCESS = 'PATIENT_SET_SUCCESS'

// export const PATIENT_CARTE_GETS_DATA_SUCCESS = 'PATIENT_CARTE_GETS_DATA_SUCCESS'

// export const PATIENT_CARTE_GETS_DATA_FAILED = 'PATIENT_CARTE_GETS_DATA_FAILED'

// export const PATIENT_CARTE_GETS_DATA_LOADING = 'PATIENT_CARTE_GETS_DATA_LOADING'

//export const GET_ALL_PATIENT_REQUEST = 'GET_ALL_PATIENT_REQUEST'
//export const GET_ALL_PATIENT_SUCCESS = 'GET_ALL_PATIENT_SUCCESS'
//export const GET_ALL_PATIENT_FAIL = 'GET_ALL_PATIENT_FAIL'

export const caisseparametreTypes = {
    CAISSE_LIST_REQUEST: "CAISSE_LIST_REQUEST",
    CAISSE_LIST_SUCCESS: "CAISSE_LIST_SUCCESS",
    CAISSE_LIST_FAIL: "CAISSE_LIST_FAIL",
};

export const caisseClotureTypes = {
    GET_CAISSE_MONTANT_REQUEST: "GET_CAISSE_MONTANT_REQUEST",
    GET_CAISSE_MONTANT_SUCCESS: "GET_CAISSE_MONTANT_SUCCESS",
    GET_CAISSE_MONTANT_FAIL: "GET_CAISSE_MONTANT_FAIL",
    GET_CAISSE_MONTANT_FAIL_400: "GET_CAISSE_MONTANT_FAIL_400",

    POST_CAISSE_CLOTURE_REQUEST: "POST_CAISSE_CLOTURE_REQUEST",
    POST_CAISSE_CLOTURE_SUCCESS: "POST_CAISSE_CLOTURE_SUCCESS",
    POST_CAISSE_CLOTURE_FAIL: "POST_CAISSE_CLOTURE_FAIL",
    POST_CAISSE_CLOTURE_FAIL_403: "POST_CAISSE_CLOTURE_FAIL_403",
    POST_CAISSE_CLOTURE_FAIL_500: "POST_CAISSE_CLOTURE_FAIL_500",

    GET_HISTORIQUE_CLOTURE_REQUEST: "GET_HISTORIQUE_CLOTURE_REQUEST",
    GET_HISTORIQUE_CLOTURE_SUCCESS: "GET_HISTORIQUE_CLOTURE_SUCCESS",
    GET_HISTORIQUE_CLOTURE_FAIL: "GET_HISTORIQUE_CLOTURE_FAIL",
    GET_HISTORIQUE_CLOTURE_FAIL_403: "GET_HISTORIQUE_CLOTURE_FAIL_403",
    GET_HISTORIQUE_CLOTURE_FAIL_500: "GET_HISTORIQUE_CLOTURE_FAIL_500",

    VALIDER_HISTORIQUE_CLOTURE_REQUEST: "VALIDER_HISTORIQUE_CLOTURE_REQUEST",
    VALIDER_HISTORIQUE_CLOTURE_SUCCESS: "VALIDER_HISTORIQUE_CLOTURE_SUCCESS",
    VALIDER_HISTORIQUE_CLOTURE_FAIL: "VALIDER_HISTORIQUE_CLOTURE_FAIL",

    GET_HISTORIQUE_VALIDEE_CLOTURE_REQUEST: "GET_HISTORIQUE_VALIDEE_CLOTURE_REQUEST",
    GET_HISTORIQUE_VALIDEE_CLOTURE_SUCCESS: "GET_HISTORIQUE_VALIDEE_CLOTURE_SUCCESS",
    GET_HISTORIQUE_VALIDEE_CLOTURE_FAIL: "GET_HISTORIQUE_VALIDEE_CLOTURE_FAIL",
    GET_HISTORIQUE_VALIDEE_CLOTURE_FAIL_403: "GET_HISTORIQUE_VALIDEE_CLOTURE_FAIL_403",
    GET_HISTORIQUE_VALIDEE_CLOTURE_FAIL_500: "GET_HISTORIQUE_VALIDEE_CLOTURE_FAIL_500",

    GET_CLOTURE_BILAN_REQUEST: "GET_CLOTURE_BILAN_REQUEST",
    GET_CLOTURE_BILAN_SUCCESS: "GET_CLOTURE_BILAN_SUCCESS",
    GET_CLOTURE_BILAN_FAIL: "GET_CLOTURE_BILAN_FAIL",

    CLOSE_HISTORIQUE_VALIDATION_POPUP: "CLOSE_HISTORIQUE_VALIDATION_POPUP",
    CLOSE_CLOTURE_POPUP: "CLOSE_CLOTURE_POPUP",
}


// export const ADD_PATIENT_REQUEST = 'ADD_PATIENT_REQUEST'
// export const ADD_PATIENT_SUCCESS = 'ADD_PATIENT_SUCCESS'
// export const ADD_PATIENT_FAIL = 'ADD_PATIENT_FAIL'
// export const ADD_PATIENT_RESET = 'ADD_PATIENT_RESET'


// export const DETAIL_PATIENT_REQUEST = 'DETAIL_PATIENT_REQUEST'
// export const DETAIL_PATIENT_SUCCESS = 'DETAIL_PPATIENT_SUCCESS'
// export const DETAIL_PATIENT_FAIL = 'DETAIL_PATIENT_FAIL'