import React, { lazy } from "react"
import * as MyPrivateRouters from "../routes/PrivateRouter";
import { Switch } from 'react-router-dom';

const LayoutVaccinationPatientAVaccinerListe = lazy(() => import("../../components/vaccination/patientsAVacciner/Liste/LayoutVaccinationPatientAVaccinerListe"))

const LayoutVaccinationPatientAdd = lazy(() => import("../../components/vaccination/patientsAVacciner/AddPatient/LayoutVaccinationPatientAdd"))

const LayoutVaccinationPatientUpdate = lazy(() => import("../../components/vaccination/patientsAVacciner/updatePatient/LayoutVaccinationPatientUpdate"))

const LayoutVaccinationCreate = lazy(() => import("../../components/vaccination/patientsAVacciner/Create/LayoutVaccinationCreate"))

const LayoutVaccinationPatientVaccinesListe = lazy(() => import("../../components/vaccination/vaccinations/Liste/LayoutVaccinationPatientVaccinesListe"))

const LayoutVaccinationUpdate = lazy(() => import("../../components/vaccination/vaccinations/Update/LayoutVaccinationUpdate"))

  

export default function VaccinationRouter() {
  return (
    <Switch>
        <MyPrivateRouters.PrivateRouterVaccination
        path="/vaccination"
        component={LayoutVaccinationPatientAVaccinerListe}
        />

        <MyPrivateRouters.PrivateRouterVaccination
        path="/vaccination-patient-ajouter"
        component={LayoutVaccinationPatientAdd}
        />

        <MyPrivateRouters.PrivateRouterVaccination
        path="/vaccination-patient-update/:id"
        component={LayoutVaccinationPatientUpdate}
        />

        <MyPrivateRouters.PrivateRouterVaccination
        path="/vaccination-create-vaccin/:id"
        component={LayoutVaccinationCreate}
        />

        <MyPrivateRouters.PrivateRouterVaccination
        path="/vaccination-effectuees"
        component={LayoutVaccinationPatientVaccinesListe}
        />

        <MyPrivateRouters.PrivateRouterVaccination
        path="/vaccination-effectuees-update/:id/:id_patient"
        component={LayoutVaccinationUpdate}
        />
    </Switch>
  )
}
