export const MALADIES_GETS_LOADING = 'MALADIES_GETS_LOADING'

export const MALADIES_GETS_SUCCESS = 'MALADIES_GETS_SUCCESS'

export const MALADIES_GETS_FAILED = 'MALADIES_GETS_FAILED'


export const CATEGORIES_MALADIES_GETS_DATA_LOADING = 'CATEGORIES_MALADIES_GETS_DATA_LOADING'

export const CATEGORIES_MALADIES_GETS_DATA_SUCCESS = 'CATEGORIES_MALADIES_GETS_DATA_SUCCESS'

export const CATEGORIES_MALADIES_GETS_DATA_FAILED = 'CATEGORIES_MALADIES_GETS_DATA_FAILED'



export const MALADIE_GET_DATA_LOADING = 'MALADIE_GET_DATA_LOADING'

export const MALADIE_GET_DATA_SUCCESS = 'MALADIE_GET_DATA_SUCCESS'

export const MALADIE_GET_DATA_FAILED = 'MALADIE_GET_DATA_FAILED'

export const MALADIE_GET_DATA_FAILED_403 = "MALADIE_GET_DATA_FAILED_403"

export const MALADIE_GET_DATA_FAILED_500 = "MALADIE_GET_DATA_FAILED_500"


export const MALADIE_POST_DATA_LOADING = 'MALADIE_POST_DATA_LOADING'

export const MALADIE_POST_DATA_SUCCESS = 'MALADIE_POST_DATA_SUCCESS'

export const MALADIE_POST_DATA_FAILED = 'MALADIE_POST_DATA_FAILED'

export const MALADIE_POST_DATA_FAILED_403 = "MALADIE_POST_DATA_FAILED_403"

export const MALADIE_POST_DATA_FAILED_500 = "MALADIE_POST_DATA_FAILED_500"


export const MALADIE_PUT_DATA_LOADING = 'MALADIE_PUT_DATA_LOADING'

export const MALADIE_PUT_DATA_SUCCESS = 'MALADIE_PUT_DATA_SUCCESS'

export const MALADIE_PUT_DATA_FAILED = 'MALADIE_PUT_DATA_FAILED'

export const MALADIE_PUT_DATA_FAILED_403 = "MALADIE_PUT_DATA_FAILED_403"

export const MALADIE_PUT_DATA_FAILED_500 = "MALADIE_PUT_DATA_FAILED_500"


export const MALADIE_DELETE_DATA_LOADING = 'MALADIE_DELETE_DATA_LOADING'

export const MALADIE_DELETE_DATA_SUCCESS = 'MALADIE_DELETE_DATA_SUCCESS'

export const MALADIE_DELETE_DATA_FAILED = 'MALADIE_DELETE_DATA_FAILED'

export const MALADIE_DELETE_DATA_FAILED_403 = "MALADIE_DELETE_DATA_FAILED_403"

export const MALADIE_DELETE_DATA_FAILED_500 = "MALADIE_DELETE_DATA_FAILED_500"


export const MALADIE_GETS_DATA_LOADING = 'MALADIE_GETS_DATA_LOADING'

export const MALADIE_GETS_DATA_SUCCESS = 'MALADIE_GETS_DATA_SUCCESS'

export const MALADIE_GETS_DATA_FAILED = 'MALADIE_GETS_DATA_FAILED'

export const MALADIE_GETS_DATA_FAILED_403 = "MALADIE_GETS_DATA_FAILED_403"

export const MALADIE_GETS_DATA_FAILED_500 = "MALADIE_GETS_DATA_FAILED_500"


export const MALADIE_GETS_REFRESH_DATA_LOADING = 'MALADIE_GETS_REFRESH_DATA_LOADING'

export const MALADIE_GETS_REFRESH_DATA_SUCCESS = 'MALADIE_GETS_REFRESH_DATA_SUCCESS'

export const MALADIE_GETS_REFRESH_DATA_FAILED = 'MALADIE_GETS_REFRESH_DATA_FAILED'

export const MALADIE_GETS_REFRESH_DATA_FAILED_403 = "MALADIE_GETS_REFRESH_DATA_FAILED_403"

export const MALADIE_GETS_REFRESH_DATA_FAILED_500 = "MALADIE_GETS_REFRESH_DATA_FAILED_500"


export const MALADIE_CLEAR_STATE = "MALADIE_CLEAR_STATE"