
import { ordonnanceType } from "../../types/ordonnanceType";
import { AUTH_ERROR, LOGOUT_SUCCESS, TOKEN_REFRESH_FAIL } from "../../types/userTypes";

const initialState = {
    isLoading: false,
    data: false,
    error: "",
};

export const getOrdonnanceLunetteReducer = (state = initialState, action) => {
    switch (action.type) {
        case ordonnanceType.GET_ORDANNANCE_LUNETTE_PATIENT_LOADING:
            return {
                ...state,
                isLoading: true,
            };
        case ordonnanceType.GET_ORDANNANCE_LUNETTE_PATIENT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload,
            };
        case ordonnanceType.GET_ORDANNANCE_LUNETTE_PATIENT_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };
        case AUTH_ERROR:
        case LOGOUT_SUCCESS:
        case TOKEN_REFRESH_FAIL:
            localStorage.removeItem('token_access')
            localStorage.removeItem('token_refresh')
            return {
                ...state,
                isLoading: false,
                data: false,
                error: "",
            }
        default:
            return state;
    }
};
