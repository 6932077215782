import { controlePBF } from "../../types/controlePBFType";

const today = new Date()
const initialState = {
  professionnel: null,
  currentPage: 1,
  totalPatientsNumber: 0,
  firstPage: 0,
  totalPerPage: 20,
  totalPage: null,
  patientsList: [],
  nextPatient: null,
  payloadRapport: {},
  services: [],
  currentServiceCode: null,
  dateFin: new Date().toISOString().slice(0, 10),
  dateDebut: new Date(today.setDate(today.getDate() - 2))
    .toISOString()
    .slice(0, 10),
  trancheAge: null,
};


export const controlePBFReducers = (state = initialState, action)=>{
    switch (action.type) {
      case controlePBF.SET_CONTROLE_PBF_DATE_DEBUT:
        return {
          ...state,
          dateDebut: action.payload,
        };

      case controlePBF.SET_CONTROLE_PBF_DATE_FIN:
        return {
          ...state,
          dateFin: action.payload,
        };

      case controlePBF.SET_CONTROLE_PBF_PROFESSIONNEL:
        return {
          ...state,
          professionnel: action.payload,
        };

      case controlePBF.SET_CONTROLE_PBF_SERVICE:
        return {
          ...state,
          services: action.payload,
        };
      case controlePBF.SET_CONTROLE_PBF_PATIENTS:
        return {
          ...state,
          patientsList: action.payload,
        };
      case controlePBF.SET_CONTROLE_PBF_PATIENTS_DATA_CURRENT_SERVICE_CODE:
        return {
          ...state,
          currentServiceCode: action.payload,
        };
      case controlePBF.SET_CONTROLE_PBF_ALL_PAGE:
        return {
          ...state,
          totalPage: action.payload,
        };
      case controlePBF.SET_CONTROLE_PBF_CURRENT_PAGE:
        return {
          ...state,
          currentPage: action.payload,
        };
      case controlePBF.SET_CONTROLE_PBF_ALL_ITEMS_NUMBER:
        return {
          ...state,
          totalPatientsNumber: action.payload,
        };
      case controlePBF.SET_CONTROLE_PBF_ITEMS_PER_PAGE:
        return {
          ...state,
          totalPerPage: action.payload,
        };
      case controlePBF.SET_CONTROLE_PBF_ITEMS_FIRST_PAGE:
        return {
          ...state,
          firstPage: action.payload,
        };
      case controlePBF.SET_CONTROLE_PBF_PAYLOAD_REQUEST:
        return {
          ...state,
          payloadRapport: action.payload,
        };
      case controlePBF.SET_CONTROLE_PBF_NEXT_PATIENT:
        return {
          ...state,
          nextPatient: action.payload,
        };
      case controlePBF.SET_CONTROLE_PBF_TRANCHE_AGE:
        return {
          ...state,
          trancheAge: action.payload,
        };
      default:
        return state;
    }
}

