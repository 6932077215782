import { bilansType } from "../../types/bilansType.js";
import { AUTH_ERROR, LOGOUT_SUCCESS, TOKEN_REFRESH_FAIL } from "../../types/userTypes";


const initialState = {
    isLoading: false,
    isSuccessGet: false,
    data: [],
    error: "",
    nombreEchographie: null

};

export const getBilansReducer = (state = initialState, action) => {
    switch (action.type) {
        case bilansType.GET_BILAN_CAISSE_LOADING:
            return {
                ...state,
                isLoading: true,
            };
        case bilansType.GET_BILAN_CAISSE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload,
            };
        case bilansType.GET_BILAN_CAISSE_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.payload,
                data: [],
                isError: true,
                isError403: false,
                isError500: false,
            }
        case bilansType.GET_BILAN_CAISSE_FAILED_403:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                data:[],
                error: action.payload,
                isError: false,
                isError403: true,
                isError500: false,
            };
        case bilansType.GET_BILAN_CAISSE_FAILED_500:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                data:[],
                error: action.payload,
                isError: false,
                isError403: false,
                isError500: true,
            };
        
        case bilansType.CLEAR_BILAN_CAISSE:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: "",
                isError: false,
                isError403: false,
                isError500: false,
            };

        
        case bilansType.GET_NOMBRE_ECHO_CAISSE_LOADING:
            return {
                ...state,
                isLoading: true,
                nombreEchographie: null
            };
        case bilansType.GET_NOMBRE_ECHO_CAISSE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                nombreEchographie: action.payload,
            };
        case bilansType.GET_NOMBRE_ECHO_CAISSE_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                error: action.payload,
                nombreEchographie: null,
                isError: true,
            }
        
        
        case AUTH_ERROR:
        case LOGOUT_SUCCESS:
        case TOKEN_REFRESH_FAIL:
            localStorage.removeItem('token_access')
            localStorage.removeItem('token_refresh')
            return {
                ...state,
                isLoading: false,
                data: false,
                error: "",
            }
        default:
            return state;
    }
};
