import React, { lazy } from "react"
import * as MyPrivateRouters from "../routes/PrivateRouter";
import { Switch } from 'react-router-dom';


const LayoutDiabetologiePatientAConsulter = lazy(() =>
    import(
      "../../components/diabetologie/patientAConsulter/LayoutDiabetologiePatientAConsulter"
    )
);
const LayoutDiabetologieConsultationListe = lazy(() =>
import(
    "../../components/diabetologie/consultation/LayoutDiabetologieConsultationListe"
)
);
const LayoutDiabetologieDemandeHospitalisationListe = lazy(() =>
import(
    "../../components/diabetologie/demandeHospitalisation/LayoutDiabetologieDemandeHospitalisationListe"
)
);
const DiabetologieObservation = lazy(() =>
import("../../components/diabetologie/observation/DiabetologieObservation")
);

const DiabetologieObservationTerminees = lazy(() =>
    import("../../components/diabetologie/observationTerminees/DiabetologieObservationTerminees")
    );

const LayoutDiabetologieCreateConsultation = lazy(() =>
import(
    "../../components/diabetologie/patientAConsulterCreate/LayoutDiabetologieCreateConsultation"
)
);

const LayoutDiabetologieViewConsultation = lazy(() =>
import(
    "../../components/diabetologie/patientAConsulterDetail/LayoutDiabetologieViewConsultation"
)
);


const LayoutCreateConsultationSortieDiabetologie = lazy(() =>
import(
    "../../components/diabetologie/patientAConsulterIssuSortie/LayoutCreateConsultationSortieDiabetologie"
)
);

const LayoutCreateConsultationSortieDemandeHospitalisationDiabetologie = lazy(
() =>
    import(
    "../../components/diabetologie/patientAConsulterIssuSortie/DemandeHospitalisation/LayoutCreateConsultationSortieDemandeHospitalisationDiabetologie"
    )
);
const LayoutCreateConsultationSortieMiseEnObservationDiabetologie = lazy(() =>
import(
    "../../components/diabetologie/patientAConsulterIssuSortie/MiseEnObservation/LayoutCreateConsultationSortieMiseEnObservationDiabetologie"
)
);

const LayoutCreateConsultationRefereInterneDiabetologie = lazy(() =>
import(
    "../../components/diabetologie/patientAConsulterIssuSortie/RefereInterne/LayoutCreateConsultationRefereInterneDiabetologie"
)
);
const LayoutCreateConsultationRefereExterneDiabetologie = lazy(() =>
import(
    "../../components/diabetologie/patientAConsulterIssuSortie/RefereExterne/LayoutCreateConsultationRefereExterneDiabetologie"
)
);

const LayoutDiabetologieOrdonnanceDetails = lazy(() =>
import(
    "../../components/diabetologie/ordonnance/detail/LayoutDiabetologieOrdonnanceDetails"
)
);
const LayoutDiabetologieOrdonnanceListe = lazy(() =>
import(
    "../../components/diabetologie/ordonnance/liste/LayoutDiabetologieOrdonnanceListe"
)
);

const LayoutDiabetologieFeuilleDeSoins = lazy(() =>
import(
    "../../components/diabetologie/feuilleDeSoins/LayoutDiabetologieFeuilleDeSoins"
)
);

const LayoutDiabetologieHospitalisationListe = lazy(() =>
import(
    "../../components/diabetologie/hospitalisation/LayoutDiabetologieHospitalisationListe"
)
);

const LayoutDiabetologieHospitalisationConsultationCreate = lazy(() =>
import(
    "../../components/diabetologie/hospitalisation/consultation/LayoutDiabetologieHospitalisationConsultationCreate"
)
);
const LayoutDiabetologieHospitalisationConsultationSortie = lazy(() =>
import(
    "../../components/diabetologie/hospitalisation/issueSortie/LayoutDiabetologieHospitalisationConsultationSortie"
)
);

const DiabetologieRefExterne = lazy(() =>
import("../../components/diabetologie/refExterne/DiabetologieRefExterne")
);
const DiabetologieRefInterne = lazy(() =>
import("../../components/diabetologie/refInterne/DiabetologieRefInterne")
);
const LayoutDiabetologieDecesConsultationSortie = lazy(() =>
import(
    "../../components/diabetologie/patientAConsulterIssuSortie/IssueDeDeces/LayoutDiabetologieDecesConsultationSortie"
)
);

const DemandeHospiObservationDiabetologie = lazy(() =>
import(
    "../../components/diabetologie/patientAConsulterIssuSortie/MiseEnObservation/DemandeHospitalisation/LayoutCreateConsultationSortieDemandeHospitalisation"
)
);
const RefExterneObservationDiabetologie = lazy(() =>
import(
    "../../components/diabetologie/patientAConsulterIssuSortie/MiseEnObservation/RefereExterne/LayoutCreateConsultationRefereExterne"
)
);
const RefInterneObservationDiabetologie = lazy(() =>
import(
    "../../components/diabetologie/patientAConsulterIssuSortie/MiseEnObservation/RefereInterne/LayoutCreateConsultationRefereInterne"
)
);

const DiabetologieExamensDetail = lazy(() =>
import("../../components/diabetologie/Examens/detail")
);
const DiabetologieExamensList = lazy(() =>
import("../../components/diabetologie/Examens/list")
);

const LayoutDiabetologieDecesListe = lazy(() =>
import("../../components/diabetologie/Deces/Liste/LayoutDiabetologieDecesListe")
);
const LayoutDiabetologieDecesCertificat = lazy(() =>
import(
    "../../components/diabetologie/Deces/Certificat/LayoutDiabetologieDecesCertificat"
)
);

const AgendaDiabetologie = lazy(() =>
import("../../components/diabetologie/rendezVous/agendaDetail/AgendaDiabetologie")
);
const LayoutDiabetologieCreateConsultationRendezVous = lazy(() =>
import(
    "../../components/diabetologie/patientAConsulterIssuSortie/RendezVous/LayoutDiabetologieCreateConsultationRendezVous"
)
);
const LayoutDiabetologieRdvListe = lazy(() =>
import("../../components/diabetologie/rendezVous/Liste/LayoutDiabetologieRdvListe")
);
const LayoutDiabetoRdvLastConsultation = lazy(() =>
import("../../components/diabetologie/rendezVous/LastConsultation/LayoutDiabetoRdvLastConsultation")
);
const LayoutDiabetoRdvNewConsultation = lazy(() =>
import("../../components/diabetologie/rendezVous/NewConsultation/LayoutDiabetoRdvNewConsultation")
);
const LayoutVoirDiabetologieResultatsExamens = lazy(() =>
import(
    "../../components/diabetologie/Examens/resultatsExamensVoir/LayoutVoirDiabetologieResultatsExamens"
)
);

const RapportDiabetologie = lazy(() => import("../../components/diabetologie/rapports/RapportDiabetologie"));

const LayoutDiabetologieControleMajor = lazy(() => 
import("../../components/diabetologie/controleMajor/LayoutDiabetologieControleMajor")
)
  

export default function DiabetologieRouter() {
  return (
    <Switch>
        <MyPrivateRouters.PrivateRouterDiabetologie
        path="/diabetologie"
        component={LayoutDiabetologiePatientAConsulter}
        />

        <MyPrivateRouters.PrivateRouterDiabetologie
        path="/diabetologie-observation-encours"
        component={DiabetologieObservation}
        />

        <MyPrivateRouters.PrivateRouterDiabetologie
        path="/diabetologie-observation-terminee"
        component={DiabetologieObservationTerminees}
        />

        <MyPrivateRouters.PrivateRouterDiabetologie
        path="/diabetologie-consultation"
        component={LayoutDiabetologieConsultationListe}
        />

        <MyPrivateRouters.PrivateRouterDiabetologie
        path="/diabetologie-ordonnance"
        component={LayoutDiabetologieOrdonnanceListe}
        />

        <MyPrivateRouters.PrivateRouterDiabetologie
        path="/diabetologie-ordonnance-voir/:id"
        component={LayoutDiabetologieOrdonnanceDetails}
        />

        <MyPrivateRouters.PrivateRouterDiabetologie
        path="/diabetologie-rdv"
        component={LayoutDiabetologieRdvListe}
        />

        <MyPrivateRouters.PrivateRouterDiabetologie
        path="/diabetologie-rdv-last-consultation/:id"
        component={LayoutDiabetoRdvLastConsultation}
        />

        <MyPrivateRouters.PrivateRouterDiabetologie
        path="/diabetologie-rdv-new-consultation/:id"
        component={LayoutDiabetoRdvNewConsultation}
        />

        <MyPrivateRouters.PrivateRouterDiabetologie
        path="/diabetologie-rdv-agenda/"
        component={AgendaDiabetologie}
        />

        <MyPrivateRouters.PrivateRouterDiabetologie
        path="/diabetologie-consultation-create/:id"
        component={LayoutDiabetologieCreateConsultation}
        />

        <MyPrivateRouters.PrivateRouterDiabetologie
        path="/diabetologie-consultation-voir-detail/:id"
        component={LayoutDiabetologieViewConsultation}
        />

        <MyPrivateRouters.PrivateRouterDiabetologie
        path="/diabetologie-demande-hospitalisation"
        component={LayoutDiabetologieDemandeHospitalisationListe}
        />

        <MyPrivateRouters.PrivateRouterDiabetologie
        path="/diabetologie-hospitalisation"
        component={LayoutDiabetologieHospitalisationListe}
        />

        <MyPrivateRouters.PrivateRouterDiabetologie
        path="/diabetologie-hospitalisation-consultation/:id"
        component={LayoutDiabetologieHospitalisationConsultationCreate}
        />

        <MyPrivateRouters.PrivateRouterDiabetologie
        path="/diabetologie-hospitalisation-sortie/:id"
        component={LayoutDiabetologieHospitalisationConsultationSortie}
        />

        <MyPrivateRouters.PrivateRouterDiabetologie
        path="/diabetologie-feuille-de-soins/:id"
        component={LayoutDiabetologieFeuilleDeSoins}
        />

        <MyPrivateRouters.PrivateRouterDiabetologie
        path="/diabetologie-consultation-sortie/:id"
        component={LayoutCreateConsultationSortieDiabetologie}
        />

        <MyPrivateRouters.PrivateRouterDiabetologie
        path="/diabetologie-consultation-sortie-demande-hospitalisation/:id"
        component={
            LayoutCreateConsultationSortieDemandeHospitalisationDiabetologie
        }
        />

        <MyPrivateRouters.PrivateRouterDiabetologie
        path="/diabetologie-consultation-sortie-mise-en-observation/:id"
        component={
            LayoutCreateConsultationSortieMiseEnObservationDiabetologie
        }
        />

        <MyPrivateRouters.PrivateRouterDiabetologie
        path="/diabetologie-consultation-sortie-refere-interne/:id"
        component={LayoutCreateConsultationRefereInterneDiabetologie}
        />

        <MyPrivateRouters.PrivateRouterDiabetologie
        path="/diabetologie-consultation-sortie-refere-externe/:id"
        component={LayoutCreateConsultationRefereExterneDiabetologie}
        />

        <MyPrivateRouters.PrivateRouterDiabetologie
        path="/diabetologie-consultation-sortie-issue-deces/:id"
        component={LayoutDiabetologieDecesConsultationSortie}
        />

        <MyPrivateRouters.PrivateRouterDiabetologie
        path="/diabetologie-consultation-sortie-rdv/:id"
        component={LayoutDiabetologieCreateConsultationRendezVous}
        />

        <MyPrivateRouters.PrivateRouterDiabetologie
        path="/diabetologie-exit-observation/:id"
        component={LayoutCreateConsultationSortieDiabetologie}
        />

        <MyPrivateRouters.PrivateRouterDiabetologie
        path="/diabetologie-observation-consultation/:id"
        component={LayoutDiabetologieCreateConsultation}
        />

        <MyPrivateRouters.PrivateRouterDiabetologie
        path="/diabetologie-observation-exit-demande-hospitalisation/:id"
        component={DemandeHospiObservationDiabetologie}
        />

        <MyPrivateRouters.PrivateRouterDiabetologie
        path="/diabetologie-observation-exit-reference-externe/:id"
        component={RefExterneObservationDiabetologie}
        />

        <MyPrivateRouters.PrivateRouterDiabetologie
        path="/diabetologie-observation-exit-reference-interne/:id"
        component={RefInterneObservationDiabetologie}
        />

        <MyPrivateRouters.PrivateRouterDiabetologie
        path="/diabetologie-observation-exit-deces/:id"
        component={LayoutDiabetologieDecesConsultationSortie}
        />

        <MyPrivateRouters.PrivateRouterDiabetologie
        path="/diabetologie-observation-exit-rdv/:id"
        component={LayoutDiabetologieCreateConsultationRendezVous}
        />

        <MyPrivateRouters.PrivateRouterDiabetologie
        path="/diabetologie-examens-detail/:id/"
        component={DiabetologieExamensDetail}
        />

        <MyPrivateRouters.PrivateRouterDiabetologie
        path="/diabetologie-examens"
        component={DiabetologieExamensList}
        />

        <MyPrivateRouters.PrivateRouterDiabetologie
        path="/diabetologie-patients-decedes"
        component={LayoutDiabetologieDecesListe}
        />

        <MyPrivateRouters.PrivateRouterDiabetologie
        path="/diabetologie-referencements-externe"
        component={DiabetologieRefExterne}
        />

        <MyPrivateRouters.PrivateRouterDiabetologie
        path="/diabetologie-referencements-interne"
        component={DiabetologieRefInterne}
        />

        <MyPrivateRouters.PrivateRouterDiabetologie
        path="/diabetologie-patients-decedes-certificat/:id"
        component={LayoutDiabetologieDecesCertificat}
        />

        <MyPrivateRouters.PrivateRouterDiabetologie
        path="/diabetologie-examens-resultat-voir/:id"
        component={LayoutVoirDiabetologieResultatsExamens}
        />

        <MyPrivateRouters.PrivateRouterDiabetologie
        path="/diabetologie-rapports"
        component={RapportDiabetologie}
        />

        <MyPrivateRouters.PrivateRouterDiabetologie
        path="/diabetologie-controle-major"
        component={LayoutDiabetologieControleMajor}
        />
    </Switch>
  )
}
