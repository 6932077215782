import { normalizeString, slugify } from "../../../utils/utilsFunctions"
import { AUTH_ERROR, LOGIN_FAIL, LOGIN_LOADING, LOGIN_SUCCESS, 
    LOGOUT_SUCCESS, USER_LOADED, USER_LOADING, TOKEN_REFRESH_SUCCESS, 
    TOKEN_REFRESH_FAIL, TOKEN_REFRESH_LOADING, ERROR_VALUE_CLEAR, 
    USER_PASSWORD_CHANGE_LOADING, USER_PASSWORD_CHANGE_SUCCESS, USER_PASSWORD_CHANGE_FAIL, CIRCUIT_PATIENT_LOADING, CIRCUIT_PATIENT_SUCCESS, CIRCUIT_PATIENT_FAILED, AUTH_ERROR_NON_TROUVE, EMAIL_USER_GET_LOADING, EMAIL_USER_GET_SUCCESS, EMAIL_USER_GET_FAILED, CLEAR_STAPPERS_VALUE_CONNEXION, ONCHANGE_SEARCH_INPUT, MARQUE_IMPRIMER_FICHE_LOADING, MARQUE_IMPRIMER_FICHE_SUCCESS, MARQUE_IMPRIMER_FICHE_FAILED, SESSION_EXPIRED, SESSION_NEW_FAILED, SESSION_NEW_SUCCESS, SESSION_NEW_LOADING } from "../../types/userTypes"
// import jwtDecode from "jwt-decode"

const initialState = {
    token_access: localStorage.getItem('token_access'),
    token_refresh: localStorage.getItem('token_refresh'),
    user: {},
    modulesData: [],
    modulesDataUserSearch: [],
    isAuthenticated: null,
    isLoading: false,
    error:null,
    isLoadingChanged:false,
    errorPassword:null,
    isLoadingConnexion:false,
    isErrorPassword:false,
    isLoginError:false,
    errorCode: null,
    loginError:null,
    isSetPasswordSucces:false,
    isLoadingRefresh:false,
    isCircuitsRegister:false,
    isCircuitsRegisterError:false,
    isLoadingGetEmail: false,
    isSuccessGetEmail: false,
    isErrorGetEmail: false,
    errorGetEmail: "",
    messageGetEmail: "",
    isLoadingGetCentre: false,
    isSuccessGetCentre: false,

    isAccueils: false,
    isCabinetDentaires: false, 
    isCaisse: false,
    isCardiologie: false,
    isConfiguration: false,
    isDermatologie: false,
    isDossiersPatients: false,
    isFacturation: false,
    isGynecologie: false,
    isHospitalisation: false,
    isInfirmerie: false,
    isKinesitherapie: false,
    isLaboratoire: false,
    isMaternite: false,
    isMedecineGenerale: false,
    isOphtalmologie: false,
    isOrl: false,
    isPediatrie: false,
    isPharmacie: false,
    isRadiologie: false,
    isRapportsMensuels: false, 
    isStatistique: false,
    isUrgence: false,
    isChirurgie: false,
    isSuiteDeCouche: false,
    isCasSociaux: false,
    isTarifsReduits: false,
    isEtablissement: false,
    isParametrageGlobale: false,
    isCorbeille: false,
    isCliniqueDiabetologie: false,
    isNeuroPsychiatrie: false,
    isRhumatologie: false,
    isPneumologie: false,
    isReanimation: false,
    isUrologie: false,
    isHemodialyse: false,
    isAnesthesie: false,
    isExamensCardiologiques: false,
    isPlanningFamilial: false,
    isNutrition: false,
    isVaccination: false,
    isMilda: false,
    isCpn: false,
    isCpon: false,
    isAccouchement: false,
    isEchographie: false,
    isControlePBF: false,
    isTelemedecine: false,


    isAdmin: false,
    isDirecteur: false,
    isCaissier: false,
    isDirecteurCommercial: false,
    isMedecins: false,
    isRessourcesHumaines: false,
    isInfirmier: false,
    isLaboratin: false,
    isComptable: false,
    isRegisseur: false,
    isAdministratif: false,
    isSuperAdministrateur: false,

    session_unavailable: true,
    
}

// eslint-disable-next-line
export default function (state = initialState, action) {
    switch (action.type) {
        case USER_LOADING:
            return {
                ...state,
                isLoading: false,
                isLoadingConnexion:false,
                error:null,
                isSetPasswordSucces:false,
                isLoadingRefresh:false,
                isLoadingGetCentre: true,
                isSuccessGetCentre: false,
        }
        case USER_PASSWORD_CHANGE_LOADING:
            return {
                ...state,
                isLoadingChanged: true,
                errorPassword: null,
                isErrorPassword:false,
                isSetPasswordSucces:false
        }
        case USER_PASSWORD_CHANGE_SUCCESS:
            return {
                ...state,
                isLoadingChanged: false,
                isErrorPassword:false,
                errorPassword:null,
                isSetPasswordSucces:true
        }
        case USER_PASSWORD_CHANGE_FAIL:
            return {
                ...state,
                isLoadingChanged: false,
                errorPassword: action.payload,
                isErrorPassword:true,
                isSetPasswordSucces:false
        }
        case USER_LOADED:
            return {
                ...state,
                isAuthenticated: true,
                isLoading: false,
                user: action.payload,
                error:null,
                isSetPasswordSucces:false,
                isLoginError:false,
                errorCode: null,
                loginError:null,
                isLoadingRefresh:false,
                isLoadingGetCentre: false,
                isSuccessGetCentre: true,

                modulesData: action.payload?.modules,
                modulesDataUserSearch: action.payload?.modules,

                isAccueils: action.payload?.modules?.filter(data => normalizeString(data?.libelle) === normalizeString("Bureau des entrées") && data?.active === true)?.length > 0 ? true : false,
                isCabinetDentaires: action.payload?.modules?.filter(data => normalizeString(data?.libelle) === normalizeString("Cabinet Dentaire") && data?.active === true)?.length > 0 ? true : false, 
                isCaisse: action.payload?.modules?.filter(data => normalizeString(data?.libelle) === normalizeString("Caisse") && data?.active === true)?.length > 0 ? true : false,
                isCardiologie: action.payload?.modules?.filter(data => normalizeString(data?.libelle) === normalizeString("Cardiologie") && data?.active === true)?.length > 0 ? true : false,
                isConfiguration: action.payload?.modules?.filter(data => normalizeString(data?.libelle) === normalizeString("Configuration") && data?.active === true)?.length > 0 ? true : false,
                isDermatologie: action.payload?.modules?.filter(data => normalizeString(data?.libelle) === normalizeString("Dermatologie") && data?.active === true)?.length > 0 ? true : false,
                isDossiersPatients: action.payload?.modules?.filter(data => normalizeString(data?.libelle) === normalizeString("Dossiers Patients") && data?.active === true)?.length > 0 ? true : false,
                isFacturation: action.payload?.modules?.filter(data => normalizeString(data?.libelle) === normalizeString("Facturation") && data?.active === true)?.length > 0 ? true : false,
                isGynecologie: action.payload?.modules?.filter(data => normalizeString(data?.libelle) === normalizeString("Gynecologie") && data?.active === true)?.length > 0 ? true : false,
                isHospitalisation: action.payload?.modules?.filter(data => normalizeString(data?.libelle) === normalizeString("Hospitalisation") && data?.active === true)?.length > 0 ? true : false,
                isInfirmerie: action.payload?.modules?.filter(data => normalizeString(data?.libelle) === normalizeString("Infirmerie") && data?.active === true)?.length > 0 ? true : false,
                isKinesitherapie: action.payload?.modules?.filter(data => normalizeString(data?.libelle) === normalizeString("Kinésithérapie") && data?.active === true)?.length > 0 ? true : false,
                isLaboratoire: action.payload?.modules?.filter(data => normalizeString(data?.libelle) === normalizeString("Laboratoire") && data?.active === true)?.length > 0 ? true : false,
                isMaternite: action.payload?.modules?.filter(data => normalizeString(data?.libelle) === normalizeString("Maternité") && data?.active === true)?.length > 0 ? true : false,
                isMedecineGenerale: action.payload?.modules?.filter(data => normalizeString(data?.libelle) === normalizeString("Médecine Générale") && data?.active === true)?.length > 0 ? true : false,
                isOphtalmologie: action.payload?.modules?.filter(data => normalizeString(data?.libelle) === normalizeString("Ophtalmologie") && data?.active === true)?.length > 0 ? true : false,
                isOrl: action.payload?.modules?.filter(data => normalizeString(data?.libelle) === normalizeString("ORL") && data?.active === true)?.length > 0 ? true : false,
                isPediatrie: action.payload?.modules?.filter(data => normalizeString(data?.libelle) === normalizeString("Pédiatrie") && data?.active === true)?.length > 0 ? true : false,
                isPharmacie: action.payload?.modules?.filter(data => normalizeString(data?.libelle) === normalizeString("Pharmacie") && data?.active === true)?.length > 0 ? true : false,
                isRadiologie: action.payload?.modules?.filter(data => normalizeString(data?.libelle) === normalizeString("Radiologie") && data?.active === true)?.length > 0 ? true : false,
                isRapportsMensuels: action.payload?.modules?.filter(data => normalizeString(data?.libelle) === normalizeString("Rapports") && data?.active === true)?.length > 0 ? true : false,
                isStatistique: action.payload?.modules?.filter(data => normalizeString(data?.libelle) === normalizeString("Statistiques") && data?.active === true)?.length > 0 ? true : false,
                isUrgence: action.payload?.modules?.filter(data => normalizeString(data?.libelle) === normalizeString("Urgence") && data?.active === true)?.length > 0 ? true : false,  
                isChirurgie: action.payload?.modules?.filter(data => normalizeString(data?.libelle) === normalizeString("Chirurgie") && data?.active === true)?.length > 0 ? true : false,  
                isSuiteDeCouche: action.payload?.modules?.filter(data => normalizeString(data?.libelle) === normalizeString("Suite de Couche") && data?.active === true)?.length > 0 ? true : false,
                isCasSociaux: action.payload?.modules?.filter(data => normalizeString(data?.libelle) === normalizeString("Cas Social") && data?.active === true)?.length > 0 ? true : false,
                isTarifsReduits: action.payload?.modules?.filter(data => normalizeString(data?.libelle) === normalizeString("Tarifs Réduits") && data?.active === true)?.length > 0 ? true : false,
                isEtablissement: action.payload?.modules?.filter(data => normalizeString(data?.libelle) === normalizeString("Établissements") && data?.active === true)?.length > 0 ? true : false,
                isParametrageGlobale: action.payload?.modules?.filter(data => normalizeString(data?.libelle) === normalizeString("Paramétrage Globale") && data?.active === true)?.length > 0 ? true : false,
                isCorbeille: action.payload?.modules?.filter(data => normalizeString(data?.libelle) === normalizeString("Corbeille") && data?.active === true)?.length > 0 ? true : false,
                isCliniqueDiabetologie: action.payload?.modules?.filter(data => normalizeString(data?.libelle) === normalizeString("Clinique Diabétologie") && data?.active === true)?.length > 0 ? true : false,
                isNeuroPsychiatrie: action.payload?.modules?.filter(data => normalizeString(data?.libelle) === normalizeString("Neuro-Psychiatrie") && data?.active === true)?.length > 0 ? true : false,
                isRhumatologie: action.payload?.modules?.filter(data => normalizeString(data?.libelle) === normalizeString("Rhumatologie") && data?.active === true)?.length > 0 ? true : false,
                isPneumologie: action.payload?.modules?.filter(data => normalizeString(data?.libelle) === normalizeString("Pneumologie") && data?.active === true)?.length > 0 ? true : false,
                isReanimation: action.payload?.modules?.filter(data => normalizeString(data?.libelle) === normalizeString("Réanimation") && data?.active === true)?.length > 0 ? true : false,
                isUrologie: action.payload?.modules?.filter(data => normalizeString(data?.libelle) === normalizeString("Urologie") && data?.active === true)?.length > 0 ? true : false,
                isHemodialyse: action.payload?.modules?.filter(data => normalizeString(data?.libelle) === normalizeString("Hémodialyse") && data?.active === true)?.length > 0 ? true : false,
                isAnesthesie: action.payload?.modules?.filter(data => normalizeString(data?.libelle) === normalizeString("Anesthésie") && data?.active === true)?.length > 0 ? true : false,
                isExamensCardiologiques: action.payload?.modules?.filter(data => normalizeString(data?.libelle) === normalizeString("Examens Cardiologiques") && data?.active === true)?.length > 0 ? true : false,
                isPlanningFamilial: action.payload?.modules?.filter(data => normalizeString(data?.libelle) === normalizeString("Planning Familial") && data?.active === true)?.length > 0 ? true : false,
                isNutrition: action.payload?.modules?.filter(data => normalizeString(data?.libelle) === normalizeString("Nutrition") && data?.active === true)?.length > 0 ? true : false,
                isVaccination: action.payload?.modules?.filter(data => normalizeString(data?.libelle) === normalizeString("Vaccination") && data?.active === true)?.length > 0 ? true : false,
                isMilda: action.payload?.modules?.filter(data => normalizeString(data?.libelle) === normalizeString("MILDA") && data?.active === true)?.length > 0 ? true : false,
                isCpn: action.payload?.modules?.filter(data => normalizeString(data?.libelle) === normalizeString("CPN") && data?.active === true)?.length > 0 ? true : false,
                isCpon: action.payload?.modules?.filter(data => normalizeString(data?.libelle) === normalizeString("CPON") && data?.active === true)?.length > 0 ? true : false,
                isAccouchement: action.payload?.modules?.filter(data => normalizeString(data?.libelle) === normalizeString("Accouchement") && data?.active === true)?.length > 0 ? true : false,
                isEchographie: action.payload?.modules?.filter(data => normalizeString(data?.libelle) === normalizeString("Échographie") && data?.active === true)?.length > 0 ? true : false,
                isControlePBF: action.payload?.modules?.filter(data => normalizeString(data?.libelle) === normalizeString("Contrôle PBF") && data?.active === true)?.length > 0 ? true : false,
                isTelemedecine: action.payload?.modules?.filter(data => normalizeString(data?.libelle) === normalizeString("Télémédecine") && data?.active === true)?.length > 0 ? true : false,

                isAdmin: action.payload?.groups?.filter(data => data?.name === "administrateurs")?.length > 0 ? true : false,
                isDirecteur: action.payload?.groups?.filter(data => data?.name === "directeurs")?.length > 0 ? true : false,
                isCaissier: action.payload?.groups?.filter(data => data?.name === "caissiers")?.length > 0 ? true : false,
                isDirecteurCommercial: action.payload?.groups?.filter(data => data?.name === "dcs")?.length > 0 ? true : false,
                isMedecins: action.payload?.groups?.filter(data => data?.name === "medecins")?.length > 0 ? true : false,
                isRessourcesHumaines: action.payload?.groups?.filter(data => data?.name === "rhs")?.length > 0 ? true : false,
                isInfirmier: action.payload?.groups?.filter(data => data?.name === "infirmiers")?.length > 0 ? true : false,
                isLaboratin: action.payload?.groups?.filter(data => data?.name === "laborantins")?.length > 0 ? true : false,
                isComptable: action.payload?.groups?.filter(data => data?.name === "comptables")?.length > 0 ? true : false,
                isRegisseur: action.payload?.groups?.filter(data => data?.name === "regisseurs")?.length > 0 ? true : false,
                isAdministratif: action.payload?.groups?.filter(data => data?.name === "administratifs")?.length > 0 ? true : false,
                isSuperAdministrateur: action.payload?.groups?.filter(data => data?.name === "superadministrateurs")?.length > 0 ? true : false,
        }
        case ONCHANGE_SEARCH_INPUT:
            const modulesDataUserSearchData = !!action.payload ? state.modulesData.filter(data => slugify(data?.libelle).includes(slugify(action.payload)) || slugify(data?.description).includes(slugify(action.payload)) ) : state.modulesData
            return {
                ...state,
                modulesDataUserSearch: modulesDataUserSearchData,
                
                isAccueils: modulesDataUserSearchData?.filter(data => normalizeString(data?.libelle) === normalizeString("Bureau des entrées") && data?.active === true)?.length > 0 ? true : false,
                isCabinetDentaires: modulesDataUserSearchData?.filter(data => normalizeString(data?.libelle) === normalizeString("Cabinet Dentaire") && data?.active === true)?.length > 0 ? true : false, 
                isCaisse: modulesDataUserSearchData?.filter(data => normalizeString(data?.libelle) === normalizeString("Caisse") && data?.active === true)?.length > 0 ? true : false,
                isCardiologie: modulesDataUserSearchData?.filter(data => normalizeString(data?.libelle) === normalizeString("Cardiologie") && data?.active === true)?.length > 0 ? true : false,
                isConfiguration: modulesDataUserSearchData?.filter(data => normalizeString(data?.libelle) === normalizeString("Configuration") && data?.active === true)?.length > 0 ? true : false,
                isDermatologie: modulesDataUserSearchData?.filter(data => normalizeString(data?.libelle) === normalizeString("Dermatologie") && data?.active === true)?.length > 0 ? true : false,
                isDossiersPatients: modulesDataUserSearchData?.filter(data => normalizeString(data?.libelle) === normalizeString("Dossiers Patients") && data?.active === true)?.length > 0 ? true : false,
                isFacturation: modulesDataUserSearchData?.filter(data => normalizeString(data?.libelle) === normalizeString("Facturation") && data?.active === true)?.length > 0 ? true : false,
                isGynecologie: modulesDataUserSearchData?.filter(data => normalizeString(data?.libelle) === normalizeString("Gynecologie") && data?.active === true)?.length > 0 ? true : false,
                isHospitalisation: modulesDataUserSearchData?.filter(data => normalizeString(data?.libelle) === normalizeString("Hospitalisation") && data?.active === true)?.length > 0 ? true : false,
                isInfirmerie: modulesDataUserSearchData?.filter(data => normalizeString(data?.libelle) === normalizeString("Infirmerie") && data?.active === true)?.length > 0 ? true : false,
                isKinesitherapie: modulesDataUserSearchData?.filter(data => normalizeString(data?.libelle) === normalizeString("Kinésithérapie") && data?.active === true)?.length > 0 ? true : false,
                isLaboratoire: modulesDataUserSearchData?.filter(data => normalizeString(data?.libelle) === normalizeString("Laboratoire") && data?.active === true)?.length > 0 ? true : false,
                isMaternite: modulesDataUserSearchData?.filter(data => normalizeString(data?.libelle) === normalizeString("Maternité") && data?.active === true)?.length > 0 ? true : false,
                isMedecineGenerale: modulesDataUserSearchData?.filter(data => normalizeString(data?.libelle) === normalizeString("Médecine Générale") && data?.active === true)?.length > 0 ? true : false,
                isOphtalmologie: modulesDataUserSearchData?.filter(data => normalizeString(data?.libelle) === normalizeString("Ophtalmologie") && data?.active === true)?.length > 0 ? true : false,
                isOrl: modulesDataUserSearchData?.filter(data => normalizeString(data?.libelle) === normalizeString("ORL") && data?.active === true)?.length > 0 ? true : false,
                isPediatrie: modulesDataUserSearchData?.filter(data => normalizeString(data?.libelle) === normalizeString("Pédiatrie") && data?.active === true)?.length > 0 ? true : false,
                isPharmacie: modulesDataUserSearchData?.filter(data => normalizeString(data?.libelle) === normalizeString("Pharmacie") && data?.active === true)?.length > 0 ? true : false,
                isRadiologie: modulesDataUserSearchData?.filter(data => normalizeString(data?.libelle) === normalizeString("Radiologie") && data?.active === true)?.length > 0 ? true : false,
                isRapportsMensuels: modulesDataUserSearchData?.filter(data => normalizeString(data?.libelle) === normalizeString("Rapports") && data?.active === true)?.length > 0 ? true : false,
                isStatistique: modulesDataUserSearchData?.filter(data => normalizeString(data?.libelle) === normalizeString("Statistiques") && data?.active === true)?.length > 0 ? true : false,
                isUrgence: modulesDataUserSearchData?.filter(data => normalizeString(data?.libelle) === normalizeString("Urgence") && data?.active === true)?.length > 0 ? true : false,  
                isChirurgie: modulesDataUserSearchData?.filter(data => normalizeString(data?.libelle) === normalizeString("Chirurgie") && data?.active === true)?.length > 0 ? true : false,  
                isSuiteDeCouche: modulesDataUserSearchData?.filter(data => normalizeString(data?.libelle) === normalizeString("Suite de Couche") && data?.active === true)?.length > 0 ? true : false,
                isCasSociaux: modulesDataUserSearchData?.filter(data => normalizeString(data?.libelle) === normalizeString("Cas Social") && data?.active === true)?.length > 0 ? true : false,
                isTarifsReduits: modulesDataUserSearchData?.filter(data => normalizeString(data?.libelle) === normalizeString("Tarifs Réduits") && data?.active === true)?.length > 0 ? true : false,
                isEtablissement: modulesDataUserSearchData?.filter(data => normalizeString(data?.libelle) === normalizeString("Établissements") && data?.active === true)?.length > 0 ? true : false,
                isParametrageGlobale: modulesDataUserSearchData?.filter(data => normalizeString(data?.libelle) === normalizeString("Paramétrage Globale") && data?.active === true)?.length > 0 ? true : false,
                isCorbeille: modulesDataUserSearchData?.filter(data => normalizeString(data?.libelle) === normalizeString("Corbeille") && data?.active === true)?.length > 0 ? true : false,
                isCliniqueDiabetologie: modulesDataUserSearchData?.filter(data => normalizeString(data?.libelle) === normalizeString("Clinique Diabétologie") && data?.active === true)?.length > 0 ? true : false,
                isNeuroPsychiatrie: modulesDataUserSearchData?.filter(data => normalizeString(data?.libelle) === normalizeString("Neuro-Psychiatrie") && data?.active === true)?.length > 0 ? true : false,
                isRhumatologie: modulesDataUserSearchData?.filter(data => normalizeString(data?.libelle) === normalizeString("Rhumatologie") && data?.active === true)?.length > 0 ? true : false,
                isPneumologie: modulesDataUserSearchData?.filter(data => normalizeString(data?.libelle) === normalizeString("Pneumologie") && data?.active === true)?.length > 0 ? true : false,
                isReanimation: modulesDataUserSearchData?.filter(data => normalizeString(data?.libelle) === normalizeString("Réanimation") && data?.active === true)?.length > 0 ? true : false,
                isUrologie: modulesDataUserSearchData?.filter(data => normalizeString(data?.libelle) === normalizeString("Urologie") && data?.active === true)?.length > 0 ? true : false,
                isHemodialyse: modulesDataUserSearchData?.filter(data => normalizeString(data?.libelle) === normalizeString("Hémodialyse") && data?.active === true)?.length > 0 ? true : false,
                isAnesthesie: modulesDataUserSearchData?.filter(data => normalizeString(data?.libelle) === normalizeString("Anesthésie") && data?.active === true)?.length > 0 ? true : false,
                isExamensCardiologiques: modulesDataUserSearchData?.filter(data => normalizeString(data?.libelle) === normalizeString("Examens Cardiologiques") && data?.active === true)?.length > 0 ? true : false,
                isPlanningFamilial: modulesDataUserSearchData?.filter(data => normalizeString(data?.libelle) === normalizeString("Planning Familial") && data?.active === true)?.length > 0 ? true : false,
                isNutrition: modulesDataUserSearchData?.filter(data => normalizeString(data?.libelle) === normalizeString("Nutrition") && data?.active === true)?.length > 0 ? true : false,
                isVaccination: modulesDataUserSearchData?.filter(data => normalizeString(data?.libelle) === normalizeString("Vaccination") && data?.active === true)?.length > 0 ? true : false,
                isMilda: modulesDataUserSearchData?.filter(data => normalizeString(data?.libelle) === normalizeString("MILDA") && data?.active === true)?.length > 0 ? true : false,
                isCpn: modulesDataUserSearchData?.filter(data => normalizeString(data?.libelle) === normalizeString("CPN") && data?.active === true)?.length > 0 ? true : false,
                isCpon: modulesDataUserSearchData?.filter(data => normalizeString(data?.libelle) === normalizeString("CPON") && data?.active === true)?.length > 0 ? true : false,
                isAccouchement: modulesDataUserSearchData?.filter(data => normalizeString(data?.libelle) === normalizeString("Accouchement") && data?.active === true)?.length > 0 ? true : false,
                isEchographie: modulesDataUserSearchData?.filter(data => normalizeString(data?.libelle) === normalizeString("Échographie") && data?.active === true)?.length > 0 ? true : false,
                isControlePBF: modulesDataUserSearchData?.filter(data => normalizeString(data?.libelle) === normalizeString("Contrôle PBF") && data?.active === true)?.length > 0 ? true : false,
                isTelemedecine: modulesDataUserSearchData?.filter(data => normalizeString(data?.libelle) === normalizeString("Télémédecine") && data?.active === true)?.length > 0 ? true : false,
            }

        case LOGIN_LOADING:
            return {
                ...state,
                isLoadingConnexion: true,
                isLoginError:false,
                errorCode: null,
                loginError:null,
            }

        case SESSION_NEW_LOADING:
            return {
                ...state,
                isLoadingConnexion: true,
                isLoginError:false,
            }

        case LOGIN_SUCCESS:
            localStorage.setItem('token_access', action.payload.token.access)
            localStorage.setItem('token_refresh', action.payload.token.refresh)
            return {
                ...state,
                token_access: localStorage.getItem('token_access'),
                token_refresh: localStorage.getItem('token_refresh'),
                isAuthenticated: true,
                isLoadingConnexion:false,
                isLoginError:false,
                errorCode: null,
                loginError:null,
                isLoadingRefresh:false,
                isLoadingGetCentre: false,
                isSuccessGetCentre: false,  

                session_unavailable: false,
            }

        case SESSION_NEW_SUCCESS:
            localStorage.setItem('token_access', action.payload.token.access)
            localStorage.setItem('token_refresh', action.payload.token.refresh)
            return {
                ...state,
                token_access: localStorage.getItem('token_access'),
                token_refresh: localStorage.getItem('token_refresh'),
                isAuthenticated: true,
                isLoadingConnexion:false,
                isLoginError:false,

                session_unavailable: false,
            }
        
        case SESSION_EXPIRED:
            return {
                ...state,
                session_unavailable: true,
            }

        case SESSION_NEW_FAILED:
            return {
                ...state,
                isLoadingConnexion:false,
                isLoginError:true,
                session_unavailable: true,
            }

        case LOGIN_FAIL:
            localStorage.removeItem('token_access')
            localStorage.removeItem('token_refresh')
            return {
                ...state,
                user: {},
                modulesData: [],
                modulesDataUserSearch: [],

                isAuthenticated: null,
                isLoading: false,
                error:null,
                isLoadingChanged:false,
                errorPassword:null,
                isLoadingConnexion:false,
                isErrorPassword:false,
                isSetPasswordSucces:false,
                isCircuitsRegister:false,
                isCircuitsRegisterError:false,
                isLoginError:true,
                loginError:typeof action.payload === 'undefined' ? "Le serveur est injoignable !!! Verifiez votre connexion internet" : action.payload,
                errorCode: typeof action.payload === 'undefined' ? 500 : 401,
                isLoadingRefresh:false,
                isLoadingGetCentre: false,
                isSuccessGetCentre: false,

                isAccueils: false,
                isCabinetDentaires: false, 
                isCaisse: false,
                isCardiologie: false,
                isConfiguration: false,
                isDermatologie: false,
                isDossiersPatients: false,
                isFacturation: false,
                isGynecologie: false,
                isHospitalisation: false,
                isInfirmerie: false,
                isKinesitherapie: false,
                isLaboratoire: false,
                isMaternite: false,
                isMedecineGenerale: false,
                isOphtalmologie: false,
                isOrl: false,
                isPediatrie: false,
                isPharmacie: false,
                isRadiologie: false,
                isRapportsMensuels: false, 
                isStatistique: false,
                isUrgence: false, 
                isChirurgie: false,
                isSuiteDeCouche: false,
                isCasSociaux: false,
                isTarifsReduits: false,
                isEtablissement: false,
                isParametrageGlobale: false,
                isCorbeille: false,
                isCliniqueDiabetologie: false,
                isNeuroPsychiatrie: false,
                isRhumatologie: false,
                isPneumologie: false,
                isReanimation: false,
                isUrologie: false,
                isHemodialyse: false,
                isAnesthesie: false,
                isExamensCardiologiques: false,
                isPlanningFamilial: false,
                isNutrition: false,
                isVaccination: false,
                isMilda: false,
                isCpn: false,
                isCpon: false,
                isAccouchement: false,
                isEchographie: false,
                isControlePBF: false,
                isTelemedecine: false,

                isAdmin: false,
                isDirecteur: false,
                isCaissier: false,
                isDirecteurCommercial: false,
                isMedecins: false,
                isRessourcesHumaines: false,
                isInfirmier: false,
                isLaboratin: false,
                isComptable: false,
                isRegisseur: false,
                isAdministratif: false,
                isSuperAdministrateur: false,

                session_unavailable: true,
            }

        case AUTH_ERROR:
        case LOGOUT_SUCCESS:
        case TOKEN_REFRESH_FAIL:
            localStorage.removeItem('token_access')
            localStorage.removeItem('token_refresh')
            return {
                ...state,
                token_access: null,
                token_refresh: null,
                user: {},
                modulesData: [],
                modulesDataUserSearch: [],
                isAuthenticated: null,
                isLoading: false,
                error:null,
                isLoadingChanged:false,
                errorPassword:null,
                isLoadingConnexion:false,
                isErrorPassword:false,
                isLoginError:false,
                errorCode: null,
                loginError:null,
                isSetPasswordSucces:false,
                isLoadingRefresh:false,
                isCircuitsRegister:false,
                isCircuitsRegisterError:false,
                isLoadingGetEmail: false,
                isSuccessGetEmail: false,
                isErrorGetEmail: false,
                errorGetEmail: "",
                messageGetEmail: "",
                isLoadingGetCentre: false,
                isSuccessGetCentre: false,

                isAccueils: false,
                isCabinetDentaires: false, 
                isCaisse: false,
                isCardiologie: false,
                isConfiguration: false,
                isDermatologie: false,
                isDossiersPatients: false,
                isFacturation: false,
                isGynecologie: false,
                isHospitalisation: false,
                isInfirmerie: false,
                isKinesitherapie: false,
                isLaboratoire: false,
                isMaternite: false,
                isMedecineGenerale: false,
                isOphtalmologie: false,
                isOrl: false,
                isPediatrie: false,
                isPharmacie: false,
                isRadiologie: false,
                isRapportsMensuels: false, 
                isStatistique: false,
                isUrgence: false,
                isChirurgie: false,
                isSuiteDeCouche: false,
                isCasSociaux: false,
                isTarifsReduits: false,
                isEtablissement: false,
                isParametrageGlobale: false,
                isCorbeille: false,
                isCliniqueDiabetologie: false,
                isNeuroPsychiatrie: false,
                isRhumatologie: false,
                isPneumologie: false,
                isReanimation: false,
                isUrologie: false,
                isHemodialyse: false,
                isAnesthesie: false,
                isExamensCardiologiques: false,
                isPlanningFamilial: false,
                isNutrition: false,
                isVaccination: false,
                isMilda: false,
                isCpn: false,
                isCpon: false,
                isAccouchement: false,
                isEchographie: false,
                isControlePBF: false,
                isTelemedecine: false,

                isAdmin: false,
                isDirecteur: false,
                isCaissier: false,
                isDirecteurCommercial: false,
                isMedecins: false,
                isRessourcesHumaines: false,
                isInfirmier: false,
                isLaboratin: false,
                isComptable: false,
                isRegisseur: false,
                isAdministratif: false,
                isSuperAdministrateur: false,

                session_unavailable: true,
            }
        
        case AUTH_ERROR_NON_TROUVE:
            localStorage.removeItem('token_access')
            localStorage.removeItem('token_refresh')
            return {
                ...state,
                user: {},
                modulesData: [],
                modulesDataUserSearch: [],
                isAuthenticated: null,
                isLoading: false,
                error:null,
                isLoadingChanged:false,
                errorPassword:null,
                isLoadingConnexion:false,
                isErrorPassword:false,
                isLoginError:false,
                errorCode: null,
                loginError:null,
                isSetPasswordSucces:false,
                isLoadingRefresh:false,
                isCircuitsRegister:false,
                isCircuitsRegisterError:false,
                isLoadingGetEmail: false,
                isSuccessGetEmail: false,
                isErrorGetEmail: false,
                errorGetEmail: "",
                messageGetEmail: "",
                isLoadingGetCentre: false,
                isSuccessGetCentre: false,

                isAccueils: false,
                isCabinetDentaires: false, 
                isCaisse: false,
                isCardiologie: false,
                isConfiguration: false,
                isDermatologie: false,
                isDossiersPatients: false,
                isFacturation: false,
                isGynecologie: false,
                isHospitalisation: false,
                isInfirmerie: false,
                isKinesitherapie: false,
                isLaboratoire: false,
                isMaternite: false,
                isMedecineGenerale: false,
                isOphtalmologie: false,
                isOrl: false,
                isPediatrie: false,
                isPharmacie: false,
                isRadiologie: false,
                isRapportsMensuels: false, 
                isStatistique: false,
                isUrgence: false,
                isChirurgie: false,
                isSuiteDeCouche: false,
                isCasSociaux: false,
                isTarifsReduits: false,
                isEtablissement: false,
                isParametrageGlobale: false,
                isCorbeille: false,
                isCliniqueDiabetologie: false,
                isRhumatologie: false,
                isPneumologie: false,
                isReanimation: false,
                isUrologie: false,
                isHemodialyse: false,
                isAnesthesie: false,
                isExamensCardiologiques: false,
                isPlanningFamilial: false,
                isNutrition: false,
                isVaccination: false,
                isMilda: false,
                isCpn: false,
                isCpon: false,
                isAccouchement: false,
                isEchographie: false,
                isControlePBF: false,
                isTelemedecine: false,

                isAdmin: false,
                isDirecteur: false,
                isCaissier: false,
                isDirecteurCommercial: false,
                isMedecins: false,
                isRessourcesHumaines: false,
                isInfirmier: false,
                isLaboratin: false,
                isComptable: false,
                isRegisseur: false,
                isAdministratif: false,
                isSuperAdministrateur: false,

                session_unavailable: false,
            }
        
        
        case TOKEN_REFRESH_LOADING:
            return {
                ...state,
                isLoadingRefresh: true          
            }
        case TOKEN_REFRESH_SUCCESS:
            localStorage.removeItem('token_access')
            localStorage.removeItem('token_refresh')
            localStorage.setItem('token_access', action.payload['access'])
            localStorage.setItem('token_refresh', action.payload['refresh'])
            return {
                ...state,
                token_access: localStorage.getItem('token_access'),
                token_refresh: localStorage.getItem('token_refresh'),
                isAuthenticated: true,
                isLoading: false, 
                isLoadingRefresh:false,       
            }
        case CIRCUIT_PATIENT_LOADING:
            return {
                ...state,
                isCircuitsRegister:false,
                isCircuitsRegisterError:false
            }
        case CIRCUIT_PATIENT_SUCCESS:
            return {
                ...state,
                isCircuitsRegister:true,
                isCircuitsRegisterError:false
            }
        case CIRCUIT_PATIENT_FAILED:
            return {
                ...state,
                isCircuitsRegister:false,
                isCircuitsRegisterError:true
            }
        
        case MARQUE_IMPRIMER_FICHE_LOADING:
            return {
                ...state,
                isFicheImprimer:false,
                isFicheImprimerError:false
            }
        case MARQUE_IMPRIMER_FICHE_SUCCESS:
            return {
                ...state,
                isFicheImprimer:true,
                isFicheImprimerError:false
            }
        case MARQUE_IMPRIMER_FICHE_FAILED:
            return {
                ...state,
                isFicheImprimer:false,
                isFicheImprimerError:true
            }

        
        case EMAIL_USER_GET_LOADING:
            return {
                ...state,
                isLoadingGetEmail:true,
                isSuccessGetEmail:false,
                isErrorGetEmail: false,
                errorGetEmail: "",
                messageGetEmail: ""
            }
        case EMAIL_USER_GET_SUCCESS:
            return {
                ...state,
                isLoadingGetEmail:false,
                isSuccessGetEmail:true,
                isErrorGetEmail: false,
                errorGetEmail: "",
                messageGetEmail: "Vous avez reçu une demande de réinitialisation du mot de passe de votre compte dans votre boîte email. Consultez pour terminer l'operation !"
            }
        case EMAIL_USER_GET_FAILED:
            return {
                ...state,

                isLoading: false,
                error:null,
                isLoadingChanged:false,
                errorPassword:null,
                isLoadingConnexion:false,
                isErrorPassword:false,
                isLoginError:false,
                errorCode: null,
                loginError:null,
                isSetPasswordSucces:false,
                isLoadingRefresh:false,
                isCircuitsRegister:false,
                isCircuitsRegisterError:false,
                isLoadingGetEmail: false,
                isSuccessGetEmail: false,
                isErrorGetEmail: false,
                errorGetEmail: "",
                messageGetEmail: "",
                isLoadingGetCentre: false,
                isSuccessGetCentre: false,
            }


        case ERROR_VALUE_CLEAR:
            return {
                ...state,

                isLoading: false,
                error:null,
                isLoadingChanged:false,
                errorPassword:null,
                isLoadingConnexion:false,
                isErrorPassword:false,
                isLoginError:false,
                errorCode: null,
                loginError:null,
                isSetPasswordSucces:false,
                isLoadingRefresh:false,
                isCircuitsRegister:false,
                isCircuitsRegisterError:false,
                isLoadingGetEmail: false,
                isSuccessGetEmail: false,
                isErrorGetEmail: false,
                errorGetEmail: "",
                messageGetEmail: "",
                isLoadingGetCentre: false,
                isSuccessGetCentre: false,
            }

        case CLEAR_STAPPERS_VALUE_CONNEXION:
            return {
                ...state,

                isLoading: false,
                error:null,
                isLoadingChanged:false,
                errorPassword:null,
                isLoadingConnexion:false,
                isErrorPassword:false,
                isLoginError:false,
                errorCode: null,
                loginError:null,
                isSetPasswordSucces:false,
                isLoadingRefresh:false,
                isCircuitsRegister:false,
                isCircuitsRegisterError:false,
                isLoadingGetEmail: false,
                isSuccessGetEmail: false,
                isErrorGetEmail: false,
                errorGetEmail: "",
                messageGetEmail: "",
                isLoadingGetCentre: false,
                isSuccessGetCentre: false,

                session_unavailable: false,
            }

        default:
            return state
    }
}


