import { refInterneTypes } from "../types/refInterneTypes";

const initialState = {
    isLoading: false,
    isLoadingRefresh: false,
    refInternes: [],
    patientAllRefInternes: [],
    error: "",
}

const refInterneReducer = (state = initialState, action) => {
    switch (action.type) {
        case refInterneTypes.REF_INTERNE_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: "",
            }
        case refInterneTypes.REF_INTERNE_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                refInternes: action.payload,
                error: "",
            }
        case refInterneTypes.REF_INTERNE_GET_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            }
        
        case refInterneTypes.REF_INTERNE_GET_REFRESH_REQUEST:
            return {
                ...state,
                isLoadingRefresh: true,
                error: "",
            }
        case refInterneTypes.REF_INTERNE_GET_REFRESH_SUCCESS:
            return {
                ...state,
                isLoadingRefresh: false,
                refInternes: action.payload,
                error: "",
            }
        case refInterneTypes.REF_INTERNE_GET_REFRESH_FAIL:
            return {
                ...state,
                isLoadingRefresh: false,
                refInternes: [],
                error: action.payload,
            }
        case refInterneTypes.PATIENT_ALL_REF_INTERNE_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: "",
            }
        case refInterneTypes.PATIENT_ALL_REF_INTERNE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                patientAllRefInternes: action.payload,
                error: "",
            }
        case refInterneTypes.PATIENT_ALL_REF_INTERNE_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            }
    
        default:
            return state;
    }
}

export default refInterneReducer