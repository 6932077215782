
import * as examensTypes from '../types/examensTypes';
import { AUTH_ERROR, LOGOUT_SUCCESS, TOKEN_REFRESH_FAIL } from '../types/userTypes';


const initialState = {
    data: [],
    datas: [],
    patientAllData: [],
    // showSuccessModal: false,
    isLoading: false,
    isLoadingRefresh: false,

    isLoadingDemande: false,
    isSuccessDemande: false,

    demandeExamen: {},
    isError: false,
    isErrorGet: false,
    isErrorGetInfo: false,
    error: "",

    examens_resultat: [],
}

// eslint-disable-next-line
export default function (state = initialState, action) {
    switch (action.type) {
        case examensTypes.EXAMENS_LIST_DATA_LOADING:
            // return executeGetDataSuccess(state, action);
            return {
                ...state,
                isLoading: true,
                datas: [],
            }
        case examensTypes.EXAMENS_LIST_DATA_SUCCESS:
            // return executeGetDataSuccess(state, action);
            return {
                ...state,
                isLoading: false,
                datas: action.payload
            }
        case examensTypes.EXAMENS_LIST_DATA_FAIL:
            // return executeGetDataSuccess(state, action);
            return {
                ...state,
                isLoading: false,
                datas: []
            }
        
        case examensTypes.EXAMENS_LIST_DATA_REFRESH_LOADING:
            // return executeGetDataSuccess(state, action);
            return {
                ...state,
                isLoadingRefresh: true,
                data: [],
            }
        case examensTypes.EXAMENS_LIST_DATA_REFRESH_SUCCESS:
            // return executeGetDataSuccess(state, action);
            return {
                ...state,
                isLoadingRefresh: false,
                data: action.payload
            }
        case examensTypes.EXAMENS_LIST_DATA_REFRESH_FAIL:
            // return executeGetDataSuccess(state, action);
            return {
                ...state,
                isLoadingRefresh: false,
                data: []
            }


        case examensTypes.EXAMENS_GET_DATA_LOADING:
            // return executeGetDataSuccess(state, action);
            return {
                ...state,
                isLoading: true,
                isErrorGet: false,
                data: action.payload
            }
        case examensTypes.EXAMENS_GET_DATA_SUCCESS:
            // return executeGetDataSuccess(state, action);
            return {
                ...state,
                isLoading: false,
                isErrorGet: false,
                data: action.payload
            }
        case examensTypes.EXAMENS_GET_DATA_FAIL:
            // return executeGetDataSuccess(state, action);
            return {
                ...state,
                isLoading: false,
                isErrorGet: true,
                data: action.payload
            }
        

        case examensTypes.DONNEE_DEMANDE_EXAMEN_GET_DATA_LOADING:
            return {
                ...state,
                isLoadingDemande: true,
                isSuccessDemande: false,
                demandeExamen: {},
                isError: false,
                isErrorGetInfo: false,
                error: ""
            }
        case examensTypes.DONNEE_DEMANDE_EXAMEN_GET_DATA_SUCCESS:
            return {
                ...state,
                isLoadingDemande: false,
                isSuccessDemande: true,
                isErrorGetInfo: false,
                demandeExamen: action.payload
            }
        case examensTypes.DONNEE_DEMANDE_EXAMEN_GET_DATA_FAILED:
            return {
                ...state,
                isLoadingDemande: false,
                isSuccessDemande: false,
                isError: true,
                isErrorGetInfo: true,
                error: action.payload
            }
        
        case examensTypes.STATE_DEMANDE_EXAMEN_RESET:
            return {
                ...state,
                isLoadingDemande: false,
                isSuccessDemande: false,
                isErrorGet: false,
                isErrorGetInfo: false,
                isError: false,
                error: ""
            }
        case examensTypes.PATIENT_ALL_EXAMENS_REQUEST:
            // return executeGetDataSuccess(state, action);
            return {
                ...state,
                isLoading: true
            }
        case examensTypes.PATIENT_ALL_EXAMENS_SUCCESS:
            // return executeGetDataSuccess(state, action);
            return {
                ...state,
                isLoading: false,
                patientAllData: action.payload
            }
        case examensTypes.PATIENT_ALL_EXAMENS_FAIL:
            // return executeGetDataSuccess(state, action);
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            }
        case examensTypes.EXAMENS_RESULTAT_REQUEST:
            return {
                ...state,
                isLoading: true,
            }
        case examensTypes.EXAMENS_RESULTAT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                examens_resultat: action.payload,
            }
        case examensTypes.EXAMENS_RESULTAT_ERROR:
            return {
                ...state,
                isLoading: false,
            }

        case AUTH_ERROR:
        case LOGOUT_SUCCESS:
        case TOKEN_REFRESH_FAIL:
            localStorage.removeItem('token_access')
            localStorage.removeItem('token_refresh')
            return {
                ...state,
                data: [],
                // showSuccessModal: false,
                isLoading: false,
                isLoadingRefresh: false,

                isLoadingDemande: false,
                isSuccessDemande: false,

                demandeExamen: {},
                isError: false,
                isErrorGet: false,
                isErrorGetInfo: false,
                error: "", 
                examens_resultat: [],
            }
        default:
            return state;
    }
}