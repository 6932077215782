import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'

export const PrivateRouterAccueils = ({...rest}) => {
    const isPermission = useSelector(state => state.userdata?.isAccueils)
    const {isAdmin, isDirecteur, isAdministratif, isRegisseur} = useSelector(state => state.userdata)

    console.log("isAccueils", isPermission)
    const isAuthenticated = useSelector(state => state.userdata.isAuthenticated)
    if(isAuthenticated){
        if(isPermission && (isAdmin || isDirecteur || isAdministratif || isRegisseur)){        
            return <Route {...rest} />
        }else{
            return <Redirect to="/page-not-authorized" />
        }
    }else{
        return <Redirect to="/" />
    }
    
}

export const PrivateRouterCabinetDentaires = ({...rest}) => {
    const isPermission = useSelector(state => state.userdata.isCabinetDentaires)
    const {isAdmin, isDirecteur, isMedecins, isRegisseur} = useSelector(state => state.userdata)
    
    console.log("isCabinetDentaires", isPermission)
    const isAuthenticated = useSelector(state => state.userdata.isAuthenticated)
    
    if(isAuthenticated){
        if(isPermission && (isAdmin || isDirecteur || isMedecins || isRegisseur)){
            return <Route {...rest} />
        }else{
            return <Redirect to="/page-not-authorized" />
        }
    }else{
        return <Redirect to="/" />
    }
}

export const PrivateRouterCaisse = ({...rest}) => {
    const isPermission = useSelector(state => state.userdata.isCaisse)
    const {isAdmin, isDirecteur, isCaissier, isRegisseur, isComptable} = useSelector(state => state.userdata)
    console.log("isCaisse", isPermission)
    const isAuthenticated = useSelector(state => state.userdata.isAuthenticated)
    
    if(isAuthenticated){
        if(isPermission && (isAdmin || isDirecteur || isCaissier || isRegisseur || isComptable)){
            return <Route {...rest} />
        }else{
            return <Redirect to="/page-not-authorized" />
        }
    }else{
        return <Redirect to="/" />
    }
}

export const PrivateRouterCardiologie = ({...rest}) => {
    const isPermission = useSelector(state => state.userdata.isCardiologie)
    const {isAdmin, isDirecteur, isMedecins, isRegisseur} = useSelector(state => state.userdata)
    console.log("isCardiologie", isPermission)
    const isAuthenticated = useSelector(state => state.userdata.isAuthenticated)
    
    if(isAuthenticated){
        if(isPermission && (isAdmin || isDirecteur || isMedecins || isRegisseur)){
            return <Route {...rest} />
        }else{
            return <Redirect to="/page-not-authorized" />
        }
    }else{
        return <Redirect to="/" />
    }
}

export const PrivateRouterReanimation = ({...rest}) => {
    const isPermission = useSelector(state => state.userdata.isReanimation)
    const {isAdmin, isDirecteur, isMedecins, isRegisseur} = useSelector(state => state.userdata)
    const isAuthenticated = useSelector(state => state.userdata.isAuthenticated)
    
    if(isAuthenticated){
        if(isPermission && (isAdmin || isDirecteur || isMedecins || isRegisseur)){
            return <Route {...rest} />
        }else{
            return <Redirect to="/page-not-authorized" />
        }
    }else{
        return <Redirect to="/" />
    }
}

export const PrivateRouterDiabetologie = ({...rest}) => {
    const isPermission = useSelector(state => state.userdata.isCliniqueDiabetologie)
    const {isAdmin, isDirecteur, isMedecins, isRegisseur} = useSelector(state => state.userdata)
    const isAuthenticated = useSelector(state => state.userdata.isAuthenticated)
    
    if(isAuthenticated){
        if(isPermission && (isAdmin || isDirecteur || isMedecins || isRegisseur)){
            return <Route {...rest} />
        }else{
            return <Redirect to="/page-not-authorized" />
        }
    }else{
        return <Redirect to="/" />
    }
}

export const PrivateRouterNeuroPsychiatrie = ({...rest}) => {
    const isPermission = useSelector(state => state.userdata.isNeuroPsychiatrie)
    const {isAdmin, isDirecteur, isMedecins, isRegisseur} = useSelector(state => state.userdata)
    const isAuthenticated = useSelector(state => state.userdata.isAuthenticated)
    
    if(isAuthenticated){
        if(isPermission && (isAdmin || isDirecteur || isMedecins || isRegisseur)){
            return <Route {...rest} />
        }else{
            return <Redirect to="/page-not-authorized" />
        }
    }else{
        return <Redirect to="/" />
    }
}

export const PrivateRouterConfiguration = ({...rest}) => {
    const isPermission = useSelector(state => state.userdata.isConfiguration)
    const {isAdmin, isDirecteur, isRegisseur, isSuperAdministrateur, isRessourcesHumaines} = useSelector(state => state.userdata)
    console.log("isConfiguration", isPermission)
    const isAuthenticated = useSelector(state => state.userdata.isAuthenticated)
    
    if(isAuthenticated){
        if(isPermission && (isAdmin || isDirecteur || isRegisseur || isSuperAdministrateur || isRessourcesHumaines)){
            return <Route {...rest} />
        }else{
            return <Redirect to="/page-not-authorized" />
        }
    }else{
        return <Redirect to="/" />
    }
}

export const PrivateRouterGynecologie = ({...rest}) => {
    const isPermission = useSelector(state => state.userdata.isGynecologie)
    const {isAdmin, isDirecteur, isMedecins, isRegisseur} = useSelector(state => state.userdata)
    console.log("isGynecologie", isPermission)
    const isAuthenticated = useSelector(state => state.userdata.isAuthenticated)
    
    if(isAuthenticated){
        if(isPermission && (isAdmin || isDirecteur || isMedecins || isRegisseur)){
            return <Route {...rest} />
        }else{
            return <Redirect to="/page-not-authorized" />
        }
    }else{
        return <Redirect to="/" />
    }
}

export const PrivateRouterDermatologie = ({...rest}) => {
    const isPermission = useSelector(state => state.userdata.isDermatologie)
    const {isAdmin, isDirecteur, isMedecins, isRegisseur} = useSelector(state => state.userdata)
    console.log("isDermatologie", isPermission)
    const isAuthenticated = useSelector(state => state.userdata.isAuthenticated)
    
    if(isAuthenticated){
        if(isPermission && (isAdmin || isDirecteur || isMedecins || isRegisseur)){
            return <Route {...rest} />
        }else{
            return <Redirect to="/page-not-authorized" />
        }
    }else{
        return <Redirect to="/" />
    }
}


export const PrivateRouterDossiersPatients = ({...rest}) => {
    const isPermission = useSelector(state => state.userdata.isDossiersPatients)
    const {isAdmin} = useSelector(state => state.userdata)
    console.log("isDossiersPatients", isPermission)
    const isAuthenticated = useSelector(state => state.userdata.isAuthenticated)
    
    if(isAuthenticated){
        if(isPermission && isAdmin ){
            return <Route {...rest} />
        }else{
            return <Redirect to="/page-not-authorized" />
        }
    }else{
        return <Redirect to="/" />
    }
}

export const PrivateRouterFacturation = ({...rest}) => {
    const isPermission = useSelector(state => state.userdata.isFacturation)
    const {isAdmin, isRegisseur, isAdministratif} = useSelector(state => state.userdata)
    console.log("isFacturation", isPermission)
    const isAuthenticated = useSelector(state => state.userdata.isAuthenticated)
    
    if(isAuthenticated){
        if(isPermission && (isAdmin || isRegisseur || isAdministratif)){
            return <Route {...rest} />
        }else{
            return <Redirect to="/page-not-authorized" />
        }
    }else{
        return <Redirect to="/" />
    }
}

export const PrivateRouterHospitalisation = ({...rest}) => {
    const isPermission = useSelector(state => state.userdata.isHospitalisation)
    const {isAdmin, isRegisseur, isDirecteur, isMedecins} = useSelector(state => state.userdata)
    console.log("isHospitalisation", isPermission)
    const isAuthenticated = useSelector(state => state.userdata.isAuthenticated)
    
    if(isAuthenticated){
        if(isPermission && (isAdmin || isDirecteur || isMedecins || isRegisseur)){
            return <Route {...rest} />
        }else{
            return <Redirect to="/page-not-authorized" />
        }
    }else{
        return <Redirect to="/" />
    }
}

export const PrivateRouterInfirmerie = ({...rest}) => {
    const isPermission = useSelector(state => state.userdata.isInfirmerie)
    const {isAdmin, isDirecteur, isInfirmier, isRegisseur} = useSelector(state => state.userdata)
    console.log("isInfirmerie", isPermission)
    const isAuthenticated = useSelector(state => state.userdata.isAuthenticated)
    
    if(isAuthenticated){
        if(isPermission && (isAdmin || isDirecteur || isInfirmier || isRegisseur)){
            return <Route {...rest} />
        }else{
            return <Redirect to="/page-not-authorized" />
        }
    }else{
        return <Redirect to="/" />
    }
}

export const PrivateRouterKinesitherapie = ({...rest}) => {
    const isPermission = useSelector(state => state.userdata.isKinesitherapie)
    const {isAdmin, isDirecteur, isMedecins, isRegisseur} = useSelector(state => state.userdata)
    console.log("isKinesitherapie", isPermission)
    const isAuthenticated = useSelector(state => state.userdata.isAuthenticated)
    
    if(isAuthenticated){
        if(isPermission && (isAdmin || isDirecteur || isMedecins || isRegisseur)){
            return <Route {...rest} />
        }else{
            return <Redirect to="/page-not-authorized" />
        }
    }else{
        return <Redirect to="/" />
    }
}

export const PrivateRouterLaboratoire = ({...rest}) => {
    const isPermission = useSelector(state => state.userdata.isLaboratoire)
    const {isAdmin, isDirecteur, isLaboratin, isRegisseur} = useSelector(state => state.userdata)
    console.log("isLaboratoire", isPermission)
    const isAuthenticated = useSelector(state => state.userdata.isAuthenticated)
    
    if(isAuthenticated){
        if(isPermission && (isAdmin || isDirecteur || isLaboratin || isRegisseur)){
            return <Route {...rest} />
        }else{
            return <Redirect to="/page-not-authorized" />
        }
    }else{
        return <Redirect to="/" />
    }
}

export const PrivateRouterMaternite = ({...rest}) => {
    const isPermission = useSelector(state => state.userdata.isMaternite)
    const {isAdmin, isDirecteur, isMedecins, isRegisseur} = useSelector(state => state.userdata)
    console.log("isMaternite", isPermission)
    const isAuthenticated = useSelector(state => state.userdata.isAuthenticated)
    
    if(isAuthenticated){
        if(isPermission && (isAdmin || isDirecteur || isMedecins || isRegisseur)){
            return <Route {...rest} />
        }else{
            return <Redirect to="/page-not-authorized" />
        }
    }else{
        return <Redirect to="/" />
    }
}

export const PrivateRouterMedecineGenerale = ({...rest}) => {
    const isPermission = useSelector(state => state.userdata.isMedecineGenerale)
    const {isAdmin, isDirecteur, isMedecins, isRegisseur} = useSelector(state => state.userdata)
    console.log("isMedecineGenerale", isPermission)
    const isAuthenticated = useSelector(state => state.userdata.isAuthenticated)
    
    if(isAuthenticated){
        if(isPermission && (isAdmin || isDirecteur || isMedecins || isRegisseur)){
            return <Route {...rest} />
        }else{
            return <Redirect to="/page-not-authorized" />
        }
    }else{
        return <Redirect to="/" />
    }
}

export const PrivateRouterisOphtalmologie = ({...rest}) => {
    const isPermission = useSelector(state => state.userdata.isOphtalmologie)
    const {isAdmin, isDirecteur, isMedecins, isRegisseur} = useSelector(state => state.userdata)
    console.log("isOphtalmologie", isPermission)
    const isAuthenticated = useSelector(state => state.userdata.isAuthenticated)
    
    if(isAuthenticated){
        if(isPermission && (isAdmin || isDirecteur || isMedecins || isRegisseur)){
            return <Route {...rest} />
        }else{
            return <Redirect to="/page-not-authorized" />
        }
    }else{
        return <Redirect to="/" />
    }
}

export const PrivateRouterOrl = ({...rest}) => {
    const isPermission = useSelector(state => state.userdata.isOrl)
    const {isAdmin, isDirecteur, isMedecins, isRegisseur} = useSelector(state => state.userdata)
    console.log("isOrl", isPermission)
    const isAuthenticated = useSelector(state => state.userdata.isAuthenticated)
    
    if(isAuthenticated){
        if(isPermission && (isAdmin || isDirecteur || isMedecins || isRegisseur)){
            return <Route {...rest} />
        }else{
            return <Redirect to="/page-not-authorized" />
        }
    }else{
        return <Redirect to="/" />
    }
}

export const PrivateRouterPediatrie = ({...rest}) => {
    const isPermission = useSelector(state => state.userdata.isPediatrie)
    const {isAdmin, isDirecteur, isMedecins, isRegisseur} = useSelector(state => state.userdata)
    console.log("isPediatrie", isPermission)
    const isAuthenticated = useSelector(state => state.userdata.isAuthenticated)
    
    if(isAuthenticated){
        if(isPermission && (isAdmin || isDirecteur || isMedecins || isRegisseur)){
            return <Route {...rest} />
        }else{
            return <Redirect to="/page-not-authorized" />
        }
    }else{
        return <Redirect to="/" />
    }
}

export const PrivateRouterPharmacie = ({...rest}) => {
    const isPermission = useSelector(state => state.userdata.isPharmacie)
    console.log("isPharmacie", isPermission)
    const isAuthenticated = useSelector(state => state.userdata.isAuthenticated)
    
    if(isAuthenticated){
        if(isPermission){
            return <Route {...rest} />
        }else{
            return <Redirect to="/page-not-authorized" />
        }
    }else{
        return <Redirect to="/" />
    }
}

export const PrivateRouterRadiologie = ({...rest}) => {
    const isPermission = useSelector(state => state.userdata.isRadiologie)
    const {isAdmin, isDirecteur, isLaboratin, isRegisseur} = useSelector(state => state.userdata)
    console.log("isRadiologie", isPermission)
    const isAuthenticated = useSelector(state => state.userdata.isAuthenticated)
    
    if(isAuthenticated){
        if(isPermission && (isAdmin || isDirecteur || isLaboratin || isRegisseur)){
            return <Route {...rest} />
        }else{
            return <Redirect to="/page-not-authorized" />
        }
    }else{
        return <Redirect to="/" />
    }
}

export const PrivateRouterRapportsMensuels = ({path,...rest}) => {
    const isPermission = useSelector(state => state.userdata.isRapportsMensuels)
    const {isAdmin, isDirecteur, isComptable, isRegisseur, isSuperAdministrateur, isDirecteurCommercial} = useSelector(state => state.userdata)
    console.log("isRapportsMensuels", isPermission)
    const isAuthenticated = useSelector(state => state.userdata.isAuthenticated)
    
    if(isAuthenticated){
        if(isPermission && (isAdmin || isDirecteur || isComptable || isRegisseur || isSuperAdministrateur || isDirecteurCommercial)){
            return path==="/rapports-mensuels"?  <Redirect to="/rapports-patients" />: <Route {...rest} />
        }else{
            return <Redirect to="/page-not-authorized" />
        }
    }else{
        return <Redirect to="/" />
    }
}

export const PrivateRouterStatistique = ({...rest}) => {
    const isPermission = useSelector(state => state.userdata.isStatistique)
    const {isAdmin, isDirecteur, isComptable, isRegisseur, isSuperAdministrateur, isDirecteurCommercial, isCaissier, isAdministratif} = useSelector(state => state.userdata)
    console.log("isStatistique", isPermission)
    const isAuthenticated = useSelector(state => state.userdata.isAuthenticated)
    
    if(isAuthenticated){
        if(isPermission && (isAdmin || isDirecteur || isComptable || isRegisseur || isSuperAdministrateur || isDirecteurCommercial || isCaissier || isAdministratif)){
            return <Route {...rest} />
        }else{
            return <Redirect to="/page-not-authorized" />
        }
    }else{
        return <Redirect to="/" />
    }
}

export const PrivateRouterUrgence = ({...rest}) => {
    const isPermission = useSelector(state => state.userdata.isUrgence)
    const {isAdmin, isDirecteur, isMedecins, isRegisseur} = useSelector(state => state.userdata)
    console.log("isUrgence", isPermission)
    const isAuthenticated = useSelector(state => state.userdata.isAuthenticated)
    
    if(isAuthenticated){
        if(isPermission && (isAdmin || isDirecteur || isMedecins || isRegisseur)){
            return <Route {...rest} />
        }else{
            return <Redirect to="/page-not-authorized" />
        }
    }else{
        return <Redirect to="/" />
    }
}


export const PrivateRouterChirurgie = ({...rest}) => {
    const isPermission = useSelector(state => state.userdata.isChirurgie)
    const {isAdmin, isDirecteur, isMedecins, isRegisseur} = useSelector(state => state.userdata)
    console.log("isChirurgie", isPermission)
    const isAuthenticated = useSelector(state => state.userdata.isAuthenticated)
    
    if(isAuthenticated){
        if(isPermission && (isAdmin || isDirecteur || isMedecins || isRegisseur)){
            return <Route {...rest} />
        }else{
            return <Redirect to="/page-not-authorized" />
        }
    }else{
        return <Redirect to="/" />
    }
}


export const PrivateRouterSuiteDeCouche = ({...rest}) => {
    const isPermission = useSelector(state => state.userdata.isSuiteDeCouche)
    const {isAdmin, isDirecteur, isMedecins, isRegisseur} = useSelector(state => state.userdata)
    console.log("isSuiteDeCouche", isPermission)
    const isAuthenticated = useSelector(state => state.userdata.isAuthenticated)
    
    if(isAuthenticated){
        if(isPermission && (isAdmin || isDirecteur || isMedecins || isRegisseur)){
            return <Route {...rest} />
        }else{
            return <Redirect to="/page-not-authorized" />
        }
    }else{
        return <Redirect to="/" />
    }
}


export const PrivateRouterCasSociaux = ({...rest}) => {
    const isPermission = useSelector(state => state.userdata.isCasSociaux)
    const {isAdmin, isDirecteur, isRegisseur, isComptable} = useSelector(state => state.userdata)
    console.log("isCasSociaux", isPermission)
    const isAuthenticated = useSelector(state => state.userdata.isAuthenticated)
    
    if(isAuthenticated){
        if(isPermission && (isAdmin || isDirecteur || isRegisseur || isComptable)){
            return <Route {...rest} />
        }else{
            return <Redirect to="/page-not-authorized" />
        }
    }else{
        return <Redirect to="/" />
    }
}


export const PrivateRouterTarifsReduits = ({...rest}) => {
    const isPermission = useSelector(state => state.userdata.isTarifsReduits)
    const {isAdmin, isDirecteur, isRegisseur, isComptable} = useSelector(state => state.userdata)
    console.log("isTarifsReduits", isPermission)
    const isAuthenticated = useSelector(state => state.userdata.isAuthenticated)
    
    if(isAuthenticated){
        if(isPermission && (isAdmin || isDirecteur || isRegisseur || isComptable)){
            return <Route {...rest} />
        }else{
            return <Redirect to="/page-not-authorized" />
        }
    }else{
        return <Redirect to="/" />
    }
}


export const PrivateRouterEtablissements = ({...rest}) => {
    const isPermission = useSelector(state => state.userdata.isEtablissement)
    const {isSuperAdministrateur} = useSelector(state => state.userdata)
    console.log("isEtablissement", isPermission)
    const isAuthenticated = useSelector(state => state.userdata.isAuthenticated)
    
    if(isAuthenticated){
        if(isPermission && (isSuperAdministrateur)){
            return <Route {...rest} />
        }else{
            return <Redirect to="/page-not-authorized" />
        }
    }else{
        return <Redirect to="/" />
    }
}


export const PrivateRouterParametrageGlobale = ({...rest}) => {
    const isPermission = useSelector(state => state.userdata.isParametrageGlobale)
    const {isSuperAdministrateur} = useSelector(state => state.userdata)
    console.log("isParametrageGlobale", isPermission)
    const isAuthenticated = useSelector(state => state.userdata.isAuthenticated)
    
    if(isAuthenticated){
        if(isPermission && (isSuperAdministrateur)){
            return <Route {...rest} />
        }else{
            return <Redirect to="/page-not-authorized" />
        }
    }else{
        return <Redirect to="/" />
    }
}


export const PrivateRouterCorbeille = ({...rest}) => {
    const isPermission = useSelector(state => state.userdata.isCorbeille)
    const {isSuperAdministrateur, isAdmin} = useSelector(state => state.userdata)
    console.log("isParametrageGlobale", isPermission)
    const isAuthenticated = useSelector(state => state.userdata.isAuthenticated)
    
    if(isAuthenticated){
        if(isPermission && (isSuperAdministrateur || isAdmin)){
            return <Route {...rest} />
        }else{
            return <Redirect to="/page-not-authorized" />
        }
    }else{
        return <Redirect to="/" />
    }
}

export const PrivateRouterPneumologie = ({...rest}) => {
    const isPermission = useSelector(state => state.userdata.isPneumologie)
    const {isAdmin, isDirecteur, isMedecins, isRegisseur} = useSelector(state => state.userdata)
    console.log("isPneumologie", isPermission)
    const isAuthenticated = useSelector(state => state.userdata.isAuthenticated)
    
    if(isAuthenticated){
        if(isPermission && (isAdmin || isDirecteur || isMedecins || isRegisseur)){
            return <Route {...rest} />
        }else{
            return <Redirect to="/page-not-authorized" />
        }
    }else{
        return <Redirect to="/" />
    }
}

export const PrivateRouterRhumatologie = ({...rest}) => {
    const isPermission = useSelector(state => state.userdata.isRhumatologie)
    const {isAdmin, isDirecteur, isMedecins, isRegisseur} = useSelector(state => state.userdata)
    console.log("isRhumatologie", isPermission)
    const isAuthenticated = useSelector(state => state.userdata.isAuthenticated)
    
    if(isAuthenticated){
        if(isPermission && (isAdmin || isDirecteur || isMedecins || isRegisseur)){
            return <Route {...rest} />
        }else{
            return <Redirect to="/page-not-authorized" />
        }
    }else{
        return <Redirect to="/" />
    }
}

export const PrivateRouterUrologie = ({...rest}) => {
    const isPermission = useSelector(state => state.userdata.isUrologie)
    const {isAdmin, isDirecteur, isMedecins, isRegisseur} = useSelector(state => state.userdata)
    console.log("isUrologie", isPermission)
    const isAuthenticated = useSelector(state => state.userdata.isAuthenticated)
    
    if(isAuthenticated){
        if(isPermission && (isAdmin || isDirecteur || isMedecins || isRegisseur)){
            return <Route {...rest} />
        }else{
            return <Redirect to="/page-not-authorized" />
        }
    }else{
        return <Redirect to="/" />
    }
}

export const PrivateRouterAnesthesie = ({...rest}) => {
    const isPermission = useSelector(state => state.userdata.isAnesthesie)
    const {isAdmin, isDirecteur, isInfirmier, isMedecins, isRegisseur} = useSelector(state => state.userdata)
    console.log("isAnesthesie", isPermission)
    const isAuthenticated = useSelector(state => state.userdata.isAuthenticated)
    
    if(isAuthenticated){
        if(isPermission && (isAdmin || isDirecteur || isMedecins || isRegisseur || isInfirmier)){
            return <Route {...rest} />
        }else{
            return <Redirect to="/page-not-authorized" />
        }
    }else{
        return <Redirect to="/" />
    }
}

export const PrivateRouterExamensCardiologiques = ({...rest}) => {
    const isPermission = useSelector(state => state.userdata.isExamensCardiologiques)
    const {isAdmin, isDirecteur, isInfirmier, isLaboratin, isMedecins, isRegisseur} = useSelector(state => state.userdata)
    console.log("isExamensCardiologiques", isPermission)
    const isAuthenticated = useSelector(state => state.userdata.isAuthenticated)
    
    if(isAuthenticated){
        if(isPermission && (isAdmin || isDirecteur || isMedecins || isRegisseur || isInfirmier || isLaboratin)){
            return <Route {...rest} />
        }else{
            return <Redirect to="/page-not-authorized" />
        }
    }else{
        return <Redirect to="/" />
    }
}

export const PrivateRouterPlanningFamilial = ({...rest}) => {
    const isPermission = useSelector(state => state.userdata.isPlanningFamilial)
    const {isAdmin, isDirecteur, isInfirmier, isMedecins, isRegisseur} = useSelector(state => state.userdata)
    console.log("isPlanningFamilial", isPermission)
    const isAuthenticated = useSelector(state => state.userdata.isAuthenticated)
    
    if(isAuthenticated){
        if(isPermission && (isAdmin || isDirecteur || isMedecins || isRegisseur || isInfirmier)){
            return <Route {...rest} />
        }else{
            return <Redirect to="/page-not-authorized" />
        }
    }else{
        return <Redirect to="/" />
    }
}

export const PrivateRouterNutrition = ({...rest}) => {
    const isPermission = useSelector(state => state.userdata.isNutrition)
    const {isAdmin, isDirecteur, isInfirmier, isMedecins, isRegisseur} = useSelector(state => state.userdata)
    console.log("isNutrition", isPermission)
    const isAuthenticated = useSelector(state => state.userdata.isAuthenticated)
    
    if(isAuthenticated){
        if(isPermission && (isAdmin || isDirecteur || isMedecins || isRegisseur || isInfirmier)){
            return <Route {...rest} />
        }else{
            return <Redirect to="/page-not-authorized" />
        }
    }else{
        return <Redirect to="/" />
    }
}

export const PrivateRouterVaccination = ({...rest}) => {
    const isPermission = useSelector(state => state.userdata.isVaccination)
    const {isAdmin, isDirecteur, isInfirmier, isMedecins, isRegisseur} = useSelector(state => state.userdata)
    console.log("isVaccination", isPermission)
    const isAuthenticated = useSelector(state => state.userdata.isAuthenticated)
    
    if(isAuthenticated){
        if(isPermission && (isAdmin || isDirecteur || isMedecins || isRegisseur || isInfirmier)){
            return <Route {...rest} />
        }else{
            return <Redirect to="/page-not-authorized" />
        }
    }else{
        return <Redirect to="/" />
    }
}

export const PrivateRouterMILDA = ({...rest}) => {
    const isPermission = useSelector(state => state.userdata.isMilda)
    const {isAdmin, isDirecteur, isInfirmier, isMedecins, isRegisseur} = useSelector(state => state.userdata)
    console.log("isMilda", isPermission)
    const isAuthenticated = useSelector(state => state.userdata.isAuthenticated)
    
    if(isAuthenticated){
        if(isPermission && (isAdmin || isDirecteur || isMedecins || isRegisseur || isInfirmier)){
            return <Route {...rest} />
        }else{
            return <Redirect to="/page-not-authorized" />
        }
    }else{
        return <Redirect to="/" />
    }
}

export const PrivateRouterCPN = ({...rest}) => {
    const isPermission = useSelector(state => state.userdata.isCpn)
    const {isAdmin, isDirecteur, isInfirmier, isMedecins, isRegisseur} = useSelector(state => state.userdata)
    console.log("isCpn", isPermission)
    const isAuthenticated = useSelector(state => state.userdata.isAuthenticated)
    
    if(isAuthenticated){
        if(isPermission && (isAdmin || isDirecteur || isMedecins || isRegisseur || isInfirmier)){
            return <Route {...rest} />
        }else{
            return <Redirect to="/page-not-authorized" />
        }
    }else{
        return <Redirect to="/" />
    }
}

export const PrivateRouterCPON = ({...rest}) => {
    const isPermission = useSelector(state => state.userdata.isCpon)
    const {isAdmin, isDirecteur, isInfirmier, isMedecins, isRegisseur} = useSelector(state => state.userdata)
    console.log("isCpon", isPermission)
    const isAuthenticated = useSelector(state => state.userdata.isAuthenticated)
    
    if(isAuthenticated){
        if(isPermission && (isAdmin || isDirecteur || isMedecins || isRegisseur || isInfirmier)){
            return <Route {...rest} />
        }else{
            return <Redirect to="/page-not-authorized" />
        }
    }else{
        return <Redirect to="/" />
    }
}

export const PrivateRouterAccouchement = ({...rest}) => {
    const isPermission = useSelector(state => state.userdata.isAccouchement)
    const {isAdmin, isDirecteur, isInfirmier, isMedecins, isRegisseur} = useSelector(state => state.userdata)
    console.log("isAccouchement", isPermission)
    const isAuthenticated = useSelector(state => state.userdata.isAuthenticated)
    
    if(isAuthenticated){
        if(isPermission && (isAdmin || isDirecteur || isMedecins || isRegisseur || isInfirmier)){
            return <Route {...rest} />
        }else{
            return <Redirect to="/page-not-authorized" />
        }
    }else{
        return <Redirect to="/" />
    }
}

export const PrivateRouterEchographie = ({...rest}) => {
    const isPermission = useSelector(state => state.userdata.isEchographie)
    const {isAdmin, isDirecteur, isLaboratin, isRegisseur} = useSelector(state => state.userdata)
    console.log("isEchographie", isPermission)
    const isAuthenticated = useSelector(state => state.userdata.isAuthenticated)
    
    if(isAuthenticated){
        if(isPermission && (isAdmin || isDirecteur || isLaboratin || isRegisseur)){
            return <Route {...rest} />
        }else{
            return <Redirect to="/page-not-authorized" />
        }
    }else{
        return <Redirect to="/" />
    }
}

export const PrivateRouterControlePBF = ({...rest}) => {
    const isPermission = useSelector(state => state.userdata.isControlePBF)
    const {isAdmin, isDirecteur, isRegisseur, isSuperAdministrateur} = useSelector(state => state.userdata)
    const isAuthenticated = useSelector(state => state.userdata.isAuthenticated)
    
    if(isAuthenticated){
        if(isPermission && (isAdmin || isSuperAdministrateur || isDirecteur || isRegisseur)){
            return <Route {...rest} />
        }else{
            return <Redirect to="/page-not-authorized" />
        }
    }else{
        return <Redirect to="/" />
    }
}


export const PrivateRouterTelemedecine = ({...rest}) => {
    const isPermission = useSelector(state => state.userdata.isTelemedecine)
    const {isAdmin, isDirecteur, isInfirmier, isMedecins, isRegisseur} = useSelector(state => state.userdata)
    console.log("isTelemedecine", isPermission)
    const isAuthenticated = useSelector(state => state.userdata.isAuthenticated)
    
    if(isAuthenticated){
        if(isPermission && (isAdmin || isDirecteur || isMedecins || isRegisseur || isInfirmier)){
            return <Route {...rest} />
        }else{
            return <Redirect to="/page-not-authorized" />
        }
    }else{
        return <Redirect to="/" />
    }
}
