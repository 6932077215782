export const fichePaiementTypes = {
  FICHEPAIEMENT_LIST_REQUEST: "FICHEPAIEMENT_LIST_REQUEST",
  FICHEPAIEMENT_LIST_SUCCESS: "FICHEPAIEMENT_LIST_SUCCESS",
  FICHEPAIEMENT_LIST_FAIL: "FICHEPAIEMENT_LIST_FAIL",

  FICHEPAIEMENT_LIST_REFRESH_REQUEST: "FICHEPAIEMENT_LIST_REFRESH_REQUEST",
  FICHEPAIEMENT_LIST_REFRESH_SUCCESS: "FICHEPAIEMENT_LIST_REFRESH_SUCCESS",
  FICHEPAIEMENT_LIST_REFRESH_FAIL: "FICHEPAIEMENT_LIST_REFRESH_FAIL",

  FICHEPAIEMENT_LIST_PRE_CAISSE_LOADING: "FICHEPAIEMENT_LIST_PRE_CAISSE_LOADING",
  FICHEPAIEMENT_LIST_PRE_CAISSE_SUCCESS: "FICHEPAIEMENT_LIST_PRE_CAISSE_SUCCESS",
  FICHEPAIEMENT_LIST_PRE_CAISSE_FAILED: "FICHEPAIEMENT_LIST_PRE_CAISSE_FAILED",

  FICHEPAIEMENT_LIST_PRE_CAISSE_REFRESH_LOADING: "FICHEPAIEMENT_LIST_PRE_CAISSE_REFRESH_LOADING",
  FICHEPAIEMENT_LIST_PRE_CAISSE_REFRESH_SUCCESS: "FICHEPAIEMENT_LIST_PRE_CAISSE_REFRESH_SUCCESS",
  FICHEPAIEMENT_LIST_PRE_CAISSE_REFRESH_FAILED: "FICHEPAIEMENT_LIST_PRE_CAISSE_REFRESH_FAILED",

  FICHEPAIEMENT_LIST_POST_CAISSE_LOADING: "FICHEPAIEMENT_LIST_POST_CAISSE_LOADING",
  FICHEPAIEMENT_LIST_POST_CAISSE_SUCCESS: "FICHEPAIEMENT_LIST_POST_CAISSE_SUCCESS",
  FICHEPAIEMENT_LIST_POST_CAISSE_FAILED: "FICHEPAIEMENT_LIST_POST_CAISSE_FAILED",

  FICHEPAIEMENT_GET_LOADING: "FICHEPAIEMENT_GET_LOADING",
  FICHEPAIEMENT_GET_SUCCESS: "FICHEPAIEMENT_GET_SUCCESS",
  FICHEPAIEMENT_GET_FAILED: "FICHEPAIEMENT_GET_FAILED",

  FICHEPAIEMENT_GET_PRESTATION_LOADING: "FICHEPAIEMENT_GET_PRESTATION_LOADING",
  FICHEPAIEMENT_GET_PRESTATION_SUCCESS: "FICHEPAIEMENT_GET_PRESTATION_SUCCESS",
  FICHEPAIEMENT_GET_PRESTATION_FAILED: "FICHEPAIEMENT_GET_PRESTATION_FAILED",

  FICHEPAIEMENT_GET_CAISSE_LOADING: "FICHEPAIEMENT_GET_CAISSE_LOADING",
  FICHEPAIEMENT_GET_CAISSE_SUCCESS: "FICHEPAIEMENT_GET_CAISSE_SUCCESS",
  FICHEPAIEMENT_GET_CAISSE_FAILED: "FICHEPAIEMENT_GET_CAISSE_FAILED",

  FICHEPAIEMENT_DELETE_REQUEST: "FICHEPAIEMENT_DELETE_REQUEST",
  FICHEPAIEMENT_DELETE_SUCCESS: "FICHEPAIEMENT_DELETE_SUCCESS",
  FICHEPAIEMENT_DELETE_FAIL: "FICHEPAIEMENT_DELETE_FAIL",

  FICHEPAIEMENT_DELETE_PRE_CAISSE_REQUEST: "FICHEPAIEMENT_DELETE_PRE_CAISSE_REQUEST",
  FICHEPAIEMENT_DELETE_PRE_CAISSE_SUCCESS: "FICHEPAIEMENT_DELETE_PRE_CAISSE_SUCCESS",
  FICHEPAIEMENT_DELETE_IMPOSSIBLE_PRE_CAISSE_SUCCESS: "FICHEPAIEMENT_DELETE_IMPOSSIBLE_PRE_CAISSE_SUCCESS",
  FICHEPAIEMENT_DELETE_PRE_CAISSE_FAIL: "FICHEPAIEMENT_DELETE_PRE_CAISSE_FAIL",

  FICHEPAIEMENT_DESARCHIVAGE_PRE_CAISSE_REQUEST: "FICHEPAIEMENT_DESARCHIVAGE_PRE_CAISSE_REQUEST",
  FICHEPAIEMENT_DESARCHIVAGE_PRE_CAISSE_SUCCESS: "FICHEPAIEMENT_DESARCHIVAGE_PRE_CAISSE_SUCCESS",
  FICHEPAIEMENT_DESARCHIVAGE_PRE_CAISSE_FAIL: "FICHEPAIEMENT_DESARCHIVAGE_PRE_CAISSE_FAIL",

  FICHEPAIEMENT_DELETE_POST_CAISSE_LOADING: "FICHEPAIEMENT_DELETE_POST_CAISSE_LOADING",
  FICHEPAIEMENT_DELETE_POST_CAISSE_SUCCESS: "FICHEPAIEMENT_DELETE_POST_CAISSE_SUCCESS",
  FICHEPAIEMENT_DELETE_POST_CAISSE_FAILED: "FICHEPAIEMENT_DELETE_POST_CAISSE_FAILED",

  FICHEPAIEMENT_POST_LOADING: "FICHEPAIEMENT_POST_LOADING",
  FICHEPAIEMENT_POST_SUCCESS: "FICHEPAIEMENT_POST_SUCCESS",
  FICHEPAIEMENT_POST_FAILED: "FICHEPAIEMENT_POST_FAILED",

  FICHEPAIEMENT_PUT_LOADING: "FICHEPAIEMENT_PUT_LOADING",
  FICHEPAIEMENT_PUT_SUCCESS: "FICHEPAIEMENT_PUT_SUCCESS",
  FICHEPAIEMENT_PUT_FAILED: "FICHEPAIEMENT_PUT_FAILED",


  GET_CHECK_CAISSE_OUVERT_LOADING: "GET_CHECK_CAISSE_OUVERT_LOADING",
  GET_CHECK_CAISSE_OUVERT_SUCCESS: "GET_CHECK_CAISSE_OUVERT_SUCCESS",
  GET_CHECK_CAISSE_OUVERT_FAILED: "GET_CHECK_CAISSE_OUVERT_FAILED",

  POST_CHECK_CAISSE_OUVERT_LOADING: "POST_CHECK_CAISSE_OUVERT_LOADING",
  POST_CHECK_CAISSE_OUVERT_SUCCESS: "POST_CHECK_CAISSE_OUVERT_SUCCESS",
  POST_CHECK_CAISSE_OUVERT_FAILED: "POST_CHECK_CAISSE_OUVERT_FAILED",


  UPDATE_CHECK_CAISSE_PROLONGER_HEURE_FERMETURE_LOADING: "UPDATE_CHECK_CAISSE_PROLONGER_HEURE_FERMETURE_LOADING",
  UPDATE_CHECK_CAISSE_PROLONGER_HEURE_FERMETURE_SUCCESS: "UPDATE_CHECK_CAISSE_PROLONGER_HEURE_FERMETURE_SUCCESS",
  UPDATE_CHECK_CAISSE_PROLONGER_HEURE_FERMETURE_FAILED: "UPDATE_CHECK_CAISSE_PROLONGER_HEURE_FERMETURE_FAILED",

  
  GET_FICHEPAIEMENT_DETAILS_LOADING: "GET_FICHEPAIEMENT_DETAILS_LOADING",
  GET_FICHEPAIEMENT_DETAILS_SUCCESS: "GET_FICHEPAIEMENT_DETAILS_SUCCESS",
  GET_FICHEPAIEMENT_DETAILS_FAILED: "GET_FICHEPAIEMENT_DETAILS_FAILED",
  

  FICHEPAIEMENT_SET_SUCCESS: 'FICHEPAIEMENT_SET_SUCCESS',

  FICHEPAIEMENT_POST_ALERT: "FICHEPAIEMENT_POST_ALERT",

  FACTURE_FICHE_EXIST: "FACTURE_FICHE_EXIST",
  FACTURE_FICHE_EXIST_RESET: "FACTURE_FICHE_EXIST_RESET",


};
