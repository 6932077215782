import React, { lazy } from "react"
import * as MyPrivateRouters from "../routes/PrivateRouter";
import { Switch } from 'react-router-dom';


const LaboratoireDemandesExamens = lazy(() =>
    import("../../components/laboratoire/demandesExamens/LaboratoireDemandesExamens")
);
const LaboratoireResultatsExamens = lazy(() =>
import(
    "../../components/laboratoire/resultatsExamens/LaboratoireResultatsExamens"
)
);
const LayoutLaboratoireCreateResultatsExamens = lazy(() =>
import(
    "../../components/laboratoire/resultatsExamensCreate/LayoutLaboratoireCreateResultatsExamens"
)
);
const LayoutLaboratoireVoirResultatsExamens = lazy(() =>
import(
    "../../components/laboratoire/resultatsExamensVoir/LayoutLaboratoireVoirResultatsExamens"
)
);

const LayoutLaboratoireRapport = lazy(() =>
import(
    "../../components/laboratoire/rapports/LayoutLaboratoireRapport"
)
);
  

export default function LaboratoireRouter(){
  return (
    <Switch>
        <MyPrivateRouters.PrivateRouterLaboratoire
        path="/laboratoire"
        component={LaboratoireDemandesExamens}
        />

        <MyPrivateRouters.PrivateRouterLaboratoire
        path="/laboratoire-resultats"
        component={LaboratoireResultatsExamens}
        />

        <MyPrivateRouters.PrivateRouterLaboratoire
        path="/laboratoire-enregistrement-resultat/:id"
        component={LayoutLaboratoireCreateResultatsExamens}
        />

        <MyPrivateRouters.PrivateRouterLaboratoire
        path="/laboratoire-resultat-voir/:id"
        component={LayoutLaboratoireVoirResultatsExamens}
        />

        <MyPrivateRouters.PrivateRouterLaboratoire
        path="/laboratoire-rapports"
        component={LayoutLaboratoireRapport}
        />
    </Switch>
  )
}
