export const rdvTypes = {
  RDV_LIST_REQUEST: "RDV_LIST_REQUEST",
  RDV_LIST_SUCCESS: "RDV_LIST_SUCCESS",
  RDV_LIST_FAIL: "RDV_LIST_FAIL",

  RDV_LIST_RAFRAICHIR_REQUEST: "RDV_LIST_RAFRAICHIR_REQUEST",
  RDV_LIST_RAFRAICHIR_SUCCESS: "RDV_LIST_RAFRAICHIR_SUCCESS",
  RDV_LIST_RAFRAICHIR_FAIL: "RDV_LIST_RAFRAICHIR_FAIL",

  RDV_DELETE_REQUEST: "RDV_DELETE_REQUEST",
  RDV_DELETE_SUCCESS: "RDV_DELETE_SUCCESS",
  RDV_DELETE_FAIL: "RDV_DELETE_FAIL",
  RDV_POST_REQUEST: "RDV_POST_REQUEST",
  RDV_POST_SUCCESS: "RDV_POST_SUCCESS",
  RDV_POST_FAIL: "RDV_POST_FAIL",
  RDV_END_REQUEST: "RDV_END_REQUEST",
  RDV_END_SUCCESS: "RDV_END_SUCCESS",
  RDV_END_FAIL: "RDV_END_FAIL",
  RDV_END_WARNING: "RDV_END_WARNING",
  RDV_PATIENT_REQUEST: "RDV_PATIENT_REQUEST",
  RDV_PATIENT_SUCCESS: "RDV_PATIENT_SUCCESS",
  RDV_PATIENT_FAIL: "RDV_PATIENT_FAIL",

  RDV_LIST_REFRESH_REQUEST: "RDV_LIST_REFRESH_REQUEST",
  RDV_LIST_REFRESH_SUCCESS: "RDV_LIST_REFRESH_SUCCESS",
  RDV_LIST_REFRESH_FAIL: "RDV_LIST_REFRESH_FAIL",

  CLEAR_RDV_STATE: "CLEAR_RDV_STATE",
};
