import {motifsCentreTypes} from "../../types/motifsCentreTypes"
import { AUTH_ERROR, LOGOUT_SUCCESS, TOKEN_REFRESH_FAIL } from "../../types/userTypes"

// import jwtDecode from "jwt-decode"

const initialState = {

    motifsData: [],
    isLoadings: false,

    motifsCentres: [],
    isLoadingList: false,
    isLoadingListRefresh: false,

    motifsCentresParAssurances: [],
    isLoadingParAssurances: false,

    motifCentre: {},
    isLoadingPost: false,
    isSuccessPost: false,

    isLoadingPut: false,
    isSuccessPut: false,

    isLoadingDelete: false,
    isSuccessDelete: false,

    motifCentreGet: {},
    isLoadingGet: false,
    isSuccessGet: false,

    isLoadingListByService: false,
    motifsCentresByService: [],

    isErrorGet: true,
    isErrorPost: false,
    isErrorPut: false,
    isErrorDelete: false,
    isErrorImpossibleDelete: false,
    error: ""
}

// eslint-disable-next-line
export default function(state = initialState, action){
switch (action.type) {
    case motifsCentreTypes.MOTIFS_LIST_LOADING:
        return {
            ...state,
            isLoadings: true
        }
    case motifsCentreTypes.MOTIFS_LIST_SUCCESS:
        return {
            ...state,
            isLoadings: false,
            motifsData: action.payload
        }
    case motifsCentreTypes.MOTIFS_LIST_FAILED:
        return {
            ...state,
            isLoadings: false,     
        }

    case motifsCentreTypes.MOTIFS_CENTRE_LIST_LOADING:
        return {
            ...state,
            isLoadingList: true
        }
    case motifsCentreTypes.MOTIFS_CENTRE_LIST_SUCCESS:
        return {
            ...state,
            isLoadingList: false,
            motifsCentres: action.payload
        }
    case motifsCentreTypes.MOTIFS_CENTRE_LIST_FAILED:
        return {
            ...state,
            isLoadingList: false,        
        }


    case motifsCentreTypes.MOTIFS_CENTRE_LIST_PAR_ASSURANCE_LOADING:
        return {
            ...state,
            isLoadingParAssurances: true
        }
    case motifsCentreTypes.MOTIFS_CENTRE_LIST_PAR_ASSURANCE_SUCCESS:
        return {
            ...state,
            isLoadingParAssurances: false,
            motifsCentresParAssurances: action.payload
        }
    case motifsCentreTypes.MOTIFS_CENTRE_LIST_PAR_ASSURANCE_FAILED:
        return {
            ...state,
            isLoadingParAssurances: false,        
        }
        

    
    case motifsCentreTypes.MOTIFS_CENTRE_LIST_BY_SERVICE_LOADING:
        return {
            ...state,
            isLoadingListByService: true,
            motifsCentresByService: []
        }
    case motifsCentreTypes.MOTIFS_CENTRE_LIST_BY_SERVICE_SUCCESS:
        return {
            ...state,
            isLoadingListByService: false,
            motifsCentresByService: action.payload
        }
    case motifsCentreTypes.MOTIFS_CENTRE_LIST_BY_SERVICE_FAILED:
        return {
            ...state,
            isLoadingListByService: false,        
        }

    
    case motifsCentreTypes.MOTIFS_CENTRE_LIST_REFRESH_LOADING:
        return {
            ...state,
            isLoadingListRefresh: true
        }
    case motifsCentreTypes.MOTIFS_CENTRE_LIST_REFRESH_SUCCESS:
        return {
            ...state,
            isLoadingListRefresh: false,
            motifsCentres: action.payload
        }
    case motifsCentreTypes.MOTIFS_CENTRE_LIST_REFRESH_FAILED:
        return {
            ...state,
            isLoadingListRefresh: false,
            motifsCentres: []       
        }
    
    
    case motifsCentreTypes.MOTIF_CENTRE_GET_LOADING:
        return {
            ...state,
            isLoadingGet: true,
            isSuccessGet: false,
            isErrorGet: false,
            error: ""
        }
    case motifsCentreTypes.MOTIF_CENTRE_GET_SUCCESS:
        return {
            ...state,
            isLoadingGet: false,
            isSuccessGet: true,
            isErrorGet: false,
            error: "",
            motifCentreGet: action.payload
        }
    case motifsCentreTypes.MOTIF_CENTRE_GET_FAILED:
        return {
            ...state,
            isLoadingGet: false,
            isSuccessGet: false,
            isErrorGet: true,
            error: action.payload,
            motifCentreGet: []       
        }
    

    case motifsCentreTypes.MOTIF_CENTRE_POST_LOADING:
        return {
            ...state,
            isLoadingPost: true,
            isSuccessPost: false,
            isErrorPost: false,
            error: ""
        }
    case motifsCentreTypes.MOTIF_CENTRE_POST_SUCCESS:
        return {
            ...state,
            isLoadingPost: false,
            isSuccessPost: true,
            isErrorPost: false,
            error: "",
            motifCentre: action.payload
        }
    case motifsCentreTypes.MOTIF_CENTRE_POST_FAILED:
        return {
            ...state,
            isLoadingPost: false,
            isSuccessPost: false,
            isErrorPost: true,
            error: action.payload,
            motifCentre: {}       
        }
    
    
    case motifsCentreTypes.MOTIF_CENTRE_UPDATE_LOADING:
        return {
            ...state,
            isLoadingPut: true,
            isSuccessPut: false,
            isErrorPut: false,
            error: ""
        }
    case motifsCentreTypes.MOTIF_CENTRE_UPDATE_SUCCESS:
        return {
            ...state,
            isLoadingPut: false,
            isSuccessPut: true,
            isErrorPut: false,
            error: "",
            motifCentre: action.payload
        }
    case motifsCentreTypes.MOTIF_CENTRE_UPDATE_FAILED:
        return {
            ...state,
            isLoadingPut: false,
            isSuccessPut: false,
            isErrorPut: true,
            error: action.payload,
            motifCentre: {}       
        }

    
    case motifsCentreTypes.MOTIF_CENTRE_DELETE_LOADING:
        return {
            ...state,
            isLoadingDelete: true,
            isSuccessDelete: false,
            isErrorDelete: false,
            isErrorImpossibleDelete: false,
            error: ""
        }
    case motifsCentreTypes.MOTIF_CENTRE_DELETE_SUCCESS:
        return {
            ...state,
            isLoadingDelete: false,
            isSuccessDelete: true,
            isErrorDelete: false,
            isErrorImpossibleDelete: false,
            error: "",
            motifsCentres: state.motifsCentres.filter(data => data?.id !== action.payload)
        }
    case motifsCentreTypes.MOTIF_CENTRE_DELETE_IMPOSSIBLE:
        return {
            ...state,
            isLoadingDelete: false,
            isSuccessDelete: false,
            isErrorDelete: false,
            isErrorImpossibleDelete: true,
            error: action.payload    
        }
    case motifsCentreTypes.MOTIF_CENTRE_DELETE_FAILED:
        return {
            ...state,
            isLoadingDelete: false,
            isSuccessDelete: false,
            isErrorDelete: true,
            isErrorImpossibleDelete: false,
            error: action.payload    
        }
    
    
    case motifsCentreTypes.MOTIF_CENTRE_CLEAR_STATE:
        return {
            ...state,
            isLoadingList: false,
            isLoadingListRefresh: false,

            motifCentre: {},
            isLoadingPost: false,
            isSuccessPost: false,

            isLoadingPut: false,
            isSuccessPut: false,

            isLoadingDelete: false,
            isSuccessDelete: false,

            motifCentreGet: {},
            isLoadingGet: false,
            isSuccessGet: false,

            isLoadingListByService: false,
            motifsCentresByService: [],

            isErrorGet: true,
            isErrorPost: false,
            isErrorPut: false,
            isErrorDelete: false,
            isErrorImpossibleDelete: false,
            error: ""  
        }

    

    case AUTH_ERROR:
    case LOGOUT_SUCCESS:
    case TOKEN_REFRESH_FAIL:
        localStorage.removeItem('token_access')
        localStorage.removeItem('token_refresh')
        return {
            ...state,
            motifsData: [],
            isLoadings: false,

            motifsCentres: [],
            isLoadingList: false,
            isLoadingListRefresh: false,

            motifsCentresParAssurances: [],
            isLoadingParAssurances: false,

            isLoadingListByService: false,
            motifsCentresByService: [],

            motifCentre: {},
            isLoadingPost: false,
            isSuccessPost: false,

            isLoadingPut: false,
            isSuccessPut: false,

            isLoadingDelete: false,
            isSuccessDelete: false,

            motifCentreGet: {},
            isLoadingGet: false,
            isSuccessGet: false,

            isErrorGet: true,
            isErrorPost: false,
            isErrorPut: false,
            isErrorDelete: false,
            isErrorImpossibleDelete: false,
            error: ""
        }

    default:
        return state
}
}


