export const PARAMETRE_ACTE_LABO_GETS_LOADING = "PARAMETRE_ACTE_LABO_GETS_LOADING"

export const PARAMETRE_ACTE_LABO_GETS_SUCCESS = "PARAMETRE_ACTE_LABO_GETS_SUCCESS"

export const PARAMETRE_ACTE_LABO_GETS_FAILED = "PARAMETRE_ACTE_LABO_GETS_FAILED"



export const PARAMETRE_ACTE_LABO_GET_LOADING = "PARAMETRE_ACTE_LABO_GET_LOADING"

export const PARAMETRE_ACTE_LABO_GET_SUCCESS = "PARAMETRE_ACTE_LABO_GET_SUCCESS"

export const PARAMETRE_ACTE_LABO_GET_FAILED = "PARAMETRE_ACTE_LABO_GET_FAILED"



export const PARAMETRE_ACTE_LABO_POST_LOADING = "PARAMETRE_ACTE_LABO_POST_LOADING"

export const PARAMETRE_ACTE_LABO_POST_SUCCESS = "PARAMETRE_ACTE_LABO_POST_SUCCESS"

export const PARAMETRE_ACTE_LABO_POST_FAILED = "PARAMETRE_ACTE_LABO_POST_FAILED"



export const PARAMETRE_ACTE_LABO_PUT_LOADING = "PARAMETRE_ACTE_LABO_PUT_LOADING"

export const PARAMETRE_ACTE_LABO_PUT_SUCCESS = "PARAMETRE_ACTE_LABO_PUT_SUCCESS"

export const PARAMETRE_ACTE_LABO_PUT_FAILED = "PARAMETRE_ACTE_LABO_PUT_FAILED"



export const PARAMETRE_ACTE_LABO_CLEAR_STATE = "PARAMETRE_ACTE_LABO_CLEAR_STATE"