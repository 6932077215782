export const patientAConsulterPartogrammesTypes = {
    
  
    GET_PATIENTE_PARTOGRAMME_LIST_A_CONSULTER_REQUEST : "GET_PATIENTE_PARTOGRAMME_LIST_A_CONSULTER_REQUEST",
    GET_PATIENTE_PARTOGRAMME_LIST_A_CONSULTER_SUCCEDED : "GET_PATIENTE_PARTOGRAMME_LIST_A_CONSULTER_SUCCEDED",
    GET_PATIENTE_PARTOGRAMME_LIST_A_CONSULTER_FAILED : "GET_PATIENTE_PARTOGRAMME_LIST_A_CONSULTER_FAILED",


    GET_PATIENTE_PARTOGRAMME_LIST_PROFESSIONELS_CENTRE_REQUEST : "GET_PATIENTE_PARTOGRAMME_LIST_PROFESSIONELS_CENTRE_REQUEST",
    GET_PATIENTE_PARTOGRAMME_LIST_PROFESSIONELS_CENTRE_SUCCEDED : "GET_PATIENTE_PARTOGRAMME_LIST_PROFESSIONELS_CENTRE_SUCCEDED",
    GET_PATIENTE_PARTOGRAMME_LIST_PROFESSIONELS_CENTRE_FAILED : "GET_PATIENTE_PARTOGRAMME_LIST_PROFESSIONELS_CENTRE_FAILED",
    

    GET_PATIENTE_PARTOGRAMME_INFO_PATIENT_REQUEST : "GET_PATIENTE_PARTOGRAMME_INFO_PATIENT_REQUEST",
    GET_PATIENTE_PARTOGRAMME_INFO_PATIENT_SUCCEDED : "GET_PATIENTE_PARTOGRAMME_INFO_PATIENT_SUCCEDED",
    GET_PATIENTE_PARTOGRAMME_INFO_PATIENT_FAILED : "GET_PATIENTE_PARTOGRAMME_INFO_PATIENT_FAILED",


    POST_PATIENTE_PARTOGRAMME_LIST_PROFESSIONELS_CENTRE_REQUEST : "POST_PATIENTE_PARTOGRAMME_LIST_PROFESSIONELS_CENTRE_REQUEST",
    POST_PATIENTE_PARTOGRAMME_LIST_PROFESSIONELS_CENTRE_SUCCEDED : "POST_PATIENTE_PARTOGRAMME_LIST_PROFESSIONELS_CENTRE_SUCCEDED",
    POST_PATIENTE_PARTOGRAMME_LIST_PROFESSIONELS_CENTRE_FAILED : "POST_PATIENTE_PARTOGRAMME_LIST_PROFESSIONELS_CENTRE_FAILED",


    PUT_CREATE_PARTOGRAMME_PATIENTE_REQUEST : "PUT_CREATE_PARTOGRAMME_PATIENTE_REQUEST",
    PUT_CREATE_PARTOGRAMME_PATIENTE_SUCCEDED : "PUT_CREATE_PARTOGRAMME_PATIENTE_SUCCEDED",
    PUT_CREATE_PARTOGRAMME_PATIENTE_FAILED : "PUT_CREATE_PARTOGRAMME_PATIENTE_FAILED",

    POST_CREATE_PARTOGRAMME_PATIENTE_REQUEST : "POST_CREATE_PARTOGRAMME_PATIENTE_REQUEST",
    POST_CREATE_PARTOGRAMME_PATIENTE_SUCCEDED : "POST_CREATE_PARTOGRAMME_PATIENTE_SUCCEDED",
    POST_CREATE_PARTOGRAMME_PATIENTE_FAILED : "POST_CREATE_PARTOGRAMME_PATIENTE_FAILED",


    GET_INFO_PARTOGRAMME_PATIENTE_REQUEST : "GET_INFO_PARTOGRAMME_PATIENTE_REQUEST",
    GET_INFO_PARTOGRAMME_PATIENTE_SUCCEDED : "GET_INFO_PARTOGRAMME_PATIENTE_SUCCEDED",
    GET_INFO_PARTOGRAMME_PATIENTE_FAILED : "GET_INFO_PARTOGRAMME_PATIENTE_FAILED",


    GET_PARTOGRAMME_PATIENTE_liste_Terminer_REQUEST : "GET_PARTOGRAMME_PATIENTE_liste_Terminer_REQUEST",
    GET_PARTOGRAMME_PATIENTE_liste_Terminer_SUCCEDED : "GET_PARTOGRAMME_PATIENTE_liste_Terminer_SUCCEDED",
    GET_PARTOGRAMME_PATIENTE_liste_Terminer_FAILED : "GET_PARTOGRAMME_PATIENTE_liste_Terminer_FAILED",


    PUT_PARTOGRAMME_TERMINER_REQUEST : "PUT_PARTOGRAMME_TERMINER_REQUEST",
    PUT_PARTOGRAMME_TERMINER_SUCCEDED : "PUT_PARTOGRAMME_TERMINER_SUCCEDED",
    PUT_PARTOGRAMME_TERMINER_FAILED : "PUT_PARTOGRAMME_TERMINER_FAILED"
    


    
  };
  