import * as lienParenteTypes from '../../types/lienParenteTypes';


const initialState = {
    data: [],
    isLoading: false,
    error:null
}

const executeGetDataSuccess = (state, action) => {
    return {
        ...state,
        data: action.data,
        isLoading:false,
        error: null
    }
}
const executeGetDataFailed = (state, action) => {
    return {
        ...state,
        data: action.data,
        isLoading:false,
        error: action
    }
}
const executeGetDataLoading = (state, action) => {
    return {
        ...state,
        isLoading:true,
        error: null
    }
}

const reducer = (state = initialState, action) => {

    switch (action.type) {
        case lienParenteTypes.LIEN_PARENTE_GET_DATA_SUCCESS:
            return executeGetDataSuccess(state, action);

        case lienParenteTypes.LIEN_PARENTE_GET_DATA_LOADING:
            return executeGetDataLoading(state, action);

        case lienParenteTypes.LIEN_PARENTE_GET_DATA_FAILED:
            return executeGetDataFailed(state, action);
        default:
            return state;
    }
}

export default reducer;