import React, { lazy } from "react"
import * as MyPrivateRouters from "../routes/PrivateRouter";
import { Switch } from 'react-router-dom';


const RapportAssurances = lazy(() =>
    import("../../components/rapports/assurances/RapportAssurances")
);

const RapportAssurancesCmu = lazy(() =>
    import("../../components/rapports/assurancesCmu/RapportAssurancesCmu")
);

const RapportAssurancesAll = lazy(() =>
  import("../../components/rapports/assurancesAll/RapportAssurancesAll")
);

const RapportFIchePaiements = lazy(() =>
    import("../../components/rapports/fichePaiements/RapportFichePaiements")
);


const RapportActivites = lazy(() =>
    import("../../components/rapports/activites/RapportActivites")
);

const RapportActivitesESPC = lazy(() =>
    import("../../components/rapports/activitesESPC/RapportActivitesESPC")
);

const RapportMaladies = lazy(() =>
    import("../../components/rapports/maladies/RapportMaladies")
);

const RapportCaisses = lazy(() =>
    import("../../components/rapports/caisses/RapportCaisses")
);

const RapportDepistageCPNMaternites = lazy(() =>
    import("../../components/rapports/depistageCPNMaternite/RapportDepistageCPNMaternites")
);



const RapportCasSociaux = lazy(() =>
    import("../../components/rapports/casSociaux/RapportCasSociaux")
);

const RapportTarifsReduits = lazy(() =>
    import("../../components/rapports/tarifsReduits/RapportTarifsReduits")
);

const RapportGratuitesCiblees = lazy(() =>
    import("../../components/rapports/gratuitesCiblees/RapportGratuitesCiblees")
);

const RapportCentres = lazy(() =>
    import("../../components/rapports/centres/RapportCentres")
);
//eslint-disable-next-line
const RapportAccouchements = lazy(() =>
    import("../../components/rapports/accouchements/RapportAccouchements")
);

const RapportServices = lazy(() =>
    import("../../components/rapports/services/RapportServices")
);

const RapportPathologies = lazy(() =>
    import("../../components/rapports/motifs/RapportPathologies")
);

const RapportPatients = lazy(() =>
    import("../../components/rapports/patients/RapportPatients")
);



const RapportDeces = lazy(() =>
    import("../../components/rapports/deces/RapportDeces")
);

const RapportReferencements = lazy(() =>
    import("../../components/rapports/referencements/RapportReferencements")
);


//eslint-disable-next-line
const PatientsReportLayout = lazy(() =>
    import("../../components/rapports/patients/PatientsReportLayout")
);
//eslint-disable-next-line
const DpiPatientReportLayout = lazy(() =>
    import("../../components/rapports/patients/DpiPatientReportLayout")
);
//eslint-disable-next-line
const GenerateNewReportHome = lazy(() =>
    import("../../components/rapports/patients/GenerateNewReportHome")
);
//eslint-disable-next-line
const IndicateurTraitementsReport = lazy(() =>
    import(
        "../../components/rapports/indicateurTraitement/RapportIndicateurTraitements"
    )
);
//eslint-disable-next-line
const PrestationsFinaniceres = lazy(() =>
    import("../../components/rapports/prestationsFinancieres/PrestationsFinaniceres")
);
//eslint-disable-next-line
const RapportProfessionnel = lazy(() =>
    import("../../components/rapports/professionnels/RapportProfessionnel")
);

//eslint-disable-next-line
const ReportProfessionnel = lazy(() =>
    import("../../components/rapports/professionnel/RapportProfessionnels")
);

//eslint-disable-next-line
const DetailsReportProfessionnels = lazy(() =>
    import("../../components/rapports/professionnels/DetailsReportProfessionnels")
);

const RedevancePercuesRapportCaisse = lazy(() =>
    import("../../components/caisse/rapports/RedevancePercuesRapportCaisse")
);

//eslint-disable-next-line
const RapportsSIGMain = lazy(() =>
    import("../../components/rapports/rapportMensuel/RapportsSIGMain")
);
const RapportSIGMainContainer = lazy(() =>
    import("../../components/rapports/rapportsSIG/RapportSIGMainContainer")
);
;

const RapportRedevancesPercues = lazy(() =>
    import("../../components/rapports/redevancesPercues/RapportRedevancesPercues")
);

const RapportsSynthese = lazy(() =>
    import("../../components/rapports/synthese/RapportSyntheses")
);

const RapportBilanCaisseAgregee = lazy(() =>
    import("../../components/rapports/bilanCaisseAgregee/RapportBilanCaisseAgregee")
);

const RapportCaissesPeriodesExercice = lazy(() =>
    import("../../components/rapports/caissesPeriodeExercice/RapportCaissesPeriodesExercice")
);


const RapportNutritionnels = lazy(() =>
    import("../../components/rapports/nutritionnel/RapportNutritionnels")
);

const RapportLaboratoireMain = lazy(() => 
        import("../../components/rapports/rapportLaboratoire/RapportLaboratoireMain")
)

const RapportRadiologieMain = lazy(() => 
    import("../../components/rapports/rapportRadiologie/RapportRadiologieMain")
)

const RapportEchographieMain = lazy(() => 
    import("../../components/rapports/rapportEchographie/RapportEchographieMain")
)

const RapportControleMajorMain = lazy(() => 
    import("../../components/rapports/rapportControleMajorSpecialites/RapportControleMajorMain")
)

const RapportActivitesConsultationsSpecialites = lazy(() =>
  import("../../components/rapports/activitesConsultations/RapportActivitesConsultations")
);

const RapportExamensTDRMain = lazy(() => 
    import("../../components/rapports/rapportTDR/RapportExamensTDRMain")
)


export default function RapportsRouter() {
  return (
    <Switch>
      <MyPrivateRouters.PrivateRouterRapportsMensuels
        path="/rapports-fiches-paiements"
        exact
        component={RapportFIchePaiements}
      />

      <MyPrivateRouters.PrivateRouterRapportsMensuels
        path="/rapports"
        exact
        component={RapportActivites}
      />
      <MyPrivateRouters.PrivateRouterRapportsMensuels
        path="/rapports-activites-consultations"
        exact
        component={RapportActivitesConsultationsSpecialites}
      />

      <MyPrivateRouters.PrivateRouterRapportsMensuels
        path="/rapports-activites-espc"
        exact
        component={RapportActivitesESPC}
      />

      <MyPrivateRouters.PrivateRouterRapportsMensuels
        path="/rapports-caisses"
        exact
        component={RapportCaisses}
      />

      <MyPrivateRouters.PrivateRouterRapportsMensuels
        path="/rapports-depistage-maternite"
        exact
        component={RapportDepistageCPNMaternites}
      />

      <MyPrivateRouters.PrivateRouterRapportsMensuels
        path="/rapports-assurances-all"
        exact
        component={RapportAssurancesAll}
      />

      <MyPrivateRouters.PrivateRouterRapportsMensuels
        path="/rapports-assurances"
        exact
        component={RapportAssurances}
      />
      <MyPrivateRouters.PrivateRouterRapportsMensuels
        path="/rapports-assurances-cmu"
        exact
        component={RapportAssurancesCmu}
      />

      <MyPrivateRouters.PrivateRouterRapportsMensuels
        path="/rapports-cas-sociaux"
        exact
        component={RapportCasSociaux}
      />
      <MyPrivateRouters.PrivateRouterRapportsMensuels
        path="/rapports-tarifs-reduits"
        exact
        component={RapportTarifsReduits}
      />
      <MyPrivateRouters.PrivateRouterRapportsMensuels
        path="/rapports-gratuites-ciblees"
        exact
        component={RapportGratuitesCiblees}
      />
      <MyPrivateRouters.PrivateRouterRapportsMensuels
        path="/rapports-centres"
        component={RapportCentres}
      />

      <MyPrivateRouters.PrivateRouterRapportsMensuels
        path="/rapports-services"
        component={RapportServices}
      />
      <MyPrivateRouters.PrivateRouterRapportsMensuels
        path="/rapports-maladies"
        component={RapportMaladies}
      />

      <MyPrivateRouters.PrivateRouterRapportsMensuels
        path="/rapports-motifs"
        component={RapportPathologies}
      />
      <MyPrivateRouters.PrivateRouterRapportsMensuels
        path="/rapports-patients"
        component={RapportPatients}
      />
      <MyPrivateRouters.PrivateRouterRapportsMensuels
        path="/rapports-deces"
        component={RapportDeces}
      />
      <MyPrivateRouters.PrivateRouterRapportsMensuels
        path="/rapports-referencements"
        component={RapportReferencements}
      />
      <MyPrivateRouters.PrivateRouterRapportsMensuels
        path="/rapports-professionnels"
        exact
        component={RapportProfessionnel}
      />

      <MyPrivateRouters.PrivateRouterRapportsMensuels
        path="/rapports-professionnels-details"
        exact
        component={DetailsReportProfessionnels}
      />
      <MyPrivateRouters.PrivateRouterRapportsMensuels
        path="/rapports-professionnel"
        exact
        component={ReportProfessionnel}
      />

      <MyPrivateRouters.PrivateRouterRapportsMensuels
        path="/rapports-redevances-percues"
        exact
        component={RapportRedevancesPercues}
      />

      <MyPrivateRouters.PrivateRouterRapportsMensuels
        path="/rapports-synthese"
        exact
        component={RapportsSynthese}
      />

      <MyPrivateRouters.PrivateRouterRapportsMensuels
        path="/rapports-bilan-caisse-agrege"
        exact
        component={RapportBilanCaisseAgregee}
      />

      <MyPrivateRouters.PrivateRouterRapportsMensuels
        path="/rapports-sig"
        exact
        component={RapportsSIGMain}
      />

      <MyPrivateRouters.PrivateRouterRapportsMensuels
        path="/rapports-redevances-percues"
        component={RedevancePercuesRapportCaisse}
      />

      <MyPrivateRouters.PrivateRouterRapportsMensuels
        path="/rapports-nutrition"
        exact
        component={RapportNutritionnels}
      />

      <MyPrivateRouters.PrivateRouterRapportsMensuels
        path="/rapports-laboratoire"
        exact
        component={RapportLaboratoireMain}
      />

      <MyPrivateRouters.PrivateRouterRapportsMensuels
        path="/rapports-radiologie"
        exact
        component={RapportRadiologieMain}
      />

      <MyPrivateRouters.PrivateRouterRapportsMensuels
        path="/rapports-echographie"
        exact
        component={RapportEchographieMain}
      />

      <MyPrivateRouters.PrivateRouterRapportsMensuels
        path="/rapports-controle-major"
        exact
        component={RapportControleMajorMain}
      />

      <MyPrivateRouters.PrivateRouterRapportsMensuels
        path="/rapports-sig-new"
        exact
        component={RapportSIGMainContainer}
      />

      <MyPrivateRouters.PrivateRouterRapportsMensuels
        path="/rapports-caisse-periode-exercice"
        component={RapportCaissesPeriodesExercice}
      />

      <MyPrivateRouters.PrivateRouterRapportsMensuels
        path="/rapports-tdr"
        exact
        component={RapportExamensTDRMain}
        />
    </Switch>
  );
}
