export const EXAMENS_GET_DATA_LOADING = 'EXAMENS_GET_DATA_LOADING';
export const EXAMENS_GET_DATA_SUCCESS = 'EXAMENS_GET_DATA_SUCCESS';
export const EXAMENS_GET_DATA_FAIL = 'EXAMENS_GET_DATA_FAIL';

export const EXAMENS_DELETE_DATA_LOADING = 'EXAMENS_DELETE_DATA_LOADING';
export const EXAMENS_DELETE_DATA_SUCCESS = 'EXAMENS_DELETE_DATA_SUCCESS';
export const EXAMENS_DELETE_DATA_FAIL = 'EXAMENS_DELETE_DATA_FAIL';

export const EXAMENS_LIST_DATA_LOADING = 'EXAMENS_LIST_DATA_LOADING';
export const EXAMENS_LIST_DATA_SUCCESS = 'EXAMENS_LIST_DATA_SUCCESS';
export const EXAMENS_LIST_DATA_FAIL = 'EXAMENS_LIST_DATA_FAIL';

export const EXAMENS_LIST_DATA_REFRESH_LOADING = 'EXAMENS_LIST_DATA_REFRESH_LOADING';
export const EXAMENS_LIST_DATA_REFRESH_SUCCESS = 'EXAMENS_LIST_DATA_REFRESH_SUCCESS';
export const EXAMENS_LIST_DATA_REFRESH_FAIL = 'EXAMENS_LIST_DATA_REFRESH_FAIL';

export const DONNEE_DEMANDE_EXAMEN_GET_DATA_LOADING = 'DONNEE_DEMANDE_EXAMEN_GET_DATA_LOADING';
export const DONNEE_DEMANDE_EXAMEN_GET_DATA_SUCCESS = 'DONNEE_DEMANDE_EXAMEN_GET_DATA_SUCCESS';
export const DONNEE_DEMANDE_EXAMEN_GET_DATA_FAILED = 'DONNEE_DEMANDE_EXAMEN_GET_DATA_FAILED';


export const STATE_DEMANDE_EXAMEN_RESET = 'STATE_DEMANDE_EXAMEN_RESET';

export const PATIENT_ALL_EXAMENS_REQUEST = "PATIENT_ALL_EXAMENS_REQUEST";
export const PATIENT_ALL_EXAMENS_SUCCESS = "PATIENT_ALL_EXAMENS_SUCCESS";
export const PATIENT_ALL_EXAMENS_FAIL = "PATIENT_ALL_EXAMENS_FAIL";

export const EXAMENS_RESULTAT_REQUEST = "EXAMENS_RESULTAT_REQUEST";
export const EXAMENS_RESULTAT_SUCCESS = "EXAMENS_RESULTAT_SUCCESS";
export const EXAMENS_RESULTAT_ERROR = "EXAMENS_RESULTAT_ERROR";