export const suiteCoucheTypes = {
    GET_INFO_SUITE_COUCHE_COMPLICATION_REQUEST: "GET_INFO_SUITE_COUCHE_COMPLICATION_REQUEST",
    GET_INFO_SUITE_COUCHE_COMPLICATION_SUCCESS: "GET_INFO_SUITE_COUCHE_COMPLICATION_SUCCESS",
    GET_INFO_SUITE_COUCHE_COMPLICATION_FAIL: "GET_INFO_SUITE_COUCHE_COMPLICATION_FAIL",

    GET_CONSULTATION_SUITE_COUCHE_COMPLICATION_REQUEST: "GET_CONSULTATION_SUITE_COUCHE_COMPLICATION_REQUEST",
    GET_CONSULTATION_SUITE_COUCHE_COMPLICATION_SUCCESS: "GET_CONSULTATION_SUITE_COUCHE_COMPLICATION_SUCCESS",
    GET_CONSULTATION_SUITE_COUCHE_COMPLICATION_FAIL: "GET_CONSULTATION_SUITE_COUCHE_COMPLICATION_FAIL",

    POST_SUITE_COUCHE_COMPLICATION_REQUEST: "POST_SUITE_COUCHE_COMPLICATION_REQUEST",
    POST_SUITE_COUCHE_COMPLICATION_SUCCESS: "POST_SUITE_COUCHE_COMPLICATION_SUCCESS",
    POST_SUITE_COUCHE_COMPLICATION_BAD_REQUEST: "POST_SUITE_COUCHE_COMPLICATION_BAD_REQUEST",
    POST_SUITE_COUCHE_COMPLICATION_SERVER_ERROR: "POST_SUITE_COUCHE_COMPLICATION_SERVER_ERROR",
    POST_SUITE_COUCHE_COMPLICATION_SERVER_UNAVAILABLE: "POST_SUITE_COUCHE_COMPLICATION_SERVER_UNAVAILABLE",

    CLEAR_POST_SUCCESS_POPUP: "CLEAR_POST_SUCCESS_POPUP",
    CLEAR_POST_ERROR_POPUP: "CLEAR_POST_ERROR_POPUP",
}