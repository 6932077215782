import React, { Component } from 'react'
import './test.css'

export class TestLoadingPage extends Component {
  render() {
    return (
        <div className="cssload-container">
            <div className="cssload-speeding-wheel"></div>
        </div>
    )
  }
}

export default TestLoadingPage
