export const utilisateursTypes = {
  UTILISATEUR_POST_REQUEST: "UTILISATEUR_POST_REQUEST",
  UTILISATEUR_POST_SUCCESS: "UTILISATEUR_POST_SUCCESS",
  UTILISATEUR_POST_FAILED: "UTILISATEUR_POST_FAILED",
  UTILISATEUR_POST_FAILED_400: "UTILISATEUR_POST_FAILED_400",
  UTILISATEUR_POST_FAILED_500: "UTILISATEUR_POST_FAILED_500",

  UTILISATEUR_LIST_REQUEST: "UTILISATEUR_LIST_REQUEST",
  UTILISATEUR_LIST_SUCCESS: "UTILISATEUR_LIST_SUCCESS",
  UTILISATEUR_LIST_FAILED: "UTILISATEUR_LIST_SUCCESS",

  UTILISATEUR_LIST_REFRESH_REQUEST: "UTILISATEUR_LIST_REFRESH_REQUEST",
  UTILISATEUR_LIST_REFRESH_SUCCESS: "UTILISATEUR_LIST_REFRESH_SUCCESS",
  UTILISATEUR_LIST_REFRESH_FAILED: "UTILISATEUR_LIST_REFRESH_SUCCESS",

  UTILISATEUR_GET_REQUEST: "UTILISATEUR_GET_REQUEST",
  UTILISATEUR_GET_SUCCESS: "UTILISATEUR_GET_SUCCESS",
  UTILISATEUR_GET_FAILED: "UTILISATEUR_GET_SUCCESS",

  STAFFUSER_DETAIL_REQUEST: "STAFFUSER_DETAIL_REQUEST",
  STAFFUSER_DETAIL_SUCCESS: "STAFFUSER_DETAIL_SUCCESS",
  STAFFUSER_DETAIL_FAIL: "STAFFUSER_DETAIL_FAIL",

  UTILISATEUR_DELETE_REQUEST: "UTILISATEUR_DELETE_REQUEST",
  UTILISATEUR_DELETE_SUCCESS: "UTILISATEUR_DELETE_SUCCESS",
  UTILISATEUR_DELETE_FAILED: "UTILISATEUR_DELETE_FAILED",

  DIALOG_MESSAGE_CLOSE: "DIALOG_MESSAGE_CLOSE",

  UTILISATEUR_UPDATE_REQUEST: "UTILISATEUR_UPDATE_REQUEST",
  UTILISATEUR_UPDATE_SUCCESS: "UTILISATEUR_UPDATE_SUCCESS",
  UTILISATEUR_UPDATE_FAILED: "UTILISATEUR_UPDATE_FAILED",

  DESACTIVER_REQUEST: "DESACTIVER_REQUEST",
  DESACTIVER_SUCCESS: "DESACTIVER_SUCCESS",
  DESACTIVER_FAILED: "DESACTIVER_FAILED",
  DESACTIVER_CLOSE_MODAL: "DESACTIVER_CLOSE_MODAL",

  RESTAURER_REQUEST: "RESTAURER_REQUEST",
  RESTAURER_SUCCESS: "RESTAURER_SUCCESS",
  RESTAURER_FAILED: "RESTAURER_FAILED",
};
