export const PATIENT_A_CONSULTER_CHIRURGIE_GET_LOADING =  "PATIENT_A_CONSULTER_CHIRURGIE_GET_LOADING"

export const PATIENT_A_CONSULTER_CHIRURGIE_GET_SUCCESS =  "PATIENT_A_CONSULTER_CHIRURGIE_GET_SUCCESS"

export const PATIENT_A_CONSULTER_CHIRURGIE_GET_FAILED =  "PATIENT_A_CONSULTER_CHIRURGIE_GET_FAILED"


export const MALADIES_CHIRURGIE_GET_LOADING = 'MALADIES_CHIRURGIE_GET_LOADING'

export const MALADIES_CHIRURGIE_GET_SUCCESS = 'MALADIES_CHIRURGIE_GET_SUCCESS'

export const MALADIES_CHIRURGIE_GET_FAILED = 'MALADIES_CHIRURGIE_GET_FAILED'


export const ACTES_CHIRUGICAUX_CHIRURGIE_GET_LOADING = 'ACTES_CHIRUGICAUX_CHIRURGIE_GET_LOADING'

export const ACTES_CHIRUGICAUX_CHIRURGIE_GET_SUCCESS = 'ACTES_CHIRUGICAUX_CHIRURGIE_GET_SUCCESS'

export const ACTES_CHIRUGICAUX_CHIRURGIE_GET_FAILED = 'ACTES_CHIRUGICAUX_CHIRURGIE_GET_FAILED'


export const CONSULTATION_CHIRURGIE_GET_LOADING = 'CONSULTATION_CHIRURGIE_GET_LOADING'

export const CONSULTATION_CHIRURGIE_GET_SUCCESS = 'CONSULTATION_CHIRURGIE_GET_SUCCESS'

export const CONSULTATION_CHIRURGIE_GET_PAS_TROUVER = "CONSULTATION_CHIRURGIE_GET_PAS_TROUVER"

export const CONSULTATION_CHIRURGIE_GET_FAILED = 'CONSULTATION_CHIRURGIE_GET_FAILED'


export const CONSULTATION_CHIRURGIE_POST_LOADING = 'CONSULTATION_CHIRURGIE_POST_LOADING'

export const CONSULTATION_CHIRURGIE_POST_SUCCESS = 'CONSULTATION_CHIRURGIE_POST_SUCCESS'

export const CONSULTATION_CHIRURGIE_POST_FAILED = 'CONSULTATION_CHIRURGIE_POST_FAILED'


export const CONSULTATION_CHIRURGIE_POST_TERMINER_LOADING = 'CONSULTATION_CHIRURGIE_POST_TERMINER_LOADING'

export const CONSULTATION_CHIRURGIE_POST_TERMINER_SUCCESS = 'CONSULTATION_CHIRURGIE_POST_TERMINER_SUCCESS'

export const CONSULTATION_CHIRURGIE_POST_TERMINER_FAILED = 'CONSULTATION_CHIRURGIE_POST_TERMINER_FAILED'


export const ISSU_DEMANDE_HOSPITALISATION_CHIRURGIE_POST_LOADING = 'ISSU_DEMANDE_HOSPITALISATION_CHIRURGIE_POST_LOADING'

export const ISSU_DEMANDE_HOSPITALISATION_CHIRURGIE_POST_SUCCESS = 'ISSU_DEMANDE_HOSPITALISATION_CHIRURGIE_POST_SUCCESS'

export const ISSU_DEMANDE_HOSPITALISATION_CHIRURGIE_POST_FAILED = 'ISSU_DEMANDE_HOSPITALISATION_CHIRURGIE_POST_FAILED'


export const ISSU_ACTES_INFIRMIERS_CHIRURGIE_POST_LOADING = 'ISSU_ACTES_INFIRMIERS_CHIRURGIE_POST_LOADING'

export const ISSU_ACTES_INFIRMIERS_CHIRURGIE_POST_SUCCESS = 'ISSU_ACTES_INFIRMIERS_CHIRURGIE_POST_SUCCESS'

export const ISSU_ACTES_INFIRMIERS_CHIRURGIE_POST_FAILED = 'ISSU_ACTES_INFIRMIERS_CHIRURGIE_POST_FAILED'


export const REFERE_INTERNE_CHIRURGIE_POST_LOADING = 'REFERE_INTERNE_CHIRURGIE_POST_LOADING'

export const REFERE_INTERNE_CHIRURGIE_POST_SUCCESS = 'REFERE_INTERNE_CHIRURGIE_POST_SUCCESS'

export const REFERE_INTERNE_CHIRURGIE_POST_FAILED = 'REFERE_INTERNE_CHIRURGIE_POST_FAILED'


export const REFERE_EXTERNE_CHIRURGIE_POST_LOADING = 'REFERE_EXTERNE_CHIRURGIE_POST_LOADING'

export const REFERE_EXTERNE_CHIRURGIE_POST_SUCCESS = 'REFERE_EXTERNE_CHIRURGIE_POST_SUCCESS'

export const REFERE_EXTERNE_CHIRURGIE_POST_FAILED = 'REFERE_EXTERNE_CHIRURGIE_POST_FAILED'


export const ISSU_MISE_EN_OBSERVATION_CHIRURGIE_POST_LOADING = 'ISSU_MISE_EN_OBSERVATION_CHIRURGIE_POST_LOADING'

export const ISSU_MISE_EN_OBSERVATION_CHIRURGIE_POST_SUCCESS = 'ISSU_MISE_EN_OBSERVATION_CHIRURGIE_POST_SUCCESS'

export const ISSU_MISE_EN_OBSERVATION_CHIRURGIE_POST_FAILED = 'ISSU_MISE_EN_OBSERVATION_CHIRURGIE_POST_FAILED'


export const ISSU_PRESCRIPTIONS_CHIRURGIE_POST_LOADING = 'ISSU_PRESCRIPTIONS_CHIRURGIE_POST_LOADING'

export const ISSU_PRESCRIPTIONS_CHIRURGIE_POST_SUCCESS = 'ISSU_PRESCRIPTIONS_CHIRURGIE_POST_SUCCESS'

export const ISSU_PRESCRIPTIONS_CHIRURGIE_POST_FAILED = 'ISSU_PRESCRIPTIONS_CHIRURGIE_POST_FAILED'


export const ISSU_SORTIE_CONSULTATION_CHIRURGIE_POST_LOADING = 'ISSU_SORTIE_CONSULTATION_CHIRURGIE_POST_LOADING'

export const ISSU_SORTIE_CONSULTATION_CHIRURGIE_POST_SUCCESS = 'ISSU_SORTIE_CONSULTATION_CHIRURGIE_POST_SUCCESS'

export const ISSU_SORTIE_CONSULTATION_CHIRURGIE_POST_FAILED = 'ISSU_SORTIE_CONSULTATION_CHIRURGIE_POST_FAILED'


export const ISSU_SORTIE_CONSULTATION_DEMANDE_CHIRURGIE_CHIRURGIE_POST_LOADING = 'ISSU_SORTIE_CONSULTATION_DEMANDE_CHIRURGIE_CHIRURGIE_POST_LOADING'

export const ISSU_SORTIE_CONSULTATION_DEMANDE_CHIRURGIE_CHIRURGIE_POST_SUCCESS = 'ISSU_SORTIE_CONSULTATION_DEMANDE_CHIRURGIE_CHIRURGIE_POST_SUCCESS'

export const ISSU_SORTIE_CONSULTATION_DEMANDE_CHIRURGIE_CHIRURGIE_POST_FAILED = 'ISSU_SORTIE_CONSULTATION_DEMANDE_CHIRURGIE_CHIRURGIE_POST_FAILED'


export const TYPES_EXAMENS_CHIRURGIE_GET_LOADING = 'TYPES_EXAMENS_CHIRURGIE_GET_LOADING'

export const TYPES_EXAMENS_CHIRURGIE_GET_SUCCESS = 'TYPES_EXAMENS_CHIRURGIE_GET_SUCCESS'

export const TYPES_EXAMENS_CHIRURGIE_GET_FAILED = 'TYPES_EXAMENS_CHIRURGIE_GET_FAILED'


export const ORDONNANCES_CHIRURGIE_GET_LOADING = 'ORDONNANCES_CHIRURGIE_GET_LOADING'

export const ORDONNANCES_CHIRURGIE_GET_SUCCESS = 'ORDONNANCES_CHIRURGIE_GET_SUCCESS'

export const ORDONNANCES_CHIRURGIE_GET_FAILED = 'ORDONNANCES_CHIRURGIE_GET_FAILED'


export const ORDONNANCE_CHIRURGIE_GET_LOADING = 'ORDONNANCE_CHIRURGIE_GET_LOADING'

export const ORDONNANCE_CHIRURGIE_GET_SUCCESS = 'ORDONNANCE_CHIRURGIE_GET_SUCCESS'

export const ORDONNANCE_CHIRURGIE_GET_FAILED = 'ORDONNANCE_CHIRURGIE_GET_FAILED'


export const CONSULTATION_CHIRURGIE_RESET = 'CONSULTATION_CHIRURGIE_RESET'

export const CONSULTATION_CHIRURGIE_RESET_AFTER = "CONSULTATION_CHIRURGIE_RESET_AFTER"



//////////////////////////////////////////////////////////////////////////////////////////

export const DEMANDE_DE_CHIRURGIE_GETS_DATA_SUCCESS = 'DEMANDE_DE_CHIRURGIE_GETS_DATA_SUCCESS'

export const DEMANDE_DE_CHIRURGIE_GETS_DATA_FAILED = 'DEMANDE_DE_CHIRURGIE_GETS_DATA_FAILED'

export const DEMANDE_DE_CHIRURGIE_GETS_DATA_LOADING = 'DEMANDE_DE_CHIRURGIE_GETS_DATA_LOADING'


export const DEMANDE_DE_CHIRURGIE_REFRESH_GETS_DATA_SUCCESS = 'DEMANDE_DE_CHIRURGIE_REFRESH_GETS_DATA_SUCCESS'

export const DEMANDE_DE_CHIRURGIE_REFRESH_GETS_DATA_FAILED = 'DEMANDE_DE_CHIRURGIE_REFRESH_GETS_DATA_FAILED'

export const DEMANDE_DE_CHIRURGIE_REFRESH_GETS_DATA_LOADING = 'DEMANDE_DE_CHIRURGIE_REFRESH_GETS_DATA_LOADING'


export const DEMANDE_DE_CHIRURGIE_POST_DATA_SUCCESS = 'DEMANDE_DE_CHIRURGIE_POST_DATA_SUCCESS'

export const DEMANDE_DE_CHIRURGIE_POST_DATA_FAILED = 'DEMANDE_DE_CHIRURGIE_POST_DATA_FAILED'

export const DEMANDE_DE_CHIRURGIE_POST_DATA_LOADING = 'DEMANDE_DE_CHIRURGIE_POST_DATA_LOADING'


export const DEMANDE_DE_CHIRURGIE_GET_DATA_SUCCESS = 'DEMANDE_DE_CHIRURGIE_GET_DATA_SUCCESS'

export const DEMANDE_DE_CHIRURGIE_GET_DATA_FAILED = 'DEMANDE_DE_CHIRURGIE_GET_DATA_FAILED'

export const DEMANDE_DE_CHIRURGIE_GET_DATA_LOADING = 'DEMANDE_DE_CHIRURGIE_GET_DATA_LOADING'


export const REQUETE_OPERATOIRE_CHIRURGIE_GETS_DATA_SUCCESS = 'REQUETE_OPERATOIRE_CHIRURGIE_GETS_DATA_SUCCESS'

export const REQUETE_OPERATOIRE_CHIRURGIE_GETS_DATA_FAILED = 'REQUETE_OPERATOIRE_CHIRURGIE_GETS_DATA_FAILED'

export const REQUETE_OPERATOIRE_CHIRURGIE_GETS_DATA_LOADING = 'REQUETE_OPERATOIRE_CHIRURGIE_GETS_DATA_LOADING'


export const REQUETE_OPERATOIRE_CHIRURGIE_REFRESH_GETS_DATA_SUCCESS = 'REQUETE_OPERATOIRE_CHIRURGIE_REFRESH_GETS_DATA_SUCCESS'

export const REQUETE_OPERATOIRE_CHIRURGIE_REFRESH_GETS_DATA_FAILED = 'REQUETE_OPERATOIRE_CHIRURGIE_REFRESH_GETS_DATA_FAILED'

export const REQUETE_OPERATOIRE_CHIRURGIE_REFRESH_GETS_DATA_LOADING = 'REQUETE_OPERATOIRE_CHIRURGIE_REFRESH_GETS_DATA_LOADING'


export const REQUETE_OPERATOIRE_CHIRURGIE_POST_DATA_SUCCESS = 'REQUETE_OPERATOIRE_CHIRURGIE_POST_DATA_SUCCESS'

export const REQUETE_OPERATOIRE_CHIRURGIE_POST_DATA_FAILED = 'REQUETE_OPERATOIRE_CHIRURGIE_POST_DATA_FAILED'

export const REQUETE_OPERATOIRE_CHIRURGIE_POST_DATA_LOADING = 'REQUETE_OPERATOIRE_CHIRURGIE_POST_DATA_LOADING'


export const REQUETE_OPERATOIRE_CHIRURGIE_GET_DATA_SUCCESS = 'REQUETE_OPERATOIRE_CHIRURGIE_GET_DATA_SUCCESS'

export const REQUETE_OPERATOIRE_CHIRURGIE_GET_DATA_FAILED = 'REQUETE_OPERATOIRE_CHIRURGIE_GET_DATA_FAILED'

export const REQUETE_OPERATOIRE_CHIRURGIE_GET_DATA_LOADING = 'REQUETE_OPERATOIRE_CHIRURGIE_GET_DATA_LOADING'


export const INTERVENTION_PROGRAMMEE_CHIRURGIE_GETS_DATA_SUCCESS = 'INTERVENTION_PROGRAMMEE_CHIRURGIE_GETS_DATA_SUCCESS'

export const INTERVENTION_PROGRAMMEE_CHIRURGIE_GETS_DATA_FAILED = 'INTERVENTION_PROGRAMMEE_CHIRURGIE_GETS_DATA_FAILED'

export const INTERVENTION_PROGRAMMEE_CHIRURGIE_GETS_DATA_LOADING = 'INTERVENTION_PROGRAMMEE_CHIRURGIE_GETS_DATA_LOADING'


export const INTERVENTION_PROGRAMMEE_CHIRURGIE_REFRESH_GETS_DATA_SUCCESS = 'INTERVENTION_PROGRAMMEE_CHIRURGIE_REFRESH_GETS_DATA_SUCCESS'

export const INTERVENTION_PROGRAMMEE_CHIRURGIE_REFRESH_GETS_DATA_FAILED = 'INTERVENTION_PROGRAMMEE_CHIRURGIE_REFRESH_GETS_DATA_FAILED'

export const INTERVENTION_PROGRAMMEE_CHIRURGIE_REFRESH_GETS_DATA_LOADING = 'INTERVENTION_PROGRAMMEE_CHIRURGIE_REFRESH_GETS_DATA_LOADING'


export const INTERVENTION_PROGRAMMEE_CHIRURGIE_POST_DATA_SUCCESS = 'INTERVENTION_PROGRAMMEE_CHIRURGIE_POST_DATA_SUCCESS'

export const INTERVENTION_PROGRAMMEE_CHIRURGIE_POST_DATA_FAILED = 'INTERVENTION_PROGRAMMEE_CHIRURGIE_POST_DATA_FAILED'

export const INTERVENTION_PROGRAMMEE_CHIRURGIE_POST_DATA_LOADING = 'INTERVENTION_PROGRAMMEE_CHIRURGIE_POST_DATA_LOADING'


export const INTERVENTION_PROGRAMMEE_CHIRURGIE_GET_DATA_SUCCESS = 'INTERVENTION_PROGRAMMEE_CHIRURGIE_GET_DATA_SUCCESS'

export const INTERVENTION_PROGRAMMEE_CHIRURGIE_GET_DATA_FAILED = 'INTERVENTION_PROGRAMMEE_CHIRURGIE_GET_DATA_FAILED'

export const INTERVENTION_PROGRAMMEE_CHIRURGIE_GET_DATA_LOADING = 'INTERVENTION_PROGRAMMEE_CHIRURGIE_GET_DATA_LOADING'


export const INTERVENTION_PROGRAMMEE_LISTE_CHIRURGIE_GETS_DATA_SUCCESS = 'INTERVENTION_PROGRAMMEE_LISTE_CHIRURGIE_GETS_DATA_SUCCESS'

export const INTERVENTION_PROGRAMMEE_LISTE_CHIRURGIE_GETS_DATA_FAILED = 'INTERVENTION_PROGRAMMEE_LISTE_CHIRURGIE_GETS_DATA_FAILED'

export const INTERVENTION_PROGRAMMEE_LISTE_CHIRURGIE_GETS_DATA_LOADING = 'INTERVENTION_PROGRAMMEE_LISTE_CHIRURGIE_GETS_DATA_LOADING'


export const INTERVENTION_PROGRAMMEE_LISTE_CHIRURGIE_REFRESH_GETS_DATA_SUCCESS = 'INTERVENTION_PROGRAMMEE_LISTE_CHIRURGIE_REFRESH_GETS_DATA_SUCCESS'

export const INTERVENTION_PROGRAMMEE_LISTE_CHIRURGIE_REFRESH_GETS_DATA_FAILED = 'INTERVENTION_PROGRAMMEE_LISTE_CHIRURGIE_REFRESH_GETS_DATA_FAILED'

export const INTERVENTION_PROGRAMMEE_LISTE_CHIRURGIE_REFRESH_GETS_DATA_LOADING = 'INTERVENTION_PROGRAMMEE_LISTE_CHIRURGIE_REFRESH_GETS_DATA_LOADING'

//////////////////////////////////////////////////////////////////////////////////////////////
export const HOSPITALISATION_CHIRURGIE_GET_DATA_SUCCESS = 'HOSPITALISATION_CHIRURGIE_GET_DATA_SUCCESS'

export const HOSPITALISATION_CHIRURGIE_GET_DATA_FAILED = 'HOSPITALISATION_CHIRURGIE_GET_DATA_FAILED'

export const HOSPITALISATION_CHIRURGIE_GET_DATA_LOADING = 'HOSPITALISATION_CHIRURGIE_GET_DATA_LOADING'

export const hospitalisationTypes = {
    DEMANDE_HOSPITALISATION_CHIRURGIE_GET_DATA_LOADING: "DEMANDE_HOSPITALISATION_CHIRURGIE_GET_DATA_LOADING",
    DEMANDE_HOSPITALISATION_CHIRURGIE_GET_DATA_SUCCESS: "DEMANDE_HOSPITALISATION_CHIRURGIE_GET_DATA_SUCCESS",
    DEMANDE_HOSPITALISATION_CHIRURGIE_GET_DATA_FAILED: "DEMANDE_HOSPITALISATION_CHIRURGIE_GET_DATA_FAILED",
    DEMANDE_HOSPITALISATION_CHIRURGIE_LIST_REQUEST: "DEMANDE_HOSPITALISATION_CHIRURGIE_LIST_REQUEST",
    DEMANDE_HOSPITALISATION_CHIRURGIE_LIST_SUCCESS: "DEMANDE_HOSPITALISATION_CHIRURGIE_LIST_SUCCESS",
    DEMANDE_HOSPITALISATION_CHIRURGIE_LIST_FAIL: "DEMANDE_HOSPITALISATION_CHIRURGIE_LIST_FAIL",
    PATIENT_HOSPITALISATION_CHIRURGIE_REQUEST: "PATIENT_HOSPITALISATION_CHIRURGIE_REQUEST",
    PATIENT_HOSPITALISATION_CHIRURGIE_SUCCESS: "PATIENT_HOSPITALISATION_CHIRURGIE_SUCCESS",
    PATIENT_HOSPITALISATION_CHIRURGIE_FAIL: "PATIENT_HOSPITALISATION_CHIRURGIE_FAIL",
    HOSPITALISATIONS_CHIRURGIE_LIST_REQUEST: "HOSPITALISATIONS_CHIRURGIE_LIST_REQUEST",
    HOSPITALISATIONS_CHIRURGIE_LIST_SUCCESS: "HOSPITALISATIONS_CHIRURGIE_LIST_SUCCESS",
    HOSPITALISATIONS_CHIRURGIE_LIST_FAIL: "HOSPITALISATIONS_CHIRURGIE_LIST_FAIL",
    HOSPITALISATION_ORIENTATION_CHIRURGIE_REQUEST: "HOSPITALISATION_ORIENTATION_CHIRURGIE_REQUEST",
    HOSPITALISATION_ORIENTATION_CHIRURGIE_SUCCESS: "HOSPITALISATION_ORIENTATION_CHIRURGIE_SUCCESS",
    HOSPITALISATION_ORIENTATION_CHIRURGIE_FAIL: "HOSPITALISATION_ORIENTATION_CHIRURGIE_FAIL",
    HOSPITALISATION_CHIRURGIE_CANCEL_REQUEST: "HOSPITALISATION_CHIRURGIE_CANCEL_REQUEST",
    HOSPITALISATION_CHIRURGIE_CANCEL_SUCCESS: "HOSPITALISATION_CHIRURGIE_CANCEL_SUCCESS",
    HOSPITALISATION_CHIRURGIE_CANCEL_FAIL: "HOSPITALISATION_CHIRURGIE_CANCEL_FAIL",
    
    HOSPITALISATION_VALIDEE_CHIRURGIE_GET_LOADING: "HOSPITALISATION_VALIDEE_CHIRURGIE_GET_LOADING",
    HOSPITALISATION_VALIDEE_CHIRURGIE_GET_SUCCESS: "HOSPITALISATION_VALIDEE_CHIRURGIE_GET_SUCCESS",
    HOSPITALISATION_VALIDEE_CHIRURGIE_GET_FAILED: "HOSPITALISATION_VALIDEE_CHIRURGIE_GET_FAILED",
}

///////////////////////////////////////////////////////////////////////////////////////////////

export const observationTypes = {
    OBSERVATION_CHIRURGIE_LIST_REQUEST: "OBSERVATION_CHIRURGIE_LIST_REQUEST",
    OBSERVATION_CHIRURGIE_LIST_SUCCESS: "OBSERVATION_CHIRURGIE_LIST_SUCCESS",
    OBSERVATION_CHIRURGIE_LIST_FAIL: "OBSERVATION_CHIRURGIE_LIST_FAIL",
}