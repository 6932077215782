import React, { lazy } from "react"
import * as MyPrivateRouters from "../routes/PrivateRouter";
import { Switch } from 'react-router-dom';


const LayoutChirurgiePatientAConsulter = lazy(() =>
    import(
      "../../components/chirurgie/patientAConsulter/LayoutChirurgiePatientAConsulter"
    )
);
const LayoutChirurgieConsultationCreate = lazy(() =>
    import(
        "../../components/chirurgie/patientAConsulterCreate/LayoutChirurgieConsultationCreate"
    )
);
const LayoutChirurgieConsultationView = lazy(() =>
    import(
        "../../components/chirurgie/patientAConsulterDetail/LayoutChirurgieConsultationView"
    )
);
const LayoutChirurgieConsultationListe = lazy(() =>
    import("../../components/chirurgie/consultation/LayoutChirurgieConsultationListe")
);
const LayoutChirurgieFeuilleDeSoins = lazy(() =>
    import("../../components/chirurgie/feuilleDeSoins/LayoutChirurgieFeuilleDeSoins")
);
const LayoutCreateConsultationSortieChirurgie = lazy(() =>
    import(
        "../../components/chirurgie/patientAConsulterIssuSortie/LayoutCreateConsultationSortieChirurgie"
    )
);

const LayoutChirurgieDecesConsultationSortie = lazy(() =>
    import(
        "../../components/chirurgie/patientAConsulterIssuSortie/IssueDeDeces/LayoutMedecineGDecesConsultationSortie"
    )
);

const ChirurgieDemandeDeChirurgie = lazy(() =>
    import(
        "../../components/chirurgie/demandeDeChirurgie/ChirurgieDemandeDeChirurgie"
    )
);
const LayoutChirurgieCreateRequeteOperatoire = lazy(() =>
    import(
        "../../components/chirurgie/requetesOperations/form/LayoutChirurgieCreateRequeteOperatoire"
    )
);
const LayoutChirurgieRequeteOperatoire = lazy(() =>
    import(
        "../../components/chirurgie/requetesOperations/liste/LayoutChirurgieRequeteOperatoire"
    )
);
const LayoutChirurgieCreateProgrammationIntervention = lazy(() =>
    import(
        "../../components/chirurgie/programmesInterventions/form/LayoutChirurgieCreateProgrammationIntervention"
    )
);
const LayoutChirurgieProgrammationIntervention = lazy(() =>
    import(
        "../../components/chirurgie/programmesInterventions/list/LayoutChirurgieProgrammationIntervention"
    )
);
const LayoutChirurgieIntervention = lazy(() =>
    import(
        "../../components/chirurgie/interventions/list/LayoutChirurgieIntervention"
    )
);
const LayoutChirurgieCreateFinProgrammationIntervention = lazy(() =>
    import(
        "../../components/chirurgie/programmesInterventions/formFin/LayoutChirurgieCreateProgrammationIntervention"
    )
);
const LayoutChirurgieCreateDetailsProgrammationIntervention = lazy(() =>
    import(
        "../../components/chirurgie/interventions/form/LayoutChirurgieCreateProgrammationIntervention"
    )
);

const LayoutChirurgieOrdonnanceDetails = lazy(() =>
    import(
        "../../components/chirurgie/ordonnance/detail/LayoutChirurgieOrdonnanceDetails"
    )
);
const LayoutChirurgieOrdonnanceListe = lazy(() =>
    import(
        "../../components/chirurgie/ordonnance/liste/LayoutChirurgieOrdonnanceListe"
    )
);
const ChirurgieExamensDetail = lazy(() =>
    import("../../components/chirurgie/Examens/detail")
);
const ChirurgieExamensList = lazy(() =>
    import("../../components/chirurgie/Examens/list")
);
const LayoutChirurgieDecesListe = lazy(() =>
    import("../../components/chirurgie/Deces/Liste/LayoutChirurgieDecesListe")
);
const LayoutChirurgieDecesCertificat = lazy(() =>
    import(
        "../../components/chirurgie/Deces/Certificat/LayoutChirurgieDecesCertificat"
    )
);

const LayoutVoirChirurgieResultatsExamens = lazy(() =>
    import(
        "../../components/chirurgie/Examens/resultatsExamensVoir/LayoutVoirMedecineGeneralResultatsExamens"
    )
);

const RapportChirurgie = lazy(() =>     import("../../components/chirurgie/rapports/RapportChirurgie"));

const LayoutChirurgieDemandeHospitalisationListe = lazy(() =>
    import(
        "../../components/chirurgie/demandeHospitalisation/LayoutChirurgieDemandeHospitalisationListe"
    )
);
const ChirurgieObservation = lazy(() =>
    import("../../components/chirurgie/observation/ChirurgieObservation")
);

const ChirurgieObservationTerminees = lazy(() =>
    import("../../components/chirurgie/observationTerminees/ChirurgieObservationTerminees")
);

const LayoutCreateConsultationChirurgieSortieDemandeHospitalisation = lazy(
() =>
        import(
        "../../components/chirurgie/patientAConsulterIssuSortie/DemandeHospitalisation/LayoutCreateConsultationChirurgieSortieDemandeHospitalisation"
        )
);
const LayoutCreateConsultationChirurgieSortieMiseEnObservation = lazy(() =>
    import(
        "../../components/chirurgie/patientAConsulterIssuSortie/MiseEnObservation/LayoutCreateConsultationChirurgieSortieMiseEnObservation"
    )
);

const LayoutCreateConsultationChirurgieRefereInterne = lazy(() =>
    import(
        "../../components/chirurgie/patientAConsulterIssuSortie/RefereInterne/LayoutCreateConsultationChirurgieRefereInterne"
    )
);
const LayoutCreateConsultationChirurgieRefereExterne = lazy(() =>
    import(
        "../../components/chirurgie/patientAConsulterIssuSortie/RefereExterne/LayoutCreateConsultationChirurgieRefereExterne"
    )
);

const LayoutChirurgieHospitalisationListe = lazy(() =>
    import(
        "../../components/chirurgie/hospitalisation/LayoutChirurgieHospitalisationListe"
    )
);

const LayoutChirurgieHospitalisationTermineesListe = lazy(() =>
    import(
        "../../components/chirurgie/hospitalisationTerminees/LayoutChirurgieHospitalisationTermineesListe"
    )
);

const LayoutChirurgieHospitalisationConsultationCreate = lazy(() =>
    import(
        "../../components/chirurgie/hospitalisation/consultation/LayoutChirurgieHospitalisationConsultationCreate"
    )
);
const LayoutChirurgieHospitalisationConsultationSortie = lazy(() =>
    import(
        "../../components/chirurgie/hospitalisation/issueSortie/LayoutChirurgieHospitalisationConsultationSortie"
    )
);

const ChirurgieRefExterne = lazy(() =>
    import("../../components/chirurgie/refExterne/ChirurgieRefExterne")
);
const ChirurgieRefInterne = lazy(() =>
    import("../../components/chirurgie/refInterne/ChirurgieRefInterne")
);

const LayoutCreateConsultationSortieDemandeHospitalisationChirurgie = lazy(
() =>
        import(
        "../../components/chirurgie/patientAConsulterIssuSortie/MiseEnObservation/DemandeHospitalisation/LayoutCreateConsultationSortieDemandeHospitalisationChirurgie"
        )
);
const LayoutCreateConsultationRefereInterneChirurgie = lazy(() =>
    import(
        "../../components/chirurgie/patientAConsulterIssuSortie/MiseEnObservation/RefereInterne/LayoutCreateConsultationRefereInterneChirurgie"
    )
);
const LayoutCreateConsultationRefereExterneChirurgie = lazy(() =>
    import(
        "../../components/chirurgie/patientAConsulterIssuSortie/MiseEnObservation/RefereExterne/LayoutCreateConsultationRefereExterneChirurgie"
    )
);

const AgendaChirurgie = lazy(() =>
    import("../../components/chirurgie/rendezVous/agendaDetail/AgendaChirurgie")
);
const LayoutChirurgieCreateConsultationRendezVous = lazy(() =>
    import(
        "../../components/chirurgie/patientAConsulterIssuSortie/RendezVous/LayoutChirurgieCreateConsultationRendezVous"
    )
);
const LayoutChirurgieRdvListe = lazy(() =>
    import(
        "../../components/chirurgie/rendezVous/Liste/LayoutChirurgieRdvListe"
    )
);
const LayoutChirurgieRdvLastConsultation = lazy(() =>
    import(
        "../../components/chirurgie/rendezVous/LastConsultation/LayoutChirurgieRdvLastConsultation"
    )
);
const LayoutChirurgieRdvNewConsultation = lazy(() =>
    import(
        "../../components/chirurgie/rendezVous/NewConsultation/LayoutChirurgieRdvNewConsultation"
    )
);


const LayoutChirurgieCompteRenduOperatoireListe = lazy(() =>
    import("../../components/chirurgie/compteRenduOperatoire/Liste/LayoutChirurgieCompteRenduOperatoireListe")
);

const LayoutChirurgieCompteRenduOperatoireCreate = lazy(() =>
    import("../../components/chirurgie/compteRenduOperatoire/Form/LayoutChirurgieCompteRenduOperatoireCreate")
);

const LayoutChirurgieCompteRenduOperatoireVoir = lazy(() =>
    import("../../components/chirurgie/compteRenduOperatoire/Voir/LayoutChirurgieCompteRenduOperatoireVoir")
);

const LayoutChirurgieControleMajor = lazy(() => 
    import("../../components/chirurgie/controleMajor/LayoutChirurgieControleMajor")
)
  

export default function UrologieRouter() {
  return (
    <Switch>
        <MyPrivateRouters.PrivateRouterChirurgie
        path="/chirurgie"
        component={LayoutChirurgiePatientAConsulter}
        />

        <MyPrivateRouters.PrivateRouterChirurgie
        path="/chirurgie-consultation-create/:id"
        component={LayoutChirurgieConsultationCreate}
        />

        <MyPrivateRouters.PrivateRouterChirurgie
        path="/chirurgie-consultation-voir-detail/:id"
        component={LayoutChirurgieConsultationView}
        />

        <MyPrivateRouters.PrivateRouterChirurgie
        path="/chirurgie-consultation"
        component={LayoutChirurgieConsultationListe}
        />

        <MyPrivateRouters.PrivateRouterChirurgie
        path="/chirurgie-feuille-de-soins/:id"
        component={LayoutChirurgieFeuilleDeSoins}
        />

        <MyPrivateRouters.PrivateRouterChirurgie
        path="/chirurgie-consultation-sortie/:id"
        component={LayoutCreateConsultationSortieChirurgie}
        />

        <MyPrivateRouters.PrivateRouterChirurgie
        path="/chirurgie-consultation-sortie-issue-deces/:id"
        component={LayoutChirurgieDecesConsultationSortie}
        />

        <MyPrivateRouters.PrivateRouterChirurgie
        path="/chirurgie-demande-chirurgie"
        component={ChirurgieDemandeDeChirurgie}
        />

        <MyPrivateRouters.PrivateRouterChirurgie
        path="/chirurgie-requete-operatoire/:id"
        component={LayoutChirurgieCreateRequeteOperatoire}
        />

        <MyPrivateRouters.PrivateRouterChirurgie
        path="/chirurgie-requetes-operatoires"
        component={LayoutChirurgieRequeteOperatoire}
        />

        <MyPrivateRouters.PrivateRouterChirurgie
        path="/chirurgie-programmation-intervention/:id"
        component={LayoutChirurgieCreateProgrammationIntervention}
        />

        <MyPrivateRouters.PrivateRouterChirurgie
        path="/chirurgie-interventions-programmes"
        component={LayoutChirurgieProgrammationIntervention}
        />

        <MyPrivateRouters.PrivateRouterChirurgie
        path="/chirurgie-interventions-effectuees"
        component={LayoutChirurgieIntervention}
        />

        <MyPrivateRouters.PrivateRouterChirurgie
        path="/chirurgie-programmation-intervention-details/:id"
        component={LayoutChirurgieCreateFinProgrammationIntervention}
        />

        <MyPrivateRouters.PrivateRouterChirurgie
        path="/chirurgie-intervention-effectuees-details/:id"
        component={
            LayoutChirurgieCreateDetailsProgrammationIntervention
        }
        />

        <MyPrivateRouters.PrivateRouterChirurgie
        path="/chirurgie-examens-detail/:id/"
        component={ChirurgieExamensDetail}
        />

        <MyPrivateRouters.PrivateRouterChirurgie
        path="/chirurgie-examens"
        component={ChirurgieExamensList}
        />

        <MyPrivateRouters.PrivateRouterChirurgie
        path="/chirurgie-ordonnance"
        component={LayoutChirurgieOrdonnanceListe}
        />

        <MyPrivateRouters.PrivateRouterChirurgie
        path="/chirurgie-ordonnance-voir/:id"
        component={LayoutChirurgieOrdonnanceDetails}
        />

        <MyPrivateRouters.PrivateRouterChirurgie
        path="/chirurgie-patients-decedes"
        component={LayoutChirurgieDecesListe}
        />

        <MyPrivateRouters.PrivateRouterChirurgie
        path="/chirurgie-patients-decedes-certificat/:id"
        component={LayoutChirurgieDecesCertificat}
        />

        <MyPrivateRouters.PrivateRouterChirurgie
        path="/chirurgie-examens-resultat-voir/:id"
        component={LayoutVoirChirurgieResultatsExamens}
        />

        <MyPrivateRouters.PrivateRouterChirurgie
        path="/chirurgie-rapports"
        component={RapportChirurgie}
        />

        <MyPrivateRouters.PrivateRouterChirurgie
        path="/chirurgie-demande-hospitalisation"
        component={LayoutChirurgieDemandeHospitalisationListe}
        />

        <MyPrivateRouters.PrivateRouterChirurgie
        path="/chirurgie-observation-encours"
        component={ChirurgieObservation}
        />

        <MyPrivateRouters.PrivateRouterChirurgie
        path="/chirurgie-observation-terminee"
        component={ChirurgieObservationTerminees}
        />

        <MyPrivateRouters.PrivateRouterChirurgie
        path="/chirurgie-rdv"
        component={LayoutChirurgieRdvListe}
        />

        <MyPrivateRouters.PrivateRouterChirurgie
        path="/chirurgie-rdv-last-consultation/:id"
        component={LayoutChirurgieRdvLastConsultation}
        />

        <MyPrivateRouters.PrivateRouterChirurgie
        path="/chirurgie-rdv-new-consultation/:id"
        component={LayoutChirurgieRdvNewConsultation}
        />

        <MyPrivateRouters.PrivateRouterChirurgie
        path="/chirurgie-consultation-sortie-rdv/:id"
        component={LayoutChirurgieCreateConsultationRendezVous}
        />

        <MyPrivateRouters.PrivateRouterChirurgie
        path="/chirurgie-rdv-agenda/"
        component={AgendaChirurgie}
        />

        <MyPrivateRouters.PrivateRouterChirurgie
            path="/chirurgie-hospitalisation-encours"
            component={LayoutChirurgieHospitalisationListe}
        />

        <MyPrivateRouters.PrivateRouterChirurgie
            path="/chirurgie-hospitalisation-terminee"
            component={LayoutChirurgieHospitalisationTermineesListe}
        />

        <MyPrivateRouters.PrivateRouterChirurgie
        path="/chirurgie-hospitalisation-consultation/:id"
        component={LayoutChirurgieHospitalisationConsultationCreate}
        />

        <MyPrivateRouters.PrivateRouterChirurgie
        path="/chirurgie-hospitalisation-sortie/:id"
        component={LayoutChirurgieHospitalisationConsultationSortie}
        />

        <MyPrivateRouters.PrivateRouterChirurgie
        path="/chirurgie-consultation-sortie-demande-hospitalisation/:id"
        component={
            LayoutCreateConsultationChirurgieSortieDemandeHospitalisation
        }
        />

        <MyPrivateRouters.PrivateRouterChirurgie
        path="/chirurgie-consultation-sortie-mise-en-observation/:id"
        component={
            LayoutCreateConsultationChirurgieSortieMiseEnObservation
        }
        />

        <MyPrivateRouters.PrivateRouterChirurgie
        path="/chirurgie-consultation-refere-interne/:id"
        component={LayoutCreateConsultationChirurgieRefereInterne}
        />

        <MyPrivateRouters.PrivateRouterChirurgie
        path="/chirurgie-consultation-refere-externe/:id"
        component={LayoutCreateConsultationChirurgieRefereExterne}
        />

        <MyPrivateRouters.PrivateRouterChirurgie
        path="/chirurgie-referencements-externe"
        component={ChirurgieRefExterne}
        />

        <MyPrivateRouters.PrivateRouterChirurgie
        path="/chirurgie-referencements-interne"
        component={ChirurgieRefInterne}
        />

        <MyPrivateRouters.PrivateRouterChirurgie
        path="/chirurgie-exit-observation/:id"
        component={LayoutCreateConsultationSortieChirurgie}
        />

        <MyPrivateRouters.PrivateRouterChirurgie
        path="/chirurgie-observation-consultation/:id"
        component={LayoutChirurgieConsultationCreate}
        />

        <MyPrivateRouters.PrivateRouterChirurgie
        path="/chirurgie-observation-exit-demande-hospitalisation/:id"
        component={
            LayoutCreateConsultationSortieDemandeHospitalisationChirurgie
        }
        />

        <MyPrivateRouters.PrivateRouterChirurgie
        path="/chirurgie-observation-exit-reference-externe/:id"
        component={LayoutCreateConsultationRefereExterneChirurgie}
        />

        <MyPrivateRouters.PrivateRouterChirurgie
        path="/chirurgie-observation-exit-reference-interne/:id"
        component={LayoutCreateConsultationRefereInterneChirurgie}
        />

        <MyPrivateRouters.PrivateRouterChirurgie
        path="/chirurgie-observation-exit-deces/:id"
        component={LayoutChirurgieDecesConsultationSortie}
        />

        <MyPrivateRouters.PrivateRouterChirurgie
        path="/chirurgie-compte-rendu-operatoire"
        component={LayoutChirurgieCompteRenduOperatoireListe}
        />

        <MyPrivateRouters.PrivateRouterChirurgie
        path="/chirurgie-compte-rendu-operatoire-create"
        component={LayoutChirurgieCompteRenduOperatoireCreate}
        />

        <MyPrivateRouters.PrivateRouterChirurgie
        path="/chirurgie-compte-rendu-operatoire-voir-detail/:id"
        component={LayoutChirurgieCompteRenduOperatoireVoir}
        />

        <MyPrivateRouters.PrivateRouterChirurgie
        path="/chirurgie-observation-exit-rdv/:id"
        component={LayoutChirurgieCreateConsultationRendezVous}
        />

        <MyPrivateRouters.PrivateRouterChirurgie
        path="/chirurgie-controle-major"
        component={LayoutChirurgieControleMajor}
        />
    </Switch>
  )
}
