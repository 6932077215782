import { professionnelSanteTypes } from "../../types/professionnelSanteTypes";
import { AUTH_ERROR, LOGOUT_SUCCESS, TOKEN_REFRESH_FAIL } from "../../types/userTypes";

const initialState = {
  isLoading: false,
  isLoadingGetProfessionnel: false,
  isSuccesGetProfessionnel: false,
  isLoadingUpdateProfessionnel: false,
  isSuccesUpdateProfessionnel: false,
  isLoadingRefresh: false,

  professionnels: [],
  professionnelsByCentreSante: [],
  professionnelSante: {},
  professionnelSanteUpdate: {},

  error: "",
  isError: false,
  isError403: false,
  isError500: false,
  isErrorDelete403: false,
  isErrorDelete500: false,
};

export const professionnelsListReducer = (state = initialState, action) => {
  switch (action.type) {
    //LIST DES PROFESSIONNELS DU CENTRE
    case professionnelSanteTypes.PROFESSIONNEL_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: "",
        isError403: false,
        isError500: false,
      };
    case professionnelSanteTypes.PROFESSIONNEL_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        professionnels: action.payload,
        error: "",
        isError403: false,
        isError500: false,
      };
    case professionnelSanteTypes.PROFESSIONNEL_LIST_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        isError403: false,
        isError500: false,
      };
    case professionnelSanteTypes.PROFESSIONNEL_LIST_FAIL_403:
      return {
        ...state,
        isLoading: false,
        professionnels:[],
        error: action.payload,
        isError403: true,
        isError500: false,
      };
    case professionnelSanteTypes.PROFESSIONNEL_LIST_FAIL_500:
      return {
        ...state,
        isLoading: false,
        professionnels:[],
        error: action.payload,
        isError403: false,
        isError500: true,
      };

    //REFRESH LIST DES PROFESSIONNELS DU CENTRE
    case professionnelSanteTypes.PROFESSIONNEL_LIST_REFRESH_REQUEST:
      return {
        ...state,
        isLoadingRefresh: true,
        error: "",
        isError403: false,
        isError500: false,
      };
    case professionnelSanteTypes.PROFESSIONNEL_LIST_REFRESH_SUCCESS:
      return {
        ...state,
        isLoadingRefresh: false,
        professionnels: action.payload,
        error: "",
        isError403: false,
        isError500: false,
      };
    case professionnelSanteTypes.PROFESSIONNEL_LIST_REFRESH_FAIL:
      return {
        ...state,
        isLoadingRefresh: false,
        error: action?.payload,
        isError403: false,
        isError500: false,
      };
    case professionnelSanteTypes.PROFESSIONNEL_LIST_REFRESH_FAIL_403:
      return {
        ...state,
        isLoadingRefresh: false,
        professionnels:[],
        error: action.payload,
        isError403: true,
        isError500: false,
      };
    case professionnelSanteTypes.PROFESSIONNEL_LIST_REFRESH_FAIL_500:
      return {
        ...state,
        isLoadingRefresh: false,
        professionnels:[],
        error: action.payload,
        isError403: false,
        isError500: true,
      };

    //LISTE DES PROFESSIONNELLES PAR CENTRE
    case professionnelSanteTypes.PROFESSIONNEL_LIST_BY_CENTRE_SANTE_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: "",
        isError403: false,
        isError500: false,
      };
    case professionnelSanteTypes.PROFESSIONNEL_LIST_BY_CENTRE_SANTE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        professionnelsByCentreSante: action.payload,
        error: "",
        isError403: false,
        isError500: false,
      };
    case professionnelSanteTypes.PROFESSIONNEL_LIST_BY_CENTRE_SANTE_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        isError403: false,
        isError500: false,
      };
    case professionnelSanteTypes.PROFESSIONNEL_LIST_BY_CENTRE_SANTE_FAIL_403:
      return {
          ...state,
          isLoading: false,
          professionnelsByCentreSante:[],
          error: action.payload,
          isError403: true,
          isError500: false,
      };
    case professionnelSanteTypes.PROFESSIONNEL_LIST_BY_CENTRE_SANTE_FAIL_500:
      return {
          ...state,
          isLoading: false,
          professionnelsByCentreSante:[],
          error: action.payload,
          isError403: false,
          isError500: true,
      };
    
    //get information du professionnel sante
    case professionnelSanteTypes.PROFESSIONNEL_GET_LOADING:
      return {
        ...state,
        isLoadingGetProfessionnel: true,
        isSuccesGetProfessionnel: false,
        isError: false,
        error: "",
        isError403: false,
        isError500: false,
      };
    case professionnelSanteTypes.PROFESSIONNEL_GET_SUCCESS:
      return {
        ...state,
        isLoadingGetProfessionnel: false,
        isSuccesGetProfessionnel: true,
        professionnelSante: action.payload,
        isError: false,
        error: "",
        isError403: false,
        isError500: false,
      };
    case professionnelSanteTypes.PROFESSIONNEL_GET_FAILED:
      return {
        ...state,
        isLoadingGetProfessionnel: false,
        isSuccesGetProfessionnel: false,
        error: action.payload,
        professionnelSante:{},
        isError: true,
        isError403: false,
        isError500: false,
      };
    case professionnelSanteTypes.PROFESSIONNEL_GET_FAILED_403:
      return {
        ...state,
        isLoadingGetProfessionnel: false,
        isSuccesGetProfessionnel: false,
        professionnelSante:{},
        error: action.payload,
        isError403: true,
        isError500: false,
      };
    case professionnelSanteTypes.PROFESSIONNEL_GET_FAILED_500:
      return {
        ...state,
        isLoadingGetProfessionnel: false,
        isSuccesGetProfessionnel: false,
        professionnelSante:{},
        error: action.payload,
        isError403: false,
        isError500: true,
      };
    
    //MISE À JOUR DU PROFESSIONNEL
    case professionnelSanteTypes.PROFESSIONNEL_UPDATE_LOADING:
      return {
        ...state,
        isLoadingUpdateProfessionnel: true,
        isSuccesUpdateProfessionnel: false,
        isError: false,
        error: "",
      };
    case professionnelSanteTypes.PROFESSIONNEL_UPDATE_SUCCESS:
      return {
        ...state,
        isLoadingUpdateProfessionnel: false,
        isSuccesUpdateProfessionnel: true,
        professionnelSanteUpdate: action.payload,
        isError: false,
        error: "",
      };
    case professionnelSanteTypes.PROFESSIONNEL_UPDATE_FAILED:
      return {
        ...state,
        isLoadingUpdateProfessionnel: false,
        isSuccesUpdateProfessionnel: false,
        error: action.payload,
        isError: true
      };

    //SUPPRESSION DU PROFESSIONNEL
    case professionnelSanteTypes.PROFESSIONNEL_DELETE_LOADING:
      return {
        ...state,
        isLoading: true,
        isSuccessDelete: false,
        isErrorDelete: false,
        error: "",
        isErrorDelete403: false,
        isErrorDelete500: false,
      };
    case professionnelSanteTypes.PROFESSIONNEL_DELETE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccessDelete: true,
        isErrorDelete: false,
        professionnels: state.professionnels.filter(data => data?.id !== action.payload),
        error: "",
        isErrorDelete403: false,
        isErrorDelete500: false,
      };
    case professionnelSanteTypes.PROFESSIONNEL_DELETE_FAILED:
      return {
        ...state,
        isLoading: false,
        isSuccessDelete: false,
        isErrorDelete: false,
        error: action.payload,
        isErrorDelete403: false,
        isErrorDelete500: false,
      };
    case professionnelSanteTypes.PROFESSIONNEL_DELETE_FAILED_403:
      return {
        ...state,
        isLoading: false,
        isSuccessDelete: false,
        error: action.payload,
        isErrorDelete403: true,
        isErrorDelete500: false,
      };
    case professionnelSanteTypes.PROFESSIONNEL_DELETE_FAILED_500:
      return {
        ...state,
        isLoading: false,
        isSuccessDelete: false,
        error: action.payload,
        isErrorDelete403: false,
        isErrorDelete500: true,
      };
    
    //EFFACEMENT DES ETATS
    case professionnelSanteTypes.PROFESSIONNEL_CLEAR_STATE:
      return {
        ...state,
        isSuccessDelete: false,
        isErrorDelete: false,
        error: "",
        isError: false,
        isError403: false,
        isError500: false,
        isErrorDelete403: false,
        isErrorDelete500: false,
        isLoading: false,
        isLoadingGetProfessionnel: false,
        isSuccesGetProfessionnel: false,
        isLoadingUpdateProfessionnel: false,
        isSuccesUpdateProfessionnel: false,
        isLoadingRefresh: false
      };
    
    //LORS DE LA DECONNECTION
    case AUTH_ERROR:
    case LOGOUT_SUCCESS:
    case TOKEN_REFRESH_FAIL:
        localStorage.removeItem('token_access')
        localStorage.removeItem('token_refresh')
        return {
          ...state,
          isLoading: false,
          isLoadingGetProfessionnel: false,
          isSuccesGetProfessionnel: false,
          isLoadingUpdateProfessionnel: false,
          isSuccesUpdateProfessionnel: false,
          isLoadingRefresh: false,

          professionnels: [],
          professionnelsByCentreSante: [],
          professionnelSante: {},
          professionnelSanteUpdate: {},

          isSuccessDelete: false,
          isErrorDelete: false,

          error: "",
          isError: false,
          isError403: false,
          isError500: false,
          isErrorDelete403: false,
          isErrorDelete500: false,
      }

    default:
      return state;
  }
};
