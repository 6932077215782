import * as gTypes from '../types/gynecologieTypes';
import { AUTH_ERROR, LOGOUT_SUCCESS, TOKEN_REFRESH_FAIL } from '../types/userTypes';
import { HOSPITALISATION_VALIDEE_GYNECO_GET_DATA_SUCCESS } from './../types/gynecologieTypes';
import { patientAConsulterTypes } from "../types/patientAConsulterTypes";


const initialState = {
    isLoading: false,
    isLoadingRefresh: false,
    patients: [],
    patientsDejaConsulter: [],
    demandeDHospitalisation: null,
    ordonnance: null,
    hospitalisation: null,
    rdv: null,
    showSuccessModal: false
}

const executeGetDataLoading = (state, action, prop) => {
    let out = {
        ...state,
        isLoading: true,
    }
    // out[prop] = action.payload
    return out
}

const executeGetDataSuccess = (state, action, prop) => {
    let out = {
        ...state,
        isLoading: false,
    }
    out[prop] = action.payload
    return out
}

const executeGetDataFailed = (state, action, prop) => {
  let out = {
      ...state,
      isLoading: false,
  }
  out[prop] = action.payload
  return out
}

export const reducer = (state = initialState, action) => {

    switch (action.type) {
        case gTypes.GYNECOLOGIE_GET_PATIENTS_A_CONSULTER_DATA_SUCCESS:
            return executeGetDataSuccess(state, action, 'patients');
        case gTypes.GYNECOLOGIE_GET_PATIENTS_A_CONSULTER_DATA_REQUEST:
            return executeGetDataLoading(state, action, 'patients');
        case gTypes.GYNECOLOGIE_GET_PATIENTS_A_CONSULTER_DATA_FAILED:
          return executeGetDataFailed(state, action, 'patients');

        case gTypes.GYNECOLOGIE_GET_PATIENTS_DEJA_CONSULTER_DATA_SUCCESS:
            return executeGetDataSuccess(state, action, 'patientsDejaConsulter');
        case gTypes.GYNECOLOGIE_GET_PATIENTS_DEJA_CONSULTER_DATA_REQUEST:
            return executeGetDataLoading(state, action, 'patientsDejaConsulter');

        case gTypes.GYNECOLOGIE_GET_DEMANDES_D_HOSPITALISATION_DATA_SUCCESS:
            return executeGetDataSuccess(state, action, 'demandeDHospitalisation');
        case gTypes.GYNECOLOGIE_GET_HOSPITALISATIONS_DATA_SUCCESS:
            return executeGetDataSuccess(state, action, 'hospitalisation');
        case gTypes.GYNECOLOGIE_GET_ORDONNANCE_DATA_SUCCESS:
            return executeGetDataSuccess(state, action, 'ordonnance');
        case gTypes.GYNECOLOGIE_GET_ORDONNANCE_DATA_REQUEST:
            return executeGetDataLoading(state, action, 'ordonnance');
        case gTypes.GYNECOLOGIE_GET_RDV_DATA_SUCCESS:
            return executeGetDataSuccess(state, action, 'rdv');

        // case gTypes.MODULE_POST_DATA_SUCCESS:
        //     return executePostDataSuccess(state, action);
        // case gTypes.MODULE_PUT_DATA_SUCCESS:
        //     return executePutDataSuccess(state, action);
        // case gTypes.MODULE_DELETE_DATA_SUCCESS:
        //     return executeDeleteDataSuccess(state, action);
        case patientAConsulterTypes.PATIENT_A_CONSULTER_LIST_REFRESH_REQUEST:
          return {
            ...state,
            isLoadingRefresh: true,
            error: null,
          };
        case patientAConsulterTypes.PATIENT_A_CONSULTER_LIST_REFRESH_SUCCESS:
          return {
            ...state,
            isLoadingRefresh: false,
            patients: action.payload,
            error: null,
          };
        case patientAConsulterTypes.PATIENT_A_CONSULTER_LIST_REFRESH_FAIL:
          return {
            ...state,
            isLoadingRefresh: false,
            patients: [],
            error: action.payload,
          };
        case AUTH_ERROR:
        case LOGOUT_SUCCESS:
        case TOKEN_REFRESH_FAIL:
            localStorage.removeItem('token_access')
            localStorage.removeItem('token_refresh')
            return {
                ...state,
                isLoading: false,
                isLoadingRefresh: false,
                patients: [],
                patientsDejaConsulter: [],
                demandeDHospitalisation: null,
                ordonnance: null,
                hospitalisation: null,
                rdv: null,
                showSuccessModal: false
            }
        default:
            return state;
    }
}


//======================================================================================//
//                         CONSULTATION GYNECO REDUCER DEBUT                            //
//======================================================================================//

const initialGynecoState = {
    consulterPatientMgConstante: {},
    maladiesMg: [],
    actesChirugicauxMg: [],
    consultationPatientMg: {},
    typesExamens: [],
    hospitalisations:[],
    vaccins: [],
    
  hospitalisationValidee : [],
  pathologiesOvariennes: [],
  pathologiesMammaires: [],
  pathologiesUterines: [],
  pathologiesUrinaires: [],
  isLoadingGet: false,
  isLoadingGetMaladies: false,
  isLoadingHospitalisationValidee: false,
    isLoadingGetActesChirugies: false,
    isLoadingPostConsultationMg: false,
    isLoadingPostTerminerConsultationMg: false,
    isLoadingGetConsultationMg: false,
    isLoadingPostMiseEnObservationMg: false,
    isLoadingPostDemandeHospitalisationMg: false,
    isLoadingPostActesInfirmiersMg: false,
    isLoadingPostSortieConsultationMg: false,
    isLoadingGetTypeExamenMg: false,
    isLoadingPostRefereInterneMg: false,
    isLoadingGetRefereInterneMg: false,
    isLoadingPostRefereExterneMg: false,
    isLoadingGetRefereExterneMg: false,
    isLoadingGetHospitalisationsMg: false,
  
    isSuccesGet: false,
    isSuccesGetMaladies: false,
    isSuccesGetActesChirugies: false,
    isSuccesPostConsultationMg: false,
    isSuccesPostTerminerConsultationMg: false,
    isSuccesGetConsultationMg: false,
    isSuccesPostMiseEnObservationMg: false,
    isSuccesPostDemandeHospitalisationMg: false,
    isSuccesPostActesInfirmiersMg: false,
    isSuccesPostSortieConsultationMg: false,
    isSuccesGetTypeExamenMg: false,
    isSuccesPostRefereInterneMg: false,
    isSuccesGetRefereInterneMg: false,
    isSuccesPostRefereExterneMg: false,
    isSuccesGetRefereExterneMg: false,
    isNonfoundConsultation: false,
  
    ordonnacesMg: [],
    ordonnanceMg: [],
    isSuccesGetOrdonnancesMg:false,
    isSuccesGetOrdonnanceMg:false,
  
    isSuccesGetHospitalisationsMg: false,
  
    isError: false,
    error: "",
  };
  
  //eslint-disable-next-line
  export const consultationGyneco = (state = initialGynecoState, action) => {
    switch(action.type) {
      case gTypes.PATIENT_A_CONSULTER_GYNECO_GET_LOADING:
        return {
          ...state,
          isLoadingGet: true,
          isLoadingPostTerminerConsultationMg: false,
          isSuccesPostTerminerConsultationMg: false,
          isError: false,
          error: "",
        };
      case gTypes.PATIENT_A_CONSULTER_GYNECO_GET_SUCCESS:
        return {
          ...state,
          isLoadingGet: false,
          consulterPatientMgConstante: action.payload,
          isSuccesGet: true,
          isLoadingPostTerminerConsultationMg: false,
          isSuccesPostTerminerConsultationMg: false,
          isError: false,
        };
      case gTypes.PATIENT_A_CONSULTER_GYNECO_GET_FAILED:
        return {
          ...state,
          isLoadingGet: false,
          consulterPatientMgConstante: {},
          error: action.payload,
          isSuccesGet: false,
          isLoadingPostTerminerConsultationMg: false,
          isSuccesPostTerminerConsultationMg: false,
          isError: true,
        };
  
      case gTypes.MALADIES_GYNECO_GET_LOADING:
        return {
          ...state,
          isLoadingGetMaladies: true,
          error: "",
          isSuccesGet: false,
          isError: false,
        };
      case gTypes.MALADIES_GYNECO_GET_SUCCESS:
        return {
          ...state,
          isLoadingGetMaladies: false,
          maladiesMg: action.payload,
          error: "",
          isSuccesGet: true,
          isError: false,
        };
      case gTypes.MALADIES_GYNECO_GET_FAILED:
        return {
          ...state,
          isLoadingGetMaladies: false,
          error: action.payload,
          isSuccesGet: false,
          isError: true,
        };
  
      case gTypes.ACTES_CHIRUGICAUX_GYNECO_GET_LOADING:
        return {
          ...state,
          isLoadingGetActesChirugies: true,
          error: "",
          isSuccesGetActesChirugies: false,
          isError: false,
        };
      case gTypes.ACTES_CHIRUGICAUX_GYNECO_GET_SUCCESS:
        return {
          ...state,
          isLoadingGetActesChirugies: false,
          actesChirugicauxMg: action.payload,
          error: "",
          isSuccesGetActesChirugies: true,
          isError: false,
        };
      case gTypes.ACTES_CHIRUGICAUX_GYNECO_GET_FAILED:
        return {
          ...state,
          isLoadingGetActesChirugies: false,
          error: action.payload,
          isSuccesGetActesChirugies: false,
          isError: true,
        };
      
      //post without terminer
      case gTypes.CONSULTATION_GYNECO_POST_LOADING:
        return {
          ...state,
          isLoadingPostConsultationMg: true,
          isNonfoundConsultation:false,
          consultationPatientMg: {},
          error: "",
          isSuccesPostConsultationMg: false,
          isLoadingPostTerminerConsultationMg: false,
          isSuccesPostTerminerConsultationMg: false,
          isSuccesGetConsultationMg: false,
          isError: false,
        };
      case gTypes.CONSULTATION_GYNECO_POST_SUCCESS:
        return {
          ...state,
          isLoadingPostConsultationMg: false,
          isNonfoundConsultation:false,
          consultationPatientMg: action.payload,
          error: "",
          isSuccesPostConsultationMg: true,
          isLoadingPostTerminerConsultationMg: false,
          isSuccesPostTerminerConsultationMg: false,
          isSuccesGetConsultationMg: false,
          isError: false,
        };
      case gTypes.CONSULTATION_GYNECO_POST_FAILED:
        return {
          ...state,
          isLoadingPostConsultationMg: false,
          isLoadingGet: false,
          isLoadingGetMaladies: false,
          isLoadingGetActesChirugies: false,
          consultationPatientMg: {},
          error: action.payload,
          isSuccesPostConsultationMg: false,
          isLoadingPostTerminerConsultationMg: false,
          isSuccesPostTerminerConsultationMg: false,
          isSuccesGetConsultationMg: false,
          isError: true,
        };
  
        //post without and terminer
        case gTypes.CONSULTATION_GYNECO_POST_TERMINER_LOADING:
          return {
            ...state,
            isLoadingPostTerminerConsultationMg: true,
            isNonfoundConsultation:false,
            consultationPatientMg: {},
            error: "",
            isSuccesPostTerminerConsultationMg: false,
            isSuccesGetConsultationMg: false,
            isError: false,
          };
        case gTypes.CONSULTATION_GYNECO_POST_TERMINER_SUCCESS:
          return {
            ...state,
            isLoadingPostTerminerConsultationMg: false,
            isNonfoundConsultation:false,
            consultationPatientMg: action.payload,
            error: "",
            isSuccesPostTerminerConsultationMg: true,
            isSuccesGetConsultationMg: false,
            isError: false,
          };
        case gTypes.CONSULTATION_GYNECO_POST_TERMINER_FAILED:
          return {
            ...state,
            isLoadingPostTerminerConsultationMg: false,
            consultationPatientMg: {},
            error: action.payload,
            isSuccesPostTerminerConsultationMg: false,
            isSuccesGetConsultationMg: false,
            isError: true,
          };
  
      case gTypes.CONSULTATION_GYNECO_GET_LOADING:
        return {
          ...state,
          isLoadingGetConsultationMg: true,
          consultationPatientMg: {},
          isNonfoundConsultation:false,
          error: "",
          isSuccesGetConsultationMg: false,
          isError: false,
        };
      case gTypes.CONSULTATION_GYNECO_GET_SUCCESS:
        return {
          ...state,
          isLoadingGetConsultationMg: false,
          consultationPatientMg: action.payload,
          isNonfoundConsultation:false,
          error: "",
          isSuccesGetConsultationMg: true,
          isError: false,
        };
      case gTypes.CONSULTATION_GYNECO_GET_PAS_TROUVER:
        return {
          ...state,
          isLoadingGetConsultationMg: false,
          consultationPatientMg: action.payload,
          isNonfoundConsultation:true,
          error: "",
          isSuccesGetConsultationMg: true,
          isError: false,
        };
      case gTypes.CONSULTATION_GYNECO_GET_FAILED:
        return {
          ...state,
          isLoadingGetConsultationMg: false,
          isNonfoundConsultation:true,
          consultationPatientMg: {},
          error: action.payload,
          isSuccesGetConsultationMg: false,
          isError: true,
        };
          
          //actes infirmiers
          case gTypes.ISSU_ACTES_INFIRMIERS_GYNECO_POST_LOADING:
              return {
                  ...state,
                  isLoadingPostActesInfirmiersMg: true,
                  consultationPatientMg:{},
                  error: '',
                  isSuccesPostActesInfirmiersMg: false,
                  isError:false
              }
          case gTypes.ISSU_ACTES_INFIRMIERS_GYNECO_POST_SUCCESS:
              return {
                  ...state,
                  isLoadingPostActesInfirmiersMg: false,
                  consultationPatientMg:action.payload,
                  error: '',
                  isSuccesPostActesInfirmiersMg: true,
                  isError:false
              }
          case gTypes.ISSU_ACTES_INFIRMIERS_GYNECO_POST_FAILED:
              return {
                  ...state,
                  isLoadingPostActesInfirmiersMg: false,
                  consultationPatientMg:{},
                  error: action.payload,
                  isSuccesPostActesInfirmiersMg: false,
                  isError:true
              }
          
          
          //sortie simple de consultation
          case gTypes.ISSU_SORTIE_CONSULTATION_GYNECO_POST_LOADING:
              return {
                  ...state,
                  isLoadingPostSortieConsultationMg: true,
                  consultationPatientMg:{},
                  error: '',
                  isSuccesPostSortieConsultationMg: false,
                  isError:false
              }
          case gTypes.ISSU_SORTIE_CONSULTATION_GYNECO_POST_SUCCESS:
              return {
                  ...state,
                  isLoadingPostSortieConsultationMg: false,
                  consultationPatientMg:action.payload,
                  error: '',
                  isSuccesPostSortieConsultationMg: true,
                  isError:false
              }
          case gTypes.ISSU_SORTIE_CONSULTATION_GYNECO_POST_FAILED:
              return {
                  ...state,
                  isLoadingPostSortieConsultationMg: false,
                  consultationPatientMg:{},
                  error: action.payload,
                  isSuccesPostSortieConsultationMg: false,
                  isError:true
              }
          
          
          case gTypes.TYPES_EXAMENS_GYNECO_GET_LOADING:
              return {
                  ...state,
                  isLoadingGetTypeExamenMg:true,
                  isSuccesGetTypeExamenMg:false,
              }
          case gTypes.TYPES_EXAMENS_GYNECO_GET_SUCCESS:
              return {
                  ...state,
                  isLoadingGetTypeExamenMg:false,
                  isSuccesGetTypeExamenMg:true,
                  typesExamens: action.payload
              }
          case gTypes.TYPES_EXAMENS_GYNECO_GET_FAILED:
              return {
                  ...state,
                  isLoadingGetTypeExamenMg:false,
                  isSuccesGetTypeExamenMg:false,
              }
  
          case gTypes.ORDONNANCES_GYNECO_GET_LOADING:
              return {
                  ...state,
                  isLoadingGetOrdonnancesMg:true,
                  isSuccesGetOrdonnancesMg:false,
              }
          case gTypes.ORDONNANCES_GYNECO_GET_SUCCESS:
              return {
                  ...state,
                  isLoadingGetOrdonnancesMg:false,
                  isSuccesGetOrdonnancesMg:true,
                  ordonnacesMg:action.payload
              }
          case gTypes.ORDONNANCES_GYNECO_GET_FAILED:
              return {
                  ...state,
                  isLoadingGetOrdonnancesMg:false,
                  isSuccesGetOrdonnancesMg:false,
              }
          
          
          case gTypes.ORDONNANCE_GYNECO_GET_LOADING:
              return {
                  ...state,
                  isLoadingGetOrdonnanceMg:true,
                  isSuccesGetOrdonnanceMg:false,
              }
          case gTypes.ORDONNANCE_GYNECO_GET_SUCCESS:
              return {
                  ...state,
                  isLoadingGetOrdonnanceMg:false,
                  isSuccesGetOrdonnanceMg:true,
                  ordonnanceMg:action.payload
              }
          case gTypes.ORDONNANCE_GYNECO_GET_FAILED:
              return {
                  ...state,
                  isLoadingGetOrdonnanceMg:false,
                  isSuccesGetOrdonnanceMg:false,
              }
  
  
  
      //demandes d'examens
      case gTypes.ISSU_DEMANDE_HOSPITALISATION_GYNECO_POST_LOADING:
        return {
          ...state,
          isLoadingPostDemandeHospitalisationMg: true,
          consultationPatientMg: {},
          error: "",
          isSuccesPostDemandeHospitalisationMg: false,
          isError: false,
        };
      case gTypes.ISSU_DEMANDE_HOSPITALISATION_GYNECO_POST_SUCCESS:
        return {
          ...state,
          isLoadingPostDemandeHospitalisationMg: false,
          consultationPatientMg: action.payload,
          error: "",
          isSuccesPostDemandeHospitalisationMg: true,
          isError: false,
        };
      case gTypes.ISSU_DEMANDE_HOSPITALISATION_GYNECO_POST_FAILED:
        return {
          ...state,
          isLoadingPostDemandeHospitalisationMg: false,
          consultationPatientMg: {},
          error: action.payload,
          isSuccesPostDemandeHospitalisationMg: false,
          isError: true,
        };
  
      //prescriptions issue sortie
      case gTypes.ISSU_PRESCRIPTIONS_GYNECO_POST_LOADING:
        return {
          ...state,
          isLoadingPostConsultationMg: true,
          consultationPatientMg: {},
          error: "",
          isSuccesPostConsultationMg: false,
          isError: false,
        };
      case gTypes.ISSU_PRESCRIPTIONS_GYNECO_POST_SUCCESS:
        return {
          ...state,
          isLoadingPostConsultationMg: false,
          consultationPatientMg: action.payload,
          error: "",
          isSuccesPostConsultationMg: true,
          isError: false,
        };
      case gTypes.ISSU_PRESCRIPTIONS_GYNECO_POST_FAILED:
        return {
          ...state,
          isLoadingPostConsultationMg: false,
          consultationPatientMg: {},
          error: action.payload,
          isSuccesPostConsultationMg: false,
          isError: true,
        };
  
      //mise en observations
      case gTypes.ISSU_MISE_EN_OBSERVATION_GYNECO_POST_LOADING:
        return {
          ...state,
          isLoadingPostMiseEnObservationMg: true,
          consultationPatientMg: {},
          error: "",
          isSuccesPostMiseEnObservationMg: false,
          isError: false,
        };
      case gTypes.ISSU_MISE_EN_OBSERVATION_GYNECO_POST_SUCCESS:
        return {
          ...state,
          isLoadingPostMiseEnObservationMg: false,
          consultationPatientMg: action.payload,
          error: "",
          isSuccesPostMiseEnObservationMg: true,
          isError: false,
        };
      case gTypes.ISSU_MISE_EN_OBSERVATION_GYNECO_POST_FAILED:
        return {
          ...state,
          isLoadingPostMiseEnObservationMg: false,
          consultationPatientMg: {},
          error: action.payload,
          isSuccesPostMiseEnObservationMg: false,
          isError: true,
        };
  
  
      //Refere interne
      case gTypes.REFERE_INTERNE_GYNECO_POST_LOADING:
        return {
          ...state,
          isLoadingPostRefereInterneMg: true,
          consultationPatientMg: {},
          error: "",
          isSuccesPostRefereInterneMg: false,
          isError: false,
        };
      case gTypes.REFERE_INTERNE_GYNECO_POST_SUCCESS:
        return {
          ...state,
          isLoadingPostRefereInterneMg: false,
          consultationPatientMg: action.payload,
          error: "",
          isSuccesPostRefereInterneMg: true,
          isError: false,
        };
      case gTypes.REFERE_INTERNE_GYNECO_POST_FAILED:
        return {
          ...state,
          isLoadingPostRefereInterneMg: false,
          consultationPatientMg: {},
          error: action.payload,
          isSuccesPostRefereInterneMg: false,
          isError: true,
        };
  
      //Refere externe
      case gTypes.REFERE_EXTERNE_GYNECO_POST_LOADING:
        return {
          ...state,
          isLoadingPostRefereExterneMg: true,
          consultationPatientMg: {},
          error: "",
          isSuccesPostRefereExterneMg: false,
          isError: false,
        };
      case gTypes.REFERE_EXTERNE_GYNECO_POST_SUCCESS:
        return {
          ...state,
          isLoadingPostRefereExterneMg: false,
          consultationPatientMg: action.payload,
          error: "",
          isSuccesPostRefereExterneMg: true,
          isError: false,
        };
      case gTypes.REFERE_EXTERNE_GYNECO_POST_FAILED:
        return {
          ...state,
          isLoadingPostRefereExterneMg: false,
          consultationPatientMg: {},
          error: action.payload,
          isSuccesPostRefereExterneMg: false,
          isError: true,
        };
  
      case gTypes.VACCINS_GYNECO_GET_SUCCESS:
        return {
          ...state,
          vaccins: action.payload,
        }
      case gTypes.PATHOLOGIE_MAMMAIRE_GET:
        return {
          ...state,
          pathologiesMammaires: action.payload,
        }
      case gTypes.PATHOLOGIE_OVARIENNE_GET:
        return {
          ...state,
          pathologiesOvariennes: action.payload,
        }
      case gTypes.PATHOLOGIE_UTERINE_GET:
        return {
          ...state,
          pathologiesUterines: action.payload,
        }
      case gTypes.PATHOLOGIE_URINAIRE_GET:
        return {
          ...state,
          pathologiesUrinaires: action.payload,
        }
      //hospitalisations
      case gTypes.HOSPITALISATION_GYNECO_GET_DATA_LOADING:
        return {
          ...state,
          isLoadingGetHospitalisationsMg: true,
          error: "",
          isSuccesGetHospitalisationsMg: false,
          isError: false,
        };
      case gTypes.HOSPITALISATION_GYNECO_GET_DATA_SUCCESS:
        return {
          ...state,
          isLoadingGetHospitalisationsMg: false,
          hospitalisations: action.payload,
          error: "",
          isSuccesGetHospitalisationsMg: true,
          isError: false,
        };
      case gTypes.HOSPITALISATION_GYNECO_GET_DATA_FAILED:
        return {
          ...state,
          isLoadingGetHospitalisationsMg: false,
          error: action.payload,
          isSuccesGetHospitalisationsMg: false,
          isError: true,
        };

        case gTypes.HOSPITALISATION_VALIDEE_GYNECO_GET_DATA_LOADING:
          return {
            ...state,
            isLoadingHospitalisationValidee: true,
          };
        case HOSPITALISATION_VALIDEE_GYNECO_GET_DATA_SUCCESS:
          return {
            ...state,
            isLoadingHospitalisationValidee: false,
            hospitalisationValidee: action.payload,
          };
        case gTypes.HOSPITALISATION_VALIDEE_GYNECO_GET_DATA_FAILED:
          return {
            ...state,
            isLoadingHospitalisationValidee: false,
            error: action.payload,
            isError: true,
          };
  
      case gTypes.CONSULTATION_GYNECO_RESET:
        return {
          ...state,
          isLoadingGet: false,
          isLoadingGetMaladies: false,
          isLoadingGetActesChirugies: false,
          isLoadingPostConsultationMg: false,
          isLoadingGetConsultationMg: false,
          isLoadingPostMiseEnObservationMg: false,
          isLoadingPostDemandeHospitalisationMg: false,
          isLoadingPostActesInfirmiersMg: false,
          isLoadingPostSortieConsultationMg: false,
          isLoadingPostRefereInterneMg: false,
          isLoadingGetRefereInterneMg: false,
          isLoadingPostRefereExterneMg: false,
          isLoadingGetRefereExterneMg: false,
  
          isSuccesGet: false,
          isSuccesGetMaladies: false,
          isSuccesGetActesChirugies: false,
          isSuccesPostConsultationMg: false,
          isSuccesGetConsultationMg: false,
          isSuccesPostMiseEnObservationMg: false,
          isSuccesPostDemandeHospitalisationMg: false,
          isSuccesPostActesInfirmiersMg: false,
          isSuccesPostSortieConsultationMg: false,
          isSuccesPostRefereInterneMg: false,
          isSuccesGetRefereInterneMg: false,
          isSuccesPostRefereExterneMg: false,
          isSuccesGetRefereExterneMg: false,
          isNonfoundConsultation: false,
          isSuccesPostTerminerConsultationMg: false,
  
          isError: false,
          error: "",
        };
  
      case gTypes.CONSULTATION_GYNECO_RESET_AFTER:
        return {
          ...state,
          isLoadingGetConsultationMg: false,
  
          isSuccesGetConsultationMg: false,
        };
  
      case AUTH_ERROR:
      case LOGOUT_SUCCESS:
      case TOKEN_REFRESH_FAIL:
        localStorage.removeItem("token_access");
        localStorage.removeItem("token_refresh");
        return {
          ...state,
          consulterPatientMgConstante: {},
          maladiesMg: [],
          actesChirugicauxMg: [],
          consultationPatientMg: {},
  
          isLoadingGet: false,
          isLoadingGetMaladies: false,
          isLoadingGetActesChirugies: false,
          isLoadingPostConsultationMg: false,
          isLoadingGetConsultationMg: false,
          isLoadingPostMiseEnObservationMg: false,
          isLoadingPostDemandeHospitalisationMg: false,
          isLoadingPostActesInfirmiersMg: false,
          isLoadingPostSortieConsultationMg: false,
  
          isSuccesGet: false,
          isSuccesGetMaladies: false,
          isSuccesGetActesChirugies: false,
          isSuccesPostConsultationMg: false,
          isSuccesGetConsultationMg: false,
          isSuccesPostMiseEnObservationMg: false,
          isSuccesPostDemandeHospitalisationMg: false,
          isSuccesPostActesInfirmiersMg: false,
          isSuccesPostSortieConsultationMg: false,
  
          isError: false,
          error: "",
        };
  
      default:
        return state;
    }
  }
  