export const reportType = {
  GENERATE_NEW_REPORT_LOADING: "GENERATE_NEW_REPORT_LOADING",
  GENERATE_NEW_REPORT_SUCCESS: "GENERATE_NEW_REPORT_SUCCESS",
  GENERATE_NEW_REPORT_FAILED: "GENERATE_NEW_REPORT_FAILED",
  
  GET_REPORTS_LOADING: "GET_REPORTS_LOADING",
  GET_REPORTS_SUCCESS: "GET_REPORTS_SUCCESS",
  GET_REPORTS_FAILED: "GET_REPORTS_FAILED",
  
  GET_REPORT_BY_ID_LOADING: "GET_REPORT_BY_ID_LOADING",
  GET_REPORT_BY_ID_SUCCESS: "GET_REPORT_BY_ID_SUCCESS",
  GET_REPORT_BY_ID_FAILED: "GET_REPORT_BY_ID_FAILED",

  DELETE_REPORT_LOADING: "DELETE_REPORT_LOADING",
  DELETE_REPORT_SUCCESS: "DELETE_REPORT_SUCCESS",
  DELETE_REPORT_FAILED: "DELETE_REPORT_FAILED",

  GET_REPORT_TREATMENT_INDICATOR_LOADING: "GET_REPORT_TREATMENT_INDICATOR_LOADING",
  GET_REPORT_TREATMENT_INDICATOR_SUCCESS: "GET_REPORT_TREATMENT_INDICATOR_SUCCESS",
  GET_REPORT_TREATMENT_INDICATOR_FAILED: "GET_REPORT_TREATMENT_INDICATOR_FAILED",


  GET_REPORT_PRESTATION_ASSURANCE_LOADING: "GET_REPORT_PRESTATION_ASSURANCE_LOADING",
  GET_REPORT_PRESTATION_ASSURANCE_SUCCESS: "GET_REPORT_PRESTATION_ASSURANCE_SUCCESS",
  GET_REPORT_PRESTATION_ASSURANCE_FAILED: "GET_REPORT_PRESTATION_ASSURANCE_FAILED",


  GET_REPORT_PRESTATION_ASSURANCE_ALL_LOADING: "GET_REPORT_PRESTATION_ASSURANCE_ALL_LOADING",
  GET_REPORT_PRESTATION_ASSURANCE_ALL_SUCCESS: "GET_REPORT_PRESTATION_ASSURANCE_ALL_SUCCESS",
  GET_REPORT_PRESTATION_ASSURANCE_ALL_FAILED: "GET_REPORT_PRESTATION_ASSURANCE_ALL_FAILED",


  GET_REPORT_PRESTATION_ASSURANCE_CMU_LOADING: "GET_REPORT_PRESTATION_ASSURANCE_CMU_LOADING",
  GET_REPORT_PRESTATION_ASSURANCE_CMU_SUCCESS: "GET_REPORT_PRESTATION_ASSURANCE_CMU_SUCCESS",
  GET_REPORT_PRESTATION_ASSURANCE_CMU_FAILED: "GET_REPORT_PRESTATION_ASSURANCE_CMU_FAILED",

  GET_ALL_ASSURANCE_LOADING: "GET_ALL_ASSURANCE_LOADING",
  GET_ALL_ASSURANCE_SUCCESS: "GET_ALL_ASSURANCE_SUCCESS",
  GET_ALL_ASSURANCE_FAILED: "GET_ALL_ASSURANCE_FAILED",
  
  
  GET_PRESTATION_PAR_MOTIF_LOADING: "GET_PRESTATION_PAR_MOTIF_LOADING",
  GET_PRESTATION_PAR_MOTIF_SUCCESS: "GET_PRESTATION_PAR_MOTIF_SUCCESS",
  GET_PRESTATION_PAR_MOTIF_FAILED: "GET_PRESTATION_PAR_MOTIF_FAILED",
  

  GET_PRESTATION_PAR_SERVICE_LOADING: "GET_PRESTATION_PAR_SERVICE_LOADING",
  GET_PRESTATION_PAR_SERVICE_SUCCESS: "GET_PRESTATION_PAR_SERVICE_SUCCESS",
  GET_PRESTATION_PAR_SERVICE_FAILED: "GET_PRESTATION_PAR_SERVICE_FAILED",


  GET_PRESTATION_GRATUITE_LOADING: "GET_PRESTATION_GRATUITE_LOADING",
  GET_PRESTATION_GRATUITE_SUCCESS: "GET_PRESTATION_GRATUITE_SUCCESS",
  GET_PRESTATION_GRATUITE_FAILED: "GET_PRESTATION_GRATUITE_FAILED",


  GET_PRESTATION_TARIFS_REDUITS_LOADING: "GET_PRESTATION_TARIFS_REDUITS_LOADING",
  GET_PRESTATION_TARIFS_REDUITS_SUCCESS: "GET_PRESTATION_TARIFS_REDUITS_SUCCESS",
  GET_PRESTATION_TARIFS_REDUITS_FAILED: "GET_PRESTATION_TARIFS_REDUITS_FAILED",


  GET_REPORT_BY_CENTER_LOADING: "GET_REPORT_BY_CENTER_LOADING",
  GET_REPORT_BY_CENTER_SUCCESS: "GET_REPORT_BY_CENTER_SUCCESS",
  GET_REPORT_BY_CENTER_FAILED: "GET_REPORT_BY_CENTER_FAILED",
  

  GET_REPORT_BY_CENTER_ETAT_GENERAL_LOADING: "GET_REPORT_BY_CENTER_ETAT_GENERAL_LOADING",
  GET_REPORT_BY_CENTER_ETAT_GENERAL_SUCCESS: "GET_REPORT_BY_CENTER_ETAT_GENERAL_SUCCESS",
  GET_REPORT_BY_CENTER_ETAT_GENERAL_FAILED: "GET_REPORT_BY_CENTER_ETAT_GENERAL_FAILED",
  

  GET_LIST_CENTER_LOADING: "GET_LIST_CENTER_LOADING",
  GET_LIST_CENTER_SUCCESS: "GET_LIST_CENTER_SUCCESS",
  GET_LIST_CENTER_FAILED: "GET_LIST_CENTER_FAILED",
  

  GET_EVOLUTION_RECETTES_LOADING: "GET_EVOLUTION_RECETTES_LOADING",
  GET_EVOLUTION_RECETTES_SUCCESS: "GET_EVOLUTION_RECETTES_SUCCESS",
  GET_EVOLUTION_RECETTES_FAILED: "GET_EVOLUTION_RECETTES_FAILED",


  GET_EVOLUTION_FREQUENTATIONS_LOADING: "GET_EVOLUTION_FREQUENTATIONS_LOADING",
  GET_EVOLUTION_FREQUENTATIONS_SUCCESS: "GET_EVOLUTION_FREQUENTATIONS_SUCCESS",
  GET_EVOLUTION_FREQUENTATIONS_FAILED: "GET_EVOLUTION_FREQUENTATIONS_FAILED",


  GET_LIST_PROFESSIONNELS_LOADING: "GET_LIST_PROFESSIONNELS_LOADING",
  GET_LIST_PROFESSIONNELS_SUCCESS: "GET_LIST_PROFESSIONNELS_SUCCESS",
  GET_LIST_PROFESSIONNELS_FAILED: "GET_LIST_PROFESSIONNELS_FAILED",

  
  GET_LIST_SPECIALITE_PROFESSIONNELS_LOADING: "GET_LIST_SPECIALITE_PROFESSIONNELS_LOADING",
  GET_LIST_SPECIALITE_PROFESSIONNELS_SUCCESS: "GET_LIST_SPECIALITE_PROFESSIONNELS_SUCCESS",
  GET_LIST_SPECIALITE_PROFESSIONNELS_FAILED: "GET_LIST_SPECIALITE_PROFESSIONNELS_FAILED",

  
  GET_DETAILS_PROFESSIONNELS_SANTE_LOADING: "GET_DETAILS_PROFESSIONNELS_SANTE_LOADING",
  GET_DETAILS_PROFESSIONNELS_SANTE_SUCCESS: "GET_DETAILS_PROFESSIONNELS_SANTE_SUCCESS",
  GET_DETAILS_PROFESSIONNELS_SANTE_FAILED: "GET_DETAILS_PROFESSIONNELS_SANTE_FAILED",


  GET_REPORTS_ALL_PATIENTS_LOADING: "GET_REPORTS_ALL_PATIENTS_LOADING",
  GET_REPORTS_ALL_PATIENTS_SUCCESS: "GET_REPORTS_ALL_PATIENTS_SUCCESS",
  GET_REPORTS_ALL_PATIENTS_FAILED: "GET_REPORTS_ALL_PATIENTS_FAILED",


  GET_REPORTS_REFERENCEMENT_LOADING: "GET_REPORTS_REFERENCEMENT_LOADING",
  GET_REPORTS_REFERENCEMENT_SUCCESS: "GET_REPORTS_REFERENCEMENT_SUCCESS",
  GET_REPORTS_REFERENCEMENT_FAILED: "GET_REPORTS_REFERENCEMENT_FAILED",


  GET_REPORTS_ACTIVITES_LOADING: "GET_REPORTS_ACTIVITES_LOADING",
  GET_REPORTS_ACTIVITES_SUCCESS: "GET_REPORTS_ACTIVITES_SUCCESS",
  GET_REPORTS_ACTIVITES_FAILED: "GET_REPORTS_ACTIVITES_FAILED",


  GET_REPORTS_DEPISTAGE_CPN_MATERNITE_LOADING: "GET_REPORTS_DEPISTAGE_CPN_MATERNITE_LOADING",
  GET_REPORTS_DEPISTAGE_CPN_MATERNITE_SUCCESS: "GET_REPORTS_DEPISTAGE_CPN_MATERNITE_SUCCESS",
  GET_REPORTS_DEPISTAGE_CPN_MATERNITE_FAILED: "GET_REPORTS_DEPISTAGE_CPN_MATERNITE_FAILED",


  GET_REPORTS_MALADIES_LOADING: "GET_REPORTS_MALADIES_LOADING",
  GET_REPORTS_MALADIES_SUCCESS: "GET_REPORTS_MALADIES_SUCCESS",
  GET_REPORTS_MALADIES_FAILED: "GET_REPORTS_MALADIES_FAILED",


  GET_REPORTS_REDEVANCES_PERCUES_LOADING: "GET_REPORTS_REDEVANCES_PERCUES_LOADING",
  GET_REPORTS_REDEVANCES_PERCUES_SUCCESS: "GET_REPORTS_REDEVANCES_PERCUES_SUCCESS",
  GET_REPORTS_REDEVANCES_PERCUES_FAILED: "GET_REPORTS_REDEVANCES_PERCUES_FAILED",


  GET_REPORTS_SYNTHESE_LOADING: "GET_REPORTS_SYNTHESE_LOADING",
  GET_REPORTS_SYNTHESE_SUCCESS: "GET_REPORTS_SYNTHESE_SUCCESS",
  GET_REPORTS_SYNTHESE_FAILED: "GET_REPORTS_SYNTHESE_FAILED",

  
  GET_REPORTS_AGREGE_BILAN_CAISSE_LOADING: "GET_REPORTS_AGREGE_BILAN_CAISSE_LOADING",
  GET_REPORTS_AGREGE_BILAN_CAISSE_SUCCESS: "GET_REPORTS_AGREGE_BILAN_CAISSE_SUCCESS",
  GET_REPORTS_AGREGE_BILAN_CAISSE_FAILED: "GET_REPORTS_AGREGE_BILAN_CAISSE_FAILED",
  

  GET_REPORTS_PATIENT_PAR_SERVICE_LOADING: "GET_REPORTS_PATIENT_PAR_SERVICE_LOADING",
  GET_REPORTS_PATIENT_PAR_SERVICE_SUCCESS: "GET_REPORTS_PATIENT_PAR_SERVICE_SUCCESS",
  GET_REPORTS_PATIENT_PAR_SERVICE_FAILED: "GET_REPORTS_PATIENT_PAR_SERVICE_FAILED",

  
  GET_REPORTS_PATIENT_PAR_MOIS_LOADING: "GET_REPORTS_PATIENT_PAR_MOIS_LOADING",
  GET_REPORTS_PATIENT_PAR_MOIS_SUCCESS: "GET_REPORTS_PATIENT_PAR_MOIS_SUCCESS",
  GET_REPORTS_PATIENT_PAR_MOIS_FAILED: "GET_REPORTS_PATIENT_PAR_MOIS_FAILED",

  
  GET_REPORTS_NUTRITIONNEL_LOADING: "GET_REPORTS_NUTRITIONNEL_LOADING",
  GET_REPORTS_NUTRITIONNEL_SUCCESS: "GET_REPORTS_NUTRITIONNEL_SUCCESS",
  GET_REPORTS_NUTRITIONNEL_FAILED: "GET_REPORTS_NUTRITIONNEL_FAILED",
  

  GET_REPORTS_ACTIVITES_ESPC_LOADING: "GET_REPORTS_ACTIVITES_ESPC_LOADING",
  GET_REPORTS_ACTIVITES_ESPC_SUCCESS: "GET_REPORTS_ACTIVITES_ESPC_SUCCESS",
  GET_REPORTS_ACTIVITES_ESPC_FAILED: "GET_REPORTS_ACTIVITES_ESPC_FAILED",


  GET_REPORTS_LABORATOIRE_LOADING: "GET_REPORTS_LABORATOIRE_LOADING",
  GET_REPORTS_LABORATOIRE_SUCCESS: "GET_REPORTS_LABORATOIRE_SUCCESS",
  GET_REPORTS_LABORATOIRE_FAILED: "GET_REPORTS_LABORATOIRE_FAILED",


  GET_REPORTS_RADIOLOGIE_LOADING: "GET_REPORTS_RADIOLOGIE_LOADING",
  GET_REPORTS_RADIOLOGIE_SUCCESS: "GET_REPORTS_RADIOLOGIE_SUCCESS",
  GET_REPORTS_RADIOLOGIE_FAILED: "GET_REPORTS_RADIOLOGIE_FAILED",


  GET_REPORTS_ECHOGRAPHIE_LOADING: "GET_REPORTS_ECHOGRAPHIE_LOADING",
  GET_REPORTS_ECHOGRAPHIE_SUCCESS: "GET_REPORTS_ECHOGRAPHIE_SUCCESS",
  GET_REPORTS_ECHOGRAPHIE_FAILED: "GET_REPORTS_ECHOGRAPHIE_FAILED",


  GET_REPORTS_CONTROL_MAJOR_LOADING: "GET_REPORTS_CONTROL_MAJOR_LOADING",
  GET_REPORTS_CONTROL_MAJOR_SUCCESS: "GET_REPORTS_CONTROL_MAJOR_SUCCESS",
  GET_REPORTS_CONTROL_MAJOR_FAILED: "GET_REPORTS_CONTROL_MAJOR_FAILED",
  
};
