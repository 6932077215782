import { decesTypes } from "../types/decesTypes";
import * as userTypes from "../types/userTypes";

const initialState = {
    isLoading: false,
    isLoadingRefresh: false,
    data: [],
    error: "",
}

const decesReducer = (state = initialState, action) => {
    switch (action.type) {
        case decesTypes.DECES_LIST_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: "",
            }
        case decesTypes.DECES_LIST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload,
                error: "",
            }
        case decesTypes.DECES_LIST_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            }
        
        case decesTypes.DECES_LIST_REFRESH_REQUEST:
            return {
                ...state,
                isLoadingRefresh: true,
                error: "",
            }
        case decesTypes.DECES_LIST_REFRESH_SUCCESS:
            return {
                ...state,
                isLoadingRefresh: false,
                data: action.payload,
                error: "",
            }
        case decesTypes.DECES_LIST_REFRESH_FAIL:
            return {
                ...state,
                isLoadingRefresh: false,
                data: [],
                error: action.payload,
            }
        
        case userTypes.AUTH_ERROR:
        case userTypes.LOGOUT_SUCCESS:
        case userTypes.TOKEN_REFRESH_FAIL:
            localStorage.removeItem('token_access')
            localStorage.removeItem('token_refresh')
            return {
                ...state,
                isLoading: false,
                isLoadingRefresh: false,
                data: [],
                error: "",
            }
    
        default:
            return state;
    }
}

export default decesReducer