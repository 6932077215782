import React, { lazy } from "react"
import * as MyPrivateRouters from "../routes/PrivateRouter";
import { Switch } from 'react-router-dom';

const LayoutPediatriePatientAConsulter = lazy(() =>
    import(
      "../../components/pediatrie/patientAConsulter/LayoutPediatriePatientAConsulter"
    )
);
const LayoutPediatrieDemandeHospitalisationListe = lazy(() =>
import(
    "../../components/pediatrie/demandeHospitalisation/LayoutPediatrieDemandeHospitalisationListe"
)
);
const LayoutPediatrieConsultationListe = lazy(() =>
    import("../../components/pediatrie/consultation/LayoutPediatrieConsultationListe")
);

const LayoutPediatrieCreateConsultation = lazy(() =>
import(
    "../../components/pediatrie/patientAConsulterCreate/LayoutPediatrieCreateConsultation"
)
);
const LayoutPediatrieViewConsultation = lazy(() =>
import(
    "../../components/pediatrie/patientAConsulterDetail/LayoutViewConsultation"
)
);
const PediatrieObservation = lazy(() =>
    import("../../components/pediatrie/observation/PediatrieObservation")
);
const PediatrieObservationTerminees = lazy(() =>
    import("../../components/pediatrie/observationTerminees/PediatrieObservationTerminees")
);

const LayoutPediatrieOrdonnanceDetails = lazy(() =>
import(
    "../../components/pediatrie/ordonnance/detail/LayoutPediatrieOrdonnanceDetails"
)
);
const LayoutPediatrieCreateConsultationSortieDemandeHospitalisation = lazy(() =>
import(
    "../../components/pediatrie/patientAConsulterIssuSortie/DemandeHospitalisation/LayoutPediatrieCreateConsultationSortieDemandeHospitalisation"
)
);
const LayoutPediatrieCreateConsultationSortieMiseEnObservation = lazy(() =>
import(
    "../../components/pediatrie/patientAConsulterIssuSortie/MiseEnObservation/LayoutPediatrieCreateConsultationSortieMiseEnObservation"
)
);

const LayoutPediatrieCreateConsultationRefereInterne = lazy(() =>
import(
    "../../components/pediatrie/patientAConsulterIssuSortie/RefereInterne/LayoutPediatrieCreateConsultationRefereInterne"
)
);
const LayoutPediatrieCreateConsultationRefereExterne = lazy(() =>
import(
    "../../components/pediatrie/patientAConsulterIssuSortie/RefereExterne/LayoutPediatrieCreateConsultationRefereExterne"
)
);

const LayoutPediatrieCreateConsultationSortie = lazy(() =>
import(
    "../../components/pediatrie/patientAConsulterIssuSortie/LayoutPediatrieCreateConsultationSortie"
)
);
const LayoutPediatrieOrdonnanceListe = lazy(() =>
import(
    "../../components/pediatrie/ordonnance/liste/LayoutPediatrieOrdonnanceListe"
)
);
const LayoutPediatrieHospitalisationListe = lazy(() =>
    import(
        "../../components/pediatrie/hospitalisation/LayoutPediatrieHospitalisationListe"
    )
);

const LayoutPediatrieHospitalisationTermineesListe = lazy(() =>
    import(
        "../../components/pediatrie/hospitalisationTerminees/LayoutPediatrieHospitalisationTermineesListe"
    )
);

const LayoutPediatrieHospitalisationConsultationCreate = lazy(() =>
import(
    "../../components/pediatrie/hospitalisation/consultation/LayoutPediatrieHospitalisationConsultationCreate"
)
);

const LayoutPediatrieHospitalisationConsultationSortie = lazy(() =>
import(
    "../../components/pediatrie/hospitalisation/issueSortie/LayoutPediatrieHospitalisationConsultationSortie"
)
);

const LayoutPediatrieFeuilleDeSoins = lazy(() =>
    import("../../components/pediatrie/feuilleDeSoins/LayoutPediatrieFeuilleDeSoins")
);

const PediatrieRefExterne = lazy(() =>
    import("../../components/pediatrie/refExterne/PediatrieRefExterne")
);
const PediatrieRefInterne = lazy(() =>
    import("../../components/pediatrie/refInterne/PediatrieRefInterne")
);

const LayoutPediatrieDecesConsultationSortie = lazy(() =>
import(
    "../../components/pediatrie/patientAConsulterIssuSortie/IssueDeDeces/LayoutPediatrieDecesConsultationSortie"
)
);

const PediatrieExamensDetail = lazy(() =>
    import("../../components/pediatrie/Examens/detail")
);
const PediatrieExamensList = lazy(() =>
    import("../../components/pediatrie/Examens/list")
);
const LayoutPediatrieDecesListe = lazy(() =>
    import("../../components/pediatrie/Deces/Liste/LayoutPediatrieDecesListe")
);
const LayoutPediatrieDecesCertificat = lazy(() =>
import(
    "../../components/pediatrie/Deces/Certificat/LayoutPediatrieDecesCertificat"
)
);

const AgendaPediatrie = lazy(() =>
    import("../../components/pediatrie/rendezVous/agendaDetail/AgendaPediatrie")
);
const LayoutPediatrieCreateConsultationRendezVous = lazy(() =>
import(
    "../../components/pediatrie/patientAConsulterIssuSortie/RendezVous/LayoutPediatrieCreateConsultationRendezVous"
)
);
const LayoutPediatrieRdvListe = lazy(() =>
    import("../../components/pediatrie/rendezVous/Liste/LayoutPediatrieRdvListe")
);
const LayoutPediatrieRdvLastConsultation = lazy(() =>
    import("../../components/pediatrie/rendezVous/LastConsultation/LayoutPediatrieRdvLastConsultation")
);
const LayoutPediatrieRdvNewConsultation = lazy(() =>
    import("../../components/pediatrie/rendezVous/NewConsultation/LayoutPediatrieRdvNewConsultation")
);
const LayoutVoirPediatrieResultatsExamens = lazy(() =>
import(
    "../../components/pediatrie/Examens/resultatsExamensVoir/LayoutVoirMedecineGeneralResultatsExamens"
)
);

const RapportPediatrie = lazy(() => import("../../components/pediatrie/rapports/RapportPediatrie"));

const LayoutPediatrieControleMajor = lazy(() => 
    import("../../components/pediatrie/controleMajor/LayoutPediatrieControleMajor")
)
  

export default function PediatrieRouter() {
  return (
    <Switch>
        <MyPrivateRouters.PrivateRouterPediatrie
        path="/pediatrie"
        component={LayoutPediatriePatientAConsulter}
        />

        <MyPrivateRouters.PrivateRouterPediatrie
        path="/pediatrie-consultation-create/:id"
        component={LayoutPediatrieCreateConsultation}
        />

        <MyPrivateRouters.PrivateRouterPediatrie
        path="/pediatrie-consultation-voir-detail/:id"
        component={LayoutPediatrieViewConsultation}
        />

        <MyPrivateRouters.PrivateRouterPediatrie
        path="/pediatrie-consultation-sortie/:id"
        component={LayoutPediatrieCreateConsultationSortie}
        />

        <MyPrivateRouters.PrivateRouterPediatrie
        path="/pediatrie-consultation-sortie-demande-hospitalisation/:id"
        component={
            LayoutPediatrieCreateConsultationSortieDemandeHospitalisation
        }
        />

        <MyPrivateRouters.PrivateRouterPediatrie
        path="/pediatrie-consultation-sortie-mise-en-observation/:id"
        component={
            LayoutPediatrieCreateConsultationSortieMiseEnObservation
        }
        />

        <MyPrivateRouters.PrivateRouterPediatrie
        path="/pediatrie-consultation-sortie-refere-interne/:id"
        component={LayoutPediatrieCreateConsultationRefereInterne}
        />

        <MyPrivateRouters.PrivateRouterPediatrie
        path="/pediatrie-consultation-sortie-refere-externe/:id"
        component={LayoutPediatrieCreateConsultationRefereExterne}
        />

        <MyPrivateRouters.PrivateRouterPediatrie
        path="/pediatrie-consultation-sortie-deces/:id"
        component={LayoutPediatrieDecesConsultationSortie}
        />

        <MyPrivateRouters.PrivateRouterPediatrie
        path="/pediatrie-consultation-sortie-rdv/:id"
        component={LayoutPediatrieCreateConsultationRendezVous}
        />

        <MyPrivateRouters.PrivateRouterPediatrie
        path="/pediatrie-observation-exit-rdv/:id"
        component={LayoutPediatrieCreateConsultationRendezVous}
        />

        <MyPrivateRouters.PrivateRouterPediatrie
        path="/pediatrie-rdv-agenda/"
        component={AgendaPediatrie}
        />

        <MyPrivateRouters.PrivateRouterPediatrie
        path="/pediatrie-demande-hospitalisation"
        component={LayoutPediatrieDemandeHospitalisationListe}
        />

        <MyPrivateRouters.PrivateRouterPediatrie
            path="/pediatrie-hospitalisation-encours"
            component={LayoutPediatrieHospitalisationListe}
        />

        <MyPrivateRouters.PrivateRouterPediatrie
            path="/pediatrie-hospitalisation-terminee"
            component={LayoutPediatrieHospitalisationTermineesListe}
        />

        <MyPrivateRouters.PrivateRouterPediatrie
        path="/pediatrie-hospitalisation-consultation/:id"
        component={LayoutPediatrieHospitalisationConsultationCreate}
        />

        <MyPrivateRouters.PrivateRouterPediatrie
        path="/pediatrie-hospitalisation-sortie/:id"
        component={LayoutPediatrieHospitalisationConsultationSortie}
        />

        <MyPrivateRouters.PrivateRouterPediatrie
        path="/pediatrie-consultation"
        component={LayoutPediatrieConsultationListe}
        />

        <MyPrivateRouters.PrivateRouterPediatrie
        path="/pediatrie-feuille-de-soins/:id"
        component={LayoutPediatrieFeuilleDeSoins}
        />

        <MyPrivateRouters.PrivateRouterPediatrie
        path="/pediatrie-observation-encours"
        component={PediatrieObservation}
        />

        <MyPrivateRouters.PrivateRouterPediatrie
        path="/pediatrie-observation-terminee"
        component={PediatrieObservationTerminees}
        />

        <MyPrivateRouters.PrivateRouterPediatrie
        path="/pediatrie-exit-observation/:id"
        component={LayoutPediatrieCreateConsultationSortie}
        />

        <MyPrivateRouters.PrivateRouterPediatrie
        path="/pediatrie-observation-consultation/:id"
        component={LayoutPediatrieCreateConsultation}
        />

        <MyPrivateRouters.PrivateRouterPediatrie
        path="/pediatrie-rdv"
        component={LayoutPediatrieRdvListe}
        />

        <MyPrivateRouters.PrivateRouterPediatrie
        path="/pediatrie-rdv-last-consultation/:id"
        component={LayoutPediatrieRdvLastConsultation}
        />

        <MyPrivateRouters.PrivateRouterPediatrie
        path="/pediatrie-rdv-new-consultation/:id"
        component={LayoutPediatrieRdvNewConsultation}
        />

        <MyPrivateRouters.PrivateRouterPediatrie
        path="/pediatrie-ordonnance"
        component={LayoutPediatrieOrdonnanceListe}
        />

        <MyPrivateRouters.PrivateRouterPediatrie
        path="/pediatrie-ordonnance-voir/:id"
        component={LayoutPediatrieOrdonnanceDetails}
        />

        <MyPrivateRouters.PrivateRouterPediatrie
        path="/pediatrie-referencements-externe"
        component={PediatrieRefExterne}
        />

        <MyPrivateRouters.PrivateRouterPediatrie
        path="/pediatrie-referencements-interne"
        component={PediatrieRefInterne}
        />

        <MyPrivateRouters.PrivateRouterPediatrie
        path="/pediatrie-observation-exit-reference-interne/:id"
        component={LayoutPediatrieCreateConsultationRefereInterne}
        />

        <MyPrivateRouters.PrivateRouterPediatrie
        path="/pediatrie-observation-exit-reference-externe/:id"
        component={LayoutPediatrieCreateConsultationRefereExterne}
        />

        <MyPrivateRouters.PrivateRouterPediatrie
        path="/pediatrie-observation-exit-demande-hospitalisation/:id"
        component={
            LayoutPediatrieCreateConsultationSortieDemandeHospitalisation
        }
        />

        <MyPrivateRouters.PrivateRouterPediatrie
        path="/pediatrie-observation-exit-deces/:id"
        component={LayoutPediatrieDecesConsultationSortie}
        />

        <MyPrivateRouters.PrivateRouterPediatrie
        path="/pediatrie-examens-detail/:id/"
        component={PediatrieExamensDetail}
        />

        <MyPrivateRouters.PrivateRouterPediatrie
        path="/pediatrie-examens"
        component={PediatrieExamensList}
        />

        <MyPrivateRouters.PrivateRouterPediatrie
        path="/pediatrie-patients-decedes"
        component={LayoutPediatrieDecesListe}
        />

        <MyPrivateRouters.PrivateRouterPediatrie
        path="/pediatrie-patients-decedes-certificat/:id"
        component={LayoutPediatrieDecesCertificat}
        />

        <MyPrivateRouters.PrivateRouterPediatrie
        path="/pediatrie-examens-resultat-voir/:id"
        component={LayoutVoirPediatrieResultatsExamens}
        />

        <MyPrivateRouters.PrivateRouterPediatrie
        path="/pediatrie-rapports"
        component={RapportPediatrie}
        />

        <MyPrivateRouters.PrivateRouterPediatrie
        path="/pediatrie-controle-major"
        component={LayoutPediatrieControleMajor}
        />
    </Switch>
  )
}
