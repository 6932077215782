import Fuse from 'fuse.js'


const months = {
    0: 'Janvier',
    1: 'Février', 
    2: 'Mars', 
    3: 'Avril',
    4: 'Mai',
    5: 'Juin',
    6: 'Juillet',
    7: 'Aout',
    8: 'Septembre',
    9: 'Octobre',
    10: 'Novembre',
    11: 'Décembre'
}

const days = {
    0: 'Dimanche',
    1: 'Lundi',
    2: 'Mardi',
    3: 'Mercredi',
    4: 'Jeudi',
    5: 'Vendredi',
    6: 'Samedi'
}

export const dateToSlash = (date) => {
//    console.log('This is date from date To Slash :', date) 
    try {
        let left = date?.split('T')[0]
        let year = left?.split('-')[0]
        let month = left?.split('-')[1]
        let day = left?.split('-')[2]
    
        return `${day}/${month}/${year}`
    } catch (error) {
        return null
    }
}

export const dateFr = (date) => {
    let year = typeof date?.getFullYear() !== "undefined" ? date?.getFullYear() : null
    let month = typeof date?.getMonth() !== "undefined" ? date?.getMonth() : null
    let dayNumber = typeof date?.getDate() !== "undefined" ? date?.getDate() : null
    let day = typeof date?.getDay() !== "undefined" ? date?.getDay() : null
    // console.log("year :", year)
    // console.log("month :", month)
    // console.log("dayNumber :", dayNumber)
    // console.log("day :", day)
    return year !== null && month !== null && dayNumber !== null && day !== null ? `${days[day]}, ${dayNumber} ${months[month]} ${year}` : null
}

export const gen = (min, max, e = 1) => {
    let _min = Math.ceil(min)
    let _max = Math.floor(max)
    return Math.floor(Math.random() * (_max - _min) + _min) * e
}

// Formate la date et l'heure 
export const dateFr2 = (date) => {
    let year = date?.getFullYear()
    let month = date?.getMonth()
    let dayNumber = date?.getDate()
    //eslint-disable-next-line
    let day = date?.getDay()

    return `${dayNumber} ${months[month]} ${year}`
}

export const dateHeureFr = (date) => {
    let year = date?.getFullYear()
    let month = date?.getMonth()
    let dayNumber = date?.getDate()
    let day = date?.getDay()
    let heure = date?.getHours()
    let minute = date?.getMinutes()
    minute = !String(minute).charAt(1) ? '0' + minute : minute

    return `${days[day]}, ${dayNumber} ${months[month]} ${year} à ${heure}:${minute}`
}

export const dateHeureFr2 = (input) => {
    const date = new Date(input)
    let year = date?.getFullYear()
    let month = date?.getMonth()
    let dayNumber = date?.getDate()
    // let day = date?.getDay()
    let heure = date?.getHours()
    let minute = date?.getMinutes()
    let second = date?.getSeconds()
    minute = !String(minute).charAt(1) ? '0' + minute : minute

    return `${dayNumber} ${months[month]} ${year} à ${heure}:${minute}:${second}`
}

// Formate la date et l'heure en format locale (français) Lundi le 1 Janvier 2022 à 2:00
export const formateDateHeure = (value) => {
    const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        timeZone: "UTC",
      };
      return value?.toLocaleString("fr-FR", options);
}

// Formate la date et l'heure en format locale (français) Lundi le 1 Janvier 2022 à 2:00
export const formateDateTemplate = (value) => {
    const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
        timeZone: "UTC"
      };
      return value?.toLocaleString("fr-FR", options);
}

export const formateDate = (input) => {
    const d = new Date(input)
    const year = d?.getFullYear()
    const month = d?.getMonth()
    const day = d?.getDay()
    const date = d?.getDate()

    return `${days[day]}, ${date} ${months[month]} ${year}`
}

function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
}

// Converti une date new Date to Html input YY-MM-DD
export const dateToHtmlInput = (t) => {
    const year = t?.getFullYear()
    const month = padTo2Digits(t?.getMonth() + 1)
    const day = t?.getDate()
    //parseInt(t?.getDate()) >= 10 ? t?.getDate() : '0' + 

    return `${year}-${month}-${day}`
}


// export const paginate = (arr, size) => {
//     return arr.reduce((acc, val, i) => {
//         let idx = Math.floor(i / size)
//         let page = acc[idx] || (acc[idx] = [])
//         page.push(val)

//         return acc
//     }, [])
// }

export const formatMoney = (x) => {
    if (!x) {
        return "0"
    }
    if (x === 0) {
        return "0";
    }
    if (x) return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
}


export const generateDateSinceOne = (date) => {
    const options = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        timeZone: "UTC"
      };
    

    let date_start = new Date('2020-11-29T00:00:00.000Z')
    let date_current = new Date('2020-11-29T00:00:00.000Z')
    let date_end = new Date('2023-06-16T00:00:00.000Z')

    const resultats = []

    console.log("date_current", date_start.toLocaleString("fr-FR", options))
    let i = 1
    let j = 1
    while (date_current?.getTime() <= date_end?.getTime()){
        if(i < 5){
            date_current.setDate(date_current?.getDate() + 1)
            resultats.push(date_current.toLocaleString("fr-FR", options))
            date_current.setDate(date_current?.getDate() + 1)
            resultats.push(date_current.toLocaleString("fr-FR", options))
            date_current.setDate(date_current?.getDate() + 1)
            resultats.push(date_current.toLocaleString("fr-FR", options))
            date_current.setDate(date_current?.getDate() + 1)
            resultats.push(date_current.toLocaleString("fr-FR", options))
            date_current.setDate(date_current?.getDate() + 1)
            resultats.push(date_current.toLocaleString("fr-FR", options))

            i = 5
            j = 5
            console.log("Condition : Dans if i < 5")
        }else{
            if(j < 14){
                j = j + 1
                console.log("Condition : Dans if j < 14")
            }else{
                j = 1
                i = 1
                date_current.setDate(date_current?.getDate() + 9)
                console.log("Condition : Dans else j")
            }
            
        }
        
        // console.log("valeur i :", i)
        // console.log("date_current", date_current.toLocaleString("fr-FR", options))
    }

    console.log("resultats", resultats)
    
    return resultats
}

export const formatDateBackendStat = (date) => {
    if(!!date === true){
        console.log("true")
      let jour = date?.getDate()
      let mois = date?.getUTCMonth() + 1
      let annee = date?.getFullYear()

      if(jour < 10){
        jour = `0${jour}`
      }

      if(mois < 10){
        mois = `0${mois}`
      }

      return `${annee}-${mois}-${jour}`
    }else{
      return ''
    }
    
}

export const formatDateTimeBackendStat = (input) => {
    const date = new Date(input)
    let year = date?.getFullYear()
    let mois = date?.getUTCMonth() + 1
    let dayNumber = date?.getDate()
    // let day = date?.getDay()
    let heure = date?.getHours()
    let minute = date?.getMinutes()
    let second = date?.getSeconds()

    minute = !String(minute).charAt(1) ? '0' + minute : minute // affichage 01 au lieu de 1
    mois = !String(mois).charAt(1) ? '0' + mois : mois // affichage 01 au lieu de 1
    second = !String(second).charAt(1) ? '0' + second : second // affichage 01 au lieu de 1
    dayNumber = !String(dayNumber).charAt(1) ? '0' + dayNumber : dayNumber // affichage 01 au lieu de 1
    heure = !String(heure).charAt(1) ? '0' + heure : heure // affichage 01 au lieu de 1

    return `${year}-${mois}-${dayNumber} ${heure}:${minute}:${second}`
}

export const stringToSlug = (str) => { //Ne prend pas en compte les caractères 漢字, ñ, é, ü, î, ā, ă!
    return str
      .trim()
      .toLowerCase()
      .replace(/[\W_]+/g, '-')
      .replace(/^-+|-+$/g, '');
};

export const stringToSlugAdvanced = (str) => { //prend en compte les caractères 漢字, ñ, é, ü, î, ā, ă!
    return str
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/[\W_]+/g, '-')
      .toLowerCase()
      .replace(/^-+|-+$/g, '');
}

export const slugify = (str) => {
    return String(str)
      ?.normalize('NFKD') // split accented characters into their base characters and diacritical marks
      .replace(/[\u0300-\u036f]/g, '') // remove all the accents, which happen to be all in the \u03xx UNICODE block.
      ?.trim() // trim leading or trailing whitespace
      ?.toLowerCase() // convert to lowercase
      ?.replace(/[^a-z0-9 -]/g, '') // remove non-alphanumeric characters
      ?.replace(/\s+/g, '-') // replace spaces with hyphens
      ?.replace(/-+/g, '-'); // remove consecutive hyphens
}

export const parseNamePerson = (str) => {
    return String(str)
      ?.normalize('NFKD') // split accented characters into their base characters and diacritical marks
      ?.toLocaleLowerCase() // put in lower case
      ?.replace(/[\u0300-\u036f]/g, '') // remove all the accents, which happen to be all in the \u03xx UNICODE block.
      ?.trim() // trim leading or trailing whitespace
      ?.replace(/[^a-zA-Z - ']/g, '') // remove non-alphabetic characters
      ?.replace(/\s+/g, '-') // replace spaces with no thing
      ?.replace(/-+/g, '-'); // remove consecutive no thing
}

export const normalizeString = (str) => {
    return String(str)
      ?.normalize('NFKD') // split accented characters into their base characters and diacritical marks
      ?.toLocaleLowerCase() // put in lower case
      ?.replace(/[\u0300-\u036f]/g, '') // remove all the accents, which happen to be all in the \u03xx UNICODE block.
      ?.trim() // trim leading or trailing whitespace
      ?.replace(/[^a-zA-Z - ']/g, '') // remove non-alphabetic characters
      ?.replace(/\s+/g, '-') // replace spaces with no thing
      ?.replace(/-+/g, '-') // remove consecutive no thing
      ?.replace(/'+/g, '-'); // remove consecutive no thing
}

export const codeToTexte = (str) => {
    return String(str)
      ?.replace(/-+/g, ' ') // replace spaces with no thing
}

export const regrexVerifyCode = (str) => {
    const regexCode = /^[dD][0-9]{2}[a-zA-Z][0-9]{4}[a-zA-Z]{3}$/g
    const regexCodeOld = /^[hH][gG][aA]-[0-9]{4}-[a-zA-Z]{2}[0-9]{5}-[0-9]{6}$/g //HGA-2023-XC31305-599987
    const found = str?.match(regexCode)
    const foundOld = str?.match(regexCodeOld)
    console.log("found", found)
    return (!!found || !!foundOld) ? true : false
}

export const getDateEnglishFormatYearMonthDay = (date) => {
    const dateValue = date?? ""
    return  !!dateValue ? dateValue
    .toLocaleDateString("fr")
    .replace(/\//g, "-")
    .split("-")
    .reverse()
    .join("-")
    : ""
}


export const findElementInListByInput = (input,list,keysFilter) =>{

    // exemple keys = keys: [
	// 	"title",
	// 	"author.firstName"
	// ]

    const fuseOptions = {
        // isCaseSensitive: false,
        // includeScore: false,
        // shouldSort: true,
        // includeMatches: false,
        // findAllMatches: false,
        // minMatchCharLength: 1,
        // location: 0,
        // threshold: 0.6,
        // distance: 100,
        // useExtendedSearch: false,
        // ignoreLocation: false,
        // ignoreFieldNorm: false,
        // fieldNormWeight: 1,
        keys: keysFilter
    };

    const fuse = new Fuse(list, fuseOptions);
    return fuse.search(input??"")  
}

export const formatPhoneNumber = (value) => {
    //(+225) 07 8897 7779
    if(!value) return value
    console.log("value", value)
    const valueNumber = value?.replace(/\s/g, '')
    const valueNumberStart = valueNumber?.slice(0, 6) //(+225)
    const valueNumberRest = valueNumber?.slice(6) // 07 8897 7779
    const valueNumberRestLength = valueNumberRest?.length
    console.log("valueNumber", `'${valueNumber}'`)
    console.log("valueNumberStart", `'${valueNumberStart}'`)
    console.log("valueNumberRest", `'${valueNumberRest}'`)
  
    if(valueNumberRestLength < 3) return `${valueNumberStart} ${valueNumberRest}`
  
    if(valueNumberRestLength < 7){ 
      return `${valueNumberStart} ${valueNumberRest?.slice(0,2)} ${valueNumberRest?.slice(2)}`
    }
  
    if(valueNumberRestLength < 11){ 
      return `${valueNumberStart} ${valueNumberRest?.slice(0,2)} ${valueNumberRest?.slice(2,6)} ${valueNumberRest?.slice(6)}`
    }
}

export const range = (x) => {
    return [...Array(x).keys()];
}

export const showRolePermission = (data=[]) => {
    //(+225) 07 8897 7779
    if(!data?.length) return ["Aucune permission accordée"]
    let result = []
    for (let index = 0; index < data.length; index++) {
        const element = data[index]

        if (element?.name === "administrateurs") {
            result?.push("Administrateur")
        }else if (element?.name === "directeurs") {
            result?.push("Directeur")
        }else if (element?.name === "caissiers") {
            result?.push("Caissier")
        }else if (element?.name === "dcs") {
            result?.push("Directeur Commercial")
        }else if (element?.name === "medecins") {
            result?.push("Médecin")
        }else if (element?.name === "rhs") {
            result?.push("Ressource Humaine")
        }else if (element?.name === "infirmiers") {
            result?.push("Infirmier")
        }else if (element?.name === "laborantins") {
            result?.push("Laboratin")
        }else if (element?.name === "comptables") {
            result?.push("Comptable")
        }else if (element?.name === "regisseurs") {
            result?.push("Regisseur")
        }else if (element?.name === "administratifs") {
            result?.push("Agent Administratif")
        }else{
            result?.push("Super Administrateur")
        }
    }

    return result?.join(", ")
}

export const combineValueLabelShow = (value, data=[]) => {
    if(!data?.length) return null
    let result = null

    for (let index = 0; index < data.length; index++) {
        const element = data[index]

        if (element?.value === value) {
            result = {value: element?.value, label: element?.label}
        }
    }

    return result
}