import React, { lazy } from "react"
import * as MyPrivateRouters from "../routes/PrivateRouter"
import { Switch } from 'react-router-dom';

const LayoutAccueilPatientListe = lazy(() =>
    import("../../components/accueils/patient/PatientListe/LayoutAccueilPatientListe")
);
const LayoutAccueilPatientCreate = lazy(() =>
    import(
        "../../components/accueils/patient/PatientCreate/LayoutAccueilPatientCreate"
    )
);
const LayoutAccueilPatientUpdate = lazy(() =>
    import(
        "../../components/accueils/patient/PatientUpdate/LayoutAccueilPatientUpdate"
    )
);
const LayoutAccueilPatientDetails = lazy(() =>
    import(
        "../../components/accueils/patient/PatientVoirPlus/LayoutAccueilPatientDetails"
    )
);

const LayoutAccueilFichesPaiementListe = lazy(() =>
    import(
        "../../components/accueils/fichespaiement/fichespaiementListe/LayoutAccueilFichesPaiementListe"
    )
);
const LayoutAccueilFichesPaiementCreate = lazy(() =>
    import(
        "../../components/accueils/fichespaiement/fichepaiementCreate/LayoutAccueilFichesPaiementCreate"
    )
);
const LayoutAccueilFichePaiementDetails = lazy(() =>
    import(
        "../../components/accueils/fichespaiement/fichepaiementVoir/LayoutAccueilFichePaiementDetails"
    )
);
const LayoutAccueilFichePaiementUpdate = lazy(() =>
    import(
        "../../components/accueils/fichespaiement/fichepaiementUpdate/LayoutAccueilFichePaiementUpdate"
    )
);

const LayoutAccueilFichePaiementMiseAJour = lazy(() =>
    import(
        "../../components/accueils/fichespaiement/fichepaiementMiseAJour/LayoutAccueilFichePaiementMiseAJour"
    )
);

const LayoutAccueilRdvListe = lazy(() =>
    import("../../components/accueils/rdv/liste/LayoutAccueilRdvListe"  )
);
const LayoutAccueilRdvCreate = lazy(() =>
    import("../../components/accueils/rdv/create/LayoutAccueilRdvCreate"    )
);
const LayoutRdvDetails = lazy(() =>
    import("../../components/accueils/rdv/create/LayoutRdvDetails"  )
);

const LayoutAccueilReorientation = lazy(() =>
    import("../../components/accueils/reorientation/LayoutAccueilReorientation" )
);

const LayoutAccueilReferenceExterne = lazy(() =>
    import("../../components/accueils/refExterne/LayoutAccueilReferenceExterne" )
);

const LayoutAccueilReferenceInterne = lazy(() =>
    import("../../components/accueils/refInterne/LayoutAccueilReferenceInterne" )
);

const LayoutAccueilHospitalisation = lazy(() =>
    import("../../components/accueils/hospitalisation/LayoutAccueilHospitalisation" )
);

export default function AccueilRouter() {
  return (
    <Switch>
        <MyPrivateRouters.PrivateRouterAccueils
            path="/bureau-des-entrees"
            component={LayoutAccueilPatientListe}
        />

        <MyPrivateRouters.PrivateRouterAccueils
            path="/accueil"
            component={LayoutAccueilPatientListe}
        />

        <MyPrivateRouters.PrivateRouterAccueils
            path="/accueil-patient-voir-plus/:id"
            component={LayoutAccueilPatientDetails}
        />

        <MyPrivateRouters.PrivateRouterAccueils
            path="/accueil-patient-create"
            component={LayoutAccueilPatientCreate}
        />

        <MyPrivateRouters.PrivateRouterAccueils
            path="/accueil-patient-update/:id"
            component={LayoutAccueilPatientUpdate}
        />

        <MyPrivateRouters.PrivateRouterAccueils
            path="/accueil-fichespaiement"
            component={LayoutAccueilFichesPaiementListe}
        />

        <MyPrivateRouters.PrivateRouterAccueils
            path="/accueil-fichespaiement-create/:id"
            component={LayoutAccueilFichesPaiementCreate}
        />

        <MyPrivateRouters.PrivateRouterAccueils
            path="/accueil-fichespaiement-voir/:id"
            component={LayoutAccueilFichePaiementDetails}
        />

        <MyPrivateRouters.PrivateRouterAccueils
            path="/accueil-fichespaiement-update/:id"
            component={LayoutAccueilFichePaiementUpdate}
        />

        <MyPrivateRouters.PrivateRouterAccueils
            path="/accueil-fichespaiement-mise-a-jour/:id"
            component={LayoutAccueilFichePaiementMiseAJour}
        />

        <MyPrivateRouters.PrivateRouterAccueils
            path="/accueil-rdv"
            component={LayoutAccueilRdvListe}
        />

        <MyPrivateRouters.PrivateRouterAccueils
            path="/accueil-rdv-create"
            component={LayoutAccueilRdvCreate}
        />

        <MyPrivateRouters.PrivateRouterAccueils
            path="/accueil-reorientation"
            component={LayoutAccueilReorientation}
        />

        <MyPrivateRouters.PrivateRouterAccueils
            path="/test"
            component={LayoutRdvDetails}
        />

        <MyPrivateRouters.PrivateRouterAccueils
            path="/accueil-referencements-externe"
            component={LayoutAccueilReferenceExterne}
        />

        <MyPrivateRouters.PrivateRouterAccueils
            path="/accueil-referencements-interne"
            component={LayoutAccueilReferenceInterne}
        />

        <MyPrivateRouters.PrivateRouterAccueils
            path="/accueil-hospitalisations"
            component={LayoutAccueilHospitalisation}
        />
    </Switch>
  )
}
