import { patientConsulteTypes } from "../types/patientConsulteTypes";
import { AUTH_ERROR, LOGOUT_SUCCESS, TOKEN_REFRESH_FAIL } from "../types/userTypes";

const initialState = {
  isLoading: false,
  isLoadingRefresh: false,
  patients: [],
  error: null,
};

const patientConsulteReducer = (state = initialState, action) => {
  switch (action.type) {
    case patientConsulteTypes.PATIENT_CONSULTE_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
        patients: [],
      };
    case patientConsulteTypes.PATIENT_CONSULTE_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        patients: action.payload,
        error: null,
      };
    case patientConsulteTypes.PATIENT_CONSULTE_LIST_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        patients: [],
      };

    
    case patientConsulteTypes.PATIENT_CONSULTE_LIST_REFRESH_REQUEST:
      return {
        ...state,
        isLoadingRefresh: true,
        error: null,
        patients: [],
      };
    case patientConsulteTypes.PATIENT_CONSULTE_LIST_REFRESH_SUCCESS:
      return {
        ...state,
        isLoadingRefresh: false,
        patients: action.payload,
        error: null,
      };
    case patientConsulteTypes.PATIENT_CONSULTE_LIST_REFRESH_FAIL:
      return {
        ...state,
        isLoadingRefresh: false,
        patients:[],
        error: action.payload,
      };

    case AUTH_ERROR:
    case LOGOUT_SUCCESS:
    case TOKEN_REFRESH_FAIL:
        localStorage.removeItem('token_access')
        localStorage.removeItem('token_refresh')
        return {
            ...state,
            isLoading: false,
            isLoadingRefresh: false,
            patients: [],
            error: null
        }

    default:
      return state;
  }
};

export default patientConsulteReducer;
