export const DEMANDE_EXAMEN_LABORATOIRE_GET_LOADING =  "DEMANDE_EXAMEN_LABORATOIRE_GET_LOADING"

export const DEMANDE_EXAMEN_LABORATOIRE_GET_SUCCESS =  "DEMANDE_EXAMEN_LABORATOIRE_GET_SUCCESS"

export const DEMANDE_EXAMEN_LABORATOIRE_GET_FAILED =  "DEMANDE_EXAMEN_LABORATOIRE_GET_FAILED"


export const DEMANDES_EXAMENS_LABORATOIRE_GETS_LOADING =  "DEMANDES_EXAMENS_LABORATOIRE_GETS_LOADING"

export const DEMANDES_EXAMENS_LABORATOIRE_GETS_SUCCESS =  "DEMANDES_EXAMENS_LABORATOIRE_GETS_SUCCESS"

export const DEMANDES_EXAMENS_LABORATOIRE_GETS_FAILED =  "DEMANDES_EXAMENS_LABORATOIRE_GETS_FAILED"


export const RESULTAT_DEMANDE_EXAMEN_LABORATOIRE_GET_LOADING =  "RESULTAT_DEMANDE_EXAMEN_LABORATOIRE_GET_LOADING"

export const RESULTAT_DEMANDE_EXAMEN_LABORATOIRE_GET_SUCCESS =  "RESULTAT_DEMANDE_EXAMEN_LABORATOIRE_GET_SUCCESS"

export const RESULTAT_DEMANDE_EXAMEN_LABORATOIRE_GET_FAILED =  "RESULTAT_DEMANDE_EXAMEN_LABORATOIRE_GET_FAILED"


export const RESULTAT_DEMANDE_EXAMEN_LABORATOIRE_POST_LOADING =  "RESULTAT_DEMANDE_EXAMEN_LABORATOIRE_POST_LOADING"

export const RESULTAT_DEMANDE_EXAMEN_LABORATOIRE_POST_SUCCESS =  "RESULTAT_DEMANDE_EXAMEN_LABORATOIRE_POST_SUCCESS"

export const RESULTAT_DEMANDE_EXAMEN_LABORATOIRE_POST_FAILED =  "RESULTAT_DEMANDE_EXAMEN_LABORATOIRE_POST_FAILED"


export const RESULTATS_DEMANDES_EXAMENS_LABORATOIRES_GETS_LOADING =  "RESULTATS_DEMANDES_EXAMENS_LABORATOIRES_GETS_LOADING"

export const RESULTATS_DEMANDES_EXAMENS_LABORATOIRES_GETS_SUCCESS =  "RESULTATS_DEMANDES_EXAMENS_LABORATOIRES_GETS_SUCCESS"

export const RESULTATS_DEMANDES_EXAMENS_LABORATOIRES_GETS_FAILED =  "RESULTATS_DEMANDES_EXAMENS_LABORATOIRES_GETS_FAILED"


export const RESULTATS_DEMANDES_EXAMENS_LABORATOIRES_RESET =  "RESULTATS_DEMANDES_EXAMENS_LABORATOIRES_RESET"


export const RESULTATS_DEMANDES_EXAMENS_LABORATOIRES_RESET_AFTER = "RESULTATS_DEMANDES_EXAMENS_LABORATOIRES_RESET_AFTER"

export const GET_EXAMENS_PARAMETRES_REQUEST = "GET_EXAMENS_PARAMETRES_REQUEST"
export const GET_EXAMENS_PARAMETRES_SUCCESS = "GET_EXAMENS_PARAMETRES_SUCCESS"
export const GET_EXAMENS_PARAMETRES_FAILED = "GET_EXAMENS_PARAMETRES_FAILED"



export const GET_LABORATOIRE_REFERENCE_RESULTATS_LOADING = "GET_LABORATOIRE_REFERENCE_RESULTATS_LOADING"
export const GET_LABORATOIRE_REFERENCE_RESULTATS_SUCCESS = "GET_LABORATOIRE_REFERENCE_RESULTATS_SUCCESS"
export const GET_LABORATOIRE_REFERENCE_RESULTATS_FAILED = "GET_LABORATOIRE_REFERENCE_RESULTATS_FAILED"


export const GET_LABORATOIRE_RESULTATS_MULTIPLE_LOADING = "GET_LABORATOIRE_RESULTATS_MULTIPLE_LOADING"
export const GET_LABORATOIRE_RESULTATS_MULTIPLE_SUCCESS = "GET_LABORATOIRE_RESULTATS_MULTIPLE_SUCCESS"
export const GET_LABORATOIRE_RESULTATS_MULTIPLE_FAILED = "GET_LABORATOIRE_RESULTATS_MULTIPLE_FAILED"
