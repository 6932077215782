import { utilisateursTypes } from "../types/utilisateursTypes";
import { AUTH_ERROR, LOGOUT_SUCCESS, TOKEN_REFRESH_FAIL } from "../types/userTypes";

const initialState = {
  isLoading: false,
  success: false,
  error: "",
  successAlert: false,
  errorAlert: false,
  isLoadingGet: false,
  successGet: false,
  userData:{},
  detail: false,
  typeRequest: null,
  postData:{},
  data:[],

  desactivateLoader: false,
  desactivateSuccess: false,
  desactivateError: false,

  restoreLoader: false,
  restoreSuccess: false,
  restoreError: false,
};

const utilisateursReducers = (state = initialState, action) => {
  switch (action.type) {
    case utilisateursTypes.UTILISATEUR_POST_REQUEST:
      return {
        ...state,
        isLoading: true,
        success: false,
        error: "",
        typeRequest:"POST",
        successAlert: false,
        errorAlert: false,
      };
    case utilisateursTypes.UTILISATEUR_POST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
        error: "",
        typeRequest:"POST",
        data: [action.payload, ...state.data],
        postData: action.payload,
        successAlert: true,
        errorAlert: false,
      };
    case utilisateursTypes.UTILISATEUR_POST_FAILED:
      return {
        ...state,
        isLoading: false,
        success: false,
        error: action.payload,
        typeRequest:"POST",
        successAlert: false,
        errorAlert: true,
      };
    case utilisateursTypes.UTILISATEUR_POST_FAILED_400:
      return {
        ...state,
        isLoading: false,
        success: false,
        typeRequest:"POST",
        error: action.payload,
        successAlert: false,
        errorAlert: true,
      };
    case utilisateursTypes.UTILISATEUR_POST_FAILED_500:
      return {
        ...state,
        isLoading: false,
        success: false,
        error: action.payload,
        successAlert: false,
        typeRequest:"POST",
        errorAlert: true,
      };

    case utilisateursTypes.UTILISATEUR_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
        success: false,
        error: "",
        successAlert: false,
        errorAlert: false,
      };
    case utilisateursTypes.UTILISATEUR_LIST_SUCCESS:
      console.log("action.payload", action.payload)
      return {
        ...state,
        isLoading: false,
        success: false,
        error: "",
        data: action.payload, //action.payload
        successAlert: false,
        errorAlert: false,
      };
    case utilisateursTypes.UTILISATEUR_LIST_FAILED:
      return {
        ...state,
        isLoading: false,
        success: false,
        error: action.payload,
        successAlert: false,
        errorAlert: false,
        data:[]
      };

    
    case utilisateursTypes.UTILISATEUR_LIST_REFRESH_REQUEST:
      return {
        ...state,
        isLoadingRefresh: true,
        success: false,
        error: "",
        successAlert: false,
      };
    case utilisateursTypes.UTILISATEUR_LIST_REFRESH_SUCCESS:
      return {
        ...state,
        isLoadingRefresh: false,
        success: false,
        error: "",
        data: action.payload, //action.payload
        successAlert: false,
      };
    case utilisateursTypes.UTILISATEUR_LIST_REFRESH_FAILED:
      return {
        ...state,
        isLoadingRefresh: false,
        success: false,
        error: action.payload,
        successAlert: false,
        errorAlert: false
      };

    
    case utilisateursTypes.UTILISATEUR_GET_REQUEST:
      return {
        ...state,
        isLoadingGet: true,
        successGet: false,
        userData:{}
      };
    case utilisateursTypes.UTILISATEUR_GET_SUCCESS:
      // console.log(action.payload)
      return {
        ...state,
        isLoadingGet: false,
        successGet: false,
        error: "",
        userData: action.payload, //action.payload
      };
    case utilisateursTypes.UTILISATEUR_GET_FAILED:
      return {
        ...state,
        isLoadingGet: false,
        successGet: false,
        error: action.payload,
        userData:{}
      };
    
    case utilisateursTypes.UTILISATEUR_UPDATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        success: false,
        error: "",
        successAlert: false,
        errorAlert: false,
        typeRequest:"UPDATE",
      };
    case utilisateursTypes.UTILISATEUR_UPDATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
        error: "",
        data: state.data.map((user) => user.id === action.payload.id ? action.payload : user),
        successAlert: true,
        errorAlert: false,
        typeRequest:"UPDATE",
      };
    case utilisateursTypes.UTILISATEUR_UPDATE_FAILED:
      return {
        ...state,
        isLoading: false,
        success: false,
        error: action.payload,
        successAlert: false,
        errorAlert: true,
        typeRequest:"UPDATE",
      };
    case utilisateursTypes.DIALOG_MESSAGE_CLOSE:
      return {
        ...state,
        isLoading: false,
        success: false,
        error: "",
        successAlert: false,
        errorAlert: false,
        isLoadingGet: false,
        successGet: false,
        isSuccessDelete: false,
        isErrorDelete: false,
      };
    case utilisateursTypes.UTILISATEUR_DELETE_REQUEST:
      return {
        ...state,
        isLoading: false,
        isSuccessDelete: false,
        isErrorDelete: false,
        error: ""
      }
    case utilisateursTypes.UTILISATEUR_DELETE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccessDelete: true,
        isErrorDelete: false,
        data: state.data.filter((user) => user.id !== action.payload),
        error: ""
      }
    case utilisateursTypes.UTILISATEUR_DELETE_FAILED:
      return {
        ...state,
        isLoading: false,
        isSuccessDelete: false,
        isErrorDelete: true,
        error: action.payload
      }

    case utilisateursTypes.DESACTIVER_REQUEST:
      return {
        ...state,
        desactivateLoader: true,
        desactivateSuccess: false,
        desactivateError: false,
      }
    case utilisateursTypes.DESACTIVER_SUCCESS:
      return {
        ...state,
        data: state.data.filter((user) => user.id !== action.payload),
        desactivateLoader: false,
        desactivateSuccess: true,
        desactivateError: false,
      }
    case utilisateursTypes.DESACTIVER_FAILED:
      return {
        ...state,
        desactivateLoader: false,
        desactivateSuccess: false,
        desactivateError: true,
      }
    case utilisateursTypes.RESTAURER_REQUEST:
      return {
        ...state,
        restoreLoader: true,
        restoreSuccess: false,
        restoreError: false,
      }
    case utilisateursTypes.RESTAURER_SUCCESS:
      return {
        ...state,
        restoreLoader: false,
        restoreSuccess: true,
        restoreError: false,
      }
    case utilisateursTypes.RESTAURER_FAILED:
      return {
        ...state,
        restoreLoader: false,
        restoreSuccess: false,
        restoreError: true,
      }
    case utilisateursTypes.DESACTIVER_CLOSE_MODAL:
      return {
        ...state,
        desactivateLoader: false,
        desactivateSuccess: false,
        desactivateError: false,
        restoreLoader: false,
        restoreSuccess: false,
        restoreError: false,
      }

    case AUTH_ERROR:
    case LOGOUT_SUCCESS:
    case TOKEN_REFRESH_FAIL:
      localStorage.removeItem("token_access");
      localStorage.removeItem("token_refresh");
      return {
        ...state,
        isLoading: false,
        success: false,
        error: "",
        successAlert: false,
        errorAlert: false,
        isLoadingGet: false,
        successGet: false,
        isSuccessDelete: false,
        isErrorDelete: false,
        userData:{},
        detail: false,
        data:[]
      };

    default:
      return state;
  }
};

export default utilisateursReducers;
