import { soinsActesTypes } from "../types/soinsActesTypes";

const initialState = {
    isLoading: false,
    data: [],
    error: null,
}

const soinsActesReducer = (state=initialState, action) => {
    switch (action.type) {
        case soinsActesTypes.SOINS_ACTES_LIST_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: null,
            }
        case soinsActesTypes.SOINS_ACTES_LIST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload,
                error: null,
            }
        case soinsActesTypes.SOINS_ACTES_LIST_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            }
    
        default:
            return state;
    }
}

export default soinsActesReducer;