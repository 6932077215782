export const SITUATION_MATRIMONIALE_GET_DATA_LOADING = 'SITUATION_MATRIMONIALE_GET_DATA_LOADING'

export const SITUATION_MATRIMONIALE_GET_DATA_SUCCESS = 'SITUATION_MATRIMONIALE_GET_DATA_SUCCESS'

export const SITUATION_MATRIMONIALE_GET_DATA_FAILED = 'SITUATION_MATRIMONIALE_GET_DATA_FAILED'

export const SITUATION_MATRIMONIALE_GET_DATA_FAILED_403 = "SITUATION_MATRIMONIALE_GET_DATA_FAILED_403"

export const SITUATION_MATRIMONIALE_GET_DATA_FAILED_500 = "SITUATION_MATRIMONIALE_GET_DATA_FAILED_500"


export const SITUATION_MATRIMONIALE_POST_DATA_LOADING = 'SITUATION_MATRIMONIALE_POST_DATA_LOADING'

export const SITUATION_MATRIMONIALE_POST_DATA_SUCCESS = 'SITUATION_MATRIMONIALE_POST_DATA_SUCCESS'

export const SITUATION_MATRIMONIALE_POST_DATA_FAILED = 'SITUATION_MATRIMONIALE_POST_DATA_FAILED'

export const SITUATION_MATRIMONIALE_POST_DATA_FAILED_403 = "SITUATION_MATRIMONIALE_POST_DATA_FAILED_403"

export const SITUATION_MATRIMONIALE_POST_DATA_FAILED_500 = "SITUATION_MATRIMONIALE_POST_DATA_FAILED_500"


export const SITUATION_MATRIMONIALE_PUT_DATA_LOADING = 'SITUATION_MATRIMONIALE_PUT_DATA_LOADING'

export const SITUATION_MATRIMONIALE_PUT_DATA_SUCCESS = 'SITUATION_MATRIMONIALE_PUT_DATA_SUCCESS'

export const SITUATION_MATRIMONIALE_PUT_DATA_FAILED = 'SITUATION_MATRIMONIALE_PUT_DATA_FAILED'

export const SITUATION_MATRIMONIALE_PUT_DATA_FAILED_403 = "SITUATION_MATRIMONIALE_PUT_DATA_FAILED_403"

export const SITUATION_MATRIMONIALE_PUT_DATA_FAILED_500 = "SITUATION_MATRIMONIALE_PUT_DATA_FAILED_500"


export const SITUATION_MATRIMONIALE_DELETE_DATA_LOADING = 'SITUATION_MATRIMONIALE_DELETE_DATA_LOADING'

export const SITUATION_MATRIMONIALE_DELETE_DATA_SUCCESS = 'SITUATION_MATRIMONIALE_DELETE_DATA_SUCCESS'

export const SITUATION_MATRIMONIALE_DELETE_DATA_FAILED = 'SITUATION_MATRIMONIALE_DELETE_DATA_FAILED'

export const SITUATION_MATRIMONIALE_DELETE_DATA_FAILED_403 = "SITUATION_MATRIMONIALE_DELETE_DATA_FAILED_403"

export const SITUATION_MATRIMONIALE_DELETE_DATA_FAILED_500 = "SITUATION_MATRIMONIALE_DELETE_DATA_FAILED_500"


export const SITUATION_MATRIMONIALE_GETS_DATA_LOADING = 'SITUATION_MATRIMONIALE_GETS_DATA_LOADING'

export const SITUATION_MATRIMONIALE_GETS_DATA_SUCCESS = 'SITUATION_MATRIMONIALE_GETS_DATA_SUCCESS'

export const SITUATION_MATRIMONIALE_GETS_DATA_FAILED = 'SITUATION_MATRIMONIALE_GETS_DATA_FAILED'

export const SITUATION_MATRIMONIALE_GETS_DATA_FAILED_403 = "SITUATION_MATRIMONIALE_GETS_DATA_FAILED_403"

export const SITUATION_MATRIMONIALE_GETS_DATA_FAILED_500 = "SITUATION_MATRIMONIALE_GETS_DATA_FAILED_500"


export const SITUATION_MATRIMONIALE_GETS_REFRESH_DATA_LOADING = 'SITUATION_MATRIMONIALE_GETS_REFRESH_DATA_LOADING'

export const SITUATION_MATRIMONIALE_GETS_REFRESH_DATA_SUCCESS = 'SITUATION_MATRIMONIALE_GETS_REFRESH_DATA_SUCCESS'

export const SITUATION_MATRIMONIALE_GETS_REFRESH_DATA_FAILED = 'SITUATION_MATRIMONIALE_GETS_REFRESH_DATA_FAILED'

export const SITUATION_MATRIMONIALE_GETS_REFRESH_DATA_FAILED_403 = "SITUATION_MATRIMONIALE_GETS_REFRESH_DATA_FAILED_403"

export const SITUATION_MATRIMONIALE_GETS_REFRESH_DATA_FAILED_500 = "SITUATION_MATRIMONIALE_GETS_REFRESH_DATA_FAILED_500"


export const SITUATION_MATRIMONIALE_CLEAR_STATE = "SITUATION_MATRIMONIALE_CLEAR_STATE"