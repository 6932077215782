export const MOTIF_GLOBALE_GET_DATA_LOADING = 'MOTIF_GLOBALE_GET_DATA_LOADING'

export const MOTIF_GLOBALE_GET_DATA_SUCCESS = 'MOTIF_GLOBALE_GET_DATA_SUCCESS'

export const MOTIF_GLOBALE_GET_DATA_FAILED = 'MOTIF_GLOBALE_GET_DATA_FAILED'

export const MOTIF_GLOBALE_GET_DATA_FAILED_403 = "MOTIF_GLOBALE_GET_DATA_FAILED_403"

export const MOTIF_GLOBALE_GET_DATA_FAILED_500 = "MOTIF_GLOBALE_GET_DATA_FAILED_500"


export const MOTIF_GLOBALE_POST_DATA_LOADING = 'MOTIF_GLOBALE_POST_DATA_LOADING'

export const MOTIF_GLOBALE_POST_DATA_SUCCESS = 'MOTIF_GLOBALE_POST_DATA_SUCCESS'

export const MOTIF_GLOBALE_POST_DATA_FAILED = 'MOTIF_GLOBALE_POST_DATA_FAILED'

export const MOTIF_GLOBALE_POST_DATA_FAILED_403 = "MOTIF_GLOBALE_POST_DATA_FAILED_403"

export const MOTIF_GLOBALE_POST_DATA_FAILED_500 = "MOTIF_GLOBALE_POST_DATA_FAILED_500"


export const MOTIF_GLOBALE_PUT_DATA_LOADING = 'MOTIF_GLOBALE_PUT_DATA_LOADING'

export const MOTIF_GLOBALE_PUT_DATA_SUCCESS = 'MOTIF_GLOBALE_PUT_DATA_SUCCESS'

export const MOTIF_GLOBALE_PUT_DATA_FAILED = 'MOTIF_GLOBALE_PUT_DATA_FAILED'

export const MOTIF_GLOBALE_PUT_DATA_FAILED_403 = "MOTIF_GLOBALE_PUT_DATA_FAILED_403"

export const MOTIF_GLOBALE_PUT_DATA_FAILED_500 = "MOTIF_GLOBALE_PUT_DATA_FAILED_500"


export const MOTIF_GLOBALE_DELETE_DATA_LOADING = 'MOTIF_GLOBALE_DELETE_DATA_LOADING'

export const MOTIF_GLOBALE_DELETE_DATA_SUCCESS = 'MOTIF_GLOBALE_DELETE_DATA_SUCCESS'

export const MOTIF_GLOBALE_DELETE_DATA_FAILED = 'MOTIF_GLOBALE_DELETE_DATA_FAILED'

export const MOTIF_GLOBALE_DELETE_DATA_FAILED_403 = "MOTIF_GLOBALE_DELETE_DATA_FAILED_403"

export const MOTIF_GLOBALE_DELETE_DATA_FAILED_500 = "MOTIF_GLOBALE_DELETE_DATA_FAILED_500"


export const MOTIF_GLOBALE_GETS_DATA_LOADING = 'MOTIF_GLOBALE_GETS_DATA_LOADING'

export const MOTIF_GLOBALE_GETS_DATA_SUCCESS = 'MOTIF_GLOBALE_GETS_DATA_SUCCESS'

export const MOTIF_GLOBALE_GETS_DATA_FAILED = 'MOTIF_GLOBALE_GETS_DATA_FAILED'

export const MOTIF_GLOBALE_GETS_DATA_FAILED_403 = "MOTIF_GLOBALE_GETS_DATA_FAILED_403"

export const MOTIF_GLOBALE_GETS_DATA_FAILED_500 = "MOTIF_GLOBALE_GETS_DATA_FAILED_500"


export const MOTIF_GLOBALE_GETS_REFRESH_DATA_LOADING = 'MOTIF_GLOBALE_GETS_REFRESH_DATA_LOADING'

export const MOTIF_GLOBALE_GETS_REFRESH_DATA_SUCCESS = 'MOTIF_GLOBALE_GETS_REFRESH_DATA_SUCCESS'

export const MOTIF_GLOBALE_GETS_REFRESH_DATA_FAILED = 'MOTIF_GLOBALE_GETS_REFRESH_DATA_FAILED'

export const MOTIF_GLOBALE_GETS_REFRESH_DATA_FAILED_403 = "MOTIF_GLOBALE_GETS_REFRESH_DATA_FAILED_403"

export const MOTIF_GLOBALE_GETS_REFRESH_DATA_FAILED_500 = "MOTIF_GLOBALE_GETS_REFRESH_DATA_FAILED_500"


export const MOTIF_GLOBALE_CLEAR_STATE = "MOTIF_GLOBALE_CLEAR_STATE"