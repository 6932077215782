import { CIRCUITS_PATIENTS_GET_DATA_FAILED, CIRCUITS_PATIENTS_GET_DATA_LOADING, CIRCUITS_PATIENTS_GET_DATA_SUCCESS, CIRCUITS_PATIENTS_REFRESH_GET_DATA_FAILED, CIRCUITS_PATIENTS_REFRESH_GET_DATA_LOADING, CIRCUITS_PATIENTS_REFRESH_GET_DATA_SUCCESS, CIRCUIT_PATIENT_DETAILS_GET_DATA_FAILED, CIRCUIT_PATIENT_DETAILS_GET_DATA_LOADING, CIRCUIT_PATIENT_DETAILS_GET_DATA_SUCCESS, CIRCUIT_PATIENT_GET_DATA_FAILED, CIRCUIT_PATIENT_GET_DATA_LOADING, CIRCUIT_PATIENT_GET_DATA_SUCCESS, DOSSIERS_PATIENT_FICHES_PAIEMENT_GET_DATA_FAILED, DOSSIERS_PATIENT_FICHES_PAIEMENT_GET_DATA_LOADING, DOSSIERS_PATIENT_FICHES_PAIEMENT_GET_DATA_SUCCESS, DOSSIERS_PATIENT_RECUS_PAIEMENT_GET_DATA_FAILED, DOSSIERS_PATIENT_RECUS_PAIEMENT_GET_DATA_LOADING, DOSSIERS_PATIENT_RECUS_PAIEMENT_GET_DATA_SUCCESS } from "../../types/dossiersPatientsTypes";
import { AUTH_ERROR, LOGOUT_SUCCESS, TOKEN_REFRESH_FAIL } from "../../types/userTypes";


const initialState = {
    circuitsPatients: [],
    circuitPatient: {},
    circuitPatientDetails:[],

    dossiersFichesPaiement: [],
    dossiersRecusPaiement: [],

    isLoadingGet:false,
    isLoadingGets:false,
    isLoadingGetsRefresh:false,
    isLoadingGetsDetails:false,

    isLoadingGetsFiches:false,
    isLoadingGetsRecus:false,

    isSuccesGet:false,
    isSuccesGets:false,
    isSuccesGetsRefresh:false,
    isSuccesGetsDetails:false,

    isSuccesGetsFiches:false,
    isSuccesGetsRecus:false,

    isError:false,
    error: ""
};

//eslint-disable-next-line
export default function (state = initialState, action){
    switch (action.type) {
        case CIRCUITS_PATIENTS_GET_DATA_LOADING:
            return {
                ...state,
                isLoadingGets: true,
                isError:false,
                error: ""
            };
        case CIRCUITS_PATIENTS_GET_DATA_SUCCESS:
            return {
                ...state,
                isLoadingGets: false,
                circuitsPatients: action.payload,
                isSuccesGets: true,
                isError:false
            };
        case CIRCUITS_PATIENTS_GET_DATA_FAILED:
            return {
                ...state,
                isLoadingGets: false,
                error: action.payload,
                isSuccesGets: false,
                isError:true
            }
        
        case CIRCUITS_PATIENTS_REFRESH_GET_DATA_LOADING:
            return {
                ...state,
                isLoadingGetsRefresh: true,
                isError:false,
                error: ""
            };
        case CIRCUITS_PATIENTS_REFRESH_GET_DATA_SUCCESS:
            return {
                ...state,
                isLoadingGetsRefresh: false,
                circuitsPatients: action.payload,
                isSuccesGets: true,
                isError:false
            };
        case CIRCUITS_PATIENTS_REFRESH_GET_DATA_FAILED:
            return {
                ...state,
                isLoadingGetsRefresh: false,
                error: action.payload,
                isSuccesGets: false,
                isError:true
            }

        case CIRCUIT_PATIENT_GET_DATA_LOADING:
            return {
                ...state,
                isLoadingGet: true,
                isError:false,
                error: ""
            }
        case CIRCUIT_PATIENT_GET_DATA_SUCCESS:
            return {
                ...state,
                isLoadingGet:false,
                circuitPatient: action.payload,
                isSuccesGet: true,
                isError:false
            }
        case CIRCUIT_PATIENT_GET_DATA_FAILED:
            return {
                ...state,
                isLoadingGet:false,
                error: action.payload,
                isSuccesGet: false,
                isError:true,
                circuitPatient:{}
            }
        
        case CIRCUIT_PATIENT_DETAILS_GET_DATA_LOADING:
            return {
                ...state,
                isLoadingGetsDetails: true,
                isSuccesGetsDetails: false,
                isError:false,
                error: ""
            }
        case CIRCUIT_PATIENT_DETAILS_GET_DATA_SUCCESS:
            return {
                ...state,
                isLoadingGetsDetails: false,
                circuitPatientDetails: action.payload,
                isSuccesGetsDetails: true,
                isError:false
            }
        case CIRCUIT_PATIENT_DETAILS_GET_DATA_FAILED:
            return {
                ...state,
                isLoadingGetsDetails: false,
                circuitPatientDetails: [],
                isSuccesGetsDetails: false,
                isError:true,
                error: action.payload
            }

        
        //dossiers patients
        case DOSSIERS_PATIENT_FICHES_PAIEMENT_GET_DATA_LOADING:
            return {
                ...state,
                isLoadingGetsFiches: true,
                isSuccesGetsFiches: false,
                isError:false,
                error: ""
            }
        case DOSSIERS_PATIENT_FICHES_PAIEMENT_GET_DATA_SUCCESS:
            return {
                ...state,
                isLoadingGetsFiches: false,
                dossiersFichesPaiement: action.payload,
                isSuccesGetsFiches: true,
                isError:false
            }
        case DOSSIERS_PATIENT_FICHES_PAIEMENT_GET_DATA_FAILED:
            return {
                ...state,
                isLoadingGetsFiches: false,
                isSuccesGetsFiches: false,
                isError:true,
                error: action.payload
            }

        case DOSSIERS_PATIENT_RECUS_PAIEMENT_GET_DATA_LOADING:
            return {
                ...state,
                isLoadingGetsRecus: true,
                isSuccesGetsRecus: false,
                isError:false,
                error: ""
            }
        case DOSSIERS_PATIENT_RECUS_PAIEMENT_GET_DATA_SUCCESS:
            return {
                ...state,
                isLoadingGetsRecus: false,
                dossiersRecusPaiement: action.payload,
                isSuccesGetsRecus: true,
                isError:false
            }
        case DOSSIERS_PATIENT_RECUS_PAIEMENT_GET_DATA_FAILED:
            return {
                ...state,
                isLoadingGetsRecus: false,
                isSuccesGetsRecus: false,
                isError:true,
                error: action.payload
            }

        case AUTH_ERROR:
        case LOGOUT_SUCCESS:
        case TOKEN_REFRESH_FAIL:
            localStorage.removeItem('token_access')
            localStorage.removeItem('token_refresh')
            return {
                ...state,
                circuitsPatients: [],
                circuitPatient: {},
                circuitPatientDetails:[],

                dossiersFichesPaiement: [],
                dossiersRecusPaiement: [],

                isLoadingGet:false,
                isLoadingGets:false,
                isLoadingGetsRefresh:false,
                isLoadingGetsDetails:false,

                isLoadingGetsFiches:false,
                isLoadingGetsRecus:false,

                isSuccesGet:false,
                isSuccesGets:false,
                isSuccesGetsRefresh:false,
                isSuccesGetsDetails:false,

                isSuccesGetsFiches:false,
                isSuccesGetsRecus:false,

                isError:false,
                error: ""
            }

        default:
            return state;
    }
}
