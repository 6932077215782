import { GET_PATIENTS_PAYMENT_SLIP_FAILED, GET_PATIENTS_PAYMENT_SLIP_LOADING, GET_PATIENTS_PAYMENT_SLIP_SUCCESS } from "../../types/patientTypes";

const initialState = {
  isLoading: true,
  data : [],
  error: null,
};

export const patientPaymentSlipsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PATIENTS_PAYMENT_SLIP_LOADING:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case GET_PATIENTS_PAYMENT_SLIP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };
    case GET_PATIENTS_PAYMENT_SLIP_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
