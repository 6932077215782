export const examenExterneTypes = {
    POST_REQUEST: "POST_REQUEST",
    POST_SUCCESS: "POST_SUCCESS",
    POST_FAILED: "POST_FAILED",
    RESET_NATURE_EXAMEN: "RESET_NATURE_EXAMEN",

    POST_ACTE_REQUEST: "POST_ACTE_REQUEST",
    POST_ACTE_SUCCESS: "POST_ACTE_SUCCESS",
    POST_ACTE_FAILED: "POST_ACTE_FAILED",
    RESET_ACTE_EXAMEN: "RESET_ACTE_EXAMEN"
}