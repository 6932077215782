import React, { lazy } from "react"
import * as MyPrivateRouters from "../routes/PrivateRouter";
import { Switch } from 'react-router-dom';


const LayoutTarifsReduitsRequeteListe = lazy(() =>
    import(
      "../../components/tarifs-reduits/Requete/Liste/LayoutTarifsReduitsRequeteListe"
    )
);

const LayoutTarifsReduitsRequeteDetails = lazy(() =>
    import(
        "../../components/tarifs-reduits/Requete/Voir/LayoutTarifsReduitsRequeteDetails"
    )
);

const LayoutTarifsReduitsRequeteCreate = lazy(() =>
    import(
        "../../components/tarifs-reduits/Requete/Form/LayoutTarifsReduitsRequeteCreate"
    )
);

const LayoutTarifsReduitsRequeteTraiteListe = lazy(() =>
    import(
        "../../components/tarifs-reduits/RequeteTraite/LayoutTarifsReduitsRequeteTraiteListe"
    )
);

const LayoutTarifsReduitsRapport = lazy(() => import("../../components/tarifs-reduits/rapport/LayoutTarifsReduitsRapport"))
  

export default function TarifsReduitsRouter() {
  return (
    <Switch>
        <MyPrivateRouters.PrivateRouterTarifsReduits
        path="/tarifs-reduits"
        component={LayoutTarifsReduitsRequeteListe}
        />

        <MyPrivateRouters.PrivateRouterTarifsReduits
        path="/tarifs-reduits-requete-details/:id"
        component={LayoutTarifsReduitsRequeteDetails}
        />

        <MyPrivateRouters.PrivateRouterTarifsReduits
        path="/tarifs-reduits-requete-create/:id"
        component={LayoutTarifsReduitsRequeteCreate}
        />

        <MyPrivateRouters.PrivateRouterTarifsReduits
        path="/tarifs-reduits-requete-traite-liste"
        component={LayoutTarifsReduitsRequeteTraiteListe}
        />

        <MyPrivateRouters.PrivateRouterTarifsReduits
        path="/tarifs-reduits-rapport"
        component={LayoutTarifsReduitsRapport}
        />
    </Switch>
  )
}
