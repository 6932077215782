export const bilansType = {
  GET_BILAN_CAISSE_LOADING: "GET_BILAN_CAISSE_LOADING",
  GET_BILAN_CAISSE_SUCCESS: "GET_BILAN_CAISSE_SUCCESS",
  GET_BILAN_CAISSE_FAILED: "GET_BILAN_CAISSE_FAILED",
  GET_BILAN_CAISSE_FAILED_403: "GET_BILAN_CAISSE_FAILED_403",
  GET_BILAN_CAISSE_FAILED_500: "GET_BILAN_CAISSE_FAILED_500",

  GET_NOMBRE_ECHO_CAISSE_LOADING: "GET_NOMBRE_ECHO_CAISSE_LOADING",
  GET_NOMBRE_ECHO_CAISSE_SUCCESS: "GET_NOMBRE_ECHO_CAISSE_SUCCESS",
  GET_NOMBRE_ECHO_CAISSE_FAILED: "GET_NOMBRE_ECHO_CAISSE_FAILED",

  CLEAR_BILAN_CAISSE: "CLEAR_BILAN_CAISSE"
};
