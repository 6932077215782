export const SIDEBAR2_OPEN = 'SIDEBAR2_OPEN'

export const SIDEBAR2_CLOSE = 'SIDEBAR2_CLOSE'


export const SIDEBAR = 'SIDEBAR'

export const SIDEBAR_CLOSE = 'SIDEBAR_CLOSE'

export const SIDEBAR_EXTEND = 'SIDEBAR_EXTEND'

export const SIDEBAR_EXTEND_CLOSE = 'SIDEBAR_EXTEND_CLOSE'
