import {
  ASSURANCE_PATIENT_GET_DATA_LOADING,
  ASSURANCE_PATIENT_GET_DATA_FAILED,
  ASSURANCE_PATIENT_GET_DATA_SUCCESS,
  // PATIENT_PATCH_DATA_LOADING
} from "../../types/assurancePatientTypes";

// import jwtDecode from "jwt-decode"

const initialState = {
  assurancespatient: [],
  isLoading: false,
  isSucces: false,
  isLoadings: false,
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case ASSURANCE_PATIENT_GET_DATA_LOADING:
      return {
        ...state,
        isLoading: true,
        isSucces: false,
      };

    case ASSURANCE_PATIENT_GET_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSucces: true,
        assurancespatient: action.payload,
      };

    case ASSURANCE_PATIENT_GET_DATA_FAILED:
      return {
        ...state,
        assurancespatient: {},
        isLoadings: false,
        isSucces: false,
      };
    case "ASSURANCE_PATIENT_REQUEST":
      return {
        ...state,
        isLoadings: true,
        isSucces: false,
      };
    case "ASSURANCE_PATIENT_SUCCESS":
      return {
        ...state,
        assurancespatient: action.payload,
        isLoadings: false,
        isSucces: true,
      };
    case "ASSURANCE_PATIENT_FAIL":
      return {
        ...state,
        assurancespatient: [],
        isLoadings: false,
        isSucces: false,
      };

    default:
      return state;
  }
}
