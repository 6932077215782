import { reportType } from "../../../types/reportType.js";
import { AUTH_ERROR, LOGOUT_SUCCESS, TOKEN_REFRESH_FAIL } from "../../../types/userTypes.js";

const initialState = {
  isLoading: false,
  isLoadingCmu: false,
  isLoadingAll: false,
  data: [],
  dataCmu: [],
  dataAll: [],
  error: "",
  isError: false,
  isSucces: false,
};

export const getPrestationAssuranceReducer = (state = initialState, action) => {
    switch (action.type) {
      case reportType.GET_REPORT_PRESTATION_ASSURANCE_LOADING:
        return {
          ...state,
          isLoading: true,
          error: "",
        };
      case reportType.GET_REPORT_PRESTATION_ASSURANCE_SUCCESS:
        return {
          ...state,
          isLoading: false,
          data: action.payload,
          isSucces: true,
          isError: false,
          error: "",
        };
      case reportType.GET_REPORT_PRESTATION_ASSURANCE_FAILED:
        return {
          ...state,
          isLoading: false,
          error: action.payload,
          isSucces: false,
          isError: true,
        };

      case reportType.GET_REPORT_PRESTATION_ASSURANCE_CMU_LOADING:
        return {
          ...state,
          isLoadingCmu: true,
          error: "",
        };
      case reportType.GET_REPORT_PRESTATION_ASSURANCE_CMU_SUCCESS:
        return {
          ...state,
          isLoadingCmu: false,
          dataCmu: action.payload,
          isSucces: true,
          isError: false,
          error: "",
        };
      case reportType.GET_REPORT_PRESTATION_ASSURANCE_CMU_FAILED:
        return {
          ...state,
          isLoadingCmu: false,
          error: action.payload,
          isSucces: false,
          isError: true,
        };

      case reportType.GET_REPORT_PRESTATION_ASSURANCE_ALL_LOADING:
        return {
          ...state,
          isLoadingAll: true,
          error: "",
        };
      case reportType.GET_REPORT_PRESTATION_ASSURANCE_ALL_SUCCESS:
        return {
          ...state,
          isLoadingAll: false,
          dataAll: action.payload,
          error: "",
        };
      case reportType.GET_REPORT_PRESTATION_ASSURANCE_ALL_FAILED:
        return {
          ...state,
          isLoadingAll: false,
          error: action.payload,
        };

      case AUTH_ERROR:
      case LOGOUT_SUCCESS:
      case TOKEN_REFRESH_FAIL:
        localStorage.removeItem("token_access");
        localStorage.removeItem("token_refresh");
        return {
          ...state,
          isLoading: false,
          isLoadingCmu: false,
          data: false,
          dataCmu: false,
          error: "",
        };
      default:
        return state;
    }
};
