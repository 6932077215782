import * as sidebarTypes from '../types/sidebarTypes';
import { AUTH_ERROR, LOGOUT_SUCCESS, TOKEN_REFRESH_FAIL } from '../types/userTypes';


const initialState = {
    sideGenValeur: "sidebar2",
    sideSpeValeur: "sidebar"
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case sidebarTypes.SIDEBAR2_OPEN:
            return {
                ...state,
                sideGenValeur: action.payload,
                sideSpeValeur: state.sideSpeValeur.includes("close") ? "sidebar extend close" : "sidebar extend"
            }
        case sidebarTypes.SIDEBAR2_CLOSE:
            return {
                ...state,
                sideGenValeur: action.payload,
                sideSpeValeur: state.sideSpeValeur.includes("close") ? "sidebar close" : "sidebar"
            }
        
        case sidebarTypes.SIDEBAR:
            return {
                ...state,
                sideSpeValeur: action.payload
            }
        case sidebarTypes.SIDEBAR_CLOSE:
            return {
                ...state,
                sideSpeValeur: action.payload
            }
        case sidebarTypes.SIDEBAR_EXTEND:
            return {
                ...state,
                sideSpeValeur: action.payload
            }
        case sidebarTypes.SIDEBAR_EXTEND_CLOSE:
            return {
                ...state,
                sideSpeValeur: action.payload
            }

        case AUTH_ERROR:
        case LOGOUT_SUCCESS:
        case TOKEN_REFRESH_FAIL:
            return {
                ...state,
                sideGenValeur: "sidebar2",
                sideSpeValeur: "sidebar"
            }
        default:
            return state;
    }
}

export default reducer;