import React, { lazy } from "react"
import * as MyPrivateRouters from "../routes/PrivateRouter";
import { Switch } from 'react-router-dom';

const LayoutDermatologiePatientAConsulter = lazy(() =>
    import(
      "../../components/dermatologie/patientAConsulter/LayoutDermatologiePatientAConsulter"
    )
);
const LayoutDermatologieConsultationListe = lazy(() =>
import(
    "../../components/dermatologie/consultation/LayoutDermatologieConsultationListe"
)
);
const LayoutDermatologieDemandeHospitalisationListe = lazy(() =>
import(
    "../../components/dermatologie/demandeHospitalisation/LayoutDermatologieDemandeHospitalisationListe"
)
);
const DermatologieObservation = lazy(() =>
import("../../components/dermatologie/observation/DermatologieObservation")
);
const DermatologieObservationTerminees = lazy(() =>
    import("../../components/dermatologie/observationTerminees/DermatologieObservationTerminees")
    );

const LayoutDermatologieConsultationCreate = lazy(() =>
import(
    "../../components/dermatologie/patientAConsulterCreate/LayoutDermatologieConsultationCreate"
)
);

const LayoutDermatologieConsultationView = lazy(() =>
import(
    "../../components/dermatologie/patientAConsulterDetail/LayoutDermatologieConsultationView"
)
);

const LayoutCreateConsultationSortieDermatologie = lazy(() =>
import(
    "../../components/dermatologie/patientAConsulterIssuSortie/LayoutCreateConsultationSortieDermatologie"
)
);

const LayoutCreateConsultationDermatologieSortieDemandeHospitalisation = lazy(
() =>
    import(
    "../../components/dermatologie/patientAConsulterIssuSortie/DemandeHospitalisation/LayoutCreateConsultationDermatologieSortieDemandeHospitalisation"
    )
);
const LayoutCreateConsultationDermatologieSortieMiseEnObservation = lazy(() =>
import(
    "../../components/dermatologie/patientAConsulterIssuSortie/MiseEnObservation/LayoutCreateConsultationDermatologieSortieMiseEnObservation"
)
);

const LayoutCreateConsultationDermatologieRefereInterne = lazy(() =>
import(
    "../../components/dermatologie/patientAConsulterIssuSortie/RefereInterne/LayoutCreateConsultationDermatologieRefereInterne"
)
);
const LayoutCreateConsultationDermatologieRefereExterne = lazy(() =>
import(
    "../../components/dermatologie/patientAConsulterIssuSortie/RefereExterne/LayoutCreateConsultationDermatologieRefereExterne"
)
);

const LayoutDermatologieOrdonnanceDetails = lazy(() =>
import(
    "../../components/dermatologie/ordonnance/detail/LayoutDermatologieOrdonnanceDetails"
)
);
const LayoutDermatologieOrdonnanceListe = lazy(() =>
import(
    "../../components/dermatologie/ordonnance/liste/LayoutDermatologieOrdonnanceListe"
)
);

const LayoutDermatologieHospitalisationListe = lazy(() =>
import(
    "../../components/dermatologie/hospitalisation/LayoutDermatologieHospitalisationListe"
)
);

const LayoutDermatologieHospitalisationConsultationCreate = lazy(() =>
import(
    "../../components/dermatologie/hospitalisation/consultation/LayoutDermatologieHospitalisationConsultationCreate"
)
);
const LayoutDermatologieHospitalisationConsultationSortie = lazy(() =>
import(
    "../../components/dermatologie/hospitalisation/issueSortie/LayoutDermatologieHospitalisationConsultationSortie"
)
);

const LayoutDermatologieFeuilleDeSoins = lazy(() =>
import(
    "../../components/dermatologie/feuilleDeSoins/LayoutDermatologieFeuilleDeSoins"
)
);

const DermatologieRefExterne = lazy(() =>
import("../../components/dermatologie/refExterne/DermatologieRefExterne")
);
const DermatologieRefInterne = lazy(() =>
import("../../components/dermatologie/refInterne/DermatologieRefInterne")
);

const LayoutDermatologieDecesConsultationSortie = lazy(() =>
import(
    "../../components/dermatologie/patientAConsulterIssuSortie/IssueDeDeces/LayoutDermatologieDecesConsultationSortie"
)
);

const LayoutCreateConsultationSortieDemandeHospitalisationDermatologie = lazy(
() =>
    import(
    "../../components/dermatologie/patientAConsulterIssuSortie/MiseEnObservation/DemandeHospitalisation/LayoutCreateConsultationSortieDemandeHospitalisationDermatologie"
    )
);
const LayoutCreateConsultationRefereInterneDermatologie = lazy(() =>
import(
    "../../components/dermatologie/patientAConsulterIssuSortie/MiseEnObservation/RefereInterne/LayoutCreateConsultationRefereInterneDermatologie"
)
);
const LayoutCreateConsultationRefereExterneDermatologie = lazy(() =>
import(
    "../../components/dermatologie/patientAConsulterIssuSortie/MiseEnObservation/RefereExterne/LayoutCreateConsultationRefereExterneDermatologie"
)
);

const DermatologieExamensDetail = lazy(() =>
import("../../components/dermatologie/Examens/detail")
);
const DermatologieExamensList = lazy(() =>
import("../../components/dermatologie/Examens/list")
);

const LayoutDermatologieDecesListe = lazy(() =>
import("../../components/dermatologie/Deces/Liste/LayoutDermatologieDecesListe")
);
const LayoutDermatologieDecesCertificat = lazy(() =>
import(
    "../../components/dermatologie/Deces/Certificat/LayoutDermatologieDecesCertificat"
)
);

const AgendaDermatologie = lazy(() =>
import("../../components/dermatologie/rendezVous/agendaDetail/AgendaDermatologie")
);
const LayoutDermatologieCreateConsultationRendezVous = lazy(() =>
import(
    "../../components/dermatologie/patientAConsulterIssuSortie/RendezVous/LayoutDermatologieCreateConsultationRendezVous"
)
);
const LayoutDermatologieRdvListe = lazy(() =>
import(
    "../../components/dermatologie/rendezVous/Liste/LayoutDermatologieRdvListe"
)
);
const LayoutDermatoRdvLastConsultation = lazy(() =>
import(
    "../../components/dermatologie/rendezVous/LastConsultation/LayoutDermatoRdvLastConsultation"
)
);
const LayoutDermatoRdvNewConsultation = lazy(() =>
import(
    "../../components/dermatologie/rendezVous/NewConsultation/LayoutDermatoRdvNewConsultation"
)
);
const LayoutVoirDermatologieResultatsExamens = lazy(() =>
import(
    "../../components/dermatologie/Examens/resultatsExamensVoir/LayoutVoirMedecineGeneralResultatsExamens"
)
);

const RapportDermatologie = lazy(() => import("../../components/dermatologie/rapports/RapportDermatologie"));

const LayoutDermatologieControleMajor = lazy(() => 
import("../../components/dermatologie/controleMajor/LayoutDermatologieControleMajor")
)

  

export default function DermatologieRouter() {
  return (
    <Switch>
        <MyPrivateRouters.PrivateRouterDermatologie
        path="/dermatologie"
        component={LayoutDermatologiePatientAConsulter}
        />

        <MyPrivateRouters.PrivateRouterDermatologie
        path="/dermatologie-demande-hospitalisation"
        component={LayoutDermatologieDemandeHospitalisationListe}
        />

        <MyPrivateRouters.PrivateRouterDermatologie
        path="/dermatologie-observation-encours"
        component={DermatologieObservation}
        />

        <MyPrivateRouters.PrivateRouterDermatologie
        path="/dermatologie-observation-terminee"
        component={DermatologieObservationTerminees}
        />

        <MyPrivateRouters.PrivateRouterDermatologie
        path="/dermatologie-consultation"
        component={LayoutDermatologieConsultationListe}
        />

        <MyPrivateRouters.PrivateRouterDermatologie
        path="/dermatologie-feuille-de-soins/:id"
        component={LayoutDermatologieFeuilleDeSoins}
        />

        <MyPrivateRouters.PrivateRouterDermatologie
        path="/dermatologie-ordonnance"
        component={LayoutDermatologieOrdonnanceListe}
        />

        <MyPrivateRouters.PrivateRouterDermatologie
        path="/dermatologie-ordonnance-voir/:id"
        component={LayoutDermatologieOrdonnanceDetails}
        />

        <MyPrivateRouters.PrivateRouterDermatologie
        path="/dermatologie-rdv"
        component={LayoutDermatologieRdvListe}
        />

        <MyPrivateRouters.PrivateRouterDermatologie
        path="/dermatologie-rdv-last-consultation/:id"
        component={LayoutDermatoRdvLastConsultation}
        />

        <MyPrivateRouters.PrivateRouterDermatologie
        path="/dermatologie-rdv-new-consultation/:id"
        component={LayoutDermatoRdvNewConsultation}
        />  

        <MyPrivateRouters.PrivateRouterDermatologie
        path="/dermatologie-consultation-sortie-rdv/:id"
        component={LayoutDermatologieCreateConsultationRendezVous}
        />

        <MyPrivateRouters.PrivateRouterDermatologie
        path="/dermatologie-rdv-agenda/"
        component={AgendaDermatologie}
        />

        <MyPrivateRouters.PrivateRouterDermatologie
        path="/dermatologie-consultation-create/:id"
        component={LayoutDermatologieConsultationCreate}
        />

        <MyPrivateRouters.PrivateRouterDermatologie
        path="/dermatologie-consultation-voir-detail/:id"
        component={LayoutDermatologieConsultationView}
        />

        <MyPrivateRouters.PrivateRouterDermatologie
        path="/dermatologie-hospitalisation"
        component={LayoutDermatologieHospitalisationListe}
        />

        <MyPrivateRouters.PrivateRouterDermatologie
        path="/dermatologie-hospitalisation-consultation/:id"
        component={LayoutDermatologieHospitalisationConsultationCreate}
        />

        <MyPrivateRouters.PrivateRouterDermatologie
        path="/dermatologie-hospitalisation-sortie/:id"
        component={LayoutDermatologieHospitalisationConsultationSortie}
        />

        <MyPrivateRouters.PrivateRouterDermatologie
        path="/dermatologie-consultation-sortie/:id"
        component={LayoutCreateConsultationSortieDermatologie}
        />

        <MyPrivateRouters.PrivateRouterDermatologie
        path="/dermatologie-consultation-sortie-demande-hospitalisation/:id"
        component={
            LayoutCreateConsultationDermatologieSortieDemandeHospitalisation
        }
        />

        <MyPrivateRouters.PrivateRouterDermatologie
        path="/dermatologie-consultation-sortie-mise-en-observation/:id"
        component={
            LayoutCreateConsultationDermatologieSortieMiseEnObservation
        }
        />

        <MyPrivateRouters.PrivateRouterDermatologie
        path="/dermatologie-consultation-refere-interne/:id"
        component={LayoutCreateConsultationDermatologieRefereInterne}
        />

        <MyPrivateRouters.PrivateRouterDermatologie
        path="/dermatologie-consultation-refere-externe/:id"
        component={LayoutCreateConsultationDermatologieRefereExterne}
        />

        <MyPrivateRouters.PrivateRouterDermatologie
        path="/dermatologie-referencements-externe"
        component={DermatologieRefExterne}
        />

        <MyPrivateRouters.PrivateRouterDermatologie
        path="/dermatologie-referencements-interne"
        component={DermatologieRefInterne}
        /> 

        <MyPrivateRouters.PrivateRouterDermatologie
        path="/dermatologie-consultation-sortie-deces/:id"
        component={LayoutDermatologieDecesConsultationSortie}
        />

        <MyPrivateRouters.PrivateRouterDermatologie
        path="/dermatologie-exit-observation/:id"
        component={LayoutCreateConsultationSortieDermatologie}
        />

        <MyPrivateRouters.PrivateRouterDermatologie
        path="/dermatologie-observation-consultation/:id"
        component={LayoutDermatologieConsultationCreate}
        />

        <MyPrivateRouters.PrivateRouterDermatologie
        path="/dermatologie-observation-exit-demande-hospitalisation/:id"
        component={
            LayoutCreateConsultationSortieDemandeHospitalisationDermatologie
        }
        />

        <MyPrivateRouters.PrivateRouterDermatologie
        path="/dermatologie-observation-exit-reference-externe/:id"
        component={LayoutCreateConsultationRefereExterneDermatologie}
        />

        <MyPrivateRouters.PrivateRouterDermatologie
        path="/dermatologie-observation-exit-reference-interne/:id"
        component={LayoutCreateConsultationRefereInterneDermatologie}
        />

        <MyPrivateRouters.PrivateRouterDermatologie
        path="/dermatologie-observation-exit-deces/:id"
        component={LayoutDermatologieDecesConsultationSortie}
        />

        <MyPrivateRouters.PrivateRouterDermatologie
        path="/dermatologie-observation-exit-rdv/:id"
        component={LayoutDermatologieCreateConsultationRendezVous}
        />

        <MyPrivateRouters.PrivateRouterDermatologie
        path="/dermatologie-examens-detail/:id/"
        component={DermatologieExamensDetail}
        />

        <MyPrivateRouters.PrivateRouterDermatologie
        path="/dermatologie-examens"
        component={DermatologieExamensList}
        />

        <MyPrivateRouters.PrivateRouterDermatologie
        path="/dermatologie-patients-decedes"
        component={LayoutDermatologieDecesListe}
        />

        <MyPrivateRouters.PrivateRouterDermatologie
        path="/dermatologie-patients-decedes-certificat/:id"
        component={LayoutDermatologieDecesCertificat}
        />

        <MyPrivateRouters.PrivateRouterDermatologie
        path="/dermatologie-examens-resultat-voir/:id"
        component={LayoutVoirDermatologieResultatsExamens}
        />

        <MyPrivateRouters.PrivateRouterDermatologie
        path="/dermatologie-rapports"
        component={RapportDermatologie}
        />

        <MyPrivateRouters.PrivateRouterDermatologie
        path="/dermatologie-controle-major"
        component={LayoutDermatologieControleMajor}
        />
    </Switch>
  )
}
