import * as typeOrdonnanceTypes from "../types/typeOrdonnanceTypes";
import { AUTH_ERROR, LOGOUT_SUCCESS, TOKEN_REFRESH_FAIL } from "../types/userTypes";

const initialState = {
  isLoading: false,
  typeOrdonnance: [],
  error: "",
};

export const typeOrdonnanceReducer = (state = initialState, action) => {
  switch (action.type) {
    case typeOrdonnanceTypes.TYPE_ORDONNANCE_GET_DATA_LOADING:
      return {
        ...state,
        isLoading: true,
        error: "",
      };
    case typeOrdonnanceTypes.TYPE_ORDONNANCE_GET_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        typeOrdonnance: action.payload,
        error: "",
      };
    case typeOrdonnanceTypes.TYPE_ORDONNANCE_GET_DATA_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    
    case AUTH_ERROR:
    case LOGOUT_SUCCESS:
    case TOKEN_REFRESH_FAIL:
        localStorage.removeItem('token_access')
        localStorage.removeItem('token_refresh')
        return {
            ...state,
            isLoading: false,
            typeOrdonnance: [],
            error: "",
        }

    default:
      return state;
  }
};
