// import { parametreTypes } from "../../types/parametreTypes";
import * as patientparametreTypes from "../../types/patientTypes";
import { AUTH_ERROR, LOGOUT_SUCCESS, TOKEN_REFRESH_FAIL } from "../../types/userTypes";
const initialState = {
    isLoading: false,
    patients: [],
    error: "",
};

export const patientListReducers = (state = initialState, action) => {
    switch (action.type) {
        case patientparametreTypes.PATIENTS_GETS_DATA_LOADING:
            return {
                ...state,
                isLoading: true,
                error: "",
            };
        case patientparametreTypes.PATIENTS_GETS_DATA_SUCCESS:
            return {
                ...state,
                isLoading: false,
                patients: action.payload,
                error: "",
            };
        case patientparametreTypes.PATIENTS_GETS_DATA_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };
        
        case AUTH_ERROR:
        case LOGOUT_SUCCESS:
        case TOKEN_REFRESH_FAIL:
            localStorage.removeItem('token_access')
            localStorage.removeItem('token_refresh')
            return {
                ...state,
                isLoading: false,
                patients: [],
                error: "",
            }

        default:
            return state;
    }
};
