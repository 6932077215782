import React from 'react'
import './style.css'

const LoadingPageComponent = () => {

  return (
    <div className='spinner-container'>
        <div className="spinner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
    // <div id="flex-container">
    //     <div className="flex-item" id="flex">Boîte flexible (cliquez pour basculer l’affichage de la boîte « normale »)</div>
    //     <div className="raw-item" id="raw">Boîte « normale » </div>
    // </div>
  )
}

export default LoadingPageComponent