import * as motifGlobaleTypes from "../types/motifGlobaleTypes";
import { AUTH_ERROR, LOGOUT_SUCCESS, TOKEN_REFRESH_FAIL } from "../types/userTypes";

const initialState = {
  motifsGlobales: [],
  isLoading: false,
  isLoadingRefresh: false,

  isLoadingGet: false,
  isLoadingPost: false,
  isLoadingUpdate: false,
  isLoadingDelete: false,

  motifGlobaleGet: {},

  isSuccessGet: false,
  isSuccessPost: false,
  isSuccessUpdate: false,
  isSuccessDelete: false,

  error: "",
  isError: false,
  isError403: false,
  isError500: false,
  isErrorDelete403: false,
  isErrorDelete500: false,
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
      case motifGlobaleTypes.MOTIF_GLOBALE_GETS_DATA_LOADING:
          return {
              ...state,
              isLoading: true
          }
      case motifGlobaleTypes.MOTIF_GLOBALE_GETS_DATA_SUCCESS:
          return {
              ...state,
              isLoading: false,
              motifsGlobales: action.payload
          }
      case motifGlobaleTypes.MOTIF_GLOBALE_GETS_DATA_FAILED:
          return {
              ...state,
              isLoading: false,        
          }
      case motifGlobaleTypes.MOTIF_GLOBALE_GETS_DATA_FAILED_403:
          return {
              ...state,
              isLoading: false,
              error: action.payload,
              isError: false,
              isError403: true,
              isError500: false,
          };
      case motifGlobaleTypes.MOTIF_GLOBALE_GETS_DATA_FAILED_500:
          return {
              ...state,
              isLoading: false,
              error: action.payload,
              isError: false,
              isError403: false,
              isError500: true,
          };

      case motifGlobaleTypes.MOTIF_GLOBALE_GETS_REFRESH_DATA_LOADING:
          return {
              ...state,
              isLoadingRefresh: true
          }
      case motifGlobaleTypes.MOTIF_GLOBALE_GETS_REFRESH_DATA_SUCCESS:
          return {
              ...state,
              isLoadingRefresh: false,
              motifsGlobales: action.payload
          }
      case motifGlobaleTypes.MOTIF_GLOBALE_GETS_REFRESH_DATA_FAILED:
          return {
              ...state,
              isLoadingRefresh: false,
              motifsGlobales: []
          }
      case motifGlobaleTypes.MOTIF_GLOBALE_GETS_REFRESH_DATA_FAILED_403:
          return {
              ...state,
              isLoadingRefresh: false,
              error: action.payload,
              isError: false,
              isError403: true,
              isError500: false,
          };
      case motifGlobaleTypes.MOTIF_GLOBALE_GETS_REFRESH_DATA_FAILED_500:
          return {
              ...state,
              isLoadingRefresh: false,
              error: action.payload,
              isError: false,
              isError403: false,
              isError500: true,
          };

      
      //load get commune in dpi
      case motifGlobaleTypes.MOTIF_GLOBALE_GET_DATA_LOADING:
          return {
              ...state,
              isLoadingGet: true,
              isSuccessGet: false,
              motifGlobaleGet: {},
              error: "",
              isError: false,
              isError403: false,
              isError500: false,
          }
      case motifGlobaleTypes.MOTIF_GLOBALE_GET_DATA_SUCCESS:
          return {
              ...state,
              isLoadingGet: false,
              isSuccessGet: true,
              motifGlobaleGet: action.payload,
              error: "",
              isError: false,
              isError403: false,
              isError500: false,
          }
      case motifGlobaleTypes.MOTIF_GLOBALE_GET_DATA_FAILED:
          return {
              ...state,
              isLoadingGet: false,
              isSuccessGet: false,
              error: action.payload,
              motifGlobaleGet: {},
              isError: true,
              isError403: false,
              isError500: false,
          }
      case motifGlobaleTypes.MOTIF_GLOBALE_GET_DATA_FAILED_403:
          return {
              ...state,
              isLoadingGet: false,
              isSuccessGet: false,
              motifGlobaleGet:{},
              error: action.payload,
              isError: false,
              isError403: true,
              isError500: false,
          };
      case motifGlobaleTypes.MOTIF_GLOBALE_GET_DATA_FAILED_500:
          return {
              ...state,
              isLoadingGet: false,
              isSuccessGet: false,
              motifGlobaleGet:{},
              error: action.payload,
              isError: false,
              isError403: false,
              isError500: true,
          };

      
      //load post commune in dpi
      case motifGlobaleTypes.MOTIF_GLOBALE_POST_DATA_LOADING:
          return {
              ...state,
              isLoadingPost: true,
              isSuccessPost: false,
              motifGlobaleGet: {},
              error: "",
              isError: false,
              isError403: false,
              isError500: false,
          }
      case motifGlobaleTypes.MOTIF_GLOBALE_POST_DATA_SUCCESS:
          return {
              ...state,
              isLoadingPost: false,
              isSuccessPost: true,
              motifGlobaleGet: action.payload,
              error: "",
              isError: false,
              isError403: false,
              isError500: false,
          }
      case motifGlobaleTypes.MOTIF_GLOBALE_POST_DATA_FAILED:
          return {
              ...state,
              isLoadingPost: false,
              isSuccessPost: false,
              error: action.payload,
              isError: true,
              isError403: false,
              isError500: false,
          }
      case motifGlobaleTypes.MOTIF_GLOBALE_POST_DATA_FAILED_403:
          return {
              ...state,
              isLoadingPost: false,
              isSuccessPost: false,
              error: action.payload,
              isError: false,
              isError403: true,
              isError500: false,
          };
      case motifGlobaleTypes.MOTIF_GLOBALE_POST_DATA_FAILED_500:
          return {
              ...state,
              isLoadingPost: false,
              isSuccessPost: false,
              error: action.payload,
              isError: false,
              isError403: false,
              isError500: true,
          };

      
      //load put commune in dpi
      case motifGlobaleTypes.MOTIF_GLOBALE_PUT_DATA_LOADING:
          return {
              ...state,
              isLoadingUpdate: true,
              isSuccessUpdate: false,
              motifGlobaleGet: {},
              error: "",
              isError: false,
              isError403: false,
              isError500: false,
          }
      case motifGlobaleTypes.MOTIF_GLOBALE_PUT_DATA_SUCCESS:
          return {
              ...state,
              isLoadingUpdate: false,
              isSuccessUpdate: true,
              motifGlobaleGet: action.payload,
              error: "",
              isError: false,
              isError403: false,
              isError500: false,
          }
      case motifGlobaleTypes.MOTIF_GLOBALE_PUT_DATA_FAILED:
          return {
              ...state,
              isLoadingUpdate: false,
              isSuccessUpdate: false,
              error: action.payload,
              isError: true,
              isError403: false,
              isError500: false,
          }
      case motifGlobaleTypes.MOTIF_GLOBALE_PUT_DATA_FAILED_403:
          return {
              ...state,
              isLoadingUpdate: false,
              isSuccessUpdate: false,
              error: action.payload,
              isError: false,
              isError403: true,
              isError500: false,
          };
      case motifGlobaleTypes.MOTIF_GLOBALE_PUT_DATA_FAILED_500:
          return {
              ...state,
              isLoadingUpdate: false,
              isSuccessUpdate: false,
              error: action.payload,
              isError: false,
              isError403: false,
              isError500: true,
          };

      
      //load delete commune in dpi
      case motifGlobaleTypes.MOTIF_GLOBALE_DELETE_DATA_LOADING:
          return {
              ...state,
              isLoadingDelete: true,
              isSuccessDelete: false,
              error: "",
              isError: false,
              isErrorDelete403: false,
              isErrorDelete500: false,
          }
      case motifGlobaleTypes.MOTIF_GLOBALE_DELETE_DATA_SUCCESS:
          return {
              ...state,
              isLoadingDelete: false,
              isSuccessDelete: true,
              motifsGlobales: state.motifsGlobales.filter(data => data?.id !== action.payload),
              error: "",
              isError: false,
              isErrorDelete403: false,
              isErrorDelete500: false,
          }
      case motifGlobaleTypes.MOTIF_GLOBALE_DELETE_DATA_FAILED:
          return {
              ...state,
              isLoadingDelete: false,
              isSuccessDelete: false,
              error: action.payload,
              isError: true,
              isErrorDelete403: false,
              isErrorDelete500: false,
          }
      case motifGlobaleTypes.MOTIF_GLOBALE_DELETE_DATA_FAILED_403:
          return {
              ...state,
              isLoadingDelete: false,
              isSuccessDelete: false,
              error: action.payload,
              isError: false,
              isErrorDelete403: true,
              isErrorDelete500: false,
          };
      case motifGlobaleTypes.MOTIF_GLOBALE_DELETE_DATA_FAILED_500:
          return {
              ...state,
              isLoadingDelete: false,
              isSuccessDelete: false,
              error: action.payload,
              isError: false,
              isErrorDelete403: false,
              isErrorDelete500: true,
          };

      case motifGlobaleTypes.MOTIF_GLOBALE_CLEAR_STATE:
          return {
              ...state,
              isLoadingGet: false,
              isLoadingPost: false,
              isLoadingUpdate: false,
              isLoadingDelete: false,

              motifGlobaleGet: {},

              isSuccessGet: false,
              isSuccessPost: false,
              isSuccessUpdate: false,
              isSuccessDelete: false,

              error: "",
              isError: false,
              isError403: false,
              isError500: false,
              isErrorDelete403: false,
              isErrorDelete500: false,
          }


      case AUTH_ERROR:
      case LOGOUT_SUCCESS:
      case TOKEN_REFRESH_FAIL:
          localStorage.removeItem('token_access')
          localStorage.removeItem('token_refresh')
          return {
              ...state,
              motifsGlobales: [],
              isLoading: false,
              isLoadingRefresh: false,

              isLoadingGet: false,
              isLoadingPost: false,
              isLoadingUpdate: false,
              isLoadingDelete: false,

              motifGlobaleGet: {},

              isSuccessGet: false,
              isSuccessPost: false,
              isSuccessUpdate: false,
              isSuccessDelete: false,

              error: "",
              isError: false,
              isError403: false,
              isError500: false,
              isErrorDelete403: false,
              isErrorDelete500: false,
      }

      default:
          return state;
  }
}
