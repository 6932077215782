import { 
    CENTRE_SANTE_GETS_DATA_SUCCESS, 
    CENTRE_SANTE_GETS_DATA_FAILED, 
    CENTRE_SANTE_GETS_DATA_LOADING,

    CENTRE_SANTE_GET_DATA_SUCCESS, 
    CENTRE_SANTE_GET_DATA_FAILED, 
    CENTRE_SANTE_GET_DATA_LOADING,

    CENTRE_SANTE_PUT_DATA_LOADING,
    CENTRE_SANTE_PUT_DATA_SUCCESS,
    CENTRE_SANTE_PUT_DATA_FAILED,
    CENTRE_SANTE_SET_SUCCESS,
    CENTRE_SANTE_SELECT_CENTRE,

    CENTRE_SANTE_GETS_DATA_REFRESH_LOADING,
    CENTRE_SANTE_GETS_DATA_REFRESH_SUCCESS,
    CENTRE_SANTE_GETS_DATA_REFRESH_FAILED,
    CENTRE_SANTE_POST_DATA_LOADING,
    CENTRE_SANTE_POST_DATA_SUCCESS,
    CENTRE_SANTE_POST_DATA_FAILED
} from "../../types/centresantreTypes"
import { AUTH_ERROR, LOGOUT_SUCCESS, TOKEN_REFRESH_FAIL } from "../../types/userTypes"

// import jwtDecode from "jwt-decode"

const initialState = {
    centresantesList: [],
    centreSantePost: null,

    centresanteName: "",
    centresanteId: null,

    isGetSucess: false,

    centresantes: null,
    centresantesPut: null,
    isLoading: false,

    isPutSucess: false,
    isPuting:false,

    isSucess:false,
    ajour:false,

    isLoadingGetCentre: false, 
    isSuccessGetCentre: false,

    isLoadingGets: false,
    isLoadingGetsRefresh: false,  
    isGetSucessGets:false,

    isLoadingPost: false,
    isSuccessPost: false,

    error:'',
    isError:false,
    isPutError:false,
    isErrorPost: false,
}

// eslint-disable-next-line
export default function(state = initialState, action){
    switch (action.type) {
        case CENTRE_SANTE_GETS_DATA_LOADING:
            return {
                ...state,
                isLoadingGets: true,
                isGetSucessGets:false
        }
        
        case CENTRE_SANTE_GETS_DATA_SUCCESS:
            return {
                ...state,
                isLoadingGets: false,
                centresantesList: action.payload,
                isGetSucessGets:true
        }
        case CENTRE_SANTE_GETS_DATA_FAILED:
            return {
                ...state,
                isLoadingGets: false,  
                isGetSucessGets:false,
                centresantesList: []
            }
        
            
        case CENTRE_SANTE_GETS_DATA_REFRESH_LOADING:
            return {
                ...state,
                isLoadingGetsRefresh: true,
                isGetSucessGets:false
            }
        case CENTRE_SANTE_GETS_DATA_REFRESH_SUCCESS:
            return {
                ...state,
                isLoadingGetsRefresh: false,
                centresantesList: action.payload,
                isGetSucessGets:true
            }
        case CENTRE_SANTE_GETS_DATA_REFRESH_FAILED:
            return {
                ...state,
                isLoadingGetsRefresh: false,  
                isGetSucessGets:false,
                centresantesList: []
            }
        
        
        case CENTRE_SANTE_GET_DATA_LOADING:
            return {
                ...state,
                isLoading: true,
                isSucess:false,
                ajour:false,
                isLoadingGetCentre: true, 
                isSuccessGetCentre: false,
        }
        
        case CENTRE_SANTE_GET_DATA_SUCCESS:
            return {
                ...state,
                isLoading: false,
                centresantes: action.payload,
                ajour:false,
                isSucess:true,
                isLoadingGetCentre: false, 
                isSuccessGetCentre: true,
        }
        case CENTRE_SANTE_GET_DATA_FAILED:
            return {
                ...state,
                centresantes: null,
                isLoading: false,  
                ajour:false,
                isSucess:false,
                isLoadingGetCentre: false, 
                isSuccessGetCentre: false,
            }
            

        case CENTRE_SANTE_POST_DATA_LOADING:
            return {
                ...state,
                isLoadingPost: true,
                isSuccessPost: false,
                isErrorPost:false,
                centreSantePost: {},
            }
        case CENTRE_SANTE_POST_DATA_SUCCESS:
            return {
                ...state,
                isLoadingPost: false,
                isSuccessPost: true,
                centreSantePost: action.payload,
                isErrorPost:false,
        }
        case CENTRE_SANTE_POST_DATA_FAILED:
            return {
                ...state,
                isLoadingPost: false,
                isSuccessPost: false,
                isErrorPost:true,
                error:action.payload,
                centreSantePost: {}
            }

        case CENTRE_SANTE_PUT_DATA_LOADING:
            return {
                ...state,
                isPuting: true,
                isPutSucess:false,
                isPutError:false,
                isSucess:false
            }
        case CENTRE_SANTE_PUT_DATA_SUCCESS:
            return {
                ...state,
                isPutSucess: true,
                isPuting: false,
                isPutError:false,
                centresantesPut: action.payload
        }
        case CENTRE_SANTE_PUT_DATA_FAILED:
            return {
                ...state,
                isPutError:true,
                error:action.payload,
                isPutSucess: false,
                isPuting:false 
            }
        
        case CENTRE_SANTE_SELECT_CENTRE: 
            return {
                ...state,
                centresanteName: action.payload?.libelle,
                centresanteId: action.payload?.id,
            }

        case CENTRE_SANTE_SET_SUCCESS:
            return {
                isError:false,
                isPutError:false,
                isErrorPost: false,
                error:'',
                isPutSucess: false,
                isPuting:false,
                isLoading: false,
                isLoadingGetCentre: false, 
                isSuccessGetCentre: false,
                isLoadingPost: false,
                isSuccessPost: false,
                centreSantePost: {},
            }

        case AUTH_ERROR:
        case LOGOUT_SUCCESS:
        case TOKEN_REFRESH_FAIL:
            localStorage.removeItem('token_access')
            localStorage.removeItem('token_refresh')
            return {
                ...state,
                centresantesList: [],

                centresanteName: "",
                centresanteId: null,

                isGetSucess: false,

                centresantes: null,
                centresantesPut: null,
                isLoading: false,

                isPutSucess: false,
                isPuting:false,

                isSucess:false,
                ajour:false,

                isLoadingGetCentre: false, 
                isSuccessGetCentre: false,

                isLoadingPost: false,
                isSuccessPost: false,

                centreSantePost: {},


                error:'',
                isError:false,
                isPutError:false,
                isErrorPost: false,
            }

        default:
            return state
    }
}


