
import axios from "../axios"


import { 
        LOGIN_FAIL, 
        LOGIN_SUCCESS, 
        LOGOUT_SUCCESS,
        USER_LOADING,
        USER_LOADED,
        AUTH_ERROR,
        TOKEN_REFRESH_SUCCESS,
        TOKEN_REFRESH_FAIL,
        TOKEN_REFRESH_LOADING,
        ERROR_VALUE_CLEAR,
        USER_PASSWORD_CHANGE_LOADING,
        USER_PASSWORD_CHANGE_SUCCESS,
        USER_PASSWORD_CHANGE_FAIL,
        LOGIN_LOADING,
        CIRCUIT_PATIENT_SUCCESS,
        CIRCUIT_PATIENT_LOADING,
        CIRCUIT_PATIENT_FAILED,
        EMAIL_USER_GET_LOADING,
        EMAIL_USER_GET_SUCCESS,
        EMAIL_USER_GET_FAILED,
        CLEAR_STAPPERS_VALUE_CONNEXION,
        ONCHANGE_SEARCH_INPUT,
        MARQUE_IMPRIMER_FICHE_LOADING,
        MARQUE_IMPRIMER_FICHE_SUCCESS,
        MARQUE_IMPRIMER_FICHE_FAILED,
        SESSION_EXPIRED,
        SESSION_NEW_LOADING,
        SESSION_NEW_SUCCESS,
        SESSION_NEW_FAILED
    } from "../types/userTypes"


export const configHeaders = {
    headers:{
        'Content-Type': 'application/json'
    }
}

export const configHeadersToken = (tok) => {
  const token = localStorage.getItem('token_access')
    return {
        headers:{
        "Content-Type": "application/json",
        "Authorization": 'Bearer ' + token 
    }
    }
}

export const configHeadersFormDataToken = (tok) => {
  const token = localStorage.getItem('token_access')
    return {
        headers:{
        "Content-Type": "multipart/form-data",
        "Authorization": `Bearer ${token}` 
    }
    }
}



export const login = (username, password) => (dispatch) => {
    const config = configHeaders
    const body = JSON.stringify({username,password})
    dispatch({type:LOGIN_LOADING})
    axios.post('/users/v1/login/', body, config)
    .then(response => {
        dispatch({
            type: LOGIN_SUCCESS,
            payload: response.data
        })
    })

    .catch((err) => {
        if(err?.response?.status === 404){
          console.log("console.log error 404")
          dispatch({
            type: LOGIN_FAIL,
            payload: "La ressource est introuvable !!!",
          });
        }else{
          dispatch({
            type: LOGIN_FAIL,
            payload: err?.response?.data?.detail
          });
        }
      });
}

export const loginNewSession = (username, password) => (dispatch) => {
  const config = configHeaders
  const body = JSON.stringify({username,password})
  dispatch({type:SESSION_NEW_LOADING})
  axios.post('/users/v1/login/', body, config)
  .then(response => {
      dispatch({
          type: SESSION_NEW_SUCCESS,
          payload: response.data
      })
  })
  .catch((err) => {
        dispatch({
          type: SESSION_NEW_FAILED,
          payload: "La ressource est introuvable !!!",
        });
    });
}


export const sessionExpired = () => (dispatch) => {
  dispatch({type:SESSION_EXPIRED})
}


//load le user connecté
export const loadUser = () => (dispatch, getState) => {
    
    //USER LOADING
    dispatch({type: USER_LOADING})
    if(getState().userdata.token_access !== null){
        axios.get(`/users/v1/users/get_users/`, configHeadersToken())
        .then(reponse => {
            // ToastSuccessGeneric().fire();

            dispatch({
                type: USER_LOADED,
                payload: reponse.data
            })
        })
        .catch((err) => {
            if(err?.response?.status === 404){
              console.log("console.log error 404")
              dispatch({
                type:AUTH_ERROR,
                payload: "La ressource est introuvable !!!",
              });
            }else{
              dispatch({
                type:AUTH_ERROR
              });
            }
        });
    }
}

export const setError = () => (dispatch) => {
    dispatch({type: ERROR_VALUE_CLEAR})
}


export const logout = () => (dispatch) => {

    dispatch({
        type: LOGOUT_SUCCESS
    })
}

export const refreshToken = () => (dispatch, getState) => {
    if(getState().userdata.token_access !== null){
        dispatch({
            type:TOKEN_REFRESH_LOADING
        })
        // const token_refresh = localStorage.getItem("token_refresh")
        const token_access = localStorage.getItem('token_access')
        const token_refresh = localStorage.getItem('token_refresh')
        // console.log(getState().userdata.token_refresh)
        axios.post('/users/v1/refresh/', 
                    JSON.stringify({"refresh": token_refresh}), 
                    configHeadersToken(token_access)
        ).then(response => {
            dispatch({
                type: TOKEN_REFRESH_SUCCESS,
                payload: response.data
            })
        })

        .catch((err) => {
            if(err?.response?.status === 404){
              console.log("console.log error 404")
              dispatch({
                type: TOKEN_REFRESH_FAIL,
                payload: "La ressource est introuvable !!!",
              });
            }else{
              dispatch({
                type: TOKEN_REFRESH_FAIL,
                
              });
            }
            
          });
    }
}

export const updatePassword = (body) => (dispatch, getState) => {
    
    //USER LOADING
    dispatch({type: USER_PASSWORD_CHANGE_LOADING})

    if(getState().userdata.token_access !== null){
        
        // // const id_user = jwtDecode(token).user_id
        axios.put(`/users/v1/changepassword/`, body, configHeadersToken())
        .then(reponse => {
            dispatch({
                type: USER_PASSWORD_CHANGE_SUCCESS,
                payload: reponse.data
            })
        })

        .catch((err) => {
            if(err?.response?.status === 404){
              console.log("console.log error 404")
              dispatch({
                type: USER_PASSWORD_CHANGE_FAIL,
                payload: "La ressource est introuvable !!!",
              });
            }else{
              dispatch({
                type: USER_PASSWORD_CHANGE_FAIL,
                payload: err?.response?.data?.old_password[0],
              });
            }
            
          });


    }
    
}


export const circuitPatientCreator = (body) => (dispatch, getState) => {
    //USER LOADING
    dispatch({type: CIRCUIT_PATIENT_LOADING})
    if(getState().userdata.token_access !== null){
        // // const id_user = jwtDecode(token).user_id
        axios.post(`/accueils/v1/circuitdetailpatients/`, body, configHeadersToken())
        .then(reponse => {
            if(reponse.status === 200){
                dispatch({
                    type: CIRCUIT_PATIENT_SUCCESS,
                    payload: reponse.data
                })
            }
        })
        .catch((err) => {
            if(err?.response?.status === 404){
              console.log("console.log error 404")
              dispatch({
                type: CIRCUIT_PATIENT_FAILED,
                payload: "La ressource est introuvable !!!",
              });
            }else{
              dispatch({
                type: CIRCUIT_PATIENT_FAILED,
                payload: err?.response?.data,
              });
            }
        });
    } 
}

export const marqueFicheImprimerCreator = (id, url) => (dispatch, getState) => {
  //USER LOADING
  dispatch({type: MARQUE_IMPRIMER_FICHE_LOADING})
  if(getState().userdata.token_access !== null){
      const url_constante = url ? url : '/caisses/v1/recuspaiements/imprimer/'
      axios.put(`${url_constante}${id}/`, {}, configHeadersToken())
      .then(reponse => {
          if(reponse.status === 200){
              dispatch({
                  type: MARQUE_IMPRIMER_FICHE_SUCCESS,
                  payload: reponse.data
              })
          }
      })
      .catch((err) => {
          if(err?.response?.status === 404){
            console.log("console.log error 404")
            dispatch({
              type: MARQUE_IMPRIMER_FICHE_FAILED,
              payload: "La ressource est introuvable !!!",
            });
          }else{
            dispatch({
              type: MARQUE_IMPRIMER_FICHE_FAILED,
              payload: err?.response?.data,
            });
          }
      });
  } 
}


export const getEmailPasswordForget = (body) => (dispatch) => {
    const config = configHeaders

    dispatch({type:EMAIL_USER_GET_LOADING})

    axios.post('', body, config)
    .then(response => {
        dispatch({
            type: EMAIL_USER_GET_SUCCESS,
            payload: response.data
        })
    })

    .catch((err) => {
        if(err?.response?.status === 404){
          console.log("console.log error 404")
          dispatch({
            type: EMAIL_USER_GET_FAILED,
            payload: "La ressource est introuvable !!!",
          });
        }else{
          dispatch({
            type: EMAIL_USER_GET_FAILED,
            payload: err?.response?.data?.detail
          });
        }
        
      });


}

export const searchModuleInBoard = (data) => (dispatch) => {
    
    dispatch({
            type: ONCHANGE_SEARCH_INPUT,
            payload: data
        })
}

export const clearStapperValues = () => (dispatch) => {
    
    dispatch({type: CLEAR_STAPPERS_VALUE_CONNEXION})
}

