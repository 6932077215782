import React, { lazy } from "react"
import * as MyPrivateRouters from "../routes/PrivateRouter";
import { Switch } from 'react-router-dom';

const StatistiquesProfessionals = lazy(() =>
    import(
      "../../components/statistiques/statistiquesProfessionals/StatistiquesProfessionals"
    )
);
const StatistiquesAccueils = lazy(() =>
import("../../components/statistiques/StatistiquesAccueils/StatistiquesAccueils")
);
const CustomizedStatisticsLayout = lazy(() =>
import(
    "../../components/statistiques/CustomizedStatistics/CustomizedStatisticsLayout"
)
);
const StatistiquePathologies = lazy(() =>
import(
    "../../components/statistiques/StatistiquesPathologies/StatistiquesPathologies"
)
);


const CustomizedCaisseStatisticsLayout = lazy(() => import("../../components/statistiques/CustomizedCaisseStatistics/CustomizedCaisseStatisticsLayout"));

const LayoutStatistiquesBilanPaiementCaisse = lazy(() =>
import(
    "../../components/statistiques/StatistiqueBilanPaiementCaisse/ListeStatistiquesBilanPaiementCaisse/LayoutStatistiquesBilanPaiementCaisse"
))

const StatistiquesPrestations = lazy(() => import("../../components/statistiques/StatistiquesPrestations/StatistiquesPrestations"))

const StatistiqueInfirmierMedecins = lazy(() => import("../../components/statistiques/InfirmierMedecinStat/InfirmierMedecinStat"))

const StatistiquesCaisse = lazy(() =>
import("../../components/statistiques/StatistiquesCaisse/StatistiquesCaisse")
);
  

export default function StatistiquesRouter() {
  return (
    <Switch>
        <MyPrivateRouters.PrivateRouterStatistique
        path="/statistiques"
        component={StatistiquesProfessionals}
        />

        <MyPrivateRouters.PrivateRouterStatistique
        path="/statistiques-accueil"
        component={StatistiquesAccueils}
        />

        <MyPrivateRouters.PrivateRouterStatistique
        path="/statistiques-caisses"
        component={CustomizedCaisseStatisticsLayout}
        />

        <MyPrivateRouters.PrivateRouterStatistique
        path="/statistiques-personnalises"
        component={CustomizedStatisticsLayout}
        />

        <MyPrivateRouters.PrivateRouterStatistique
        path="/statistiques-pathologies"
        component={StatistiquePathologies}
        />

        <MyPrivateRouters.PrivateRouterStatistique
        path="/statistiques-bilan"
        component={LayoutStatistiquesBilanPaiementCaisse}
        />

        <MyPrivateRouters.PrivateRouterStatistique
        path="/statistiques-motifs"
        component={StatistiquesPrestations}
        />

        <MyPrivateRouters.PrivateRouterStatistique
        path="/statistiques-personnels"
        component={StatistiqueInfirmierMedecins}
        />

        <MyPrivateRouters.PrivateRouterStatistique
        path="/statistiques-caisse"
        component={StatistiquesCaisse}
        />
    </Switch>
  )
}
