import React, { lazy } from "react"
import * as MyPrivateRouters from "../routes/PrivateRouter";
import { Switch } from 'react-router-dom';


const LayoutUrgencePatientAConsulter = lazy(() =>
    import(
      "../../components/urgence/patientAConsulter/LayoutUrgencePatientAConsulter"
    )
);

const UrgenceObservation = lazy(() =>
    import("../../components/urgence/observation/UrgenceObservation")
);
const UrgenceObservationTerminees = lazy(() =>
    import("../../components/urgence/observationTerminees/UrgenceObservationTerminees")
);

const LayoutUrgenceConsultationListe = lazy(() =>
    import("../../components/urgence/consultation/LayoutUrgenceConsultationListe")
);
const LayoutUrgenceOrdonnanceListe = lazy(() =>
    import("../../components/urgence/ordonnance/liste/LayoutUrgenceOrdonnanceListe")
);
const LayoutUrgenceOrdonnanceDetails = lazy(() =>
    import(
        "../../components/urgence/ordonnance/detail/LayoutUrgenceOrdonnanceDetails"
    )
);

const LayoutUrgenceConsultationCreate = lazy(() =>
    import(
        "../../components/urgence/patientAConsulterCreate/LayoutUrgenceConsultationCreate"
    )
);
const LayoutUrgenceConsultationView = lazy(() =>
    import(
        "../../components/urgence/patientAConsulterDetail/LayoutUrgenceConsultationView"
    )
);
const LayoutUrgenceCreateConsultationSortie = lazy(() =>
    import(
        "../../components/urgence/patientAConsulterIssuSortie/LayoutUrgenceCreateConsultationSortie"
    )
);
const LayoutUrgenceCreateConsultationSortieDemandeHospitalisation = lazy(() =>
    import(
        "../../components/urgence/patientAConsulterIssuSortie/DemandeHospitalisation/LayoutUrgenceCreateConsultationSortieDemandeHospitalisation"
    )
);
const LayoutUrgenceCreateConsultationSortieMiseEnObservation = lazy(() =>
    import(
        "../../components/urgence/patientAConsulterIssuSortie/MiseEnObservation/LayoutUrgenceCreateConsultationSortieMiseEnObservation"
    )
);
const LayoutUrgenceCreateConsultationRefereExterne = lazy(() =>
    import(
        "../../components/urgence/patientAConsulterIssuSortie/RefereExterne/LayoutUrgenceCreateConsultationRefereExterne"
    )
);
const LayoutUrgenceCreateConsultationRefereInterne = lazy(() =>
    import(
        "../../components/urgence/patientAConsulterIssuSortie/RefereInterne/LayoutUrgenceCreateConsultationRefereInterne"
    )
);

const UrgenceRefExterne = lazy(() =>
    import("../../components/urgence/refExterne/UrgenceRefExterne")
);
const UrgenceRefInterne = lazy(() =>
    import("../../components/urgence/refInterne/UrgenceRefInterne")
);

const LayoutUrgenceDemandeHospitalisationListe = lazy(() =>
    import(
        "../../components/urgence/demandeHospitalisation/LayoutUrgenceDemandeHospitalisationListe"
    )
);

const LayoutUrgenceHospitalisationListe = lazy(() =>
    import(
        "../../components/urgence/hospitalisation/LayoutUrgenceHospitalisationListe"
    )
);

const LayoutUrgenceHospitalisationTermineesListe = lazy(() =>
    import(
        "../../components/urgence/hospitalisationTerminees/LayoutUrgenceHospitalisationTermineesListe"
    )
);

const LayoutUrgenceHospitalisationConsultationCreate = lazy(() =>
    import(
        "../../components/urgence/hospitalisation/consultation/LayoutUrgenceHospitalisationConsultationCreate"
    )
);
const LayoutUrgenceHospitalisationConsultationSortie = lazy(() =>
    import(
        "../../components/urgence/hospitalisation/issueSortie/LayoutUrgenceHospitalisationConsultationSortie"
    )
);

const UrgenceExamensDetail = lazy(() =>
    import("../../components/urgence/Examens/detail")
);
const UrgenceExamensList = lazy(() =>
    import("../../components/urgence/Examens/list")
);

const LayoutUrgenceDecesConsultationSortie = lazy(() =>
    import(
        "../../components/urgence/patientAConsulterIssuSortie/IssueDeDeces/LayoutUrgenceDecesConsultationSortie"
    )
);

const LayoutUrgenceFeuilleDeSoins = lazy(() =>
    import("../../components/urgence/feuilleDeSoins/LayoutUrgenceFeuilleDeSoins")
);

const LayoutUrgenceDecesListe = lazy(() =>
    import("../../components/urgence/Deces/Liste/LayoutUrgenceDecesListe")
);
const LayoutUrgenceDecesCertificat = lazy(() =>
    import("../../components/urgence/Deces/Certificat/LayoutUrgenceDecesCertificat")
);

const AgendaUrgence = lazy(() =>
    import("../../components/urgence/rendezVous/agendaDetail/AgendaUrgence")
);
const LayoutUrgenceCreateConsultationRendezVous = lazy(() =>
    import(
        "../../components/urgence/patientAConsulterIssuSortie/RendezVous/LayoutUrgenceCreateConsultationRendezVous"
    )
);
const LayoutUrgenceRdvListe = lazy(() =>
    import("../../components/urgence/rendezVous/Liste/LayoutUrgenceRdvListe")
);
const LayoutUrgenceRdvLastConsultation = lazy(() =>
    import("../../components/urgence/rendezVous/LastConsultation/LayoutUrgenceRdvLastConsultation")
);
const LayoutUrgenceRdvNewConsultation = lazy(() =>
    import("../../components/urgence/rendezVous/NewConsultation/LayoutUrgenceRdvNewConsultation")
);
const LayoutVoirUrgenceResultatsExamens = lazy(() =>
    import(
        "../../components/urgence/Examens/resultatsExamensVoir/LayoutVoirMedecineGeneralResultatsExamens"
    )
);

const RapportUrgence = lazy(() =>   import("../../components/urgence/rapports/RapportUrgence"));

const LayoutUrgenceControleMajor = lazy(() => 
    import("../../components/urgence/controleMajor/LayoutUrgenceControleMajor")
)
  

export default function UrgenceRouter() {
  return (
    <Switch>
        <MyPrivateRouters.PrivateRouterUrgence
        path="/urgence"
        component={LayoutUrgencePatientAConsulter}
        />

        <MyPrivateRouters.PrivateRouterUrgence
        path="/urgence-observation-encours"
        component={UrgenceObservation}
        />

        <MyPrivateRouters.PrivateRouterUrgence
        path="/urgence-observation-terminee"
        component={UrgenceObservationTerminees}
        />

        <MyPrivateRouters.PrivateRouterUrgence
        path="/urgence-consultation"
        component={LayoutUrgenceConsultationListe}
        />

        <MyPrivateRouters.PrivateRouterUrgence
        path="/urgence-ordonnance"
        component={LayoutUrgenceOrdonnanceListe}
        />

        <MyPrivateRouters.PrivateRouterUrgence
        path="/urgence-ordonnance-voir/:id"
        component={LayoutUrgenceOrdonnanceDetails}
        />

        <MyPrivateRouters.PrivateRouterUrgence
        path="/urgence-consultation-create/:id"
        component={LayoutUrgenceConsultationCreate}
        />

        <MyPrivateRouters.PrivateRouterUrgence
        path="/urgence-consultation-voir-detail/:id"
        component={LayoutUrgenceConsultationView}
        />

        <MyPrivateRouters.PrivateRouterUrgence
        path="/urgence-consultation-sortie/:id"
        component={LayoutUrgenceCreateConsultationSortie}
        />

        <MyPrivateRouters.PrivateRouterUrgence
        path="/urgence-consultation-sortie-demande-hospitalisation/:id"
        component={
            LayoutUrgenceCreateConsultationSortieDemandeHospitalisation
        }
        />

        <MyPrivateRouters.PrivateRouterUrgence
        path="/urgence-consultation-sortie-mise-en-observation/:id"
        component={
            LayoutUrgenceCreateConsultationSortieMiseEnObservation
        }
        />

        <MyPrivateRouters.PrivateRouterUrgence
        path="/urgence-consultation-sortie-refere-interne/:id"
        component={LayoutUrgenceCreateConsultationRefereInterne}
        />

        <MyPrivateRouters.PrivateRouterUrgence
        path="/urgence-consultation-sortie-refere-externe/:id"
        component={LayoutUrgenceCreateConsultationRefereExterne}
        />

        <MyPrivateRouters.PrivateRouterUrgence
        path="/urgence-referencements-externe"
        component={UrgenceRefExterne}
        />

        <MyPrivateRouters.PrivateRouterUrgence
        path="/urgence-referencements-interne"
        component={UrgenceRefInterne}
        />

        <MyPrivateRouters.PrivateRouterUrgence
        path="/urgence-demande-hospitalisation"
        component={LayoutUrgenceDemandeHospitalisationListe}
        />

        <MyPrivateRouters.PrivateRouterUrgence
            path="/urgence-hospitalisation-encours"
            component={LayoutUrgenceHospitalisationListe}
        />

        <MyPrivateRouters.PrivateRouterUrgence
            path="/urgence-hospitalisation-terminee"
            component={LayoutUrgenceHospitalisationTermineesListe}
        />

        <MyPrivateRouters.PrivateRouterUrgence
        path="/urgence-hospitalisation-consultation/:id"
        component={LayoutUrgenceHospitalisationConsultationCreate}
        />

        <MyPrivateRouters.PrivateRouterUrgence
        path="/urgence-hospitalisation-sortie/:id"
        component={LayoutUrgenceHospitalisationConsultationSortie}
        />

        <MyPrivateRouters.PrivateRouterUrgence
        path="/urgence-examens-detail/:id/"
        component={UrgenceExamensDetail}
        />

        <MyPrivateRouters.PrivateRouterUrgence
        path="/urgence-examens"
        component={UrgenceExamensList}
        />

        <MyPrivateRouters.PrivateRouterUrgence
        path="/urgence-consultation-sortie-rdv/:id"
        component={LayoutUrgenceCreateConsultationRendezVous}
        />

        <MyPrivateRouters.PrivateRouterUrgence
        path="/urgence-rdv"
        component={LayoutUrgenceRdvListe}
        />

        <MyPrivateRouters.PrivateRouterUrgence
        path="/urgence-rdv-last-consultation"
        component={LayoutUrgenceRdvLastConsultation}
        />

        <MyPrivateRouters.PrivateRouterUrgence
        path="/urgence-rdv-new-consultation"
        component={LayoutUrgenceRdvNewConsultation}
        />

        <MyPrivateRouters.PrivateRouterUrgence
        path="/urgence-rdv-agenda"
        component={AgendaUrgence}
        />

        <MyPrivateRouters.PrivateRouterUrgence
        path="/urgence-consultation-sortie-deces/:id"
        component={LayoutUrgenceDecesConsultationSortie}
        />

        <MyPrivateRouters.PrivateRouterUrgence
        path="/urgence-observation-consultation/:id"
        component={LayoutUrgenceConsultationCreate}
        />

        <MyPrivateRouters.PrivateRouterUrgence
        path="/urgence-exit-observation/:id"
        component={LayoutUrgenceCreateConsultationSortie}
        />

        <MyPrivateRouters.PrivateRouterUrgence
        path="/urgence-observation-exit-referencement-interne/:id"
        component={LayoutUrgenceCreateConsultationRefereInterne}
        />

        <MyPrivateRouters.PrivateRouterUrgence
        path="/urgence-observation-exit-referencement-externe/:id"
        component={LayoutUrgenceCreateConsultationRefereExterne}
        />

        <MyPrivateRouters.PrivateRouterUrgence
        path="/urgence-observation-exit-rdv/:id"
        component={LayoutUrgenceCreateConsultationRendezVous}
        />

        <MyPrivateRouters.PrivateRouterUrgence
        path="/urgence-observation-exit-deces/:id"
        component={LayoutUrgenceDecesConsultationSortie}
        />

        <MyPrivateRouters.PrivateRouterUrgence
        path="/urgence-observation-exit-demande-hospitalisation/:id"
        component={
            LayoutUrgenceCreateConsultationSortieDemandeHospitalisation
        }
        />

        <MyPrivateRouters.PrivateRouterUrgence
        path="/urgence-feuille-de-soins/:id"
        component={LayoutUrgenceFeuilleDeSoins}
        />

        <MyPrivateRouters.PrivateRouterUrgence
        path="/urgence-patients-decedes"
        component={LayoutUrgenceDecesListe}
        />

        <MyPrivateRouters.PrivateRouterUrgence
        path="/urgence-patients-decedes-certificat/:id"
        component={LayoutUrgenceDecesCertificat}
        />

        <MyPrivateRouters.PrivateRouterUrgence
        path="/urgence-examens-resultat-voir/:id"
        component={LayoutVoirUrgenceResultatsExamens}
        />

        <MyPrivateRouters.PrivateRouterUrgence
        path="/urgence-rapports"
        component={RapportUrgence}
        />

        <MyPrivateRouters.PrivateRouterUrgence
        path="/urgence-controle-major"
        component={LayoutUrgenceControleMajor}
        />
    </Switch>
  )
}
