export const controlePBF = {
  SET_CONTROLE_PBF_SERVICE: "SET_CONTROLE_PBF_SERVICE",
  SET_CONTROLE_PBF_PROFESSIONNEL: "SET_CONTROLE_PBF_PROFESSIONNEL",
  SET_CONTROLE_PBF_DATE_DEBUT: "SET_CONTROLE_PBF_DATE_DEBUT",
  SET_CONTROLE_PBF_DATE_FIN: "SET_CONTROLE_PBF_DATE_FIN",
  SET_CONTROLE_PBF_PATIENTS:"SET_CONTROLE_PBF_PATIENTS",
  SET_CONTROLE_PBF_PATIENTS_DATA_CURRENT_SERVICE_CODE : "SET_CONTROLE_PBF_PATIENTS_DATA_CURRENT_SERVICE_CODE",
  SET_CONTROLE_PBF_ALL_PAGE: "SET_CONTROLE_PBF_ALL_PAGE",
  SET_CONTROLE_PBF_CURRENT_PAGE: "SET_CONTROLE_PBF_CURRENT_PAGE",
  SET_CONTROLE_PBF_ALL_ITEMS_NUMBER: "SET_CONTROLE_PBF_ALL_ITEMS_NUMBER",
  SET_CONTROLE_PBF_ITEMS_PER_PAGE: "SET_CONTROLE_PBF_ITEMS_PER_PAGE",
  SET_CONTROLE_PBF_ITEMS_FIRST_PAGE: "SET_CONTROLE_PBF_ITEMS_FIRST_PAGE",
  SET_CONTROLE_PBF_PAYLOAD_REQUEST: "SET_CONTROLE_PBF_PAYLOAD_REQUEST",
  SET_CONTROLE_PBF_NEXT_PATIENT:"SET_CONTROLE_PBF_NEXT_PATIENT",
  SET_CONTROLE_PBF_TRANCHE_AGE: "SET_CONTROLE_PBF_TRANCHE_AGE",
};