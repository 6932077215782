export const COMMUNE_GET_DATA_LOADING = 'COMMUNE_GET_DATA_LOADING'

export const COMMUNE_GET_DATA_SUCCESS = 'COMMUNE_GET_DATA_SUCCESS'

export const COMMUNE_GET_DATA_FAILED = 'COMMUNE_GET_DATA_FAILED'

export const COMMUNE_GET_DATA_FAILED_403 = "COMMUNE_GET_DATA_FAILED_403"

export const COMMUNE_GET_DATA_FAILED_500 = "COMMUNE_GET_DATA_FAILED_500"


export const COMMUNE_POST_DATA_LOADING = 'COMMUNE_POST_DATA_LOADING'

export const COMMUNE_POST_DATA_SUCCESS = 'COMMUNE_POST_DATA_SUCCESS'

export const COMMUNE_POST_DATA_FAILED = 'COMMUNE_POST_DATA_FAILED'

export const COMMUNE_POST_DATA_FAILED_403 = "COMMUNE_POST_DATA_FAILED_403"

export const COMMUNE_POST_DATA_FAILED_500 = "COMMUNE_POST_DATA_FAILED_500"


export const COMMUNE_PUT_DATA_LOADING = 'COMMUNE_PUT_DATA_LOADING'

export const COMMUNE_PUT_DATA_SUCCESS = 'COMMUNE_PUT_DATA_SUCCESS'

export const COMMUNE_PUT_DATA_FAILED = 'COMMUNE_PUT_DATA_FAILED'

export const COMMUNE_PUT_DATA_FAILED_403 = "COMMUNE_PUT_DATA_FAILED_403"

export const COMMUNE_PUT_DATA_FAILED_500 = "COMMUNE_PUT_DATA_FAILED_500"


export const COMMUNE_DELETE_DATA_LOADING = 'COMMUNE_DELETE_DATA_LOADING'

export const COMMUNE_DELETE_DATA_SUCCESS = 'COMMUNE_DELETE_DATA_SUCCESS'

export const COMMUNE_DELETE_DATA_FAILED = 'COMMUNE_DELETE_DATA_FAILED'

export const COMMUNE_DELETE_DATA_FAILED_403 = "COMMUNE_DELETE_DATA_FAILED_403"

export const COMMUNE_DELETE_DATA_FAILED_500 = "COMMUNE_DELETE_DATA_FAILED_500"


export const COMMUNE_GETS_DATA_LOADING = 'COMMUNE_GETS_DATA_LOADING'

export const COMMUNE_GETS_DATA_SUCCESS = 'COMMUNE_GETS_DATA_SUCCESS'

export const COMMUNE_GETS_DATA_FAILED = 'COMMUNE_GETS_DATA_FAILED'

export const COMMUNE_GETS_DATA_FAILED_403 = "COMMUNE_GETS_DATA_FAILED_403"

export const COMMUNE_GETS_DATA_FAILED_500 = "COMMUNE_GETS_DATA_FAILED_500"


export const COMMUNE_GETS_REFRESH_DATA_LOADING = 'COMMUNE_GETS_REFRESH_DATA_LOADING'

export const COMMUNE_GETS_REFRESH_DATA_SUCCESS = 'COMMUNE_GETS_REFRESH_DATA_SUCCESS'

export const COMMUNE_GETS_REFRESH_DATA_FAILED = 'COMMUNE_GETS_REFRESH_DATA_FAILED'


export const COMMUNE_CLEAR_STATE = "COMMUNE_CLEAR_STATE"