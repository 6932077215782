import * as typeCarteTypes from "../../types/typeCarteTypes";

const initialState = {
  data: [],
  isLoading: false,
  error: null,
};

// const executeGetDataSuccess = (state, action) => {
//   return {
//     ...state,
//     data: action.payload,
//     isLoading: false,
//     error: null,
//   };
// };
// const executeGetDataFailed = (state, action) => {
//   return {
//     ...state,
//     data: null,
//     isLoading: false,
//     error: action.payload,
//   };
// };
// const executeGetDataLoading = (state, action) => {
//   return {
//     ...state,
//     isLoading: true,
//     data: null,
//     error: null,
//   };
// };

const typeCarteReducers = (state = initialState, action) => {
  switch (action.type) {
    case typeCarteTypes.TYPE_CARTE_GET_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        error: null,
      };
    case typeCarteTypes.TYPE_CARTE_GET_DATA_LOADING:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case typeCarteTypes.TYPE_CARTE_GET_DATA_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default typeCarteReducers;
