// import { parametreTypes } from "../../types/parametreTypes";
// import { patientparametreTypes } from "../../types/patientTypes";
import { blocOperatoireParametreTypes } from "../../types/blocOperatoireTypes";
import { AUTH_ERROR, LOGOUT_SUCCESS, TOKEN_REFRESH_FAIL } from "../../types/userTypes";
const initialState = {
    isLoading: false,
    error: "",
    isSuccess:false,
    blocOperatoires: [],
    blocOperatoiresDisponibles: [],
    blocOperatoire: {},
    deleteLoader: false,
};

export const blocOperatoireListReducers = (state = initialState, action) => {
    switch (action.type) {
        case blocOperatoireParametreTypes.BLOC_OPERATOIRE_LIST_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: "",
                isSuccess:false,
                blocOperatoires: [],
                deleteLoader: false,
            };
        case blocOperatoireParametreTypes.BLOC_OPERATOIRE_LIST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                blocOperatoires: action.payload,
                error: "",
                isSuccess:false,
            };
        case blocOperatoireParametreTypes.BLOC_OPERATOIRE_LIST_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                isSuccess:false,
                blocOperatoires: [],
            };

        case blocOperatoireParametreTypes.BLOC_OPERATOIRE_LIST_REFRESH_REQUEST:
            return {
                ...state,
                isLoadingRefresh: true,
                error: "",
                isSuccess:false,
                deleteLoader: false,
            };
        case blocOperatoireParametreTypes.BLOC_OPERATOIRE_LIST_REFRESH_SUCCESS:
            return {
                ...state,
                isLoadingRefresh: false,
                blocOperatoires: action.payload,
                error: "",
                isSuccess:false,
            };
        case blocOperatoireParametreTypes.BLOC_OPERATOIRE_LIST_REFRESH_FAIL:
            return {
                ...state,
                isLoadingRefresh: false,
                error: action.payload,
                isSuccess:false,
                blocOperatoires: [],
            };

        case blocOperatoireParametreTypes.BLOC_OPERATOIRE_LIST_DISPONIBLE_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: "",
                isSuccess:false,
                blocOperatoiresDisponibles: [],
            };
        case blocOperatoireParametreTypes.BLOC_OPERATOIRE_LIST_DISPONIBLE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                blocOperatoiresDisponibles: action.payload,
                error: "",
                isSuccess:false,
            };
        case blocOperatoireParametreTypes.BLOC_OPERATOIRE_LIST_DISPONIBLE_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                isSuccess:false,
                blocOperatoiresDisponibles: [],
            };

        case blocOperatoireParametreTypes.BLOC_OPERATOIRE_POST_LOADING:
            return {
                ...state,
                isLoading: true,
                isSuccess:false,
                error: '',
                blocOperatoire: {},
                deleteLoader: false,
            };
        case blocOperatoireParametreTypes.BLOC_OPERATOIRE_POST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error: "",
                isSuccess:true,
                blocOperatoire: {},
                deleteLoader: false,
            };
        case blocOperatoireParametreTypes.BLOC_OPERATOIRE_POST_FAILED:
            return {
                ...state,
                isLoading: false,
                isSuccess:false,
                error: action.payload,
                blocOperatoire: {},
                deleteLoader: false,
            };
        case blocOperatoireParametreTypes.BLOC_OPERATOIRE_DETAIL_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: "",
                isSuccess:false,
                blocOperatoire: {},
                deleteLoader: false,
            };
        case blocOperatoireParametreTypes.BLOC_OPERATOIRE_DETAIL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error: "",
                isSuccess:false,
                blocOperatoire: {...action.payload},
                deleteLoader: false,
            };
        case blocOperatoireParametreTypes.BLOC_OPERATOIRE_DETAIL_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                isSuccess:false,
                blocOperatoire: {},
                deleteLoader: false,
            };
        case blocOperatoireParametreTypes.BLOC_OPERATOIRE_UPDATE_REQUEST:
            return {
                ...state,
                isLoading: true,
                isSuccess:false,
                error: '',
                blocOperatoire: {},
                deleteLoader: false,
            };
        case blocOperatoireParametreTypes.BLOC_OPERATOIRE_UPDATE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error: "",
                isSuccess:true,
                blocOperatoire: {},
                deleteLoader: false,
            };
        case blocOperatoireParametreTypes.BLOC_OPERATOIRE_UPDATE_FAIL:
            return {
                ...state,
                isLoading: false,
                isSuccess:false,
                error: action.payload,
                blocOperatoire: {},
                deleteLoader: false,
            };
        case blocOperatoireParametreTypes.BLOC_OPERATOIRE_DELETE_REQUEST:
            return {
                ...state,
                deleteLoader: true,
                error: "",
            };
        case blocOperatoireParametreTypes.BLOC_OPERATOIRE_DELETE_SUCCESS:
            return {
                ...state,
                deleteLoader: false,
                blocOperatoires: state.blocOperatoires.filter(
                    (blocOperatoire) => blocOperatoire.id !== action.payload
                  ),
                error: "",
                success: true,
            };
        case blocOperatoireParametreTypes.BLOC_OPERATOIRE_DELETE_FAIL:
            return {
                ...state,
                deleteLoader: false,
                error: action.payload,
            }
            case "SUCCESS_CLEAN":
                return {
                    ...state,
                    success: false,
                    error: "",
                }

        case AUTH_ERROR:
        case LOGOUT_SUCCESS:
        case TOKEN_REFRESH_FAIL:
            localStorage.removeItem('token_access')
            localStorage.removeItem('token_refresh')
            return {
                ...state,
                isLoading: false,
                error: "",
                isSuccess:false,
                blocOperatoires: [],
                blocOperatoiresDisponibles: [],
                blocOperatoire: {},
                deleteLoader: false,
            }

        default:
            return state;
    }
};
