export const recuPaiementTypes = {
    CAISSE_RECU_GETS_LOADING: "CAISSE_RECU_GETS_LOADING",
    CAISSE_RECU_GETS_SUCCESS: "CAISSE_RECU_GETS_SUCCESS",
    CAISSE_RECU_GETS_FAILED: "CAISSE_RECU_GETS_FAILED",

    CAISSE_RECU_GET_STATUT_RECU_LOADING: "CAISSE_RECU_GET_STATUT_RECU_LOADING",
    CAISSE_RECU_GET_STATUT_RECU_SUCCESS: "CAISSE_RECU_GET_STATUT_RECU_SUCCESS",
    CAISSE_RECU_GET_STATUT_RECU_203_SUCCESS: "CAISSE_RECU_GET_STATUT_RECU_203_SUCCESS",
    CAISSE_RECU_GET_STATUT_RECU_FAILED: "CAISSE_RECU_GET_STATUT_RECU_FAILED",

    CAISSE_RECU_GETS_REFRESH_LOADING: "CAISSE_RECU_GETS_REFRESH_LOADING",
    CAISSE_RECU_GETS_REFRESH_SUCCESS: "CAISSE_RECU_GETS_REFRESH_SUCCESS",
    CAISSE_RECU_GETS_REFRESH_FAILED: "CAISSE_RECU_GETS_REFRESH_FAILED",
  
    CAISSE_RECU_GET_LOADING: "CAISSE_RECU_GET_LOADING",
    CAISSE_RECU_GET_SUCCESS: "CAISSE_RECU_GET_SUCCESS",
    CAISSE_RECU_GET_FAILED: "CAISSE_RECU_GET_FAILED",

    CAISSE_RECU_GET_PRESTATION_LOADING: "CAISSE_RECU_GET_PRESTATION_LOADING",
    CAISSE_RECU_GET_PRESTATION_SUCCESS: "CAISSE_RECU_GET_PRESTATION_SUCCESS",
    CAISSE_RECU_GET_PRESTATION_FAILED: "CAISSE_RECU_GET_PRESTATION_FAILED",
    CAISSE_RECU_ANNULER_IMPOSSIBLE_SUCCESS: "CAISSE_RECU_ANNULER_IMPOSSIBLE_SUCCESS",
  
    CAISSE_RECU_ANNULER_LOADING: "CAISSE_RECU_ANNULER_LOADING",
    CAISSE_RECU_ANNULER_SUCCESS: "CAISSE_RECU_ANNULER_SUCCESS",
    CAISSE_RECU_ANNULER_FAILED: "CAISSE_RECU_ANNULER_FAILED",

    CAISSE_RECU_DESARCHIVAGE_LOADING: "CAISSE_RECU_DESARCHIVAGE_LOADING",
    CAISSE_RECU_DESARCHIVAGE_SUCCESS: "CAISSE_RECU_DESARCHIVAGE_SUCCESS",
    CAISSE_RECU_DESARCHIVAGE_FAILED: "CAISSE_RECU_DESARCHIVAGE_FAILED",
  
    CAISSE_RECU_POST_LOADING: "CAISSE_RECU_POST_LOADING",
    CAISSE_RECU_POST_SUCCESS: "CAISSE_RECU_POST_SUCCESS",
    CAISSE_RECU_POST_FAILED: "CAISSE_RECU_POST_FAILED",
    CAISSE_RECU_POST_FAILED_400: "CAISSE_RECU_POST_FAILED_400",
  
    CAISSE_RECU_SET_SUCCESS: 'CAISSE_RECU_SET_SUCCESS',
  };
  