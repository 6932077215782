import { hospitalisationTypes } from "../types/hospitalisationTypes";
import { AUTH_ERROR, LOGOUT_SUCCESS, TOKEN_REFRESH_FAIL } from "../types/userTypes";

const initialState = {
    isLoading: false,
    isLoadingList: false,
    isLoadingListrRefresh: false,
    isLoadingHospitalisation:false,
    demandes: [],
    hospitalisations:[],
    patientAllHospitalisations: [],
    error: "",
    isOriente: false,
}

const hospitalisationReducer = (state = initialState, action) => {
    switch (action.type) {
        case hospitalisationTypes.DEMANDE_HOSPITALISATION_LIST_REQUEST:
            return {
                ...state,
                isLoadingList: true,
                isOriente: false,
                error: ""
            }
        case hospitalisationTypes.DEMANDE_HOSPITALISATION_LIST_SUCCESS:
            return {
                ...state,
                isLoadingList: false,
                demandes: action.payload,
                isOriente: false,
                error: ""
            }
        case hospitalisationTypes.DEMANDE_HOSPITALISATION_LIST_FAIL:
            return {
                ...state,
                isLoadingList: false,
                isOriente: false,
                error: action.payload
            }
        
        
        case hospitalisationTypes.DEMANDE_HOSPITALISATION_LIST_REFRESH_REQUEST:
            return {
                ...state,
                isLoadingListrRefresh: true,
                isOriente: false,
                error: ""
            }
        case hospitalisationTypes.DEMANDE_HOSPITALISATION_LIST_REFRESH_SUCCESS:
            return {
                ...state,
                isLoadingListrRefresh: false,
                demandes: action.payload,
                isOriente: false,
                error: ""
            }
        case hospitalisationTypes.DEMANDE_HOSPITALISATION_LIST_REFRESH_FAIL:
            return {
                ...state,
                isLoadingListrRefresh: false,
                isOriente: false,
                error: action.payload
            }


        case hospitalisationTypes.PATIENT_HOSPITALISATION_REQUEST:
            return {
                ...state,
                isLoadingHospitalisation: true,
                isOriente: false,
                error: ""
            }
        case hospitalisationTypes.PATIENT_HOSPITALISATION_SUCCESS:
            return {
                ...state,
                isLoadingHospitalisation: false,
                demandes: action.payload,
                isOriente: false,
                error: ""
            }
        case hospitalisationTypes.PATIENT_HOSPITALISATION_FAIL:
            return {
                ...state,
                isLoadingHospitalisation: false,
                isOriente: false,
                error: action.payload
            }



        case hospitalisationTypes.HOSPITALISATIONS_LIST_REQUEST:
            return {
                ...state,
                isLoadingHospitalisation: true,
                isOriente: false,
                error: ""
            }
        case hospitalisationTypes.HOSPITALISATIONS_LIST_SUCCESS:
            return {
                ...state,
                isLoadingHospitalisation: false,
                hospitalisations: action.payload,
                isOriente: false,
                error: ""
            }
        case hospitalisationTypes.HOSPITALISATIONS_LIST_FAIL:
            return {
                ...state,
                isLoadingHospitalisation: false,
                isOriente: false,
                error: action.payload
            }


        case hospitalisationTypes.HOSPITALISATION_ORIENTATION_REQUEST:
            return {
                ...state,
                isLoading: true,
                isOriente: false,
                error: "",
            }
        case hospitalisationTypes.HOSPITALISATION_ORIENTATION_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isOriente: true,
                error: "",
            }
        case hospitalisationTypes.HOSPITALISATION_ORIENTATION_FAIL:
            return {
                ...state,
                isLoading: false,
                isOriente: false,
                error: action.payload,
            }


            
        case hospitalisationTypes.HOSPITALISATION_CANCEL_REQUEST:
            return {
                ...state,
                isLoading: true,
                isOriente: false,
                error: ""
            }
        case hospitalisationTypes.HOSPITALISATION_CANCEL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                demandes: [state.demandes.filter((demande) => demande.id !== action.payload.id)],
                isOriente: true,
                error: ""
            }
        case hospitalisationTypes.HOSPITALISATION_CANCEL_FAIL:
            return {
                ...state,
                isLoading: false,
                isOriente: false,
                error: action.payload
            }

        
        case hospitalisationTypes.HOSPITALISATION_RESET_STATE:
            return {
                ...state,
                isLoadingList: false,
                isLoadingHospitalisation:false,
                isLoading: false,
                isOriente: false,
                error: ""
            }
        case hospitalisationTypes.PATIENT_ALL_HOSPITALISATIONS_REQUEST:
            return {
                ...state,
                isLoading: true,
                isOriente: false,
                error: ""
            }
        case hospitalisationTypes.PATIENT_ALL_HOSPITALISATIONS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                patientAllHospitalisations: action.payload,
                isOriente: false,
                error: ""
            }
        case hospitalisationTypes.PATIENT_ALL_HOSPITALISATIONS_FAIL:
            return {
                ...state,
                isLoading: false,
                isOriente: false,
                error: action.payload
            }
        
        case AUTH_ERROR:
        case LOGOUT_SUCCESS:
        case TOKEN_REFRESH_FAIL:
            localStorage.removeItem('token_access')
            localStorage.removeItem('token_refresh')
            return {
                ...state,
                isLoading: false,
                isLoadingList: false,
                isLoadingListrRefresh: false,
                isLoadingHospitalisation:false,
                demandes: [],
                hospitalisations:[],
                error: "",
                isOriente: false,
            }
    
        default:
            return state;
    }
}

export default hospitalisationReducer;