import React, { lazy } from "react"
import * as MyPrivateRouters from "../routes/PrivateRouter";
import { Switch } from 'react-router-dom';


const LayoutPneumologiePatientAConsulter = lazy(() =>
        import("../../components/pneumologie/patientAConsulter/LayoutPneumologiePatientAConsulter")
);
const LayoutPneumologieConsultationListe = lazy(() =>
    import("../../components/pneumologie/consultation/LayoutPneumologieConsultationListe")
);
const LayoutPneumologieDemandeHospitalisationListe = lazy(() =>
    import(
        "../../components/pneumologie/demandeHospitalisation/LayoutPneumologieDemandeHospitalisationListe"
    )
);
const PneumologieObservation = lazy(() =>
    import("../../components/pneumologie/observation/PneumologieObservation")
);
const PneumologieObservationTerminees = lazy(() =>
    import("../../components/pneumologie/observationTerminees/PneumologieObservationTerminees")
);

const LayoutPneumologieConsultationCreate = lazy(() =>
    import("../../components/pneumologie/patientAConsulterCreate/LayoutPneumologieCreateConsultation")
);

const LayoutPneumologieConsultationView = lazy(() =>
    import("../../components/pneumologie/patientAConsulterDetail/LayoutPneumologieViewConsultation")
);

const LayoutCreateConsultationSortiePneumologie = lazy(() =>
    import(
        "../../components/pneumologie/patientAConsulterIssuSortie/LayoutCreateConsultationSortiePneumologie"
    )
);

const LayoutCreateConsultationSortieDemandeHospitalisationPneumologie = lazy(() =>
    import(
        "../../components/pneumologie/patientAConsulterIssuSortie/DemandeHospitalisation/LayoutCreateConsultationSortieDemandeHospitalisationPneumologie"
    )
);
const LayoutCreateConsultationSortieMiseEnObservationPneumologie = lazy(() =>
    import(
        "../../components/pneumologie/patientAConsulterIssuSortie/MiseEnObservation/LayoutCreateConsultationSortieMiseEnObservationPneumologie"
    )
);

const LayoutCreateConsultationRefereInternePneumologie = lazy(() =>
    import(
        "../../components/pneumologie/patientAConsulterIssuSortie/RefereInterne/LayoutCreateConsultationRefereInternePneumologie"
    )
);
const LayoutCreateConsultationRefereExternePneumologie = lazy(() =>
    import(
        "../../components/pneumologie/patientAConsulterIssuSortie/RefereExterne/LayoutCreateConsultationRefereExternePneumologie"
    )
);

const LayoutPneumologieOrdonnanceDetails = lazy(() =>
    import("../../components/pneumologie/ordonnance/detail/LayoutPneumologieOrdonnanceDetails")
);
const LayoutPneumologieOrdonnanceListe = lazy(() =>
    import("../../components/pneumologie/ordonnance/liste/LayoutPneumologieOrdonnanceListe")
);

const LayoutPneumologieHospitalisationListe = lazy(() =>
    import("../../components/pneumologie/hospitalisation/LayoutPneumologieHospitalisationListe")
);

const LayoutPneumologieHospitalisationConsultationCreate = lazy(() =>
    import(
        "../../components/pneumologie/hospitalisation/consultation/LayoutPneumologieHospitalisationConsultationCreate"
    )
);

const LayoutPneumologieHospitalisationConsultationSortie = lazy(() =>
    import(
        "../../components/pneumologie/hospitalisation/issueSortie/LayoutPneumologieHospitalisationConsultationSortie"
    )
);

const LayoutPneumologieFeuilleDeSoins = lazy(() =>
    import("../../components/pneumologie/feuilleDeSoins/LayoutPneumologieFeuilleDeSoins")
);

const PneumologieRefExterne = lazy(() =>
    import("../../components/pneumologie/refExterne/PneumologieRefExterne")
);
const PneumologieRefInterne = lazy(() =>
    import("../../components/pneumologie/refInterne/PneumologieRefInterne")
);
const LayoutPneumologieDecesConsultationSortie = lazy(() =>
    import(
        "../../components/pneumologie/patientAConsulterIssuSortie/IssueDeDeces/LayoutPneumologieDecesConsultationSortie"
    )
);

const DemandeHospiObservationPneumologie = lazy(() =>
    import(
        "../../components/pneumologie/patientAConsulterIssuSortie/MiseEnObservation/DemandeHospitalisation/LayoutCreateConsultationSortieDemandeHospitalisationPneumologie"
    )
);
const RefExterneObservationPneumologie = lazy(() =>
    import(
        "../../components/pneumologie/patientAConsulterIssuSortie/MiseEnObservation/RefereExterne/LayoutCreateConsultationRefereExternePneumologie"
    )
);
const RefInterneObservationPneumologie = lazy(() =>
    import(
        "../../components/pneumologie/patientAConsulterIssuSortie/MiseEnObservation/RefereInterne/LayoutCreateConsultationRefereInternePneumologie"
    )
);

const PneumologieExamensDetail = lazy(() => import("../../components/pneumologie/Examens/detail"));
const PneumologieExamensList = lazy(() => import("../../components/pneumologie/Examens/list"));
const LayoutPneumologieDecesListe = lazy(() =>
    import("../../components/pneumologie/Deces/Liste/LayoutPneumologieDecesListe")
);
const LayoutPneumologieDecesCertificat = lazy(() =>
    import("../../components/pneumologie/Deces/Certificat/LayoutPneumologieDecesCertificat")
);

const AgendaPneumologie = lazy(() =>
    import("../../components/pneumologie/rendezVous/agendaDetail/AgendaPneumologie")
);
const LayoutPneumologieCreateConsultationRendezVous = lazy(() =>
    import(
        "../../components/pneumologie/patientAConsulterIssuSortie/RendezVous/LayoutPneumologieCreateConsultationRendezVous"
    )
);
const LayoutPneumologieRdvListe = lazy(() =>
    import("../../components/pneumologie/rendezVous/Liste/LayoutPneumologieRdvListe")
);

const LayoutPneumologieRdvLastConsultation = lazy(() =>
    import("../../components/pneumologie/rendezVous/LastConsultation/LayoutPneumologieRdvLastConsultation")
);

const LayoutPneumologieRdvNewConsultation = lazy(() =>
    import("../../components/pneumologie/rendezVous/NewConsultation/LayoutPneumologieRdvNewConsultation")
);

const LayoutVoirPneumologieResultatsExamens = lazy(() =>
    import(
        "../../components/pneumologie/Examens/resultatsExamensVoir/LayoutPneumologieRadiologieVoirResultatsExamens"
    )
);

const RapportPneumologie = lazy(() => import("../../components/pneumologie/rapports/RapportPneumologie"));

const LayoutPneumologieControleMajor = lazy(() => 
    import("../../components/pneumologie/controleMajor/LayoutPneumologieControleMajor")
)
  

export default function PneumologieRouter() {
  return (
    <Switch>
        <MyPrivateRouters.PrivateRouterPneumologie
        path="/pneumologie"
        component={LayoutPneumologiePatientAConsulter}
        />

        <MyPrivateRouters.PrivateRouterPneumologie
        path="/pneumologie-demande-hospitalisation"
        component={LayoutPneumologieDemandeHospitalisationListe}
        />

        <MyPrivateRouters.PrivateRouterPneumologie
        path="/pneumologie-observation-encours"
        component={PneumologieObservation}
        />

        <MyPrivateRouters.PrivateRouterPneumologie
        path="/pneumologie-observation-terminee"
        component={PneumologieObservationTerminees}
        />

        <MyPrivateRouters.PrivateRouterPneumologie
        path="/pneumologie-consultation"
        component={LayoutPneumologieConsultationListe}
        />

        <MyPrivateRouters.PrivateRouterPneumologie
        path="/pneumologie-feuille-de-soins/:id"
        component={LayoutPneumologieFeuilleDeSoins}
        />

        <MyPrivateRouters.PrivateRouterPneumologie
        path="/pneumologie-ordonnance"
        component={LayoutPneumologieOrdonnanceListe}
        />

        <MyPrivateRouters.PrivateRouterPneumologie
        path="/pneumologie-ordonnance-voir/:id"
        component={LayoutPneumologieOrdonnanceDetails}
        />

        <MyPrivateRouters.PrivateRouterPneumologie
        path="/pneumologie-rdv"
        component={LayoutPneumologieRdvListe}
        />

        <MyPrivateRouters.PrivateRouterPneumologie
        path="/pneumologie-rdv-last-consultation/:id"
        component={LayoutPneumologieRdvLastConsultation}
        />

        <MyPrivateRouters.PrivateRouterPneumologie
        path="/pneumologie-rdv-new-consultation/:id"
        component={LayoutPneumologieRdvNewConsultation}
        />

        <MyPrivateRouters.PrivateRouterPneumologie
        path="/pneumologie-rdv-agenda/"
        component={AgendaPneumologie}
        />

        <MyPrivateRouters.PrivateRouterPneumologie
        path="/pneumologie-consultation-create/:id"
        component={LayoutPneumologieConsultationCreate}
        />

        <MyPrivateRouters.PrivateRouterPneumologie
        path="/pneumologie-consultation-voir-detail/:id"
        component={LayoutPneumologieConsultationView}
        />

        <MyPrivateRouters.PrivateRouterPneumologie
        path="/pneumologie-hospitalisation"
        component={LayoutPneumologieHospitalisationListe}
        />

        <MyPrivateRouters.PrivateRouterPneumologie
        path="/pneumologie-hospitalisation-consultation/:id"
        component={LayoutPneumologieHospitalisationConsultationCreate}
        />

        <MyPrivateRouters.PrivateRouterPneumologie
        path="/pneumologie-hospitalisation-sortie/:id"
        component={LayoutPneumologieHospitalisationConsultationSortie}
        />

        <MyPrivateRouters.PrivateRouterPneumologie
        path="/pneumologie-consultation-sortie/:id"
        component={LayoutCreateConsultationSortiePneumologie}
        />

        <MyPrivateRouters.PrivateRouterPneumologie
        path="/pneumologie-consultation-sortie-demande-hospitalisation/:id"
        component={
            LayoutCreateConsultationSortieDemandeHospitalisationPneumologie
        }
        />

        <MyPrivateRouters.PrivateRouterPneumologie
        path="/pneumologie-consultation-sortie-mise-en-observation/:id"
        component={LayoutCreateConsultationSortieMiseEnObservationPneumologie}
        />

        <MyPrivateRouters.PrivateRouterPneumologie
        path="/pneumologie-consultation-sortie-refere-interne/:id"
        component={LayoutCreateConsultationRefereInternePneumologie}
        />

        <MyPrivateRouters.PrivateRouterPneumologie
        path="/pneumologie-consultation-sortie-refere-externe/:id"
        component={LayoutCreateConsultationRefereExternePneumologie}
        />

        <MyPrivateRouters.PrivateRouterPneumologie
        path="/pneumologie-consultation-sortie-rdv/:id"
        component={LayoutPneumologieCreateConsultationRendezVous}
        />

        <MyPrivateRouters.PrivateRouterPneumologie
        path="/pneumologie-referencements-externe"
        component={PneumologieRefExterne}
        />

        <MyPrivateRouters.PrivateRouterPneumologie
        path="/pneumologie-referencements-interne"
        component={PneumologieRefInterne}
        />

        <MyPrivateRouters.PrivateRouterPneumologie
        path="/pneumologie-consultation-sortie-deces/:id"
        component={LayoutPneumologieDecesConsultationSortie}
        />

        <MyPrivateRouters.PrivateRouterPneumologie
        path="/pneumologie-observation-consultation/:id"
        component={LayoutPneumologieConsultationCreate}
        />

        <MyPrivateRouters.PrivateRouterPneumologie
        path="/pneumologie-exit-observation/:id"
        component={LayoutCreateConsultationSortiePneumologie}
        />

        <MyPrivateRouters.PrivateRouterPneumologie
        path="/pneumologie-observation-exit-demande-hospitalisation/:id"
        component={DemandeHospiObservationPneumologie}
        />

        <MyPrivateRouters.PrivateRouterPneumologie
        path="/pneumologie-observation-exit-reference-externe/:id"
        component={RefExterneObservationPneumologie}
        />

        <MyPrivateRouters.PrivateRouterPneumologie
        path="/pneumologie-observation-exit-reference-interne/:id"
        component={RefInterneObservationPneumologie}
        />

        <MyPrivateRouters.PrivateRouterPneumologie
        path="/pneumologie-observation-exit-rdv/:id"
        component={LayoutPneumologieCreateConsultationRendezVous}
        />

        <MyPrivateRouters.PrivateRouterPneumologie
        path="/pneumologie-observation-exit-deces/:id"
        component={LayoutPneumologieDecesConsultationSortie}
        />

        <MyPrivateRouters.PrivateRouterPneumologie
        path="/pneumologie-examens-detail/:id/"
        component={PneumologieExamensDetail}
        />

        <MyPrivateRouters.PrivateRouterPneumologie
        path="/pneumologie-examens"
        component={PneumologieExamensList}
        />

        <MyPrivateRouters.PrivateRouterPneumologie
        path="/pneumologie-patients-decedes"
        component={LayoutPneumologieDecesListe}
        />

        <MyPrivateRouters.PrivateRouterPneumologie
        path="/pneumologie-patients-decedes-certificat/:id"
        component={LayoutPneumologieDecesCertificat}
        />

        <MyPrivateRouters.PrivateRouterPneumologie
        path="/pneumologie-examens-resultat-voir/:id"
        component={LayoutVoirPneumologieResultatsExamens}
        />

        <MyPrivateRouters.PrivateRouterPneumologie
        path="/pneumologie-rapports"
        component={RapportPneumologie}
        />

        <MyPrivateRouters.PrivateRouterPneumologie
        path="/pneumologie-controle-major"
        component={LayoutPneumologieControleMajor}
        />
    </Switch>
  )
}
