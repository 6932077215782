import React, { lazy } from "react"
import * as MyPrivateRouters from "../routes/PrivateRouter";
import { Switch } from 'react-router-dom';


const LayoutSuiteDeCoucheListeSansComplication = lazy(() =>
    import("../../components/suiteDeCouche/SansComplication/Liste/LayoutListeSuiteCoucheSansComplications.jsx")
);
const LayoutSuiteDeCoucheConsultationSansComplication = lazy(() =>
import("../../components/suiteDeCouche/SansComplication/Create/LayoutSuiteCoucheSansComplications.jsx")
);
const LayoutSuiteDeCoucheBatiments = lazy(() =>
import("../../components/suiteDeCouche/batiments/LayoutSuiteDeCoucheBatiments")
);
const LayoutSuiteDeCoucheChambres = lazy(() =>
import("../../components/suiteDeCouche/chambres/LayoutSuiteDeCoucheChambres")
);
const LayoutSuiteDeCoucheLits = lazy(() =>
import("../../components/suiteDeCouche/lits/LayoutSuiteDeCoucheLits")
);
const LayoutSuiteDeCoucheTermine = lazy(() =>
import(
    "../../components/suiteDeCouche/dejaHospitalise/LayoutSuiteDeCoucheTermine"
)
);
const LayoutSuiteDeCoucheFeuilleDeSoins = lazy(() =>
import(
    "../../components/suiteDeCouche/feuilleDeSoins/LayoutSuiteDeCoucheFeuilleDeSoins"
)
);
  

export default function SuiteDeCoucheRouter() {
  return (
    <Switch>
        <MyPrivateRouters.PrivateRouterSuiteDeCouche
        path="/suite-de-couche"
        component={LayoutSuiteDeCoucheListeSansComplication}
        />

        <MyPrivateRouters.PrivateRouterSuiteDeCouche
        path="/suite-de-couche-sans-complication-consultation/:id"
        component={LayoutSuiteDeCoucheConsultationSansComplication}
        />

        <MyPrivateRouters.PrivateRouterSuiteDeCouche
        path="/suite-de-couche-termines/"
        component={LayoutSuiteDeCoucheTermine}
        />

        <MyPrivateRouters.PrivateRouterSuiteDeCouche
        path="/suite-de-couche-termines-feuille-de-soins/:id/"
        component={LayoutSuiteDeCoucheFeuilleDeSoins}
        />

        <MyPrivateRouters.PrivateRouterSuiteDeCouche
        path="/suite-de-couche-batiments/"
        component={LayoutSuiteDeCoucheBatiments}
        />

        <MyPrivateRouters.PrivateRouterSuiteDeCouche
        path="/suite-de-couche-lits/"
        component={LayoutSuiteDeCoucheLits}
        />

        <MyPrivateRouters.PrivateRouterSuiteDeCouche
        path="/suite-de-couche-chambres/"
        component={LayoutSuiteDeCoucheChambres}
        />
    </Switch>
  )
}
