import * as ethnieTypes from "../../types/ethnieTypes";
import * as typesUsers from "../../types/userTypes"

const initialState = {
  data: [],
  isLoading: false,
  error: "",
};

const executeGetDataSuccess = (state, action) => {
  return {
    ...state,
    data: action.payload,
    isLoading: false,
    error: "",
  };
};
const executeGetDataFailed = (state, action) => {
  return {
    ...state,
    isLoading: false,
    error: action.payload,
  };
};
const executeGetDataLoading = (state) => {
  return {
    ...state,
    isLoading: true,
    error: "",
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ethnieTypes.ETHINE_GET_DATA_SUCCESS:
      return executeGetDataSuccess(state, action);

    case ethnieTypes.ETHINE_GET_DATA_LOADING:
      return executeGetDataLoading(state, action);

    case ethnieTypes.ETHINE_GET_DATA_FAILED:
      return executeGetDataFailed(state, action);

    case typesUsers.AUTH_ERROR:
    case typesUsers.LOGOUT_SUCCESS:
    case typesUsers.TOKEN_REFRESH_FAIL:
        localStorage.removeItem("token_access");
        localStorage.removeItem("token_refresh");
        return {
            ...state,
            data: [],
            isLoading: false,
            error:null
        }
    default:
      return state;
  }
};

export default reducer;
