import React, { lazy } from "react"
import * as MyPrivateRouters from "../routes/PrivateRouter";
import { Switch } from 'react-router-dom';

const LayoutCorbeillePatientsListe = lazy(() =>
  import(
    "../../components/corbeille/patients/LayoutCorbeillePatientsListe"
  )
);

const LayoutCorbeilleFichesPaiementsListe = lazy(() =>
  import(
    "../../components/corbeille/fichespaiement/LayoutCorbeilleFichesPaiementsListe"
  )
);

const LayoutCorbeilleRecusPaiementsListe = lazy(() =>
  import(
    "../../components/corbeille/recuspaiement/LayoutCorbeilleRecusPaiementsListe"
  )
);

const LayoutCorbeilleRendezVousListe = lazy(() =>
  import(
    "../../components/corbeille/rendezvous/LayoutCorbeilleRendezVousListe"
  )
);

const LayoutCorbeilleUsersListe = lazy(() =>
  import(
    "../../components/corbeille/users/CorbeilleUserLayout"
  )
);
  

export default function CorbeilleRouter() {
  return (
    <Switch>
        <MyPrivateRouters.PrivateRouterCorbeille
          path="/corbeille"
          component={LayoutCorbeillePatientsListe}
        />

        <MyPrivateRouters.PrivateRouterCorbeille
          path="/corbeille-patients"
          component={LayoutCorbeillePatientsListe}
        />

        <MyPrivateRouters.PrivateRouterCorbeille
          path="/corbeille-fiches-paiements"
          component={LayoutCorbeilleFichesPaiementsListe}
        />

        <MyPrivateRouters.PrivateRouterCorbeille
          path="/corbeille-recus-paiements"
          component={LayoutCorbeilleRecusPaiementsListe}
        />

        <MyPrivateRouters.PrivateRouterCorbeille
          path="/corbeille-rendezvous"
          component={LayoutCorbeilleRendezVousListe}
        />
        
        <MyPrivateRouters.PrivateRouterCorbeille
          path="/corbeille-users"
          component={LayoutCorbeilleUsersListe}
        />
    </Switch>
  )
}
