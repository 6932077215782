import { facturationTypes } from "../../types/facturationTypes";
import { AUTH_ERROR, LOGOUT_SUCCESS, TOKEN_REFRESH_FAIL } from "../../types/userTypes"


const initialState = {
    referenceInternePatients: [],
    referenceInternePatient: {},
    referenceInternePatientPost: {},

    hospitalisationPatients: [],
    hospitalisationPatient: {},
    hospitalisationPatientPost: {},

    demandeExamenPatients: [],
    demandeExamenPatient: {},
    demandeExamenPatientPost: {},

    actesPosesPatients: [],
    actesPosesPatient: {},
    actesPosesPatientPost: {},

    isLoadingReferenceInternePatients: false,
    isSuccesReferenceInternePatients: false,

    isLoadingReferenceInterneRefreshPatients: false,
    isSuccesReferenceInterneRefreshPatients: false,

    isLoadingReferenceInternePatient: false,
    isSuccesReferenceInternePatient: false,

    isLoadingReferenceInternePatientPost: false,
    isSuccesReferenceInternePatientPost: false,


    isLoadingHospitalisationPatients: false,
    isSuccesHospitalisationPatients: false,

    isLoadingHospitalisationRefreshPatients: false,
    isSuccesHospitalisationRefreshPatients: false,

    isLoadingHospitalisationPatient: false,
    isSuccesHospitalisationPatient: false,

    isLoadingHospitalisationPatientPost: false,
    isSuccesHospitalisationPatientPost: false,


    isLoadingDemandeExamenPatients: false,
    isSuccesDemandeExamenPatients: false,

    isLoadingDemandeExamenRefreshPatients: false,
    isSuccesDemandeExamenRefreshPatients: false,

    isLoadingDemandeExamenPatient: false,
    isSuccesDemandeExamenPatient: false,

    isLoadingDemandeExamenPatientPost: false,
    isSuccesDemandeExamenPatientPost: false,

    isLoadingActesPosesPatients: false,
    isSuccesActesPosesPatients: false,

    isLoadingActesPosesPatient: false,
    isSuccesActesPosesPatient: false,

    isLoadingActesPosesPatientPost: false,
    isSuccesActesPosesPatientPost: false,

    isError: false,
    error:''
}


// eslint-disable-next-line
export default function (state = initialState, action) {
    switch (action.type) {
        
        //gets informations fiches references internes patients facturation
        case facturationTypes.FACTURATION_REFERENCE_INTERNE_GETS_LOADING:
            return {
                ...state,
                isLoadingReferenceInternePatients: true,
                isError:false,
                error:""
            }
        case facturationTypes.FACTURATION_REFERENCE_INTERNE_GETS_SUCCESS:
            return {
                ...state,
                isLoadingReferenceInternePatients: false,
                isSuccesReferenceInternePatients:true,
                referenceInternePatients: action.payload,
                isError:false,
                error:""
            }
        case facturationTypes.FACTURATION_REFERENCE_INTERNE_GETS_FAILED:
            return {
                ...state,
                isLoadingReferenceInternePatients: false,
                isSuccesReferenceInternePatients:false,
                isError:true,
                error: action.payload
            }

        
        //gets informations fiches references internes refresh patients facturation
        case facturationTypes.FACTURATION_REFERENCE_INTERNE_GETS_REFRESH_LOADING:
            return {
                ...state,
                isLoadingReferenceInterneRefreshPatients: true,
                isError:false,
                error:""
            }
        case facturationTypes.FACTURATION_REFERENCE_INTERNE_GETS_REFRESH_SUCCESS:
            return {
                ...state,
                isLoadingReferenceInterneRefreshPatients: false,
                isSuccesReferenceInterneRefreshPatients:true,
                referenceInternePatients: action.payload,
                isError:false,
                error:""
            }
        case facturationTypes.FACTURATION_REFERENCE_INTERNE_GETS_REFRESH_FAILED:
            return {
                ...state,
                isLoadingReferenceInterneRefreshPatients: false,
                isSuccesReferenceInterneRefreshPatients:false,
                isError:true,
                error: action.payload
            }

        
        //get informations fiche reference interne patient facturation
        case facturationTypes.FACTURATION_REFERENCE_INTERNE_GET_LOADING:
            return {
                ...state,
                isLoadingReferenceInternePatient: true,
                referenceInternePatient: {},
                isError:false,
                error:""
            }
        case facturationTypes.FACTURATION_REFERENCE_INTERNE_GET_SUCCESS:
            return {
                ...state,
                isLoadingReferenceInternePatient: false,
                isSuccesReferenceInternePatient:true,
                referenceInternePatient: action.payload,
                isError:false,
                error:""
            }
        case facturationTypes.FACTURATION_REFERENCE_INTERNE_GET_FAILED:
            return {
                ...state,
                isLoadingReferenceInternePatient: false,
                isSuccesReferenceInternePatient:false,
                referenceInternePatient: {},
                isError:true,
                error: action.payload
            }
        
        
        //post fiche reference interne patient facturation
        case facturationTypes.FACTURATION_REFERENCE_INTERNE_POST_LOADING:
            return {
                ...state,
                isLoadingReferenceInternePatientPost: true,
                referenceInternePatientPost: {},
                isError:false,
                error:""
            }
        case facturationTypes.FACTURATION_REFERENCE_INTERNE_POST_SUCCESS:
            return {
                ...state,
                isLoadingReferenceInternePatientPost: false,
                isSuccesReferenceInternePatientPost: true,
                referenceInternePatientPost: action.payload,
                isError:false,
                error:""
            }
        case facturationTypes.FACTURATION_REFERENCE_INTERNE_POST_FAILED:
            return {
                ...state,
                isLoadingReferenceInternePatientPost: false,
                isSuccesReferenceInternePatientPost: false,
                referenceInternePatientPost: {},
                isError:true,
                error: action.payload
            }
        
        
        //gets informations fiches hospitalisations patients facturation
        case facturationTypes.FACTURATION_HOSPITALISATION_GETS_LOADING:
            return {
                ...state,
                isLoadingHospitalisationPatients: true,
                isError:false,
                error:""
            }
        case facturationTypes.FACTURATION_HOSPITALISATION_GETS_SUCCESS:
            return {
                ...state,
                isLoadingHospitalisationPatients: false,
                isSuccesHospitalisationPatients:true,
                hospitalisationPatients: action.payload,
                isError:false,
                error:""
            }
        case facturationTypes.FACTURATION_HOSPITALISATION_GETS_FAILED:
            return {
                ...state,
                isLoadingHospitalisationPatients: false,
                isSuccesHospitalisationPatients:false,
                isError:true,
                error: action.payload
            }

        
        //gets informations fiches hospitalisations refresh patients facturation
        case facturationTypes.FACTURATION_HOSPITALISATION_GETS_REFRESH_LOADING:
            return {
                ...state,
                isLoadingHospitalisationRefreshPatients: true,
                isError:false,
                error:""
            }
        case facturationTypes.FACTURATION_HOSPITALISATION_GETS_REFRESH_SUCCESS:
            return {
                ...state,
                isLoadingHospitalisationRefreshPatients: false,
                isSuccesHospitalisationRefreshPatients:true,
                hospitalisationPatients: action.payload,
                isError:false,
                error:""
            }
        case facturationTypes.FACTURATION_HOSPITALISATION_GETS_REFRESH_FAILED:
            return {
                ...state,
                isLoadingHospitalisationRefreshPatients: false,
                isSuccesHospitalisationRefreshPatients:false,
                isError:true,
                error: action.payload
            }

        
        //get informations fiche hospitalisation patient facturation
        case facturationTypes.FACTURATION_HOSPITALISATION_GET_LOADING:
            return {
                ...state,
                isLoadingHospitalisationPatient: true,
                hospitalisationPatient: {},
                isError:false,
                error:"",
                isSuccesHospitalisationPatient:false,
            }
        case facturationTypes.FACTURATION_HOSPITALISATION_GET_SUCCESS:
            return {
                ...state,
                isLoadingHospitalisationPatient: false,
                isSuccesHospitalisationPatient:true,
                hospitalisationPatient: action.payload,
                isError:false,
                error:""
            }
        case facturationTypes.FACTURATION_HOSPITALISATION_GET_FAILED:
            return {
                ...state,
                isLoadingHospitalisationPatient: false,
                isSuccesHospitalisationPatient:false,
                hospitalisationPatient: {},
                isError:true,
                error: action.payload,
            }
        
        
        //post fiche hospitalisations patient facturation
        case facturationTypes.FACTURATION_HOSPITALISATION_POST_LOADING:
            return {
                ...state,
                isLoadingHospitalisationPatientPost: true,
                hospitalisationPatientPost: {},
                isError:false,
                error:""
            }
        case facturationTypes.FACTURATION_HOSPITALISATION_POST_SUCCESS:
            return {
                ...state,
                isLoadingHospitalisationPatientPost: false,
                isSuccesHospitalisationPatientPost: true,
                hospitalisationPatientPost: action.payload,
                isError:false,
                error:""
            }
        case facturationTypes.FACTURATION_HOSPITALISATION_POST_FAILED:
            return {
                ...state,
                isLoadingHospitalisationPatientPost: false,
                isSuccesHospitalisationPatientPost: false,
                hospitalisationPatientPost: {},
                isError:true,
                error: action.payload
            }


        
        //gets informations fiches demande examens patients facturation
        case facturationTypes.FACTURATION_DEMANDE_EXAMEN_GETS_LOADING:
            return {
                ...state,
                isLoadingDemandeExamenPatients: true,
                isLoadingDemandeExamenPatientsAttentes: true,
                isError:false,
                error:""
            }
        case facturationTypes.FACTURATION_DEMANDE_EXAMEN_GETS_SUCCESS:
            return {
                ...state,
                isLoadingDemandeExamenPatients: false,
                isLoadingDemandeExamenPatientsAttentes: false,
                isSuccesDemandeExamenPatients:true,
                demandeExamenPatients: action.payload,
                isError:false,
                error:""
            }
        case facturationTypes.FACTURATION_DEMANDE_EXAMEN_GETS_FAILED:
            return {
                ...state,
                isLoadingDemandeExamenPatients: false,
                isLoadingDemandeExamenPatientsAttentes: false,
                isSuccesDemandeExamenPatients:false,
                isError:true,
                error: action.payload
            }

        
        //gets informations fiches demande examens refresh patients facturation
        case facturationTypes.FACTURATION_DEMANDE_EXAMEN_GETS_REFRESH_LOADING:
            return {
                ...state,
                isLoadingDemandeExamenRefreshPatients: true,
                isLoadingDemandeExamenPatientsAttentes: false,
                isError:false,
                error:""
            }
        case facturationTypes.FACTURATION_DEMANDE_EXAMEN_GETS_REFRESH_SUCCESS:
            return {
                ...state,
                isLoadingDemandeExamenRefreshPatients: false,
                isSuccesDemandeExamenRefreshPatients:true,
                demandeExamenPatients: action.payload,
                isError:false,
                error:""
            }
        case facturationTypes.FACTURATION_DEMANDE_EXAMEN_GETS_REFRESH_FAILED:
            return {
                ...state,
                isLoadingDemandeExamenRefreshPatients: false,
                isSuccesDemandeExamenRefreshPatients:false,
                isError:true,
                error: action.payload
            }

        
        //get informations fiche demande examen patient facturation
        case facturationTypes.FACTURATION_DEMANDE_EXAMEN_GET_LOADING:
            return {
                ...state,
                isLoadingDemandeExamenPatient: true,
                demandeExamenPatient: {},
                isError:false,
                error:""
            }
        case facturationTypes.FACTURATION_DEMANDE_EXAMEN_GET_SUCCESS:
            return {
                ...state,
                isLoadingDemandeExamenPatient: false,
                isSuccesDemandeExamenPatient:true,
                demandeExamenPatient: action.payload,
                isError:false,
                error:""
            }
        case facturationTypes.FACTURATION_DEMANDE_EXAMEN_GET_FAILED:
            return {
                ...state,
                isLoadingDemandeExamenPatient: false,
                isSuccesDemandeExamenPatient:false,
                demandeExamenPatient: {},
                isError:true,
                error: action.payload
            }
        
        
        //post fiche demande examen patient facturation
        case facturationTypes.FACTURATION_DEMANDE_EXAMEN_POST_LOADING:
            return {
                ...state,
                isLoadingDemandeExamenPatientPost: true,
                isLoadingDemandeExamenPatientsAttentes: false,
                demandeExamenPatientPost: {},
                isError:false,
                error:""
            }
        case facturationTypes.FACTURATION_DEMANDE_EXAMEN_POST_SUCCESS:
            return {
                ...state,
                isLoadingDemandeExamenPatientPost: false,
                isLoadingDemandeExamenPatientsAttentes: true,
                isSuccesDemandeExamenPatientPost: true,
                demandeExamenPatientPost: action.payload,
                isError:false,
                error:""
            }
        case facturationTypes.FACTURATION_DEMANDE_EXAMEN_POST_FAILED:
            return {
                ...state,
                isLoadingDemandeExamenPatientPost: false,
                isLoadingDemandeExamenPatientsAttentes: false,
                isSuccesDemandeExamenPatientPost: false,
                demandeExamenPatientPost: {},
                isError:true,
                error: action.payload
            }
        

        //gets liste fiche facturation actes posés 
        case facturationTypes.FACTURATION_ACTES_POSES_LOADING:
            return {
                ...state,
                isLoadingActesPosesPatients: true,
                isSuccesActesPosesPatients: false,
                isError:false,
                error:""
            }
        case facturationTypes.FACTURATION_ACTES_POSES_SUCCESS:
            return {
                ...state,
                isLoadingActesPosesPatients: false,
                isSuccesActesPosesPatients: true,
                actesPosesPatients: action.payload,
                isError:false,
                error:""
            }
        case facturationTypes.FACTURATION_ACTES_POSES_FAILED:
            return {
                ...state,
                isLoadingActesPosesPatients: false,
                isSuccesActesPosesPatients: false,
                isError:true,
                error: action.payload
            }

        
        //get informations fiche facturation actes posés patient 
        case facturationTypes.FACTURATION_ACTES_POSES_DETAILS_LOADING:
            return {
                ...state,
                isLoadingActesPosesPatient: true,
                isSuccesActesPosesPatient: false,
                demandeExamenPatient: {},
                isError:false,
                error:""
            }
        case facturationTypes.FACTURATION_ACTES_POSES_DETAILS_SUCCESS:
            return {
                ...state,
                isLoadingActesPosesPatient: false,
                isSuccesActesPosesPatient: true,
                actesPosesPatient: action.payload,
                isError:false,
                error:""
            }
        case facturationTypes.FACTURATION_ACTES_POSES_DETAILS_FAILED:
            return {
                ...state,
                isLoadingActesPosesPatient: false,
                isSuccesActesPosesPatient: false,
                actesPosesPatient: {},
                isError:true,
                error: action.payload
            }
        
        
        //post fiche facturation actes posés patient
        case facturationTypes.FACTURATION_ACTES_POSES_POST_LOADING:
            return {
                ...state,
                isLoadingActesPosesPatientPost: true,
                isSuccesActesPosesPatientPost: false,
                demandeExamenPatientPost: {},
                isError:false,
                error:""
            }
        case facturationTypes.FACTURATION_ACTES_POSES_POST_SUCCESS:
            return {
                ...state,
                isLoadingActesPosesPatientPost: false,
                isSuccesActesPosesPatientPost: true,
                actesPosesPatientPost: action.payload,
                isError:false,
                error:""
            }
        case facturationTypes.FACTURATION_ACTES_POSES_POST_FAILED:
            return {
                ...state,
                isLoadingActesPosesPatientPost: false,
                isSuccesActesPosesPatientPost: false,
                actesPosesPatientPost: {},
                isError:true,
                error: action.payload
            }
        
        //resets des constantes
        case facturationTypes.FACTURATION_STARTER_RESET:
            return {
                ...state,
                isLoadingReferenceInternePatients: false,
                isSuccesReferenceInternePatients: false,

                isLoadingReferenceInterneRefreshPatients: false,
                isSuccesReferenceInterneRefreshPatients: false,

                isLoadingReferenceInternePatient: false,
                isSuccesReferenceInternePatient: false,

                isLoadingReferenceInternePatientPost: false,
                isSuccesReferenceInternePatientPost: false,


                isLoadingHospitalisationPatients: false,
                isSuccesHospitalisationPatients: false,

                isLoadingHospitalisationRefreshPatients: false,
                isSuccesHospitalisationRefreshPatients: false,

                isLoadingHospitalisationPatient: false,
                isSuccesHospitalisationPatient: false,

                isLoadingHospitalisationPatientPost: false,
                isSuccesHospitalisationPatientPost: false,


                isLoadingDemandeExamenPatients: false,
                isSuccesDemandeExamenPatients: false,

                isLoadingDemandeExamenRefreshPatients: false,
                isSuccesDemandeExamenRefreshPatients: false,

                isLoadingDemandeExamenPatient: false,
                isSuccesDemandeExamenPatient: false,

                isLoadingDemandeExamenPatientPost: false,
                isSuccesDemandeExamenPatientPost: false,

                isLoadingActesPosesPatients: false,
                isSuccesActesPosesPatients: false,
            
                isLoadingActesPosesPatient: false,
                isSuccesActesPosesPatient: false,
            
                isLoadingActesPosesPatientPost: false,
                isSuccesActesPosesPatientPost: false,

                isError: false,
                error:''
            }
    
        case AUTH_ERROR:
        case LOGOUT_SUCCESS:
        case TOKEN_REFRESH_FAIL:
            localStorage.removeItem("token_access")
            localStorage.removeItem("token_refresh")
            return {
                ...state,
                referenceInternePatients: [],
                referenceInternePatient: {},
                referenceInternePatientPost: {},

                hospitalisationPatients: [],
                hospitalisationPatient: {},
                hospitalisationPatientPost: {},

                demandeExamenPatients: [],
                demandeExamenPatient: {},
                demandeExamenPatientPost: {},

                isLoadingReferenceInternePatients: false,
                isSuccesReferenceInternePatients: false,

                isLoadingReferenceInterneRefreshPatients: false,
                isSuccesReferenceInterneRefreshPatients: false,

                isLoadingReferenceInternePatient: false,
                isSuccesReferenceInternePatient: false,

                isLoadingReferenceInternePatientPost: false,
                isSuccesReferenceInternePatientPost: false,


                isLoadingHospitalisationPatients: false,
                isSuccesHospitalisationPatients: false,

                isLoadingHospitalisationRefreshPatients: false,
                isSuccesHospitalisationRefreshPatients: false,

                isLoadingHospitalisationPatient: false,
                isSuccesHospitalisationPatient: false,

                isLoadingHospitalisationPatientPost: false,
                isSuccesHospitalisationPatientPost: false,


                isLoadingDemandeExamenPatients: false,
                isSuccesDemandeExamenPatients: false,

                isLoadingDemandeExamenRefreshPatients: false,
                isSuccesDemandeExamenRefreshPatients: false,

                isLoadingDemandeExamenPatient: false,
                isSuccesDemandeExamenPatient: false,

                isLoadingDemandeExamenPatientPost: false,
                isSuccesDemandeExamenPatientPost: false,

                isError: false,
                error:''
            }

        default:
            return state
  }
}
