import axios from "axios";
import { server_url } from "../reduxFiles/axios";


axios.defaults.baseURL = server_url

axios.defaults.headers.post["Content-Type"] = "application/json";

axios.interceptors.request.use((req) => {
  if (!req.url.includes("login")) {
    req.headers = {
      "Content-Type": "application/json",
      // 'Authorization':  `Bearer ${token}`
    };
  }

  if (
    req.url.includes("services") ||
    req.url.includes("groups") ||
    req.url.includes("specialite") ||
    req.url.includes("pays") ||
    req.url.includes("module") ||
    req.url.includes("commune") ||
    req.url.includes("identification") ||
    req.url.includes("lienparente") ||
    req.url.includes("typecartes") ||
    req.url.includes("situationmatrimoniale") ||
    req.url.includes("ethnies") ||
    req.url.includes("professionnels") ||
    req.url.includes("get_rendez_vous_by_medecin") ||
    req.url.includes("rendezvous") ||
    req.url.includes("terminer_rdv") ||
    req.url.includes('deces') ||
    req.url.includes('gynecologies') ||
    req.url.includes('medecine-generale') ||
    req.url.includes('hospitalisations') ||
    req.url.includes('orls') ||
    req.url.includes('bilan_jour')

  ) {
    req.headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token_access"),
    };
  }
  return req;
});

axios.interceptors.response.use(
  (res) => {
    return res
  },

  async (error) => {
    const originalRequest = error.config
    // Refresh Token Automatically
   
     if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true
      const token_refresh = localStorage.getItem("token_refresh")
      const response = await axios.post('/users/v1/refresh/', {refresh: token_refresh})
      if(response.status === 201){
        localStorage.setItem("token_access",response.data)
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem("token_access")
        return axios(originalRequest)
      }
     
    }
    //
    if (error.message.status === 403) {
    }
    return error;
  }
);
